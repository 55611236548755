import { Component, forwardRef } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import {
  DeprecatedMatrixData,
  MatrixData,
  ParticipationLevelActivityTemplateParams
} from '.';
import txt from '!!raw-loader!./index.ts';
import {
  CheckboxMatrixError,
  TemplateInput
} from '../../../../common/interfaces/module.interface';
import { take } from 'rxjs/operators';
import { BuyerPersona } from '../../../../common/interfaces/buyer-persona.interface';
import { of } from 'rxjs';

@Component({
  selector: 'participation-level-activity',
  templateUrl: 'participation-level-activity.component.html',
  styleUrls: ['./participation-level-activity.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => ParticipationLevelActivityComponent)
    }
  ]
})
export class ParticipationLevelActivityComponent extends TemplateComponent {
  params = txt;
  contentData: ParticipationLevelActivityTemplateParams;

  personaInputs: TemplateInput[] = [];
  selectionMatrixInputs: TemplateInput[] = [];
  valueMatrix: MatrixData[][] = [];
  personaList: BuyerPersona[];
  errorInputs: CheckboxMatrixError[] = [];

  getDescription() {
    return '';
  }

  getName() {
    return 'Participation level activity';
  }

  getGroup(): string {
    return 'Module';
  }

  init() {
    this.contentData = this.data.data
      .template_params_json as ParticipationLevelActivityTemplateParams;

    const suffix = this.contentData.input_sufix
      ? '_' + this.contentData.input_sufix
      : '';

    const personaInputsStartIndex = this.contentData.selection_matrix
      ? this.contentData.selection_matrix.length
      : 0;
    if (this.contentData.selection_matrix) {
      this.selectionMatrixInputs = this.contentData.selection_matrix.map(
        (_matrix, matrixInputIndex) =>
          this.getInput(
            `participation_level_activity_${matrixInputIndex + 1}${suffix}`
          )
      );
    }
    this.selectionMatrixInputs.forEach(line =>
      this.valueMatrix.push(line.content ? JSON.parse(line.content) : [])
    );
    this.buyerPersonasList$.pipe(take(1)).subscribe(buyerPersonas => {
      this.personaList = buyerPersonas;
      this.personaInputs = buyerPersonas.map(persona =>
        this.getInput(
          `participation_level_activity_${personaInputsStartIndex +
            persona.index}${suffix}`
        )
      );
      this.valueMatrix = this.prepareMatrixLineData(
        this.valueMatrix,
        buyerPersonas
      );
    });
  }

  validate() {
    const isValid = this.selectionMatrixInputs.reduce((acc, cur) => {
      const value = JSON.parse(cur.content);

      return value &&
        Object.keys(value).length === this.personaInputs.length &&
        value.every(select => !!select.role)
        ? acc
        : false;
    }, true);

    if (!isValid) {
      this.setErrorInputs();
      this.setValidationError({
        type: 'hasEmptyStatements',
        message: `Please make selections for all personas above`
      });
    } else {
      this.removeValidationError('hasEmptyStatements');
    }

    return of(isValid);
  }

  updateTextInput(personaIdx: number, lineIdx: number, option: string): void {
    const personaMatrixData = this.valueMatrix[lineIdx].find(
      persona => persona.index === personaIdx
    );
    personaMatrixData.role = option;
    this.errorInputs.forEach((elem, index) => {
      if (elem.errorRow === lineIdx && elem.errorCol === personaIdx - 1) {
        this.errorInputs.splice(index, 1);
      }
    });
    this.selectionMatrixInputs[lineIdx].content = JSON.stringify(
      this.valueMatrix[lineIdx]
    );
    this.onMatrixSelectionChange(this.selectionMatrixInputs[lineIdx]);
  }

  setErrorInputs(): void {
    this.selectionMatrixInputs.forEach((input, i) => {
      JSON.parse(input.content).forEach((item, k) => {
        if (!item.role) {
          this.errorInputs.push({ errorRow: i, errorCol: k });
        }
      });
    });
  }

  isInvalidCheckbox(lineIdx, personaIdx) {
    return this.errorInputs.some(
      item => item.errorRow === lineIdx && item.errorCol === personaIdx
    );
  }

  private onMatrixSelectionChange(selectionMatrixInput: TemplateInput): void {
    this.contentChanged(selectionMatrixInput);
  }

  private prepareMatrixLineData(
    matrixData: (DeprecatedMatrixData | MatrixData[])[],
    buyerPersonas: BuyerPersona[]
  ): MatrixData[][] {
    return matrixData.reduce(
      (
        accum: MatrixData[][],
        line: DeprecatedMatrixData | MatrixData[],
        lineIndex
      ) => {
        if (
          Object.values(line).some(role => typeof role === 'string') ||
          !line.length ||
          line.length !== buyerPersonas.length
        ) {
          buyerPersonas.forEach((persona, index) => {
            accum[lineIndex] = accum[lineIndex] ? accum[lineIndex] : [];
            accum[lineIndex].push({
              index: persona.index,
              role: line[index]?.role || null
            });
          });
        } else {
          accum.push([...(line as MatrixData[])]);
        }

        return accum;
      },
      []
    );
  }
}
