import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileData } from '../module-viewer/riverside-step-template/templates/file-uploader-template';
import { VideoData, VideoModalInfo } from './index';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { VideoManagerModalComponent } from './video-manager-modal/video-manager-modal.component';
import { TemplateInput } from '../common/interfaces/module.interface';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-video-manager',
  templateUrl: './video-manager.component.html',
  styleUrls: ['./video-manager.component.scss']
})
export class VideoManagerComponent implements OnInit {
  @Input() videoData: VideoData | string;
  @Input() recordModeOnly = false;
  @Input() videoWidth = '325';
  @Input() videoHeight = '245';
  @Input() input: TemplateInput;
  @Input() readonly = false;
  @Output() videoDataChange: EventEmitter<VideoData> = new EventEmitter();
  @Output() recordVideoChange: EventEmitter<string> = new EventEmitter();
  @Output() uploadVideoChange: EventEmitter<FileData> = new EventEmitter();

  srcURL: string;

  constructor(private modalService: MatDialog) {}

  ngOnInit(): void {
    if (!this.videoData || typeof this.videoData === 'string') {
      const videoUrl =
        typeof this.videoData === 'string' ? this.videoData : undefined;
      this.videoData = {
        recordedVideoUrl: videoUrl,
        uploadedVideoData: undefined
      };
    }
    this.setVideoSourceURL();
  }

  onRecordVideoChange(recordVideoUrl: string): void {
    this.recordVideoChange.emit(recordVideoUrl);
    this.videoData = {
      recordedVideoUrl: recordVideoUrl
    };
    this.onVideoDataChange(this.videoData as VideoData);
  }

  onVideoDataChange(videoData: VideoData): void {
    this.videoDataChange.emit(videoData);
  }

  openVideoManagerModal(): void {
    const dialogConfig = new MatDialogConfig<VideoModalInfo>();
    const currentData: VideoData = { ...(this.videoData as VideoData) };
    let triggerVideoDataChange = false;
    dialogConfig.data = {
      videoData: { ...currentData },
      input: this.input,
      readonly: this.readonly,
      srcURL: this.srcURL
    };
    this.modalService
      .open(VideoManagerModalComponent, dialogConfig)
      .afterClosed()
      .subscribe((videoData: VideoData) => {
        if (
          currentData?.recordedVideoUrl !== videoData.recordedVideoUrl &&
          videoData.recordedVideoUrl
        ) {
          this.recordVideoChange.emit(videoData.recordedVideoUrl);
          triggerVideoDataChange = true;
        }
        if (currentData?.uploadedVideoData !== videoData.uploadedVideoData) {
          this.uploadVideoChange.emit(videoData.uploadedVideoData);
          triggerVideoDataChange = true;
        }
        if (currentData?.comment !== videoData.comment) {
          triggerVideoDataChange = true;
        }
        if (triggerVideoDataChange) {
          this.videoData = videoData;
          this.setVideoSourceURL();
          this.onVideoDataChange(videoData);
        }
      });
  }

  setVideoSourceURL(): void {
    this.srcURL = (this.videoData as VideoData).recordedVideoUrl
      ? (this.videoData as VideoData).recordedVideoUrl
      : (this.videoData as VideoData).uploadedVideoData?.url
          .split('https://s3-us-west-2.amazonaws.com/riverside-seagage-secure/')
          .join(environment.apiRoot + '/api/resources?s3_key=');
  }
}
