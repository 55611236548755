import {
  TemplateComponentInterface,
  TemplateContentDataBase
} from '../template.interface';
import {
  InputDictionary,
  Module
} from '../../../../common/interfaces/module.interface';
import { TemplateContentData } from '../template-data.class';
import ModuleContent from '../../../../common/interfaces/module-content.model';
import { BuyerPersona } from '../../../../common/interfaces/buyer-persona.interface';

export interface BlockRepeaterTemplateData extends TemplateContentDataBase {
  template_params_json: {
    description: string;
    title: string;
    content: string;
    step_type_select: 'tabs_from_api' | 'tabs_from_builder' | 'no_tabs';
    use_buyer_persona_as_source: boolean;
    api_url: string;
    tabs_data_field: string;
    message_to_show_when_API_response_is_empty: string;
    tabs_list: Array<{ value: string }>;
    template_blocks: Array<Module>;
    previous_module_result_params: Array<{
      block_index: number;
      tab_module_template: Array<{
        module: Module;
        step_id: number;
      }>;
    }>;
    default_data_count: number;
    add_button_label: string;
    allow_to_remove_blocks: boolean;
    remove_button_label: string;
    input_sufix: string;
    show_tab_name_in_content: boolean;
    wrapper_content: string;
    load_action_label: string;
    json: string;
  };
}

export type BlockRepeaterTemplateParams = BlockRepeaterTemplateData['template_params_json'];

export class BlockRepeaterWrapperComponentTemplate
  implements TemplateComponentInterface {
  data: TemplateContentData;
  buyerPersona?: BuyerPersona;
  showBuyerPersona?: boolean;
  blockRepeaterModules?: ModuleContent[];
  removeBlock?: any;
  getInput: any;
  contentChanged: (data) => void;
  blockRepeaterContentChanged: (data, step_id: number) => void;
  wrapperContent?: string;
  tabName?: string;
  showTabName?: boolean;
  template1?: any;
}

export interface BlockData {
  [stepIndex: string]: InputDictionary;
}

export interface OldBlockRepeaterContent {
  apiData: BlockData[];
  templateData: BlockData[];
}

export interface InputRepeaterBlock {
  data: BlockData;
  id: string;
  linkedUuid?: string;
}

export interface BlockRepeaterContent {
  blocks: InputRepeaterBlock[];
  id: string;
}

export type BlockRepeaterContentInput =
  | OldBlockRepeaterContent
  | BlockRepeaterContent;
