import { ConfirmationModalComponent } from '../components/confirmation-modal/confirmation-modal';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ConfirmationModalOptions } from '../components/confirmation-modal';
import { Injectable } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { MatSpinner } from '@angular/material/progress-spinner';
import { OverlayRef } from '@angular/cdk/overlay/overlay-ref';

@Injectable()
export class ConfirmationService {
  private overlayRef: OverlayRef = this.createOverlay();

  constructor(protected modalService: MatDialog, private overlay: Overlay) {}

  removeDialog(options?: ConfirmationModalOptions): Observable<boolean> {
    return this.performDialog({
      ...options,
      type: 'removing'
    });
  }

  simpleDialog(options?: ConfirmationModalOptions): Observable<boolean> {
    return this.performDialog(options);
  }

  showLoadingSpinner(): void {
    this.overlayRef.attach(new ComponentPortal(MatSpinner));
  }

  stopLoadingSpinner(): void {
    if (this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
    }
  }

  private performDialog(
    options?: ConfirmationModalOptions
  ): Observable<boolean> {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      data: options
    });

    return modalRef.afterClosed().pipe(filter(result => result));
  }

  private createOverlay(): OverlayRef {
    return this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-dark-backdrop',
      positionStrategy: this.overlay
        .position()
        .global()
        .centerHorizontally()
        .centerVertically()
    });
  }
}
