import { Component, forwardRef, ViewChild } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import txt from '!!raw-loader!./index.ts';
import {
  CampaignNeedCriteriaType,
  CampaignNeedItem,
  CampaignNeedsData,
  CampaignNeedsTemplateParams,
  NeedCriteriaName,
  NeedsCriteria,
  PreviousInputsContent,
  PreviousNeedsInputs
} from './index';
import { TemplateInput } from '../../../../common/interfaces/module.interface';
import { Campaign } from '../campaign-calendar-template/campaign-calendar';
import {
  BuyerPersona,
  BuyerPersonaDictionary
} from '../../../../common/interfaces/buyer-persona.interface';
import { ExpandedData } from '../expand-data';
import { ListElement, ListEntry } from '../list-entry';
import { map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import ModuleContent from '../../../../common/interfaces/module-content.model';
import { BlockRepeaterContent } from '../block-repeater';
import { getNestedValue } from '../../../../common/utils/helpers';
import { TabsComponent } from '../../../../common/components/tabs/tabs-component/tabs.component';

@Component({
  selector: 'app-campaign-needs-template',
  templateUrl: './campaign-needs-template.component.html',
  styleUrls: ['./campaign-needs-template.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => CampaignNeedsTemplateComponent)
    }
  ]
})
export class CampaignNeedsTemplateComponent extends TemplateComponent {
  @ViewChild('tabsComponent') tabsComponent: TabsComponent;

  params = txt;
  contentData: CampaignNeedsTemplateParams;
  prefix = 'campaign_needs_1';

  selectedCampaignIndex = 0;
  campaignCalendar: Campaign[];
  campaignNeedsData: CampaignNeedsData;
  personas: BuyerPersonaDictionary;
  showError = false;
  errorMessage = 'Something went wrong. Please contact administrator!';

  readonly needTypeOrder: CampaignNeedCriteriaType[] = [
    'needs',
    'challenges',
    'goals',
    'triggerEvents'
  ];
  readonly needName: NeedCriteriaName = {
    needs: 'Need',
    challenges: 'Challenge',
    goals: 'Goal',
    triggerEvents: 'Trigger Event'
  };

  private readonly buyerPersonaModuleId = 1;
  private readonly buyerPersonaStepIds = [
    19535,
    19543,
    20559,
    21007,
    21015,
    20016,
    20017,
    20018,
    20019
  ];
  private readonly campaignCalendarInputName = 'campaign_calendar_1';
  private readonly previousInputInfo: PreviousNeedsInputs = {
    needs: [
      [
        21007,
        'block_repeater_1_recognizes_need',
        {
          childPath: [
            'templateData[{personaId}].21012.list_entry_1_recognizes_need_needs.content',
            'blocks[{personaId}].data.21012.list_entry_1_recognizes_need_needs.content'
          ]
        }
      ],
      [
        21015,
        'block_repeater_1_defines_need',
        {
          childPath: [
            'templateData[{personaId}].21020.list_entry_1_defines_need_need.content',
            'blocks[{personaId}].data.21020.list_entry_1_defines_need_need.content'
          ]
        }
      ],
      [
        20016,
        'block_repeater_1_evaluates_options',
        {
          childPath: [
            'templateData[{personaId}].20023.list_entry_1_evaluates_options_need.content',
            'blocks[{personaId}].data.20023.list_entry_1_evaluates_options_need.content'
          ]
        }
      ],
      [
        20017,
        'block_repeater_1_mitigates_risk',
        {
          childPath: [
            'templateData[{personaId}].20026.list_entry_1_mitigates_risk_need.content',
            'blocks[{personaId}].data.20026.list_entry_1_mitigates_risk_need.content'
          ]
        }
      ],
      [
        20018,
        'block_repeater_1_selects_solution',
        {
          childPath: [
            'templateData[{personaId}].20029.list_entry_1_selects_solution_need.content',
            'blocks[{personaId}].data.20029.list_entry_1_selects_solution_need.content'
          ]
        }
      ],
      [
        20019,
        'block_repeater_1_realizes_value',
        {
          childPath: [
            'templateData[{personaId}].20032.list_entry_1_realizes_value_need.content',
            'blocks[{personaId}].data.20032.list_entry_1_realizes_value_need.content'
          ]
        }
      ]
    ],
    challenges: [
      [19535, 'expand_data_1_challenges', { expandDataTitle: 'Challenges' }]
    ],
    goals: [[19543, 'expand_data_1_goals', { expandDataTitle: 'Goals' }]],
    triggerEvents: [
      [
        20559,
        'expand_data_1_trigger_events',
        { expandDataTitle: 'Trigger Events' }
      ]
    ]
  };
  private previousInputContent: PreviousInputsContent;
  private inputKey: string;

  get input(): TemplateInput {
    return this.inputs[this.inputKey];
  }

  get calendarInput(): TemplateInput {
    return this.inputs[this.campaignCalendarInputName];
  }

  getDescription() {
    return '';
  }

  getName() {
    return 'Campaign Needs';
  }

  getGroup(): string {
    return 'Module';
  }

  init(): void {
    this.contentData = this.data.data
      .template_params_json as CampaignNeedsTemplateParams;
    this.inputKey = this.contentData.input_sufix
      ? `${this.prefix}_${this.contentData.input_sufix}`
      : this.prefix;

    this.prepareCampaignNeedsData();
  }

  addPersonaNeed(needs: CampaignNeedItem[]): void {
    needs.push({ textValue: null, checked: false });
  }

  removePersonaNeed(
    needs: CampaignNeedItem[],
    needIndex: number,
    criteriaType: CampaignNeedCriteriaType
  ): void {
    this.confirmationService
      .removeDialog({ text: this.needName[criteriaType] })
      .subscribe(() => {
        needs.splice(needIndex, 1);
        this.saveNeedInput();
      });
  }

  onCampaignChange(): void {
    this.tabsComponent?.selectTab(0);
  }

  saveNeedInput(): void {
    const newContentString = JSON.stringify(this.campaignNeedsData);
    if (newContentString !== this.input.content) {
      this.input.content = newContentString;
      this.contentChanged(this.input);
    }
  }

  private prepareCampaignNeedsData(): void {
    const campaigns: Campaign[] = JSON.parse(
      this.calendarInput?.content || null
    ) as Campaign[];
    const needs: CampaignNeedsData = JSON.parse(
      this.input?.content || null
    ) as CampaignNeedsData;
    if (campaigns?.length) {
      this.preparePreviousDataContent()
        .pipe(switchMap(() => this.buyerPersonasService.getBuyerPersonas()))
        .subscribe(
          personas => {
            this.personas = personas.reduce(
              (accum: BuyerPersonaDictionary, persona) => {
                accum[persona.uuid] = persona;

                return accum;
              },
              {}
            );
            this.campaignCalendar = campaigns;
            this.campaignNeedsData = campaigns.reduce(
              (accum: CampaignNeedsData, calendar) => {
                const campaignNeed = needs?.campaigns?.find(
                  need => need.companyId === calendar.id
                );
                const campaignPersonas = this.parseStringPersonaToArray(
                  calendar.persona
                );
                const personaNeeds = campaignPersonas?.map(personaId => {
                  const personaInfo = campaignNeed?.persona?.find(
                    info => info.id === personaId
                  ) || { id: personaId, newInfo: this.getBlankCriterias() };

                  personaInfo.previousInfo = this.getPreviousNeedsInfo(
                    this.personas[personaId],
                    personaInfo?.previousInfo
                  );

                  return personaInfo;
                });
                accum.campaigns.push({
                  companyId: calendar.id,
                  persona: personaNeeds
                });

                return accum;
              },
              { campaigns: [] }
            );
          },
          () => (this.showError = true)
        );
    } else {
      this.showError = true;
      this.errorMessage =
        'Please go back to step 4 and complete the campaigns!';
    }
  }

  private getPreviousNeedsInfo(
    persona: BuyerPersona,
    oldPrevData: NeedsCriteria
  ): NeedsCriteria {
    return Object.keys(this.previousInputInfo).reduce(
      (
        criteriaAccum: NeedsCriteria,
        criteriaName: CampaignNeedCriteriaType
      ) => {
        const criteriaArray: string[] = this.previousInputInfo[criteriaName]
          .map(inputInfo => {
            if (!this.previousInputContent?.[inputInfo[1]]) {
              return null;
            }
            if (inputInfo[1].includes('block_repeater')) {
              return this.getNeedFromBlockRepeater(
                this.previousInputContent?.[
                  inputInfo[1]
                ] as BlockRepeaterContent,
                inputInfo[2].childPath,
                persona
              );
            } else if (inputInfo[1].includes('expand_data')) {
              return this.getNeedFromExpandData(
                this.previousInputContent?.[inputInfo[1]] as ExpandedData,
                inputInfo[2].expandDataTitle,
                persona
              );
            }

            return null;
          })
          .flat()
          .filter(Boolean);
        const criteriaNeeds: CampaignNeedItem[] = criteriaArray.map(
          (item, index) => {
            const oldItem = oldPrevData?.[criteriaName]?.[index];

            return {
              textValue: { content: item },
              checked: oldItem?.checked || false
            };
          }
        );
        criteriaAccum[criteriaName] = criteriaNeeds;

        return criteriaAccum;
      },
      this.getBlankCriterias()
    );
  }

  private getNeedFromBlockRepeater(
    content: BlockRepeaterContent,
    childPath: string[],
    persona: BuyerPersona
  ): string[] {
    let listEntry: ListElement[] =
      getNestedValue(content, childPath[0], {
        personaId: String(persona.index - 1)
      }) ||
      getNestedValue(content, childPath[1], {
        personaId: String(persona.index - 1)
      }) ||
      null;
    if (!!listEntry && !Array.isArray(listEntry)) {
      listEntry = Object.values(listEntry as ListEntry);
    }

    return listEntry?.map(item => item.option);
  }

  private getNeedFromExpandData(
    content: ExpandedData,
    title: string,
    persona: BuyerPersona
  ): string {
    const value = content?.[title]?.[persona.index - 1];

    return value?.ice?.content || (!!value?.value && String(value?.value));
  }

  private parseStringPersonaToArray(
    persona: string | (string | number)[]
  ): string[] {
    if (typeof persona === 'string') {
      return Object.values(this.personas)
        .filter(buyerPersona => (persona as string).includes(buyerPersona.name))
        .sort((a, b) => a.index - b.index)
        .map(buyerPersona => buyerPersona.uuid);
    }

    return Object.values(this.personas)
      .filter(p => persona.some(i => p.uuid === String(i) || p.index === i))
      .sort((a, b) => a.index - b.index)
      .map(p => p.uuid);
  }

  private preparePreviousDataContent(): Observable<void> {
    return this.navService.organization$.pipe(
      switchMap(orgId =>
        this.moduleContentService
          .loadSteps(
            this.buyerPersonaModuleId,
            orgId,
            this.buyerPersonaStepIds,
            true
          )
          .pipe(
            map(content => {
              const stepsContent = content.reduce(
                (accum: { [stepId: string]: ModuleContent }, step) => {
                  accum[step.step_id] = step;

                  return accum;
                },
                {}
              );
              this.previousInputContent = Object.keys(
                this.previousInputInfo
              ).reduce(
                (
                  accum: PreviousInputsContent,
                  criteriaName: CampaignNeedCriteriaType
                ) => {
                  this.previousInputInfo[criteriaName]?.forEach(inputInfo => {
                    const input =
                      stepsContent?.[inputInfo[0]]?.inputs?.[inputInfo[1]];
                    accum[inputInfo[1]] = JSON.parse(input?.content || null);
                  });

                  return accum;
                },
                {}
              );

              return;
            })
          )
      )
    );
  }

  private getBlankCriterias(): NeedsCriteria {
    return {
      needs: [],
      challenges: [],
      goals: [],
      triggerEvents: []
    };
  }
}
