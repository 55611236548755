import { Component, forwardRef } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import {
  AreaICPSegment,
  ICPArea,
  ICPAreaBuyerPersona,
  IcpAreasTemplateData
} from '.';
import txt from '!!raw-loader!./index.ts';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { TemplateInput } from '../../../../common/interfaces/module.interface';
import {
  BuyerPersonaMatrix,
  BuyerPersonaMatrixFromStep,
  ICPSegmentSelector
} from '../value-proposition';
import { take } from 'rxjs/operators';

@Component({
  selector: 'icp-areas',
  templateUrl: 'icp-areas.component.html',
  styleUrls: ['./icp-areas.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => IcpAreasComponent)
    }
  ]
})
export class IcpAreasComponent extends TemplateComponent {
  params = txt;
  contentData: IcpAreasTemplateData['template_params_json'];

  buyerPersonasSelectionMatrixInput: TemplateInput;
  icpSegmentsInput: TemplateInput;
  icpAreasInput: TemplateInput;

  sanitizer: DomSanitizer;
  modalService: MatDialog;

  faTrashIcon = faTrash;

  buyerPersonaMatrix = new BuyerPersonaMatrix();

  icpSegmentsFromPreviousStep: ICPSegmentSelector[] = [];
  icpAreaSegments: AreaICPSegment[];
  buyerPersonasArray: ICPAreaBuyerPersona[];

  errorMessage =
    'Please return to ICP Segments step and select at least one ICP segment.';
  hasErrors = false;

  getDescription() {
    return '';
  }

  getName() {
    return 'Icp Areas';
  }

  getGroup(): string {
    return 'Module';
  }

  init() {
    this.contentData = this.data.data
      .template_params_json as IcpAreasTemplateData['template_params_json'];

    this.modalService = this.injectorObj.get(MatDialog);
    this.sanitizer = this.injectorObj.get(DomSanitizer);

    this.buyerPersonasSelectionMatrixInput = this.getInput(
      'buyer_personas_selection_matrix',
      1
    );

    this.icpAreasInput = this.getInput('icp_areas', 1);

    this.icpSegmentsInput = this.getInput('icp_segment_selector', 1);

    this.icpSegmentsFromPreviousStep = (JSON.parse(
      this.icpSegmentsInput.content
    ) as ICPSegmentSelector[]).filter(segment => segment.isChecked);

    const personaMatrix = JSON.parse(
      this.buyerPersonasSelectionMatrixInput.content
    ) as BuyerPersonaMatrixFromStep;

    this.buyerPersonaMatrix = this.getBuyerPersonaMatrixInstance(personaMatrix);

    if (!personaMatrix || this.icpSegmentsFromPreviousStep.length === 0) {
      this.hasErrors = true;
    } else {
      const neededPersonas = this.buyerPersonaMatrix.rows.map(r => r.persona);

      if (this.icpAreasInput.content) {
        this.buyerPersonasList$.pipe(take(1)).subscribe(personas => {
          this.buyerPersonasArray = personas.filter(
            p => neededPersonas.indexOf(p.uuid) !== -1
          ) as ICPAreaBuyerPersona[];

          const icpAreaSegmentsFromInput = JSON.parse(
            this.icpAreasInput.content
          ) as AreaICPSegment[];

          this.icpAreaSegments = this.icpSegmentsFromPreviousStep.map(
            segment => {
              const personasFromMatrix = this.getPersonasFromMatrixByICPIndex(
                segment.index
              );

              const ICPForCheck = icpAreaSegmentsFromInput.find(
                s => s.index === segment.index
              );

              if (!ICPForCheck) {
                const newICP = this.getNewICPAreasSegmentInstance(
                  segment.title,
                  segment.index
                );
                newICP.personas = personasFromMatrix;

                return newICP;
              }

              personasFromMatrix.forEach(pfm => {
                const personaForCheck = ICPForCheck.personas.find(
                  p => p.index === pfm.index
                );
                if (personaForCheck) {
                  pfm.areas = personaForCheck.areas.slice();
                }
              });

              ICPForCheck.personas = personasFromMatrix;

              return ICPForCheck;
            }
          );
        });
      } else {
        this.icpAreaSegments = [];
        this.buyerPersonasList$.pipe(take(1)).subscribe(personas => {
          this.buyerPersonasArray = personas.filter(
            p => neededPersonas.indexOf(p.uuid) !== -1
          ) as ICPAreaBuyerPersona[];

          this.icpAreaSegments = this.icpSegmentsFromPreviousStep.map(
            segment => {
              const emptyICP = this.getNewICPAreasSegmentInstance(
                segment.title,
                segment.index
              );

              emptyICP.personas = this.getPersonasFromMatrixByICPIndex(
                segment.index
              );

              return emptyICP;
            }
          );
        });
      }
    }
  }

  getBuyerPersonaMatrixInstance(
    personaMatrix: BuyerPersonaMatrixFromStep
  ): BuyerPersonaMatrix {
    const resultPersonaMatrix = new BuyerPersonaMatrix();
    Object.entries(personaMatrix).map(pair => {
      const persona = pair[0];
      const icps = pair[1];

      const row = {
        persona,
        icps: []
      };

      row.icps = Object.entries(icps)
        .filter(icpPair => icpPair[1])
        .map(icpPair => ({
          index: Number(icpPair[0]),
          isSelected: icpPair[1]
        }));

      if (row.icps.length > 0) {
        resultPersonaMatrix.rows.push(row);
      }
    });

    return resultPersonaMatrix;
  }

  getNewICPAreasSegmentInstance(title: string, index: number): AreaICPSegment {
    return {
      name: title,
      personas: [],
      index,
      isChecked: true,
      criteria: null
    };
  }

  getNewICPAreaInstance(icpIndex: number, icpAreaName: string) {
    return {
      icp: icpIndex,
      name: icpAreaName,
      inputs: ['', '', '']
    };
  }

  getICPAreaPersonaInstance(persona: ICPAreaBuyerPersona) {
    return {
      ...persona,
      isAddICPAreaSectionVisible: false,
      areas: [1, 2, 3].map(() => ({
        name: '',
        icp: 0,
        inputs: ['', '', '']
      }))
    };
  }

  getPersonasFromMatrixByICPIndex(icpIndex: number): ICPAreaBuyerPersona[] {
    return this.buyerPersonaMatrix.rows
      .filter(r => r.icps.find(i => i.index === icpIndex))
      .map(r =>
        this.getICPAreaPersonaInstance(
          this.buyerPersonasArray.find(bpr => bpr.uuid === r.persona)
        )
      );
  }

  validate(): Observable<boolean> {
    const areas = this.icpAreaSegments
      .map(s => s.personas)
      .flat()
      .map(p => p.areas)
      .flat() as ICPArea[];

    return of(
      areas.some(
        area =>
          area.name.length > 0 && area.inputs.some(input => input.length > 0)
      )
    );
  }

  addICPArea(
    persona: ICPAreaBuyerPersona,
    icpAreaName: string,
    icpIndex: number
  ) {
    const newArea = this.getNewICPAreaInstance(icpIndex, icpAreaName);

    if (this.validateArea(newArea)) {
      persona.areas.push(newArea);
      persona.isAddICPAreaSectionVisible = false;
      this.saveICP();
    } else {
      this.snackBarService.error('Please enter area title');
    }
  }

  saveICP() {
    const icpAreaSegmentsToSave = this.icpAreaSegments.map(segment => ({
      index: segment.index,
      name: segment.name,
      personas: segment.personas.map(persona => ({
        index: persona.index,
        areas: persona.areas
      }))
    }));

    this.icpAreasInput.content = JSON.stringify(icpAreaSegmentsToSave);
    this.contentChanged(this.icpAreasInput);
  }

  removeICPArea(persona: ICPAreaBuyerPersona, areaIndex: number) {
    if (persona.areas.length === 3) {
      return this.snackBarService.error(
        `You can't leave less than three areas!`
      );
    }

    this.confirmationService
      .removeDialog({
        text: `"Area #${areaIndex + 1}: ${persona.areas[areaIndex].name}"`
      })
      .subscribe(() => {
        persona.areas.splice(areaIndex, 1);
        this.saveICP();
      });
  }

  makeSafeText(area: ICPArea, inputIndex: number) {
    area.inputs[inputIndex] = this.textContent(area.inputs[inputIndex]);
  }

  updateAreaName(area: ICPArea) {
    area.name = this.textContent(area.name);
    this.saveICP();
  }

  switchInput($event: KeyboardEvent, position: number) {
    const parentOfInput = ($event.target as HTMLInputElement).parentElement;
    const neededInput =
      position === 1
        ? parentOfInput.previousElementSibling?.firstElementChild
        : parentOfInput.nextElementSibling?.firstElementChild;

    if (neededInput instanceof HTMLInputElement) {
      neededInput.selectionStart = neededInput.value.length;
      neededInput.focus();
    }
  }

  validateArea(area: ICPArea) {
    area.name = area.name.trim();
    area.name = this.textContent(area.name);

    return area.name.length > 1;
  }
}
