import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { LoaderEnabled } from './loader.service';
import { SnackBarService } from './snackbar.service';

export type UsersTypes = 'md' | 'facilitator' | 'rmcf';

export interface UsersTypesInterface {
  type: UsersTypes;
  label: string;
  buttonLabel: string;
  list?: UserDTO[];
}

export class UserDTO {
  email: string;
  fname: string;
  lname: string;
  id?: string;
  isEnabled?: boolean;
  isDeleted?: boolean;
}

export interface UserEvent {
  type: 'changeState' | 'delete';
  user: UserDTO;
}

@Injectable()
export class UsersManagementService {
  baseUrl = environment.apiRoot + '/api/specuser';

  constructor(
    private httpClient: HttpClient,
    private snackBar: SnackBarService
  ) {}

  @LoaderEnabled()
  loadByType(userType: UsersTypes) {
    return this.httpClient.get(`${this.baseUrl}/list/${userType}`).pipe(
      catchError(error => {
        this.snackBar.error('Data wasn\'t loaded. Try later, please');

        throw error;
      })
    );
  }

  @LoaderEnabled()
  createUser(userType: UsersTypes, userData: Partial<UserDTO>) {
    return this.httpClient
      .post(`${this.baseUrl}/create/${userType}`, userData)
      .pipe(
        catchError(error => {
          if (error.status === 400) {
            this.snackBar.error(
              error.error && error.error.failure
                ? error.error.failure
                : 'User wasn\'t created. Check the correctness of the fields, please'
            );
          } else {
            this.snackBar.error('User wasn\'t created. Try later, please');
          }

          throw error;
        })
      );
  }

  @LoaderEnabled()
  toggleUserState(userType: UsersTypes, userData: UserDTO) {
    return this.httpClient
      .post(`${this.baseUrl}/${userData.id}/toggle`, {
        state: !userData.isEnabled
      })
      .pipe(
        catchError(error => {
          if (error.status === 400) {
            this.snackBar.error(
              error.error && error.error.failure
                ? error.error.failure
                : 'User\'s state wasn\'t changed. Try later, please'
            );
          } else {
            this.snackBar.error(
              'User\'s state wasn\'t changed. Try later, please'
            );
          }

          throw error;
        })
      );
  }

  @LoaderEnabled()
  deleteUser(userType: UsersTypes, userData: UserDTO) {
    return this.httpClient
      .post(`${this.baseUrl}/${userData.id}/delete`, {})
      .pipe(
        catchError(error => {
          if (error.status === 400) {
            this.snackBar.error(
              error.error && error.error.failure
                ? error.error.failure
                : 'User wasn\'t deleted. Try later, please'
            );
          } else {
            this.snackBar.error('User wasn\'t deleted. Try later, please');
          }

          throw error;
        })
      );
  }
}
