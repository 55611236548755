import { ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModuleViewerRootComponent } from './module-viewer-root.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MainComponent } from './main/main.component';
import { AppRoutingModule } from './module-viewer-routing.module';
import { RouterModule } from '@angular/router';
import { ModuleService } from '../common/services/module.service';
import { LeftMenuComponent } from './left-menu/module/module-left-menu.component';
import { ContentComponent } from './content/content.component';
import { UserService } from '../common/services/user.service';
import { IceComponent } from './ice/ice.component';
import { RiversideStepTemplateComponent } from './riverside-step-template/riverside-step-template.component';
import { RTemplateDirective } from './riverside-step-template/riverside-step-template-host.directive';
import { ModuleContentService } from '../common/services/module-content.service';
import { LetterImageComponent } from './ice/letter-image/letter-image.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSortModule } from '@angular/material/sort';
import { UserComponent } from './ice/user/user.component';
import { IceService } from './ice/ice.service';
import { LeftSidebarComponent } from './left-sidebar/left-sidebar.component';
import { InboxComponent } from './inbox/inbox.component';
import { LeftMenuService } from '../common/services/left-menu.service';
import { InboxLeftMenuComponent } from './left-menu/inbox/inbox-left-menu.component';
import { AccountLeftMenuComponent } from './left-menu/account/account-left-menu.component';
import { E3TableComponent } from '../common/components/e3-table/e3-table.component';
import { InboxService } from './inbox/inbox.service';
import { ModuleBuilderModule } from '../module-builder/module-builder.module';
import { RequestFeedbackComponent } from './request-feedback/request-feedback.component';
import { E3CommonModule } from '../common/e3-common.module';
import { ModuleNavComponent } from './content/module-nav/module-nav.component';
import { ModuleNavService } from '../common/services/module-nav.service';
import { BrainstormTemplateComponent } from './riverside-step-template/templates/brainstorm/brainstorm.component';
import { GenericTemplateComponent } from './riverside-step-template/templates/generic/generic.component';
import { PersonaBehaviorTemplateComponent } from './riverside-step-template/templates/persona_behavior/persona_behavior.component';
import { SegmentCriteriaDefineComponent } from './riverside-step-template/templates/segment-criteria-define/segment-criteria-define.component';
import { ReadableTimePipe } from '../common/pipes/readabletime.pipe';
import { CanModifyPipe } from '../common/pipes/canModify.pipe';
import { ReversePipe } from '../common/pipes/reverse.pipe';
import { CustomCurrencyPipe } from '../common/pipes/custom-currency.pipe';
import { PersonaPictureTemplateComponent } from './riverside-step-template/templates/persona-picture/persona-picture.component';
import { PersonaPictureListComponent } from './riverside-step-template/templates/persona-picture/persona-picture-list/persona-picture-list.component';
import { QuestionImageComponent } from './riverside-step-template/templates/question-image/question-image.component';
import { PersonaComponent } from './riverside-step-template/persona/persona.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { VideoManagerModule } from '../video-manager/video-manager.module';
import { FeedbackSourceComponent } from './request-feedback/feedback-source/feedback-source.component';
import { AudioRecorderModule } from '../audio-recorder/audio-recorder.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MasterDashboardComponent } from './master-dashboard/master-dashboard.component';
import { DashboardProgressBarComponent } from './dashboard-progress-bar/dashboard-progress-bar.component';
import {
  LineChartModule,
  BarChartModule,
  BubbleChartModule,
  NgxChartsModule
} from '@swimlane/ngx-charts';
import { ModuleLinkComponent } from './dashboard/module-link/module-link.component';
import { SelectionMatrixComponent } from './selection-matrix/selection-matrix.component';
import { OrgSelectorComponent } from './left-menu/org-selector/org-selector.component';
import { TemplateHeadingComponent } from './riverside-step-template/template-heading/template-heading.component';
import { MatInputModule } from '@angular/material/input';
import { ToggleModuleComponent } from './dashboard/toggle-module/toggle-module.component';
import { DueDateComponent } from './dashboard/due-date/due-date.component';
import { AssessmentComponent } from './assessment/assessment.component';
import { AssessmentScoreComponent } from './assessment-score/assessment.component';
import { AssessmentMenuComponent } from './left-menu/assessment-menu/assessment-menu.component';
import { AssessmentService } from '../common/services/assessment.service';
import { AssessmentFinishComponent } from './assessment/assessment-finish/assessment-finish.component';
import { ViewAssessmentsComponent } from './dashboard/view-assessments/view-assessments.component';
import { ChartLabelTooltipsDirective } from './directives/chart-label-tooltips.directive';
import { LineChartValueLabelDirective } from './directives/line-chart-value-label.directive';
import { AssessmentChartComponent } from './assessment-chart/assessment-chart.component';
import { ProfileComponent } from './account/profile/profile.component';
import { ChangePasswordComponent } from './account/change-password/change-password.component';
import { ProfilePictureInputComponent } from './account/profile/profile-picture-input/profile-picture-input.component';
import { ProfilePictureSelectorComponent } from './account/profile/profile-picture-selector/profile-picture-selector.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SpinnerComponent } from './spinner/spinner.component';
import { InputTextComponent } from './input-text/input-text.component';
import { VideoComponent } from './riverside-step-template/templates/video/video.component';
import { IcpInputComponent } from './riverside-step-template/templates/segment-criteria-define/icp-input/icp-input.component';
import { ModuleFeedbackComponent } from './riverside-step-template/module-feedback/module-feedback.component';
import { ErrorMsgComponent } from './error-msg/error-msg.component';
import { SpreadsheetComponent } from './riverside-step-template/templates/spreadsheet/spreadsheet.component';
import { HotTableModule } from '../../lib/angular-handsontable-3.0.0-ce/public_api';
import { SpreadsheetService } from '../common/services/spreadsheet.service';
import { LoginComponent } from './login/login.component';
import { CorsInterceptor } from '../common/interceptors/CorsInterceptor';
import { ModuleProgressBarComponent } from './riverside-step-template/module-progress-bar/module-progress-bar.component';
import { BuyerPersonasSelectorComponent } from './riverside-step-template/persona/buyer-personas-selector/buyer-personas-selector.component';
import { PreRequisiteModulesComponent } from './riverside-step-template/templates/pre-requisite-modules/pre-requisite-modules.component';
import { ModuleResultComponent } from './riverside-step-template/templates/module-result/module-result.component';
import { BuyerPersonasService } from '../common/services/buyer-personas.service';
import { CampaignCalendarTemplateComponent } from './riverside-step-template/templates/campaign-calendar-template/campaign-calendar-template.component';
import { CampaignCalendarComponent } from './riverside-step-template/templates/campaign-calendar-template/campaign-calendar/campaign-calendar.component';
import { PersonaNamePipe } from '../common/pipes/personaName.pipe';
import { SessionExpirationModalComponent } from '../common/components/session-expiration-modal/session-expiration-modal.component';
import { RadioButtonComponent } from './riverside-step-template/templates/radio-button/radio-button.component';
import { ContentMapComponent } from './riverside-step-template/templates/content-map/content-map.component';
import { CheckboxSelectorComponent } from './riverside-step-template/templates/checkbox-selector/checkbox-selector.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatGridListModule } from '@angular/material/grid-list';
import { PersonaStrategyComponent } from './riverside-step-template/templates/persona-strategy/persona-strategy.component';
import { IcpBreakoutsComponent } from './riverside-step-template/templates/icp-breakouts/icp-breakouts.component';
import { ObserversModule } from '@angular/cdk/observers';
import { IcpTableComponent } from './riverside-step-template/templates/icp-breakouts/icp-table/icp-table.component';
import { FileUploaderTemplateComponent } from './riverside-step-template/templates/file-uploader-template/file-uploader-template.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PersonaRolesComponent } from './riverside-step-template/templates/persona-roles/persona-roles.component';
import { ListEntryComponent } from './riverside-step-template/templates/list-entry/list-entry.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ComposableTemplateComponent } from './riverside-step-template/templates/composable-template/composable-template.component';
import { TalentStrategyComponent } from './riverside-step-template/templates/talent-strategy/talent-strategy.component';
import { MatSelectModule } from '@angular/material/select';
import { RACIComponent } from './riverside-step-template/templates/raci/raci.component';
import { NegativeAnswersComponent } from './riverside-step-template/templates/negative-answers/negative-answers.component';
import { BuyerJourneyMapComponent } from './riverside-step-template/templates/buyer-journey-map/buyer-journey-map.component';
import { ShowInputValueComponent } from './riverside-step-template/templates/show-input-value/show-input-value.component';
import { BuyerProcessMapComponent } from './riverside-step-template/templates/buyer-process-map/buyer-process-map.component';
import { BuyerPersonasSelectionMatrixComponent } from './riverside-step-template/templates/buyer-personas-selection-matrix/buyer-personas-selection-matrix.component';
import { IncludesValuePipe } from '../common/pipes/ArrayTools/includesValue.pipe';
import { ContentPipe } from '../common/pipes/InputsTools/content.pipe';
import { HtmlBreaklinesPipe } from '../common/pipes/InputsTools/htmlBreaklines.pipe';
import { MarketingOrganizationDesignComponent } from './riverside-step-template/templates/marketing-organization-design/marketing-organization-design.component';
import { CompetitionComparisonComponent } from './riverside-step-template/templates/competition-comparison/competition-comparison.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { SelectBuyerPersonaComponent } from './riverside-step-template/templates/select-buyer-persona/select-buyer-persona.component';
import { ExpandDataComponent } from './riverside-step-template/templates/expand-data/expand-data.component';
import { UsersManagementModule } from '../users-management/users-management.module';
import { ValuePropositionComponent } from './riverside-step-template/templates/value-proposition/value-proposition.component';
import { MatListModule } from '@angular/material/list';
import { MatrixEntryComponent } from './riverside-step-template/templates/matrix-entry/matrix-entry.component';
import { MatRippleModule, MatNativeDateModule } from '@angular/material/core';
import { IcpSegmentSelectorComponent } from './riverside-step-template/templates/icp-segment-selector/icp-segment-selector.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { RavenExceptionHandler } from '../common/services/raven.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormBuilderFieldComponent } from './riverside-step-template/templates/brainstorm/form-builder-field/form-builder-field.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatChipsModule } from '@angular/material/chips';
import { ConfirmationService } from '../common/services/confirmation.service';
import { CountdownTimerComponent } from './riverside-step-template/templates/countdown-timer/countdown-timer.component';
import { BuyerInputListComponent } from './riverside-step-template/templates/buyer-input-list/buyer-input-list.component';
import { OpportunitySummaryComponent } from './riverside-step-template/templates/opportunity-summary/opportunity-summary.component';
import { CompensationAndQuotaComponent } from './riverside-step-template/templates/compensation-and-quota/compensation-and-quota.component';
import { IcpAreasComponent } from './riverside-step-template/templates/icp-areas/icp-areas.component';
import { QuizComponent } from './riverside-step-template/templates/quiz/quiz.component';
import { SalesMarketingOpsReviewComponent } from './riverside-step-template/templates/sales-marketing-ops-review/sales-marketing-ops-review.component';
import { EvaluateComponent } from './riverside-step-template/templates/evaluate/evaluate.component';
import { MatTableModule } from '@angular/material/table';
import { EvaluateTableComponent } from './riverside-step-template/templates/evaluate/evaluate-table/evaluate-table.component';
import { EvaluateSelectionFromTotalsComponent } from './riverside-step-template/templates/evaluate/evaluate-selection-from-totals/evaluate-selection-from-totals.component';
import { SalesBudgetStatusTemplateComponent } from './riverside-step-template/templates/sales-budget-status-template/sales-budget-status-template.component';
import { SalesLeadershipSellingTimeComponent } from './riverside-step-template/templates/sales-leadership-selling-time/sales-leadership-selling-time.component';
import { SituationalCoachingComponent } from './riverside-step-template/templates/situational-coaching/situational-coaching.component';
import { CustomerSuccessReviewComponent } from './riverside-step-template/templates/customer-success-review/customer-success-review.component';
import { ParticipationLevelActivityComponent } from './riverside-step-template/templates/participation-level-activity/participation-level-activity.component';
import { BlockRepeaterComponent } from './riverside-step-template/templates/block-repeater/block-repeater.component';
import { ReviewWithSelectionComponent } from './riverside-step-template/templates/review-with-selection/review-with-selection.component';
import { PricingStrategyPlotComponent } from './riverside-step-template/templates/pricing-strategy-plot/pricing-strategy-plot.component';
import { MatrixCheckboxesComponent } from './riverside-step-template/templates/matrix-entry/matrix-checkboxes/matrix-checkboxes.component';
import { ExportKeyInterceptor } from '../common/interceptors/export-key.interceptor';
import { ChannelStrategySummaryComponent } from './riverside-step-template/templates/channel-strategy-summary/channel-strategy-summary.component';
import { ProspectingContactStrategyComponent } from './riverside-step-template/templates/prospecting-contact-strategy/prospecting-contact-strategy.component';
import { CalculatedCriteriaComponent } from './riverside-step-template/templates/calculated-criteria/calculated-criteria.component';
import { KpiSummaryComponent } from './riverside-step-template/templates/kpi-summary/kpi-summary.component';
import { BlockRepeaterWrapperComponent } from './riverside-step-template/templates/block-repeater/block-repeater-wrapper/block-repeater-wrapper.component';
import { MatrixEntrySummaryComponent } from './riverside-step-template/templates/matrix-entry-summary/matrix-entry-summary.component';
import { BlockRepeaterResultComponent } from './riverside-step-template/templates/block-repeater-result/block-repeater-result.component';
import { SafeIDPipe } from '../common/pipes/safeID.pipe';
import { TerritoryDesignSummaryComponent } from './riverside-step-template/templates/territory-design-summary/territory-design-summary.component';
import { RestService } from '../common/services/rest.service';
import { PlaysReviewComponent } from './riverside-step-template/templates/plays-review/plays-review.component';
import { ExpandDataResultComponent } from './riverside-step-template/templates/expand-data-result/expand-data-result.component';
import { VideoLibraryComponent } from './video-library/video-library.component';
import { VideoModalComponent } from './video-library/video-modal/video-modal.component';
import { CustomNumberPipe } from '../common/pipes/custom-number.pipe';
import { FinalFeedbackComponent } from './riverside-step-template/templates/final-feedback/final-feedback.component';
import { AudienceExtensionTemplateComponent } from './riverside-step-template/templates/audience-extension-template/audience-extension-template.component';
import { ChartDataComponent } from './riverside-step-template/templates/chart-data/chart-data.component';
import { CustomBubbleChartComponent } from './components/custom-charts/custom-bubble-chart/custom-bubble-chart.component';
import { CustomBubbleSeriesComponent } from './components/custom-charts/custom-bubble-chart/custom-bubble-series.component';
import { BubbleLabelComponent } from './components/custom-charts/custom-bubble-chart/bubble-label.component';
import { CampaignNeedsTemplateComponent } from './riverside-step-template/templates/campaign-needs-template/campaign-needs-template.component';
import { ListViewDashboardComponent } from './dashboard/list-view-dashboard/list-view-dashboard.component';
import { ListViewSeDashboardComponent } from './dashboard/list-view-dashboard/list-view-se-dashboard/list-view-se-dashboard.component';
import { ListViewFacDashboardComponent } from './dashboard/list-view-dashboard/list-view-fac-dashboard/list-view-fac-dashboard.component';
import { DashboardHeaderComponent } from './dashboard/dashboard-header/dashboard-header.component';
import { ListViewBaseDashboardComponent } from './dashboard/list-view-dashboard/list-view-base-dashboard';
import { FilterByUUIDPipe } from '../common/pipes/filterByUUID.pipe';
import { FileUploaderComponent } from '../common/components/file-uploader/file-uploader.component';
import { ProductLaunchAndMarketingComponent } from './riverside-step-template/templates/product-launch-and-marketing/product-launch-and-marketing.component';
import { BlogComponent } from './blog/blog.component';
import { BlogLeftMenuComponent } from './left-menu/blog/blog.component';
import { EditBlogComponent } from './blog/edit/edit.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ListViewExecutiveDashboardComponent } from './dashboard/list-view-dashboard/list-view-executive-dashboard/list-view-executive-dashboard.component';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    RouterModule.forRoot([], { useHash: true }),
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule,
    AppRoutingModule,
    MatMenuModule,
    MatRadioModule,
    MatButtonModule,
    MatIconModule,
    MatTabsModule,
    MatSortModule,
    MatProgressBarModule,
    ModuleBuilderModule,
    CKEditorModule,
    E3CommonModule,
    VideoManagerModule,
    AudioRecorderModule,
    LineChartModule,
    BarChartModule,
    BubbleChartModule,
    MatInputModule,
    ReactiveFormsModule,
    ImageCropperModule,
    HotTableModule.forRoot(),
    FontAwesomeModule,
    MatCheckboxModule,
    ObserversModule,
    MatSelectModule,
    FontAwesomeModule,
    MatGridListModule,
    MatSelectModule,
    ObserversModule,
    DragDropModule,
    MatTooltipModule,
    UsersManagementModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatListModule,
    MatRippleModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatButtonToggleModule,
    NgxMatSelectSearchModule,
    MatDialogModule,
    MatTableModule,
    MatChipsModule,
    NgxChartsModule,
    MatSlideToggleModule,
    MatAutocompleteModule
  ],
  declarations: [
    ModuleViewerRootComponent,
    MainComponent,
    LeftMenuComponent,
    InboxLeftMenuComponent,
    AccountLeftMenuComponent,
    ContentComponent,
    ReadableTimePipe,
    CanModifyPipe,
    PersonaNamePipe,
    ReversePipe,
    CustomCurrencyPipe,
    FilterByUUIDPipe,
    CustomNumberPipe,
    IceComponent,
    RiversideStepTemplateComponent,
    RTemplateDirective,
    LetterImageComponent,
    UserComponent,
    LeftSidebarComponent,
    InboxComponent,
    E3TableComponent,
    RequestFeedbackComponent,
    ModuleNavComponent,
    BrainstormTemplateComponent,
    GenericTemplateComponent,
    PersonaBehaviorTemplateComponent,
    SegmentCriteriaDefineComponent,
    PersonaPictureTemplateComponent,
    PersonaPictureListComponent,
    PersonaComponent,
    FeedbackSourceComponent,
    DashboardComponent,
    MasterDashboardComponent,
    DashboardProgressBarComponent,
    ModuleLinkComponent,
    SelectionMatrixComponent,
    QuestionImageComponent,
    OrgSelectorComponent,
    TemplateHeadingComponent,
    ToggleModuleComponent,
    DueDateComponent,
    AssessmentComponent,
    AssessmentScoreComponent,
    AssessmentMenuComponent,
    AssessmentFinishComponent,
    ViewAssessmentsComponent,
    ChartLabelTooltipsDirective,
    LineChartValueLabelDirective,
    AssessmentChartComponent,
    ProfileComponent,
    ChangePasswordComponent,
    ProfilePictureInputComponent,
    ProfilePictureSelectorComponent,
    InputTextComponent,
    VideoComponent,
    IcpInputComponent,
    ModuleFeedbackComponent,
    ErrorMsgComponent,
    SpinnerComponent,
    SpreadsheetComponent,
    LoginComponent,
    ModuleProgressBarComponent,
    BuyerPersonasSelectorComponent,
    PreRequisiteModulesComponent,
    ModuleResultComponent,
    PersonaStrategyComponent,
    CampaignCalendarTemplateComponent,
    CampaignCalendarComponent,
    ModuleResultComponent,
    CheckboxSelectorComponent,
    SessionExpirationModalComponent,
    PersonaNamePipe,
    RadioButtonComponent,
    PersonaNamePipe,
    FileUploaderTemplateComponent,
    RACIComponent,
    ContentMapComponent,
    SessionExpirationModalComponent,
    PersonaRolesComponent,
    SessionExpirationModalComponent,
    PersonaNamePipe,
    IcpBreakoutsComponent,
    IcpTableComponent,
    ComposableTemplateComponent,
    TalentStrategyComponent,
    ListEntryComponent,
    RiversideStepTemplateComponent,
    ShowInputValueComponent,
    NegativeAnswersComponent,
    BuyerJourneyMapComponent,
    BuyerProcessMapComponent,
    CompetitionComparisonComponent,
    SelectBuyerPersonaComponent,
    ExpandDataComponent,
    ValuePropositionComponent,
    IcpSegmentSelectorComponent,
    BuyerPersonasSelectionMatrixComponent,
    MatrixEntryComponent,
    FormBuilderFieldComponent,
    ContentPipe,
    IncludesValuePipe,
    MarketingOrganizationDesignComponent,
    CountdownTimerComponent,
    BuyerInputListComponent,
    OpportunitySummaryComponent,
    QuizComponent,
    ParticipationLevelActivityComponent,
    CompensationAndQuotaComponent,
    SalesMarketingOpsReviewComponent,
    SalesLeadershipSellingTimeComponent,
    SituationalCoachingComponent,
    SalesBudgetStatusTemplateComponent,
    IcpAreasComponent,
    HtmlBreaklinesPipe,
    CustomerSuccessReviewComponent,
    BlockRepeaterComponent,
    ReviewWithSelectionComponent,
    CustomerSuccessReviewComponent,
    EvaluateComponent,
    EvaluateTableComponent,
    EvaluateSelectionFromTotalsComponent,
    PricingStrategyPlotComponent,
    MatrixCheckboxesComponent,
    ProspectingContactStrategyComponent,
    ChannelStrategySummaryComponent,
    KpiSummaryComponent,
    BlockRepeaterWrapperComponent,
    MatrixEntrySummaryComponent,
    BlockRepeaterResultComponent,
    SafeIDPipe,
    CalculatedCriteriaComponent,
    TerritoryDesignSummaryComponent,
    PlaysReviewComponent,
    ExpandDataResultComponent,
    VideoLibraryComponent,
    VideoModalComponent,
    FinalFeedbackComponent,
    CampaignNeedsTemplateComponent,
    AudienceExtensionTemplateComponent,
    ChartDataComponent,
    CustomBubbleChartComponent,
    CustomBubbleSeriesComponent,
    BubbleLabelComponent,
    ListViewDashboardComponent,
    ListViewSeDashboardComponent,
    ListViewFacDashboardComponent,
    DashboardHeaderComponent,
    ListViewBaseDashboardComponent,
    ProductLaunchAndMarketingComponent,
    BlogComponent,
    BlogLeftMenuComponent,
    EditBlogComponent,
    ListViewExecutiveDashboardComponent
  ],
  providers: [
    AssessmentService,
    ModuleService,
    ModuleNavService,
    SpreadsheetService,
    ModuleContentService,
    UserService,
    IceService,
    LeftMenuService,
    InboxService,
    CanModifyPipe,
    ContentPipe,
    BuyerPersonasService,
    ReversePipe,
    CustomCurrencyPipe,
    FilterByUUIDPipe,
    CustomNumberPipe,
    ConfirmationService,
    RestService,
    { provide: HTTP_INTERCEPTORS, useClass: CorsInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ExportKeyInterceptor, multi: true },
    { provide: ErrorHandler, useClass: RavenExceptionHandler }
  ],
  bootstrap: [ModuleViewerRootComponent]
})
export class ModuleViewerModule {}
