import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  OnDestroy
} from '@angular/core';
import { ModuleNavService } from 'src/app/common/services/module-nav.service';
import Message from '../../inbox/message.model';
import { IceService } from '../../ice/ice.service';
import { Subscription, of, Observable, forkJoin, Subject } from 'rxjs';
import { ModuleService } from 'src/app/common/services/module.service';
import { TemplateContentData } from '../../riverside-step-template/templates/template-data.class';
import { TemplateComponent } from '../../riverside-step-template/templates/template-base.class';
import { NavigationActions } from '../../../common/types/module-viewer';
import { map, switchMap, take, filter, takeUntil } from 'rxjs/operators';
import { SnackBarService } from '../../../common/services/snackbar.service';
import { MatDialog } from '@angular/material/dialog';
import {
  SurveyModalComponent,
  SurveyModalOptions
} from '../../../common/components/survey-modal/survey-modal.component';

@Component({
  selector: 'module-nav',
  templateUrl: './module-nav.component.html',
  styleUrls: ['./module-nav.component.scss']
})
export class ModuleNavComponent implements OnInit, OnChanges, OnDestroy {
  @Input() step: TemplateContentData;
  @Input() action: NavigationActions;
  @Input() subaction: NavigationActions;
  @Input() submitting: Subscription;
  @Input() is_done: boolean;
  @Input() is_subaction_done: boolean;
  @Input() hideActionButton: boolean;
  @Output() feedback: EventEmitter<Partial<Message>> = new EventEmitter();
  @Input() template: TemplateComponent;
  @Input() enableModuleSurvey = false;

  showPrevious = true;
  showNext = true;
  submittingSubaction: Subscription;
  unApproveSub: Subscription;
  actionButtonText: string;
  subactionButtonText: string;

  private componentDestroy$: Subject<void> = new Subject();

  constructor(
    private navService: ModuleNavService,
    private moduleService: ModuleService,
    private iceService: IceService,
    private snackBarService: SnackBarService,
    private modalService: MatDialog
  ) {}

  ngOnInit() {
    this.prepareActionFlag('is_done', this.action);
    this.prepareButtonTexts();
    this.subaction &&
      this.prepareActionFlag('is_subaction_done', this.subaction);

    // todo - what does it do?
    this.unApproveSub && this.unApproveSub.unsubscribe();
    this.unApproveSub = this.iceService.onUnapprove.subscribe(() => {
      this.markAsApproved(!!this.subaction, false);
      this.markAsDone(!!this.subaction, false);
    });
  }

  ngOnDestroy() {
    this.unApproveSub.unsubscribe();
    this.componentDestroy$.next();
    this.componentDestroy$.complete();
  }

  ngOnChanges() {
    this.prepareActionFlag('is_done', this.action);
    this.prepareButtonTexts();
  }

  prepareButtonTexts() {
    this.actionButtonText = this.getActionButtonText();
    this.subactionButtonText = this.getActionButtonText(
      'is_subaction_done',
      this.subaction
    );
  }

  prepareActionFlag(doneKey: string, action: NavigationActions) {
    const {
      data: { is_checked, is_approved, waiting_for_feedback, feedback_received }
    } = this.step;
    switch (action) {
      case 'feedback':
      case 'final_feedback':
        this[doneKey] = waiting_for_feedback;
        break;
      case 'provide_feedback':
      case 'provide_final_feedback':
        this[doneKey] = feedback_received;
        break;
      case 'approve':
        this[doneKey] = is_approved;
        break;
      default:
        this[doneKey] = is_checked;
    }
  }

  next() {
    this.navService.nextStep();
  }

  previous() {
    this.navService.previousStep();
  }

  feedbackClicked() {
    this.markAsDone(false, null, true);
  }

  markAsDone(isSubaction = false, state: boolean = null, isFeedback = false) {
    const key = isSubaction ? 'is_subaction_done' : 'is_done';
    const newState = state !== null ? state : !this[key];
    const step = this.step.data;

    this[
      isSubaction ? 'submittingSubaction' : 'submitting'
    ] = this.validateTemplate()
      .pipe(
        switchMap(isValid =>
          isValid
            ? this.moduleService.markAsDone(
                step.module_id,
                step.org_id,
                step.step_id,
                newState
              )
            : of(null)
        ),
        filter(res => !!res)
      )
      .subscribe(res => {
        if (newState) {
          this.navService.nextStep();
        }

        if (isFeedback) {
          this.feedback.emit();
          this.is_done = true;
        }

        this.moduleService.moduleChanged$.next(!newState);
        this[key] = newState;
      });
  }

  markAsApproved(isSubaction = false, state: boolean = null) {
    const key = isSubaction ? 'is_subaction_done' : 'is_done';
    const newState = state !== null ? state : !this[key];
    const step = this.step.data;

    this[
      isSubaction ? 'submittingSubaction' : 'submitting'
    ] = this.moduleService
      .markAsApproved(
        step.module_id,
        step.org_id,
        step.step_id,
        newState,
        isSubaction
      )
      .subscribe((response: number[]) => {
        if (newState) {
          this.iceService.onApprove.emit();
          this.navService.nextStep();
        }

        this.moduleService.moduleChanged$.next(!newState);
        this[key] = newState;
      });
  }

  buttonClicked(action?: NavigationActions, isSubaction = false) {
    action = action || this.action;

    switch (action) {
      case 'feedback':
        if (this.enableModuleSurvey) {
          this.openModuleRateSurvey().subscribe(() => this.feedbackClicked());
        } else {
          this.feedbackClicked();
        }
        break;
      case 'provide_feedback':
      case 'final_feedback':
      case 'provide_final_feedback':
        this.feedbackClicked();
        break;
      case 'approve':
        this.markAsApproved(isSubaction);
        break;
      default:
        this.markAsDone(isSubaction);
    }
  }

  getActionButtonText(doneKey = 'is_done', action?: NavigationActions) {
    action = action || this.action;
    switch (action) {
      case 'feedback':
        return this[doneKey] ? 'Feedback Requested' : 'Request Feedback';
      case 'provide_feedback':
      case 'provide_final_feedback':
        return this[doneKey] ? 'Feedback Provided' : 'Provide Feedback';
      case 'final_feedback':
        return this[doneKey] ? 'Submitted' : 'Submit';
      case 'approve':
        return this[doneKey] ? 'Approved' : 'Approve';
      default:
        return this[doneKey] ? 'Done' : 'Mark as done';
    }
  }

  print() {
    this.template.print();
  }

  private validateTemplate(): Observable<boolean> {
    return this.template.validate().pipe(
      take(1),
      map(isValid => {
        if (!isValid) {
          this.snackBarService.error(
            this.template.getValidationErrorMessage(),
            5000
          );
        }

        return isValid;
      })
    );
  }

  private openModuleRateSurvey(): Observable<boolean> {
    return forkJoin([
      this.navService.currentOrganizationData$.pipe(take(1)),
      this.navService.moduleDataReplay$.pipe(take(1))
    ]).pipe(
      switchMap(([curOrg, curModule]) => {
        const data: SurveyModalOptions = {
          moduleName: curModule.name,
          companyName: curOrg.name,
          userName: this.step.me.name
        };

        return this.modalService
          .open(SurveyModalComponent, { data })
          .afterClosed()
          .pipe(takeUntil(this.componentDestroy$));
      })
    );
  }
}
