import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByUUID',
  pure: true
})
export class FilterByUUIDPipe implements PipeTransform {
  transform<T extends { uuid: string }>(
    values: Array<T>,
    uuid: string,
    rowIndex = 0
  ): T {
    const byUUID = values?.find(value => value.uuid === uuid);
    const byIndex = values[rowIndex];

    return uuid && byUUID ? byUUID : byIndex;
  }
}
