import { NgModule, Injectable } from '@angular/core';
import {
  RouterModule,
  Routes,
  CanDeactivate,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';

import { ModuleEditorComponent } from './module-editor/module-editor.component';
import { ModuleSelectorComponent } from './module-selector/module-selector.component';
import { MainComponent } from '../module-viewer/main/main.component';
import { Observable, of } from 'rxjs';
import { ConfirmationService } from '../common/services/confirmation.service';
import { UserService } from '../common/services/user.service';
import { defaultIfEmpty, switchMap } from 'rxjs/operators';
import User from '../common/interfaces/user.model';
import { AccountProfile } from '../common/interfaces/account.interface';

@Injectable()
export class ConfirmExitGuard implements CanDeactivate<ModuleEditorComponent> {
  constructor(private confirmationService: ConfirmationService) {}

  canDeactivate(target: ModuleEditorComponent): Observable<boolean> | boolean {
    if (target.hasChanges && target.hasChanges()) {
      const modal = this.confirmationService.simpleDialog({
        text:
          'Leaving the module builder will discard any unsaved changes. Are you sure?'
      });

      return modal.pipe(defaultIfEmpty(false));
    }

    return true;
  }
}

@Injectable()
export class BuilderAccessGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return (
      this.userService?.me?.permissions.riversideModuleEditor ||
      this.userService.getAccount().pipe(
        switchMap((account: AccountProfile) => {
          if (account) {
            return of((account as User).permissions.riversideModuleEditor);
          }

          return of(false);
        })
      )
    );
  }
}

const routes: Routes = [
  {
    path: 'builder',
    canDeactivate: [ConfirmExitGuard],
    canActivate: [BuilderAccessGuard],
    component: MainComponent,
    children: [
      {
        path: '',
        component: ModuleSelectorComponent,
        children: [
          {
            path: ':id',
            canDeactivate: [ConfirmExitGuard],
            component: ModuleEditorComponent
          },
          {
            path: ':id/:stepid',
            canDeactivate: [ConfirmExitGuard],
            component: ModuleEditorComponent
          }
        ]
      }
    ]
  }
];

@NgModule({
  // imports: [RouterModule.forRoot(routes, {useHash: true, enableTracing: true, urlUpdateStrategy: 'deferred'})],
  imports: [RouterModule.forChild(routes)],
  providers: [ConfirmExitGuard, BuilderAccessGuard],
  exports: [RouterModule]
})
export class AppRoutingModule {}
