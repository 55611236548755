import { ErrorHandler, Injectable } from '@angular/core';
import * as Raven from 'raven-js';

Raven.config('https://9054697f48ea4bb49aad626e49ca847c@sentry.io/3816873', {
  whitelistUrls: [/.*seagage\.com.*/]
}).install();

@Injectable()
export class RavenExceptionHandler implements ErrorHandler {
  handleError(error: any): void {
    console.error(error);
    Raven.captureException(error.originalException);
  }
}
