import { Component, forwardRef } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import txt from '!!raw-loader!./index.ts';
import {
  TerritoryAssignment,
  TerritoryDesignSummaryTemplateData,
  RepresentativeInput
} from './index';
import { TemplateInput } from '../../../../common/interfaces/module.interface';
import { ExpandedData } from '../expand-data';
import { environment } from '../../../../../environments/environment';
import { isEmptyValue } from '../../../../common/utils/helpers';

@Component({
  selector: 'app-territory-design-summary',
  templateUrl: './territory-design-summary.component.html',
  styleUrls: ['./territory-design-summary.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => TerritoryDesignSummaryComponent)
    }
  ]
})
export class TerritoryDesignSummaryComponent extends TemplateComponent {
  params = txt;
  contentData: TerritoryDesignSummaryTemplateData['template_params_json'];
  prefix = 'territory_design_summary_1';

  territories: TerritoryAssignment[] = [];
  icpSegments: string[] = [];
  allowDynamicApi = environment.allowDynamicApi;
  showTotalPotential = true;

  private inputKey: string;

  getDescription(): string {
    return '';
  }

  getName(): string {
    return 'Territory Design Summary';
  }

  getGroup(): string {
    return 'Outputs';
  }

  get input(): TemplateInput {
    return this.inputs[this.inputKey];
  }

  init(): void {
    this.contentData = this.data.data
      .template_params_json as TerritoryDesignSummaryTemplateData['template_params_json'];
    this.inputKey = this.contentData.input_sufix
      ? `${this.prefix}_${this.contentData.input_sufix}`
      : this.prefix;
    this.parseInputData();
  }

  onChangeRepresentatives(assignment: TerritoryAssignment): void {
    this.territories = this.territories.map(territory => ({
      ...territory,
      employee:
        territory.podName === assignment.podName && !!territory.podName
          ? assignment.employee
          : territory.employee
    }));
    this.updateRepresentatives();
  }

  private updateRepresentatives(): void {
    const representativeContent: RepresentativeInput[] = this.territories.map(
      territory => ({
        territory: territory.territory,
        employee: territory.employee,
        podName: territory.podName
      })
    );
    const changedContentString = JSON.stringify(representativeContent);
    if (changedContentString !== this.input.content) {
      this.input.content = changedContentString;
      this.contentChanged(this.input);
    }
  }

  private parseInputData(): void {
    const input: TemplateInput = this.inputs[this.contentData.input_to_expand];
    const expandedData: ExpandedData = JSON.parse(
      input?.content || null
    ) as ExpandedData;
    const representativeContent = JSON.parse(
      this.input?.content || null
    ) as RepresentativeInput[];
    if (expandedData) {
      const podEmployeeEntries: {
        [podName: string]: string;
      } = representativeContent?.reduce((accum, rep) => {
        if (rep.podName && !accum[rep.podName]) {
          accum[rep.podName] = rep.employee;
        }

        return accum;
      }, {});
      const podNameColumnName = Object.keys(
        expandedData || {}
      ).find(columnName => columnName.match(/(POD)/g));
      const potentialColumnName = Object.keys(
        expandedData || {}
      ).find(columnName => columnName.match(/(total[\s]*potential)/gi));
      this.showTotalPotential = !isEmptyValue(potentialColumnName);
      this.territories =
        expandedData.Territory?.reduce(
          (accum: TerritoryAssignment[], data, index) => {
            const territory = this.textContent(String(data.value || ''));
            const podName = this.textContent(
              String(expandedData[podNameColumnName]?.[index]?.value || '')
            );
            const totalPotential = Number(
              expandedData[potentialColumnName]?.[index]?.value || 0
            );
            const employee =
              representativeContent?.find(emp => emp.territory === territory)
                ?.employee || '';
            if (territory) {
              accum.push({ territory, podName, totalPotential, employee });
            }

            return accum;
          },
          []
        ).map(territory => ({
          ...territory,
          employee:
            podEmployeeEntries?.[territory.podName] || territory.employee
        })) || [];
      this.updateRepresentatives();
    }
  }
}
