import { Component, forwardRef } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import { ShowInputValueTemplateData } from '.';
import txt from '!!raw-loader!./index.ts';

@Component({
  selector: 'show-input-value',
  templateUrl: 'show-input-value.component.html',
  styleUrls: ['./show-input-value.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => ShowInputValueComponent)
    }
  ]
})
export class ShowInputValueComponent extends TemplateComponent {
  params = txt;

  contentData: ShowInputValueTemplateData['template_params_json'];
  previousStepInputContent: string;

  getDescription() {
    return '';
  }

  getName() {
    return 'Show Input Value';
  }

  getGroup(): string {
    return 'Generic';
  }

  protected init() {
    this.contentData = this.data.data
      .template_params_json as ShowInputValueTemplateData['template_params_json'];

    const inputID = this.contentData.input_id;

    const inputInstance = this.getInput(inputID);
    this.previousStepInputContent = inputInstance
      ? inputInstance.content
      : undefined;
  }
}
