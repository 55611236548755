import { Component, forwardRef } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import txt from '!!raw-loader!./index.ts';
import {
  AudienceApiEndpointsData,
  AudienceExtensionContent,
  AudienceExtensionTemplateParams,
  AudienceExtensionType,
  AudienceInfo
} from './index';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { BlockRepeaterContent } from '../block-repeater';
import { updateBlockRepeaterFormat } from '../block-repeater/helpers';
import { BuyerPersonaDictionary } from '../../../../common/interfaces/buyer-persona.interface';

@Component({
  selector: 'app-audience-extension-template',
  templateUrl: './audience-extension-template.component.html',
  styleUrls: ['./audience-extension-template.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => AudienceExtensionTemplateComponent)
    }
  ]
})
export class AudienceExtensionTemplateComponent extends TemplateComponent {
  params = txt;
  contentData: AudienceExtensionTemplateParams;
  prefix = 'audience_extension_1';

  apiData: AudienceApiEndpointsData;
  previousData: AudienceExtensionContent;
  extensionData: AudienceExtensionContent;
  buyerPersonas: BuyerPersonaDictionary;
  hasError = false;
  errorMessage = 'Something went wrong. Please contact administrator!';

  private inputKey: string;
  private readonly apiEndpoints: { [name in AudienceExtensionType]: string } = {
    value_propositions: 'value-propositions/statements',
    challenges: 'buyer-personas/challenges'
  };

  private get input() {
    return this.inputs[this.inputKey];
  }

  private get previousAudienceInput() {
    return this.inputs[this.contentData.audiences_input_name];
  }

  get typeSelect(): AudienceExtensionType {
    return this.contentData.type_select;
  }

  getDescription() {
    return '';
  }

  getName() {
    return 'Audience Extension';
  }

  getGroup() {
    return 'Module';
  }

  init() {
    super.init();
    this.contentData = this.data.data
      .template_params_json as AudienceExtensionTemplateParams;
    this.inputKey = this.contentData.input_sufix
      ? `${this.prefix}_${this.contentData.input_sufix}`
      : this.prefix;

    this.prepareAdditionalRequests().subscribe(apiData => {
      this.apiData = apiData;
      this.preparePreviousData();
      if (!this.hasError) {
        this.prepareExtensionData();
      }
    });
  }

  audienceTrack(idx: number, a: AudienceInfo) {
    return a.id;
  }

  saveAudienceInput(): void {
    const newContentString = JSON.stringify(this.extensionData);
    if (this.input.content !== newContentString) {
      this.input.content = newContentString;
      this.contentChanged(this.input);
    }
  }

  private prepareAdditionalRequests(): Observable<AudienceApiEndpointsData> {
    return this.buyerPersonasList$.pipe(
      tap(
        personas =>
          (this.buyerPersonas = personas.reduce(
            (accum: BuyerPersonaDictionary, persona) => {
              accum[persona.uuid] = persona;

              return accum;
            },
            {}
          ))
      ),
      switchMap(() =>
        this.navService.organization$.pipe(
          switchMap(orgId =>
            this.apiEndpoints[this.typeSelect]
              ? this.moduleService
                  .getAPIData(orgId, this.apiEndpoints[this.typeSelect])
                  .pipe(
                    catchError(() => of(null)),
                    map(data => [this.typeSelect, data])
                  )
              : of(null)
          ),
          take(1),
          map(
            (data: [AudienceExtensionType, any]): AudienceApiEndpointsData => {
              switch (data?.[0]) {
                case 'value_propositions':
                  return {
                    valuePropositions: data[1],
                    challenges: null
                  };
                case 'challenges':
                  return {
                    valuePropositions: null,
                    challenges: data[1]
                  };
                default:
                  return null;
              }
            }
          )
        )
      )
    );
  }

  private preparePreviousData(): void {
    const previousContent: BlockRepeaterContent = updateBlockRepeaterFormat(
      JSON.parse(this.previousAudienceInput?.content || null)
    );
    const audienceNameInfo = {
      id: this.contentData.audience_name_step_id,
      inputName: this.contentData.audience_name_step_input_name
    } as const;
    const audiencePersonasInfo = {
      id: this.contentData.selected_personas_step_id,
      inputName: this.contentData.selected_personas_step_input_name
    } as const;
    if (
      !this.previousAudienceInput ||
      !(audienceNameInfo.id && audienceNameInfo.inputName) ||
      !(audiencePersonasInfo.id && audiencePersonasInfo.inputName)
    ) {
      this.hasError = true;
      console.error(
        'Configuration error. Previous step input and/or paths are not set!'
      );

      return;
    }
    this.previousData = previousContent?.blocks?.reduce(
      (accum: AudienceExtensionContent, block) => {
        const audienceId = block.id;
        const audienceName =
          block.data?.[audienceNameInfo.id]?.[audienceNameInfo.inputName]
            ?.content;
        const selectedPersonas: string[] = JSON.parse(
          block.data?.[audiencePersonasInfo.id]?.[
            audiencePersonasInfo.inputName
          ]?.content || null
        );
        const audience: AudienceInfo = {
          id: audienceId,
          name: audienceName,
          personas: selectedPersonas,
          staticInfo: {
            valuePropositions: {
              value: selectedPersonas
                .map(persona =>
                  this.apiData?.valuePropositions
                    ?.filter(item =>
                      item.personaUUID
                        ? item.personaUUID === String(persona)
                        : item.personaIndex ===
                          this.buyerPersonas[persona]?.index
                    )
                    .map(item => item.option)
                )
                .flat()
                .filter(Boolean)
                .join('<br>')
            },
            challenges: {
              value: selectedPersonas
                .map(persona =>
                  this.apiData?.challenges
                    ?.filter(item =>
                      item.personaUUID
                        ? item.personaUUID === String(persona)
                        : item.personaIndex ===
                          this.buyerPersonas[persona]?.index
                    )
                    .map(item => item.option)
                )
                .flat()
                .filter(Boolean)
                .join('<br>')
            }
          },
          newInfo: {
            valuePropositions: { value: null },
            challenges: { value: null }
          }
        };
        accum.audiences.push(audience);

        return accum;
      },
      { audiences: [] }
    );
    if (!this.previousData?.audiences) {
      this.hasError = true;
      this.errorMessage = 'Please return to step 3 and fill in audiences';
    }
  }

  private prepareExtensionData(): void {
    const content: AudienceExtensionContent = JSON.parse(
      this.input?.content || null
    );
    this.extensionData = this.previousData?.audiences.reduce(
      (accum: AudienceExtensionContent, updatedAudience) => {
        const audience = { ...updatedAudience };
        const foundAudience = content?.audiences?.find(
          item => item.id === audience.id
        );
        if (!!foundAudience) {
          audience.newInfo = {
            valuePropositions: foundAudience.newInfo.valuePropositions,
            challenges: foundAudience.newInfo.challenges
          };
        }
        accum.audiences.push(audience);

        return accum;
      },
      { audiences: [] }
    );
  }
}
