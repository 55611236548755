import { Injectable, NgModule } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterModule,
  RouterStateSnapshot,
  Routes,
  UrlTree
} from '@angular/router';
import { MainComponent } from '../module-viewer/main/main.component';
import { MainComponent as MainUsersManagementComponent } from './main/main.component';
import { Observable, of } from 'rxjs';
import { UserService } from '../common/services/user.service';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class UsersManagementGuard implements CanActivate {
  constructor(private userService: UserService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.userService.me) {
      return this.userService.me.permissions.riversideManageRMCFUsers;
    } else {
      return this.userService.getAccount().pipe(
        catchError(err => of(false)),
        map(user => {
          this.userService.setMeFromData(user);

          return this.userService.me.permissions.riversideManageRMCFUsers;
        })
      );
    }
  }
}

const routes: Routes = [
  {
    path: 'users-management',
    component: MainComponent,
    canActivate: [UsersManagementGuard],
    children: [
      {
        path: '',
        component: MainUsersManagementComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [UsersManagementGuard]
})
export class UsersManagementRoutingModule {}
