import { Component, forwardRef } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import { CampaignCalendarTemplateData } from '.';
import {
  Campaign,
  CampaignCustomField,
  CampaignTemplateErrors,
  defaultKpiValue
} from './campaign-calendar';
import txt from '!!raw-loader!./index.ts';
import { catchError, take } from 'rxjs/operators';
import { BuyerPersona } from '../../../../common/interfaces/buyer-persona.interface';
import { of } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { Validation } from '../../../../common/validator.class';
import { TemplateInput } from '../../../../common/interfaces/module.interface';

@Component({
  selector: 'app-campaign-calendar-template',
  templateUrl: './campaign-calendar-template.component.html',
  styleUrls: ['./campaign-calendar-template.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => CampaignCalendarTemplateComponent)
    }
  ]
})
export class CampaignCalendarTemplateComponent extends TemplateComponent {
  params = txt;
  contentData: CampaignCalendarTemplateData['template_params_json'];

  itemLabel = 'Campaign';
  customFieldsArray: CampaignCustomField[];
  parsedCampaigns: Campaign[];
  personas: BuyerPersona[];
  validators: Validation[];
  campaignValidationErrors: CampaignTemplateErrors;

  readonly defaultEmptyCampaignsLength = 5;

  get input() {
    return this.getInput('campaign_calendar_1');
  }

  set campaigns(campaigns: Campaign[]) {
    this.parsedCampaigns = campaigns;
    this.input.content = JSON.stringify(campaigns);
    this.contentChanged(this.input, this.validators);
  }

  get campaigns(): Campaign[] {
    return this.parsedCampaigns;
  }

  getDescription() {
    return '';
  }

  getName() {
    return 'Campaign Calendar';
  }

  getGroup(): string {
    return 'Module';
  }

  init(): void {
    this.contentData = this.data.data
      .template_params_json as CampaignCalendarTemplateData['template_params_json'];
    if (this.contentData.item_label) {
      this.itemLabel = this.textContent(this.contentData.item_label);
    }
    if (
      this.contentData.enable_custom_fields &&
      this.contentData.inputs_for_custom_fields
    ) {
      this.prepareCustomFields();
    }
    this.prepareCampaigns();
    this.validators = this.getValidators();
  }

  validateInput(inp: TemplateInput, validators: Validation[] = []): boolean {
    this.prepareValidationCampaignErrors();

    return super.validateInput(inp, validators);
  }

  validate() {
    return of(this.validateInput(this.input, this.validators));
  }

  private parseCampaignsFromInput(): Campaign[] {
    let campaigns = JSON.parse(this.input.getValue() || '[]') as Campaign[];
    if (this.personas) {
      campaigns = campaigns.map(campaign => {
        let personaInfo = campaign.persona as string | (string | number)[];
        if (typeof personaInfo === 'string') {
          personaInfo = this.personas
            .filter(buyerPersona =>
              (personaInfo as string).includes(buyerPersona.name)
            )
            .map(
              buyerPersona => buyerPersona.uuid || String(buyerPersona.index)
            );
        }
        personaInfo = personaInfo
          .map(item =>
            typeof item === 'number'
              ? this.personas.find(persona => persona.index === item)?.uuid ||
                null
              : item
          )
          .filter(Boolean);

        return {
          ...campaign,
          persona: personaInfo as string[]
        };
      });
    }

    return campaigns;
  }

  private prepareCampaigns(): void {
    this.buyerPersonasService
      .getBuyerPersonasData()
      .pipe(
        take(1),
        catchError(() => of(null))
      )
      .subscribe((res: BuyerPersona[]) => {
        this.personas = res;
        this.parsedCampaigns = this.parseCampaignsFromInput();
        if (!this.parsedCampaigns.length) {
          this.parsedCampaigns = new Array(this.defaultEmptyCampaignsLength)
            .fill(null)
            .map(() => ({
              id: uuidv4(),
              theme: '',
              kpi: this.contentData.is_campaign_module ? defaultKpiValue : null,
              persona: [],
              customFields: this.customFieldsArray?.reduce(
                (accum, customField) => {
                  accum[customField.name] = '';

                  return accum;
                },
                {}
              )
            }));
          this.campaigns = this.parsedCampaigns;
        }
      });
  }

  private prepareCustomFields(): void {
    const customFields: CampaignCustomField[] = this.contentData.custom_fields?.map(
      field => ({ name: field.name, type: field.fields_type_select })
    );
    const inputCustomFields: CampaignCustomField[] = this.contentData.inputs_for_custom_fields
      .map(inputName => {
        const input = this.getInput(inputName.from_input);
        const content = input?.content ? JSON.parse(input.content) : null;
        if (content) {
          if (input.element_key.includes('list_entry')) {
            return Object.keys(content)
              .map(key => ({
                name: content[key].option,
                type: inputName.fields_type_select
              }))
              .filter(field => !!field.name);
          }
        }

        return null;
      })
      .flat()
      .filter(Boolean);
    this.customFieldsArray = [customFields, inputCustomFields]
      .flat()
      .filter(Boolean);
  }

  private getValidators(): Validation[] {
    const minNumberOfCampaignsFunc = (value: string) => {
      const content: Campaign[] = JSON.parse(value || null);

      return !!content?.length;
    };
    const minNumberOfCampaignsMessage =
      'Please add at least one campaign above';
    const minNumberOfCampaignsValidator = new Validation(
      minNumberOfCampaignsFunc,
      minNumberOfCampaignsMessage
    );
    const requiredTitleFunc = (value: string) => {
      const content: Campaign[] = JSON.parse(value || null);

      return content?.every(campaign => !!campaign?.theme);
    };
    const requiredTitleMessage = 'Please fill in the fields marked above';
    const requiredTitleValidator = new Validation(
      requiredTitleFunc,
      requiredTitleMessage
    );

    return [minNumberOfCampaignsValidator, requiredTitleValidator];
  }

  private prepareValidationCampaignErrors(): void {
    const campaignErrors = this.campaigns?.map(campaign => ({
      theme: !campaign.theme
    }));
    this.campaignValidationErrors = {
      isEmpty: !this.campaigns?.length,
      campaigns: campaignErrors
    };
  }
}
