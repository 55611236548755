import { TemplateContentDataBase } from '../template.interface';

export interface ProductLaunchAndMarketingTemplateData
  extends TemplateContentDataBase {
  template_params_json: {
    description: string;
    title: string;
    api_endpoint: string;
  };
}

export interface ProductLaunchAndMarketingData {
  tabTitle: string;
  metric_details: ProductLaunchAndMarketingMetricDetails[];
}

export interface ProductLaunchAndMarketingMetricDetails {
  title: string;
  metrics: ProductLaunchAndMarketingMetrics[];
}

export interface ProductLaunchAndMarketingMetrics {
  name: string;
  details: ProductLaunchAndMarketingDetails[];
  tiers: ProductLaunchAndMarketingTiers[];
}

export interface ProductLaunchAndMarketingDetails {
  title: string;
  value: string | number;
  type: string;
}

export interface ProductLaunchAndMarketingTiers {
  title: string;
  incrementalIncentive: number;
}

export const ProductLaunchAndMarketingInitialDetails: ProductLaunchAndMarketingDetails[] = [
  {
    title: 'Weight',
    value: null,
    type: 'number'
  },
  {
    title: 'Quota',
    value: null,
    type: 'currency'
  },
  {
    title: 'Target Incentive',
    value: null,
    type: 'currency'
  },
  {
    title: 'Threshold',
    value: null,
    type: 'currency'
  },
  {
    title: 'Frequency',
    value: null,
    type: 'dropdown'
  }
];
