import { TemplateContentDataBase } from '../template.interface';
import { environment } from '../../../../../environments/environment';
import { BuyerPersona } from '../../../../common/interfaces/buyer-persona.interface';

export interface BuyerProcessMapTemplateData extends TemplateContentDataBase {
  template_params_json: {
    description: string;
    title: string;
  };
}

export interface PersonaRole {
  [personaIndex: number]: {
    personaRole: string;
    personaRef: BuyerPersona;
  };
}

export interface PersonaInfo {
  [stepName: string]: {
    personas: PersonaRole;
    descriptions: {
      [title: string]: string[];
    };
  };
}

export interface BlockRepeaterPersonaRoleInfo {
  [title: string]: {
    inputIndex: number;
    inputName: string;
  };
}

export interface PersonaRoleInputInfo {
  name: string;
  blockInfo: BlockRepeaterPersonaRoleInfo;
}

export const stepInputInfo: { [stepName: string]: PersonaRoleInputInfo } = {
  recognizes: {
    name: 'block_repeater_1_recognizes_need',
    blockInfo: {
      needs: {
        inputIndex: 21012,
        inputName: 'list_entry_1_recognizes_need_needs'
      },
      activities: {
        inputIndex: 21013,
        inputName: 'list_entry_1_recognizes_need_activities'
      },
      content_preferences: {
        inputIndex: 21014,
        inputName: 'list_entry_1_recognizes_need_content_preference'
      }
    }
  },
  need: {
    name: 'block_repeater_1_defines_need',
    blockInfo: {
      needs: {
        inputIndex: 21020,
        inputName: 'list_entry_1_defines_need_need'
      },
      activities: {
        inputIndex: 21021,
        inputName: 'list_entry_1_defines_need_activities'
      },
      content_preferences: {
        inputIndex: 20022,
        inputName: 'list_entry_1_defines_need_content_preference'
      }
    }
  },
  options: {
    name: 'block_repeater_1_evaluates_options',
    blockInfo: {
      needs: {
        inputIndex: 20023,
        inputName: 'list_entry_1_evaluates_options_need'
      },
      activities: {
        inputIndex: 20024,
        inputName: 'list_entry_1_evaluates_options_activities'
      },
      content_preferences: {
        inputIndex: 20025,
        inputName: 'list_entry_1_evaluates_options_content_preference'
      }
    }
  },
  risk: {
    name: 'block_repeater_1_mitigates_risk',
    blockInfo: {
      needs: {
        inputIndex: 20026,
        inputName: 'list_entry_1_mitigates_risk_need'
      },
      activities: {
        inputIndex: 20027,
        inputName: 'list_entry_1_mitigates_risk_activities'
      },
      content_preferences: {
        inputIndex: 20028,
        inputName: 'list_entry_1_mitigates_risk_content_preference'
      }
    }
  },
  solution: {
    name: 'block_repeater_1_selects_solution',
    blockInfo: {
      needs: {
        inputIndex: 20029,
        inputName: 'list_entry_1_selects_solution_need'
      },
      activities: {
        inputIndex: 20030,
        inputName: 'list_entry_1_selects_solution_activities'
      },
      content_preferences: {
        inputIndex: 20031,
        inputName: 'list_entry_1_selects_solution_content_preference'
      }
    }
  },
  realizes: {
    name: 'block_repeater_1_realizes_value',
    blockInfo: {
      needs: {
        inputIndex: 20032,
        inputName: 'list_entry_1_realizes_value_need'
      },
      activities: {
        inputIndex: 20033,
        inputName: 'list_entry_1_realizes_value_activities'
      },
      content_preferences: {
        inputIndex: 20034,
        inputName: 'list_entry_1_realizes_value_content_preference'
      }
    }
  }
};

export type DescriptionTitle = 'needs' | 'activities' | 'content_preferences';

export const descriptionFieldInputInfo: {
  [title: string]: { inputIndex: number; inputName: string };
} = {
  needs: {
    inputIndex: 21012,
    inputName: 'list_entry_1_recognizes_need_needs'
  },
  activities: {
    inputIndex: 21013,
    inputName: 'list_entry_1_recognizes_need_activities'
  },
  content_preferences: {
    inputIndex: 21014,
    inputName: 'list_entry_1_recognizes_need_content_preference'
  }
};

export const roles: { [key: number]: { title: string; image: string } } = {
  0: {
    title: 'Driver',
    image:
      `${environment.apiRoot}/api/resources?s3_key=Modules+content/` +
      'Buyer+Process+Map/Wireframe+Graphics/Driver.svg' +
      '#svgView(viewBox(460, 185, 35, 35))'
  },
  1: {
    title: 'Participant',
    image:
      `${environment.apiRoot}/api/resources?s3_key=Modules+content/` +
      'Buyer+Process+Map/Wireframe+Graphics/Participant.svg' +
      '#svgView(viewBox(430, 185, 35, 35))'
  },
  2: {
    title: 'Gatekeeper',
    image:
      `${environment.apiRoot}/api/resources?s3_key=Modules+content/` +
      'Buyer+Process+Map/Wireframe+Graphics/Gatekeeper.svg' +
      '#svgView(viewBox(462, 185, 35, 35))'
  },
  3: {
    title: 'Not Involved',
    image:
      `${environment.apiRoot}/api/resources?s3_key=Modules+content/` +
      'Buyer+Process+Map/Wireframe+Graphics/Not_Involved.svg' +
      '#svgView(viewBox(443, 187, 35, 35))'
  }
};

export const titles = [
  { step: 'recognizes', title: 'RECOGNIZES NEED' },
  { step: 'need', title: 'DEFINES NEED' },
  { step: 'options', title: 'EVALUATES OPTIONS' },
  { step: 'risk', title: 'MITIGATES RISK' },
  { step: 'solution', title: 'SELECTS SOLUTION' },
  { step: 'realizes', title: 'REALIZES VALUE' }
];

export const objectives: { [step: string]: string[] } = {
  recognizes: [
    'Assess and understand the issue or opportunity (define the problem)',
    'Outline the challenge or scope',
    'Identify and solicit peer examples and best practices'
  ],
  need: [
    'Define “What’s possible?”',
    'Identify Potential Options and Value Propositions',
    'Gain internal consensus/support to act',
    'Determine Certification Recognition and Authorization'
  ],
  options: [
    'Assess provider experience and viability',
    'Validate solution fit and alignment with existing programs',
    'Modeling possible outcomes',
    'Vendor solution proof points (case studies, metric improvements, references, pilots)',
    'Understanding of what implementation looks like'
  ],
  risk: [
    'Detailed requirements, implementation plan and timeline',
    'Internal and external implementation resources',
    'Internal communication/awareness of project status',
    'Internal alignment on measuring project success',
    'Training',
    'Over communication'
  ],
  solution: [
    'Clear understanding of how the solution is utilized and the ROI',
    'Feedback from users',
    'Implementation evaluation and awareness of any loose ends',
    'Evaluation and communication of adoption and success',
    'Best practices for usage'
  ],
  realizes: [
    'Requesting feedback from users for improvement.',
    'Review of metrics to confirm ROI.',
    'Ensure consistent use of the solution.'
  ]
};

export const questions: { [step: string]: string[] } = {
  recognizes: [
    'How big/bad is my problem?',
    'How will this problem affect my business?',
    'How do I assess the size and reach of my problem?',
    'Do I need to do anything to solve my problem?',
    'How urgent is my problem?',
    'Are any of my peers experiencing this problem too?',
    'What do the analysts/experts say about my problem?',
    'Where can I learn more about the problem?',
    'Where can I learn more about possible solutions?',
    'Is there anyone else in my company that sees this problem?',
    'Is this a quick fix that I can handle on my own?',
    'What are my peers doing about similar problems?',
    'What are the market trends?',
    'Have anyone actually solved this issue?',
    'Does anyone else in my company know about this problem?',
    'Who else can I get to help to uncover the issue?',
    'Can I see the product?  I want to kick the tires and see cool stuff.'
  ],
  need: [
    'Who needs to buy-in to the problem definition?',
    'What is the problem?',
    'Who surfaced the problem?',
    'Is the problem real?',
    'How big is the problem?',
    'What is the root cause of the problem?',
    'What are the consequences of the problem if unsolved?',
    'Who is the problem impacting?',
    'Who else is experiencing this problem?',
    'Are my peers talking about this problem too?',
    'How do I educate myself to understand this problem better?',
    'What are the best practices related to solving this problem?',
    'I\'m curious, what products exist to address this?',
    'Can I see how a solution works at a high level?',
    'What is my true problem and how should I solve it?',
    'Have you helped anyone else with this issue?',
    'Should I do this internally or outsource?',
    'Can your solution work with internal capabilities?',
    'How hard is it to customize?',
    'How quickly can this be implemented?',
    'Who can help me establish my broader needs and criteria?',
    'Does your offering fit our needs/requirements?',
    'How effectively does your solution fit the needs/requirements?',
    'What is the best evaluation process?',
    'What are the consequences if I decided not to take action?',
    'What are all my projects that are competing for budget/resources?',
    'Who is someone I can trust to advise me on my decision process?',
    'Is there financial means (or budget) in place for potential solutions?'
  ],
  options: [
    'Can I do nothing and it will fix itself?',
    'If I do nothing, what are the negative impacts?',
    'What are all of my solution alternatives?',
    'If I don\'t solve this correctly, how will this affect me & my team?',
    'Can I solve this quickly?',
    'Who will own the solution?',
    'What are all the "must have" solution requirements?',
    'Can I see a demonstration of your solution?',
    'Where have you done this successfully before?',
    'Can we afford to do nothing right now?',
    'Do we have the internal capability & capacity  to do it right now?',
    'How will things integrate?',
    'Can I do a deep dive into product functionality?',
    'How hard is it to customize?',
    'How many of our requirements will this meet?',
    'What is a fair price / what is the ROI?',
    'Have we considered all of the options?',
    'Can we see & use a proof of concept?',
    'Who needs to see & review the proof of concept?',
    'Do all my stakeholders support the uniqueness of this solution?',
    'What other options should I consider?',
    'Do I understand the pros/cons of my options?',
    'Do you understand my business?',
    'Would you be a good strategic partner?',
    'How expensive are renewals?',
    'What is my all-in lifetime commitment?',
    'What is the best way for me to compare my options?',
    'What does or what else can you provide?',
    'Do you or your company have experience with this?',
    'Where can we obtain independent research?',
    'What have you seen other companies do?',
    'Is there a preference or bias towards a potential solution?',
    'Have the individuals that will be affected by the solution(s) been identified?'
  ],
  risk: [
    'Who will manage the vendor?',
    'Can we confirm that this solution will meet all of our privacy guidelines?',
    'Who else will this solution affect?',
    'Who should we make sure is part of the decision-making process?',
    'When do we need this solution to go live?',
    'What is the implementation timeline?',
    'How are you different than your competitors?',
    'Can we talk to a reference/referral?',
    'What are the risks of using this solution?',
    'What are the risks of doing nothing?',
    'What kind of ongoing support do you provide?',
    'How difficult is the onboarding process?',
    'How are we going to pay for this?',
    'What value will we get in the short term?',
    'What value will we get in the long term?',
    'Will my peers agree with this decision?',
    'Will I get fired if I don\'t select the right solution?'
  ],
  solution: [
    'Are we missing anything in the requirements?',
    'Are we missing anything in the T&Cs?',
    'Is the SOW clearly structured?',
    'Are there clear SLAs in place?',
    'When can I commit to my superiors this will be up and running?',
    'What is the best way to socialize vendor capabilities?',
    'Is our organization ready to implement this solution?',
    'Did the reference/referral resolve our concerns?',
    'Do I trust these guys?',
    'Are there any blind spots in the proposal I\'m not thinking of?',
    'Are all my internal stakeholder onboard & supportive?',
    'How long will it take for legal to approve this?',
    'How long will it take for accounts payable to pay for this?'
  ],
  realizes: [
    'Who do I talk to about  issues?',
    'How quickly can they resolve issues?',
    'Who is my main point of contact?',
    'What is my return on investment?',
    'How do I use this product?',
    'How often will they update the software?',
    'How many people need to be involved in using this solution?',
    'If they underperform, how do we terminate the contract?',
    'How do I renew?',
    'How do I upgrade?',
    'How do I continue to educate myself on your solution?'
  ]
};
