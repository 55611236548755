import { Pipe, PipeTransform } from '@angular/core';
import { currencyToNumber, purifyNumberString } from '../utils/helpers';

@Pipe({
  name: 'customCurrency'
})
export class CustomCurrencyPipe implements PipeTransform {
  private currency = 'USD';

  transform(value: string | number, fractionDigits = 2): string {
    const isEmpty =
      (typeof value === 'number' && !value && value !== 0) ||
      (typeof value !== 'number' && !value);
    if (isEmpty) {
      return '';
    }

    const currency =
      typeof value === 'string' ? currencyToNumber(value) : value;
    const fractionSize = String(value).includes('.') ? fractionDigits : 0;

    return (currency || 0).toLocaleString('en-US', {
      style: 'currency',
      currency: this.currency,
      minimumFractionDigits: fractionSize
    });
  }

  inputTransform(value: string | number, maxFractionSize = 2): string {
    const valueString = typeof value === 'number' ? String(value) : value;
    const currencyString = purifyNumberString(valueString);

    if (!currencyString) {
      return '';
    }

    const periodIndex = currencyString.indexOf('.');
    const fractionString =
      periodIndex === -1 ? '' : currencyString.substring(periodIndex + 1);
    const fractionSize =
      fractionString.length > maxFractionSize
        ? maxFractionSize
        : fractionString.length;
    const localCurString = Number(currencyString).toLocaleString('en-US', {
      style: 'currency',
      currency: this.currency,
      minimumFractionDigits: fractionSize
    });

    return periodIndex !== -1 && !fractionSize
      ? [localCurString, '.'].join('')
      : localCurString;
  }

  parse(value: string): number {
    const currencyString = purifyNumberString(value);

    return currencyString ? currencyToNumber(currencyString) : null;
  }
}
