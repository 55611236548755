import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EvaluatingInputData, TotalSelectionInputData } from '../index';

@Component({
  selector: 'app-evaluate-selection-from-totals',
  templateUrl: './evaluate-selection-from-totals.component.html',
  styleUrls: ['./evaluate-selection-from-totals.component.scss']
})
export class EvaluateSelectionFromTotalsComponent implements OnInit {
  @Input() model: TotalSelectionInputData;
  @Input() objects: Partial<EvaluatingInputData>;
  @Input() criteria: Partial<EvaluatingInputData>;
  @Input() evaluatingInputContent: EvaluatingInputData;
  @Input() disabled: boolean;
  @Input() defaultValue: number;

  @Output() modelChange = new EventEmitter<TotalSelectionInputData>();

  objectsScores: TotalSelectionInputData;
  selectedObjectKey: string;

  displayedColumns = ['Name', 'Score'];

  ngOnInit(): void {
    this.selectedObjectKey = this.model && Object.keys(this.model)[0];
    if (this.objects && this.evaluatingInputContent) {
      this.prepareObjectsScore();
    }
  }

  prepareObjectsScore() {
    this.objectsScores = Object.entries(this.objects).reduce(
      (objectsAcc: TotalSelectionInputData, [objectKey, objectValue]) => ({
        ...objectsAcc,
        [objectKey]: {
          title: objectValue.title,
          score: Object.entries(this.criteria).reduce(
            (scoreAcc: number, [criterionKey, criterionValue]) =>
              this.evaluatingInputContent[objectKey]
                ? this.evaluatingInputContent[objectKey].criteria[criterionKey]
                  ? scoreAcc +
                    this.evaluatingInputContent[objectKey].criteria[
                      criterionKey
                    ].rating
                  : scoreAcc + this.defaultValue
                : scoreAcc + this.defaultValue,
            0
          )
        }
      }),
      {}
    );
  }

  onSelectChange(objectKey: string) {
    this.modelChange.emit({
      [objectKey]: this.objectsScores[objectKey]
    });
  }
}
