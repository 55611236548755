import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  Module,
  ModuleStepChange,
  Organization,
  Section,
  Step
} from '../../common/interfaces/module.interface';
import { ActivatedRoute } from '@angular/router';
import { ModuleService } from '../../common/services/module.service';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem
} from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { StepTemplateEditorComponent } from './step-template-editor/step-template-editor.component';
import { StepLinkEditorComponent } from './step-link-editor/step-link-editor.component';
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs/operators';
import { SnackBarService } from '../../common/services/snackbar.service';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons/faFileAlt';
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy';
import { ModuleNavService } from '../../common/services/module-nav.service';
import { ConfirmationService } from '../../common/services/confirmation.service';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { ConfirmationModalComponent } from '../../common/components/confirmation-modal/confirmation-modal';
import { ConfirmationModalOptions } from '../../common/components/confirmation-modal';
import { MatDialogRef } from '@angular/material/dialog/dialog-ref';

@Component({
  selector: 'app-module-editor',
  templateUrl: './module-editor.component.html',
  styleUrls: ['./module-editor.component.scss']
})
export class ModuleEditorComponent implements OnInit, OnDestroy {
  organizations$: Observable<Organization[]>;
  moduleData: Module;
  sections: Section[];
  partialSteps: Step[] = [];
  ready = false;
  moduleLink = '';
  saving: Subscription;
  moduleSub: Subscription;
  lastSavedModule: string;
  sortableListIDs: string[];
  faFile = faFileAlt;
  faLink = faLink;
  faTrash = faTrashAlt;
  faCopy = faCopy;
  faOpen = faExternalLinkAlt;
  faSpinner = faSpinner;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  private updateIntervalId: number;
  private updateModalRef: MatDialogRef<
    ConfirmationModalComponent,
    ConfirmationModalOptions
  >;

  constructor(
    private route: ActivatedRoute,
    private moduleService: ModuleService,
    private modalService: MatDialog,
    private confirmationService: ConfirmationService,
    private snackBarService: SnackBarService,
    private moduleNavService: ModuleNavService
  ) {}

  hasChanges = () => {
    if (!this.moduleData) {
      return false;
    }
    this.moduleData.steps = this.generateStepsData();

    return this.lastSavedModule !== JSON.stringify(this.moduleData);
  };

  private get rootLevelSteps(): Step[] {
    return Object.keys(this.sections)
      .map(key => this.sections[key].steps)
      .flat();
  }

  private get currentModule(): Module {
    return {
      ...this.moduleData,
      steps: this.generateStepsData()
    };
  }

  ngOnInit() {
    this.moduleSub = this.route.params
      .pipe(
        switchMap(params =>
          this.moduleService.getModuleConfig(Number(params.id))
        ),
        catchError(() => this.moduleService.getDefaultModule())
      )
      .subscribe(moduleData => {
        this.prepareModuleData(moduleData);
        if (this.route.snapshot.params.stepid) {
          this.openStepFromRouteParameter(
            Number(this.route.snapshot.params.stepid)
          );
        }
        this.checkOnModuleModification();
      });
    this.createExternalLink();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.moduleSub.unsubscribe();
    if (this.updateIntervalId) {
      clearInterval(this.updateIntervalId);
    }
  }

  onClickAddStep(sectionIndex: number) {
    const lastStepIndex = this.getNewStepIndex(sectionIndex);
    this.sections[sectionIndex].steps.push(this.newStep(lastStepIndex + 1));
    this.sortStepIndexes();
  }

  onClickRemoveStep(sectionIndex: number, index: number) {
    this.confirmationService
      .removeDialog({
        text: 'this step'
      })
      .subscribe(() => {
        this.sections[sectionIndex].steps.splice(index, 1);
        this.sortStepIndexes();
      });
  }

  onClickEditStepTemplate(sectionIndex: number, index: number) {
    const modalRef = this.modalService.open(StepTemplateEditorComponent, {
      panelClass: 'step-template-editor-modal',
      maxHeight: '90vh',
      scrollStrategy: new NoopScrollStrategy()
    });
    modalRef.componentInstance.step = this.sections[sectionIndex].steps[index];
    modalRef.componentInstance.rootLevelSteps = this.rootLevelSteps;
    modalRef.componentInstance.partialSteps = this.partialSteps;
    modalRef.componentInstance.moduleData = this.moduleData;
    modalRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => (this.sections = this.getSections(this.generateStepsData()))
      );
  }

  copyStep(sectionIndex: number, index: number) {
    const copyStep = { ...this.sections[sectionIndex].steps[index] };
    copyStep.step_index = this.getNewStepIndex(sectionIndex) + 1;
    this.sections[sectionIndex].steps.push(copyStep);
  }

  editPartialStepTemplate(index: number): void {
    const modalRef = this.modalService.open(StepTemplateEditorComponent, {
      panelClass: 'step-template-editor-modal',
      maxHeight: '90vh',
      scrollStrategy: new NoopScrollStrategy()
    });
    modalRef.componentInstance.step = this.partialSteps[index];
    modalRef.componentInstance.rootLevelSteps = this.rootLevelSteps;
    modalRef.componentInstance.partialSteps = this.partialSteps;
    modalRef.componentInstance.moduleData = this.moduleData;
    modalRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => (this.sections = this.getSections(this.generateStepsData()))
      );
  }

  onClickEditSectionTemplate(sectionIndex: number) {
    const modalRef = this.modalService.open(StepTemplateEditorComponent, {
      panelClass: 'section-template-editor-modal',
      maxHeight: '90vh',
      scrollStrategy: new NoopScrollStrategy()
    });
    modalRef.componentInstance.step = this.sections[sectionIndex].section;
    modalRef.componentInstance.rootLevelSteps = this.rootLevelSteps;
    modalRef.componentInstance.partialSteps = this.partialSteps;
  }

  onClickLinkStep(sectionIndex: number, index?: number) {
    const modalRef = this.modalService.open(StepLinkEditorComponent, {
      panelClass: 'step-link-editor-modal',
      maxHeight: '90vh',
      scrollStrategy: new NoopScrollStrategy()
    });

    modalRef.componentInstance.step =
      index === undefined
        ? this.sections[sectionIndex].section
        : this.sections[sectionIndex].steps[index];

    modalRef.componentInstance.module = this.moduleData;
  }

  onClickAddSection() {
    const newSection = { section: this.newStep(), steps: [] };
    newSection.section.is_section_break = true;
    this.sections.push(newSection);
  }

  onClickRemoveSection(sectionIndex: number) {
    this.confirmationService
      .simpleDialog({
        text:
          '<h2>Are you sure you want to remove this section?</h2>' +
          '<p>The following steps it contains will be removed as well:</p> \n* ' +
          this.sections[sectionIndex].steps
            .map(step => step.description)
            .join('<br/>* ')
      })
      .subscribe(() => {
        this.sections.splice(sectionIndex, 1);
        this.sortStepIndexes();
      });
  }

  onClickSave() {
    this.moduleData.steps = this.generateStepsData();
    this.moduleService
      .getModuleUpdate(this.moduleData)
      .pipe(
        switchMap(updatedModule => {
          if (updatedModule) {
            const resolveFunc = () => {
              this.updateModalRef = this.openUpdateModuleDialog(
                'The module has been modified by another user.' +
                  ' Saving the module will overwrite their changes. Are you sure you want to proceed?'
              );

              return this.updateModalRef.afterClosed().pipe(
                tap(() => (this.updateModalRef = null)),
                map(result => !!result)
              );
            };

            return this.resolveModuleUpdate(updatedModule, resolveFunc);
          } else {
            return of(true);
          }
        }),
        filter(Boolean)
      )
      .subscribe(() => {
        const sameSuffixSiblingSteps = this.findSameInputSuffixSiblingSteps(
          this.moduleData.steps
        );

        if (sameSuffixSiblingSteps.length > 0) {
          this.snackBarService.warning(
            'You should probably change Input Suffixes, open console for more details'
          );

          for (const step of sameSuffixSiblingSteps) {
            const parentStep = this.moduleData.steps.find(
              p => p.id === step.parent_step_id
            );
            console.warn(
              `Step which may need to change Input Suffix
              ID: ${step.id},
              template: ${step.template_component},
              Input Suffix: "${step.template_params_json.input_sufix}",
              Parent Step: {
              \t ID: ${parentStep?.id},
              \t template: ${parentStep?.template_component}
              \t description: "${parentStep?.description || '<no description>'}"
              \t Input Suffix: "${parentStep?.template_params_json
                .input_sufix || ''}",
              }`.replace(/  +/g, '')
            );
          }
        }

        this.saving = this.moduleService
          .saveModule(this.moduleData)
          .subscribe(() => {
            this.setPristineState();
            this.snackBarService.success('Saved!');
          });
      });
  }

  findSameInputSuffixSiblingSteps(steps: Step[]) {
    const stepSuffixesSet = new Set<string>();
    const stepsWithDubblingSuffixes = new Set<Step>();
    const multiChildrenSteps = steps.filter(
      s =>
        s.template_component === 'block_repeater' ||
        s.template_component === 'composable_template'
    );
    const childrenSteps = steps.filter(s =>
      multiChildrenSteps.some(parent => parent.id === s.parent_step_id)
    );

    for (const step of childrenSteps) {
      const suffix = step.template_params_json.input_sufix;

      if (!!suffix && stepSuffixesSet.has(suffix)) {
        const hasSiblingsSameSuffix = childrenSteps.some(
          s =>
            s.parent_step_id === step.parent_step_id &&
            s.template_params_json.input_sufix === suffix
        );

        if (hasSiblingsSameSuffix) {
          stepsWithDubblingSuffixes.add(step);
        }
      }

      stepSuffixesSet.add(suffix);
    }

    return [...stepsWithDubblingSuffixes];
  }

  onSectionDrop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.sections, event.previousIndex, event.currentIndex);
    this.sortStepIndexes();
  }

  onStepDrop(event: CdkDragDrop<Step[]>) {
    transferArrayItem(
      event.previousContainer.data,
      event.container.data,
      event.previousIndex,
      event.currentIndex
    );
    this.sortStepIndexes();
  }

  getSections(steps: Step[]): Section[] {
    const stepSections = steps.reduce((sections, step) => {
      if (step.is_section_break) {
        sections.push({ section: step, steps: [] });
      } else if (!step.is_partial) {
        sections[sections.length - 1].steps.push(step);
      }

      return sections;
    }, []);
    this.sortableListIDs = Array.from(stepSections.keys()).map(
      id => 'drop-' + id
    );

    return stepSections;
  }

  generateStepsData(): Step[] {
    const moduleSteps = (this.sections || []).reduce((steps, section) => {
      steps.push(section.section);

      section.steps.forEach(step => steps.push(step));

      return steps;
    }, [] as Step[]);

    return moduleSteps.concat(this.partialSteps);
  }

  setPristineState() {
    this.lastSavedModule = JSON.stringify(this.currentModule);
  }

  export(moduleId: number) {
    window.location.href = this.moduleService.exportUrl(moduleId);
  }

  sync(moduleId: number) {
    if (
      prompt(`Data synchronization is a destructive action which will overwrite your current module and step configuration.
        Please make sure to export a data backup before proceeding.
        Are you sure you want to continue with the synchronization? Type "Yes" to confirm.`) !==
      'Yes'
    ) {
      return;
    }

    this.moduleService.sync(moduleId).subscribe(() => {
      alert(
        'Synchronization complete. The application will be reloaded to refresh your data.'
      );
      window.location.reload();
    });
  }

  private prepareModuleData(
    moduleData: Module,
    showNotification = false,
    saveState = true
  ): void {
    this.moduleData = moduleData;
    this.partialSteps = moduleData.steps.filter(step => !!step.is_partial);
    this.sections = this.getSections(moduleData.steps) || [];
    this.ready = true;
    if (saveState) {
      this.setPristineState();
    }
    this.sortStepIndexes();
    if (showNotification) {
      this.showUpdateModuleNotification();
    }
  }

  private newStep(stepIndex?: number): Step {
    return {
      description: '',
      is_section_break: false,
      id: 0,
      module_id: this.moduleData.id,
      template_params_json: {},
      template_component: '',
      is_printable: false,
      is_request_feedback: false,
      step_index: stepIndex
    };
  }

  private openStepFromRouteParameter(stepId: number): void {
    let paramStepIndex = 0;
    const partialStepIndex = this.partialSteps.findIndex(
      partialStep => partialStep.id === stepId
    );
    const sectionIndex = this.sections.findIndex(section => {
      paramStepIndex = section.steps.findIndex(step => step.id === stepId);

      return paramStepIndex >= 0 || section.section.id === stepId;
    });
    if (
      partialStepIndex === -1 &&
      sectionIndex === -1 &&
      paramStepIndex === -1
    ) {
      this.snackBarService.error('Unable to find step!');
    } else {
      if (partialStepIndex !== -1) {
        this.editPartialStepTemplate(partialStepIndex);
      } else if (this.sections[sectionIndex].section.id === stepId) {
        this.onClickEditSectionTemplate(sectionIndex);
      } else {
        this.onClickEditStepTemplate(sectionIndex, paramStepIndex);
      }
    }
  }

  private createExternalLink() {
    let organizationID = 0;
    this.organizations$ = this.moduleService.getOrganizations();
    this.moduleNavService.organization$
      .pipe(take(1))
      .subscribe(orgId => (organizationID = orgId));

    this.moduleLink = `/org/${organizationID}/module/`;
  }

  private getNewStepIndex(sectionIndex: number) {
    const stepsLength = this.sections
      .filter((sections, index) => index <= sectionIndex)
      .map(section => section.steps.filter(step => !step.is_partial).length)
      .reduce((accumulator, sectionLength) => accumulator + sectionLength);
    const sectionSteps = this.sections[sectionIndex].steps.filter(
      step => !step.is_partial
    );

    return sectionSteps.length
      ? sectionSteps[sectionSteps.length - 1].step_index
      : stepsLength;
  }

  private checkOnModuleModification(): void {
    const checkInterval = 15000;
    const delayUpdate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
      false
    );
    this.updateIntervalId = setInterval(() => {
      if (!this.updateModalRef) {
        this.moduleService
          .getModuleUpdate(this.moduleData)
          .pipe(filter((module: Module) => !!module))
          .subscribe((updatedModule: Module) => {
            const resolveFunc = () => {
              this.updateModalRef = this.openUpdateModuleDialog(
                'This module has just been modified by another user. Would you like to reload the module data?'
              );

              return this.updateModalRef.afterClosed().pipe(
                tap(result => {
                  if (result) {
                    this.prepareModuleData(updatedModule, true);
                  } else {
                    delayUpdate.next(true);
                  }
                  this.updateModalRef = null;
                }),
                map(result => !!result)
              );
            };
            this.resolveModuleUpdate(updatedModule, resolveFunc).subscribe();
          });
      }
    }, checkInterval);
    delayUpdate.asObservable().subscribe(value => {
      if (value) {
        clearInterval(this.updateIntervalId);
      }
    });
  }

  private resolveModuleUpdate(
    updatedModule: Module,
    resolveFunc: () => Observable<boolean>
  ): Observable<boolean> {
    const isModified = !ModuleService.isEqualModules(
      this.currentModule,
      this.lastSavedModule
    );
    const isUpToDate = ModuleService.isEqualModules(
      this.lastSavedModule,
      updatedModule
    );
    if (isUpToDate) {
      this.moduleData = {
        ...this.moduleData,
        last_updated: updatedModule.last_updated
      };
      this.setPristineState();

      return of(true);
    } else {
      if (isModified) {
        const localChanges = ModuleService.CompareStepsBetweenModules(
          this.lastSavedModule,
          this.currentModule
        );
        const updateChanges = ModuleService.CompareStepsBetweenModules(
          this.lastSavedModule,
          updatedModule
        );
        const hasConflict = (
          local: ModuleStepChange[],
          update: ModuleStepChange[]
        ): boolean =>
          local.some(localChange => {
            const updateChange = update.find(
              change => Number(localChange.step.id) === Number(change.step.id)
            );

            return (
              updateChange &&
              !(
                localChange.action === 'deleted' &&
                updateChange.action === 'deleted'
              ) &&
              !(
                localChange.action === 'contentChange' &&
                updateChange.action === 'contentChange' &&
                ModuleService.isEqualSteps(
                  localChange.step,
                  updateChange.step,
                  false
                )
              ) &&
              !(localChange.action === 'positionChange')
            );
          });
        if (hasConflict(localChanges, updateChanges)) {
          return resolveFunc();
        } else {
          const mergedSteps = localChanges.reduce(
            (accum: Step[], change) => {
              const stepId = accum.findIndex(
                step => Number(step.id) === Number(change.step.id)
              );
              switch (change.action) {
                case 'contentChange': {
                  if (stepId !== -1) {
                    accum[stepId] = change.step;
                  }
                  break;
                }
                case 'positionChange': {
                  if (
                    stepId !== -1 &&
                    ModuleService.isEqualSteps(
                      change.step,
                      accum[stepId],
                      false
                    )
                  ) {
                    accum[stepId] = change.step;
                  } else if (!change.step.id) {
                    accum.push(change.step);
                  }
                  break;
                }
                case 'deleted': {
                  if (stepId !== -1) {
                    accum.splice(stepId, 1);
                  }
                  break;
                }
                case 'new': {
                  accum.push(change.step);
                  break;
                }
              }

              return accum;
            },
            [...updatedModule.steps]
          );
          const mergedModule = {
            ...updatedModule,
            steps: mergedSteps
          };
          mergedModule.steps.sort(
            (stepA, stepB) =>
              Number(stepA.step_index) - Number(stepB.step_index)
          );
          this.prepareModuleData(mergedModule, true, false);
          this.lastSavedModule = JSON.stringify(updatedModule);

          return of(true);
        }
      } else {
        this.prepareModuleData(updatedModule, true);

        return of(true);
      }
    }
  }

  private openUpdateModuleDialog(
    text: string
  ): MatDialogRef<ConfirmationModalComponent, ConfirmationModalOptions> {
    return this.modalService.open(ConfirmationModalComponent, {
      data: {
        text,
        confirmBtnTitle: 'Yes',
        rejectBtnTitle: 'No'
      }
    });
  }

  private showUpdateModuleNotification(): void {
    this.snackBarService.info('Reloading newest module data', 2000);
  }

  private sortStepIndexes(): void {
    let passedSectionStepsLength = 0;
    this.sections.forEach(section => {
      let index = 0;
      section.steps.forEach(step => {
        if (!step.is_partial) {
          step.step_index = index + passedSectionStepsLength + 1;
          index++;
        }
      });
      passedSectionStepsLength =
        passedSectionStepsLength +
        section.steps.filter(step => !step.is_partial).length;
    });
  }
}
