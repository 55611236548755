import { TemplateContentDataBase } from '../template.interface';
import { ICPSegment } from '../icp-segment-selector';
import { BuyerPersona } from '../../../../common/interfaces/buyer-persona.interface';
import { ICPArea } from '../icp-areas';
import { SafeHtml } from '@angular/platform-browser';

export interface ICPPersonaData {
  personaRef: BuyerPersona;
  persona: string;
  NeedWantsFears: string;
  ProductsServices: string;
  UniqueCommonCommodity: string;
  Prioritize: string;
  ValuePropositionStatement: string;
  ValuePropositionParagraph: string;
}

export interface ICP extends ICPSegment {
  icpPersonaData: ICPPersonaData[];
  companyNarrative: string;
  companyNarrativeHTML?: SafeHtml;
  icpValueProposition: string;
}

export interface ICPSegmentSelector {
  index: number;
  title: string;
  isChecked: boolean;
}

export interface ValuePropositionStep {
  index: number;
  isEditableField: boolean;
}

export interface ValuePropositionTableColumn {
  title: string;
  classFieldsNames: string[];
  placeholders?: string[];
  steps: ValuePropositionStep[];
  currentStep?: ValuePropositionStep;
}

export interface ICPPersonaToSave {
  index: number;
  areas: ICPArea[];
}

export interface IcpAreaSegmentsToSave {
  index: number;
  name: string;
  personas: ICPPersonaToSave[];
}

export interface ICPPersonaDataForSave {
  persona: number;
  NeedWantsFears: string;
  ProductsServices: string;
  UniqueCommonCommodity: string;
  Prioritize: string;
  ValuePropositionStatement: string;
  ValuePropositionParagraph: string;
}

export interface IcpDataForSave {
  companyNarrative: string;
  isChecked: boolean;
  name: string;
  index: number;
  icpValueProposition: string;
  icpPersonaData: ICPPersonaDataForSave[];
}

export interface BuyerPersonaMatrixICP {
  index: number;
  isSelected: boolean;
}

export interface BuyerPersonaMatrixRow {
  persona: string;
  icps: BuyerPersonaMatrixICP[];
}

export class BuyerPersonaMatrix {
  rows: BuyerPersonaMatrixRow[] = [];
}

export interface SolutionFromListEntry {
  option: string;
}

export interface BuyerPersonaMatrixFromStep {
  [key: number]: { [key: number]: boolean };
}

export interface ValuePropositionTemplateData extends TemplateContentDataBase {
  template_params_json: {
    title: string;
    description: string;
    input_sufix: string;
    step_select:
      | 'First'
      | 'Second'
      | 'Third'
      | 'Fourth'
      | 'Fifth'
      | 'Sixth'
      | 'Summary';
  };
}
