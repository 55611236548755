import { TemplateContentDataBase } from '../template.interface';

export interface Title {
  step: string;
  title: string;
}

export interface OpportunitySummaryTemplateData
  extends TemplateContentDataBase {
  template_params_json: {
    description: string;
    title: string;
  };
}

export const inputKeys = {
  exitCriteria: '_questions_ec_',
  salesActivities: '_sa_',
  toolsAids: '_questions_ta_',
  verification: '_questions_ver_'
};

export const titles: Title[] = [
  { step: 'recognizes', title: 'RECOGNIZES NEED' },
  { step: 'need', title: 'DEFINES NEED' },
  { step: 'options', title: 'EVALUATES OPTIONS' },
  { step: 'risk', title: 'MITIGATES RISK' },
  { step: 'solution', title: 'SELECTS SOLUTION' },
  { step: 'realizes', title: 'REALIZES VALUE' }
];

export const subtitles = {
  recognizes:
    'Customer becomes aware of, identifies and is stimulated to quantify the' +
    ' impact and challenges relevant to their business problem or need.',
  need:
    'Customer becomes aware of, identifies and is stimulated to quantify the' +
    ' impact and challenges relevant to their business problem or need.',
  options:
    'Customer has defined necessary requirements and is evaluating options' +
    ' which align with organizational needs and budget, including ‘Do Nothing’ and ’Do it Ourselves’',
  risk:
    'Customer works to minimize risk to their organization and proceeds with internal recommendation to purchase',
  solution:
    'Customer gives final approval on proposal and signs agreement.  Customer is focused on implementation to solve the problem.',
  realizes:
    'Customer is adopting the solution to realize the value of the solution'
};
