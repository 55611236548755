import { Component, OnInit, Input } from '@angular/core';
import { TemplateInput } from '../../common/interfaces/module.interface';

@Component({
  selector: 'error-msg',
  templateUrl: './error-msg.component.html',
  styleUrls: ['./error-msg.component.scss']
})
export class ErrorMsgComponent implements OnInit {
  @Input() input: Partial<TemplateInput>;

  constructor() {}

  ngOnInit() {}
}
