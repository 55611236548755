import { Component, forwardRef } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import {
  SalesBudgetInputContent,
  SalesBudgetStatusStepType,
  SalesBudgetStatusTemplateParams,
  SalesBudgetStatusTemplateTemplateData
} from '.';
import txt from '!!raw-loader!./index.ts';
import { filter } from 'rxjs/operators';
import { TemplateInput } from '../../../../common/interfaces/module.interface';
import { isEmptyValue } from '../../../../common/utils/helpers';

@Component({
  selector: 'sales-budget-status-template',
  templateUrl: 'sales-budget-status-template.component.html',
  styleUrls: ['./sales-budget-status-template.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => SalesBudgetStatusTemplateComponent)
    }
  ]
})
export class SalesBudgetStatusTemplateComponent extends TemplateComponent {
  params = txt;
  contentData: SalesBudgetStatusTemplateTemplateData['template_params_json'];
  prefix = 'sales_budget_status_template_1';
  inputKey: string;
  salesInputs: SalesBudgetInputContent;

  salesLaborBudget = 0;
  salesExpensesBudget = 0;
  laborSpendingPercentage = 0.9;
  salesBudgetForLabor = 0;
  salesBudgetForExpenses = 0;
  baseLaborSpending = 0;
  estimatedLaborSpending = 0;
  estimatedExpensesSpending = 0;
  remainingBudget = 0;

  marketingLaborSpendingPercentage = 0;
  marketingBudgetForLabor = 0;
  marketingBudgetForNonLabor = 0;
  marketingEstimatedLaborSpending = 0;
  marketingEstimatedVariableExpenses = 0;
  marketingRemainingBudget = 0;
  marketingRemainingNonLaborBudget = 0;

  init() {
    super.init();
    this.contentData = this.data.data
      .template_params_json as SalesBudgetStatusTemplateParams;

    this.inputKey = this.contentData.input_sufix
      ? `${this.prefix}_${this.contentData.input_sufix}`
      : this.prefix;

    this.salesInputs = JSON.parse(this.input.content) || {};

    this.laborSpendingPercentage =
      (this.salesInputs.labor_spending_percentage || 0) / 100;
    this.salesLaborBudget =
      (this.salesInputs.yearly_sales_budget || 0) *
      this.laborSpendingPercentage;
    this.salesExpensesBudget =
      (this.salesInputs.yearly_sales_budget || 0) *
      (1 - this.laborSpendingPercentage);

    this.marketingLaborSpendingPercentage =
      (this.salesInputs.marketing_budget?.marketing_budget_labor_percentage ||
        0) / 100;
    this.marketingBudgetForLabor =
      (this.salesInputs.marketing_budget?.yearly_marketing_budget || 0) *
      this.marketingLaborSpendingPercentage;
    this.marketingBudgetForNonLabor =
      !isEmptyValue(
        this.salesInputs.marketing_budget?.yearly_marketing_budget
      ) &&
      !isEmptyValue(
        this.salesInputs.marketing_budget?.marketing_budget_labor_percentage
      )
        ? (this.salesInputs.marketing_budget?.yearly_marketing_budget || 0) *
          (1 - this.marketingLaborSpendingPercentage)
        : null;

    this.calculateSpending();
    this.saveBudgetInput();

    this.moduleService.stepInputChanged$
      .pipe(
        this.whileExists(),
        filter(
          (inp: TemplateInput) =>
            inp && !inp.element_key.includes('sales_budget_status')
        )
      )
      .subscribe(inp => {
        this.inputs[inp.element_key] = inp;
        this.calculateSpending();
      });
  }

  getDescription(): string {
    return 'Module';
  }

  updateInputs(): void {
    if (this.isMarketingBudgetStep) {
      this.updateMarketingBudgetLabor();
    } else {
      this.updateSalesBudgetLabor();
    }
    this.saveBudgetInput();
  }

  getSpreadsheetInputData(inputName: string, fieldName?: string): number {
    if (this.inputs[inputName]?.content) {
      const content = JSON.parse(this.inputs[inputName].content);

      return fieldName ? content[fieldName] : content;
    }

    return null;
  }

  getMatrixInputData(inputName: string, index: number): number {
    if (this.inputs[inputName] && this.inputs[inputName].content) {
      let matrixData = JSON.parse(this.inputs[inputName].content);
      if (matrixData) {
        matrixData = matrixData.pop();
        matrixData = matrixData.filter(el => Number(el.id) === index);

        return matrixData.length ? matrixData[0].data : 0;
      }
    }

    return 0;
  }

  getName(): string {
    return 'Sales Budget Status Template';
  }

  getGroup(): string {
    return 'Module';
  }

  get input(): TemplateInput {
    return this.inputs[this.inputKey];
  }

  saveBudgetInput(): void {
    const newContent = JSON.stringify(this.salesInputs);
    if (newContent !== this.input.content) {
      this.input.content = newContent;
      this.contentChanged(this.input);
    }
  }

  private get isMarketingBudgetStep(): boolean {
    const marketingSteps: SalesBudgetStatusStepType[] = [
      '11_total_marketing_budget',
      '12_annual_market_labor',
      '13_new_marketing_positions',
      '14_review_positions',
      '15_marketing_variable_expenses'
    ];

    return marketingSteps.some(i => i === this.contentData.step_select);
  }

  private updateSalesBudgetLabor(): void {
    if (
      (this.salesInputs.yearly_sales_budget ||
        this.salesInputs.yearly_sales_budget === 0) &&
      (this.salesInputs.labor_spending_percentage ||
        this.salesInputs.labor_spending_percentage === 0)
    ) {
      this.laborSpendingPercentage =
        this.salesInputs.labor_spending_percentage / 100;
      this.salesInputs.sales_budget_for_labor =
        this.salesInputs.yearly_sales_budget * this.laborSpendingPercentage;
    } else {
      this.salesInputs.sales_budget_for_labor = null;
    }
    this.salesBudgetForLabor = this.salesInputs.sales_budget_for_labor;
  }

  private updateMarketingBudgetLabor(): void {
    if (
      !isEmptyValue(
        this.salesInputs.marketing_budget?.yearly_marketing_budget
      ) &&
      !isEmptyValue(
        this.salesInputs.marketing_budget?.marketing_budget_labor_percentage
      )
    ) {
      this.marketingLaborSpendingPercentage =
        this.salesInputs.marketing_budget.marketing_budget_labor_percentage /
        100;
      this.salesInputs.marketing_budget.marketing_budget_for_labor =
        this.salesInputs.marketing_budget.yearly_marketing_budget *
        this.marketingLaborSpendingPercentage;
      this.marketingBudgetForNonLabor =
        this.salesInputs.marketing_budget?.yearly_marketing_budget -
        this.salesInputs.marketing_budget.marketing_budget_for_labor;
    } else {
      this.salesInputs.marketing_budget.marketing_budget_for_labor = null;
      this.marketingBudgetForNonLabor = null;
    }
    this.marketingBudgetForLabor = this.salesInputs.marketing_budget.marketing_budget_for_labor;
  }

  private calculateSpending(): void {
    if (this.isMarketingBudgetStep) {
      this.calculateMarketingBudgetSpending();
    } else {
      this.calculateSalesBudgetSpending();
    }
  }

  private calculateSalesBudgetSpending(): void {
    const projectingTE =
      this.getSpreadsheetInputData('spreadsheet_2_other_expenses', 't_e') ||
      this.salesInputs.projecting_T_E ||
      0;
    const projectingConferenceExpense = (this.salesInputs.projecting_conference_expense =
      this.getSpreadsheetInputData(
        'spreadsheet_2_other_expenses',
        'conference'
      ) ||
      this.salesInputs.projecting_conference_expense ||
      0);
    const projectingEventsExpense = (this.salesInputs.projecting_events_expense =
      this.getSpreadsheetInputData('spreadsheet_2_other_expenses', 'events') ||
      this.salesInputs.projecting_events_expense ||
      0);
    const projectingReimbursables = (this.salesInputs.projecting_reimbursables =
      this.getSpreadsheetInputData(
        'spreadsheet_2_other_expenses',
        'reimbursable_expenses'
      ) ||
      this.salesInputs.projecting_reimbursables ||
      0);
    switch (this.contentData.step_select) {
      case '1_total_sales_budget':
        if (
          !this.salesInputs.yearly_sales_budget &&
          this.salesInputs.yearly_sales_budget !== 0
        ) {
          this.salesInputs.yearly_sales_budget = null;
        }
        if (
          !this.salesInputs.labor_spending_percentage &&
          this.salesInputs.labor_spending_percentage !== 0
        ) {
          this.salesInputs.labor_spending_percentage = null;
        }
        if (
          !this.salesInputs.sales_budget_for_labor &&
          this.salesInputs.sales_budget_for_labor !== 0
        ) {
          this.salesInputs.sales_budget_for_labor = null;
        }
        this.salesBudgetForLabor = this.salesInputs.sales_budget_for_labor;

        break;

      case '2_base_labor':
        this.salesInputs.base_labor_spendings =
          this.getSpreadsheetInputData(
            'spreadsheet_2_budget_strategy_merged',
            'ote'
          ) || 0;
        this.salesBudgetForLabor = this.salesLaborBudget;
        this.baseLaborSpending = this.salesInputs.base_labor_spendings;
        this.remainingBudget =
          this.salesBudgetForLabor - this.baseLaborSpending;
        break;

      case '3_projecting_total_attainment':
        this.salesInputs.annual_sales_labor =
          this.getSpreadsheetInputData(
            'spreadsheet_2_budget_strategy_merged',
            'ote'
          ) || 0;
        this.salesBudgetForLabor = this.salesLaborBudget;
        this.estimatedLaborSpending = this.salesInputs.annual_sales_labor;
        this.remainingBudget =
          this.salesLaborBudget - this.salesInputs.annual_sales_labor;
        break;

      case 'projecting_bonuses':
        this.salesInputs.annual_sales_labor =
          this.getSpreadsheetInputData(
            'spreadsheet_2_budget_strategy_merged',
            'projecting_bonuses'
          ) || 0;
        this.salesBudgetForLabor = this.salesLaborBudget;
        this.estimatedLaborSpending = this.salesInputs.annual_sales_labor;
        this.remainingBudget =
          this.salesLaborBudget - this.salesInputs.annual_sales_labor;
        break;

      case 'bonus_amounts':
        this.salesInputs.annual_sales_labor =
          this.getSpreadsheetInputData(
            'spreadsheet_2_budget_strategy_merged',
            'bonus_amounts'
          ) || 0;
        this.salesBudgetForLabor = this.salesLaborBudget;
        this.estimatedLaborSpending = this.salesInputs.annual_sales_labor;
        this.remainingBudget =
          this.salesLaborBudget - this.salesInputs.annual_sales_labor;
        break;

      case '4_seasonality_adjustments':
        this.salesInputs.estimated_labor_totals =
          this.getSpreadsheetInputData(
            'spreadsheet_2_budget_strategy_merged',
            'ote'
          ) || 0;
        this.salesBudgetForLabor = this.salesLaborBudget;
        this.estimatedLaborSpending =
          this.salesInputs.estimated_labor_totals || 0;
        this.remainingBudget =
          this.salesLaborBudget -
          (this.salesInputs.estimated_labor_totals || 0);
        break;

      case '5_projecting_T_E':
        this.salesInputs.projecting_T_E =
          this.getSpreadsheetInputData('spreadsheet_2_other_expenses', 't_e') ||
          0;
        this.salesBudgetForExpenses = this.salesExpensesBudget;
        this.estimatedExpensesSpending = this.salesInputs.projecting_T_E || 0;
        this.remainingBudget =
          this.salesExpensesBudget - this.salesInputs.projecting_T_E;
        break;

      case '6_projecting_conference_expense':
        this.salesInputs.projecting_conference_expense =
          this.getSpreadsheetInputData(
            'spreadsheet_2_other_expenses',
            'conference'
          ) || 0;
        const conferenceSpending =
          projectingTE + this.salesInputs.projecting_conference_expense;
        this.salesBudgetForExpenses = this.salesExpensesBudget;
        this.estimatedExpensesSpending = conferenceSpending;
        this.remainingBudget = this.salesExpensesBudget - conferenceSpending;
        break;

      case '7_projecting_events_expense':
        this.salesInputs.projecting_events_expense =
          this.getSpreadsheetInputData(
            'spreadsheet_2_other_expenses',
            'events'
          ) || 0;
        const eventsSpending =
          projectingTE +
          projectingConferenceExpense +
          this.salesInputs.projecting_events_expense;
        this.salesBudgetForExpenses = this.salesExpensesBudget;
        this.estimatedExpensesSpending = eventsSpending;
        this.remainingBudget = this.salesExpensesBudget - eventsSpending;
        break;

      case '8_projecting_reimbursables':
        this.salesInputs.projecting_reimbursables =
          this.getSpreadsheetInputData(
            'spreadsheet_2_other_expenses',
            'reimbursable_expenses'
          ) || 0;
        const reimbursablesSpending =
          projectingTE +
          projectingConferenceExpense +
          projectingEventsExpense +
          this.salesInputs.projecting_reimbursables;
        this.salesBudgetForExpenses = this.salesExpensesBudget;
        this.estimatedExpensesSpending = reimbursablesSpending;
        this.remainingBudget = this.salesExpensesBudget - reimbursablesSpending;
        break;

      case '9_calculating_spiffs':
        this.salesInputs.calculating_spiffs =
          this.getSpreadsheetInputData(
            'spreadsheet_2_other_expenses',
            'spiffs'
          ) || 0;
        const spiffsSpending =
          projectingTE +
          projectingConferenceExpense +
          projectingEventsExpense +
          projectingReimbursables +
          this.salesInputs.calculating_spiffs;
        this.salesBudgetForExpenses = this.salesExpensesBudget;
        this.estimatedExpensesSpending = spiffsSpending;
        this.remainingBudget = this.salesExpensesBudget - spiffsSpending;
        break;

      case '10_adjusting_monthly_expenses':
        this.salesInputs.estimated_expenses_totals =
          this.getSpreadsheetInputData(
            'spreadsheet_2_other_expenses',
            'monthly_expenses'
          ) || 0;
        this.salesBudgetForLabor = this.salesExpensesBudget;
        this.estimatedLaborSpending = this.salesInputs.estimated_expenses_totals;
        this.remainingBudget =
          this.salesExpensesBudget - this.salesInputs.estimated_expenses_totals;
        break;
    }
  }

  private calculateMarketingBudgetSpending(): void {
    this.salesInputs.marketing_budget = this.salesInputs.marketing_budget ?? {
      yearly_marketing_budget: null,
      marketing_budget_labor_percentage: null,
      marketing_budget_for_labor: null,
      marketing_base_labor_spendings: null,
      annual_market_labor: 0,
      new_marketing_positions: 0,
      review_positions: 0,
      marketing_variable_expenses: 0
    };
    const annualMarketLabor = (this.salesInputs.marketing_budget.annual_market_labor =
      this.getSpreadsheetInputData(
        'spreadsheet_2_annual_market_labor',
        'annual_market_labor'
      ) ?? this.salesInputs.marketing_budget.annual_market_labor);
    const newMarketingPositions = (this.salesInputs.marketing_budget.new_marketing_positions =
      this.getSpreadsheetInputData(
        'spreadsheet_2_new_marketing_positions',
        'new_marketing_positions'
      ) ?? this.salesInputs.marketing_budget.new_marketing_positions);
    const reviewPositions = (this.salesInputs.marketing_budget.review_positions =
      this.getSpreadsheetInputData(
        'spreadsheet_2_review_positions',
        'review_positions'
      ) ?? this.salesInputs.marketing_budget.review_positions);
    const marketingVariableExpenses = (this.salesInputs.marketing_budget.marketing_variable_expenses =
      this.getSpreadsheetInputData(
        'spreadsheet_2_variable_expenses',
        'marketing_variable_expenses'
      ) ?? this.salesInputs.marketing_budget.marketing_variable_expenses);
    switch (this.contentData.step_select) {
      case '11_total_marketing_budget': {
        this.salesInputs.marketing_budget.yearly_marketing_budget =
          this.salesInputs.marketing_budget.yearly_marketing_budget ?? null;
        this.salesInputs.marketing_budget.marketing_budget_labor_percentage =
          this.salesInputs.marketing_budget.marketing_budget_labor_percentage ??
          null;
        this.salesInputs.marketing_budget.marketing_budget_for_labor =
          this.salesInputs.marketing_budget.marketing_budget_for_labor ?? null;
        this.marketingBudgetForLabor = this.salesInputs.marketing_budget.marketing_budget_for_labor;
        break;
      }
      case '12_annual_market_labor': {
        this.salesInputs.marketing_budget.annual_market_labor = annualMarketLabor;
        this.marketingEstimatedLaborSpending = this.salesInputs.marketing_budget.annual_market_labor;
        break;
      }
      case '13_new_marketing_positions': {
        this.salesInputs.marketing_budget.new_marketing_positions = newMarketingPositions;
        this.marketingEstimatedLaborSpending =
          this.salesInputs.marketing_budget.annual_market_labor +
          this.salesInputs.marketing_budget.new_marketing_positions;
        break;
      }
      case '14_review_positions': {
        this.salesInputs.marketing_budget.review_positions = reviewPositions;
        this.marketingEstimatedLaborSpending = this.salesInputs.marketing_budget.review_positions;
        break;
      }
      case '15_marketing_variable_expenses': {
        this.salesInputs.marketing_budget.marketing_variable_expenses = marketingVariableExpenses;
        this.marketingEstimatedVariableExpenses = this.salesInputs.marketing_budget.marketing_variable_expenses;
        break;
      }
    }
    this.marketingRemainingBudget =
      this.marketingBudgetForLabor - this.marketingEstimatedLaborSpending;
    this.marketingRemainingNonLaborBudget =
      this.marketingBudgetForNonLabor - this.marketingEstimatedVariableExpenses;
  }
}
