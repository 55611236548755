import { safeID } from '../utils/safe-id';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'safeID'
})
export class SafeIDPipe implements PipeTransform {
  transform(value: string, ...args): string {
    return safeID(value);
  }
}
