import { Component, forwardRef } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import { MarketingOrganizationDesignTemplateData } from '.';
import txt from '!!raw-loader!./index.ts';
import {
  Employee,
  EmployeeError,
  JobTypes,
  KPI,
  Resource,
  ResourceCircles,
  ResourceColors,
  Strategy,
  StrategyBlock,
  StrategyBlockError
} from './Strategy';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { differenceInWeeks, endOfYear, parse } from 'date-fns';
import {
  MAT_DATE_FNS_MONTH_YEAR_FORMATS,
  MONTH_YEAR_INPUT_FORMAT
} from '../../../../common/utils/date-fns-date-picker';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { Observable, of } from 'rxjs';
import { BlockData, BlockRepeaterContent } from '../block-repeater';
import { updateBlockRepeaterFormat } from '../block-repeater/helpers';

@Component({
  selector: 'marketing-organization-design',
  templateUrl: 'marketing-organization-design.component.html',
  styleUrls: ['./marketing-organization-design.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => MarketingOrganizationDesignComponent)
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_MONTH_YEAR_FORMATS }
  ]
})
export class MarketingOrganizationDesignComponent extends TemplateComponent {
  readonly jobType: typeof JobTypes = JobTypes;
  readonly JobTypeList: JobTypes[] = [
    JobTypes.Hire,
    JobTypes.Train,
    JobTypes.Buy
  ];
  readonly resourceCircles: ResourceCircles[] = [
    ResourceCircles.Low,
    ResourceCircles.Medium,
    ResourceCircles.High
  ];

  readonly contributionColor: Record<ResourceCircles, ResourceColors> = {
    [ResourceCircles.Low]: ResourceColors.Red,
    [ResourceCircles.Medium]: ResourceColors.Yellow,
    [ResourceCircles.High]: ResourceColors.Green
  };

  readonly costColor: Record<ResourceCircles, ResourceColors> = {
    [ResourceCircles.Low]: ResourceColors.Green,
    [ResourceCircles.Medium]: ResourceColors.Yellow,
    [ResourceCircles.High]: ResourceColors.Red
  };

  params = txt;
  contentData: MarketingOrganizationDesignTemplateData['template_params_json'];

  strategyBlocks: StrategyBlock[] = [];
  inputsEditor: any[] = [];
  strategiesWithHire: Strategy[] = [];
  blockRepeaterKey: string;
  workHours: number;
  weeks: number[] = [];
  employees: Employee[] = [];
  yearlyHours = 0;
  revenue = 0;
  marketingRevenue = 4;
  marketingBudget = 0;
  unallocatedBudget = 0;
  faTrashedIcon = faTrash;

  strategyErrors: StrategyBlockError[] = [];
  employeeErrors: EmployeeError[] = [];
  employeeInvalid = false;

  get unallocatedHours() {
    let unallocatedHoursValue = 0;

    if (!this.strategyBlocks) {
      return unallocatedHoursValue;
    }

    this.strategyBlocks.forEach(block => {
      (block.strategies || []).forEach(strategy => {
        if (strategy.hours) {
          unallocatedHoursValue += strategy.hours;
        }
      });
    });

    return this.yearlyHours - unallocatedHoursValue;
  }

  getDescription() {
    return '';
  }

  getName() {
    return 'Marketing Organization Design';
  }

  getGroup(): string {
    return 'Module';
  }

  init() {
    super.init();
    this.contentData = this.data.data
      .template_params_json as MarketingOrganizationDesignTemplateData['template_params_json'];
    this.blockRepeaterKey = Object.keys(this.inputs).find(el =>
      el.includes('select_strategies_br')
    );
    this.strategyBlocks = this.getStrategyBlocks();
    if (this.contentData.step_type_select === '3_determine') {
      this.strategyBlocks.forEach(block =>
        (block.strategies || [])
          .filter(strategy => strategy.jobType === JobTypes.Hire)
          .forEach(strategy => {
            this.strategiesWithHire.push(strategy);
            const start = parse(
              strategy.strategyExecutionDate,
              MONTH_YEAR_INPUT_FORMAT,
              new Date()
            );
            const end = endOfYear(new Date());
            const weeks = differenceInWeeks(end, start);
            this.weeks.push(weeks < 52 ? weeks : 52);
          })
      );
      const inputKey = Object.keys(this.inputs).find(el =>
        el.includes('employees')
      );
      const content = JSON.parse(this.inputs[inputKey].content);
      const example = {
        title: 'E.g. Content Writer',
        hoursPerWeek: 40,
        weeksPerYear: 40,
        totalyHours: 1600
      };
      this.employees = content?.length > 0 ? content : [example];
    }
    if (
      this.contentData.step_type_select === '4_work_hours' ||
      this.contentData.step_type_select === '5_allocate_time'
    ) {
      this.workHours = this.inputs.marketing_organization_design_1?.content
        ? Number(this.inputs.marketing_organization_design_1?.content)
        : null;
    }
    if (this.contentData.step_type_select === '5_allocate_time') {
      const employee = JSON.parse(
        this.inputs.marketing_organization_design_1_employees.content
      );
      this.yearlyHours = employee
        ? +employee.reduce((el, el1) => el + el1.totalyHours, 0) +
          +this.workHours
        : +this.workHours;
    }

    if (
      (this.contentData.step_type_select === '8_marketing_budget' ||
        this.contentData.step_type_select === '9_allocate_budget') &&
      this.inputs.marketing_organization_design_1_budget
    ) {
      this.marketingBudget = +this.inputs.marketing_organization_design_1_budget
        .content;
      this.revenue = +this.inputs.marketing_organization_design_2_budget
        .content;
      this.marketingRevenue =
        +this.inputs.marketing_organization_design_3_budget.content || 4;
    }

    if (
      this.contentData.step_type_select === '1_review_strategy' ||
      this.contentData.step_type_select === '2_hire_train_buy' ||
      this.contentData.step_type_select === '5_allocate_time' ||
      this.contentData.step_type_select === '6_allocate_resources' ||
      this.contentData.step_type_select === '7_revenue_attribution' ||
      this.contentData.step_type_select === '9_allocate_budget' ||
      this.contentData.step_type_select === '12_Review_and_Print'
    ) {
      this.strategyBlocks = this.strategyBlocks.filter(
        block =>
          block.strategies &&
          block.strategies.length &&
          block.strategies.some(strategy => strategy.checked)
      );
    }

    if (
      this.contentData.step_type_select === '9_allocate_budget' ||
      this.contentData.step_type_select === '12_Review_and_Print'
    ) {
      this.sortStrategyBlockByScore();
      const allocate = JSON.parse(
        this.inputs.marketing_organization_design_1_allocate.content
      );
      this.strategyBlocks = this.strategyBlocks.map(block => {
        const allocateBlock = allocate.find(item => item.title === block.title);
        block.cost = allocateBlock.cost;
        block.contribution = allocateBlock.contribution;
        block.budget = allocateBlock.budget;
        block.budgetPercent = allocateBlock.budgetPercent;
        block.scoreValue = allocateBlock.scoreValue;

        return block;
      });
      this.unallocatedBudget = this.marketingBudget - this.getInputBudget();
    }
  }

  validate(): Observable<boolean> {
    let isInvalid = false;
    this.validationTriggered.next(true);
    switch (this.contentData.step_type_select) {
      case '2_hire_train_buy': {
        this.strategyErrors = this.strategyBlocks.reduce(
          (accum: StrategyBlockError[], strategyBlock) => {
            accum.push({
              strategies: strategyBlock.strategies.map(strategy => {
                const strategyError = {
                  canMemberExecuteStrategy:
                    strategy.checked &&
                    strategy.canMemberExecuteStrategy === undefined,
                  jobType:
                    strategy.checked &&
                    (strategy.canMemberExecuteStrategy
                      ? false
                      : !strategy.jobType),
                  strategyExecutionDate:
                    strategy.checked &&
                    (strategy.canMemberExecuteStrategy
                      ? false
                      : !strategy.strategyExecutionDate)
                };
                isInvalid =
                  isInvalid ||
                  strategyError.canMemberExecuteStrategy ||
                  strategyError.jobType ||
                  strategyError.strategyExecutionDate;

                return strategyError;
              })
            });

            return accum;
          },
          []
        );
        if (isInvalid) {
          this.setValidationError({
            type: 'hireTrainAndBuyEmptyValues',
            message: 'Please mark all statements above and fill date fields'
          });
        } else {
          this.removeValidationError('hireTrainAndBuyEmptyValues');
        }
        break;
      }
      case '3_determine': {
        [this.employeeErrors, this.employeeInvalid] = this.validateEmployees(
          this.employees
        );
        isInvalid = this.employeeInvalid;
        break;
      }
      case '5_allocate_time': {
        this.strategyErrors = this.strategyBlocks.reduce(
          (accum: StrategyBlockError[], strategyBlock) => {
            accum.push({
              strategies: strategyBlock.strategies.map(strategy => {
                const hours =
                  strategy.checked && !(strategy.hours || strategy.hours === 0);
                const strategyError = {
                  isInvalid: hours,
                  hours
                };
                isInvalid = isInvalid || strategyError.isInvalid;

                return strategyError;
              })
            });

            return accum;
          },
          []
        );
        if (isInvalid) {
          this.setValidationError({
            type: 'allocateTimeEmptyValues',
            message: 'Please fill all fields above'
          });
        } else {
          this.removeValidationError('allocateTimeEmptyValues');
        }
        break;
      }
      case '7_revenue_attribution': {
        this.strategyErrors = this.strategyBlocks.reduce(
          (accum: StrategyBlockError[], strategyBlock) => {
            accum.push({
              strategies: strategyBlock.strategies.map(strategy => {
                const strategyError = {
                  isInvalid:
                    strategy.checked &&
                    (!strategy.contribution || !strategy.cost),
                  contribution: strategy.checked && !strategy.contribution,
                  cost: strategy.checked && !strategy.cost,
                  resources: strategy.resources?.map(resource => ({
                    isInvalid:
                      strategy.checked &&
                      (!resource.contribution || !resource.cost),
                    contribution: strategy.checked && !resource.contribution,
                    cost: strategy.checked && !resource.cost
                  }))
                };
                isInvalid =
                  isInvalid ||
                  strategyError.isInvalid ||
                  strategyError.resources?.some(res => res.isInvalid);

                return strategyError;
              })
            });

            return accum;
          },
          []
        );
        if (isInvalid) {
          this.setValidationError({
            type: 'revenueAttributionEmptyValues',
            message: 'Please mark all statements above'
          });
        } else {
          this.removeValidationError('revenueAttributionEmptyValues');
        }
        break;
      }
    }

    return of(!isInvalid);
  }

  deleteRowFromStrategy(
    entity: string,
    blockIndex: number,
    strategyIndex: number,
    resourceIndex?: number,
    kpiIndex?: number,
    trackingIndex?: number
  ): void {
    this.confirmationService
      .removeDialog({
        text: `this ${entity}`
      })
      .subscribe(() => {
        const strategy = this.strategyBlocks[blockIndex].strategies[
          strategyIndex
        ];
        switch (entity) {
          case 'resource': {
            strategy.resources.splice(resourceIndex, 1);
            break;
          }
          case 'kpi': {
            strategy.kpi.splice(kpiIndex, 1);
            break;
          }
          case 'track': {
            const kpi = strategy.kpi[kpiIndex];
            kpi.trackingMethod.splice(trackingIndex, 1);
            break;
          }
          default:
            return;
        }
        this.saveMarketingInput(blockIndex);
      });
  }

  addNewResourceToStrategy(
    blockIndex: number,
    strategyIndex: number,
    resourceName: string
  ): void {
    const strategy = this.strategyBlocks[blockIndex].strategies[strategyIndex];
    const resource: Resource = { name: resourceName };
    if (strategy.resources) {
      strategy.resources.push(resource);
    } else {
      strategy.resources = [resource];
    }
    this.saveMarketingInput(blockIndex);
  }

  setStrategyType(
    blockIndex: number,
    strategy: Strategy,
    jobType: JobTypes
  ): void {
    strategy.jobType = jobType;
    strategy.canMemberExecuteStrategy = false;
    this.saveMarketingInput(blockIndex);
  }

  setCanExecuteStrategy(
    blockIndex: number,
    strategyIndex: number,
    value: boolean
  ): void {
    if (value) {
      this.strategyBlocks[blockIndex].strategies[
        strategyIndex
      ].jobType = undefined;
    }
    this.strategyBlocks[blockIndex].strategies[
      strategyIndex
    ].canMemberExecuteStrategy = value;
    this.saveMarketingInput(blockIndex);
  }

  setResourceCircle(
    resourceCircleValue: ResourceCircles,
    isCost: boolean,
    entity: string,
    blockIndex: number,
    strategyIndex?: number,
    resourceIndex?: number
  ): void {
    const strategy = this.strategyBlocks[blockIndex].strategies[strategyIndex];
    switch (entity) {
      case 'block': {
        if (isCost) {
          this.strategyBlocks[blockIndex].cost = resourceCircleValue;
        } else {
          this.strategyBlocks[blockIndex].contribution = resourceCircleValue;
        }
        this.strategyBlocks[blockIndex].scoreValue = this.calculateScoreValue(
          this.strategyBlocks[blockIndex].contribution,
          this.strategyBlocks[blockIndex].cost
        );
        break;
      }
      case 'strategy': {
        if (isCost) {
          strategy.cost = resourceCircleValue;
        } else {
          strategy.contribution = resourceCircleValue;
        }
        strategy.scoreValue = this.calculateScoreValue(
          strategy.contribution,
          strategy.cost
        );
        break;
      }
      case 'resource': {
        const resource = strategy.resources[resourceIndex];
        if (isCost) {
          resource.cost = resourceCircleValue;
        } else {
          resource.contribution = resourceCircleValue;
        }
        resource.scoreValue = this.calculateScoreValue(
          resource.contribution,
          resource.cost
        );
        break;
      }
      default:
        return;
    }
    this.saveMarketingInput(blockIndex);
  }

  setBudget(
    budgetValue: number,
    entity: string,
    blockIndex: number,
    strategyIndex?: number,
    resourceIndex?: number
  ): void {
    const strategy = this.strategyBlocks[blockIndex].strategies[strategyIndex];
    switch (entity) {
      case 'strategy': {
        strategy.budget = budgetValue;
        break;
      }
      case 'resource': {
        strategy.resources[resourceIndex].budget = budgetValue;
        break;
      }
      case 'block': {
        this.strategyBlocks[blockIndex].budget = budgetValue;
        break;
      }
      default:
        return;
    }
    this.unallocatedBudget = this.marketingBudget - this.getInputBudget();
    this.saveMarketingInput(blockIndex);
  }

  setPercent(
    budgetPercent: string | number,
    entity: string,
    blockIndex: number,
    strategyIndex?: number,
    resourceIndex?: number
  ): void {
    const strategy = this.strategyBlocks[blockIndex].strategies[strategyIndex];
    switch (entity) {
      case 'strategy': {
        strategy.budgetPercent = Number(budgetPercent);
        break;
      }
      case 'resource': {
        strategy.resources[resourceIndex].budgetPercent = Number(budgetPercent);
        break;
      }
      case 'block': {
        this.strategyBlocks[blockIndex].budgetPercent = Number(budgetPercent);
        break;
      }
      default:
        return;
    }
    this.saveMarketingInput(blockIndex);
  }

  setExecutionDate(
    blockIndex: number,
    strategyIndex: number,
    strategy: Strategy,
    dateValue: string
  ): void {
    strategy.strategyExecutionDate = dateValue;
    this.saveMarketingInput(blockIndex);
  }

  saveMarketingInput(blockIndex: number): void {
    const strategies = JSON.stringify(
      this.strategyBlocks[blockIndex].strategies
    );
    const content = updateBlockRepeaterFormat(
      JSON.parse(this.inputs[this.blockRepeaterKey].content)
    );
    const block = content.blocks[blockIndex].data;
    const idKey = this.getIdKey(block);
    const oldStrategies = block[idKey].checkbox_1_select_strategies_cs?.content;
    if (oldStrategies !== strategies) {
      block[idKey].checkbox_1_select_strategies_cs.content = strategies;
      this.inputs[this.blockRepeaterKey].content = JSON.stringify(content);
      this.contentChanged(this.inputs[this.blockRepeaterKey]);
    }
    if (this.contentData.step_type_select === '9_allocate_budget') {
      const input = this.inputs.marketing_organization_design_1_allocate;
      input.content = JSON.stringify(this.strategyBlocks);
      this.contentChanged(this.inputs[input.element_key]);
    }
  }

  saveToStrategy(
    value: string,
    entity: string,
    editorIndex: number,
    blockIndex: number,
    strategyIndex: number,
    kpiIndex?: number
  ) {
    if (value.trim().length) {
      switch (entity) {
        case 'resource':
          this.saveResource(editorIndex, blockIndex, strategyIndex);
          break;
        case 'kpi':
          this.saveKpi(editorIndex, blockIndex, strategyIndex);
          break;
        case 'track':
          this.saveTrack(editorIndex, blockIndex, strategyIndex, kpiIndex);
          break;
        default:
          return;
      }
      this.closeInputEditor(editorIndex);
    } else {
      this.snackBarService.error(`Value shouldn't be empty`);
    }
  }

  saveResource(
    editorIndex: number,
    blockIndex: number,
    strategyIndex: number
  ): void {
    this.addNewResourceToStrategy(
      blockIndex,
      strategyIndex,
      this.inputsEditor[editorIndex].content
    );
  }

  saveKpi(editorIndex: number, blockIndex: number, strategyIndex: number) {
    this.addNewKpiToStrategy(
      blockIndex,
      strategyIndex,
      this.inputsEditor[editorIndex].content
    );
  }

  saveTrack(
    editorIndex: number,
    blockIndex: number,
    strategyIndex: number,
    kpiIndex: number
  ) {
    this.addNewTrackToKpi(
      blockIndex,
      strategyIndex,
      kpiIndex,
      this.inputsEditor[editorIndex].content
    );
  }

  saveWorkHours(): void {
    const workHoursInput = this.inputs.marketing_organization_design_1;
    const changedContent = this.workHours?.toString() || null;
    if (workHoursInput.content !== changedContent) {
      workHoursInput.content = changedContent;
      this.contentChanged(workHoursInput);
    }
  }

  showInputEditor(editorIndex: number) {
    if (!this.disabled) {
      this.inputsEditor[editorIndex] = { content: '' };
    }
  }

  closeInputEditor(editorIndex: number) {
    delete this.inputsEditor[editorIndex];
  }

  addEmployee(): void {
    if (!this.disabled) {
      this.employees.push({
        title: null,
        hoursPerWeek: null,
        weeksPerYear: null,
        totalyHours: null
      });
    }
  }

  removeEmployee(idx): void {
    if (!this.disabled) {
      this.confirmationService
        .removeDialog({
          text: `employee ${this.employees[idx].title}`
        })
        .subscribe(() => {
          this.employees.splice(idx, 1);
          [this.employeeErrors, this.employeeInvalid] = this.validateEmployees(
            this.employees
          );
        });
    }
  }

  solveHours(index): void {
    this.employees[index].totalyHours =
      this.employees[index].hoursPerWeek * this.employees[index].weeksPerYear;
    const inputKey = Object.keys(this.inputs).find(el =>
      el.includes('employees')
    );
    if (this.validationTriggered.getValue()) {
      [this.employeeErrors, this.employeeInvalid] = this.validateEmployees(
        this.employees
      );
    }
    const contentChanged = JSON.stringify(this.employees);

    if (this.inputs[inputKey].content !== contentChanged) {
      this.inputs[inputKey].content = contentChanged;
      this.contentChanged(this.inputs[inputKey]);
    }
  }

  saveRevenue(): void {
    const revenueInput = this.inputs.marketing_organization_design_2_budget;
    if (revenueInput && revenueInput.content !== this.revenue.toString()) {
      revenueInput.content = this.revenue.toString();
      this.contentChanged(revenueInput);
    }
  }

  saveMarketingRevenue(): void {
    const changedContent = this.marketingRevenue?.toString() || null;
    const marketingRevenueInput = this.inputs
      .marketing_organization_design_3_budget;
    if (
      marketingRevenueInput &&
      marketingRevenueInput.content !== changedContent
    ) {
      marketingRevenueInput.content = changedContent;
      this.contentChanged(marketingRevenueInput);
    }
  }

  calculateBudgetRevenue(): void {
    const input = this.inputs.marketing_organization_design_1_budget;

    this.marketingBudget = this.revenue * (this.marketingRevenue / 100);
    if (input.content !== this.marketingBudget.toString()) {
      input.content = this.marketingBudget.toString();
      this.contentChanged(input);
    }
  }

  private validateEmployees(employees: Employee[]): [EmployeeError[], boolean] {
    let employeeInvalid = false;
    const employeeErrors: EmployeeError[] = employees.map(employee => {
      const employeeError = {
        title: !employee.title,
        hoursPerWeek:
          employee.hoursPerWeek === undefined ||
          employee.hoursPerWeek === null ||
          !String(employee.hoursPerWeek),
        weeksPerYear:
          employee.weeksPerYear === undefined ||
          employee.weeksPerYear === null ||
          !String(employee.weeksPerYear)
      };
      employeeInvalid =
        employeeInvalid ||
        employeeError.title ||
        employeeError.hoursPerWeek ||
        employeeError.weeksPerYear;

      return employeeError;
    });

    return [employeeErrors, employeeInvalid];
  }

  private getInputBudget(): number {
    return this.strategyBlocks.reduce(
      (blockAccumulator, currentBlock) =>
        blockAccumulator +
        currentBlock.budget +
        (currentBlock.strategies
          ? currentBlock.strategies.reduce(
              (strategyAccumulator, currentStrategy) =>
                strategyAccumulator +
                (currentStrategy.budget || 0) +
                (currentStrategy.resources
                  ? currentStrategy.resources.reduce(
                      (resourceAccumulator, currentResource) =>
                        resourceAccumulator + (currentResource.budget || 0),
                      0
                    )
                  : 0),
              0
            )
          : 0),
      0
    );
  }

  private calculateScoreValue(
    contribution: ResourceCircles,
    cost: ResourceCircles
  ): number {
    const colors: ResourceColors[] = [
      this.contributionColor[contribution],
      this.costColor[cost]
    ];
    if (colors.filter(Boolean).length !== 2) {
      return 0;
    }
    if (colors.every(color => color === ResourceColors.Green)) {
      return 5;
    }
    if (
      colors.some(color => color === ResourceColors.Green) &&
      colors.some(color => color === ResourceColors.Yellow)
    ) {
      return 4;
    }
    if (
      [
        colors.every(color => color === ResourceColors.Yellow),
        !!colors.some(color => color === ResourceColors.Green) &&
          colors.some(color => color === ResourceColors.Red)
      ].some(Boolean)
    ) {
      return 3;
    }
    if (
      colors.some(color => color === ResourceColors.Yellow) &&
      colors.some(color => color === ResourceColors.Red)
    ) {
      return 2;
    }

    return 1;
  }

  private sortStrategyBlockByScore(): void {
    this.strategyBlocks.forEach(block => {
      if (block.strategies) {
        block.strategies.sort((a, b) => b.scoreValue - a.scoreValue);
        block.strategies.forEach(strategy => {
          if (strategy.resources) {
            strategy.resources.sort((a, b) => b.scoreValue - a.scoreValue);
          }
        });
      }
    });
  }

  private addNewKpiToStrategy(
    blockIndex: number,
    strategyIndex: number,
    kpiName: string
  ): void {
    const strategy = this.strategyBlocks[blockIndex].strategies[strategyIndex];
    const kpi: KPI = { name: kpiName };
    if (strategy.kpi) {
      strategy.kpi.push(kpi);
    } else {
      strategy.kpi = [kpi];
    }
    this.saveMarketingInput(blockIndex);
  }

  private getStrategyBlocks(): StrategyBlock[] {
    const blockRepeaterContent: BlockRepeaterContent = this.inputs[
      this.blockRepeaterKey
    ]
      ? JSON.parse(this.inputs[this.blockRepeaterKey].content)
      : null;
    const array = [];
    blockRepeaterContent?.blocks.forEach((item, index) => {
      let title = null;
      let checkbox = null;
      let stepId = null;
      let name = null;
      let hours = null;
      const block = item.data;

      Object.keys(block).forEach(step => {
        Object.keys(block[step]).forEach(input => {
          if (input.includes('title')) {
            title = block[step][input];
          }

          if (input.includes('checkbox')) {
            checkbox = block[step][input];
          }

          if (input.includes('name')) {
            name = block[step][input];
          }

          if (input.includes('fb_3')) {
            hours = block[step][input];
          }
        });
        if (checkbox && !stepId) {
          stepId = step;
        }
      });

      if (checkbox) {
        array.push({
          ...checkbox,
          title: title.content,
          numberOfBlock: index,
          stepId,
          hours: hours.content,
          name: name.content
        });
      }
    });

    return array.map(strategyBlock => {
      const ret: StrategyBlock = {
        marketingHead: '',
        inputName: strategyBlock.element_key,
        title: strategyBlock.title,
        numberOfBlock: strategyBlock.numberOfBlock,
        stepId: strategyBlock.stepId,
        name: strategyBlock.name,
        hours: strategyBlock.hours,
        strategies: JSON.parse(strategyBlock.content)?.map(checkboxContent => ({
          ...checkboxContent,
          task: checkboxContent.task
            ? checkboxContent.task
            : [
                {
                  canMemberExecuteTask: null,
                  jobType: null,
                  taskExecutionDate: null,
                  hours: null,
                  resources: null,
                  kpi: null
                }
              ]
        }))
      };

      return ret;
    });
  }

  private addNewTrackToKpi(
    blockIndex: number,
    strategyIndex: number,
    kpiIndex: number,
    trackName: string
  ): void {
    const strategy = this.strategyBlocks[blockIndex].strategies[strategyIndex]
      .kpi[kpiIndex];
    if (!strategy.trackingMethod) {
      strategy.trackingMethod = [];
    }
    strategy.trackingMethod.push(trackName);
    this.saveMarketingInput(blockIndex);
  }

  private getIdKey(data: BlockData) {
    return Object.keys(data).find(el =>
      Object.keys(data[el])[0].includes('select_strategies_cs')
    );
  }
}
