import { Component, forwardRef, HostListener, OnInit } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import {
  inputKeys,
  OpportunitySummaryTemplateData,
  subtitles,
  Title,
  titles
} from '.';
import txt from '!!raw-loader!./index.ts';

declare interface InputData {
  [step: string]: {
    [inputField: string]: {
      [num: number]: string[] | string | null;
    };
  };
}

@Component({
  selector: 'opportunity-summary',
  templateUrl: 'opportunity-summary.component.html',
  styleUrls: ['./opportunity-summary.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => OpportunitySummaryComponent)
    }
  ]
})
export class OpportunitySummaryComponent extends TemplateComponent
  implements OnInit {
  params = txt;
  inputKeys = inputKeys;
  title: string[] = [];
  subtitles = subtitles;
  progressBar = Object.values(titles);
  inputData: InputData = {};
  contentData: OpportunitySummaryTemplateData['template_params_json'];

  steps = ['recognizes', 'need', 'options', 'risk', 'solution', 'realizes'];
  partnerActivities: { [key: string]: string } = {};
  stages: any = [];

  questionInformation: any = {};
  currentStep = this.steps[0];
  isPrint = false;

  @HostListener('window:beforeprint', ['$event'])
  @HostListener('window:afterprint', ['$event'])
  onPrint() {
    this.isPrint = !this.isPrint;
  }

  ngOnInit() {
    super.ngOnInit();
    this.progressBar.forEach((item: Title) => this.title.push(item.title));
    this.stages = JSON.parse(
      this.getInput(`matrix_entry_1_stage_defs_sx1`).content
    );
    this.parseInputData();
    this.setOrientationPrintLayout('landscape');
    this.getQuestionTitles();
  }

  parseInputData() {
    for (const [index, step] of this.steps.entries()) {
      this.inputData[step] = {};
      Object.keys(this.inputKeys).forEach(key => {
        this.inputData[step][key] = {};
        for (let i = 1; i <= 3; i++) {
          this.inputData[step][key][i] = [];
          const data =
            this.getInput(
              `matrix_entry_${i}${this.inputKeys[key]}${index + 1}`
            ) ||
            this.getInput(`brainstorm_${i}${this.inputKeys[key]}${index + 1}`);

          if (key === 'salesActivities' && i === 1) {
            const partnerActivities = this.getInput(
              `brainstorm_${i}${this.inputKeys[key]}questions_${index + 1}`
            );

            this.partnerActivities[step] = partnerActivities.content;

            this.inputData[step][key][i] = data
              ? JSON.parse(data.content)
                  .map(el => {
                    const isMajorInteraction = el.shift().data;

                    const res = el
                      .filter(input => !!input.data)
                      .map(input => input.data);

                    return (
                      '<ul class="sales-activity ' +
                      (isMajorInteraction ? 'major-interaction' : '') +
                      '"><li>' +
                      res.join('</li><li>') +
                      '</li></ul>'
                    );
                  })
                  .join('')
              : null;
          } else {
            if (data) {
              this.inputData[step][key][i] = this.strip(data.content);
            }
          }
        }
      });
    }
  }

  toggleStep(step: string) {
    if (this.currentStep === step) {
      return;
    }
    this.currentStep = step;
  }

  /**
   * Get `recognizes` questions title
   */
  getQuestionTitles(): void {
    const orgId = this.navService.lastOrganization.current;
    const modId = this.navService.module.current;

    this.moduleContentService.loadStepData(modId, 10145, orgId).subscribe(
      (response: any) => {
        this.questionInformation = response.body.template_params_json.questions;
      },
      err => console.warn
    );
  }

  getDescription() {
    return 'Opportunity Management summary template';
  }

  getName() {
    return 'Opportunity Summary';
  }

  getGroup() {
    return 'Outputs';
  }

  get input() {
    return this.getInput('opportunity_summary');
  }

  private strip(html: string): string[] {
    if (!html) {
      return [''];
    }
    const doc = new DOMParser().parseFromString(html, 'text/html');
    const childText = Array.from(doc.body.children || []).map(
      i => i.textContent
    );
    Array.from(doc.body.children || []).forEach(i => doc.body.removeChild(i));
    const leftText = doc.body.textContent;
    const text = [...childText, leftText].filter(Boolean);

    return text.length ? text : [''];
  }
}
