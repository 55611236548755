import { Component, Input, OnChanges, OnInit, OnDestroy } from '@angular/core';
import { TemplateComponent } from '../../template-base.class';
import { ModuleService } from 'src/app/common/services/module.service';
import { SegmentCriteria } from '..';
import { TemplateInput } from '../../../../../common/interfaces/module.interface';
import { ConfirmationService } from '../../../../../common/services/confirmation.service';
import { ModuleNavService } from '../../../../../common/services/module-nav.service';
import { ModuleContentService } from '../../../../../common/services/module-content.service';
import { combineLatest } from 'rxjs';
import ModuleContent from '../../../../../common/interfaces/module-content.model';
import { switchMap, takeWhile } from 'rxjs/operators';
import { Validate } from '../../../../../common/validator.class';

@Component({
  selector: 'app-icp-input',
  templateUrl: './icp-input.component.html',
  styleUrls: ['./icp-input.component.scss']
})
export class IcpInputComponent implements OnChanges, OnInit, OnDestroy {
  @Input() mode: 'criteria' | 'weight' | 'grade';
  @Input() inputPrefix;
  @Input() criterias;
  @Input() inputIndex: number;
  @Input() review = false;
  @Input() gradeLevels: { A: number; B: number; C: number; D: number };

  selectedGrades: { [criteriaIndex: number]: number } = {};

  calcWeightSum = 0;
  calcAllWeightsSelected = false;
  calcGradeSum = 0;
  calcAllGradesSelected = false;

  isStepDone = false;
  moduleContent: ModuleContent;

  instanceExists = true;

  constructor(
    public template: TemplateComponent,
    private moduleService: ModuleService,
    private confirmationService: ConfirmationService,
    private moduleNavService: ModuleNavService,
    private moduleContentService: ModuleContentService
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.moduleNavService.step$,
      this.moduleNavService.organization$,
      this.moduleNavService.module$
    ])
      .pipe(
        takeWhile(_ => this.instanceExists),
        switchMap(([stepId, orgId, moduleId]) =>
          this.moduleContentService.load(moduleId, stepId, orgId, true)
        )
      )
      .subscribe(moduleContent => {
        this.moduleContent = moduleContent;
        this.isStepDone = moduleContent.is_checked;
      });
  }

  ngOnDestroy(): void {
    this.instanceExists = false;
  }

  ngOnChanges() {
    if (Object.values(this.selectedGrades).length) {
      this.resetGradeSelection();
    }

    this.initGrades();
    this.syncCriteria(true, true);
  }

  initGrades() {
    const inp = this.template.getInput(this.inputPrefix, this.inputIndex, '');
    this.selectedGrades = inp && inp.content ? JSON.parse(inp.content) : {};

    // in case the grade weight has been changed, make sure previously set values don't exceed the new threshold
    this.selectedGrades = Object.entries(this.selectedGrades).reduce(
      (grades, entry) => {
        if (this.criterias[entry[0]]) {
          grades[entry[0]] = Math.min(
            entry[1],
            this.criterias[entry[0]].weight
          );
        }

        return grades;
      },
      {}
    );

    this.syncGrade(true);
  }

  gradeChange(
    weight: number,
    gradeId: number,
    gradeInput: HTMLInputElement
  ): void {
    if (weight <= this.criterias[gradeId].weight) {
      this.selectedGrades[gradeId] = weight;
      this.syncGrade();
    } else {
      gradeInput.value = String(this.selectedGrades[gradeId]);
    }
  }

  syncGrade(onlyCalculate = false) {
    this.calcGradeSum = this.gradeSum();
    this.calcAllGradesSelected = this.allGradesSelected();

    if (this.calcAllGradesSelected) {
      const input = this.template.getInput(
        this.inputPrefix,
        this.inputIndex,
        ''
      );

      input.content = JSON.stringify(this.selectedGrades);

      this.moduleService.saveInput(input).subscribe();
    }
  }

  gradeSum() {
    return Object.values(this.selectedGrades || {}).reduce(
      (total, grade) => total + grade,
      0
    );
  }

  allGradesSelected() {
    return (
      this.criterias &&
      Object.values(this.selectedGrades).filter(a => a !== null).length ===
        this.criterias.length
    );
  }

  resetGradeSelection() {
    this.selectedGrades = {};
    this.syncGrade();
    this.initGrades();
  }

  getEmptyCriteria() {
    const emptyDef = JSON.stringify({ content: '', comments_json: '' });

    return {
      name: JSON.parse(emptyDef),
      description: JSON.parse(emptyDef),
      weight: 0
    };
  }

  addCriteria() {
    if (this.criterias.length < 5) {
      this.criterias.push(this.getEmptyCriteria());
    }
  }

  removeCriteria(idx: number, input: TemplateInput): void {
    if (this.criterias.length > 3) {
      this.template.decorateInput(input);
      this.confirmationService.removeDialog().subscribe(() => {
        this.criterias.splice(idx, 1);
        this.syncCriteria();
      });
    }
  }

  pointsSum(criteria: SegmentCriteria[]) {
    return criteria
      ? criteria.reduce((sum, cr) => sum + (cr.weight || 0), 0)
      : 0;
  }

  allWeightsSelected(criteria: SegmentCriteria[]) {
    return criteria ? !criteria.find(cr => !cr.weight) : false;
  }

  weightChange(
    weight: number,
    weightId: number,
    weightInput: HTMLInputElement
  ): void {
    if (weight === null || (weight <= 100 && weight > 0)) {
      this.criterias[weightId].weight = weight;
      if (this.pointsSum(this.criterias) > 100) {
        this.balanceCriterias(weightId);
      }
      this.syncCriteria(true, false);
    }
    if (this.criterias[weightId].weight) {
      weightInput.value = String(this.criterias[weightId].weight);
    }
  }

  syncCriteria(validateWeight = false, onlyCalculate = false) {
    const input = this.template.getInput('criteria', this.inputIndex);

    this.calcWeightSum = this.pointsSum(this.criterias);
    this.calcAllWeightsSelected = this.allWeightsSelected(this.criterias);

    if (this.isStepDone && this.calcWeightSum !== 100) {
      this.moduleService
        .markAsDone(
          this.moduleContent.module_id,
          this.moduleContent.org_id,
          this.moduleContent.step_id,
          false
        )
        .subscribe(_ => {
          this.isStepDone = false;
          this.moduleService.moduleChanged$.next(true);
        });
    }

    if (validateWeight) {
      if (!this.calcAllWeightsSelected && this.calcWeightSum !== 100) {
        return;
      }
    }

    if (!onlyCalculate) {
      input.content = JSON.stringify(
        this.criterias.map(c => ({
          description: {
            comments_json: c.description.comments_json,
            content: c.description.content
          },
          name: {
            comments_json: c.name.comments_json,
            content: c.name.content
          },
          weight: c.weight || 0
        }))
      );

      this.moduleService.saveInput(input).subscribe();
    }
  }

  validate() {
    if ('criteria' === this.mode) {
      return this.criterias.reduce((isValid, criteria) => {
        ['name', 'description'].forEach((field, index) => {
          this.template.decorateInput(criteria[field]);
          const availableCharactersCount = index ? 120 : 30;
          if (
            !this.template.validateInput(criteria[field], [
              Validate.maxCharacters(availableCharactersCount),
              Validate.required('Please fill out this field')
            ])
          ) {
            isValid = false;
          }
        });

        return isValid;
      }, true);
    } else if ('weight' === this.mode) {
      return (
        this.criterias.reduce((isValid, criteria) => {
          this.template.decorateInput(criteria);

          if (criteria.weight <= 0) {
            isValid = false;
            criteria.error.next('Select a weight for this criteria');
          } else {
            criteria.error.next(null);
          }

          return isValid;
        }, true) &&
        this.allWeightsSelected(this.criterias) &&
        this.pointsSum(this.criterias) === 100
      );
    } else if ('grade' === this.mode) {
      return this.allGradesSelected();
    }

    return false;
  }

  private balanceCriterias(changedCriteriaIndex: number): void {
    const criteriaSum = this.pointsSum(this.criterias);
    const pointsExcess = criteriaSum - 100;
    const nonChangedSum =
      criteriaSum - this.criterias[changedCriteriaIndex].weight;
    const balancedSum = nonChangedSum - pointsExcess;
    let biggestNumber = [null, null];
    const roundedCriterias: SegmentCriteria[] = this.criterias.map(
      (criteria: SegmentCriteria, index) => {
        if (
          index === changedCriteriaIndex ||
          !criteria.weight ||
          criteria.weight === 0
        ) {
          return criteria;
        } else {
          const roundedWeight = Math.round(
            (criteria.weight / nonChangedSum) * balancedSum
          );
          if (biggestNumber[1] === null || biggestNumber[1] < roundedWeight) {
            biggestNumber = [index, roundedWeight];
          }

          return {
            ...criteria,
            weight: roundedWeight < 1 ? 1 : roundedWeight
          };
        }
      }
    );
    const currentSum = this.pointsSum(roundedCriterias);
    const currentDifference = 100 - currentSum;
    if (
      biggestNumber[0] !== null &&
      biggestNumber[1] + currentDifference >= 1
    ) {
      roundedCriterias[biggestNumber[0]].weight =
        roundedCriterias[biggestNumber[0]].weight + currentDifference;
    } else if (biggestNumber[1] + currentDifference < 1) {
      const acceptableCriteria = roundedCriterias.find(
        criteria => criteria.weight + currentDifference >= 1
      );
      if (acceptableCriteria) {
        acceptableCriteria.weight =
          acceptableCriteria.weight + currentDifference;
      }
    }

    this.criterias = roundedCriterias;
  }
}
