import BaseModel from './base.model';

class User extends BaseModel {
  name = '';
  fname = '';
  lname = '';
  email = '';
  abbreviation = '';
  id = '';
  profile_picture = '';
  is_riverside_rmcf_admin? = false;
  is_super_admin? = false;
  is_riverside_managing_director = false;
  is_reiverside_sales_rep? = false;
  fund? = '';
  permissions = {
    riversideRMCFDashboard: false,
    riversideSalesDashboard: false,
    riversideModuleEditor: false,
    riversideRequestFeedback: false,
    riversideProvideFeedback: false,
    riversideManageRMCFUsers: false,
    riversideRMCFAdmin: false
  };

  protected transform() {
    return {
      name: (val: any, data: User) => `${data.fname} ${data.lname}`,
      abbreviation: (val: any, data: User) =>
        `${data.fname.substr(0, 1).toUpperCase()}${(
          data.lname.substr(0, 1) || data.fname.substr(1, 1)
        ).toUpperCase()}`
    };
  }
}
export default User;
