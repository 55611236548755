import { Component, forwardRef } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import { InputData, SalesLeadershipSellingTimeTemplateData } from '.';
import txt from '!!raw-loader!./index.ts';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem
} from '@angular/cdk/drag-drop';
import { Validation } from '../../../../common/validator.class';
import { of } from 'rxjs';

@Component({
  selector: 'sales-leadership-selling-time',
  templateUrl: 'sales-leadership-selling-time.component.html',
  styleUrls: ['./sales-leadership-selling-time.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => SalesLeadershipSellingTimeComponent)
    }
  ]
})
export class SalesLeadershipSellingTimeComponent extends TemplateComponent {
  params = txt;
  contentData: SalesLeadershipSellingTimeTemplateData['template_params_json'];

  days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
  droppedDays: string[] = [];
  inputData: InputData;

  private emptyRowTitle = 'Should fill days';
  private inputValidators: Validation[];

  get input() {
    return this.getInput('sales_leadership_selling_time_1');
  }

  getDescription() {
    return '';
  }

  getName() {
    return 'Sales Leadership Selling Time';
  }

  getGroup() {
    return 'Module';
  }

  init() {
    super.init();
    this.inputData = this.salesLeadershipSellingTimeInputData;
    this.inputValidators = this.getValidators();
  }

  validate() {
    return of(this.validateInput(this.input, this.inputValidators));
  }

  drop(event: CdkDragDrop<string[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
    this.updateData();
  }

  updateData(): void {
    this.fillTimeTitle();
    this.input.content = JSON.stringify(this.inputData);
    this.contentChanged(this.input, this.inputValidators);
  }

  private buildInputData(): InputData {
    const data = {
      days: this.days,
      droppedDays: this.droppedDays,
      times: []
    };
    for (let i = 0; i !== 2; i++) {
      data.times.push({
        title: this.emptyRowTitle,
        value: [
          { title: '8:00 AM - 10:00 AM', isChecked: false },
          { title: '10:00 AM - 12:00 PM', isChecked: false },
          { title: '12:00 PM - 2:00 PM', isChecked: false },
          { title: '2:00 PM - 4:00 PM', isChecked: false },
          { title: '4:00 PM - 6:00 PM', isChecked: false }
        ]
      });
    }

    return data;
  }

  private fillTimeTitle(): void {
    this.inputData.times.forEach(
      (t, i) =>
        (t.title =
          this.inputData.droppedDays.length === 5
            ? this.inputData.droppedDays[i + 3]
            : this.emptyRowTitle)
    );
  }

  private getValidators(): Validation[] {
    const rankEachDayMessage =
      'Please drag and drop each day of the week into the scale on the right';
    const rankEachDayFunc = (value: string) => {
      const content: InputData = JSON.parse(value || null);

      return content && !content.days?.length;
    };

    return [new Validation(rankEachDayFunc, rankEachDayMessage)];
  }

  get salesLeadershipSellingTimeInputData(): InputData {
    const data = JSON.parse(
      this.input.getValue() || JSON.stringify(this.buildInputData())
    );

    // replace 10:00 PM to 10:00 AM in existing data
    data.times = data.times.map(day => ({
      ...day,
      value: day.value.map(entry => ({
        ...entry,
        title: entry.title.split('10:00 PM').join('10:00 AM')
      }))
    }));

    return data;
  }
}
