import {
  Component,
  ComponentFactory,
  ComponentRef,
  forwardRef,
  ViewChild
} from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import {
  BlockRepeaterTemplateParams,
  BlockRepeaterWrapperComponentTemplate,
  BlockData,
  BlockRepeaterContent,
  InputRepeaterBlock
} from '.';
import txt from '!!raw-loader!./index.ts';
import {
  InputDictionary,
  Module,
  Step,
  TemplateInput
} from '../../../../common/interfaces/module.interface';
import { RTemplateDirective } from '../../riverside-step-template-host.directive';
import ModuleContent from '../../../../common/interfaces/module-content.model';
import { TemplateContentData } from '../template-data.class';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';
import { BlockRepeaterResultParamsData } from '../block-repeater-result';
import { parse, stringify } from 'flatted';
import { BuyerPersona } from '../../../../common/interfaces/buyer-persona.interface';
import { ModuleResultParamsData } from '../module-result';
import { BlockRepeaterWrapperComponent } from './block-repeater-wrapper/block-repeater-wrapper.component';
import { Validation } from '../../../../common/validator.class';
import { ExportService } from '../../../../common/services/export.service';
import { updateBlockRepeaterFormat } from './helpers';
import { v4 as uuid4 } from 'uuid';
import { Question } from '../brainstorm';

@Component({
  selector: 'block-repeater',
  templateUrl: 'block-repeater.component.html',
  styleUrls: ['./block-repeater.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => BlockRepeaterComponent)
    }
  ]
})
export class BlockRepeaterComponent extends TemplateComponent {
  @ViewChild(RTemplateDirective, { static: true })
  templateHost: RTemplateDirective;

  params = txt;
  contentData: BlockRepeaterTemplateParams;
  prefix = 'block_repeater_1';

  moduleToCopy: ModuleContent[];
  wrapperComponentsRefs: ComponentRef<BlockRepeaterWrapperComponent>[] = [];
  blocksData: BlockRepeaterContent;
  counter = 0;
  steps: Step[] = [];
  buyerPersonas: BuyerPersona[];
  activeTabIndex = 0;
  tabNames: string[];
  tabTypes = ['tabs_from_api', 'tabs_from_builder'];
  empty_api_message: string;
  hasTabs = false;
  currentOrg: number;
  loading = false;
  hasError = false;
  blockRepeaterError =
    'An error was found at this step. Please contact with admin!';

  private inputKey: string;
  private exportService: ExportService;

  getDescription() {
    return 'Block repeater';
  }

  getName() {
    return 'Block repeater';
  }

  getGroup(): string {
    return 'Generic';
  }

  getInput(): TemplateInput {
    return this.inputs[this.inputKey];
  }

  init() {
    super.init();
    this.exportService = this.injectorObj.get(ExportService);

    this.contentData = this.data.data
      .template_params_json as BlockRepeaterTemplateParams;

    this.empty_api_message = this.textContent(
      this.contentData.message_to_show_when_API_response_is_empty || ''
    );
    if (!this.contentData.step_type_select) {
      this.contentData.step_type_select = 'no_tabs';
    }
    this.hasTabs = this.tabTypes.includes(this.contentData.step_type_select);

    this.inputKey = this.contentData.input_sufix
      ? `${this.prefix}_${this.contentData.input_sufix}`
      : this.prefix;

    const buyerPersonaObservable: Observable<BuyerPersona[]> = this.contentData
      .use_buyer_persona_as_source
      ? this.buyerPersonasList$.pipe(
          tap(personas => (this.buyerPersonas = personas))
        )
      : of(null);

    buyerPersonaObservable
      .pipe(
        switchMap(() => this.navService.moduleDataReplay$),
        this.whileExists(),
        take(1),
        switchMap((module: Module) =>
          module?.id === this.data.data.module_id
            ? of(module)
            : this.moduleService.getOrgModule(
                this.data.data.module_id,
                this.data.data.org_id,
                true
              )
        )
      )
      .subscribe((module: Module) => {
        this.steps = module.steps.filter(
          step => step.parent_step_id === this.data.data.step_id
        );
        this.loading = true;
        this.moduleContentService
          .loadSteps(
            this.data.data.module_id,
            this.data.data.org_id,
            this.steps.map(step => step.id)
          )
          .pipe(this.whileExists())
          .subscribe(
            (modules: ModuleContent[]) => {
              this.loading = false;
              this.moduleToCopy = modules;
              if (this.contentData.api_url) {
                this.navService.organization$
                  .pipe(
                    take(1),
                    switchMap(orgId => {
                      this.currentOrg = orgId;
                      this.loading = true;

                      return this.moduleService.getAPIData(
                        orgId,
                        this.contentData.api_url
                      );
                    }),
                    tap(() => (this.loading = false))
                  )
                  .subscribe(
                    (data: []) => {
                      this.setBlockRepeaterData();
                      this.initTabs(data);
                      this.initTabsData(data);
                    },
                    () => {
                      this.tabNames = [];
                      this.hasError = true;
                    }
                  );
              } else {
                this.setBlockRepeaterData();
                if (this.contentData.step_type_select === 'tabs_from_builder') {
                  this.initTabs();
                  this.initTabsData(this.tabNames);
                } else {
                  this.initTemplateData();
                }
              }
            },
            () => (this.hasError = true)
          );
      });
  }

  validate() {
    return forkJoin(
      this.wrapperComponentsRefs.map(ref => ref.instance.validate())
    ).pipe(
      tap(validations => {
        if (this.hasTabs) {
          const isCurrentTabValid = validations[this.activeTabIndex];
          const firstInvalidTab = validations.findIndex(isValid => !isValid);
          if (isCurrentTabValid && firstInvalidTab !== -1) {
            this.activateTab(firstInvalidTab);
          }
        }
      }),
      map(validations => validations.every(Boolean))
    );
  }

  initTemplates(
    modules: ModuleContent[],
    index: number,
    source = this.blocksData.blocks
  ): void {
    let indexForDelete;
    const viewContainerRef = this.templateHost.viewContainerRef;
    const componentFactory: ComponentFactory<BlockRepeaterWrapperComponent> = this.componentFactoryResolver.resolveComponentFactory(
      this.templateService.templates.block_repeater_wrapper
    );
    const componentRef: ComponentRef<BlockRepeaterWrapperComponent> = viewContainerRef.createComponent(
      componentFactory
    );
    this.wrapperComponentsRefs.push(componentRef);
    const templateInstance: BlockRepeaterWrapperComponentTemplate =
      componentRef.instance;
    modules[0].disabled = this.data.data.disabled;
    modules[0].hidden = this.hasTabs && this.activeTabIndex !== index;

    templateInstance.data = new TemplateContentData({
      data: modules[0],
      me: this.me,
      canModify: modules[0].can_modify
    });

    templateInstance.blockRepeaterModules = modules;
    templateInstance.getInput = this.getInput;
    templateInstance.tabName = (this.tabNames && this.tabNames?.[index]) || '';
    templateInstance.showTabName = this.contentData.show_tab_name_in_content;
    if (
      this.contentData.use_buyer_persona_as_source &&
      this.buyerPersonas?.[index]
    ) {
      templateInstance.buyerPersona = this.buyerPersonas[index];
      templateInstance.showBuyerPersona = !this.hasTabs;
    }
    if (this.contentData.wrapper_content) {
      templateInstance.wrapperContent = this.contentData.wrapper_content;
    }

    templateInstance.blockRepeaterContentChanged = (
      data: TemplateInput,
      step_id: number,
      validators?: Validation[]
    ) => {
      const input = this.getInput();
      const newContent: BlockRepeaterContent = {
        id: this.blocksData.id,
        blocks: this.removeCircularStructureFromBlockData(
          this.blocksData.blocks
        )
      };
      source[index].data[step_id][data.element_key] = data;
      input.content = JSON.stringify(newContent);
      this.contentChanged(input);
    };

    if (this.hasTabs) {
      componentRef.location.nativeElement.className = 'block-for-tabs';

      if (this.counter === 0) {
        componentRef.location.nativeElement.className += ' active';
      }
    }

    indexForDelete = this.counter++;

    if (this.contentData.allow_to_remove_blocks) {
      templateInstance.removeBlock = () => {
        viewContainerRef.remove(indexForDelete);
        this.initCapTemplate(modules[0], indexForDelete);
        this.wrapperComponentsRefs.splice(index, 1);
        indexForDelete--;

        delete this.blocksData.blocks[index];
        this.blocksData.blocks = this.blocksData.blocks.filter(
          el => !!el && !!el.data
        );
        const input = this.getInput();
        const newContent: BlockRepeaterContent = {
          id: this.blocksData.id,
          blocks: this.removeCircularStructureFromBlockData(
            this.blocksData.blocks
          )
        };
        input.content = JSON.stringify(newContent);

        this.contentChanged(input);
      };
    }
  }

  setBlockRepeaterData() {
    const input = this.getInput();
    const content: BlockRepeaterContent = input.content
      ? updateBlockRepeaterFormat(JSON.parse(input.content))
      : { id: `block-repeater-${uuid4()}`, blocks: [] };
    content.blocks = this.updateInputsInExistedBlocks(content.blocks);
    this.blocksData = content;
  }

  initTemplateData() {
    const defaultDataCount = this.contentData.use_buyer_persona_as_source
      ? this.buyerPersonas?.length || 0
      : this.contentData.default_data_count || 0;
    const currentBlockNumber = this.blocksData?.blocks?.length || 0;
    const difference = defaultDataCount - currentBlockNumber;
    if (difference > 0) {
      for (let i = 0; i < difference; i++) {
        this.populateData(currentBlockNumber + i);
      }
    } else if (difference < 0 && this.contentData.use_buyer_persona_as_source) {
      this.blocksData.blocks?.splice(
        this.blocksData.blocks.length - -difference,
        -difference
      );
    }

    this.blocksData?.blocks?.forEach((steps, index) => {
      const templateDataForInit = this.prepareTemplateToRender(
        steps.data,
        index,
        index
      );

      if (!templateDataForInit.length) {
        return;
      }

      this.initTemplates(templateDataForInit, index);
    });
  }

  initTabsData(data = []): void {
    let componentData = this.blocksData?.blocks;
    const currentBlockNumber = componentData?.length || 0;
    const difference = data.length - currentBlockNumber;

    if (difference > 0) {
      for (let i = 0; i < difference; i++) {
        this.populateData(currentBlockNumber + i);
      }
    } else if (difference < 0) {
      componentData?.splice(componentData.length - -difference, -difference);
      componentData = componentData.filter(el => !!el && !!el.data);
    }

    const dataForInit = componentData
      .map((steps, index) =>
        this.prepareTemplateToRender(steps.data, index, index)
      )
      .filter(steps => !!steps.length);
    const resultSteps = dataForInit[0]?.filter(
      step => step.template_component === 'block_repeater_result'
    );
    if (resultSteps?.length) {
      this.loading = true;
      this.getResultChildSteps(resultSteps).subscribe(stepContent => {
        if (stepContent) {
          stepContent.forEach((steps, index) => {
            dataForInit.forEach(moduleContent => {
              moduleContent[index].options = {
                ...moduleContent[index].options,
                resultSteps: stringify(steps)
              };
            });
          });
        }
        this.loading = false;
        dataForInit.forEach((steps, index) =>
          this.initTemplates(steps, index, componentData)
        );
      });
    } else {
      dataForInit.forEach((steps, index) =>
        this.initTemplates(steps, index, componentData)
      );
    }
  }

  copyStep() {
    const moduleToCopy = JSON.parse(
      JSON.stringify(
        this.removeCircularStructureFromModuleContent(this.moduleToCopy)
      )
    );
    const input = this.getInput();

    this.populateData();

    moduleToCopy.forEach(module => {
      module.instance_index = this.blocksData.blocks.length - 1;
      module.options = {
        ...module.options,
        tabIndex: Number(this.blocksData.blocks.length - 1)
      };
    });

    this.initTemplates(moduleToCopy, this.blocksData.blocks.length - 1);

    const newContent: BlockRepeaterContent = {
      id: this.blocksData.id,
      blocks: this.removeCircularStructureFromBlockData(this.blocksData.blocks)
    };
    input.content = JSON.stringify(newContent);
    this.contentChanged(input);
  }

  initCapTemplate(module, index) {
    const viewContainerRef = this.templateHost.viewContainerRef;
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      this.templateService.templates.generic
    );
    const componentRef = viewContainerRef.createComponent(
      componentFactory,
      index
    );
    const templateInstance: BlockRepeaterWrapperComponentTemplate = componentRef.instance as BlockRepeaterWrapperComponentTemplate;
    module.disabled = this.data.data.disabled;

    templateInstance.data = new TemplateContentData({
      data: module,
      me: this.me,
      canModify: module.can_modify
    });
    if (this.contentData.step_type_select !== 'no_tabs') {
      templateInstance.tabName = this.tabNames?.[index];
    }
    templateInstance.showTabName = this.contentData.show_tab_name_in_content;
    if (this.contentData.wrapper_content) {
      templateInstance.wrapperContent = this.contentData.wrapper_content;
    }
  }

  initTabs(data?: []) {
    if (this.contentData.step_type_select === 'tabs_from_builder') {
      this.tabNames = this.contentData.use_buyer_persona_as_source
        ? this.buyerPersonas.map(persona => persona.name)
        : this.contentData.tabs_list.map(tab => this.textContent(tab.value));
    }

    if (this.contentData.step_type_select === 'tabs_from_api') {
      const property = this.textContent(this.contentData.tabs_data_field);
      this.tabNames = data.map(el => el[property]);
    }
  }

  setDefaultDataByJSON(step: ModuleContent, stepId: string): void {
    const jsonDefaultValue = JSON.parse(
      this.contentData.json
        .replace('<p>', '')
        .replace('</p>', '')
        .replace(/&quot;/g, '"')
        .replace(/&amp;/g, '&')
    );
    if (
      step.template_component === 'brainstorm' &&
      jsonDefaultValue[step.instance_index] &&
      jsonDefaultValue[step.instance_index][stepId]
    ) {
      const defaultValueLabel = 'default_value';
      (step.template_params_json as any).questions.forEach(
        (question: Question, index) => {
          question[defaultValueLabel] =
            jsonDefaultValue[step.instance_index][stepId][index][
              defaultValueLabel
            ];
        }
      );
    }
    if (
      step.template_component === 'checkbox' &&
      jsonDefaultValue[step.instance_index] &&
      jsonDefaultValue[step.instance_index][stepId]
    ) {
      step.template_params_json = {
        ...step.template_params_json,
        options: jsonDefaultValue[step.instance_index][stepId]
      };
    }
  }

  prepareTemplateToRender(
    steps: BlockData,
    tabIndex = -1,
    instanceIndex = 0
  ): ModuleContent[] {
    const templatesToRender = [];
    const templatesPositions: { [stepId: number]: number } = {};
    Object.keys(steps).forEach((step_id, blockIndex) => {
      const stepIndex = this.moduleToCopy.findIndex(
        moduleData => moduleData.step_id === +step_id
      );
      const moduleToRender = this.moduleToCopy[stepIndex]
        ? JSON.parse(JSON.stringify(this.moduleToCopy[stepIndex]))
        : null;
      if (!moduleToRender) {
        return;
      }

      moduleToRender.instance_index = instanceIndex;

      const isNoTabsSelect = this.contentData.step_type_select === 'no_tabs';

      if (this.contentData.json) {
        this.setDefaultDataByJSON(moduleToRender, step_id);
      }

      let newOptions =
        tabIndex === -1
          ? moduleToRender.options
          : {
              ...moduleToRender.options,
              tabIndex,
              tabName: isNoTabsSelect ? null : this.tabNames[tabIndex],
              tabNames: isNoTabsSelect ? null : this.tabNames
            };
      switch (moduleToRender.template_component) {
        case 'module_result': {
          const previousModuleTemplateInfo = this.contentData.previous_module_result_params?.find(
            info => info.block_index === blockIndex
          );
          if (
            previousModuleTemplateInfo?.tab_module_template &&
            previousModuleTemplateInfo.tab_module_template[tabIndex]
          ) {
            newOptions = {
              ...newOptions,
              module: String(
                previousModuleTemplateInfo.tab_module_template[tabIndex].module
              ),
              step_id: String(
                previousModuleTemplateInfo.tab_module_template[tabIndex].step_id
              )
            };
          }
          break;
        }
      }

      moduleToRender.instance_index = instanceIndex;

      if (moduleToRender.template_component === 'composable_template') {
        const block = this.blocksData.blocks[tabIndex];
        const childSteps = block.data[moduleToRender.step_id];
        const stepsLabel = 'childSteps';
        newOptions[stepsLabel] = childSteps;
      }

      templatesToRender.push({
        ...moduleToRender,
        options: newOptions,
        inputs: steps[step_id]
      });
      templatesPositions[moduleToRender.step_id] = this.steps[
        stepIndex
      ].step_index;
    });

    return templatesToRender.sort(
      (tempA, tempB) =>
        templatesPositions[tempA.step_id] - templatesPositions[tempB.step_id]
    );
  }

  activateTab(tabIndex: number): void {
    const newTab = this.wrapperComponentsRefs[tabIndex];
    if (newTab) {
      const currentActiveTab = this.wrapperComponentsRefs[this.activeTabIndex];
      currentActiveTab.location.nativeElement.className = currentActiveTab.location.nativeElement.className
        .split(' active')
        .join('');
      currentActiveTab.instance.data.data.hidden = true;
      newTab.location.nativeElement.className += ' active';
      newTab.instance.data.data.hidden = false;
      this.activeTabIndex = tabIndex;
      newTab.instance.updateChildrenData();
      currentActiveTab.instance.updateChildrenData();
    }
    this.activeTabIndex = tabIndex;
    this.exportService.currentBlockRepeaterIndex.next(tabIndex);
  }

  trackByFn(index, item) {
    return index;
  }

  private populateData(blockIndex?: number): void {
    const moduleToCopy: ModuleContent[] = parse(stringify(this.moduleToCopy));
    const data: BlockData = {};
    moduleToCopy.forEach(module => {
      data[module.step_id] = this.getInputsForCopyBlock(module);
    });

    const newBlock: InputRepeaterBlock = {
      id: `block-${uuid4()}`,
      data
    };
    if (
      this.contentData.use_buyer_persona_as_source &&
      this.buyerPersonas[blockIndex]?.uuid
    ) {
      newBlock.linkedUuid = this.buyerPersonas[blockIndex].uuid;
    }
    this.blocksData.blocks.push(newBlock);
  }

  private updateInputsInExistedBlocks(
    blocks: InputRepeaterBlock[]
  ): InputRepeaterBlock[] {
    const data = blocks.filter(el => !!el && !!el.data);
    if (data?.length) {
      return data.map(block => ({
        ...block,
        data: this.moduleToCopy.reduce((stepDict: BlockData, module) => {
          const existModule = Object.entries(block.data || {}).find(
            ([stepId, info]) => Number(stepId) === module.step_id
          )?.[1];
          const processedInputs = this.getInputsForCopyBlock(module);
          if (existModule) {
            stepDict[module.step_id] = Object.keys(processedInputs).reduce(
              (dict: InputDictionary, inputKey) => {
                const existContent = existModule[inputKey]?.content;
                dict[inputKey] = !!processedInputs[inputKey].content
                  ? processedInputs[inputKey]
                  : { ...processedInputs[inputKey], content: existContent };

                return dict;
              },
              existModule
            );
          } else {
            stepDict[module.step_id] = processedInputs;
          }

          return stepDict;
        }, {})
      }));
    }

    return data;
  }

  private getInputsForCopyBlock(module: ModuleContent): InputDictionary {
    const templateComponentString = module.template_component;
    const inputSuffix = module.template_params_json?.input_sufix || '';
    const inputRegex = new RegExp(
      `${templateComponentString}(?:_\\d+_?)?${inputSuffix}`,
      'gi'
    );
    const exceptionalInputs = ['spreadsheet', 'file_uploader'];
    const inputs = parse(stringify(module.inputs));
    if (inputs) {
      return Object.keys(inputs).reduce((dict, inputKey) => {
        if (
          inputKey.match(inputRegex) &&
          !exceptionalInputs.some(except => inputKey.startsWith(except))
        ) {
          dict[inputKey] = { ...inputs[inputKey], content: null };
        }

        return dict;
      }, inputs);
    }

    return inputs;
  }

  private getResultChildSteps(
    resultSteps: ModuleContent[]
  ): Observable<ModuleContent[][]> {
    const setStepId = (
      moduleSteps: { [key: number]: number[] },
      moduleId: number,
      stepId: number
    ) => {
      if (moduleSteps[moduleId]) {
        if (!moduleSteps[moduleId].find(moduleStep => moduleStep === stepId)) {
          moduleSteps[moduleId].push(stepId);
        }
      } else {
        moduleSteps[moduleId] = [stepId];
      }
    };
    type paramType = BlockRepeaterResultParamsData | ModuleResultParamsData;
    const currentModuleId = this.moduleNavService.module.current;
    const content: { module: number; step_id: number }[] = resultSteps.map(
      step => {
        const templateParams: paramType = step.template_params_json as paramType;
        switch (step.template_component) {
          case 'block_repeater_result':
            return {
              module: Number(templateParams.module),
              step_id: Number(templateParams.step_id)
            };
          case 'module_result':
            return step.options?.module && step.options?.step_id
              ? {
                  module: Number(step.options.module),
                  step_id: Number(step.options.step_id)
                }
              : {
                  module: Number(templateParams.module),
                  step_id: Number(templateParams.step_id)
                };
        }
      }
    );
    const resultModules$ = content.map(step =>
      currentModuleId === Number(step.module)
        ? this.navService.moduleDataReplay$.pipe(take(1))
        : this.moduleService.getOrgModule(
            Number(step.module),
            this.currentOrg,
            true
          )
    );
    const blockSteps: number[][] = [];

    return forkJoin(resultModules$).pipe(
      switchMap(modules => {
        const moduleSteps = content.reduce(
          (accum: { [key: number]: number[] }, resultStep, index) => {
            const steps = modules[index].steps.filter(
              step => step.parent_step_id === Number(resultStep.step_id)
            );
            blockSteps.push([
              Number(resultStep.step_id),
              ...steps.map(step => step.id)
            ]);
            setStepId(
              accum,
              Number(resultStep.module),
              Number(resultStep.step_id)
            );
            steps.forEach(step =>
              setStepId(accum, Number(step.module_id), Number(step.id))
            );

            return accum;
          },
          {}
        );

        return forkJoin(
          Object.keys(moduleSteps).map(moduleId =>
            this.moduleContentService.loadSteps(
              Number(moduleId),
              this.data.data.org_id,
              moduleSteps[moduleId]
            )
          )
        );
      }),
      map(steps => {
        const flattenSteps = steps.flat();

        return blockSteps.map(block =>
          block.map(stepId =>
            flattenSteps.find(step => Number(step.step_id) === Number(stepId))
          )
        );
      })
    );
  }

  private removeCircularStructureFromBlockData(
    circularData: InputRepeaterBlock[]
  ): InputRepeaterBlock[] {
    return circularData.map(block => ({
      ...block,
      data:
        !!block?.data && block?.data !== {}
          ? Object.keys(block.data).reduce(
              (blockAccum: BlockData, stepIndex) => {
                blockAccum[stepIndex] = Object.keys(
                  block.data[stepIndex]
                ).reduce(
                  (
                    inputAccum: { [inputKey: string]: TemplateInput },
                    inputName
                  ) => {
                    const {
                      observer,
                      error,
                      getValue,
                      ...inputContent
                    } = block.data[stepIndex][inputName];
                    inputAccum[inputName] = inputContent;

                    return inputAccum;
                  },
                  {}
                );

                return blockAccum;
              },
              {}
            )
          : block.data
    }));
  }

  private removeCircularStructureFromModuleContent(
    circularData: ModuleContent[]
  ): ModuleContent[] {
    circularData.forEach(content => {
      if (content.inputs) {
        Object.keys(content.inputs).forEach(inputName => {
          const input = content.inputs[inputName];
          input.error = null;
          input.observer = null;
          input.getValue = null;
        });
      }
    });

    return circularData;
  }
}
