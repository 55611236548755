import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  Module,
  StepSearchResult,
  Template,
  TemplateGroup
} from '../../common/interfaces/module.interface';
import { TemplateService } from '../../common/services/template.service';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ModuleService } from '../../common/services/module.service';
import { Router } from '@angular/router';

interface SearchStep {
  stepName: string;
  stepId: number;
  stepIndex: number;
  childSteps?: SearchStep[];
}

interface StepList {
  moduleName: string;
  moduleId: number;
  steps: SearchStep[];
}

@Component({
  selector: 'app-step-search',
  templateUrl: './step-search.component.html',
  styleUrls: ['./step-search.component.scss']
})
export class StepSearchComponent implements OnInit, OnDestroy {
  modules: Module[];
  templates: Template[];
  filteredTemplateGroups: TemplateGroup[] = [];
  stepList: StepList[] = [];
  resultSteps: StepSearchResult[] = [];
  showSearchBlock = false;
  loading = false;
  noResult = false;
  searchForm: FormGroup;
  templateType: FormControl = new FormControl(null);
  querySearch: FormControl = new FormControl(null);
  templateFilterControl: FormControl = new FormControl(null);
  private componentDestroy$: Subject<void> = new Subject();

  constructor(
    private templateService: TemplateService,
    private moduleService: ModuleService,
    private router: Router
  ) {
    this.searchForm = new FormGroup({
      templateType: this.templateType,
      querySearch: this.querySearch
    });
  }

  ngOnInit(): void {
    this.moduleService
      .getModules()
      .subscribe(modules => (this.modules = modules));
    this.templates = this.templateService.getTemplates();
    this.filteredTemplateGroups = TemplateService.getTemplateGroups(
      this.templates
    );
    this.searchForm.setValidators([
      this.oneOfControlRequired(this.templateType, this.querySearch)
    ]);
    this.templateFilterControl.valueChanges
      .pipe(takeUntil(this.componentDestroy$))
      .subscribe((value: string) => {
        this.filterTemplatesByName(value);
      });
  }

  ngOnDestroy(): void {
    this.componentDestroy$.next();
    this.componentDestroy$.complete();
  }

  showSearchForm(): void {
    this.showSearchBlock = true;
  }

  closeSearchForm(): void {
    this.showSearchBlock = false;
  }

  stepSearch(): void {
    if (this.searchForm.valid) {
      this.loading = true;
      this.templateService
        .searchTemplateInModules(
          this.templateType.value,
          this.querySearch.value
        )
        .subscribe(response => {
          this.loading = false;
          if (response && response.success && response.results) {
            this.resultSteps = response.results;
            this.stepList = this.prepareStepList(response.results);
            this.noResult = !this.stepList.length;
          } else {
            this.noResult = true;
          }
        });
    } else {
      this.searchForm.markAllAsTouched();
    }
  }

  isControlInvalid(control: FormControl): boolean {
    const controlTouched = !!(control && (control.dirty || control.touched));
    const controlInvalid = !!(control && control.invalid);
    const parentInvalid = !!(
      control &&
      control.parent &&
      control.parent.invalid &&
      (control.parent.dirty || control.parent.touched)
    );

    return controlTouched && (controlInvalid || parentInvalid);
  }

  routeToStep(searchModule: StepList, step: SearchStep): void {
    this.router.navigateByUrl(
      `/builder/${searchModule.moduleId}/${step.stepId}`
    );
  }

  private oneOfControlRequired(...controls: AbstractControl[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      for (const aControl of controls) {
        if (!Validators.required(aControl)) {
          return null;
        }
      }

      return { oneOfRequired: true };
    };
  }

  private filterTemplatesByName(query: string): void {
    const filteredTemplates = this.templates.filter(template =>
      template.name.toLowerCase().includes(query.toLowerCase())
    );
    this.filteredTemplateGroups = TemplateService.getTemplateGroups(
      filteredTemplates
    );
  }

  private prepareStepList(searchedSteps: StepSearchResult[]): StepList[] {
    return searchedSteps
      .reduce((accumulator, currentStep) => {
        const stepModule = this.modules.find(
          module => module.id === Number(currentStep.module_id)
        );
        let accumulatorModule: StepList = accumulator.find(
          module => module.moduleId === Number(currentStep.module_id)
        );
        if (!accumulatorModule) {
          const searchModule: StepList = {
            moduleName:
              stepModule.name ||
              currentStep.module_name ||
              `Module id${currentStep.module_id}`,
            moduleId: stepModule.id || Number(currentStep.module_name),
            steps: []
          };
          accumulator.push(searchModule);
          accumulatorModule = searchModule;
        }
        const moduleStep = stepModule.steps.find(
          step => step.id === Number(currentStep.id)
        );
        const searchStep: SearchStep = {
          stepName:
            moduleStep.description ||
            currentStep.description ||
            `${moduleStep.template_component || 'step'} (id ${currentStep.id})`,
          stepId: Number(currentStep.id),
          stepIndex: moduleStep.step_index || null,
          childSteps: null
        };
        if (currentStep.parent_id) {
          const parentStep = stepModule.steps.find(
            step => step.id === Number(currentStep.parent_id)
          );
          let parentSearchStep: SearchStep = accumulatorModule.steps.find(
            step => step.stepId === Number(currentStep.parent_id)
          );
          if (!parentSearchStep) {
            parentSearchStep = {
              stepName:
                parentStep.description ||
                `${parentStep.template_component || 'Parent step'} (id ${
                  currentStep.parent_id
                })`,
              stepId: Number(currentStep.parent_id),
              stepIndex: parentStep.step_index || null,
              childSteps: []
            };
            const index = accumulatorModule.steps.findIndex(
              step => parentSearchStep.stepIndex < step.stepIndex
            );
            if (index >= 0 && parentSearchStep.stepIndex) {
              accumulatorModule.steps.splice(index, 0, parentSearchStep);
            } else {
              accumulatorModule.steps.push(parentSearchStep);
            }
          }
          if (parentSearchStep.childSteps) {
            parentSearchStep.childSteps.push(searchStep);
          } else {
            parentSearchStep.childSteps = [searchStep];
          }
        } else {
          accumulatorModule.steps.push(searchStep);
        }

        return accumulator;
      }, [])
      .sort((a, b): number => {
        const nameA = a.moduleName.toUpperCase();
        const nameB = b.moduleName.toUpperCase();

        return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
      });
  }
}
