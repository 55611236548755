import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { ModuleNavService } from '../../services/module-nav.service';
import { Organization } from '../../interfaces/module.interface';
import { Subscription } from 'rxjs';
import { sanitizeHtml } from '../../utils/html-helpers';

@Pipe({
  name: 'content'
})
export class ContentPipe implements PipeTransform, OnDestroy {
  organization: Organization;
  organizationSubscription: Subscription;

  constructor(private moduleNavService: ModuleNavService) {
    this.organizationSubscription = this.moduleNavService.currentOrganizationData$.subscribe(
      organization => (this.organization = organization)
    );
  }

  transform(value: string, needSanitize = true): string {
    if (value) {
      let replacedValue = value
        .split('[current_company_name]')
        .join(this.organization ? this.organization.name : 'Company');

      if (needSanitize) {
        const parser = new DOMParser().parseFromString(
          sanitizeHtml(replacedValue)
            .split('<')
            .join(' <') || '',
          'text/html'
        );

        parser.querySelectorAll('.del').forEach(deleted => deleted.remove());
        replacedValue = parser.body.textContent
          .trim()
          .replace(/[^\S\r\n]/g, ' ');
      }

      return replacedValue;
    } else {
      return value;
    }
  }

  ngOnDestroy(): void {
    if (this.organizationSubscription) {
      this.organizationSubscription.unsubscribe();
    }
  }
}
