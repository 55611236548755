import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  Module,
  Organization,
  SprintStatus
} from '../../../common/interfaces/module.interface';
import { ModuleScores } from '../../../common/interfaces/assessment.interface';
import { Sort } from '@angular/material/sort';
import { ModuleService } from '../../../common/services/module.service';
import { SECONDARY_FULL_DATE_INPUT_FORMAT } from '../../../common/utils/date-fns-date-picker';

@Component({ template: '' })
export class ListViewBaseDashboardComponent {
  @Input() organization: Organization;
  @Input() canActivate: boolean;
  @Input() IS_RACF_SE: boolean;
  @Input() sprintStatus: SprintStatus;
  @Input() assessmentScores: ModuleScores;

  @Output() sprintStatusUpdateTrigger = new EventEmitter<void>();
  @Output() moduleListUpdateTrigger = new EventEmitter<void>();
  @Output() listSortOrderUpdateTrigger = new EventEmitter<Sort>();

  readonly targetSprintDateFormat = SECONDARY_FULL_DATE_INPUT_FORMAT;
  readonly sprintOptions = [
    { value: 0, name: 'Set sprint' },
    { value: 1, name: 'Current' },
    { value: 2, name: 'Next' }
  ];

  constructor(protected moduleService: ModuleService) {}

  saveAssignedTo(module: Module): void {
    this.moduleService
      .saveAssignedTo(module, this.organization.id, module.status.assigned_to)
      .subscribe(newStatus => {
        module.status = newStatus;
      });
  }

  saveNotes(module: Module): void {
    this.moduleService
      .saveNotes(module, this.organization.id, module.status.notes)
      .subscribe(newStatus => {
        module.status = newStatus;
      });
  }

  saveModuleSprint(module: Module): void {
    this.moduleService
      .setModuleSprint(module, this.organization.id, module.status.sprint_id)
      .subscribe(() => {
        this.triggerSprintStatusUpdate();
        this.triggerModuleListUpdate();
      });
  }

  saveSprintDate(date: string): void {
    this.moduleService
      .setSprintTargetDate(this.organization.id, date)
      .subscribe();
  }

  setListSort(sortLabel: Sort): void {
    this.listSortOrderUpdateTrigger.next(sortLabel);
  }

  summarizeImprovement(moduleList: Module[]): number {
    return Math.round(
      moduleList?.reduce(
        (prev, cur: Module) =>
          prev +
          Math.round(
            cur.status?.sprint_id === 1 && cur.status?.potential_improvement
              ? cur.status?.potential_improvement
              : 0
          ),
        0
      ) || 0
    );
  }

  protected triggerSprintStatusUpdate(): void {
    this.sprintStatusUpdateTrigger.emit();
  }

  protected triggerModuleListUpdate(): void {
    this.moduleListUpdateTrigger.emit();
  }
}
