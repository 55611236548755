import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { AssessmentService } from 'src/app/common/services/assessment.service';
import { Observable, BehaviorSubject, combineLatest, Subject, of } from 'rxjs';
import {
  AssessmentType,
  AssessmentGroup,
  AssessmentOrgGroup,
  AssessmentSession
} from 'src/app/common/interfaces/assessment.interface';
import { ModuleNavService } from 'src/app/common/services/module-nav.service';
import {
  switchMap,
  filter,
  take,
  distinctUntilChanged,
  withLatestFrom,
  shareReplay,
  tap,
  skip,
  takeUntil,
  map
} from 'rxjs/operators';
import {
  AssessmentChartSeries,
  AssessmentChartActiveEntries
} from '../../assessment-chart';
import { environment } from '../../../../environments/environment';
import { FormControl, Validators } from '@angular/forms';
import { Organization } from 'src/app/common/interfaces/module.interface';

const MIN_ASSESSMENTS_COUNT = 3;

@Component({
  selector: 'app-view-assessments',
  templateUrl: './view-assessments.component.html',
  styleUrls: ['./view-assessments.component.scss']
})
export class ViewAssessmentsComponent implements OnInit, OnDestroy {
  @Input() isExecutiveDashboard = false;
  @Input() organization: Organization;
  types$: Observable<AssessmentType[]>;
  groups$: Observable<AssessmentGroup[]>;
  orgGroups$: Observable<AssessmentOrgGroup[]>;
  orgObserver$: Observable<number>;
  sessions$: Observable<AssessmentSession[]>;
  assessments: AssessmentSession[];

  activeType$ = new BehaviorSubject<AssessmentType>(null);
  activeGroup$: BehaviorSubject<AssessmentGroup>;
  version$ = new BehaviorSubject<number>(2);

  chart: AssessmentChartSeries;
  activeEntries: AssessmentChartActiveEntries;
  colors: string[];
  activatedSeries: number;
  apiRoot = environment.apiRoot;
  versions = [2, 1];
  minAssessments = MIN_ASSESSMENTS_COUNT;
  selectedAssessments = new FormControl(
    [],
    [
      Validators.minLength(MIN_ASSESSMENTS_COUNT),
      Validators.maxLength(MIN_ASSESSMENTS_COUNT)
    ]
  );

  private componentDestroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public asmService: AssessmentService,
    public navService: ModuleNavService
  ) {}

  ngOnInit() {
    this.types$ = this.asmService.getTypes();

    if (window.history.state && window.history.state.type) {
      this.asmService
        .getType(window.history.state.type)
        .pipe(take(1))
        .subscribe(type => this.setType(type));
    } else {
      this.navService.assessmentType$
        .pipe(take(1))
        .subscribe(type => this.setType(type));
    }

    this.orgObserver$ = this.navService.organization$.pipe(
      distinctUntilChanged()
    );

    this.sessions$ = combineLatest([
      this.activeType$.pipe(
        filter(t => !!t),
        map(type => type.id),
        distinctUntilChanged()
      ),
      this.orgObserver$,
      this.version$.pipe(distinctUntilChanged())
    ]).pipe(
      switchMap(([type, org, version]) =>
        this.asmService.getCompletedSessions(type, org, version)
      ),
      switchMap(sessions => {
        this.assessments = sessions.reverse();

        this.selectedAssessments.setValue(
          this.assessments.slice(
            0,
            Math.min(this.assessments.length, MIN_ASSESSMENTS_COUNT)
          )
        );

        this.onSelectAssessment();

        return of(this.assessments);
      }),
      shareReplay(1)
    );
  }

  onSelectAssessment() {
    this.sessions$ = of(this.selectedAssessments.value.reverse());
    this.initCharts();
  }

  ngOnDestroy(): void {
    this.asmService.version$.next(2);
    this.asmService.returnVersionData();
    this.componentDestroy$.next();
    this.componentDestroy$.complete();
  }

  initCharts() {
    const colors = [
      'red',
      'green',
      'orange',
      'blue',
      'yellow',
      'brown',
      'grey'
    ];
    this.sessions$
      .pipe(takeUntil(this.componentDestroy$), withLatestFrom(this.activeType$))
      .subscribe(([sessions, type]) => {
        if (!type) {
          return;
        }

        this.chart = sessions.map((session, sIdx) => {
          const series = Object.values(session.groups)
            .sort((a, b) => {
              const aPos = Number(a.position);
              const aId = Number(a.group_id);
              const bPos = Number(b.position);
              const bId = Number(b.group_id);

              return aPos === bPos
                ? aId > bId
                  ? 1
                  : -1
                : aPos > bPos
                ? 1
                : -1;
            })
            .map((group, idx) => ({
              value: Number(group.score),
              formattedValue:
                group.score === null ? 'N/A' : String(group.score),
              name: idx + 1,
              label: (
                type.groups.find(
                  g => Number(g.id) === Number(group.group_id)
                ) || { shortName: '' }
              ).shortName
            }));

          const score = Math.round(session.score * 10) / 10;
          series.push({
            value: score,
            formattedValue: String(score),
            name: series.length + 1,
            label: 'Average'
          });

          return {
            name: session.formattedDate,
            id: session.id,
            series
          };
        });

        this.colors = colors.slice(0, this.chart.length);
      });
  }

  setType(type: AssessmentType) {
    this.activeType$.next(type);
  }

  setActiveSeries(event) {
    this.activatedSeries = event;
  }

  setVersion(version) {
    this.asmService.version$.next(version);
    this.types$
      .pipe(
        skip(1),
        take(1),
        tap(types => {
          this.version$.next(version);
          this.activeType$.next(
            types.find(type => type.id === this.activeType$.value.id)
          );
        })
      )
      .subscribe();
  }

  activate(id: number) {
    const index = this.chart.findIndex(el => el.id === id);
    if (this.chart[index]) {
      this.activatedSeries = id;
      this.activeEntries = [{ name: this.chart[index].name }];
    }
  }

  deactivate(id: number) {
    if (id === this.activatedSeries) {
      this.activatedSeries = null;
      this.activeEntries = [];
    }
  }
}
