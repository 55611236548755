import { TemplateContentDataBase } from '../template.interface';
import { TemplateInput } from '../../../../common/interfaces/module.interface';

export const COMPANIES_MODELS = [
  'Reseller',
  'VAR',
  'OEM',
  'Wholesaler',
  'Distributor',
  'Dealer',
  'Sales Agent',
  'Referral Partner',
  'Strategic Alliance',
  'Other'
];

export interface FormBuilderData {
  question: string;
  content: string;
}

export interface Company {
  name: string;
  model: string;
  roles: string[];
  vision: any;
}

export interface ChannelStrategySummaryTemplateData
  extends TemplateContentDataBase {
  template_params_json: {
    title: string;
    description: string;
    companies_sufix: string;
    demand_generation_id: number;
    deal_registration_rules_id: number;
    deal_registration_custom_rules_sufix: string;
    company_roles_sufix: string;
    partnership_visions_sufix: string;
    partner_marketing_plan_id: number;
    partner_marketing_plan_suffix: string;
    partner_marketing_plan_fb_id: string;
    partner_marketing_plan_fb_suffix: string;
    lead_management_id: number;
    lead_management_suffix: string;
    lead_management_fb_id: string;
    lead_management_fb_suffix: string;
    channel_enablement_id: number;
    channel_enablement_br_sufix: string;
    key_stackholders_sufix: string;
    kpi_matrix_sufix: string;
    escalation_matrix_sufix: string;
    mdf_sufix: string;
  };
}
