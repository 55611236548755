export const isArrowKey = (event: KeyboardEvent): boolean =>
  event.code
    ? event.code === 'ArrowLeft' ||
      event.code === 'ArrowRight' ||
      event.code === 'ArrowUp' ||
      event.code === 'ArrowDown'
    : event.keyCode >= 37 && event.keyCode <= 40;

export const isDeleteKey = (event: KeyboardEvent): boolean =>
  event.code ? event.code === 'Delete' : event.keyCode === 46;

export const isBackspaceKey = (event: KeyboardEvent): boolean =>
  event.code ? event.code === 'Backspace' : event.keyCode === 8;

export const isDeleteOrBackspace = (event: KeyboardEvent): boolean =>
  isDeleteKey(event) || isBackspaceKey(event);

export const isPreviousConditionCombo = (event: KeyboardEvent): boolean =>
  (event.code ? event.code === 'KeyZ' : event.keyCode === 90) && event.ctrlKey;

export const isCopyTextCombo = (event: KeyboardEvent): boolean =>
  (event.code ? event.code === 'KeyC' : event.keyCode === 67) && event.ctrlKey;

export const isPasteTextCombo = (event: KeyboardEvent): boolean =>
  (event.code ? event.code === 'KeyV' : event.keyCode === 86) && event.ctrlKey;

export const isControlCombo = (event: KeyboardEvent): boolean =>
  isPreviousConditionCombo(event) ||
  isCopyTextCombo(event) ||
  isPasteTextCombo(event);

export const isEnterKey = (event: KeyboardEvent): boolean =>
  event.code
    ? event.code === 'Enter' || event.code === 'NumpadEnter'
    : event.keyCode === 13;
