import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { sanitizeHtml } from '../utils/html-helpers';
@Pipe({
  name: 'safehtml'
})
export class SafehtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(html: string, sanitize = true) {
    return this.sanitizer.bypassSecurityTrustHtml(
      sanitize ? sanitizeHtml(html) : html
    );
  }
}
