import { BlockRepeaterContent, BlockRepeaterContentInput } from './index';
import { v4 as uuid4 } from 'uuid';

export const updateBlockRepeaterFormat = (
  content: BlockRepeaterContentInput
): BlockRepeaterContent => {
  if (!content) {
    return null;
  }
  let newFormatBlockRepeater: BlockRepeaterContent;
  if ('id' in content || 'uuid' in content) {
    newFormatBlockRepeater = content;
  } else {
    const actualData =
      (content.apiData?.length ? content.apiData : content.templateData) || [];

    newFormatBlockRepeater = {
      id: `block-repeater-${uuid4()}`,
      blocks: actualData?.map(block => ({
        id: `block-${uuid4()}`,
        data: block
      }))
    };
  }

  newFormatBlockRepeater.id =
    newFormatBlockRepeater.id || `block-repeater-${uuid4()}`;
  newFormatBlockRepeater.blocks?.forEach(
    block => (block.id = block.id || `block-${uuid4()}`)
  );

  return newFormatBlockRepeater;
};
