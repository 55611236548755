import { Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import {
  BuyerProcessMapTemplateData,
  DescriptionTitle,
  objectives,
  PersonaInfo,
  PersonaRole,
  PersonaRoleInputInfo,
  questions,
  roles,
  stepInputInfo,
  titles
} from '.';
import txt from '!!raw-loader!./index.ts';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { DESCRIPTION_FIELDS } from '../persona-roles';
import { BuyerPersona } from 'src/app/common/interfaces/buyer-persona.interface';
import { from, Subscription } from 'rxjs';
import { map, mergeMap, reduce, take } from 'rxjs/operators';
import { OldBlockRepeaterContent, BlockData } from '../block-repeater';
import { ListEntry } from '../list-entry';
import { template } from '@angular-devkit/core';

declare interface BuyerPersonaExtended extends BuyerPersona {
  icon: SafeStyle;
  iconTitle: string;
}

@Component({
  selector: 'buyer-process-map',
  templateUrl: 'buyer-process-map.component.html',
  styleUrls: ['./buyer-process-map.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => BuyerProcessMapComponent)
    }
  ]
})
export class BuyerProcessMapComponent extends TemplateComponent
  implements OnInit, OnDestroy {
  params = txt;
  contentData: BuyerProcessMapTemplateData['template_params_json'];

  progressBar = Object.values(titles);
  currentStep: string;
  rolesInDescription: { [key: string]: { [key: string]: string[] } } = {};
  buyerPersonasExtended: { [key: string]: BuyerPersonaExtended[] } = {};
  personRoles: PersonaInfo = {};
  stepKeys = ['recognizes', 'need', 'options', 'risk', 'solution', 'realizes'];
  sectionTitles = titles.reduce((ret, title) => {
    ret[title.step] = title.title;

    return ret;
  }, {});
  personasDescriptions = [];
  firstStageIndex = 0;
  lastStageIndex = 5;
  stageIndex: number;
  selectedTabIndex = 0;
  descriptions = [
    { key: 'needs', title: 'Needs' },
    { key: 'activities', title: 'Activities' },
    { key: 'content_preferences', title: 'Content Preferences' }
  ];

  readonly questionList = questions;
  readonly rolesData = roles;
  readonly objectiveList = objectives;
  readonly descriptionFields = DESCRIPTION_FIELDS;

  private sanitizer: DomSanitizer;
  private subscriptions: Subscription[] = [];

  getDescription() {
    return '';
  }

  getName() {
    return 'Buyer Process Map';
  }

  getGroup(): string {
    return 'Outputs';
  }

  ngOnInit() {
    super.ngOnInit();
    this.contentData = this.data.data
      .template_params_json as BuyerProcessMapTemplateData['template_params_json'];

    this.sanitizer = this.injectorObj.get<DomSanitizer>(DomSanitizer);
    const stages = JSON.parse(
      this.getInput(`list_entry_1_stages_title`).content
    );

    this.stageIndex = this.firstStageIndex;

    stages.forEach((stage, i) => {
      this.progressBar[i].title = stage.option;
    });

    titles.forEach((title, i) => {
      this.sectionTitles[title.step] = stages[i].option;
    });

    this.toggleCurrentStep(this.progressBar[0]);

    this.subscriptions = [
      this.buildPersonaExtended(),
      this.getDescriptionPersonas()
    ];
    this.setOrientationPrintLayout('landscape');
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  buildPersonaExtended() {
    return this.buyerPersonasList$.subscribe(personas => {
      this.stepKeys.forEach(step => {
        this.buyerPersonasExtended[step] = [];
        personas.forEach((persona: BuyerPersonaExtended) => {
          const extendedPersona = { ...persona };
          if (this.personRoles[step]) {
            const roleData = Object.values(this.rolesData).find(
              item =>
                item.title ===
                this.personRoles[step].personas[persona.index]?.personaRole
            );

            if (roleData) {
              extendedPersona.icon = this.sanitizer.bypassSecurityTrustStyle(
                `url('${roleData.image}')`
              );
              extendedPersona.iconTitle = roleData.title;
            } else {
              extendedPersona.icon = null;
              extendedPersona.iconTitle = '';
            }
          }
          this.buyerPersonasExtended[step].push(extendedPersona);
        });
      });
    });
  }

  getDescriptionPersonas() {
    return this.buyerPersonasList$.subscribe();
  }

  toggleCurrentStep(item) {
    this.currentStep = item.step;
    this.personasDescriptions = [];
    this.stageIndex = this.firstStageIndex;
    this.selectedTabIndex = this.progressBar.indexOf(item);
    const inputsEntries = Object.entries(this.inputs);
    for (const [index, stepName] of this.stepKeys.entries()) {
      try {
        this.personRoles[stepName] = {
          personas: null,
          descriptions: this.getPersonaDescriptionsFromBlockRepeater(
            stepInputInfo[stepName]
          )
        };
      } catch (e) {
        continue;
      }

      const plaInput =
        inputsEntries.find(input =>
          input[0].startsWith(`participation_level_activity_${index + 1}`)
        )[1] || undefined;

      this.buyerPersonasList$
        .pipe(
          take(1),
          mergeMap((list: BuyerPersona[]) => from(list)),
          map((persona: BuyerPersona) => {
            const resultPersonas: PersonaRole = {};
            if (plaInput && plaInput.content) {
              let personaToProcess = JSON.parse(plaInput.content)[
                `${persona.index - 1}`
              ];
              personaToProcess =
                personaToProcess?.role ||
                (personaToProcess?.length && personaToProcess);
              if (
                personaToProcess !== undefined &&
                personaToProcess !== 'Not_involved' &&
                personaToProcess !== null
              ) {
                resultPersonas[`${persona.index - 1}`] = {
                  personaRole: personaToProcess,
                  personaRef: persona
                };
              }
            }

            return resultPersonas;
          }),
          reduce((acc, curr) => ({ ...acc, ...curr }), {})
        )
        .subscribe(personas => {
          this.personRoles[stepName].personas = personas;
          this.convertImgUrlToLocal(this.personRoles[stepName].personas);
        });
    }
  }

  convertImgUrlToLocal(personas) {
    if (personas) {
      for (const persona in personas) {
        if (personas[persona].personaRef.picture) {
          personas[
            persona
          ].personaRef.picture = this.buyerPersonasService.changePersonaURLImageToLocal(
            personas[persona].personaRef
          ).picture;
        }
      }
    }
  }

  private getStepID(step: string) {
    return step.slice(0, -1);
  }

  private strip(html: string): string[] {
    if (!html) {
      return [''];
    }
    const doc = new DOMParser().parseFromString(html, 'text/html');

    return [doc.body.textContent || ''];
  }

  private getContentData(string: string) {
    const reg = /<div[^<]*>([^/]+)<\/div>/g;

    const res = this.getAllMatches(string, reg);
    if (!res.length) {
      return this.strip(string);
    }

    return res;
  }

  private getAllMatches(string: string, reg: RegExp): string[] {
    const res = [];
    let match;
    do {
      match = reg.exec(string);
      if (match) {
        const str = match[1].replace(/\s*?<br>\s*?/g, '');
        if (str) {
          res.push(str.trim());
        }
      }
    } while (match);

    return res;
  }

  private getPersonaDescriptionsFromBlockRepeater(
    inputInfo: PersonaRoleInputInfo
  ): { [title: string]: string[] } {
    const blockRepeaterInput = this.getInput(inputInfo.name);
    const content: OldBlockRepeaterContent = JSON.parse(
      blockRepeaterInput.content || null
    );
    const data: BlockData[] = content?.templateData;
    let result = { needs: [], activities: [], content_preferences: [] };

    const brBlocks = (content as any)?.blocks as [];

    if (data || (brBlocks && this.stageIndex === this.selectedTabIndex)) {
      result = (data ? data : brBlocks).reduce((accum, block, index) => {
        Object.keys(accum).forEach((titleName: DescriptionTitle) => {
          const blockInfo = inputInfo.blockInfo[titleName];
          const listEntryInfo: ListEntry = JSON.parse(
            (brBlocks ? (block as any).data : block)[blockInfo.inputIndex][
              blockInfo.inputName
            ].content || null
          );
          const descriptions = listEntryInfo
            ? Object.values(listEntryInfo)
                .map(item => item.option)
                .filter(Boolean)
            : null;
          if (descriptions?.length) {
            accum[titleName].push(...descriptions);
            if (!this.personasDescriptions[index])
              this.personasDescriptions[index] = {};
            if (!this.personasDescriptions[index][titleName]) {
              this.personasDescriptions[index][titleName] = [];
              this.descriptions.map((field, fieldIndex) => {
                if (descriptions[fieldIndex]) {
                  this.personasDescriptions[index][titleName].push(
                    descriptions[fieldIndex]
                  );
                }
              });
            }
          }
        });

        return accum;
      }, result);
    }
    this.stageIndex++;

    return result;
  }
}
