import { HandsontableCellChange } from '../../module-viewer/riverside-step-template/templates/spreadsheet';
import { isEmptyValue } from './helpers';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SpreadsheetService } from '../services/spreadsheet.service';

export const isCellChanged = (change: HandsontableCellChange): boolean =>
  String(change[2]) !== String(change[3]) &&
  Boolean(String(change[2]) || String(change[3]));

export const isEditChange = (
  context,
  changes: HandsontableCellChange[],
  source: string
): boolean => {
  // sometimes the context argument is not passed (HOT 6 only)
  if (!source) {
    source = (changes as unknown) as string;
  }

  return (
    source === 'edit' ||
    source === 'Autofill.fill' ||
    source === 'CopyPaste.paste'
  );
};

export const isFormulaString = (value: string | boolean | number): boolean =>
  !!value && typeof value === 'string' ? value[0] === '=' : false;

export const getColNumericIndex = (col: string): number =>
  col
    .split('')
    .reverse()
    .reduce(
      (res, char, idx) =>
        (char.charCodeAt(0) - 64) * Math.max(1, idx * 26) + res,
      0
    ) - 1;

export const getRowIndexFromAddress = (cellAddress: string): number =>
  Number(cellAddress.match(/([0-9]+)/)[0]) - 1;

export const getColumnIndexFromAddress = (cellAddress: string): number =>
  getColNumericIndex(cellAddress.match(/([A-Z]+)/)[0]);

export const getColChar = (index: number): string => {
  const previousLetters =
    index >= 26 ? getColChar(Math.floor(index / 26) - 1) : '';
  const lastLetter = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[index % 26];

  return previousLetters + lastLetter;
};

export const parseNumberRange = (range: string): [number, number] => {
  const filteredRange = (range || '').match(/(-*\d+)(-*)(\d*)/g)?.[0];
  if (!filteredRange) {
    return null;
  }
  const rangeMatch = filteredRange.match(/(-*\d+)(-*)(\d*)/);
  const rangeStart = rangeMatch?.[1] ? Number(rangeMatch[1]) : null;
  const hasRangeDash = !!rangeMatch?.[2];
  const rangeEnd = rangeMatch?.[3] ? Number(rangeMatch[3]) : null;
  if (rangeEnd === null) {
    return [rangeStart, hasRangeDash ? -1 : rangeStart];
  }

  return [rangeStart, rangeEnd];
};

export const parseColumnRange = (range: string): [number, number] => {
  const filteredRange = (range || '').match(/(-*[A-Z]+)(-*)([A-Z]*)/g)?.[0];
  if (!filteredRange) {
    return null;
  }
  const rangeMatch = filteredRange.match(/(-*[A-Z]+)(-*)([A-Z]*)/);
  const rangeStart = rangeMatch?.[1] ? getColNumericIndex(rangeMatch[1]) : null;
  const hasRangeDash = !!rangeMatch?.[2];
  const rangeEnd = rangeMatch?.[3] ? getColNumericIndex(rangeMatch[3]) : null;

  if (rangeEnd === null) {
    return [rangeStart, hasRangeDash ? -1 : rangeStart];
  }

  return [rangeStart, rangeEnd];
};

export const parseSpreadsheetRange = (
  range: string,
  multipleRanges = false,
  rangeSeparator = ','
): { rowRange: [number, number]; colRange: [number, number] }[] => {
  const getParsedRange = (rangeString: string) => ({
    rowRange: parseNumberRange(rangeString),
    colRange: parseColumnRange(rangeString)
  });
  if (!range) {
    return null;
  }
  if (multipleRanges) {
    const ranges = range.split(rangeSeparator);

    return ranges.map(getParsedRange);
  }

  return [getParsedRange(range)];
};

export const parseSpreadsheetCellAddress = (cellAddress): [number, number] => {
  const row = getRowIndexFromAddress(cellAddress);
  const column = getColumnIndexFromAddress(cellAddress);

  return [row, column];
};

export const isValueInNumberRange = (
  value: number,
  range: [number, number]
): boolean =>
  !!range &&
  !isEmptyValue(range[0]) &&
  !isEmptyValue(range[1]) &&
  value >= range[0] &&
  value <= range[1];

export function getStepSpreadsheetApiData(
  step,
  spreadsheetService: SpreadsheetService
): Observable<any[]> {
  const inputs: any = Object.values(step.content.inputs) || [];
  const xls = step.template_params_json.apiResource;

    const visibleRows = step.template_params_json.visibleRows
      ? step.template_params_json.visibleRows
      : '';
    const visibleCols = step.template_params_json.visibleCols
      ? getParsedRange(step.template_params_json.visibleCols, 'column')
      : [];

  return combineLatest(
    inputs.map(input => {
      const inputId = input.id;

      return spreadsheetService.getSpreadsheet(input, xls, visibleRows.toString(), visibleCols).pipe(
        map(content => ({
          inputId,
          xls,
          content,
          visibleRows,
          visibleCols
        })),
        catchError(() => of({ inputId, xls, content: undefined }))
      );
    })
  );
}

export function getParsedRange(range, direction: 'row' | 'column') {
  return range
    ? range.split(',').reduce((rows, intv) => {
      const highLow = intv.split('-');
      if (direction === 'row' && highLow.length === 2 && !highLow[1]) {
        this.isOpenEndedRowRange = true;
        this.openEndedStartingRow = Number(highLow[0]) - 1;

        return [];
      }
      for (
        let idx = Number(highLow[0]) - 1;
        idx <= Number(highLow[highLow.length - 1]) - 1;
        idx++
      ) {
        rows.push(idx);
      }

      return rows;
    }, [])
    : [];
}
