import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tabName'
})
export class TabNamePipe implements PipeTransform {
  transform(value: string): string {
    return value
      .split('_')
      .map(fieldName => fieldName.charAt(0).toUpperCase() + fieldName.slice(1))
      .join(' ');
  }
}
