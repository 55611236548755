import { Component, OnInit, Inject } from '@angular/core';
import { CKEditor4 } from 'ckeditor4-angular/ckeditor';
import { ckEditorConfig } from '../../../module-builder/constants/ckeditor-config';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BlogService } from 'src/app/common/services/blog.service';
import { Post } from 'src/app/common/interfaces/post.interface';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

// autocomplete
import { COMMA, ENTER } from '@angular/cdk/keycodes';

const KEY_ENTER = 13;
const KEY_COMMA = 188;
@Component({
  selector: 'app-edit-blog',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditBlogComponent implements OnInit {
  form: FormGroup;
  post: Post;
  flagUpdate: boolean;
  tagList: string[];
  ckConfig: CKEditor4.Config = ckEditorConfig;

  // autocomplete
  myControl = new FormControl();
  filteredOptions: Observable<string[]>;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  deleteConfirmation = false;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  postTags: Array<any>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { id: number; tags: string[] },
    public dialogRef: MatDialogRef<EditBlogComponent>,
    private blogService: BlogService
  ) {
    this.form = new FormGroup({
      visible: new FormControl(false),
      title: new FormControl(null, [
        Validators.required,
        Validators.minLength(3)
      ]),
      content: new FormControl(null),
      tag: new FormControl(null)
    });
    this.flagUpdate = false;
    this.postTags = [];
    this.tagList = [];
  }

  ngOnInit(): void {
    if (this.data) {
      const { id, tags } = this.data;
      if (id) {
        this.flagUpdate = true;
        this.getPost(id);
      }
      this.tagList = tags;
    }

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this.filter(value))
    );
  }

  filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.tagList.filter(
      option => option.toLowerCase().indexOf(filterValue) === 0
    );
  }

  getPost(id: number) {
    this.blogService.getPost(id).subscribe(
      (response: any) => {
        this.post = response;
        this.postTags = response.tags !== null ? response.tags : [];
        this.setFormData();
      },
      err => console.warn
    );
  }

  setFormData() {
    const { title, content } = this.post;
    this.form.patchValue({
      title,
      content,
      visible: false,
      tag: ''
    });
  }

  onSubmit() {
    const { visible, title, content } = this.form.value;
    this.post = {
      ...this.post,
      visible,
      title,
      content,
      tags: this.postTags
    };
    this.post.new_tags = this.postTags.filter(
      tag => !this.tagList.includes(tag)
    );

    if (this.flagUpdate) {
      this.update();
    } else {
      this.create();
    }
  }

  update() {
    this.blogService.updatePost(this.post).subscribe(
      (response: any) => {
        this.dialogRef.close({
          action: 'update',
          post: response.post,
          tags: response.tags
        });
      },
      err => console.warn
    );
  }

  create() {
    this.blogService.createPost(this.post).subscribe(
      (response: any) => {
        this.dialogRef.close({
          action: 'create',
          post: response.post,
          tags: response.tags
        });
      },
      err => console.warn
    );
  }

  showDeleteConfirmation() {
    this.deleteConfirmation = true;
    setTimeout(() => {
      this.deleteConfirmation = false;
    }, 3000);
  }

  onDelete() {
    this.blogService.deletePost(this.post.id).subscribe(
      response => {
        this.dialogRef.close({ action: 'delete' });
      },
      err => console.warn
    );
  }

  add(event: any, selectedTag?: string): void {
    if (selectedTag) {
      this.postTags.push(selectedTag);
      this.myControl.setValue('');

      return;
    }
    if (event.keyCode !== KEY_ENTER && event.keyCode !== KEY_COMMA) return;
    const value = (event.target.value || '').trim();
    // Add our tag
    if (value) {
      this.postTags.push(value);
      this.myControl.setValue('');
    }
    // Clear the input value
    event.preventDefault();
  }

  remove(tag): void {
    const index = this.postTags.indexOf(tag);

    if (index >= 0) {
      this.postTags.splice(index, 1);
    }
  }
}
