import { Component, forwardRef } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import { PlaysReviewTemplateData, ReviewDataBlock } from '.';
import txt from '!!raw-loader!./index.ts';
import { switchMap, take } from 'rxjs/operators';
import { updateBlockRepeaterFormat } from '../block-repeater/helpers';

@Component({
  selector: 'plays-review',
  templateUrl: 'plays-review.component.html',
  styleUrls: ['./plays-review.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => PlaysReviewComponent)
    }
  ]
})
export class PlaysReviewComponent extends TemplateComponent {
  params = txt;
  contentData: PlaysReviewTemplateData['template_params_json'];

  tabNames = [];
  tabsData: ReviewDataBlock;
  empty_api_message: string;
  activeTabIndex = 0;
  templateClass: string;
  playReviewBlocksStructure = [
    {
      title: 'The play',
      name: 'secondColumn',
      innerBlocks: [
        {
          index: 2,
          title: 'When you hear',
          class: 'customer-say'
        },
        {
          index: 3,
          title: 'Actions to take',
          class: 'actions-to-take'
        }
      ],
      class: 'review-tab-block review-tab-second-block'
    },
    {
      name: 'secondColumn',
      innerBlocks: [
        {
          index: 4,
          title: 'The Goal',
          class: 'the-goal'
        },
        {
          index: 5,
          title: 'Success',
          class: 'success'
        }
      ],
      class: 'review-tab-block review-tab-third-block'
    },
    {
      name: 'secondColumn',
      innerBlocks: [
        {
          index: 6,
          title: 'Positive Signs',
          class: 'positive-signs'
        },
        {
          index: 7,
          title: 'Red Flags',
          class: 'red-flags'
        }
      ],
      class: 'review-tab-block review-tab-forth-block'
    }
  ];
  playReviewBlocksStructure2 = [
    {
      title: 'The play',
      name: 'thirdColumn',
      innerBlocks: [
        {
          title: 'Actions to take',
          class: 'actions-to-take'
        },
        {
          title: 'When you hear',
          class: 'customer-say'
        }
      ],
      class: 'review-tab-block review-tab-second-block'
    },
    {
      name: 'secondColumn',
      innerBlocks: [
        {
          title: 'The Goal',
          class: 'the-goal'
        },
        {
          title: 'Success',
          class: 'success'
        }
      ],
      class: 'review-tab-block review-tab-third-block'
    },
    {
      name: 'forthColumn',
      innerBlocks: [
        {
          title: 'Positive Signs',
          class: 'positive-signs'
        },
        {
          title: 'Red Flags',
          class: 'red-flags'
        }
      ],
      class: 'review-tab-block review-tab-forth-block'
    }
  ];

  init() {
    super.init();
    this.contentData = this.data.data
      .template_params_json as PlaysReviewTemplateData['template_params_json'];

    this.empty_api_message = this.textContent(
      this.contentData.message_to_show_when_API_response_is_empty || ''
    );

    this.templateClass =
      this.contentData.step_select === 'ReviewSalesPlays'
        ? 'review-sales-plays-template'
        : this.contentData.step_select === 'ReviewCompetitorSalesPlays'
        ? 'review-competitor-sales-plays-template'
        : '';

    if (this.contentData.api_url) {
      this.navService.organization$
        .pipe(
          take(1),
          switchMap(orgId =>
            this.moduleService.getAPIData(orgId, this.contentData.api_url)
          )
        )
        .subscribe(
          (data: []) => {
            const dataField = this.textContent(this.contentData.api_data_field);
            this.tabNames = data.map(el => el[dataField]);

            this.tabsData = this.setReviewData(this.initTabsData());
          },
          _ => (this.tabNames = [])
        );
    } else {
      this.tabNames = this.contentData.custom_tabs.map(tab =>
        this.textContent(tab.value)
      );
      this.tabsData = this.setReviewData(this.initTabsData());
    }
  }

  initTabsData(): string[][][][] {
    return Object.entries(this.inputs)
      .filter(input => input[0].includes('block_repeater_1'))
      .map(input => {
        const inputData = input[1];
        try {
          const content = updateBlockRepeaterFormat(
            JSON.parse(inputData.content)
          );
          const blocks: string[][][] = content.blocks.map(tabs =>
            Object.values(tabs.data).map(value =>
              Object.values(value).map(template => {
                const templateContent = template?.content;
                if (templateContent) {
                  try {
                    return JSON.parse(templateContent);
                  } catch (e) {
                    return this.textContent(templateContent);
                  }
                } else {
                  return [];
                }
              })
            )
          );

          return blocks;
        } catch (e) {
          return null;
        }
      })
      .filter(el => !!el);
  }

  setReviewData(content: string[][][][]): ReviewDataBlock {
    const reviewData: ReviewDataBlock = {
      firstColumn: null,
      secondColumn: null,
      thirdColumn: null,
      forthColumn: null
    };
    let loopData;

    switch (this.contentData.step_select) {
      case 'ReviewSalesPlays':
        reviewData.firstColumn = content[0].map((el, index) => ({
          name: el[0][0],
          tagline: el[0][1],
          situation: content[0][index][1][0]
        }));
        reviewData.secondColumn = this.prepareColValues(content[0]).map(el => [
          ...el
        ]);
        break;

      case 'ReviewCompetitorSalesPlays':
        loopData = { secondColumn: 0, thirdColumn: 1, forthColumn: 3 };
        reviewData.firstColumn = content[2].map(el => {
          const [name, tagline, situation] = el.shift();

          return { name, tagline, situation };
        });
        this.prepareReviewBlock(loopData, reviewData, content);
        break;

      case 'ReviewStageSalesPlays':
        loopData = { secondColumn: 0, thirdColumn: 2, forthColumn: 3 };
        reviewData.firstColumn = content[1].map(el => {
          const [name, tagline, situation] = el
            .filter(values => values.length)
            .shift();

          return { name, tagline, situation };
        });
        this.prepareReviewBlock(loopData, reviewData, content);
        break;
    }

    return reviewData;
  }

  prepareColValues(col) {
    return col.map((tab: []) =>
      tab.map(el =>
        Object.values(el[0])
          .map((val: any) => val.option)
          .filter(val => !!val)
      )
    );
  }

  prepareReviewBlock(loopData: [], reviewData: ReviewDataBlock, content): void {
    Object.entries(loopData).forEach(([columnName, index]) => {
      reviewData[columnName] = this.prepareColValues(content[index]);
    });
  }

  getDescription() {
    return '';
  }

  getName() {
    return 'Plays Review';
  }

  getGroup() {
    return 'Outputs';
  }
}
