import { AfterContentInit, Component, forwardRef } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import { CountdownTimerTemplateData } from '.';
import txt from '!!raw-loader!./index.ts';

@Component({
  selector: 'countdown-timer',
  templateUrl: 'countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => CountdownTimerComponent)
    }
  ]
})
export class CountdownTimerComponent extends TemplateComponent
  implements AfterContentInit {
  params = txt;
  time: string;
  run: boolean;
  interval: number;
  seconds = 0;
  minutes = 0;
  secondsElement: HTMLInputElement;
  minutesElement: HTMLInputElement;
  date = new Date();
  contentData: CountdownTimerTemplateData['template_params_json'];

  getDescription() {
    return '';
  }

  getName() {
    return 'Countdown Timer';
  }

  getGroup() {
    return 'Generic';
  }

  startTimer() {
    this.run = true;
    let startPoint = Date.now();
    this.interval = window.setInterval(() => {
      if (this.run) {
        if (Date.now() - startPoint >= 1000) {
          this.seconds--;
          startPoint += 1000;
        }

        if (this.seconds === -1) {
          this.seconds = 59;
          this.minutes--;
          if (this.minutes === -1) {
            this.seconds = 0;
            this.minutes = 0;
            clearInterval(this.interval);
          }
        }
        this.setTime();
      }
    }, 100);
  }

  resetTimer() {
    this.run = false;
    clearInterval(this.interval);
    this.seconds = this.contentData.seconds;
    this.minutes = this.contentData.minutes;
    this.setTime();
  }

  init() {
    this.contentData = this.data.data
      .template_params_json as CountdownTimerTemplateData['template_params_json'];
  }

  ngAfterContentInit(): void {
    this.secondsElement = document.getElementById(
      'seconds'
    ) as HTMLInputElement;
    this.minutesElement = document.getElementById(
      'minutes'
    ) as HTMLInputElement;
    const id = this.data.data.step_id;
    if (localStorage.getItem(`${id}_minutes`)) {
      this.minutes = Number(localStorage.getItem(`${id}_minutes`));
      this.seconds = Number(localStorage.getItem(`${id}_seconds`));
      this.run = 'true' === localStorage.getItem(`${id}_run`);
      if (this.run) {
        this.startTimer();
      }
    } else {
      this.seconds = this.contentData.seconds;
      this.minutes = this.contentData.minutes;
    }
    this.setTime();
  }

  setTime() {
    const id = this.data.data.step_id;
    this.secondsElement.value =
      this.seconds > 9
        ? this.seconds.toString()
        : '0' + this.seconds.toString();
    this.minutesElement.value =
      this.minutes > 9
        ? this.minutes.toString()
        : '0' + this.minutes.toString();
    localStorage.setItem(`${id}_minutes`, this.minutes.toString());
    localStorage.setItem(`${id}_seconds`, this.seconds.toString());
    localStorage.setItem(`${id}_run`, this.run.toString());
  }

  setSeconds(event) {
    const seconds = Number(event.target.value);
    this.seconds =
      seconds < 0 || seconds > 59 || isNaN(seconds)
        ? this.contentData.seconds || 1
        : seconds;
    event.target.value = this.seconds < 10 ? `0${this.seconds}` : this.seconds;
  }

  setMinutes(event) {
    const minutes = Number(event.target.value);
    this.minutes = minutes <= 0 || minutes > 59 || isNaN(minutes) ? 1 : minutes;
    event.target.value = this.minutes < 10 ? `0${this.minutes}` : this.minutes;
  }
}
