import { Injectable } from '@angular/core';
import {
  Module,
  StepSearchResponse,
  Template,
  TemplateGroup
} from '../interfaces/module.interface';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class TemplateService {
  private baseUrl = environment.apiRoot + '/api/modules';
  private _templates;

  get templates() {
    return this._templates;
  }

  set templates(templates) {
    this._templates = templates;
  }

  constructor(private httpClient: HttpClient) {}

  static getTemplateGroups(templates: Template[]): TemplateGroup[] {
    return templates
      .reduce(
        (
          templateGroups: { groupName: string; templates: Template[] }[],
          currentTemplate
        ) => {
          const templateGroup = templateGroups.find(
            group => group.groupName === currentTemplate.groupName
          );
          if (templateGroup) {
            templateGroup.templates.push(currentTemplate);
          } else {
            templateGroups.push({
              groupName: currentTemplate.groupName,
              templates: [currentTemplate]
            });
          }

          return templateGroups;
        },
        [
          { groupName: 'Generic', templates: [] },
          { groupName: 'Module', templates: [] },
          { groupName: 'Outputs', templates: [] }
        ]
      )
      .filter(templateGroup => templateGroup.templates.length);
  }

  getTemplates(): Template[] {
    return Object.keys(this._templates)
      .map(id => {
        const inst = new this._templates[id]();

        return {
          id,
          name: inst.getName(),
          groupName: inst.getGroup() || 'Generic',
          description: inst.getDescription(),
          hasInputs: inst.hasInputs(),
          params_json: inst.getBuilderParams()
        };
      })
      .sort((a, b): number => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
      });
  }

  searchTemplateInModules(
    templateType = '',
    query = ''
  ): Observable<StepSearchResponse> {
    return this.httpClient.get<StepSearchResponse>(
      `${this.baseUrl}/find-steps?template=${templateType ||
        ''}&query=${query || ''}`
    );
  }
}
