/* eslint-disable prefer-arrow/prefer-arrow-functions */
import * as Handsontable from 'src/assets/scripts/handsontable.js';
import { SpreadsheetMultipleCheckboxItem } from '../../../../../common/interfaces/spreadsheet.interface';
import { isEmptyValue } from '../../../../../common/utils/helpers';

const isCheckboxListenerAdded = new WeakMap();

export function empty(element) {
  let child;
  while ((child = element.lastChild)) {
    element.removeChild(child);
  }
}

export function isImmediatePropagationStopped(event) {
  return event.isImmediatePropagationEnabled === false;
}

export function MultipleCheckboxesRenderer(
  hotInstance,
  td: HTMLTableDataCellElement,
  row,
  column,
  prop,
  value,
  cellProperties
) {
  Handsontable.renderers.BaseRenderer.apply(this, arguments);
  registerEvents(hotInstance);

  const options: SpreadsheetMultipleCheckboxItem[] = getOptions(cellProperties);
  const selected: SpreadsheetMultipleCheckboxItem[] = parseCellValue(value);
  const showOtherOption: boolean = cellProperties?.options?.showOtherCheckbox;

  let inputs;

  if (options.length) {
    inputs = options.map((option, index) => {
      const isSelected = !!selected.find(item => item.index === option.index);

      return createOptionElement(
        String(option.value),
        option.index || String(index),
        isSelected
      );
    });
  }

  empty(td);
  if (inputs?.length) {
    inputs.forEach(input => td.appendChild(input));
    if (showOtherOption) {
      const selectedOther = selected?.find(item => item.isOther);
      const otherCheckboxDiv = createOtherCheckboxElement(
        selectedOther ? String(selectedOther.value || '') : null
      );
      td.appendChild(otherCheckboxDiv);
    }
  }

  function createCheckbox(
    option: string,
    index: string,
    isSelected = false,
    isOther = false
  ) {
    const input = document.createElement('input');
    input.className = 'htCheckboxRendererInput';
    input.type = 'checkbox';
    input.setAttribute('autocomplete', 'off');
    input.setAttribute('tabindex', '-1');
    input.setAttribute('data-row', row);
    input.setAttribute('data-col', column);
    if (index) {
      input.setAttribute('data-index', index);
    }
    if (option) {
      input.setAttribute('data-option', option);
    }
    if (isOther) {
      input.setAttribute('data-other-checkbox', String(true));
    }
    input.checked = isSelected;

    return input;
  }

  function createLabel(option: string) {
    const label = document.createElement('label');

    label.className = 'htCheckboxRendererLabel';
    label.appendChild(document.createTextNode(option));

    return label;
  }

  function createOtherInput(option: string, isSelected = false) {
    const input = document.createElement('input');
    input.className = 'other-checkbox-text-input';
    input.type = 'text';
    input.setAttribute('data-row', row);
    input.setAttribute('data-col', column);
    input.value = option || '';
    input.style.display = isSelected ? 'block' : 'none';
    input.onkeydown = event => {
      const key = event.keyCode || event.charCode;
      if (key === 8 || key === 46 || (key >= 37 && key <= 40)) {
        event.stopPropagation();
      }
    };

    return input;
  }

  function createOptionElement(
    option: string,
    index: string,
    isSelected: boolean
  ) {
    const divElement = document.createElement('DIV');
    const input = createCheckbox(option, index, isSelected);
    divElement.className = `checkbox-item ${
      !input.checked
        ? 'not-show-in-printable-mode'
        : 'bullet-printable-checkbox'
    }`;
    const label = createLabel(option);
    divElement.appendChild(input);
    divElement.appendChild(label);

    return divElement;
  }

  function createOtherCheckboxElement(option: string) {
    const isSelected = !isEmptyValue(option);
    const divElement = document.createElement('DIV');
    divElement.className = 'checkbox-item other-checkbox';
    const checkbox = createCheckbox(option, null, isSelected, true);
    const label = createLabel('Other');
    label.style.display = isSelected ? 'none' : 'block';
    const textInput = createOtherInput(option, isSelected);
    divElement.appendChild(checkbox);
    divElement.appendChild(label);
    divElement.appendChild(textInput);

    divElement.className = `${divElement.className} ${
      !checkbox.checked ? 'not-show-in-printable-mode' : ''
    }`;

    return divElement;
  }
}

function registerEvents(instance) {
  let eventManager = isCheckboxListenerAdded.get(instance);

  if (!eventManager) {
    eventManager = new Handsontable.EventManager();
    eventManager.addEventListener(instance.rootElement, 'click', event =>
      onClick(event, instance)
    );
    eventManager.addEventListener(instance.rootElement, 'change', event =>
      onChange(event, instance)
    );

    isCheckboxListenerAdded.set(instance, eventManager);
  }
}

function onClick(event, instance) {
  if (isLabel(event.target)) {
    const element = event.target?.parentNode?.firstChild;
    if (isCheckboxInput(element)) {
      element.click();

      return false;
    }
  } else if (!isCheckboxInput(event.target) && !isTextInput(event.target)) {
    return false;
  } else if (isTextInput(event.target)) {
    event.target.focus();

    return false;
  }

  const row = parseInt(event.target.getAttribute('data-row'), 10);
  const col = parseInt(event.target.getAttribute('data-col'), 10);
  const cellProperties = instance.getCellMeta(row, col);

  if (cellProperties.readOnly) {
    event.preventDefault();
  }
}

function onChange(event, instance) {
  if (!isCheckboxInput(event.target) && !isTextInput(event.target)) {
    return false;
  }

  const isOther = isOtherCheckbox(event.target) || isTextInput(event.target);
  const row = parseInt(event.target.getAttribute('data-row'), 10);
  const col = parseInt(event.target.getAttribute('data-col'), 10);
  const optionIndex: string = event.target.getAttribute('data-index');
  const cellProperties = instance.getCellMeta(row, col);
  const options = getOptions(cellProperties);
  const currentValue: string = instance.getDataAtCell(row, col);
  const data = filterOutDeprecatedSelections(
    parseCellValue(currentValue),
    options
  );
  const index = isOther
    ? data.findIndex(item => item.isOther)
    : data.findIndex(item => item.index === optionIndex);
  if (isCheckboxInput(event.target)) {
    let otherTextInput: HTMLInputElement;
    if (isOther) {
      switchOtherCheckbox(event.target);
      otherTextInput = event.target?.parentNode?.[2] as HTMLInputElement;
    }
    if (!cellProperties.readOnly && (optionIndex || isOther)) {
      if (event.target.checked) {
        const isOptionSelected = index !== -1;
        const option: SpreadsheetMultipleCheckboxItem = isOther
          ? { value: otherTextInput?.value || '', isOther: true }
          : options.find(item => item.index === optionIndex);
        if (!isOptionSelected && option) {
          data.push(option);
        }
      } else {
        if (index !== -1) {
          data.splice(index, 1);
        }
      }
    }
  } else if (isTextInput(event.target)) {
    event.preventDefault();
    const textValue = event.target.value;
    if (index === -1) {
      data.push({ value: textValue, isOther: true });
    } else {
      data[index].value = event.target.value;
    }
  }

  const newDataString = JSON.stringify(data);
  if (newDataString !== currentValue) {
    instance.setDataAtCell(row, col, newDataString);
  }
}

function isLabel(element: HTMLElement) {
  return element.tagName === 'LABEL';
}

function isTextInput(element) {
  return element.tagName === 'INPUT' && element.getAttribute('type') === 'text';
}

function isCheckboxInput(element) {
  return (
    element.tagName === 'INPUT' && element.getAttribute('type') === 'checkbox'
  );
}

function isOtherCheckbox(element) {
  return element.getAttribute('data-other-checkbox') === 'true';
}

function switchOtherCheckbox(checkboxElement: HTMLInputElement) {
  const divElement = checkboxElement?.parentNode;
  if (divElement) {
    const label = divElement.childNodes[1] as HTMLLabelElement;
    const textInput = divElement.childNodes[2] as HTMLInputElement;
    if (checkboxElement.checked) {
      label.style.display = 'none';
      textInput.style.display = 'block';
    } else {
      label.style.display = 'block';
      textInput.style.display = 'none';
    }
  }
}

function getOptions(properties): SpreadsheetMultipleCheckboxItem[] {
  return properties.options?.checkboxes
    ? [...properties.options.checkboxes]
    : [];
}

function filterOutDeprecatedSelections(
  selected: SpreadsheetMultipleCheckboxItem[],
  options: SpreadsheetMultipleCheckboxItem[]
): SpreadsheetMultipleCheckboxItem[] {
  return options.length
    ? [
        ...options.filter(
          option => !!selected.find(item => option.index === item.index)
        ),
        selected.find(item => item.isOther)
      ].filter(Boolean)
    : selected;
}

function parseCellValue(value: string): SpreadsheetMultipleCheckboxItem[] {
  let values: SpreadsheetMultipleCheckboxItem[] = [];
  try {
    const parsedValue = JSON.parse(value);
    if (Array.isArray(parsedValue)) {
      values = parsedValue.map((item, index) =>
        typeof item === 'string' ? { value: item, index: String(index) } : item
      );
    }
  } catch (e) {
    values = [];
  }

  return values;
}
