import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import {
  Module,
  OtherStep,
  Step,
  Template,
  TemplateGroup
} from 'src/app/common/interfaces/module.interface';
import { TemplateField } from './step-template-field';
import { ExportService } from '../../../common/services/export.service';
import { MatDialogRef } from '@angular/material/dialog';
import { TemplateService } from '../../../common/services/template.service';
import { MatSelectChange } from '@angular/material/select';

const labelDescriptors = {
  question_image: {
    image: { label: 'image', descriptor: 'image/pdf_url' }
  }
};
@Component({
  selector: 'app-step-template-editor',
  templateUrl: './step-template-editor.component.html',
  styleUrls: ['./step-template-editor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StepTemplateEditorComponent implements OnInit {
  @Input() step: Step;

  @Input() rootLevelSteps: Step[];

  @Input() partialSteps: Step[];

  @Input() moduleData: Module;

  stepEdit: Step;

  template: Template;

  templates: Template[];

  templateGroups: TemplateGroup[] = [];
  filteredTemplateGroups: TemplateGroup[] = [];

  templateFieldsData: { [key: string]: any } = {};
  templateFields: TemplateField[] = [];
  generalTemplateFields: TemplateField[] = [];
  configurationTemplateFields: TemplateField[] = [];
  arrayTemplateFields: TemplateField[] = [];

  configurationFields = ['number_of_inputs', 'input_sufix'];

  parentStep: Step;
  parentTemplate: Template;

  description = '';

  activeTab = 0;

  isUnderConstruction: boolean;
  stepFieldsToHide: { [key: string]: boolean } = {};
  fieldDescriptor: any;

  constructor(
    public modal: MatDialogRef<StepTemplateEditorComponent>,
    public exportService: ExportService,
    private templateService: TemplateService
  ) {}

  ngOnInit() {
    this.stepEdit = JSON.parse(JSON.stringify(this.step));
    if ('[]' === JSON.stringify(this.stepEdit.template_params_json)) {
      this.stepEdit.template_params_json = {};
    }
    try {
      this.stepEdit.other_steps = JSON.parse(
        this.stepEdit.other_steps as string
      );
    } catch (e) {
      this.stepEdit.other_steps = [];
    }

    this.templates = this.templateService.getTemplates();
    this.templateGroups = TemplateService.getTemplateGroups(this.templates);
    this.filteredTemplateGroups = this.templateGroups;
    this.onTemplateChange(this.stepEdit.template_component);

    if (
      this.template ||
      (!this.template && !this.stepEdit.template_component)
    ) {
      this.isUnderConstruction = false;
    } else {
      this.isUnderConstruction = true;
      console.warn(
        'Failed to load template ',
        this.stepEdit.template_component
      );
    }

    if (this.step.is_partial) {
      this.parentStep =
        this.rootLevelSteps.find(
          step => step.id === this.step.parent_step_id
        ) ||
        this.partialSteps.find(step => step.id === this.step.parent_step_id);
      this.parentTemplate = this.parentStep
        ? this.templates.find(
            template => template.id === this.parentStep.template_component
          )
        : null;
    }
  }

  save() {
    this.filterOutForeignFields();
    this.step.template_component = this.stepEdit.template_component;
    this.step.template_params_json = this.stepEdit.template_params_json;
    this.step.is_exportable = this.stepEdit.is_exportable;
    this.step.export_title = this.stepEdit.export_title;
    this.step.export_api = this.stepEdit.export_api;
    this.step.topic = this.stepEdit.topic;
    this.step.is_printable = this.stepEdit.is_printable;
    this.step.is_request_feedback = this.stepEdit.is_request_feedback;
    this.step.is_pdf_exportable = this.stepEdit.is_pdf_exportable;
    this.step.pdf_export_title = this.stepEdit.pdf_export_title;
    this.step.pdf_export_filename = this.stepEdit.pdf_export_filename;
    this.step.is_other_step_pdf_export = this.stepEdit.is_other_step_pdf_export;
    this.step.other_steps = JSON.stringify(this.stepEdit.other_steps);
    this.step.is_partial = this.stepEdit.is_partial;
    this.step.position = this.stepEdit.position;
    this.step.step_index = this.stepEdit.step_index;
    this.step.pptx_steps = this.stepEdit.pptx_steps;
    if (this.step.parent_step_id !== this.stepEdit.parent_step_id) {
      this.step.parent_step_id = this.stepEdit.parent_step_id;
      const stepId = this.partialSteps.findIndex(
        step => step.id === this.step.id
      );
      if (stepId !== -1) {
        this.partialSteps.splice(stepId, 1);
      }
      this.partialSteps.push(this.step);
    }
    this.modal.close();
  }

  onTemplateChange(templateId: string) {
    this.template = this.templates.find(tpl => tpl.id === templateId);
    if (this.template && this.template.params_json) {
      const fields = this.template.params_json
        .replace(/\s/g, '')
        .split(/inputs\:\s{0,}\[\]/)
        .join('inputs:Array<{key: string}>')
        .split(';')
        .join(',')
        .split('Array<')
        .join('[')
        .split('>')
        .join(']')
        .replace(/([_a-zA-Z0-9]+)/g, '"$1"')
        .split(',}')
        .join('}')
        .split('{')
        .join('[')
        .split('}')
        .join(']')
        .split(',')
        .join('],[')
        .split(':')
        .join(',')
        .split('?')
        .join('')
        .split('\'')
        .join('')
        .split('|')
        .join(',')
        .split(',,')
        .join(',');

      this.fieldDescriptor = labelDescriptors[templateId]
        ? labelDescriptors[templateId]
        : null;

      this.fillTemplateFields(fields);

      this.description = this.template.description;
      this.generalTemplateFields.forEach(field =>
        this.hideDependentFields(field[0])
      );
    }
  }

  fieldsParse(fields) {
    return JSON.parse(fields).map(field => {
      if (field.length === 2) {
        if (field[1] instanceof Array) {
          return [field[0], this.fieldsParse(JSON.stringify(field[1]))];
        } else {
          return field;
        }
      } else {
        return [field[0], field.slice(1)];
      }
    });
  }

  getComposableSteps(stepId: number): Step[] {
    const composableStepsFromRootLevel = this.rootLevelSteps.filter(
      step =>
        (step.template_component === 'composable_template' ||
          step.template_component === 'block_repeater') &&
        step.id !== stepId
    );
    const composableStepsFromPartial = this.partialSteps
      .filter(
        step =>
          (step.template_component === 'composable_template' ||
            step.template_component === 'block_repeater') &&
          step.id !== stepId
      )
      .map(step => {
        const titleKey = 'title';

        return {
          ...step,
          description: step.template_params_json[titleKey]
            ? step.template_params_json[titleKey]
            : ''
        };
      });

    return composableStepsFromRootLevel.concat(composableStepsFromPartial);
  }

  trackByFn(index, item) {
    return index;
  }

  moveToComposableBlock(event: MatSelectChange): void {
    const selectedStep = this.rootLevelSteps.find(
      step => step.id === event.value
    )
      ? this.rootLevelSteps.find(step => step.id === event.value)
      : this.partialSteps.find(step => step.id === event.value);
    if (selectedStep) {
      const subSteps = this.partialSteps
        .filter(step => step.parent_step_id === selectedStep.id)
        .sort((stepA, stepB) => stepA.position - stepB.position);
      subSteps.forEach(subStep => {
        if (subStep.position > this.stepEdit.position) {
          const position = subStep.position;
          subStep.position = this.stepEdit.position;
          this.stepEdit.position = position;
        }
        if (subStep.step_index > this.stepEdit.step_index) {
          const stepIndex = subStep.step_index;
          subStep.step_index = this.stepEdit.step_index;
          this.stepEdit.step_index = stepIndex;
        }
      });
      this.stepEdit.parent_step_id = selectedStep.id;
      this.stepEdit.is_partial = true;
    }
  }

  filterTemplates(query: string): void {
    this.filteredTemplateGroups = this.templateGroups.map(group => {
      const tmpGroup = { ...group };
      tmpGroup.templates = tmpGroup.templates.filter(template =>
        template.name.toLowerCase().includes(query.toLowerCase())
      );

      return tmpGroup;
    });
  }

  onFieldChange(field: string, event): void {
    this.templateFieldsData = {
      ...this.templateFieldsData,
      [field]: event
    };
    this.hideDependentFields(field);
  }

  changeActiveTab({ index }) {
    const hiddenTabStepsCount = this.arrayTemplateFields
      .slice(0, index)
      .map(([key]) => key)
      .filter(tabName => this.stepFieldsToHide[tabName]).length;

    this.activeTab = index + hiddenTabStepsCount;
  }

  hideDependentFields(field: string): void {
    const value = this.stepEdit.template_params_json[field];
    switch (this.stepEdit.template_component) {
      case 'list_entry': {
        switch (field) {
          case 'min_number_of_filled_items': {
            this.stepFieldsToHide = {
              ...this.stepFieldsToHide,
              error_message_when_not_enough_items_filled: !value
            };
            break;
          }
          case 'inline_inputs': {
            this.stepFieldsToHide = {
              ...this.stepFieldsToHide,
              input_min_rows_height: !value,
              input_max_rows_height: !value
            };
            break;
          }
        }
        break;
      }
      case 'expand_data': {
        switch (field) {
          case 'enable_custom_columns': {
            this.stepFieldsToHide = {
              ...this.stepFieldsToHide,
              custom_columns: !value,
              types_for_custom_columns: !value
            };
            break;
          }
        }
        break;
      }
      case 'matrix_entry': {
        switch (field) {
          case 'min_number_of_filled_items': {
            this.stepFieldsToHide = {
              ...this.stepFieldsToHide,
              error_message_when_not_enough_items_filled: !value
            };
            break;
          }
          case 'form_type_select': {
            const isTableView =
              this.stepEdit.template_params_json[field] === '1_table_view';
            const isVerticalView =
              this.stepEdit.template_params_json[field] ===
              '3_vertical_form_view';
            this.stepFieldsToHide = {
              ...this.stepFieldsToHide,
              show_column_index: !isTableView,
              allow_reordering_items: !isVerticalView,
              show_footer_row: !isTableView,
              multiple_headers: !isTableView,
              reverse_rows_columns_in_table: !isTableView
            };
            break;
          }
          case 'api_as_default_data_source': {
            this.stepFieldsToHide = {
              ...this.stepFieldsToHide,
              api_endpoint: !value,
              api_fields_to_use: !value,
              default_data: !!value
            };
            break;
          }
          case 'copy_matrix_entry_data_as_source': {
            this.stepFieldsToHide = {
              ...this.stepFieldsToHide,
              source_input_name: !value,
              source_content_path: !value,
              default_data: !!value
            };
            break;
          }
          case 'multiple_headers': {
            this.stepFieldsToHide = {
              ...this.stepFieldsToHide,
              header_rows: !value
            };
            break;
          }
          case 'show_predefined_left_column': {
            this.stepFieldsToHide = {
              ...this.stepFieldsToHide,
              predefined_left_column: !value
            };
            break;
          }
        }
        break;
      }
      case 'checkbox': {
        switch (field) {
          case 'allow_multiple_checkboxes_in_row': {
            this.stepFieldsToHide = {
              ...this.stepFieldsToHide,
              multiple_checkboxes: !value
            };
            break;
          }
          case 'allowUserCustomQuestions': {
            this.stepFieldsToHide = {
              ...this.stepFieldsToHide,
              customItemLabel: !value
            };
            break;
          }
          case 'api_as_data_source': {
            this.stepFieldsToHide = {
              ...this.stepFieldsToHide,
              api_endpoint: !value,
              api_endpoint_data_field: !value
            };
          }
        }
        break;
      }
      case 'block_repeater': {
        switch (field) {
          case 'step_type_select': {
            const tabsFromApi =
              this.stepEdit.template_params_json[field] === 'tabs_from_api';
            const tabsFromBuilder =
              this.stepEdit.template_params_json[field] === 'tabs_from_builder';
            const noTabs =
              this.stepEdit.template_params_json[field] === 'no_tabs';
            this.stepFieldsToHide = {
              ...this.stepFieldsToHide,
              api_url: !tabsFromApi,
              tabs_data_field: !tabsFromApi,
              message_to_show_when_API_response_is_empty: !tabsFromApi,
              tabs_list: !tabsFromBuilder,
              use_buyer_persona_as_source: !(tabsFromBuilder || noTabs),
              default_data_count: !noTabs,
              allow_to_remove_blocks: !noTabs,
              add_button_label: !noTabs,
              remove_button_label: !noTabs
            };
            break;
          }
          case 'template_blocks': {
            const childSteps = this.partialSteps
              .filter(step => step.parent_step_id === this.step.id)
              .sort((stepA, stepB) => stepA.position - stepB.position);
            const hasPreviousModuleResult = childSteps.some(
              step => step.template_component === 'module_result'
            );
            this.stepFieldsToHide = {
              ...this.stepFieldsToHide,
              previous_module_result_params: !hasPreviousModuleResult
            };
            break;
          }
        }
        break;
      }
      case 'composable_template': {
        switch (field) {
          case 'show_linked_steps':
            this.stepFieldsToHide = {
              ...this.stepFieldsToHide,
              linked_steps_order: !value
            };
            break;
        }
        break;
      }
      case 'quiz': {
        switch (field) {
          case 'addQuestionButton':
            this.stepFieldsToHide = {
              ...this.stepFieldsToHide,
              buttonLabel: !value
            };
            break;
        }
        break;
      }
      case 'select_buyer_persona': {
        switch (field) {
          case 'type_select':
            const isDropdown =
              this.stepEdit.template_params_json[field] === 'dropdown';
            this.stepFieldsToHide = {
              ...this.stepFieldsToHide,
              min_number_of_selections: !isDropdown,
              max_number_of_selections: !isDropdown
            };
            break;
        }
        break;
      }
      case 'spreadsheet': {
        switch (field) {
          case 'allow_adding_items': {
            this.stepFieldsToHide = {
              ...this.stepFieldsToHide,
              add_item_action_label: !value,
              number_of_rows_to_add_on_click: !value
            };
            break;
          }
          case 'allow_deleting_items': {
            this.stepFieldsToHide = {
              ...this.stepFieldsToHide,
              deleting_row_range: !value
            };
            break;
          }
          case 'enable_filtering_empty_rows': {
            this.stepFieldsToHide = {
              ...this.stepFieldsToHide,
              filtering_empty_row_range: !value,
              specified_column_index_to_check_for_empty_rows: !value,
              number_of_empty_rows_by_default: !value
            };
            break;
          }
        }
        break;
      }
      case 'file_uploader': {
        switch (field) {
          case 'filetype_select':
            this.stepFieldsToHide = {
              ...this.stepFieldsToHide,
              fileExtensions: !(
                this.stepEdit.template_params_json[field] === 'Custom'
              )
            };
            break;
        }
        break;
      }
      case 'video': {
        switch (field) {
          case 'show_response_field':
            this.stepFieldsToHide = {
              ...this.stepFieldsToHide,
              placeholder: !value,
              question_label: !value
            };
            break;
        }
        break;
      }
      case 'campaign_calendar': {
        switch (field) {
          case 'enable_custom_fields':
            this.stepFieldsToHide = {
              ...this.stepFieldsToHide,
              custom_fields: !value,
              inputs_for_custom_fields: !value
            };
            break;
        }
        break;
      }
      case 'raci': {
        switch (field) {
          case 'dynamic_table':
            {
              this.stepFieldsToHide = {
                ...this.stepFieldsToHide,
                number_of_predefined_cols: !value,
                number_of_predefined_rows: !value,
                projects: !value,
                default_cols: !value,
                default_rows: !value
              };
            }
            break;
        }
      }
    }
  }

  addOtherStep() {
    if (!this.stepEdit.other_steps) {
      this.stepEdit.other_steps = [] as OtherStep[];
    }

    (this.stepEdit.other_steps as OtherStep[]).push({
      module_id: null,
      step_id: null,
      button_title: ''
    });
  }

  removeOtherStep(step: OtherStep) {
    const idx = (this.stepEdit.other_steps as OtherStep[]).indexOf(step);
    (this.stepEdit.other_steps as OtherStep[]).splice(idx, 1);
  }

  getLabelDescriptor(key: any): string {
    let label: string;
    if (this.fieldDescriptor[key[0]]) {
      label =
        key[0] === this.fieldDescriptor[key[0]].label
          ? this.fieldDescriptor[key[0]].descriptor
          : null;
    } else {
      label = null;
    }

    return label;
  }

  private fillTemplateFields(fields: string): void {
    this.templateFields = [];
    this.configurationTemplateFields = [];
    this.arrayTemplateFields = [];
    this.generalTemplateFields = [];
    const templateData = {};

    JSON.parse('[' + fields + ']').forEach(field => {
      const fieldToAdd =
        field.length === 2 ? field : [field[0], field.slice(1)];
      this.templateFields.push(fieldToAdd);
      templateData[fieldToAdd[0]] = this.stepEdit.template_params_json[
        field[0]
      ];

      if (this.configurationFields.includes(fieldToAdd[0])) {
        this.configurationTemplateFields.push(fieldToAdd);
      } else if (
        Array.isArray(fieldToAdd[1]) &&
        fieldToAdd[0].substr(-7) !== '_select' &&
        fieldToAdd[0].substr(-7) !== '_blocks'
      ) {
        this.arrayTemplateFields.push(fieldToAdd);
      } else {
        this.generalTemplateFields.push(fieldToAdd);
      }
    });
    this.templateFieldsData = { ...templateData };
    if (this.template.hasInputs && !fields.includes('number_of_inputs')) {
      this.templateFields.push(['number_of_inputs', 'number']);
      this.configurationTemplateFields.push(['number_of_inputs', 'number']);
    }
  }

  private filterOutForeignFields(): void {
    this.stepEdit = {
      ...this.stepEdit,
      template_params_json: this.templateFields.reduce((accum, field) => {
        if (this.stepEdit.template_params_json[field[0]] !== undefined) {
          accum[field[0]] = this.stepEdit.template_params_json[field[0]];
        }

        return accum;
      }, {})
    };
  }
}
