import { Component, forwardRef, OnInit } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import { BuyerJourneyMapTemplateData, stepsData } from '.';
import txt from '!!raw-loader!./index.ts';

@Component({
  selector: 'buyer-journey-map',
  templateUrl: 'buyer-journey-map.component.html',
  styleUrls: ['./buyer-journey-map.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => BuyerJourneyMapComponent)
    }
  ]
})
export class BuyerJourneyMapComponent extends TemplateComponent
  implements OnInit {
  params = txt;
  contentData: BuyerJourneyMapTemplateData['template_params_json'];

  journeyData: {
    [step: string]: Array<{ title: string; checked: boolean }>;
  } = {};
  journeyStepData = stepsData;

  private readonly steps = [
    'recognizes',
    'need',
    'options',
    'risk',
    'solution',
    'realizes'
  ];

  ngOnInit() {
    super.ngOnInit();

    this.steps.forEach((step, i) => {
      this.journeyData[step] = JSON.parse(
        this.getInput(`checkbox_1_questions_${i + 1}`).content
      );
    });
  }

  getDescription() {
    return '';
  }

  getName() {
    return 'Buyer Journey Map';
  }

  getGroup(): string {
    return 'Outputs';
  }
}
