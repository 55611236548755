import { Component, forwardRef } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import txt from '!!raw-loader!./index.ts';
import {
  InputSummaryInfo,
  MatrixEntrySummaryTemplateData,
  MatrixSummary
} from './index';
import { TemplateInput } from '../../../../common/interfaces/module.interface';
import { updateBlockRepeaterFormat } from '../block-repeater/helpers';

@Component({
  selector: 'app-matrix-entry-summary',
  templateUrl: './matrix-entry-summary.component.html',
  styleUrls: ['./matrix-entry-summary.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => MatrixEntrySummaryComponent)
    }
  ]
})
export class MatrixEntrySummaryComponent extends TemplateComponent {
  params = txt;
  contentData: MatrixEntrySummaryTemplateData['template_params_json'];

  loading = false;
  matrixSummary: MatrixSummary[];
  matrixHeader: string[] = [];

  getDescription(): string {
    return '';
  }

  getName(): string {
    return 'Matrix Entry Summary';
  }

  getGroup(): string {
    return 'Outputs';
  }

  protected init(): void {
    this.contentData = this.data.data
      .template_params_json as MatrixEntrySummaryTemplateData['template_params_json'];
    if (this.contentData.input_keys?.length) {
      this.parseInputData();
      this.matrixHeader = this.getMatrixHeader();
    }
  }

  private getMatrixHeader(): string[] {
    if (
      this.matrixSummary.length &&
      this.matrixSummary[0].matrix &&
      this.matrixSummary[0].matrix[0]
    ) {
      const sectionTitle = this.contentData.section_title || '';
      const matrixHeader = this.matrixSummary[0].matrix[0].map(
        item => item.title
      );

      return [sectionTitle, ...matrixHeader];
    }

    return null;
  }

  private parseInputData(): void {
    this.matrixSummary = this.contentData.input_keys
      .reduce((accum, key) => {
        const input = this.getInput(key.key);
        if (input && input.content) {
          if (input.element_key.includes('block_repeater')) {
            const matrixSummaries = this.getMatrixSummariesFromBlockRepeater(
              input,
              key
            );
            accum.push(...(matrixSummaries || []));
          }
          if (input.element_key.includes('matrix_entry')) {
            const matrixSummary = this.getMatrixSummaryFromMatrixEntry(
              input,
              key
            );
            accum.push(matrixSummary);
          }
        }

        return accum;
      }, [])
      .filter(matrix => matrix?.matrix?.length);
  }

  private getMatrixSummariesFromBlockRepeater(
    input: TemplateInput,
    inputInfo: InputSummaryInfo
  ): MatrixSummary[] {
    const content = updateBlockRepeaterFormat(JSON.parse(input.content));
    if (content?.blocks) {
      return content.blocks.map(block => {
        const summary: {
          question?: TemplateInput;
          data?: TemplateInput;
        } = inputInfo.block_repeater_input_suffixes.reduce(
          (accum, inputKey) => {
            let blockInputKey = '';
            const blockData = block.data;
            const inputBlock = Object.keys(blockData).find(key => {
              blockInputKey = Object.keys(blockData[key]).find(elementKey =>
                elementKey.includes(inputKey.key)
              );

              return !!blockInputKey;
            });
            if (inputBlock && blockInputKey) {
              accum[inputKey.input_contain_select || 'data'] =
                blockData[inputBlock][blockInputKey];
            }

            return accum;
          },
          {}
        );

        return {
          question: summary?.question?.content || inputInfo.title || 'Other',
          matrix: summary?.data?.content
            ? JSON.parse(summary.data.content)
            : [] || []
        };
      });
    }

    return null;
  }

  private getMatrixSummaryFromMatrixEntry(
    input: TemplateInput,
    inputInfo: InputSummaryInfo
  ): MatrixSummary {
    return {
      question: inputInfo.title || 'Other',
      matrix: input.content ? JSON.parse(input.content) : [] || []
    };
  }
}
