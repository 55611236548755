import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { E3CommonModule } from '../common/e3-common.module';
import { StandaloneRecorderComponent } from './standalone-recorder/standalone-recorder.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { VideoManagerModalComponent } from './video-manager-modal/video-manager-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { VideoManagerComponent } from './video-manager.component';
import { FormsModule } from '@angular/forms';

const exports = [
  StandaloneRecorderComponent,
  VideoManagerModalComponent,
  VideoManagerComponent
];

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    E3CommonModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatTabsModule,
    MatInputModule,
    FormsModule
  ],
  declarations: exports,
  exports
})
export class VideoManagerModule {}
