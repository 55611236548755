import { Component, forwardRef } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import { AnswersData, InputData, QuizTemplateParams, TQuizResult } from '.';
import txt from '!!raw-loader!./index.ts';
import { TemplateInput } from '../../../../common/interfaces/module.interface';
import { of } from 'rxjs';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio/radio';
import { Validation } from '../../../../common/validator.class';

@Component({
  selector: 'quiz',
  templateUrl: 'quiz.component.html',
  styleUrls: ['./quiz.component.scss'],
  providers: [
    { provide: TemplateComponent, useExisting: forwardRef(() => QuizComponent) }
  ]
})
export class QuizComponent extends TemplateComponent {
  params = txt;
  contentData: QuizTemplateParams;

  inputData: InputData;
  inputSuffix: string;
  input: TemplateInput;
  results: TQuizResult[];
  summaryResult = 0;
  isQuizCompleted = false;
  isUserInputVisible = false;
  userCustomQuestion = '';
  allowToChangeLabels = false;

  private inputValidator: Validation[];

  getDescription() {
    return '';
  }

  getName() {
    return 'Quiz';
  }

  getGroup() {
    return 'Generic';
  }

  init() {
    this.contentData = this.data.data
      .template_params_json as QuizTemplateParams;
    this.inputSuffix = this.contentData.input_sufix;

    this.input = this.getInput('quiz', 1, '', this.inputSuffix);

    this.buildInputData();
    if (!this.contentData.theSameSetOfAnswers) {
      this.results = this.contentData.results.map(result => ({
        text: result.text,
        rangeMin: Number(result.rangeMin),
        rangeMax: Number(result.rangeMax) + 0.99
      }));

      this.contentData.resultsTitle = this.contentData.resultsTitle
        ? this.contentData.resultsTitle
        : '';
      this.countSummary();
    }
    this.setQuizState();
    this.prepareValidator();
    this.allowToChangeLabels = this.contentData.allowToChangeLabels;
  }

  validate() {
    return of(
      this.contentData.theSameSetOfAnswers
        ? this.validateInput(this.input, this.inputValidator)
        : this.isQuizCompleted ||
            this.validateInput(this.input, this.inputValidator)
    );
  }

  inputContent(input: TemplateInput): InputData {
    return JSON.parse(input.content || null);
  }

  selectAnswer(radioGroup: MatRadioGroup, radioButton: MatRadioButton): void {
    if (
      radioGroup.selected !== radioButton &&
      !this.contentData.disallowToChangeAnswers
    ) {
      radioGroup.selected = radioButton;
      radioGroup._emitChangeEvent();
    }
  }

  onChange($event?, id?: number): void {
    if ($event) {
      const answer = this.inputData.questions.find(item => item.id === id)
        .answers[$event.value];
      this.inputData.answers = {
        ...this.inputData.answers,
        [id]: { index: $event.value, value: answer.value ? answer.value : 0 }
      };
      if (!this.isQuizCompleted) {
        this.setQuizState();
      }
    }
    this.input.content = JSON.stringify(this.inputData);
    this.contentChanged(this.input, this.inputValidator);
  }

  onAnswerChange($event?, id?: number): void {
    if ($event) {
      const answer = this.inputData.questions.find(item => item.id === id)
        .answers[$event.value];
      this.inputData.answers = {
        ...this.inputData.answers,
        [id]: { index: $event.value, value: answer.value ? answer.value : 0 }
      };
    }
    this.input.content = JSON.stringify({
      answers: this.inputData.answers,
      questions: this.inputData.questions
    });
    this.contentChanged(this.input, this.inputValidator);
    if ($event) {
      this.countSummary();
      if (!this.isQuizCompleted) {
        this.setQuizState();
      }
    }
  }

  removeRow(id: number): void {
    this.confirmationService.removeDialog().subscribe(() => {
      const idx = this.inputData.questions.findIndex(el => el.id === id);
      this.inputData.questions.splice(idx, 1);
      delete this.inputData.answers[idx + 1];
      this.input.content = JSON.stringify(this.inputData);
      this.contentChanged(this.input, this.inputValidator);
    });
  }

  addNewQuestion(): void {
    if (!this.userCustomQuestion.trim().length) {
      this.snackBarService.error('Please fill in this field');

      return;
    }
    const lastQuestion = this.inputData.questions[
      this.inputData.questions.length - 1
    ];
    const lastId = lastQuestion.id;
    const answers = lastQuestion.answers.slice();
    this.inputData.questions.push({
      id: lastId + 1,
      question: this.userCustomQuestion,
      answers: [...answers],
      isRemovable: true
    });
    this.isUserInputVisible = false;
    this.input.content = JSON.stringify(this.inputData);
    this.contentChanged(this.input, this.inputValidator);
  }

  private buildInputData(): void {
    const content: InputData | AnswersData = JSON.parse(this.input.content);
    if (content) {
      // Negative case is support of old filled data in a simple quiz
      this.inputData =
        'answers' in content
          ? content
          : { questions: [...this.contentData.questions], answers: content };
    } else {
      this.inputData = {
        questions: [...this.contentData.questions],
        answers: {}
      };
    }
  }

  private countSummary(): void {
    this.summaryResult = Object.values(this.inputData.answers).reduce(
      (acc, curr) => acc + +curr.value,
      0
    );
  }

  private setQuizState(): void {
    this.isQuizCompleted =
      this.inputData.answers &&
      Object.keys(this.inputData.answers)?.length ===
        this.inputData.questions?.length;
  }

  private prepareValidator(): void {
    const validFunc = (value: InputData) =>
      value && Object.keys(value?.answers)?.length === value?.questions?.length;
    const errorMessage = 'Please mark all statements above';
    this.inputValidator = [new Validation(validFunc, errorMessage)];
  }
}
