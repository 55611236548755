import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import {
  AllowedDateInputFormat,
  DateFnsDateAdapter
} from '../../utils/date-fns-date-picker';
import { format } from 'date-fns';
import { MatDateFormats } from '@angular/material/core/datetime/date-formats';
import { MatDatepicker } from '@angular/material/datepicker';
import { FormatDatePipe } from '../../pipes/fomartdate.pipe';

@Component({
  selector: 'app-date-picker-input',
  templateUrl: './date-picker-input.component.html',
  styleUrls: ['./date-picker-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: DateAdapter, useClass: DateFnsDateAdapter }]
})
export class DatePickerInputComponent implements OnInit, OnChanges {
  @Input() dateValue: Date | string;
  @Input() minDateValue: Date | string;
  @Input() maxDateValue: Date | string;
  @Input() placeholder: string;
  @Input() startView: 'month' | 'year' | 'multi-year' = 'month';
  @Input() visible = true;
  @Input() disabled = false;
  @Input() showResetIcon = false;
  @Input() isMonthYearPicker = false;
  @Input() size: 'small' | 'medium' = 'medium';
  @Input() dateIconFirst = false;
  @Input() parseDateInputFormat: AllowedDateInputFormat;
  @Output() dateValueChange: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild(MatDatepicker) datepicker: MatDatepicker<Date>;

  @HostBinding('class') get inputSize() {
    return this.size;
  }

  constructor(
    @Inject(MAT_DATE_FORMATS) public dateFormat: MatDateFormats,
    private formatDatePipe: FormatDatePipe
  ) {}

  ngOnInit(): void {
    if (!this.parseDateInputFormat) {
      this.parseDateInputFormat = this.dateFormat.parse.dateInput;
    }
    this.dateValue = this.formatDatePipe.parse(
      this.dateValue,
      this.parseDateInputFormat
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.dateValue = this.formatDatePipe.parse(
      this.dateValue,
      this.parseDateInputFormat
    );
  }

  handleDateChange(date: Date | string): void {
    if (date && date instanceof Date) {
      this.dateValueChange.emit(format(date, this.parseDateInputFormat));
    }
  }

  open(): void {
    this.datepicker.open();
  }

  clearDate(): void {
    this.dateValue = null;
  }

  chosenMonthHandler(monthYearDate: Date): void {
    if (this.isMonthYearPicker) {
      this.handleDateChange(monthYearDate);
      this.datepicker.close();
    }
  }
}
