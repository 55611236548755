import { TemplateContentDataBase } from '../template.interface';
import { MatrixElement } from '../matrix-entry';

export interface CustomerSuccessReviewTemplateData
  extends TemplateContentDataBase {
  template_params_json: {
    description: string;
    title: string;
    print_orientation_select: 'auto' | 'portrait' | 'landscape';
  };
}

export enum SPSections {
  AccountPlanning = 'Account planning',
  AccountDevelopment = 'Account development',
  OpportunityManagement = 'Opportunity management',
  AccountReviews = 'Account reviews'
}

export interface CustomerSuccessSection {
  section: SPSections;
  steps: CustomerSuccessStep[];
}

export interface CustomerSuccessStep {
  name: string;
  info: CustomerSuccessInfo | { rhythmTable: string[][] };
}

export interface CustomerSuccessInfo {
  definition: string[];
  exitCriteria: string;
  buyerPhase: string[];
  buyerQuestions: string[];
  jobAids: string[];
  salesAids: string[];
  referenceMaterials: string[];
  keyActivities: {
    title: string;
    description: string[];
  }[];
}

export type CustomerSuccessInfoKeys = {
  [key in keyof CustomerSuccessInfo]: string;
};
