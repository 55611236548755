import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'htmlBreaklines'
})
export class HtmlBreaklinesPipe implements PipeTransform {
  transform(value: string): string {
    return value.replace(new RegExp('\n', 'g'), '<br/>');
  }
}
