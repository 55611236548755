import { Component, forwardRef } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import { PreRequisiteModuleTemplateData } from '.';
import { map, switchMap, tap } from 'rxjs/operators';
import { combineLatest, Observable, of } from 'rxjs';
import { Module } from 'src/app/common/interfaces/module.interface';
import txt from '!!raw-loader!./index.ts';

@Component({
  selector: 'app-pre-requisite-modules',
  templateUrl: './pre-requisite-modules.component.html',
  styleUrls: ['./pre-requisite-modules.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => PreRequisiteModulesComponent)
    }
  ]
})
export class PreRequisiteModulesComponent extends TemplateComponent {
  params = txt;
  contentData: PreRequisiteModuleTemplateData['template_params_json'];

  combineData$: Observable<[Module[], Module[]]>;

  hasError = false;

  init() {
    this.contentData = this.data.data
      .template_params_json as PreRequisiteModuleTemplateData['template_params_json'];

    const moduleIDs = this.contentData.modules.map(module =>
      Number(module.module)
    );
    const recommendedModulesIDs = this.contentData.recommendedModules?.map(
      module => Number(module.module)
    );

    this.combineData$ = combineLatest([
      this.getModules(moduleIDs),
      recommendedModulesIDs ? this.getModules(recommendedModulesIDs) : of([])
    ]);
  }

  getModules(moduleIDs: number[]): Observable<Module[]> {
    return this.navService.organization$.pipe(
      switchMap(orgId =>
        this.moduleService
          .getCategories(orgId)
          .pipe(
            map(categories =>
              categories.body.reduce(
                (res, category) =>
                  res.concat(
                    category.modules.filter(module =>
                      moduleIDs.includes(module.id)
                    )
                  ),
                []
              )
            )
          )
      )
    );
  }

  getDescription() {
    return 'Require completion of certain modules before allowing access to the next module';
  }

  getName() {
    return 'Pre-requisite Modules';
  }

  getGroup(): string {
    return 'Generic';
  }

  hasInputs() {
    return false;
  }

  validate() {
    this.hasError = false;

    return this.combineData$.pipe(
      map(modules =>
        modules[0].every(
          module => module.status && module.status.progress === 100
        )
      ),
      tap(isValid => (this.hasError = !isValid))
    );
  }
}
