import { Component, Inject } from '@angular/core';
import { VideoData, VideoModalInfo } from '../index';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TemplateInput } from '../../common/interfaces/module.interface';
import { FileData } from '../../module-viewer/riverside-step-template/templates/file-uploader-template';

@Component({
  selector: 'app-video-manager-modal',
  templateUrl: './video-manager-modal.component.html',
  styleUrls: ['./video-manager-modal.component.scss']
})
export class VideoManagerModalComponent {
  videoData: VideoData;
  input: TemplateInput;
  readonly = false;
  srcURL: string;

  constructor(
    private modal: MatDialogRef<VideoManagerModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: VideoModalInfo
  ) {
    this.videoData = data.videoData;
    this.readonly = data.readonly;
    this.input = data.input;
    this.srcURL = data.srcURL;

    this.modal.disableClose = true;
    this.modal
      .backdropClick()
      .subscribe(() => this.modal.close(this.videoData));
  }

  saveFileInputData(fileData: FileData) {
    this.videoData = {
      recordedVideoUrl: undefined,
      comment: this.videoData.comment,
      uploadedVideoData: fileData
    };
  }

  saveRecordedVideoData(recordVideoUrl: string): void {
    this.videoData = {
      recordedVideoUrl: recordVideoUrl,
      comment: this.videoData.comment,
      uploadedVideoData: undefined
    };
  }
}
