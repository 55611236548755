import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { publishReplay, refCount, take } from 'rxjs/operators';

export const MAX_CACHE_AGE = 5000; // in milliseconds

@Injectable({
  providedIn: 'root'
})
export class RestService {
  private requestCache = new Map<string, Observable<any>>();

  constructor(private http: HttpClient) {}

  get<T = any>(
    url,
    options?,
    isRequestCacheable = false,
    cacheTime = MAX_CACHE_AGE
  ): Observable<T> {
    if (!isRequestCacheable) {
      return this.sendRequest('GET', url, options);
    }
    if (!this.requestCache.has(url)) {
      const request = this.sendRequest('GET', url, options).pipe(
        publishReplay(1, cacheTime === null ? undefined : cacheTime),
        refCount(),
        take(1)
      );
      this.requestCache.set(url, request);
    }

    return this.requestCache.get(url);
  }

  private sendRequest(method, url, options?): Observable<any> {
    return this.http.request(method, url, options);
  }
}
