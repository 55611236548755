import { Pipe, PipeTransform } from '@angular/core';
import { purifyNumberString } from '../utils/helpers';

export type NumberType = 'whole' | 'integer' | 'decimal';

@Pipe({
  name: 'customNumber'
})
export class CustomNumberPipe implements PipeTransform {
  transform(
    value: string | number,
    numberType: NumberType = 'decimal',
    positiveOnly = false
  ): string {
    if (value === null || value === undefined) {
      return null;
    } else {
      let pureNumberString = purifyNumberString(String(value));
      if (positiveOnly) {
        pureNumberString = pureNumberString.replace('-', '');
      }
      switch (numberType) {
        case 'decimal':
          return pureNumberString;
        case 'integer': {
          const integerStringMatch = pureNumberString?.match(/(-?\d+).?\d*/);

          return integerStringMatch ? integerStringMatch[1] : null;
        }
        case 'whole': {
          const wholeNumberStringMatch = pureNumberString?.match(
            /-*(\d+).?\d*/
          );

          return wholeNumberStringMatch ? wholeNumberStringMatch[1] : null;
        }
      }
    }
  }

  parse(
    value: string | number,
    numberType: NumberType = 'decimal',
    positiveOnly = false
  ): number {
    let valueString = value ? purifyNumberString(String(value)) : null;
    const isBadNumber = !valueString || isNaN(Number(valueString));
    if (isBadNumber) {
      return null;
    } else {
      if (positiveOnly) {
        valueString = valueString.replace('-', '');
      }
      switch (numberType) {
        case 'decimal':
          return Number(valueString);
        case 'integer': {
          const integerStringMatch = valueString?.match(/(-?\d+).?\d*/);

          return integerStringMatch ? Number(integerStringMatch[1]) : null;
        }
        case 'whole': {
          const wholeNumberStringMatch = valueString?.match(/-*(\d+).?\d*/);

          return wholeNumberStringMatch
            ? Number(wholeNumberStringMatch[1])
            : null;
        }
      }
    }
  }
}
