import {
  AfterContentInit,
  Component,
  ContentChildren,
  OnDestroy,
  QueryList
} from '@angular/core';
import { TabsItemComponent } from '../tabs-item-component/tabs-item.component';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-tabs-component',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements AfterContentInit, OnDestroy {
  @ContentChildren(TabsItemComponent) tabs: QueryList<TabsItemComponent>;

  tabItems: TabsItemComponent[];
  activeTabIndex = 0;
  private componentDestroy$: Subject<void> = new Subject();

  constructor() {}

  ngAfterContentInit(): void {
    this.tabs.changes
      .pipe(
        takeUntil(this.componentDestroy$),
        startWith<any, undefined>(undefined),
        map(() => this.tabs.toArray())
      )
      .subscribe((tabs: TabsItemComponent[]) => (this.tabItems = tabs));
  }

  ngOnDestroy(): void {
    this.componentDestroy$.next();
    this.componentDestroy$.complete();
  }

  selectTab(tabIndex: number): void {
    const tabItem: TabsItemComponent = this.tabItems[tabIndex];
    const activeTab: TabsItemComponent = this.tabItems[this.activeTabIndex];
    if (this.activeTabIndex === tabIndex) {
      return;
    }
    if (activeTab) {
      activeTab.isActive = false;
    }
    this.activeTabIndex = tabIndex;
    tabItem.isActive = true;
  }
}
