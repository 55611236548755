import {
  Component,
  ElementRef,
  forwardRef,
  QueryList,
  ViewChildren
} from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import {
  RaciErrors,
  RaciInputContent,
  RACITemplateParams,
  RowItem,
  Task
} from '.';
import txt from '!!raw-loader!./index.ts';
import { TemplateInput } from '../../../../common/interfaces/module.interface';
import { Validation } from '../../../../common/validator.class';
import { of } from 'rxjs';

@Component({
  selector: 'raci',
  templateUrl: 'raci.component.html',
  styleUrls: ['./raci.component.scss'],
  providers: [
    { provide: TemplateComponent, useExisting: forwardRef(() => RACIComponent) }
  ]
})
export class RACIComponent extends TemplateComponent {
  @ViewChildren('columnInputs') columnInputs: QueryList<ElementRef>;
  @ViewChildren('taskInputs') taskInputs: QueryList<ElementRef>;

  params = txt;
  contentData: RACITemplateParams;
  prefix = 'raci_1';
  inputKey: string;

  readonly RACIValues = ['R', 'A', 'C', 'I'];
  readonly staticTableRows: RowItem[] = [
    {
      id: 0,
      title: 'Profiles',
      tasks: [
        {
          id: 0,
          title: 'Identify Competencies',
          choices: []
        },
        {
          id: 1,
          title: 'Create Long-Term Scorecard Based on KPIs',
          choices: []
        },
        {
          id: 2,
          title: 'Determine Accountabilities (KPIs)',
          choices: []
        },
        {
          id: 3,
          title: 'Create Long-Term Scorecard Based on KPIs',
          choices: []
        },
        {
          id: 4,
          title: 'Complete/Update Job Description',
          choices: []
        }
      ]
    },
    {
      id: 1,
      title: 'Assessments',
      tasks: [
        {
          id: 0,
          title: 'Acquire an Online Assessment for Ongoing Use',
          choices: []
        },
        {
          id: 1,
          title:
            'Administer Assessments Regularly & Perform a Strengths & Weaknesses Gaps Analysis',
          choices: []
        }
      ]
    },
    {
      id: 2,
      title: 'Sourcing',
      tasks: [
        {
          id: 0,
          title:
            'Determine Where and How to Source Candidates (Recruiter, Referrals, etc.)',
          choices: []
        },
        {
          id: 1,
          title: 'Build a Virtual Bench of Candidates',
          choices: []
        },
        {
          id: 2,
          title:
            'Track Recruiting Metrics (Time to Fill Position, Ramp to Productivity, etc.)',
          choices: []
        }
      ]
    },
    {
      id: 3,
      title: 'Hiring',
      tasks: [
        {
          id: 0,
          title:
            'Conduct Pre-Search Meeting to Determine Hiring Steps & Hiring Scorecard',
          choices: []
        },
        {
          id: 1,
          title: 'Conduct Screening/Work History Interview',
          choices: []
        },
        {
          id: 2,
          title: 'Schedule a Hiring Team Meeting to Determine Needs',
          choices: []
        },
        {
          id: 3,
          title: 'Check References',
          choices: []
        },
        {
          id: 4,
          title: 'Administer Assessment',
          choices: []
        },
        {
          id: 5,
          title: 'Schedule In-Person Interviews',
          choices: []
        },
        {
          id: 6,
          title: 'Initiate Job Trial',
          choices: []
        },
        {
          id: 7,
          title:
            'Collect & Distribute Scorecard & Reference Check Notes to Hiring Team Ahead of Final Offer Meeting',
          choices: []
        },
        {
          id: 8,
          title: 'Prepare & Send Offer Letter',
          choices: []
        }
      ]
    },
    {
      id: 4,
      title: 'Onboarding',
      tasks: [
        {
          id: 0,
          title: 'Go Through Onboarding Checklist',
          choices: []
        }
      ]
    },
    {
      id: 5,
      title: 'Talent Development',
      tasks: [
        {
          id: 0,
          title: 'Create an Individualized Development Plan',
          choices: []
        },
        {
          id: 1,
          title: 'Assign a Mentor',
          choices: []
        },
        {
          id: 2,
          title: 'Generate Employee Scorecard to Track KPIs',
          choices: []
        }
      ]
    },
    {
      id: 6,
      title: 'Succession Planning',
      tasks: [
        {
          id: 0,
          title:
            'Map Out a Succession Plan Every 6 Months (Use Scorecards, IDPs, & Assessments)',
          choices: []
        }
      ]
    }
  ];
  readonly existingJobs = [
    'Recruiter/HR',
    'VP Sales',
    'CEO',
    'Dir. of Sales Excellence'
  ];

  raciInput: TemplateInput;
  raciContent: RaciInputContent;
  tableRows: RowItem[] = [];
  isReadOnly = false;

  validators: Validation[];
  validationRaciErrors: RaciErrors;

  getDescription() {
    return '';
  }

  getName() {
    return 'RACI';
  }

  getGroup(): string {
    return 'Module';
  }

  getInput(): TemplateInput {
    return this.inputs[this.inputKey];
  }

  init() {
    this.contentData = this.data.data
      .template_params_json as RACITemplateParams;
    this.inputKey = this.contentData.input_sufix
      ? `${this.prefix}_${this.contentData.input_sufix}`
      : this.prefix;
    this.raciInput = this.getInput();
    this.isReadOnly = this.disabled || this.contentData.read_only;

    if (this.raciInput.content) {
      this.convertOldFormatContentToNew();
      this.raciContent = JSON.parse(this.raciInput.content);
    } else {
      if (this.contentData.dynamic_table) {
        this.prepareDefaultDataForDynamicTable();
      } else {
        this.raciContent = {
          rows: [],
          columns: this.existingJobs.map((item, index) => ({
            id: index,
            title: item
          }))
        };
      }
    }

    this.prepareTableRows();
    this.updateTableRows();
    this.validators = this.getValidators();
  }

  validate() {
    return of(this.validateInput(this.raciInput, this.validators));
  }

  validateInput(inp: TemplateInput, validators: Validation[] = []): boolean {
    this.prepareValidationRaciErrors();

    return super.validateInput(inp, validators);
  }

  saveRACIInput(): void {
    const newContent = JSON.stringify(this.raciContent);
    if (this.raciInput.content !== newContent) {
      this.raciInput.content = newContent;
      this.contentChanged(this.raciInput, this.validators);
    }
  }

  toggleRACITask(
    row: RowItem,
    task: Task,
    buttonColumn: number,
    choice: string
  ): void {
    if (this.isReadOnly) {
      return;
    }
    const selectedRowItem = this.raciContent.rows.find(r => r.id === row.id);
    const c = [];
    c[buttonColumn] = [choice];

    if (selectedRowItem) {
      const checkTask = selectedRowItem.tasks.find(t => t.id === task.id);

      if (checkTask) {
        if (checkTask.choices[buttonColumn]) {
          const itemIndex = checkTask.choices[buttonColumn].indexOf(choice);

          if (itemIndex !== -1) {
            checkTask.choices[buttonColumn].splice(itemIndex, 1);
          } else {
            checkTask.choices[buttonColumn].push(choice);
          }
        } else {
          checkTask.choices[buttonColumn] = [choice];
        }
      } else {
        selectedRowItem.tasks.push({
          id: task.id,
          title: task.title,
          choices: c
        });
      }
    } else {
      this.raciContent.rows.push({
        title: row.title,
        id: row.id,
        tasks: [
          {
            id: task.id,
            title: task.title,
            choices: c
          }
        ]
      });
    }
    this.saveRACIInput();
    this.raciContent = JSON.parse(this.raciInput.content);
    this.updateTableRows();
  }

  addColumn(): void {
    const lastColumn =
      this.raciContent.columns?.length &&
      this.raciContent.columns[this.raciContent.columns.length - 1];
    if (this.raciContent.columns?.length === 0 || !!lastColumn?.title) {
      this.raciContent.columns.push({
        id: (lastColumn?.id || 0) + 1,
        title: ''
      });
      this.updateTableRows();
      this.saveRACIInput();
      setTimeout(() => {
        if (this.columnInputs?.last?.nativeElement) {
          this.columnInputs.last.nativeElement.focus();
        }
      });
    } else {
      this.snackBarService.info(
        'Please fill in the title of the previous column',
        2000
      );
    }
  }

  addTask(): void {
    const lastTask: Task =
      this.raciContent?.rows[0]?.tasks &&
      this.raciContent.rows[0].tasks[this.raciContent.rows[0].tasks.length - 1];
    if (this.raciContent?.rows[0]?.tasks.length === 0 || !!lastTask?.title) {
      const newTaskId: number = (lastTask?.id || 0) + 1;
      const newTask: Task = {
        id: newTaskId,
        title: '',
        choices: []
      };
      this.raciContent.rows = this.raciContent.rows.map(row => ({
        ...row,
        tasks: [...row.tasks, { ...newTask }]
      }));
      this.tableRows = this.tableRows.map(row => ({
        ...row,
        tasks: [...row.tasks, { ...newTask }]
      }));
      this.updateTableRows();
      this.saveRACIInput();
      setTimeout(() => {
        if (this.taskInputs?.last?.nativeElement) {
          this.taskInputs.last.nativeElement.focus();
        }
      });
    } else {
      this.snackBarService.info(
        'Please fill in the title of the previous task (row)',
        2000
      );
    }
  }

  deleteColumn(columnIndex: number): void {
    this.confirmationService
      .removeDialog({
        text: `column ${this.raciContent.columns[columnIndex].title}`
      })
      .subscribe(() => {
        this.raciContent.columns.splice(columnIndex, 1);
        this.raciContent.rows.forEach(row =>
          row.tasks.forEach(task => {
            task.choices = task.choices.filter(
              (choice, choiceColIndex) => choiceColIndex !== columnIndex
            );
          })
        );
        this.tableRows = this.getTableRowsFromInputContent();
        this.saveRACIInput();
      });
  }

  deleteTask(task: Task): void {
    this.confirmationService
      .removeDialog({ text: `task ${task.title}` })
      .subscribe(() => {
        this.raciContent.rows.forEach(row => {
          row.tasks = row.tasks.filter(item => item.id !== task.id);
        });
        this.tableRows = this.getTableRowsFromInputContent();
        this.saveRACIInput();
      });
  }

  onTaskNameChange(taskId: number, changedTitle: string): void {
    this.raciContent.rows.forEach(row => {
      const changedTask = row.tasks.find(task => task.id === taskId);
      if (changedTask) {
        changedTask.title = changedTitle;
      }
    });
    this.saveRACIInput();
  }

  private getTableRowsFromInputContent(): RowItem[] {
    return this.raciContent.rows.map(row => ({
      ...row,
      tasks:
        row.tasks?.map(task => ({
          ...task,
          choices: task.choices?.map((stringChoices: string[]) =>
            this.RACIValues.map((raciValue: string) =>
              Boolean(stringChoices?.includes(raciValue))
            )
          )
        })) || []
    }));
  }

  private prepareTableRows(): void {
    if (this.contentData.dynamic_table) {
      if (this.raciContent) {
        this.tableRows = this.getTableRowsFromInputContent();
      }
    } else {
      this.tableRows = this.staticTableRows;
    }
  }

  private updateTableRows(): void {
    this.tableRows.map((row, rowIndex) => {
      row.tasks.map((task, taskIndex) => {
        this.raciContent.columns.map((column, columnIndex) => {
          this.tableRows[rowIndex].tasks[taskIndex].choices[columnIndex] = [];
          this.RACIValues.map((raci, raciIndex) => {
            const res = this.isTaskHaveChoice(
              row.id,
              task.id,
              columnIndex,
              raci
            );
            if (
              this.tableRows[rowIndex].tasks[taskIndex].choices[columnIndex]
            ) {
              this.tableRows[rowIndex].tasks[taskIndex].choices[
                columnIndex
              ].push(res);
            } else {
              this.tableRows[rowIndex].tasks[taskIndex].choices[columnIndex] = [
                res
              ];
            }
          });
          if (
            !this.tableRows[rowIndex].tasks[taskIndex].choices[
              columnIndex
            ].find(item => item === true)
          ) {
            this.tableRows[rowIndex].tasks[taskIndex].choices[
              columnIndex
            ] = null;
          }
        });
      });
    });
  }

  private isTaskHaveChoice(
    rowID: number,
    taskID: number,
    buttonColumn: number,
    choice: string
  ): boolean {
    const row: RowItem = this.raciContent.rows.find(r => r.id === rowID);

    if (row) {
      const task: Task = row.tasks.find(t => t.id === taskID);

      if (!choice) {
        return task && task.choices[buttonColumn]
          ? task.choices[buttonColumn].length > 0
          : false;
      }

      if (task) {
        return task.choices[buttonColumn]
          ? task.choices[buttonColumn].indexOf(choice) !== -1
          : false;
      }
    }

    return false;
  }

  private convertOldFormatContentToNew(): void {
    const content: RaciInputContent | RowItem[] = JSON.parse(
      this.raciInput.content
    );
    if (Array.isArray(content)) {
      this.raciContent = {
        rows: content,
        columns: this.existingJobs.map((item, index) => ({
          id: index,
          title: item
        }))
      };
      this.saveRACIInput();
    }
  }

  private prepareDefaultDataForDynamicTable(): void {
    const projects: string[] = (this.contentData.projects || [])
      .map(col => col?.title)
      .filter(Boolean);
    const defaultCols = (this.contentData.default_cols || [])
      .filter(item => !!item?.title)
      .map((col, index) => ({ id: index, title: col.title }));
    if (
      this.contentData.number_of_predefined_cols &&
      defaultCols.length < this.contentData.number_of_predefined_cols
    ) {
      const leftToFill =
        this.contentData.number_of_predefined_cols - defaultCols.length;
      const startIndex = defaultCols.length;
      const blankCols = new Array(leftToFill)
        .fill('')
        .map((item: string, index) => ({
          id: index + startIndex,
          title: item
        }));
      defaultCols.push(...blankCols);
    }
    const defaultRows: string[] = (this.contentData.default_rows || [])
      .map(row => row.title)
      .filter(Boolean);
    if (
      this.contentData.number_of_predefined_rows &&
      defaultRows.length < this.contentData.number_of_predefined_rows
    ) {
      const leftToFill =
        this.contentData.number_of_predefined_rows - defaultRows.length;
      const blankRows: string[] = new Array(leftToFill).fill('');
      defaultRows.push(...blankRows);
    }
    const rows: RowItem[] = projects.map((projectName, index) => ({
      id: index,
      title: projectName,
      tasks: defaultRows.map((rowName, rowIndex) => ({
        id: rowIndex,
        title: rowName,
        choices: []
      }))
    }));
    if (projects.length) {
      this.raciContent = { rows, columns: [...defaultCols] };
    }
  }

  private getValidators(): Validation[] {
    if (this.isReadOnly) {
      return null;
    }
    if (this.contentData.dynamic_table) {
      const filledTitleMessage =
        'Please fill in all titles for rows and columns in the table above';
      const filledTitleFunc = (value: string) => {
        const content: RaciInputContent = JSON.parse(value || null);
        const isColumnTitlesFilled =
          !content?.columns?.length ||
          content.columns.every(column => !!column.title);
        const isTaskTitlesFilled =
          !content?.rows?.[0]?.tasks?.length ||
          content.rows[0].tasks.every(task => !!task.title);

        return isColumnTitlesFilled && isTaskTitlesFilled;
      };
      const filledTitleValidation = new Validation(
        filledTitleFunc,
        filledTitleMessage
      );

      return [filledTitleValidation];
    }

    return null;
  }

  private prepareValidationRaciErrors(): void {
    if (this.isReadOnly) {
      return null;
    }
    if (this.contentData.dynamic_table) {
      this.validationRaciErrors = {
        columns: this.raciContent?.columns?.map(column => ({
          isTitleInvalid: !column.title
        })),
        tasks: this.raciContent?.rows?.[0]?.tasks?.map(task => ({
          isTitleInvalid: !task.title
        }))
      };
    }
  }
}
