import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface SurveyModalOptions {
  moduleName: string;
  companyName: string;
  userName: string;
}

const surveyParamKeys: SurveyModalOptions = {
  moduleName: 'Module',
  companyName: 'Company',
  userName: 'User'
};

@Component({
  selector: 'app-survey-modal',
  templateUrl: './survey-modal.component.html',
  styleUrls: ['./survey-modal.component.scss']
})
export class SurveyModalComponent {
  surveySrc = 'https://byu.az1.qualtrics.com/jfe/form/SV_43k6l8nqyBTxFRP';

  constructor(
    public modal: MatDialogRef<SurveyModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: SurveyModalOptions
  ) {
    if (this.data) {
      const params: string = Object.keys(this.data)
        .reduce((accum: string[], key) => {
          if (surveyParamKeys[key]) {
            accum.push(`${surveyParamKeys[key]}=${this.data[key]}`);
          }

          return accum;
        }, [])
        .join('&');
      if (params) {
        this.surveySrc = encodeURI(`${this.surveySrc}?${params}`);
      }
    }
  }
}
