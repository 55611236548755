import { Component, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-tabs-body-component',
  templateUrl: './tabs-body.component.html',
  styleUrls: ['./tabs-body.component.scss']
})
export class TabsBodyComponent {
  @ViewChild(TemplateRef) bodyContent: TemplateRef<any>;

  constructor() {}
}
