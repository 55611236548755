import { ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModuleBuilderRootComponent } from './module-builder-root.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MainComponent } from './main/main.component';
import { AppRoutingModule } from './module-builder-routing.module';
import { ModuleEditorComponent } from './module-editor/module-editor.component';
import { RouterModule } from '@angular/router';
import { ModuleSelectorComponent } from './module-selector/module-selector.component';
import { ModuleService } from '../common/services/module.service';
import { TemplateService } from '../common/services/template.service';
import { E3CommonModule } from '../common/e3-common.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { StepTemplateEditorComponent } from './module-editor/step-template-editor/step-template-editor.component';
import { StepLinkEditorComponent } from './module-editor/step-link-editor/step-link-editor.component';
import { StepTemplateFieldComponent } from './module-editor/step-template-editor/step-template-field/step-template-field.component';
import { MatIconModule } from '@angular/material/icon';
import { BuilderSelectOptionPipe } from './module-editor/builder-select-option.pipe';
import { TabNamePipe } from './module-editor/step-template-editor/tab-name.pipe';
import { HotTableModule } from '../../lib/angular-handsontable-3.0.0-ce/public_api';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { CKEditorModule } from 'ckeditor4-angular';
import { RavenExceptionHandler } from '../common/services/raven.service';
import { MatDialogModule } from '@angular/material/dialog';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatCardModule } from '@angular/material/card';
import { StepSearchComponent } from './step-search/step-search.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot([], { useHash: true, enableTracing: true }),
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule,
    AppRoutingModule,
    E3CommonModule,
    DragDropModule,
    CKEditorModule,
    MatIconModule,
    HotTableModule.forRoot(),
    MatCheckboxModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatCheckboxModule,
    MatDialogModule,
    FontAwesomeModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatTabsModule,
    MatTooltipModule
  ],
  declarations: [
    ModuleBuilderRootComponent,
    ModuleEditorComponent,
    MainComponent,
    ModuleSelectorComponent,
    StepTemplateEditorComponent,
    StepLinkEditorComponent,
    StepTemplateFieldComponent,
    BuilderSelectOptionPipe,
    StepSearchComponent,
    TabNamePipe
  ],
  providers: [
    ModuleService,
    TemplateService,
    { provide: ErrorHandler, useClass: RavenExceptionHandler }
  ],
  bootstrap: [ModuleBuilderRootComponent]
})
export class ModuleBuilderModule {}
