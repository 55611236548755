import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  OnDestroy,
  OnInit
} from '@angular/core';
import { Question } from '../index';
import { TemplateInput } from '../../../../../common/interfaces/module.interface';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatSelectChange } from '@angular/material/select';
import * as HotFormula from 'hot-formula-parser/dist/formula-parser.min.js';
import { ModuleService } from 'src/app/common/services/module.service';
import { debounceTime, switchMap, takeWhile } from 'rxjs/operators';
import { ModuleNavService } from '../../../../../common/services/module-nav.service';
import { SnackBarService } from '../../../../../common/services/snackbar.service';
import { sanitizeHtml } from '../../../../../common/utils/html-helpers';
import { DateAdapter } from '@angular/material/core';
import { DateFnsDateAdapter } from '../../../../../common/utils/date-fns-date-picker';
import { VideoData } from '../../../../../video-manager';

@Component({
  selector: 'form-builder-field',
  templateUrl: './form-builder-field.component.html',
  styleUrls: ['./form-builder-field.component.scss'],
  providers: [{ provide: DateAdapter, useClass: DateFnsDateAdapter }]
})
export class FormBuilderFieldComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() question: Question;
  @Input() input: TemplateInput;
  @Input() i: number;
  @Input() canModify: boolean;
  @Input() isInline: boolean;
  @Input() hint: string;
  @Input() formulaParams: number[] = [];
  @Input() appearance: 'standard' | 'outline';

  @Output() valueChanged = new EventEmitter<{
    index: number;
    event: Event | MatDatepickerInputEvent<unknown> | MatSelectChange | string;
  }>();

  @ViewChild('invisibleText') invisibleText: ElementRef;

  formulaValue = '';
  minWidth = 70;
  width: number;
  options: string[] = [];
  instanceExists = true;
  todayDate: Date;
  videoData: VideoData | string;

  private formulaParser = new HotFormula.Parser();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private moduleService: ModuleService,
    private navService: ModuleNavService,
    private snackBarService: SnackBarService
  ) {}

  ngOnInit(): void {
    if (this.question.type_select === 'Video_recorder') {
      this.prepareVideoData();
    }
  }

  ngAfterViewInit(): void {
    this.todayDate = new Date();
    if (this.isInline) {
      if (this.question.type_select === 'Numbers') {
        this.invisibleText.nativeElement.innerHTML = isNaN(
          Number(this.input.content)
        )
          ? ''
          : sanitizeHtml(this.input.content);
      } else {
        this.invisibleText.nativeElement.innerHTML = sanitizeHtml(
          this.input.content
        );
      }
      this.width = this.calculateInputWidth();
      this.changeDetectorRef.detectChanges();
    }
    if (this.question.type_select === 'Select') {
      if (this.question.api_as_data_source) {
        this.prepareSelectOptionsFromApi();
      } else {
        this.options = this.parseOption(this.question.select_options);
      }
      this.changeDetectorRef.detectChanges();
    }
    if (this.question.type_select === 'Formula') {
      this.prepareFormulaValue();

      this.moduleService.stepInputChanged$
        .pipe(
          takeWhile(() => this.instanceExists),
          debounceTime(200)
        )
        .subscribe(() => this.prepareFormulaValue());
    }
  }

  ngOnDestroy(): void {
    this.instanceExists = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.question.type_select === 'Formula' && changes.formulaValues) {
      this.prepareFormulaValue();
    }
  }

  parseOption(options: string): string[] {
    return options
      .replace(/<[^>]*>/g, '')
      .split('\n')
      .filter(el => el.length);
  }

  validateValue($event): boolean {
    let isValid = true;
    if ($event.target && this.question.type_select === 'Numbers') {
      if (!$event.target.value) {
        $event.target.value = 0;
      }

      isValid = this.validateNumber($event.target.value);
    }

    return isValid;
  }

  submitVideo(videoData: VideoData): void {
    const newContentString = JSON.stringify(videoData);
    this.updateData(this.i, newContentString);
  }

  validateNumber(value: number): boolean {
    let isValid =
      this.question.minValue !== undefined
        ? Number(value) >= this.question.minValue
        : true;

    if (!isValid) {
      this.snackBarService.error(
        `Value should be at least ${this.question.minValue}`
      );
    }

    if (
      this.question.maxValue !== undefined &&
      this.question.maxValue < Number(value)
    ) {
      isValid = false;
      this.snackBarService.error(
        `Value should be no more than ${this.question.maxValue}`
      );
    }

    return isValid;
  }

  updateData(
    index: number,
    $event:
      | Event
      | MatDatepickerInputEvent<unknown>
      | MatSelectChange
      | string
      | number
  ): void {
    if (this.validateValue($event)) {
      this.valueChanged.emit({
        index,
        event: typeof $event === 'number' ? String($event) : $event
      });
    }
  }

  resizeInput($event) {
    this.invisibleText.nativeElement.innerHTML = sanitizeHtml(
      $event.target.value
    );

    setTimeout(() => {
      this.width = this.calculateInputWidth();
    }, 0);
  }

  calculateInputWidth(): number {
    return this.invisibleText.nativeElement.offsetWidth + 7 > this.minWidth
      ? this.invisibleText.nativeElement.offsetWidth + 17
      : this.minWidth;
  }

  private prepareFormulaValue(): void {
    const parserResult = this.formulaParser.parse(
      this.parseFormulaString(this.question.excel_formula)
    );
    const newResult = parserResult.result?.toFixed(
      this.question.formula_value_precision
    );
    if (!parserResult.error && newResult && this.formulaValue !== newResult) {
      this.formulaValue = newResult;
      this.updateData(this.i, this.formulaValue);
      this.changeDetectorRef.detectChanges();
    }
  }

  private parseFormulaString(formulaString: string): string {
    const indexReplacer = (match, valueIndex) =>
      String(this.formulaParams[valueIndex] || 0);

    return formulaString.replace(/{(\d+)}/g, indexReplacer);
  }

  private prepareSelectOptionsFromApi(): void {
    this.navService.organization$
      .pipe(
        switchMap(orgId =>
          this.moduleService.getAPIData(orgId, this.question.api_endpoint)
        )
      )
      .subscribe(response => {
        if (response && Array.isArray(response)) {
          this.options = response
            .map(item =>
              this.question.api_fields_to_use.map(field =>
                this.textContent(item[field.field_name])
              )
            )
            .flat();
        }
      });
  }

  private textContent(el: string): string {
    const parser = new DOMParser().parseFromString(el || '', 'text/html');
    parser.querySelectorAll('.del').forEach(deleted => deleted.remove());

    return parser.body.textContent.trim().replace(/\s/g, ' ');
  }

  private prepareVideoData(): void {
    try {
      this.videoData = JSON.parse(this.input?.content || null);
    } catch (e) {
      this.videoData = this.input?.content;
    }
  }
}
