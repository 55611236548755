export const fieldTypes = [
  'id',
  'title',
  'title_from_input',
  'linked_step_suffix',
  'sufix',
  'input_sufix',
  'input_to_expand',
  'from_input',
  'key',
  'block_repeater_input_key',
  'question',
  'option',
  'behavior',
  'image',
  // 'value',
  'fileExtensions',
  'input_id',
  'add_item_action_label',
  'load_action_label',
  'display_input_inline',
  'type_select',
  'source_data_pattern_sub_matrix_entry_only',
  'source_data_key_sub_list_entry_only',
  'placeholder',
  'question_label',
  'class_name_prefix',
  'buttonLabel',
  'table_title',
  'table_subtitle',
  'footer_row_label',
  'radio_group',
  'radio_value',
  'api_endpoint',
  'api_endpoint_data_field',
  'field_name',
  'excel_formula',
  'section_title'
];
