import { Component, forwardRef, ViewChild } from '@angular/core';
import { TemplateComponent } from '../../template-base.class';
import { BlockRepeaterWrapperTemplateData } from './index';
import txt from '!!raw-loader!./index.ts';
import { RTemplateDirective } from '../../../riverside-step-template-host.directive';
import ModuleContent from '../../../../../common/interfaces/module-content.model';
import { TemplateContentData } from '../../template-data.class';
import { BlockRepeaterWrapperComponentTemplate } from '../index';
import { forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BuyerPersona } from '../../../../../common/interfaces/buyer-persona.interface';

@Component({
  selector: 'block-repeater-wrapper',
  templateUrl: 'block-repeater-wrapper.component.html',
  styleUrls: ['./block-repeater-wrapper.component.scss'],
  providers: [
    {
      provide: BlockRepeaterWrapperComponentTemplate,
      useExisting: forwardRef(() => BlockRepeaterWrapperComponent)
    }
  ]
})
export class BlockRepeaterWrapperComponent extends TemplateComponent {
  @ViewChild(RTemplateDirective, { static: true })
  templateHost: RTemplateDirective;

  params = txt;
  contentData: BlockRepeaterWrapperTemplateData['template_params_json'];

  removeBlock?: any;
  templateInstances: TemplateComponent[] = [];
  buyerPersona: BuyerPersona;
  showBuyerPersona = false;
  blockRepeaterModules: ModuleContent[];
  wrapperContent: string;
  tabName: string;
  showTabName = false;
  instanceValidationErrors: boolean[] = [];
  haveValidationErrors = false;

  get input() {
    return this.getInput('block_repeater_wrapper');
  }

  getDescription() {
    return '';
  }

  getName() {
    return 'Block Repeater Wrapper';
  }

  getGroup() {
    return 'Module';
  }

  init() {
    super.init();
    this.contentData = this.data.data
      .template_params_json as BlockRepeaterWrapperTemplateData['template_params_json'];

    if (this.blockRepeaterModules) {
      this.initTemplates(this.blockRepeaterModules);
    }
  }

  validate() {
    return forkJoin(
      this.templateInstances.map(template => template.validate())
    ).pipe(
      map(validations => {
        this.instanceValidationErrors = validations.map(valid => !valid);

        return validations.every(Boolean);
      }),
      tap(isValid => (this.haveValidationErrors = !isValid))
    );
  }

  removeSteps(): void {
    this.confirmationService
      .removeDialog({
        text: 'this block'
      })
      .subscribe(() => {
        this.removeBlock();
      });
  }

  initTemplates(modules: ModuleContent[]): void {
    const viewContainerRef = this.templateHost.viewContainerRef;
    modules?.forEach((module, moduleIndex) => {
      const componentFactory = this.componentFactoryResolver?.resolveComponentFactory(
        this.templateService.templates[module.template_component]
      );
      const componentRef = viewContainerRef.createComponent(componentFactory);
      const templateInstance = componentRef.instance as TemplateComponent;
      module.disabled = this.data.data.disabled;
      module.hidden = this.data.data.hidden;

      templateInstance.data = new TemplateContentData({
        data: module,
        me: this.me,
        canModify: module.can_modify
      });

      const descr = module.template_params_json.description;
      if (descr) {
        const descrDiv = document.createElement('div');
        descrDiv.className = 'composable-description';
        descrDiv.innerHTML = descr;
        const el = componentRef.location.nativeElement;
        el.insertBefore(descrDiv, el.firstChild);
      }
      templateInstance.contentChanged = (data, validators) => {
        if (validators?.length && data?.error?.getValue()) {
          this.instanceValidationErrors[
            moduleIndex
          ] = !templateInstance.validateInput(data, validators);
          this.haveValidationErrors = this.instanceValidationErrors.some(
            Boolean
          );
        }
        this.blockRepeaterContentChanged(data, module.step_id, validators);
      };
      templateInstance.isPartOfBlockRepeater = true;
      templateInstance.parentStep = this;
      this.templateInstances.push(templateInstance);
      this.instanceValidationErrors.push(false);
    });
  }

  updateChildrenData(): void {
    if (this.templateInstances?.length) {
      this.templateInstances.forEach(instance => {
        instance.data.data.hidden = this.data.data.hidden;
        instance.updateTemplateData();
      });
    }
  }
}
