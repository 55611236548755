import { SafeStyle } from '@angular/platform-browser';

export interface Campaign {
  id: string;
  theme: string;
  persona: string[];
  buyingStage?: string;
  campaignType?: string;
  kpi?: string;
  messaging?: string;
  tacticalMap?: string;
  startDate?: string;
  endDate?: string;
  assigned?: string;
  color?: string;
  offset?: SafeStyle;
  width?: SafeStyle;
  customFields?: {
    [key: string]: string;
  };
}

type CampaignErrors = Partial<
  Record<
    keyof Omit<Campaign, 'id' | 'offset' | 'width' | 'customFields'>,
    boolean
  >
>;

export interface CampaignTemplateErrors {
  isEmpty: boolean;
  campaigns: CampaignErrors[];
}

export interface CampaignGraph {
  [key: string]: Campaign[];
}

export interface CampaignCustomField {
  name: string;
  type: 'text' | 'number' | 'currency';
}

export const buyingStages = [
  'Recognizes Need',
  'Defines Need',
  'Evaluates Options',
  'Mitigates Risk',
  'Selects Solution',
  'Realizes Value'
] as const;

export const campaignTypes = [
  'Awareness',
  'Lead Gen',
  'Cross Sell',
  'Upsell',
  'Customer Sat'
] as const;

export const defaultKpiValue = 'MCL MQL';
