import { BehaviorSubject } from 'rxjs';
import User from 'src/app/common/interfaces/user.model';
import ModuleContent from 'src/app/common/interfaces/module-content.model';

interface TemplateContentDataProps {
  data: ModuleContent;
  me: User;
  canModify: boolean;
}
export class TemplateContentData {
  data: ModuleContent;
  me: User;
  canModify: boolean;
  onHideChanges: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor({ data, me, canModify }: TemplateContentDataProps) {
    this.data = data;
    this.me = me;
    this.canModify = canModify;
  }

  get hideChanges() {
    return this.onHideChanges.getValue();
  }

  set hideChanges(val: boolean) {
    this.onHideChanges.next(val);
  }
}
