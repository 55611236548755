import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';

@Component({
  selector: 'spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
  @Input() sub: Subscription;

  faSpinner = faSpinner;

  constructor() {}

  ngOnInit() {}
}
