import { Component, OnInit } from '@angular/core';
import { EditBlogComponent } from './edit/edit.component';
import { MatDialog } from '@angular/material/dialog';
import { BlogService } from 'src/app/common/services/blog.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  post = {
    title: '',
    content: ''
  };
  selectedMessage: any;

  constructor(
    private modalService: MatDialog,
    private postService: BlogService
  ) {}

  ngOnInit(): void {
    this.postService.currentPost.subscribe(loadedPost => {
      if (loadedPost) this.post = loadedPost;
    }); //<= Always get current value!
  }

  editPost() {
    const modalRef = this.modalService.open(EditBlogComponent, {
      panelClass: 'step-template-editor-modal',
      maxHeight: '90vh'
    });
  }
}
