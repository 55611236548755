import { Pipe, PipeTransform } from '@angular/core';
import { utcToZonedTime } from 'date-fns-tz';
import { format, isSameDay, isSameYear } from 'date-fns';

@Pipe({
  name: 'readabletime'
})
export class ReadableTimePipe implements PipeTransform {
  transform(datetime: string | Date, forceFormat?: string) {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const utcTime = new Date(datetime);
    const date = tz ? utcToZonedTime(utcTime, tz) : utcTime;

    if (forceFormat) {
      return format(date, forceFormat);
    } else if (isSameDay(new Date(), date)) {
      return format(date, 'p');
    } else if (isSameYear(new Date(), date)) {
      return format(date, 'MMMM d');
    } else {
      return format(date, 'PP');
    }
  }
}
