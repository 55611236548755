import { Component, Input, ViewChild } from '@angular/core';
import {
  Module,
  Organization
} from 'src/app/common/interfaces/module.interface';
import { ModuleService } from 'src/app/common/services/module.service';
import { DatePickerInputComponent } from '../../../common/components/date-picker-input/date-picker-input.component';

@Component({
  selector: 'app-due-date',
  exportAs: 'dueDate',
  templateUrl: './due-date.component.html',
  styleUrls: ['./due-date.component.scss']
})
export class DueDateComponent {
  @Input() module: Module;
  @Input() organization: Organization;
  @Input() disabled: boolean;

  @ViewChild(DatePickerInputComponent)
  datePickerInput: DatePickerInputComponent;

  minDate: Date = new Date();

  constructor(private moduleService: ModuleService) {}

  saveDueDate(dateValue: string, module: Module) {
    module.status.due_date = dateValue;
    this.moduleService
      .setDueDate(module, module.status.due_date, this.organization.id)
      .subscribe(newStatus => {
        module.status = newStatus;
      });
  }

  openDatePicker(): void {
    !this.disabled && this.datePickerInput.open();
  }
}
