import {
  Component,
  ViewChild,
  forwardRef,
  ElementRef,
  HostBinding
} from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import { QuestionImageTemplateData } from '.';
import {
  DomSanitizer,
  SafeStyle,
  SafeResourceUrl
} from '@angular/platform-browser';
import txt from '!!raw-loader!./index.ts';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';

@Component({
  selector: 'app-question-image',
  templateUrl: './question-image.component.html',
  styleUrls: ['./question-image.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => QuestionImageComponent)
    }
  ],
  preserveWhitespaces: true
})
export class QuestionImageComponent extends TemplateComponent {
  @ViewChild('iframe', { static: true }) iframe: ElementRef;

  contentData: QuestionImageTemplateData['template_params_json'];
  params = txt;

  faSpinner = faSpinner;

  pdf: SafeResourceUrl;
  image: SafeStyle;
  pdfLoaded = false;

  @HostBinding('class.pdf-block') get isPDF(): boolean {
    return !!this.pdf;
  }

  @HostBinding('class.image-block') get isImage(): boolean {
    return !!this.image;
  }

  getDescription() {
    return '';
  }

  getName() {
    return 'Embed media';
  }

  getGroup(): string {
    return 'Generic';
  }

  init() {
    this.contentData = this.data.data
      .template_params_json as QuestionImageTemplateData['template_params_json'];

    const sanitizer = this.injectorObj.get<DomSanitizer>(DomSanitizer);
    if (this.contentData.image?.toLowerCase().substr(-4) === '.pdf') {
      this.pdf = sanitizer.bypassSecurityTrustResourceUrl(
        this.contentData.image
      );
    } else {
      this.image = sanitizer.bypassSecurityTrustStyle(
        'url(' + this.contentData.image + ')'
      );
    }
  }

  iframeLoaded() {
    this.pdfLoaded = true;
  }
}
