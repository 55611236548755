import { Component, forwardRef } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import { PersonaPictureTemplateData } from '.';
import { PersonaPictureListComponent } from './persona-picture-list/persona-picture-list.component';
import txt from '!!raw-loader!./index.ts';
import { MatDialog } from '@angular/material/dialog';
import { filter, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

interface ListItem {
  [key: string]: {
    option: string;
    uuid: string;
  };
}

interface Persona {
  uuid: string;
  title: string;
  name: string;
  picture: string;
}

@Component({
  selector: 'app-persona-picture',
  templateUrl: './persona-picture.component.html',
  styleUrls: ['./persona-picture.component.scss'],
  preserveWhitespaces: true,
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => PersonaPictureTemplateComponent)
    }
  ]
})
export class PersonaPictureTemplateComponent extends TemplateComponent {
  params = txt;
  contentData: PersonaPictureTemplateData['template_params_json'];

  personasIds: string[] = [];
  personas: { [uuid: string]: Persona } = {};
  err: { error: Observable<string> } = { error: null };
  protected modalService: MatDialog;

  getDescription() {
    return '';
  }

  getName() {
    return 'Persona Picture';
  }

  getGroup(): string {
    return 'Module';
  }

  init() {
    super.init();
    this.contentChanged$.pipe(this.whileExists()).subscribe(_ => {
      this.buyerPersonasService.reloadBuyerPersonas();
    });
    this.modalService = this.injectorObj.get(MatDialog);
    const previousData: ListItem = JSON.parse(
      Object.values(this.inputs).find(
        input =>
          input?.element_key?.includes('personas') &&
          this.notEmpty(input.content)
      ).content
    );
    const parsedPersons = this.inputs?.persona_picture_1?.content
      ? JSON.parse(this.inputs?.persona_picture_1?.content)
      : {};
    this.personasIds = Object.values(previousData)
      .filter(value => value.uuid)
      .map(value => value.uuid);

    this.personasIds.forEach(uuid => {
      const prevDataById = [...(previousData as any)].find(
        data => data.uuid === uuid
      );
      const parsedPerson = parsedPersons[uuid];
      this.personas[uuid] = {
        uuid,
        name: parsedPerson?.name || '',
        title: parsedPerson?.titles || prevDataById.option || '',
        picture: parsedPerson?.picture || ''
      } as any;
    });
    this.inputs.persona_picture_1.content = JSON.stringify(this.personas);
    this.contentChanged(this.inputs.persona_picture_1);

    this.contentData = this.data.data
      .template_params_json as PersonaPictureTemplateData['template_params_json'];
  }

  openModal(uuid: string) {
    const modalRef = this.modalService.open(PersonaPictureListComponent);

    modalRef
      .afterClosed()
      .pipe(filter(src => src))
      .subscribe(src => {
        this.personas[uuid].picture = src;
        this.updateInputs();
      });
  }

  updateInputs() {
    this.inputs.persona_picture_1.content = JSON.stringify(this.personas);
    this.contentChanged(this.inputs.persona_picture_1);
  }

  validate(): Observable<boolean> {
    return of(this.personasIds.every(uuid => this.personas[uuid].picture)).pipe(
      tap(item => {
        this.err.error = of(
          item ? null : 'Please select a picture for all personas'
        );
      })
    );
  }
}
