import { TemplateContentDataBase } from '../template.interface';

export interface BuyerJourneyMapTemplateData extends TemplateContentDataBase {
  template_params_json: {
    description: string;
    title: string;
  };
}

export const stepsData = [
  {
    step: 'recognizes',
    title: 'Recognizes Need',
    description:
      'Buyer is aware of and has identified the impact and ' +
      'challenges relevant to their business problem or need.'
  },
  {
    step: 'need',
    title: 'Defines Need',
    description:
      'Buyer defines the problem, quantifies the implications of' +
      ' not solving it, and identifies necessary requirements.'
  },
  {
    step: 'options',
    title: 'Evaluates Options',
    description:
      'Buyer has defined necessary requirements and is evaluating ' +
      'options, which align with organizational needs and budget, including ' +
      '‘Do Nothing’ and ’Do it Ourselves’.'
  },
  {
    step: 'risk',
    title: 'Mitigates Risk',
    description:
      'Buyer works to minimize risk to their organization and ' +
      'proceeds with internal recommendation to purchase.'
  },
  {
    step: 'solution',
    title: 'Selects Solution',
    description:
      'Buyer gives final approval on proposal and signs agreement. ' +
      'Buyer is focused on implementation to solve the problem.'
  },
  {
    step: 'realizes',
    title: 'Realizes Value',
    description:
      'Buyer is adopting the solution to realize the value of the solution.'
  }
];
