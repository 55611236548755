import { Component, OnInit, Input } from '@angular/core';
import { DialogType } from './e3-confirmation-dialog.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'e3-confirmation-dialog',
  templateUrl: './e3-confirmation-dialog.component.html',
  styleUrls: ['./e3-confirmation-dialog.component.scss']
})
export class E3ConfirmationDialogComponent implements OnInit {
  @Input() ok_text = 'Confirm';
  @Input() cancel_text = 'Cancel';
  @Input() content: string;
  @Input() title: string;
  @Input() type: DialogType;
  @Input() noCancel = false;

  constructor(public modal: MatDialogRef<E3ConfirmationDialogComponent>) {}

  ngOnInit() {}
}
