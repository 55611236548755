import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'includesValue'
})
export class IncludesValuePipe implements PipeTransform {
  transform(arr: any[], value: any): boolean {
    return (arr || []).includes(value);
  }
}
