import { Component, ComponentRef, forwardRef, ViewChild } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import { ComposableTemplateParams } from '.';
import txt from '!!raw-loader!./index.ts';
import {
  Module,
  Step,
  TemplateInput
} from '../../../../common/interfaces/module.interface';
import ModuleContent from '../../../../common/interfaces/module-content.model';
import { RTemplateDirective } from '../../riverside-step-template-host.directive';
import { Templates } from '../index';
import { TemplateContentData } from '../template-data.class';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { BlockRepeaterComponent } from '../block-repeater/block-repeater.component';
import { BlockRepeaterWrapperComponent } from '../block-repeater/block-repeater-wrapper/block-repeater-wrapper.component';

@Component({
  selector: 'composable-template',
  templateUrl: 'composable-template.component.html',
  styleUrls: ['./composable-template.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => ComposableTemplateComponent)
    }
  ]
})
export class ComposableTemplateComponent extends TemplateComponent {
  @ViewChild(RTemplateDirective, { static: true })
  templateHost: RTemplateDirective;
  params = txt;
  contentData: ComposableTemplateParams;

  requiredModule: Module;
  steps: Step[] = [];
  linkedSteps: Step[] = [];
  componentRefs: ComponentRef<TemplateComponent>[] = [];
  loading = false;

  tabNames: string[];
  activeTabIndex = 0;

  getDescription() {
    return 'Composable';
  }

  getName() {
    return 'Composable Template';
  }

  getGroup(): string {
    return 'Generic';
  }

  validate(): Observable<boolean> {
    return forkJoin(
      this.componentRefs.map(component => component.instance.validate())
    ).pipe(map(validations => validations.every(Boolean)));
  }

  init() {
    super.init();
    this.contentData = this.data.data
      .template_params_json as ComposableTemplateParams;

    this.loadChildTemplates();
    if (this.contentData.print_orientation_select) {
      this.setOrientationPrintLayout(this.contentData.print_orientation_select);
    }
  }

  onStepTransition(): void {
    this.componentRefs?.forEach(templateRef =>
      templateRef.instance.onStepTransition()
    );
    super.onStepTransition();
  }

  activateTab(tabIndex: number): void {
    this.componentRefs.forEach(componentRef => {
      componentRef.location.nativeElement.className = componentRef.location.nativeElement.className
        .split(' active-tab')
        .join('');
    });

    const tab = this.componentRefs[tabIndex];
    if (tab) {
      tab.location.nativeElement.className += ' active-tab';
    }
    this.activeTabIndex = tabIndex;
  }

  trackByFn(index, item) {
    return index;
  }

  private getRequiredModule(currentModule: Module): Observable<Module> {
    return currentModule?.id === this.data.data.module_id
      ? of(currentModule)
      : this.moduleService.getOrgModule(
          this.data.data.module_id,
          this.data.data.org_id,
          true
        );
  }

  private getLinkedSteps(module: Module): Step[] {
    const linkedStepIds = this.contentData.linked_steps_order
      ? this.contentData.linked_steps_order.map(item => Number(item.step_id))
      : this.data.data.linked_ids.map(id => Number(id));

    return linkedStepIds
      .map(linkedStepId => module.steps.find(step => step.id === linkedStepId))
      .filter(Boolean);
  }

  private getRequiredSteps(module: Module): Step[] {
    let steps = module.steps.filter(
      step => step.parent_step_id === this.data.data.step_id
    );
    if (
      this.contentData.show_linked_steps &&
      this.data.data.linked_ids?.length
    ) {
      this.linkedSteps = this.getLinkedSteps(module);
      steps = [...steps, ...this.linkedSteps];
    }

    return steps;
  }

  private loadRequiredSteps(module: Module): Observable<ModuleContent[]> {
    this.requiredModule = module;
    this.steps = this.getRequiredSteps(module);

    return this.moduleContentService.loadSteps(
      this.data.data.module_id,
      this.data.data.org_id,
      this.steps.map(step => step.id)
    );
  }

  private createTemplateComponent(idx: number, module: ModuleContent): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      this.templateService.templates[this.steps[idx].template_component]
    );
    const componentRef = this.templateHost.viewContainerRef.createComponent(
      componentFactory
    );
    module.disabled = this.me.permissions.riversideRMCFAdmin;
    const templateInstance = componentRef.instance as TemplateComponent;
    module.disabled =
      !!this.linkedSteps.find(linkedStep => linkedStep.id === module.step_id) ||
      this.data.data.disabled;

    templateInstance.data = new TemplateContentData({
      data: module,
      me: this.me,
      canModify: module.can_modify
    });

    if (this.isPartOfBlockRepeater) {
      templateInstance.contentChanged = this.contentChanged;
      templateInstance.blockRepeaterContentChanged = this.blockRepeaterContentChanged;
      Object.keys(templateInstance.data.data.inputs).forEach(item => {
        if (this.data.data.options.childSteps[item]) {
          templateInstance.data.data.inputs[item] = this.data.data.options
            .childSteps[item] as TemplateInput;
        }
      });
      templateInstance.data.data.options = this.data.data.options;
      templateInstance.parentStep = this.parentStep;
      templateInstance.isPartOfBlockRepeater = this.isPartOfBlockRepeater;
    }

    const descr = module.template_params_json.description;
    if (descr) {
      const descrDiv = document.createElement('div');
      descrDiv.className = 'composable-description';
      descrDiv.innerHTML = descr;
      const el = componentRef.location.nativeElement;
      el.insertBefore(descrDiv, el.firstChild);
    }
    this.componentRefs.push(componentRef as ComponentRef<TemplateComponent>);
    if (this.contentData.display_child_blocks_as_tabs) {
      const stepTitle = module.template_params_json.title;
      this.tabNames.push(stepTitle);
      componentRef.location.nativeElement.className += ' composable-tab';
      componentRef.location.nativeElement.setAttribute('tab-name', stepTitle);
      if (idx === 0) {
        componentRef.location.nativeElement.className += ' active-tab';
      }
    }
  }

  private initComponents(content: ModuleContent[]): void {
    if (this.contentData.display_child_blocks_as_tabs) {
      this.tabNames = [];
    }
    content.forEach((moduleContent: ModuleContent, index) =>
      this.createTemplateComponent(index, moduleContent)
    );
  }

  private loadChildTemplates(): void {
    this.loading = true;
    this.navService.moduleDataReplay$
      .pipe(
        this.whileExists(),
        take(1),
        switchMap((currentModule: Module) =>
          this.getRequiredModule(currentModule)
        ),
        switchMap((requiredModule: Module) =>
          this.loadRequiredSteps(requiredModule)
        ),
        tap(() => (this.loading = false))
      )
      .subscribe(content => this.initComponents(content));
  }
}
