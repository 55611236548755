import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/common/services/user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted: Subscription;
  returnUrl: string;
  redirectUrl: string;
  isInvalid = false;

  fields = ['username', 'password'];

  isValidCredentials = true;

  constructor(
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    private router: Router,
    private userService: UserService
  ) {
    // redirect to home if already logged in
    // if (this.authenticationService.currentUserValue) {
    //     this.router.navigate(['/']);
    // }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/dashboard';
    this.redirectUrl = this.route.snapshot.queryParams.redirectUrl;
  }

  submit(event: Event) {
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.isInvalid = true;

      return;
    }

    const trimmedUsername = String(this.loginForm.get('username').value).trim();
    this.loginForm.patchValue({
      username: trimmedUsername
    });

    this.loading = true;

    const form = new FormData();

    this.fields.forEach(field =>
      form.append(field, this.loginForm.get(field).value)
    );

    form.append('json', 'true');

    this.submitted = this.userService.signin(form).subscribe({
      error: () => {
        this.isValidCredentials = false;
        this.loading = false;
      },
      next: res => {
        if (res) {
          this.userService.getAccount().subscribe({
            next: account => {
              this.userService.setMeFromData(account);

              if (this.redirectUrl) {
                window.location.href = this.redirectUrl;
              }

              this.router.navigate([this.returnUrl]);
              this.loading = false;
            },
            error: err => (this.loading = false),
            complete: () => (this.loading = false)
          });
        } else {
          this.isValidCredentials = false;
          this.loading = false;
        }
      }
    });
  }
}
