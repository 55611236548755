import { Injectable, NgModule } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  RouterModule,
  RouterStateSnapshot,
  Routes,
  UrlTree
} from '@angular/router';
import { MainComponent } from './main/main.component';
import { ContentComponent } from './content/content.component';
import { InboxComponent } from './inbox/inbox.component';
import { LeftMenuComponent } from './left-menu/module/module-left-menu.component';
import { InboxLeftMenuComponent } from './left-menu/inbox/inbox-left-menu.component';
import { AccountLeftMenuComponent } from './left-menu/account/account-left-menu.component';
import { BlogLeftMenuComponent } from './left-menu/blog/blog.component';
import {
  DashboardComponent,
  SalesTrainingSegment
} from './dashboard/dashboard.component';
import { MasterDashboardComponent } from './master-dashboard/master-dashboard.component';
import { AssessmentComponent } from './assessment/assessment.component';
import { AssessmentMenuComponent } from './left-menu/assessment-menu/assessment-menu.component';
import { AssessmentFinishComponent } from './assessment/assessment-finish/assessment-finish.component';
import { BlogComponent } from './blog/blog.component';
import { ProfileComponent } from './account/profile/profile.component';
import { ChangePasswordComponent } from './account/change-password/change-password.component';
import { LoginComponent } from './login/login.component';
import { VideoLibraryComponent } from './video-library/video-library.component';
import { UserService } from '../common/services/user.service';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class ViewerAccessGuard implements CanActivate, CanActivateChild {
  constructor(private userService: UserService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return (
      environment.allowDynamicApi ||
      (this.userService?.me?.permissions &&
        Object.values(this.userService?.me?.permissions).some(key => key)) ||
      this.userService
        .getAccount()
        .pipe(switchMap(account => of(Boolean(account))))
    );
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return (
      environment.allowDynamicApi ||
      (this.userService?.me?.permissions &&
        Object.values(this.userService?.me?.permissions).some(key => key)) ||
      this.userService
        .getAccount()
        .pipe(switchMap(account => of(Boolean(account))))
    );
  }
}

const moduleContentRoute = [
  {
    path: 'step/:stepId',
    component: ContentComponent
  },
  {
    path: '',
    component: LeftMenuComponent,
    outlet: 'left-menu'
  }
];

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard'
  },
  {
    path: 'org/:orgId/module/:moduleId',
    component: MainComponent,
    canActivate: [ViewerAccessGuard],
    canActivateChild: [ViewerAccessGuard],
    children: moduleContentRoute
  },
  {
    path: 'module/:moduleId',
    component: MainComponent,
    canActivate: [ViewerAccessGuard],
    canActivateChild: [ViewerAccessGuard],
    children: moduleContentRoute
  },
  {
    path: 'inbox',
    component: MainComponent,
    canActivate: [ViewerAccessGuard],
    canActivateChild: [ViewerAccessGuard],
    children: [
      {
        path: '',
        component: InboxComponent
      },
      {
        path: ':id',
        component: InboxComponent
      },
      {
        path: '',
        component: InboxLeftMenuComponent,
        outlet: 'left-menu'
      }
    ]
  },
  {
    path: 'account',
    component: MainComponent,
    canActivate: [ViewerAccessGuard],
    canActivateChild: [ViewerAccessGuard],
    children: [
      {
        path: '',
        redirectTo: 'profile',
        pathMatch: 'full'
      },
      {
        path: 'profile',
        component: ProfileComponent
      },
      {
        path: 'password',
        component: ChangePasswordComponent
      },
      {
        path: '',
        component: AccountLeftMenuComponent,
        outlet: 'left-menu'
      }
    ]
  },
  {
    path: 'dashboard',
    component: MainComponent,
    canActivate: [ViewerAccessGuard],
    canActivateChild: [ViewerAccessGuard],
    children: [
      {
        path: '',
        component: DashboardComponent
      }
    ]
  },
  {
    path: `dashboard/${SalesTrainingSegment}`,
    component: MainComponent,
    canActivate: [ViewerAccessGuard],
    canActivateChild: [ViewerAccessGuard],
    children: [
      {
        path: '',
        component: DashboardComponent
      }
    ]
  },
  {
    path: 'dashboard/:orgId',
    component: MainComponent,
    canActivate: [ViewerAccessGuard],
    canActivateChild: [ViewerAccessGuard],
    children: [
      {
        path: '',
        component: DashboardComponent
      }
    ]
  },
  {
    path: `dashboard/${SalesTrainingSegment}/:orgId`,
    component: MainComponent,
    canActivate: [ViewerAccessGuard],
    canActivateChild: [ViewerAccessGuard],
    children: [
      {
        path: '',
        component: DashboardComponent
      }
    ]
  },
  {
    path: 'master-dashboard',
    component: MainComponent,
    canActivate: [ViewerAccessGuard],
    canActivateChild: [ViewerAccessGuard],
    children: [
      {
        path: '',
        component: MasterDashboardComponent
      }
    ]
  },
  {
    path: 'video-library',
    component: MainComponent,
    canActivate: [ViewerAccessGuard],
    canActivateChild: [ViewerAccessGuard],
    children: [
      {
        path: '',
        component: VideoLibraryComponent
      },
      {
        path: ':moduleId',
        component: VideoLibraryComponent
      }
    ]
  },
  {
    path: 'org/:orgId/assessment',
    component: MainComponent,
    canActivate: [ViewerAccessGuard],
    canActivateChild: [ViewerAccessGuard],
    children: [
      {
        path: '',
        component: AssessmentComponent
      },
      {
        path: '',
        component: AssessmentMenuComponent,
        outlet: 'left-menu'
      },
      {
        path: 'finish',
        component: AssessmentFinishComponent
      }
    ]
  },
  {
    path: 'blog',
    component: MainComponent,
    canActivate: [ViewerAccessGuard],
    canActivateChild: [ViewerAccessGuard],
    children: [
      {
        path: '',
        component: BlogComponent
      },
      {
        path: '',
        component: BlogLeftMenuComponent,
        outlet: 'left-menu'
      }
    ]
  }
];

@NgModule({
  // imports: [RouterModule.forRoot(routes, {useHash: true, enableTracing: true, urlUpdateStrategy: 'deferred'})],
  imports: [RouterModule.forChild(routes)],
  providers: [ViewerAccessGuard],
  exports: [RouterModule]
})
export class AppRoutingModule {}
