import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { dataSetName, IcpTable, TableRow, TitleChangeEvent } from '../index';
import {
  isDeleteOrBackspace,
  isPreviousConditionCombo
} from '../../../../../common/utils/keyboard-helpers';

@Component({
  selector: 'app-icp-table',
  templateUrl: './icp-table.component.html',
  styleUrls: ['./icp-table.component.scss']
})
export class IcpTableComponent implements OnInit, OnChanges {
  @Input() table: IcpTable;
  @Input() activeTab: dataSetName | string;
  @Input() disabled = false;

  @Output() titleCellChanged = new EventEmitter<TitleChangeEvent>();
  @Output() addNewRow = new EventEmitter<dataSetName>();
  @Output() deleteRow = new EventEmitter<TableRow>();
  @Output() combinedChange = new EventEmitter<void>();

  isProspects = false;
  isCombined = false;
  isPersona = false;
  totalColumnValue = [];
  numberInputMaxLength = 10;
  titleInputMaxLength = 50;

  constructor() {}

  ngOnInit() {
    this.isProspects = this.table.title === 'Prospects';
    this.isCombined = this.table.title === 'Combined';
    this.isPersona = this.activeTab === 'persona';
    this.countColumnValue();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.countColumnValue();
  }

  onCellContentChanged(value: string, row: TableRow, path: string) {
    let res: string | number;
    if (path !== 'title') {
      res = +(
        value.replace(/[^\d]/g, '').slice(0, this.numberInputMaxLength) || 0
      );
      row.values[path] = res;
    } else {
      res = value.trim().slice(0, this.titleInputMaxLength);
      this.titleCellChanged.emit({ row, value: res });
    }

    this.combinedChange.emit();
    this.countColumnValue();

    return res.toString();
  }

  onCellFocus(value: string) {
    return +value === 0 ? '' : value;
  }

  checkMaxLength(
    event: KeyboardEvent,
    tdCell: HTMLTableDataCellElement,
    maxlength: number
  ): void {
    if (
      tdCell.innerText.length >= maxlength &&
      !(
        isDeleteOrBackspace(event) ||
        isPreviousConditionCombo(event) ||
        !!window.getSelection().toString()
      )
    ) {
      event.preventDefault();
    }
  }

  private countColumnValue() {
    this.totalColumnValue = this.table.rows
      .map(row => [
        row.values.a,
        row.values.b,
        row.values.c,
        row.values.d,
        row.values.a + row.values.b + row.values.c + row.values.d
      ])
      .reduce((total, val) => total.map((totalVal, i) => totalVal + val[i]), [
        0,
        0,
        0,
        0,
        0
      ]);
  }
}
