import { NgModule } from '@angular/core';
import { UsersManagementRootComponent } from './users-management-root.component';
import { RouterModule } from '@angular/router';
import { MainComponent } from './main/main.component';
import { UsersManagementRoutingModule } from './users-management-routing.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { UserFormComponent } from './user-form/user-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { MatTableModule } from '@angular/material/table';
import { UsersTableComponent } from './users-table/users-table.component';
import { LoaderComponent } from '../common/components/loader/loader.component';
import { LoaderService } from '../common/services/loader.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  imports: [
    RouterModule.forRoot([], { useHash: true }),
    UsersManagementRoutingModule,
    MatCheckboxModule,
    MatIconModule,
    MatButtonModule,
    MatTabsModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    NgxTrimDirectiveModule,
    MatTableModule,
    MatProgressSpinnerModule
  ],
  declarations: [
    UsersManagementRootComponent,
    MainComponent,
    UserFormComponent,
    UsersTableComponent,
    LoaderComponent
  ],
  entryComponents: [UsersManagementRootComponent, MainComponent],
  providers: [LoaderService],
  bootstrap: [UsersManagementRootComponent]
})
export class UsersManagementModule {}
