import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

export interface Topic {
  title: string;
  stepId: number[];
  activeTopic?: number;
  isActive?: boolean;
  hiddenStep?: number;
}

@Component({
  selector: 'module-progress-bar',
  templateUrl: './module-progress-bar.component.html',
  styleUrls: ['./module-progress-bar.component.scss']
})
export class ModuleProgressBarComponent implements OnChanges {
  @Input() progressBarTopics: Array<Topic>;
  hasActiveTopic = false;

  ngOnChanges(changes: SimpleChanges): void {
    this.hasActiveTopic =
      Array.isArray(this.progressBarTopics) &&
      this.progressBarTopics.some(topic => topic.isActive);
  }
}
