import { Component, forwardRef } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import { TablePath, SituationalCoachingTemplateData, tableData } from '.';
import txt from '!!raw-loader!./index.ts';

@Component({
  selector: 'situational-coaching',
  templateUrl: 'situational-coaching.component.html',
  styleUrls: ['./situational-coaching.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => SituationalCoachingComponent)
    }
  ]
})
export class SituationalCoachingComponent extends TemplateComponent {
  params = txt;
  contentData: SituationalCoachingTemplateData['template_params_json'];

  table: TablePath[][];
  isTable = true;

  get input() {
    return this.getInput('situational_coaching_1');
  }

  get inputData(): TablePath[][] {
    return JSON.parse(
      this.input.getValue().replace(/\n/g, '<br>') || JSON.stringify(tableData)
    );
  }

  getDescription() {
    return 'Specific template for the Sales Leadership module';
  }

  getName() {
    return 'Situational Coaching';
  }

  getGroup() {
    return 'Module';
  }

  init() {
    super.init();
    this.table = this.inputData;
    this.contentData = this.data.data
      .template_params_json as SituationalCoachingTemplateData['template_params_json'];
    if (this.contentData.layout_type_select === 'list') {
      this.isTable = false;
    }
    Object.keys(this.inputs).map(inputName => {
      this.inputs[inputName].content =
        this.inputs[inputName].content || '<ul><li>&nbsp;</li></ul>';
    });
  }

  addRep(rowElement: TablePath): void {
    rowElement.reps.push({ value: '' });
    this.updateData();
  }

  deleteRep(rowElement: TablePath, index: number): void {
    this.confirmationService.removeDialog({ text: 'rep' }).subscribe(() => {
      rowElement.reps.splice(index, 1);
      this.updateData();
    });
  }

  updateData(): void {
    this.input.content = JSON.stringify(this.table);
    this.contentChanged(this.input);
  }
}
