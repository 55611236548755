import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-assessment-score',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.scss']
})
export class AssessmentScoreComponent implements OnInit {
  @Input() score: number | string;

  constructor() {}

  ngOnInit() {}
}
