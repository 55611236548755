import { Component, forwardRef } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import txt from '!!raw-loader!./index.ts';
import {
  CustomerSuccessInfo,
  CustomerSuccessInfoKeys,
  CustomerSuccessReviewTemplateData,
  CustomerSuccessSection,
  CustomerSuccessStep,
  SPSections
} from './index';
import { TemplateInput } from '../../../../common/interfaces/module.interface';
import { MatrixElement } from '../matrix-entry';

@Component({
  selector: 'app-customer-success-review',
  templateUrl: './customer-success-review.component.html',
  styleUrls: ['./customer-success-review.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => CustomerSuccessReviewComponent)
    }
  ]
})
export class CustomerSuccessReviewComponent extends TemplateComponent {
  params = txt;
  contentData: CustomerSuccessReviewTemplateData['template_params_json'];

  spSections: typeof SPSections = SPSections;
  sections: CustomerSuccessSection[] = [];
  selectedSectionId = 0;
  selectedStepId = 0;

  readonly tableIcons = [
    'event',
    'chat',
    'group',
    'stars',
    'settings',
    'access_time'
  ];

  private readonly sectionSteps: Readonly<Record<SPSections, string[]>> = {
    [SPSections.AccountPlanning]: [
      'Understand Customer Landscape and Define Business Opportunity',
      'Develop the Strategy and Account Plan and Gain Customer Approval',
      'Execute and Govern the Account Plan'
    ],
    [SPSections.AccountDevelopment]: [
      'Establish Relationship',
      'Develop Relationship',
      'Nurture Relationship'
    ],
    [SPSections.OpportunityManagement]: ['The Rhythm of the relationship'],
    [SPSections.AccountReviews]: [
      'Customer Business Reviews',
      'Internal Account Review'
    ]
  };

  private readonly sectionStepDefinitions: Readonly<
    Record<SPSections, string[][]>
  > = {
    [SPSections.AccountPlanning]: [
      [
        'Understand all aspects of the customer environment ' +
          'including desired adherence and business outcomes, financials, leadership, etc.',
        'Understand and document summary of customer priorities'
      ],
      [
        'Develop a recommendation for your customer which meets the requirements defined' +
          'in the strategic problem definition. Customer should acknowledge and agree to recommendations.'
      ],
      [
        'Customer is taking steps toward further understanding your recommendations and offerings.'
      ]
    ],
    [SPSections.AccountDevelopment]: [
      [
        'You do not have a relationship with specific buyer within an account.' +
          'That relationship must established in order to start a conversation'
      ],
      [
        'You have an established relationship. This relationship must be' +
          'strengthened and developed'
      ],
      ['The relationship is nurtured to find active and lalent demand']
    ],
    [SPSections.OpportunityManagement]: [],
    [SPSections.AccountReviews]: [
      [
        'Regularly scheduled cadence of business reviews with the customer, focusing' +
          'on validating alignment with customer business and IT priorities'
      ],
      [
        'Account reviews drive behaviour through the process of inspection. ' +
          'Using the account plan in Salesforce.com, hold monthly leadership reviews of strategic' +
          'elements of the account.'
      ]
    ]
  };

  private readonly inputNamesForSteps: Readonly<
    Record<SPSections, Partial<CustomerSuccessInfoKeys>[]>
  > = {
    [SPSections.AccountPlanning]: [
      {
        exitCriteria: 'brainstorm_1_questions_customer_landscape',
        buyerPhase: 'brainstorm_2_questions_customer_landscape',
        buyerQuestions: 'brainstorm_3_questions_customer_landscape',
        salesAids: 'brainstorm_4_questions_customer_landscape',
        referenceMaterials: 'brainstorm_5_questions_customer_landscape',
        keyActivities: 'matrix_entry_1_key_activities_customer_landscape'
      },
      {
        exitCriteria: 'brainstorm_1_questions_develop_strategy',
        buyerPhase: 'brainstorm_2_questions_develop_strategy',
        buyerQuestions: 'brainstorm_3_questions_develop_strategy',
        salesAids: 'brainstorm_4_questions_develop_strategy',
        referenceMaterials: 'brainstorm_5_questions_develop_strategy',
        keyActivities: 'matrix_entry_1_key_activities_develop_strategy'
      },
      {
        exitCriteria: 'brainstorm_1_questions_excute_and_govern',
        buyerPhase: 'brainstorm_2_questions_excute_and_govern',
        buyerQuestions: 'brainstorm_3_questions_excute_and_govern',
        salesAids: 'brainstorm_4_questions_excute_and_govern',
        referenceMaterials: 'brainstorm_5_questions_excute_and_govern',
        keyActivities: 'matrix_entry_1_key_activities_execute_and_govern'
      }
    ],
    [SPSections.AccountDevelopment]: [
      {
        exitCriteria: 'brainstorm_1_questions_establish_relationship',
        buyerPhase: 'brainstorm_2_questions_establish_relationship',
        jobAids: 'brainstorm_3_questions_establish_relationship',
        keyActivities: 'matrix_entry_1_key_activities_establish_relationship'
      },
      {
        exitCriteria: 'brainstorm_1_questions_develop_relationship',
        buyerPhase: 'brainstorm_2_questions_develop_relationship',
        jobAids: 'brainstorm_3_questions_develop_relationship',
        keyActivities: 'matrix_entry_1_key_activities_develop_relationship'
      },
      {
        exitCriteria: 'brainstorm_1_questions_nurture_relationship',
        buyerPhase: 'brainstorm_2_questions_nurture_relationship',
        jobAids: 'brainstorm_3_questions_nurture_relationship',
        keyActivities: 'matrix_entry_1_key_activities_nuture_relationship'
      }
    ],
    [SPSections.OpportunityManagement]: null,
    [SPSections.AccountReviews]: [
      {
        exitCriteria: 'brainstorm_1_questions_customer_business_reviews',
        buyerPhase: 'brainstorm_2_questions_customer_business_reviews',
        salesAids: 'brainstorm_3_questions_customer_business_reviews',
        keyActivities: 'matrix_entry_1_key_activities_customer_business_reviews'
      },
      {
        exitCriteria: 'brainstorm_1_questions_internal_account_review',
        salesAids: 'brainstorm_2_questions_internal_account_review',
        keyActivities: 'matrix_entry_1_key_activities_internal_account_review'
      }
    ]
  };

  private readonly sectionOrder: SPSections[] = [
    SPSections.AccountPlanning,
    SPSections.AccountDevelopment,
    SPSections.OpportunityManagement,
    SPSections.AccountReviews
  ];

  getDescription(): string {
    return '';
  }

  getName(): string {
    return 'Customer Success Review';
  }

  getGroup(): string {
    return 'Outputs';
  }

  get input(): TemplateInput {
    return this.getInput('customer_success_review');
  }

  protected init(): void {
    this.contentData = this.data.data
      .template_params_json as CustomerSuccessReviewTemplateData['template_params_json'];
    this.parseInputData();
    this.selectStep(0, 0);
    if (this.contentData.print_orientation_select) {
      this.setOrientationPrintLayout(this.contentData.print_orientation_select);
    }
  }

  private parseInputData(): void {
    this.sections = this.sectionOrder.map(section => {
      switch (section) {
        case SPSections.AccountPlanning:
        case SPSections.AccountDevelopment:
        case SPSections.AccountReviews: {
          const steps = this.sectionSteps[section].map(
            (stepName, stepIndex) => {
              const inputNamesForStep = this.inputNamesForSteps[section][
                stepIndex
              ];
              const csInfo: CustomerSuccessInfo = {
                definition: this.sectionStepDefinitions[section][stepIndex],
                exitCriteria: this.getInputContent(
                  inputNamesForStep.exitCriteria
                ),
                buyerPhase: this.handleIceString(inputNamesForStep.buyerPhase),
                buyerQuestions: this.handleIceString(
                  inputNamesForStep.buyerQuestions
                ),
                jobAids: this.handleIceString(inputNamesForStep.jobAids),
                salesAids: this.handleIceString(inputNamesForStep.salesAids),
                referenceMaterials: this.handleIceString(
                  inputNamesForStep.referenceMaterials
                ),
                keyActivities: this.getKeyActivitiesFromMatrix(
                  inputNamesForStep.keyActivities
                )
              };
              const csStep: CustomerSuccessStep = {
                name: stepName,
                info: csInfo
              };

              return csStep;
            }
          );

          return { section, steps };
        }
        case SPSections.OpportunityManagement: {
          const step: CustomerSuccessStep = {
            name: this.sectionSteps[SPSections.OpportunityManagement][0],
            info: {
              rhythmTable: this.getRhythmTableFromMatrix(
                'matrix_entry_1_establishing_rhythm'
              )
            }
          };

          return {
            section,
            steps: [step]
          };
        }
      }
    });
  }

  private getRhythmTableFromMatrix(inputName: string): string[][] {
    const blankRhythmTable: string[][] = Array.from(Array(6), () =>
      new Array(5).fill('')
    );
    const matrix = this.getParsedInputContent(inputName);
    if (matrix) {
      return blankRhythmTable.map((row, rowId) => {
        if (matrix[rowId]) {
          return row.map(({}, columnId) => {
            const matrixElement = matrix[rowId].find(
              element => Number(element.id) === columnId + 1
            );

            return matrixElement ? String(matrixElement.data) || '' : '';
          });
        }

        return row;
      });
    }

    return blankRhythmTable;
  }

  private getKeyActivitiesFromMatrix(
    inputName: string
  ): { title: string; description: string[] }[] {
    const matrix = this.getParsedInputContent(inputName);
    if (matrix && matrix.length) {
      return matrix.map(row => {
        const descriptionString = String(
          row.find(element => Number(element.id) === 3)?.data
        );
        const splitDescription = descriptionString.split('\n').filter(Boolean);

        return {
          title:
            String(row.find(element => Number(element.id) === 2)?.data || '') ||
            null,
          description: splitDescription.length ? splitDescription : null
        };
      });
    }

    return null;
  }

  private getInputContent(inputName: string): string {
    return inputName ? this.getInput(inputName)?.content || null : undefined;
  }

  private getParsedInputContent(inputName: string) {
    return JSON.parse(this.getInputContent(inputName)) as MatrixElement[][];
  }

  private selectStep(sectionId: number, stepId: number): void {
    this.selectedSectionId = sectionId;
    this.selectedStepId = stepId;
  }

  private handleIceString(inputName: string): string[] {
    const textContentWithBreakLines = (el: string) => {
      const elWithBreakLines = el.replace(/<\/p>/g, '</p>\n');
      const parser = new DOMParser().parseFromString(
        elWithBreakLines,
        'text/html'
      );
      parser.querySelectorAll('.del').forEach(deleted => deleted.remove());

      return parser.body.textContent.trim();
    };

    if (inputName) {
      const inputContent = this.getInputContent(inputName);
      if (!inputContent) {
        return null;
      }

      return textContentWithBreakLines(inputContent)
        .split('\n')
        .filter(Boolean);
    }

    return undefined;
  }
}
