import { TemplateContentDataBase } from '../template.interface';
import { BuyerPersona } from '../../../../common/interfaces/buyer-persona.interface';
import { ExpandedDataItem } from '../expand-data';

export interface FinalFeedbackTemplateData extends TemplateContentDataBase {
  template_params_json: {
    description: string;
    title: string;
    print_orientation_select: 'auto' | 'portrait' | 'landscape';
  };
}

export type FinalFeedbackTemplateParams = FinalFeedbackTemplateData['template_params_json'];

export interface PersonaFeedbackInfo {
  persona: BuyerPersona;
  inputIceInfo: {
    averageAge?: ExpandedDataItem;
    commonTitles?: ExpandedDataItem;
    education?: ExpandedDataItem;
    genderMale?: ExpandedDataItem;
    genderFemale?: ExpandedDataItem;
    howTheyBuy?: ExpandedDataItem;
    howTheyPay?: ExpandedDataItem;
    wateringHoles?: ExpandedDataItem;
    challenges?: ExpandedDataItem;
    objections?: ExpandedDataItem;
    goals?: ExpandedDataItem;
    priorities?: ExpandedDataItem;
    means?: ExpandedDataItem;
    metrics?: ExpandedDataItem;
    triggerEvents?: ExpandedDataItem;
  };
}

export type PersonaIceInfo = PersonaFeedbackInfo['inputIceInfo'];
export type ExpandDataInputInfo = { inputName: string; titleName: string };

type TPersonaInputNames = {
  [P in keyof Required<PersonaIceInfo>]: ExpandDataInputInfo;
};

export const personaInputNames: TPersonaInputNames = {
  averageAge: {
    inputName: 'expand_data_1_age_gender_and_education',
    titleName: 'Age'
  },
  commonTitles: {
    inputName: 'expand_data_1_age_gender_and_education',
    titleName: 'Other Common Titles'
  },
  education: {
    inputName: 'expand_data_1_age_gender_and_education',
    titleName: 'Education'
  },
  genderMale: {
    inputName: 'expand_data_1_age_gender_and_education',
    titleName: '% M'
  },
  genderFemale: {
    inputName: 'expand_data_1_age_gender_and_education',
    titleName: '% F'
  },
  howTheyBuy: { inputName: 'expand_data_1_htb', titleName: 'How They Buy' },
  howTheyPay: { inputName: 'expand_data_1_htb', titleName: 'How They Pay' },
  wateringHoles: {
    inputName: 'expand_data_1_watering_holes',
    titleName: 'Watering Holes'
  },
  challenges: {
    inputName: 'expand_data_1_challenges',
    titleName: 'Challenges'
  },
  objections: {
    inputName: 'expand_data_1_common_objections',
    titleName: 'Common Objections'
  },
  goals: { inputName: 'expand_data_1_goals', titleName: 'Goals' },
  priorities: {
    inputName: 'expand_data_1_priorities',
    titleName: 'Priorities'
  },
  means: { inputName: 'expand_data_1_means', titleName: 'Means' },
  metrics: { inputName: 'expand_data_1_metrics', titleName: 'Metrics' },
  triggerEvents: {
    inputName: 'expand_data_1_trigger_events',
    titleName: 'Trigger Events'
  }
} as const;
