import { Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import {
  addDays,
  addMonths,
  addYears,
  toDate,
  format,
  getDate,
  setDay,
  getMonth,
  setMonth,
  getDaysInMonth,
  getYear,
  parse,
  isValid
} from 'date-fns';

const WEEK_STARTS_ON = 1; // 0 sunday, 1 monday...

export const FULL_DATE_INPUT_FORMAT = 'MMM d, yyyy';
export const SECONDARY_FULL_DATE_INPUT_FORMAT = 'yyyy-MM-dd';
export const MONTH_YEAR_INPUT_FORMAT = 'yyyy-MM';

export type AllowedDateInputFormat =
  | typeof FULL_DATE_INPUT_FORMAT
  | typeof SECONDARY_FULL_DATE_INPUT_FORMAT
  | typeof MONTH_YEAR_INPUT_FORMAT;

export const MAT_DATE_FNS_DATE_FORMATS = {
  parse: {
    dateInput: FULL_DATE_INPUT_FORMAT
  },
  display: {
    dateInput: FULL_DATE_INPUT_FORMAT,
    monthYearLabel: 'LLL y',
    dateA11yLabel: 'MMMM d, y',
    monthYearA11yLabel: 'MMMM y'
  }
};

export const MAT_DATE_FNS_MONTH_YEAR_FORMATS = {
  parse: {
    dateInput: MONTH_YEAR_INPUT_FORMAT
  },
  display: {
    dateInput: MONTH_YEAR_INPUT_FORMAT,
    monthYearLabel: 'LLL y',
    dateA11yLabel: 'MMMM d, y',
    monthYearA11yLabel: 'MMMM y'
  }
};

const range = (start: number, end: number): number[] => {
  const arr: number[] = [];
  for (let i = start; i <= end; i++) {
    arr.push(i);
  }

  return arr;
};

@Injectable()
export class DateFnsDateAdapter extends DateAdapter<Date> {
  addCalendarDays(date: Date, days: number): Date {
    return addDays(date, days);
  }

  addCalendarMonths(date: Date, months: number): Date {
    return addMonths(date, months);
  }

  addCalendarYears(date: Date, years: number): Date {
    return addYears(date, years);
  }

  clone(date: Date): Date {
    return toDate(date);
  }

  createDate(year: number, month: number, date: number): Date {
    return new Date(year, month, date);
  }

  format(date: Date, displayFormat: any): string {
    return format(date, displayFormat);
  }

  getDate(date: Date): number {
    return getDate(date);
  }

  getDateNames(): string[] {
    return range(1, 31).map(day => String(day));
  }

  getDayOfWeek(date: Date): number {
    return parseInt(format(date, 'i'), 10);
  }

  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    const map = {
      long: 'EEEE',
      short: 'E..EEE',
      narrow: 'EEEEE'
    };

    const formatStr = map[style];
    const date = new Date();

    return range(0, 6).map(month => format(setDay(date, month), formatStr));
  }

  getFirstDayOfWeek(): number {
    return WEEK_STARTS_ON;
  }

  getMonth(date: Date): number {
    return getMonth(date);
  }

  getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
    const map = {
      long: 'LLLL',
      short: 'LLL',
      narrow: 'LLLLL'
    };

    const formatStr = map[style];
    const date = new Date();

    return range(0, 11).map(month => format(setMonth(date, month), formatStr));
  }

  getNumDaysInMonth(date: Date): number {
    return getDaysInMonth(date);
  }

  getYear(date: Date): number {
    return getYear(date);
  }

  getYearName(date: Date): string {
    return format(date, 'yyyy');
  }

  invalid(): Date {
    return new Date(NaN);
  }

  isDateInstance(obj: any): boolean {
    return obj instanceof Date;
  }

  isValid(date: Date): boolean {
    return date instanceof Date && !isNaN(date.getTime());
  }

  parse(value: any, parseFormat: any): Date | null {
    return parse(value, parseFormat, new Date());
  }

  toIso8601(date: Date): string {
    return date.toISOString();
  }

  today(): Date {
    return new Date();
  }

  deserialize(value: any): Date | null {
    if (value) {
      if (typeof value === 'string') {
        const fullDate = parse(value, FULL_DATE_INPUT_FORMAT, new Date());
        const monthYearDate = parse(value, MONTH_YEAR_INPUT_FORMAT, new Date());

        return isValid(fullDate)
          ? fullDate
          : isValid(monthYearDate)
          ? monthYearDate
          : null;
      } else if (typeof value === 'number') {
        return new Date(value);
      } else if (value instanceof Date) {
        return this.clone(value as Date);
      }
    }

    return null;
  }
}
