import { Component, forwardRef } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import {
  EvaluatingInputData,
  TotalSelectionInputData,
  EvaluateTemplateData,
  EvaluatingEntry
} from '.';
import txt from '!!raw-loader!./index.ts';
import { TemplateInput } from '../../../../common/interfaces/module.interface';

@Component({
  selector: 'evaluate',
  templateUrl: 'evaluate.component.html',
  styleUrls: ['./evaluate.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => EvaluateComponent)
    }
  ]
})
export class EvaluateComponent extends TemplateComponent {
  params = txt;
  contentData: EvaluateTemplateData['template_params_json'];

  inputInstance: TemplateInput;
  objects: Partial<EvaluatingInputData>;
  criteria: Partial<EvaluatingInputData>;
  model: EvaluatingInputData | TotalSelectionInputData;

  evaluatingInputContent: EvaluatingInputData;
  defaultValue: number;
  ratingsValues: number[] = [];
  selectedObjectKey: string;

  getDescription() {
    return `<p>This template allows a user to evaluate some objects by criteria on Step 1 (evaluation), get a summary 
            evaluation on Step 2 (selection from totals) and choose one from objects, and show selected company
             by user on Step 3 (Review).</p>
            <p>Step 1 should have linked steps
            (with the List Entry template), an object suffix and a criteria suffix appropriated to linked steps, also 
            you can set min and max limit of rating for evaluation.</p>
            <p>Step 2 should be linked with appropriated Step 1, objects step and criteria step.</p>
            <p>Step 2 has to contain set input suffix of Step 1 in "Evaluating Sufix" field and suffix of objects and criteria steps.</p>
            <p>Suffixes of Step 1 and 2 should be different.</p>
            <p>Suffix of Step 3 should be the same as Step 2.</p>
`;
  }

  getName() {
    return 'Evaluate';
  }

  getGroup() {
    return 'Generic';
  }

  init() {
    super.init();
    this.contentData = this.data.data
      .template_params_json as EvaluateTemplateData['template_params_json'];
    this.inputInstance = this.getInput(
      this.data.data.template_component,
      1,
      '',
      this.contentData.input_sufix || ''
    );

    this.model =
      !this.contentData.step_type_select ||
      this.contentData.step_type_select === 'evaluating'
        ? this.getEvaluatingInputData()
        : this.getTotalSelectionInputData();

    this.contentData.min_rate = this.contentData.min_rate || 1;
    this.contentData.max_rate = this.contentData.max_rate || 5;

    for (
      let i = 0;
      i <= this.contentData.max_rate - this.contentData.min_rate;
      i++
    ) {
      const val = (Number(this.contentData.min_rate) + i).toString();
      this.ratingsValues.push(Number(val));
    }

    this.defaultValue = Math.min(...this.ratingsValues);

    for (const key of Object.keys(this.inputs)) {
      if (key.endsWith(this.contentData.object_sufix)) {
        this.objects = this.getDataForEvaluate(this.inputs[key].content);
      } else if (key.endsWith(this.contentData.criteria_sufix)) {
        this.criteria = this.getDataForEvaluate(this.inputs[key].content);
      } else if (key.endsWith(this.contentData.evaluating_sufix)) {
        this.evaluatingInputContent =
          this.inputs[key].content &&
          (JSON.parse(this.inputs[key].content) as EvaluatingInputData);
      }
    }
    this.selectedObjectKey = this.model && Object.keys(this.model)[0];
  }

  getDataForEvaluate(json: string): Partial<EvaluatingInputData> {
    const parsedJSON = JSON.parse(json) as EvaluatingEntry;
    const resultObject =
      parsedJSON &&
      Object.entries(parsedJSON).reduce(
        (acc, [key, value]) =>
          value.option ? { ...acc, [key]: { title: value.option } } : acc,
        {}
      );

    return (
      resultObject && Object.keys(resultObject).length !== 0 && resultObject
    );
  }

  onModelChange(model: EvaluatingInputData | TotalSelectionInputData) {
    this.setEvaluateInputData(model);
  }

  evaluateComponentInitialized(model: EvaluatingInputData) {
    this.setEvaluateInputData(model);
  }

  private setEvaluateInputData(
    evaluateInputData: EvaluatingInputData | TotalSelectionInputData
  ) {
    this.inputInstance.content = JSON.stringify(evaluateInputData);
    this.contentChanged(this.inputInstance);
  }

  private getEvaluatingInputData() {
    return JSON.parse(
      this.inputInstance.getValue() || '{}'
    ) as EvaluatingInputData;
  }

  private getTotalSelectionInputData() {
    return JSON.parse(
      this.inputInstance.getValue() || '{}'
    ) as TotalSelectionInputData;
  }
}
