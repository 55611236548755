import { Component, ElementRef, forwardRef, ViewChild } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import { FileData, FileUploaderTemplateData } from '.';
import txt from '!!raw-loader!./index.ts';
import { TemplateInput } from '../../../../common/interfaces/module.interface';
import { Observable, of } from 'rxjs';
import {
  imageExtensions,
  videoExtensions
} from '../../../../common/components/file-uploader/file-uploader.component';

@Component({
  selector: 'app-file-uploader-template',
  templateUrl: './file-uploader-template.component.html',
  styleUrls: ['./file-uploader-template.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => FileUploaderTemplateComponent)
    }
  ]
})
export class FileUploaderTemplateComponent extends TemplateComponent {
  @ViewChild('customFile', { static: true }) fileInput: ElementRef;

  contentData: FileUploaderTemplateData['template_params_json'];
  params = txt;

  fileInputControl: TemplateInput;
  acceptedTypesArray: string[];
  maximumFileSizeMB = 25;
  builderErrorMessage: string;
  customUploaderFileMessage: string;
  waitingForUploadingFileMessage: string;
  fileData: FileData;

  getDescription(): string {
    return 'File uploader';
  }

  getName(): string {
    return 'File uploader';
  }

  getGroup(): string {
    return 'Generic';
  }

  hasInputs(): boolean {
    return true;
  }

  init() {
    this.contentData = this.data.data
      .template_params_json as FileUploaderTemplateData['template_params_json'];

    this.fileInputControl = this.getInput(
      'file_uploader',
      this.data.data.instance_index + 1,
      null,
      this.contentData.input_sufix
    );

    this.customUploaderFileMessage =
      this.contentData.custom_uploader_file_message ||
      'Click to choose a file or drag it here';
    this.waitingForUploadingFileMessage =
      this.contentData.waiting_for_uploading_file_message ||
      'Your file is uploading. Please wait...';

    if (this.contentData.maximumFileSizeMb) {
      this.contentData.maximumFileSizeMb = Math.abs(
        this.contentData.maximumFileSizeMb
      );

      if (this.contentData.maximumFileSizeMb !== 0) {
        this.maximumFileSizeMB = this.contentData.maximumFileSizeMb;
      }
    }

    const clearStringWithExtensions = this.contentData.fileExtensions?.trim();
    if (clearStringWithExtensions?.length) {
      this.acceptedTypesArray = clearStringWithExtensions
        .split(',')
        .map(type => type.trim().toLowerCase());
    }

    this.fileData = JSON.parse(this.fileInputControl?.content || null);
  }

  validate(): Observable<boolean> {
    if (this.contentData.file_is_required) {
      return of(!!this.fileData);
    } else {
      return of(true);
    }
  }

  showFormatValidationError() {
    let formats = '';

    if (this.contentData.filetype_select === 'Image') {
      formats = imageExtensions.join(', ');
      this.snackBarService.error(
        `Incorrect file extension. Only ${formats} extensions are allowed`
      );
    } else if (this.contentData.filetype_select === 'Video') {
      formats = videoExtensions.join(', ');
      this.snackBarService.error(
        `Incorrect file extension. Only ${formats} extensions are allowed`
      );
    } else if (this.contentData.filetype_select === 'Custom') {
      formats = this.acceptedTypesArray.join(', ');
      this.snackBarService.error(
        `Incorrect file extension. Only ${formats} extensions are allowed!`
      );
    } else if (this.contentData.filetype_select === 'Spreadshset') {
      this.snackBarService.error(
        `Incorrect file extension. Please upload an (XLS) spreadsheet!`
      );
    } else {
      console.warn('Format of file is not configured in the builder');
      this.snackBarService.error(
        `Unexpected error, contact administrator, please!`
      );
    }
  }

  saveFileInput(fileData: FileData): void {
    this.fileInputControl.content = JSON.stringify(fileData);
    this.saveInput(this.fileInputControl).subscribe();
  }
}
