import { Pipe, PipeTransform } from '@angular/core';
import { isSameDay, isSameYear, format, isValid } from 'date-fns';
import { FormatDatePipe } from './fomartdate.pipe';

@Pipe({
  name: 'readabledate'
})
export class ReadableDatePipe implements PipeTransform {
  constructor(private formatDatePipe: FormatDatePipe) {}

  transform(date: string): string {
    const fullDate = this.formatDatePipe.parse(date);

    if (!isValid(fullDate)) {
      return '';
    } else if (isSameDay(new Date(), fullDate)) {
      return 'Today';
    } else if (isSameYear(new Date(), fullDate)) {
      return format(fullDate, 'MMM d');
    } else {
      return format(fullDate, 'MMM d, yyyy');
    }
  }
}
