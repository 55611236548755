import { Component, forwardRef, QueryList, ViewChildren } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import { CompensationAndQuotaTemplateData } from '.';
import txt from '!!raw-loader!./index.ts';
import { timer } from 'rxjs';

@Component({
  selector: 'compensation-and-quota',
  templateUrl: 'compensation-and-quota.component.html',
  styleUrls: ['./compensation-and-quota.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => CompensationAndQuotaComponent)
    }
  ]
})
export class CompensationAndQuotaComponent extends TemplateComponent {
  @ViewChildren('textAreaElement') textareas: QueryList<any>;

  params = txt;
  contentData: CompensationAndQuotaTemplateData['template_params_json'];
  inputData: any;

  frequencyOptions = ['weekly', 'monthly', 'quarterly', 'annually'];
  determineOptions = ['week', 'month', 'quarter', 'year'];

  readonly currencyInputType: { [key: string]: boolean } = {
    Calculate: true,
    Quota: true,
    'On Target Earnings (OTE)': true,
    'Total Base/Fixed': true,
    'Total Incentive/Variable': true,
    'Total Incentive': true,
    'Target Sales Revenue': true,
    'Total Quota with Oversubscription': true,
    'Baseline Quota Per Rep': true,
    'Quota Running Total': true
  };

  protected init() {
    super.init();
    this.contentData = this.data.data
      .template_params_json as CompensationAndQuotaTemplateData['template_params_json'];
    this.inputData = this.getExtraInputData(this.input.getValue());
    this.updateRow();
  }

  getExtraInputData(inputValue: string) {
    switch (this.contentData.step_type_select) {
      case 'metrics_per_position':
        return this.buildMetricsPerPositionData(
          inputValue,
          JSON.parse(this.getInput('matrix_entry_1').content)
        );

      case 'pay_mix':
        return this.buildPayMixData(
          inputValue,
          JSON.parse(this.getInput('expand_data_1_ote').content)
        );

      case 'calculate_baseline':
        return this.buildBaselineData(
          inputValue ? JSON.parse(inputValue) : null,
          JSON.parse(
            this.getInput('compensation_and_quota_1_metrics_per_position')
              .content
          ),
          JSON.parse(this.getInput('matrix_entry_1').content)
        );

      case 'personalize_quotas':
        return this.buildPersonalizeQuotasData(
          inputValue ? JSON.parse(inputValue) : null,
          JSON.parse(
            this.getInput('compensation_and_quota_1_metric_details').content
          ),
          JSON.parse(
            this.getInput('expand_data_1_territory_allocation').content
          ),
          JSON.parse(this.getInput('compensation_and_quota_1_pay_mix').content),
          JSON.parse(
            this.getInput('compensation_and_quota_1_calculate_baseline').content
          )
        );

      case 'metrics':
        return this.buildMetricsData(
          inputValue,
          JSON.parse(this.getInput('matrix_entry_1').content),
          JSON.parse(
            this.getInput('compensation_and_quota_1_metrics_per_position')
              .content
          )
        );

      case 'metric_details':
        return this.buildMetricsDetailsData(
          inputValue,
          this.getInput('compensation_and_quota_1_metrics').getValue(),
          JSON.parse(this.getInput('matrix_entry_1').content),
          JSON.parse(
            this.getInput('compensation_and_quota_1_metrics_per_position')
              .content
          ),
          JSON.parse(
            this.getInput('compensation_and_quota_1_calculate_baseline').content
          )
        );

      case 'review_comp_plan':
        return this.buildReviewCompPlanData(
          JSON.parse(this.getInput('compensation_and_quota_1_pay_mix').content),
          JSON.parse(this.getInput('expand_data_1_jobs').content),
          JSON.parse(
            this.getInput('compensation_and_quota_1_metric_details').content
          ),
          JSON.parse(
            this.getInput('compensation_and_quota_1_personalize_quotas').content
          )
        );

      case 'determine_blocks':
        return this.buildDetermineBlocksData(
          inputValue,
          JSON.parse(this.getInput('matrix_entry_1').content),
          JSON.parse(this.getInput('compensation_and_quota_1_metrics').content)
        );

      case 'ramp_percentages':
        return this.buildRampPercentagesData(
          inputValue,
          this.getInput('compensation_and_quota_1_determine_blocks').getValue(),
          JSON.parse(this.getInput('matrix_entry_1').content),
          JSON.parse(this.getInput('compensation_and_quota_1_metrics').content)
        );

      default:
        return [];
    }
  }

  buildRampPercentagesData(
    inputValue: string,
    determineInput,
    jobData,
    metricsData
  ) {
    const input = inputValue ? JSON.parse(inputValue) : null;
    const determineBlocksData = this.buildDetermineBlocksData(
      determineInput,
      jobData,
      metricsData
    );

    determineBlocksData.forEach((job, jobIdx) => {
      job.products.forEach((product, prodIdx) => {
        product.metrics.forEach((metric, metricIdx) => {
          if (metric.numberOfBlocks) {
            metric.rampPercentages = [];
            for (let i = 0; i < Math.min(20, metric.numberOfBlocks); i++) {
              const checkInput =
                input &&
                input[jobIdx] &&
                input[jobIdx].products &&
                input[jobIdx].products[prodIdx] &&
                input[jobIdx].products[prodIdx].metrics &&
                input[jobIdx].products[prodIdx].metrics[metricIdx] &&
                input[jobIdx].products[prodIdx].metrics[metricIdx]
                  .rampPercentages &&
                input[jobIdx].products[prodIdx].metrics[metricIdx]
                  .rampPercentages[i];

              metric.rampPercentages.push({
                title: `Block ${i + 1}`,
                threshold: checkInput
                  ? input[jobIdx].products[prodIdx].metrics[metricIdx]
                      .rampPercentages[i].threshold
                  : null,
                guaranteedIncentive: checkInput
                  ? input[jobIdx].products[prodIdx].metrics[metricIdx]
                      .rampPercentages[i].guaranteedIncentive
                  : null
              });
            }
          }
        });
      });
    });

    return determineBlocksData;
  }

  buildDetermineBlocksData(inputValue: string, jobData, metricsData) {
    const input = inputValue ? JSON.parse(inputValue) : null;
    const determineBlocksData = [];

    const uniqueJob = new Set();
    jobData.forEach(item => uniqueJob.add(item[0].data));

    [...uniqueJob].forEach((job, index) => {
      determineBlocksData.push({ job, products: [] });

      metricsData.forEach(person => {
        if (person.position === job) {
          person.products.forEach((product, idx) => {
            determineBlocksData[index].products.push({
              title: product.title,
              metrics: []
            });

            product.metrics.forEach((metric, i) => {
              const checkInput =
                input &&
                input[index] &&
                input[index].products[idx] &&
                input[index].products[idx].metrics[i];

              if (
                metric &&
                !determineBlocksData[index].products[idx].metrics.find(
                  m => m.title === metric
                )
              ) {
                determineBlocksData[index].products[idx].metrics.push({
                  title: metric,
                  rampBlocks: checkInput
                    ? input[index].products[idx].metrics[i].rampBlocks
                    : null,
                  numberOfBlocks: checkInput
                    ? input[index].products[idx].metrics[i].numberOfBlocks
                    : null
                });
              }
            });
          });
        }
      });
    });

    return determineBlocksData;
  }

  buildReviewCompPlanData(
    payMixData,
    responsibilitiesData,
    metricDetailsData,
    personalizeQuotas
  ) {
    (metricDetailsData || []).map((position, positionIndex) => {
      position.persons?.map((persona, personaIndex) => {
        const respKeys = [];
        Object.keys(responsibilitiesData).forEach(key => {
          if (key.toLowerCase().includes('job')) {
            respKeys[0] = key;
          } else {
            respKeys[1] = key;
          }
        });

        const payMixIdx = payMixData[0].content.findIndex(
          el =>
            el.value.includes(persona.name) &&
            el.value.includes(persona.position)
        );

        persona.payMix = [];
        persona.personalizeQuota = {
          headTable: personalizeQuotas[positionIndex].headTable,
          mainTable:
            personalizeQuotas[positionIndex].persons[personaIndex]?.mainTable ||
            [],
          bottomTable:
            personalizeQuotas[positionIndex].persons[personaIndex]
              ?.bottomTable || []
        };
        payMixData.forEach((col, index) => {
          if (index > 0) {
            persona.payMix.push({
              title: col.title,
              value: col.content[payMixIdx]?.value
            });
          }
        });

        const jobIdx = responsibilitiesData[respKeys[0]].findIndex(
          job => job.value === persona.position
        );

        persona.responsibilities = (
          responsibilitiesData[respKeys[1]][jobIdx]?.value || ''
        )
          .split('\n')
          .filter(v => !!v)
          .map((v, i) => ({ v, i }));

        const middleId = Math.ceil(persona.responsibilities.length / 2);

        persona.responsibilities = [
          persona.responsibilities.slice(0, middleId),
          persona.responsibilities.slice(middleId)
        ];
      });
    });

    return metricDetailsData;
  }

  buildMetricsDetailsData(
    inputValue: string,
    metricsInput: string,
    personData,
    productData,
    baseLine
  ) {
    const input = inputValue ? JSON.parse(inputValue) : null;

    return this.buildMetricsData(metricsInput, personData, productData)
      .map(item => {
        return {
          position: item.position,
          persons: [item, ...item.anotherPersons]
        };
      })
      .map((data, positionIdx) => {
        data.persons = data.persons.map((item, index) => {
          const detailsData = {
            name: item.name,
            position: item.position,
            products: [],
            isEmptyTable: false
          };
          item.products.forEach((product, idx) => {
            detailsData.products.push({
              title: product.title,
              metrics: []
            });
            product.metrics.forEach((metric, i) => {
              if (metric) {
                const checkInput =
                  input &&
                  input?.[positionIdx]?.persons?.[index]?.products?.[idx]
                    ?.metrics[i]?.details;
                detailsData.products[idx].metrics.push({
                  title: metric.value,
                  totalQuota:
                    baseLine &&
                    baseLine[positionIdx]?.metrics[i]?.data[2].value,
                  tiers: checkInput
                    ? input[positionIdx].persons[index]?.products[idx]?.metrics[
                        i
                      ].tiers
                    : [],
                  details: [
                    {
                      title: 'Weight',
                      value: checkInput ? checkInput[0].value : null,
                      type: 'number'
                    },
                    {
                      title: 'Quota',
                      value: checkInput ? checkInput[1].value : null,
                      type: 'currency'
                    },
                    {
                      title: 'Target Incentive',
                      value: checkInput ? checkInput[2].value : null,
                      type: 'currency'
                    },
                    {
                      title: 'Threshold',
                      value: checkInput ? checkInput[3].value : null,
                      type: 'currency'
                    },
                    {
                      title: 'Frequency',
                      value: checkInput ? checkInput[4].value : null,
                      type: 'dropdown'
                    }
                  ]
                });
              }
            });
          });

          return detailsData;
        });

        return data;
      });
  }

  buildMetricsData(inputValue: string, personData, productData) {
    const metricsData = [];
    const input = inputValue ? JSON.parse(inputValue) : null;
    if (personData?.entries()) {
      for (const [index, item] of personData.entries()) {
        const products = [];
        let rules = [];
        const inputFound = input.filter(elm => elm.position === item[0].data);
        if (inputFound.length > 0) {
          // Verify if there are more people
          const anotherPerson = inputFound[0].anotherPersons.filter(
            person => person.name === item[1].data
          );
          rules =
            anotherPerson.length > 0
              ? anotherPerson[0].products[0].rules
              : inputFound[0].products[0].rules;
        }
        const product = productData.find(el => el.title === item[0].data);
        products.push({
          metrics: product?.metrics || [],
          rules: rules || Array(productData[index]?.metrics.length || 0)
            .fill('')
            .map((rule, idx) =>
              rules && rules.length > idx ? rules[idx] : rule
            )
        });
        const existPositionIdx = metricsData.findIndex(
          data => data.position === item[0].data
        );
        if (existPositionIdx < 0) {
          metricsData.push({
            name: item[1].data,
            position: item[0].data,
            anotherPersons: [],
            products
          });
        } else {
          metricsData[existPositionIdx].anotherPersons.push({
            name: item[1].data,
            position: item[0].data,
            products: metricsData[existPositionIdx].products
          });
        }
      }
    }

    // async set textareas height to match content
    timer(1).subscribe(() => {
      this.textareas.forEach(textarea =>
        this.adjustHeigth(textarea.nativeElement)
      );
    });

    return metricsData;
  }

  buildPersonalizeQuotasData(
    inputValue,
    metricDetails,
    allocation,
    payMix,
    baseline
  ) {
    return metricDetails?.map((detail, index) => {
      const persons = [];
      const bottomQuota = baseline[index]?.metrics.map(metric => {
        return 0 || Math.round(metric.data[4].value);
      });
      detail.persons.forEach((person, idx) => {
        const inputs = [];
        inputValue &&
          inputValue[index]?.persons[idx].mainTable.forEach(row => {
            if (row.type === 'input') {
              inputs.push(row.value);
            }
          });
        const quotas = detail.persons[idx]?.products[0]?.metrics?.map(
          (metric, metricIndex) => {
            let sum = 0;
            detail.persons.forEach(
              p => (sum += p.products[0]?.metrics[metricIndex].details[1].value)
            );
            const valueAverage = sum / detail.persons.length;

            return {
              title: `${metric.title} Quota`,
              value: inputs[metricIndex]
                ? inputs[metricIndex]
                : new Array(allocation?.Territory?.length || 1).fill(
                    valueAverage || 0
                  ),
              type: 'input'
            };
          }
        );
        const assignedAmount = quotas.length
          ? quotas.map(quota => {
              return quota.value?.reduce((acc, value) => acc + value);
            })
          : null;
        const coverage = assignedAmount
          ? assignedAmount.map((item, coverageIndex) => {
              return Math.round((item / (bottomQuota[coverageIndex] || 1)) * 100);
            })
          : null;
        const allocations = Object.keys(allocation)
          .slice(1, Object.keys(allocation).length - 1)
          .map(key => {
            return {
              title: key,
              value: allocation[key],
              type: 'array',
              hide: true
            };
          });

        persons.push({
          mainTable: [
            { title: 'Territory', value: allocation.Territory, type: 'array', hide: true },
            {
              title: 'Employee Name',
              value: person.name,
              type: 'string',
              hide: true
            },
            {
              title: detail.position,
              value: `${detail.position} - ${person.name}`,
              type: 'string'
            },
            {
              title: 'On Target Earnings',
              value: payMix[1].content[index].value,
              type: 'string'
            },
            {
              title: 'Base/Incentive Breakdown',
              value: `${payMix[2].content[index].value}/${payMix[3].content[index].value}`,
              type: 'string'
            },
            {
              title: 'Total Number of customers',
              value: payMix[1].content[index].value,
              type: 'string',
              hide: true
            }
          ]
            .concat(quotas)
            .concat(allocations)
            .concat([
              {
                title: 'Total Quota',
                value: payMix[1].content[index].value,
                type: 'string'
              }
            ]),
          bottomTable: [
            {
              title: 'Assigned amount',
              value: assignedAmount
            },
            {
              title: 'Quota',
              value: bottomQuota
            },
            { title: 'Coverage %', value: coverage }
          ]
        });
      });

      return {
        title: detail.position,
        persons,
        headTable: [
          {
            title: 'Metric',
            value: baseline[index]?.metrics.map(metric => metric.value)
          },
          {
            title: 'Target Sales Revenue',
            value: baseline[index]?.metrics.map(metric => metric.data[0].value)
          },
          {
            title: 'Quota With Oversubscription',
            value: baseline[index]?.metrics.map(metric => metric.data[2].value)
          },
          { title: 'Quota Running Total', value: bottomQuota }
        ]
      };
    });
  }

  buildBaselineData(inputValue, positions, data) {
    return positions.map((position, posIndex) => {
      position.metrics = position.metrics.map((metric, metricIndex) => {
        const inputMetric =
          inputValue && inputValue[posIndex]?.metrics?.[metricIndex];
        metric.editableData = (inputMetric && inputMetric.editableData) || [
          0,
          0
        ];
        metric.data = [
          {
            value: metric.editableData[0],
            title: 'Target Sales Revenue',
            isReadOnly: false
          },
          {
            value: metric.editableData[1],
            title: 'Oversubscription',
            isReadOnly: false
          },
          {
            value: metric.editableData[0] * (metric.editableData[1] / 100),
            title: 'Total Quota with Oversubscription',
            isReadOnly: true
          },
          {
            value: this.countSalesEmployees(position.title, data),
            title: 'Number of Sales Employees',
            isReadOnly: true
          },
          {
            value: 0,
            title: 'Baseline Quota Per Rep',
            isReadOnly: true
          }
        ];

        return metric;
      });

      return position;
    });
  }

  buildMetricsPerPositionData(inputValue: string, data) {
    const dataFormat = inputValue ? JSON.parse(inputValue) : [];

    return data.reduce((acc, curr) => {
      const exists = acc.find(el => el[0].data === curr[0].data);
      !exists && acc.push(curr);

      return acc;
    }, [])
      .map((item, index) => {
        return {
          title: item[0].data,
          metrics: dataFormat[index]?.metrics?.length
            ? dataFormat[index].metrics
            : [{ title: '', value: '' }]
        };
      });
  }

  buildPayMixData(inputValue: string, data) {
    const arrayLength =
      data && Object.keys(data).length ? data[Object.keys(data)[0]].length : 0;
    const content = JSON.parse(inputValue || null);
    const newPayMixData: {
      title: string;
      content: any[];
      isReadOnly: boolean;
      placeholder?: string;
      type?: string;
    }[] = [
      {
        title: 'Base/Fixed Percentage',
        content: new Array(arrayLength).fill(null),
        isReadOnly: false,
        placeholder: '0',
        type: 'percentage'
      },
      {
        title: 'Incentive/Variable Percentage',
        content: new Array(arrayLength).fill(0),
        isReadOnly: true,
        placeholder: '50',
        type: 'percentage'
      },
      {
        title: 'Total Base/Fixed',
        content: new Array(arrayLength).fill(null),
        isReadOnly: true,
        placeholder: '',
        type: 'currency'
      },
      {
        title: 'Total Incentive/Variable',
        content: new Array(arrayLength).fill(null),
        isReadOnly: true,
        placeholder: '',
        type: 'currency'
      }
    ];
    const dataFormat = newPayMixData.map(data => {
      const oldData = content?.find(i => i.title === data.title);

      return {
        ...data,
        content: data.content.map((v, i) => oldData?.content?.[i] || v)
      };
    });

    dataFormat.forEach(v => {
      for (let i = 0; i < arrayLength; i++) {
        if (!v.content[i]) {
          v.content[i] = { value: '' };
        }
      }
    });
    Object.keys(data)
      .reverse()
      .forEach(key => {
        dataFormat.unshift({
          title: key.replace(/<[^>]*>/g, ''),
          content: data[key],
          isReadOnly: true
        });
      });

    return dataFormat;
  }

  calculatePayMix() {
    for (const [index] of this.inputData[2].content.entries()) {
      let value = this.inputData[2].content[index]?.value;
      const OTE = this.inputData[1].content[index]?.value;

      if (!value || value < 0 || value > 100) {
        value = 0;
        this.inputData[2].content[index].value = 0;
      }

      this.inputData[3].content[index].value =
        value >= 0 && value <= 100 ? 100 - value : 0;

      this.inputData[4].content[index].value =
        value >= 0 && value <= 100 ? (value / 100) * OTE : 0;

      this.inputData[5].content[index].value =
        value >= 0 && value <= 100 ? ((100 - value) / 100) * OTE : 0;
    }
  }

  calculateBaseline() {
    this.inputData = this.inputData.map(position => {
      position.metrics = position.metrics.map(metric => {
        metric.editableData = [metric.data[0].value, metric.data[1].value];
        metric.data[2].value =
          metric.data[0].value * (metric.data[1].value / 100);
        metric.data[4].value = metric.data[2].value / metric.data[3].value;

        return metric;
      });

      return position;
    });
  }

  calculateQuotas() {
    this.inputData = this.inputData?.map(position => {
      position.persons.map(person => {
        const assignedAmount = [];
        const coverage = [];
        person.mainTable.forEach(row => {
          if (row.type === 'input') {
            assignedAmount.push(row.value.reduce((acc, value) => acc + value));
          }
        });
        assignedAmount.forEach((item, index) =>
          coverage.push(
            Math.round((item / (person.bottomTable[1].value[index] || 1)) * 100)
          )
        );
        person.bottomTable[0].value = assignedAmount;
        person.bottomTable[2].value = coverage;

        return person;
      });

      return position;
    });
  }

  removeTier(metric, tier) {
    const idx = metric.tiers.indexOf(tier);
    metric.tiers.splice(idx, 1);
    this.updateRow();
  }

  removeMetric(column, metric) {
    const idx = column.metrics.indexOf(metric);
    column.metrics.splice(idx, 1);
    this.updateRow();
  }

  addTier(metric) {
    metric.tiers.push({ title: '', value: null });
  }

  addMetric(column) {
    column.metrics.push({ title: '', value: null });
  }

  updateRow(table?: any) {
    if (table && table.hasOwnProperty('value') && !table.value) {
      table.value = 0;
    }
    if (this.contentData.step_type_select === 'pay_mix') {
      this.calculatePayMix();
    }
    if (this.contentData.step_type_select === 'calculate_baseline') {
      this.calculateBaseline();
    }
    if (this.contentData.step_type_select === 'personalize_quotas') {
      this.calculateQuotas();
    }
    this.input.content = JSON.stringify(this.inputData);
    this.contentChanged(this.input);
  }

  clearEmptyValue(table: any) {
    if (table && table.hasOwnProperty('value') && !table.value) {
      table.value = '';
    }
  }

  adjustHeigth(textarea: HTMLTextAreaElement) {
    textarea.style.height = '1px';
    textarea.style.height = `${textarea.scrollHeight}px`;
  }

  getDescription() {
    return 'Multi-step template';
  }

  getName() {
    return 'Compensation And Quota';
  }

  getGroup() {
    return 'Module';
  }

  get input() {
    return this.getInput(
      `compensation_and_quota_1_${this.contentData.step_type_select}`
    );
  }

  private countSalesEmployees(role: string, data): number {
    return data.filter(el => el[0].data === role).length
  }
}
