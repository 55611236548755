import { Component, OnInit, Input } from '@angular/core';
import { Step, Module } from 'src/app/common/interfaces/module.interface';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-step-link-editor',
  templateUrl: './step-link-editor.component.html',
  styleUrls: ['./step-link-editor.component.scss']
})
export class StepLinkEditorComponent implements OnInit {
  @Input() step: Step;
  @Input() module: Module;

  linked_ids = {};
  neededSteps: Step[] = [];
  linkedStepParentDescription = {};

  constructor(public modal: MatDialogRef<StepLinkEditorComponent>) {}

  ngOnInit() {
    this.linked_ids = (this.step.linked_ids || []).reduce((ids, id) => {
      ids[id] = true;

      return ids;
    }, {});

    const firstSteps = this.module.steps.filter(
      s => s.position < this.step.position
    );
    const partialSteps = this.module.steps.filter(s => s.is_partial);
    this.neededSteps = firstSteps.reduce((acc, current, index) => {
      acc.push(current);
      acc = acc.concat(
        partialSteps.filter(ps => ps.parent_step_id === current.id)
      );

      return acc;
    }, []);

    this.module.steps.forEach(step => {
      if (step.is_partial) {
        const parentStep = this.module.steps.find(
          item => item.id === step.parent_step_id
        );
        if (parentStep) {
          this.linkedStepParentDescription = {
            ...this.linkedStepParentDescription,
            [step.id]: parentStep.description
          };
        }
      }
    });
  }

  save() {
    this.step.linked_ids = Object.entries(this.linked_ids).reduce(
      (ids, entry) => {
        if (entry[1]) {
          ids.push(entry[0]);
        }

        return ids;
      },
      []
    );
    this.modal.close();
  }
}
