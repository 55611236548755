import { Component, forwardRef } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import { KpiSummaryTemplateData, SummaryContent } from '.';
import txt from '!!raw-loader!./index.ts';
import { ListEntry } from '../list-entry';
import { BlockData } from '../block-repeater';
import { updateBlockRepeaterFormat } from '../block-repeater/helpers';

@Component({
  selector: 'kpi-summary',
  templateUrl: 'kpi-summary.component.html',
  styleUrls: ['./kpi-summary.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => KpiSummaryComponent)
    }
  ]
})
export class KpiSummaryComponent extends TemplateComponent {
  params = txt;
  contentData: KpiSummaryTemplateData['template_params_json'];

  kpiBlocks: SummaryContent[] = [];
  columnsCount = [];

  getDescription() {
    return '<p>This template provides summary of KPI step. It requires linked KPI step.</p>';
  }

  getName() {
    return 'KPI Summary';
  }

  getGroup() {
    return 'Outputs';
  }

  init() {
    super.init();

    this.setOrientationPrintLayout('landscape');

    this.contentData = this.data.data
      .template_params_json as KpiSummaryTemplateData['template_params_json'];

    const kpiInput = this.inputs[
      Object.keys(this.inputs).find(key => key.startsWith('block_repeater'))
    ];
    const content = updateBlockRepeaterFormat(JSON.parse(kpiInput.content));
    this.kpiBlocks = this.initKPIContent(
      content.blocks.map(block => block.data)
    );
  }

  initKPIContent(blocks: BlockData[]): SummaryContent[] {
    return blocks
      .map(block => {
        const titleInput =
          Object.values(block)[0] && Object.values(Object.values(block)[0])[0];
        const title = titleInput && (titleInput.content as string);

        const entriesInput =
          Object.values(block)[1] && Object.values(Object.values(block)[1])[0];
        const entries =
          entriesInput && (JSON.parse(entriesInput.content) as ListEntry);
        const descriptions =
          entries &&
          Object.values(entries).length &&
          Object.values(entries)
            .map(entry => entry.option)
            .filter(entry => entry);

        this.columnsCount =
          this.columnsCount.length < descriptions.length
            ? new Array(descriptions.length)
            : this.columnsCount;

        return {
          title,
          descriptions
        };
      })
      .filter(content => content.title);
  }
}
