import { Component, OnInit } from '@angular/core';
import { UserService } from '../common/services/user.service';
import User from '../common/interfaces/user.model';
import { catchError, map, filter, switchMap, take, tap } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { TemplateService } from '../common/services/template.service';
import { Templates } from './riverside-step-template/templates';
import { BlockRepeaterComponent } from './riverside-step-template/templates/block-repeater/block-repeater.component';
import { BlockRepeaterWrapperComponent } from './riverside-step-template/templates/block-repeater/block-repeater-wrapper/block-repeater-wrapper.component';
import { ComposableTemplateComponent } from './riverside-step-template/templates/composable-template/composable-template.component';

@Component({
  selector: 'app-module-viewer-root',
  templateUrl: './module-viewer-root.component.html',
  styleUrls: ['./module-viewer-root.component.scss']
})
export class ModuleViewerRootComponent implements OnInit {
  me$: Observable<User>;
  me: User;

  private templates = {
    ...Templates,
    block_repeater: BlockRepeaterComponent,
    block_repeater_wrapper: BlockRepeaterWrapperComponent,
    composable_template: ComposableTemplateComponent
  };
  private environmentApi: string;

  constructor(
    private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private templateService: TemplateService
  ) {
    templateService.templates = this.templates;
  }

  ngOnInit() {
    // @ts-ignore
    let observable = of(null);
    if (environment.allowDynamicApi) {
      document.body.classList.add('pdf-export');
      this.environmentApi = environment.apiRoot;
      observable = this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        switchMap(() => this.activatedRoute.queryParams),
        tap(queryParam => {
          environment.apiRoot = queryParam.api
            ? decodeURIComponent(queryParam.api)
            : this.environmentApi;
        })
      );
      observable.subscribe();
    }
    observable.pipe(take(1)).subscribe(() => {
      this.me$ = this.userService.getAccount().pipe(
        catchError(() => {
          this.userService.redirectToLoginPage();

          return of(false);
        }),
        map(user => {
          this.userService.setMeFromData(user);
          this.me = this.userService.me;

          if (user) {
            this.userService.startCheckingSessionTime();
          }

          return this.userService.me;
        })
      );
    });
  }
}
