import { Component, forwardRef } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import {
  ReviewWithSelectionEntry,
  ReviewWithSelectionInputData,
  ReviewWithSelectionSlideConfig,
  ReviewWithSelectionSlidesConfig,
  ReviewWithSelectionTemplateData
} from '.';
import txt from '!!raw-loader!./index.ts';
import { TemplateInput } from '../../../../common/interfaces/module.interface';
import { ListEntry } from '../list-entry';

@Component({
  selector: 'review-with-selection',
  templateUrl: 'review-with-selection.component.html',
  styleUrls: ['./review-with-selection.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => ReviewWithSelectionComponent)
    }
  ]
})
export class ReviewWithSelectionComponent extends TemplateComponent {
  params = txt;

  contentData: ReviewWithSelectionTemplateData['template_params_json'];

  inputInstance: TemplateInput;
  existedContent: ReviewWithSelectionInputData;

  currentSlide = '0';
  haveSliderNavigation: boolean;

  model: ReviewWithSelectionInputData;
  areEntriesExist = false;

  getDescription() {
    return `
      <p>This template provides the ability to get entries from the Checkbox Selector template, group them into blocks
      and render like a slider.</p>
      <p>This template requires 1 input and links related to the Checkbox Selector steps which should be grouped</p>
      <p>For the grouping of entries You should add slides, their titles, and blocks. Blocks have title and input suffix.</p>
      <p>Input suffix represents inputs' name common for entries.</p>
    `;
  }

  getName() {
    return 'Review With Selection';
  }

  getGroup() {
    return 'Outputs';
  }

  set reviewWithSelectionInputData(
    reviewWithSelectionInputData: ReviewWithSelectionInputData
  ) {
    this.inputInstance.content = JSON.stringify(reviewWithSelectionInputData);
    this.contentChanged(this.inputInstance);
  }

  init() {
    super.init();
    this.contentData = this.data.data
      .template_params_json as ReviewWithSelectionTemplateData['template_params_json'];

    this.inputInstance = this.getInput(
      this.data.data.template_component,
      1,
      '',
      this.contentData.input_sufix
    );
    this.existedContent = JSON.parse(
      this.inputInstance.content
    ) as ReviewWithSelectionInputData;

    this.prepareLinkedInputs();
    this.haveSliderNavigation = Object.keys(this.model)?.length > 1;
  }

  prepareLinkedInputs() {
    if (this.contentData.slides.length) {
      let slideIndex = 0;
      this.model = this.model ? this.model : {};
      this.contentData.slides.forEach(
        (slideBlocks: ReviewWithSelectionSlidesConfig) => {
          slideIndex = slideBlocks?.linked_inputs_suffixes
            ? this.prepareBlockModel(slideBlocks, slideIndex)
            : slideIndex;
        }
      );
    }
  }

  prepareBlockModel(
    slideBlocks: ReviewWithSelectionSlidesConfig,
    slideIndex: number
  ): number {
    let blockIndex: number;
    slideBlocks.linked_inputs_suffixes.forEach(
      (item: ReviewWithSelectionSlideConfig) => {
        const linkedInputInstanceValue = Object.values(
          this.inputs
        ).find(input => input.element_key.includes(item.input_sufix));

        if (linkedInputInstanceValue && linkedInputInstanceValue.content) {
          const listEntry = JSON.parse(
            linkedInputInstanceValue.content
          ) as ListEntry;
          const reviewEntries = Object.entries(listEntry).reduce(
            (acc: ReviewWithSelectionEntry, [listEntryKey, listEntryValue]) => {
              let entryValue;

              if (this.existedContent && this.existedContent[slideIndex]) {
                const existedBlockKey = Object.keys(
                  this.existedContent[slideIndex].blocks
                ).find(key => key.endsWith(item.input_sufix));

                if (existedBlockKey) {
                  entryValue = this.existedContent[slideIndex].blocks[
                    existedBlockKey
                  ]?.entries[listEntryKey]?.checked;
                }
              }

              this.areEntriesExist = listEntryValue.option
                ? true
                : this.areEntriesExist;

              return listEntryValue.option
                ? {
                    ...acc,
                    [listEntryKey]: {
                      ...listEntryValue,
                      checked: entryValue ? entryValue : false
                    }
                  }
                : acc;
            },
            {}
          );

          if (Object.keys(reviewEntries).length) {
            this.model[slideIndex] = this.model[slideIndex]
              ? this.model[slideIndex]
              : {
                  title: slideBlocks.title,
                  blocks: {}
                };

            const blocksKeys = Object.keys(this.model[slideIndex].blocks);
            const lastBlockKeyIndex =
              blocksKeys &&
              blocksKeys[blocksKeys?.length - 1]?.match(/^(\d+)_/i);
            blockIndex =
              lastBlockKeyIndex && lastBlockKeyIndex[1]
                ? Number(lastBlockKeyIndex[1]) + 1
                : 0;

            this.model[slideIndex].blocks[
              `${blockIndex}_${item.input_sufix}`
            ] = {
              blockTitle: item.block_title,
              entries: reviewEntries
            };
          }
        }
      }
    );

    if (this.model[slideIndex]) {
      slideIndex++;
    }

    return slideIndex;
  }

  onCheckboxChange() {
    this.reviewWithSelectionInputData = this.model;
  }

  toggleCurrentSlide(slideKey) {
    this.currentSlide = slideKey;
  }
}
