import {
  Directive,
  ElementRef,
  forwardRef,
  HostListener,
  Inject,
  Renderer2
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  formatDoc,
  keepOnlyTextTags,
  removeStylesFromTags,
  sanitizeHtml,
  trimEmptyLinesFromEnd
} from '../utils/html-helpers';

@Directive({
  selector:
    '[contenteditable][formControlName], [contenteditable][formControl], [contenteditable][ngModel]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContentEditableValueAccessorDirective),
      multi: true
    }
  ]
})
export class ContentEditableValueAccessorDirective
  implements ControlValueAccessor {
  constructor(
    @Inject(ElementRef) private readonly elementRef: ElementRef,
    @Inject(Renderer2) private readonly renderer: Renderer2
  ) {}

  private static processValue(value: string | null): string {
    const processed = value || '';

    return processed.trim() === '<br>' ? '' : processed;
  }

  @HostListener('paste', ['$event'])
  onPaste(event): void {
    let types;
    let pastedData;
    if (event?.clipboardData?.types && event?.clipboardData?.getData) {
      types = event.clipboardData.types;
      if (
        (types instanceof DOMStringList && types.contains('text/html')) ||
        (types.indexOf && types.indexOf('text/html') !== -1)
      ) {
        pastedData = event.clipboardData.getData('text/html');
        this.processPaste(pastedData, event);
        event.stopPropagation();
        event.preventDefault();

        return;
      }
    }
    const savedContent = document.createDocumentFragment();
    while (this.elementRef.nativeElement.childNodes.length > 0) {
      savedContent.appendChild(this.elementRef.nativeElement.childNodes[0]);
    }
    this.waitForPastedData(this.elementRef.nativeElement, savedContent);
  }

  @HostListener('input')
  onInput() {
    this.onChange(
      ContentEditableValueAccessorDirective.processValue(
        this.elementRef.nativeElement.innerHTML
      )
    );
  }

  @HostListener('blur')
  onBlur() {
    this.onTouched();
  }

  writeValue(value: string | null) {
    this.renderer.setProperty(
      this.elementRef.nativeElement,
      'innerHTML',
      ContentEditableValueAccessorDirective.processValue(value)
    );
  }

  registerOnChange(onChange: (value: string) => void) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    this.renderer.setAttribute(
      this.elementRef.nativeElement,
      'contenteditable',
      String(!disabled)
    );
  }

  private onTouched = () => {};

  private onChange: (value: string) => void = () => {};

  private processPaste(pastedData, event): void {
    const newPastedData = removeStylesFromTags(
      trimEmptyLinesFromEnd(keepOnlyTextTags(sanitizeHtml(pastedData)))
    ).trim();
    formatDoc('insertHtml', newPastedData, event.target);
  }

  private waitForPastedData(elem, savedContent): void {
    if (elem.childNodes && elem.childNodes.length > 0) {
      const pastedData = elem.innerHTML;
      elem.innerHTML = '';
      elem.appendChild(savedContent);
      this.processPaste(elem, pastedData);
    } else {
      setTimeout(() => this.waitForPastedData(elem, savedContent), 20);
    }
  }
}
