import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Observable, of } from 'rxjs';
import { ModuleService } from '../../../../common/services/module.service';
import { TemplateField } from '.';
import { map } from 'rxjs/operators';
import { fieldTypes } from '../../../constants/field-types';
import {
  Module,
  Step,
  Template
} from '../../../../common/interfaces/module.interface';
import { StepTemplateEditorComponent } from '../step-template-editor.component';
import { StepLinkEditorComponent } from '../../step-link-editor/step-link-editor.component';
import { MatDialog } from '@angular/material/dialog';
import { CKEditor4 } from 'ckeditor4-angular/ckeditor';
import { CKEditorComponent } from 'ckeditor4-angular';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons/faFileAlt';
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy';
import { ConfirmationService } from '../../../../common/services/confirmation.service';
import { ckEditorConfig } from '../../../constants/ckeditor-config';

@Component({
  selector: 'app-step-template-field',
  templateUrl: './step-template-field.component.html',
  styleUrls: ['./step-template-field.component.scss']
})
export class StepTemplateFieldComponent implements OnInit, OnChanges {
  @Input() templateFieldsData: { [key: string]: any };
  @Input() field: TemplateField;
  @Input() json: any; // @todo - a hot mess that somehow works
  @Input() change: EventEmitter<string> = new EventEmitter<string>();
  @Input() parentStep: Step;
  @Input() parent: any;
  @Input() partialSteps: Step[];
  @Input() rootLevelSteps: Step[];
  @Input() moduleData: Module;
  @Input() selectedTemplate: Template;
  @Input() templates: Template[];
  @Input() parentParameterName: string;
  @Output() jsonChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() ckConfig: CKEditor4.Config = ckEditorConfig;
  @Input() labelDescriptor: string;
  name: string;
  title: string;
  subtitle: string;
  type: TemplateField;
  // should be Observable<[string | number, string][]> but TS 3.1 doesn't like it
  selectValues$: Observable<string[][]>;

  filteredComposableTemplates: Template[];
  stepSubFieldsToHide: { [key: string]: boolean }[] = [];
  jsonPreviousValue: string;

  hasSubFields = false;
  isNestedArray = false;
  showToggleTextIcon = false;

  faFile = faFileAlt;
  faLink = faLink;
  faTrash = faTrashAlt;
  faCopy = faCopy;

  subSteps: Step[] = [];

  private inputTypes = [
    ['textarea', 'textarea'],
    ['input', 'single line text input'],
    ['number', 'number input'],
    ['date', 'date input'],
    ['dropdown', 'dropdown select']
  ];

  private fieldsOnlyCkEditor: string[] = [
    'description',
    'content',
    'instructions',
    'json'
  ];

  constructor(
    private moduleService: ModuleService,
    private modalService: MatDialog,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit() {
    this.name = this.field[0];
    this.type = this.field.length > 2 ? this.field.slice(1) : this.field[1];
    if ('string' === this.type) {
      // in case we still have some JSON data
      if (typeof this.json === 'object' && this.json !== null) {
        this.json = JSON.stringify(this.json);
        this.type = 'json';
      } else if (
        fieldTypes.includes(this.name) ||
        this.name.toLowerCase().indexOf('url') > -1 ||
        this.name.endsWith('_sufix')
      ) {
        this.type = 'text-input';
      } else {
        this.json = this.json || '';
      }
    }

    if (this.type === 'Module') {
      this.type = 'select';
      this.selectValues$ = this.moduleService
        .getModules()
        .pipe(
          map(modules =>
            modules.map(module => [module.id.toString(), module.name])
          )
        );
    }

    if (this.type === 'inputType') {
      this.type = 'select';
      this.selectValues$ = of(this.inputTypes);
    }

    if (this.type instanceof Array) {
      if (this.name.substr(-7) === '_select') {
        this.selectValues$ = of(this.type.map(val => [val, val]));
        this.type = 'select';
      } else if (this.name.substr(-7) === '_blocks') {
        this.type = 'blocks';
        this.subSteps = this.getSubSteps();
      } else {
        this.hasSubFields = true;

        if (!(this.json instanceof Array)) {
          this.json = [{}];
        }
        this.prepareSubFieldsToHide(this.json);
        this.isNestedArray = this.type.some((item: any) => {
          if (item instanceof Array) {
            return item.some(field => field instanceof Array);
          }

          return false;
        });
      }
    }

    if (this.type === 'Step' || this.type.includes('Step')) {
      this.type = 'steps';
    }

    if (this.name.substr(0, 11) === 'apiResource') {
      this.type = 'select';
      this.selectValues$ = this.moduleService
        .getTemplateResources(0, 'spreadsheet')
        .pipe(
          map(resources => resources.map(resource => [resource, resource]))
        );
    }

    if (
      this.type === 'string' &&
      !this.fieldsOnlyCkEditor.includes(this.name)
    ) {
      this.showToggleTextIcon = true;
      if (!this.isHTML(this.json)) {
        this.type = 'text-input';
      } else if (this.checkOnlyPTag(this.json)) {
        this.type = 'text-input';
        this.json = this.replacePTag(this.json);
      }
    }

    this.templates = this.templates || [];

    this.filteredComposableTemplates = this.templates;

    this.title = this.labelDescriptor
      ? this.parseFieldName(this.labelDescriptor)
      : this.parseFieldName(this.name.split('_sub_')[0]);
    this.subtitle = this.parseFieldName(this.name.split('_sub_')[1]);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.templateFields) {
      this.prepareSubFieldsToHide(this.json);
    }
  }

  addPartialStep(): void {
    this.partialSteps.push(this.newStep());
    this.subSteps = this.getSubSteps();
  }

  deletePartialStep(stepIndex: number): void {
    this.confirmationService
      .removeDialog({
        text: 'this block'
      })
      .subscribe(() => {
        const partialStepIndex = this.partialSteps.findIndex(
          partialStep =>
            partialStep.step_index === this.subSteps[stepIndex].step_index
        );
        this.partialSteps.splice(partialStepIndex, 1);
        this.subSteps = this.getSubSteps();
        this.valueChange();
      });
  }

  copyPartialStep(stepIndex: number): void {
    this.partialSteps.push({ ...this.subSteps[stepIndex] });
    this.subSteps = this.getSubSteps();
  }

  editPartialStepTemplate(index: number) {
    const modalRef = this.modalService.open(StepTemplateEditorComponent, {
      panelClass: 'second-step-template-editor-modal',
      backdropClass: 'second-step-template-editor-modal-background',
      maxHeight: '90vh'
    });

    modalRef.componentInstance.step = this.subSteps[index];
    modalRef.componentInstance.rootLevelSteps = this.rootLevelSteps;
    modalRef.componentInstance.partialSteps = this.partialSteps;
    modalRef.componentInstance.moduleData = this.moduleData;
  }

  linkPartialStep(index: number) {
    const modalRef = this.modalService.open(StepLinkEditorComponent, {
      panelClass: 'step-link-editor-modal',
      maxHeight: '90vh'
    });

    modalRef.componentInstance.step = this.subSteps[index];
    modalRef.componentInstance.module = this.moduleData;
  }

  nestedChange(json, nestedJson, idx: number, field: TemplateField) {
    this.jsonChange.emit(json);
    const changedJson = [...json];
    changedJson[idx][field[0]] = nestedJson;
    this.prepareSubFieldsToHide(changedJson);
  }

  valueChange() {
    if ('input_sufix' === this.name) {
      this.json = this.json.replace(/[\W]+/g, '');
    }
    this.jsonPreviousValue = null;
    this.jsonChange.emit(this.json);
    this.prepareSubFieldsToHide(this.json);
  }

  sourceModeInputChange(ckEditor: CKEditorComponent, info: Event): void {
    if (ckEditor.instance.mode === 'source') {
      this.editorValueChange((info.target as HTMLInputElement).value);
    }
  }

  editorValueChange(info: CKEditor4.EventInfo | string): void {
    if (typeof info === 'string') {
      this.json =
        'input_sufix' === this.name ? info.replace(/[\W]+/g, '') : info;
      this.jsonChange.emit(this.json);
    }
  }

  onClickAddSubField() {
    this.json.push({});
    this.prepareSubFieldsToHide(this.json);
  }

  onClickRemoveSubField(idx: number) {
    this.confirmationService.removeDialog().subscribe(() => {
      this.json.splice(idx, 1);
      this.valueChange();
    });
  }

  onSubValueDrop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.json, event.previousIndex, event.currentIndex);
  }

  onPartialStepDrop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.subSteps, event.previousIndex, event.currentIndex);
    const values: {
      position: number;
      step_index: number;
    }[] = this.subSteps
      .map(subStep => ({
        position: subStep.position,
        step_index: subStep.step_index
      }))
      .sort((valueA, valueB) => valueA.step_index - valueB.step_index);
    this.subSteps.forEach((subStep, index) => {
      subStep.position = values[index].position;
      subStep.step_index = values[index].step_index;
    });
    this.partialSteps.sort(
      (partialStepA, partialStepB) =>
        partialStepA.step_index - partialStepB.step_index
    );
    this.subSteps = this.getSubSteps();
  }

  filterComposableTemplates(query) {
    this.filteredComposableTemplates = this.templates.filter(template =>
      template.name.toLowerCase().includes(query.toLowerCase())
    );
  }

  toggleTextFormatType(): void {
    if (this.type === 'string') {
      this.jsonPreviousValue = this.json;
      this.json = this.removeTagsFromHTML(this.convertHTMLEntity(this.json));
    } else {
      this.json = this.jsonPreviousValue ? this.jsonPreviousValue : this.json;
    }

    this.type = this.type === 'string' ? 'text-input' : 'string';
  }

  isHTML(str: string): boolean {
    return /<\/?[a-z][\s\S]*>/i.test(str);
  }

  isFieldHidden(idx, field): boolean {
    return !!this.stepSubFieldsToHide?.[idx]?.[field[0]];
  }

  removeTagsFromHTML(html: string): string {
    return html.replace(/<[^>\s]+>/g, '');
  }

  convertHTMLEntity(text: string): string {
    const span = document.createElement('span');

    return text.replace(/&[#A-Za-z0-9]+;/gi, entity => {
      span.innerHTML = entity;

      return span.innerText;
    });
  }

  checkOnlyPTag(str: string): boolean {
    return !this.isHTML(this.replacePTag(str));
  }

  replacePTag(str: string): string {
    const replaceOpenTag =
      str.substring(0, 3) === '<p>' ? str.substring(3) : str;

    return replaceOpenTag.substring(
      replaceOpenTag.length - 4,
      replaceOpenTag.length
    ) === '</p>'
      ? replaceOpenTag.substring(0, replaceOpenTag.length - 4)
      : replaceOpenTag;
  }

  private parseFieldName(title: string): string {
    if (!title) {
      return;
    }

    return title
      .split(/(?=[A-Z])/)
      .join(' ')
      .split('_')
      .map(word => word[0].toUpperCase() + word.substr(1))
      .join(' ');
  }

  private newStep(): Step {
    const allSteps = this.partialSteps.concat(this.partialSteps);
    const { lastPosition, lastStepIndex } = allSteps.reduce(
      (value, step) => {
        value.lastPosition =
          value.lastPosition < step.position
            ? step.position
            : value.lastPosition;
        value.lastStepIndex =
          value.lastStepIndex < step.step_index
            ? step.step_index
            : value.lastStepIndex;

        return value;
      },
      { lastPosition: 0, lastStepIndex: 0 }
    );

    return {
      description: '',
      is_section_break: false,
      id: 0,
      is_partial: true,
      parent_step_id: this.parentStep.id,
      module_id: this.moduleData.id,
      position: lastPosition + 1,
      step_index: lastStepIndex + 1,
      template_params_json: {},
      template_component: '',
      is_request_feedback: false,
      is_printable: false
    };
  }

  private getSubSteps(): Step[] {
    return this.partialSteps
      .filter(step => step.parent_step_id === this.parentStep.id)
      .sort((stepA, stepB) => stepA.position - stepB.position);
  }

  private prepareSubFieldsToHide(json): void {
    if (this.selectedTemplate) {
      if (this.selectedTemplate.id === 'checkbox' && this.name === 'options') {
        this.stepSubFieldsToHide = json.map(item => {
          const isShowTitle = this.parent?.show_title;
          const isShowDescription = this.parent?.show_descriptions;

          return {
            title: !isShowTitle && !this?.parent?.allowUserCustomQuestions,
            description:
              !isShowDescription && !this?.parent?.allowUserCustomQuestions
          };
        });
      }
      if (this.selectedTemplate.id === 'matrix_entry') {
        if (this.name === 'options') {
          this.stepSubFieldsToHide = json.map(item => {
            const isTextType =
              item.type_select && item.type_select === '1_text';
            const isTextareaType =
              item.type_select && item.type_select === '2_textarea';
            const isNumberBoxesType =
              item.type_select && item.type_select === '3_number';
            const isCheckBoxType =
              item.type_select && item.type_select === '4_checkbox';
            const isLabelType =
              item.type_select && item.type_select === '6_label';
            const isDropdownType =
              item.type_select && item.type_select === '7_dropdown';
            const isRadioType =
              item.type_select && item.type_select === '8_radio';
            const isCheckBoxesType =
              item.type_select && item.type_select === '9_checkboxes';
            const isDateType =
              item.type_select && item.type_select === '5_date';
            const isShowFooterRow = this.parent?.show_footer_row;
            const isShowAPI = item.use_api_values;

            return {
              use_api_values: !isDropdownType,
              matrix_api_url: !isShowAPI,
              api_param: !isShowAPI,
              matrix_dropdown: !(!isShowAPI && isDropdownType),
              sort_rows_by_dropdown_value: !isDropdownType,
              radio_group: !isRadioType,
              radio_value: !isRadioType,
              is_total: !(
                isNumberBoxesType ||
                isCheckBoxesType ||
                isCheckBoxType
              ),
              footer_row_label: !isShowFooterRow,
              footer_cell_colspan: !isShowFooterRow,
              checkboxes: !isCheckBoxesType,
              placeholder: !(
                isTextType ||
                isTextareaType ||
                isNumberBoxesType ||
                isDateType
              ),
              currency_input: !isNumberBoxesType,
              required: isLabelType,
              input_min_rows_height: !isTextareaType,
              input_max_rows_height: !isTextareaType,
              min_value: !isNumberBoxesType,
              max_value: !isNumberBoxesType
            };
          });
        } else if (this.name === 'matrix_element') {
          this.stepSubFieldsToHide = json.map(item => {
            const defaultDataFromInput = !!item.use_value_from_input;

            return {
              linked_input_name: !defaultDataFromInput,
              value_path: !defaultDataFromInput
            };
          });
        }
      }
      if (this.selectedTemplate.id === 'expand_data') {
        if (
          this.name === 'columns' ||
          this.name === 'custom_columns' ||
          this.name === 'types_for_custom_columns'
        ) {
          this.stepSubFieldsToHide = json.map(item => {
            const isDropdownType =
              item.type_select && item.type_select === 'dropdown';
            const isCheckboxesType =
              item.type_select && item.type_select === 'checkboxes';
            const isInputType =
              item.type_select && item.type_select === 'input';
            const isTextareaType =
              item.type_select && item.type_select === 'textarea';
            const isNumberType =
              item.type_select && item.type_select === 'number';
            const isIce = item.type_select && item.type_select === 'ice';
            const isMultipleTextareas =
              item.type_select && item.type_select === 'multiple_textareas';
            const isTakeInputValues =
              item.take_input_values_as_dropdown_options;

            return {
              take_input_values_as_dropdown_options: !isDropdownType,
              dropdown_options: !isDropdownType || isTakeInputValues,
              checkboxes: !isCheckboxesType,
              placeholder: !(isInputType || isTextareaType || isNumberType),
              currency_input: !isNumberType,
              multi_line: !isIce,
              number_of_pregenerated_items: !isMultipleTextareas,
              show_add_action_button: !isMultipleTextareas,
              add_action_button_label: !isMultipleTextareas
            };
          });
        }
        if (this.name === 'inputs_to_expand') {
          this.stepSubFieldsToHide = json.map(item => {
            const isBlockRepeaterPart = !!item.is_part_of_block_repeater;

            return {
              block_repeater_input_key: !isBlockRepeaterPart
            };
          });
        }
      }
      if (this.selectedTemplate.id === 'radio_button') {
        if (this.name === 'options') {
          this.stepSubFieldsToHide = json.map(item => {
            const isTitleAndDescription =
              this.parent.step_select === 'TitleAndDescription';
            const isTitleDescriptionAndImage =
              this.parent.step_select === 'TitleDescriptionAndImage';
            const isTitleButtonsAndInput =
              this.parent.step_select === 'TitleButtonsAndInput';

            return {
              image: !isTitleDescriptionAndImage,
              description: !(
                isTitleAndDescription || isTitleDescriptionAndImage
              ),
              inputValue: !isTitleButtonsAndInput
            };
          });
        }
        if (this.name === 'inputs_to_expand') {
          this.stepSubFieldsToHide = json.map(item => {
            const isBlockRepeaterPart = !!item.is_part_of_block_repeater;

            return {
              block_repeater_input_key: !isBlockRepeaterPart
            };
          });
        }
      }
      if (this.selectedTemplate.id === 'brainstorm') {
        if (this.name === 'questions') {
          this.stepSubFieldsToHide = json.map(item => {
            const isFormulaType =
              item.type_select && item.type_select === 'Formula';
            const isNumbersType =
              item.type_select && item.type_select === 'Numbers';
            const isSelectType =
              item.type_select && item.type_select === 'Select';
            const isApiSource = item.api_as_data_source;
            const isDateType = item.type_select && item.type_select === 'Date';
            const isInline = item.type_select && item.type_select === 'Inline';
            const isTextarea =
              item.type_select && item.type_select === 'Textarea';
            const defaultDataFromInput = !!item.use_value_from_input_as_default;
            const loadInputEndpoint = !!item.load_input_endpoint;

            return {
              excel_formula: !isFormulaType,
              inputs_for_formula: !isFormulaType,
              formula_value_precision: !isFormulaType,
              api_as_data_source: !isSelectType,
              api_endpoint: !isSelectType || !isApiSource,
              api_fields_to_use: !isSelectType || !isApiSource,
              select_options: !isSelectType || isApiSource,
              disallow_selecting_past_dates: !isDateType,
              minValue: !isNumbersType,
              maxValue: !isNumbersType,
              currency_input: !isNumbersType,
              max_input_length: !(isInline || isTextarea || isNumbersType),
              linked_input_name: !defaultDataFromInput || loadInputEndpoint,
              value_path: !defaultDataFromInput,
              load_input_endpoint: !defaultDataFromInput,
              module_id: !(defaultDataFromInput && loadInputEndpoint),
              input_key: !(defaultDataFromInput && loadInputEndpoint),
              inputs: !defaultDataFromInput,
              inputs_separator: !defaultDataFromInput
            };
          });
        }
        if (
          this.name === 'inputs' &&
          this.parentParameterName === 'questions'
        ) {
          this.stepSubFieldsToHide = json.map(item => {
            const loadInputEndpoint = !!item.load_input_endpoint;

            return { module_id: !loadInputEndpoint };
          });
        }
      }
    }
  }
}
