import { Component, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-tabs-label-component',
  templateUrl: './tabs-label.component.html',
  styleUrls: ['./tabs-label.component.scss']
})
export class TabsLabelComponent {
  @ViewChild(TemplateRef) labelContent: TemplateRef<any>;

  constructor() {}
}
