import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModuleService } from 'src/app/common/services/module.service';
import { UserService } from 'src/app/common/services/user.service';
import User from 'src/app/common/interfaces/user.model';
import { TemplateContentData } from 'src/app/module-viewer/riverside-step-template/templates/template-data.class';
import { RiversideStepTemplateComponent } from 'src/app/module-viewer/riverside-step-template/riverside-step-template.component';
import { ModuleContentService } from 'src/app/common/services/module-content.service';
import { switchMap, catchError, filter, tap, take } from 'rxjs/operators';
import { ModuleNavService } from 'src/app/common/services/module-nav.service';
import { combineLatest, Subscription, Observable, throwError } from 'rxjs';
import { Templates } from '../riverside-step-template/templates';
import { IceService } from '../ice/ice.service';
import ModuleContent from 'src/app/common/interfaces/module-content.model';
import { LeftMenuService } from 'src/app/common/services/left-menu.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Topic } from '../riverside-step-template/module-progress-bar/module-progress-bar.component';
import { Module } from '../../common/interfaces/module.interface';

const NO_TOPIC_SYMBOL = '-';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit, OnDestroy {
  @ViewChild(RiversideStepTemplateComponent, { static: false })
  templateComponent: RiversideStepTemplateComponent;

  me: User;
  templateData: TemplateContentData;
  templateComponentName: keyof typeof Templates;
  canModify = false;
  styles = '';
  progressBarTopics: Array<Topic> = [];
  routeWatch: Subscription;
  moduleData: Module;

  moduleContent$: Observable<ModuleContent>;
  leftMenuExpanded$: Observable<boolean>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private moduleService: ModuleService,
    private userService: UserService,
    private moduleContentService: ModuleContentService,
    private navService: ModuleNavService,
    private iceService: IceService,
    private leftMenuService: LeftMenuService
  ) {}

  ngOnDestroy() {
    this.routeWatch.unsubscribe();
    if (this.templateComponent) {
      this.templateComponent.triggerStepTransition();
    }
  }

  ngOnInit() {
    this.me = this.userService.me;

    this.routeWatch = this.route.params
      .pipe(filter(params => params.stepId))
      .subscribe(
        params => (this.navService.step.current = Number(params.stepId))
      );

    this.moduleContent$ = combineLatest([
      this.navService.organization$,
      this.navService.module$,
      this.navService.step$,
      this.moduleService.moduleChanged$
    ]).pipe(
      tap(() => {
        if (this.templateComponent) {
          this.templateComponent.triggerStepTransition();
        }
      }),
      switchMap(([org, module, step]) =>
        this.moduleContentService.load(module, step, org).pipe(
          catchError((err: HttpErrorResponse) => {
            if (err.error?.code === 'MODULE_DISABLED') {
              this.router.navigate([
                'dashboard',
                this.navService.lastOrganization.current
              ]);

              return throwError(err);
            } else {
              return this.navService.moduleData$.pipe(
                take(1),
                switchMap(moduleData => {
                  const firstStep = (
                    moduleData.steps.find(
                      s =>
                        !s.is_section_break &&
                        this.navService.step.current === s.id
                    ) || moduleData.steps.find(s => !s.is_section_break)
                  ).id;
                  this.navService.goToStep(firstStep);

                  return this.moduleContentService.load(
                    moduleData.id,
                    firstStep,
                    org
                  );
                })
              );
            }
          })
        )
      ),
      tap(content => {
        this.navService.moduleDataReplay$
          .pipe(
            filter(
              module =>
                module.status.org_id ===
                this.navService.lastOrganization.current
            ),
            take(1)
          )
          .subscribe(moduleData => {
            this.moduleData = moduleData;
            if (
              !moduleData.status ||
              !moduleData.status.is_activated ||
              moduleData.status.org_id !==
                this.navService.lastOrganization.current
            ) {
              this.router.navigate([
                'dashboard',
                this.navService.lastOrganization.current
              ]);

              return;
            }

            if (
              (
                moduleData.steps.find(step => step.id === content.step_id) || {
                  isLocked: true
                }
              ).isLocked
            ) {
              this.navService.previousStep();
            }
          });
      }),
      tap(content => {
        if (
          this.moduleService.moduleChanged$.getValue() ||
          Number(content.step_id) !== Number(this.templateData?.data.step_id)
        ) {
          this.render(content);
        }
      })
    );

    this.leftMenuExpanded$ = this.leftMenuService.onExpand;
  }

  render(moduleContent: ModuleContent) {
    const { feedback_requested, feedback_started, can_modify } = moduleContent;
    const is_riverside_managing_director = this.me.permissions
      .riversideProvideFeedback;

    if (
      feedback_requested &&
      !feedback_started &&
      is_riverside_managing_director
    ) {
      this.moduleService
        .feedbackStarted({ id: moduleContent.module_id })
        .subscribe();
    }

    this.canModify = can_modify;

    this.iceService.shouldShowWarning =
      moduleContent.is_approved && !is_riverside_managing_director;

    moduleContent.disabled =
      (!is_riverside_managing_director && feedback_started) || !can_modify;

    this.templateData = new TemplateContentData({
      data: moduleContent,
      me: this.me,
      canModify: can_modify
    });

    this.templateComponentName = moduleContent.template_component as keyof typeof Templates;

    this.styles = `module_${moduleContent.module_id} step_${moduleContent.step_id}`;

    this.prepareProgressBarTopics();

    window.scrollTo(0, 0);
  }

  private prepareProgressBarTopics(): void {
    this.progressBarTopics = [];
    if (this.moduleData) {
      this.moduleData.steps.forEach(step => {
        if (step.topic && step.topic !== NO_TOPIC_SYMBOL) {
          const existentTopic = this.progressBarTopics.find(
            topic => topic.title === step.topic
          );
          if (existentTopic) {
            existentTopic.stepId.push(step.id);
          } else {
            this.progressBarTopics.push({
              title: step.topic,
              stepId: [step.id]
            });
          }
        } else if (
          step.topic === NO_TOPIC_SYMBOL &&
          this.progressBarTopics.length
        ) {
          this.progressBarTopics[this.progressBarTopics.length - 1].hiddenStep =
            step.id;
        } else if (this.progressBarTopics.length) {
          this.progressBarTopics[this.progressBarTopics.length - 1].stepId.push(
            step.id
          );
        }
      });
    }
  }
}
