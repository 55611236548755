/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { SpreadSheetCellRenderFunctionType } from '../index';

export function wrapRendererFunction(
  rendererFunction: SpreadSheetCellRenderFunctionType
): SpreadSheetCellRenderFunctionType {
  return (instance, td, row, col, prop, value, cellProperties) => {
    rendererFunction(instance, td, row, col, prop, value, cellProperties);
    wrapCellContent(instance, td, row, col, prop, value, cellProperties);
  };
}

export function wrapCellContent(
  instance,
  td,
  row,
  col,
  prop,
  value,
  cellProperties
): void {
  if (
    cellProperties.type !== 'checkbox' &&
    cellProperties.type !== 'multiple-checkboxes' &&
    cellProperties.type !== 'delete-cell'
  ) {
    const contentWrapperDiv = document.createElement('DIV');
    contentWrapperDiv.className = 'cell-content-wrapper';
    const contentValueDiv = document.createElement('DIV');
    contentValueDiv.className = 'cell-content-value';
    let contentHTML = td.innerHTML;
    if (cellProperties.type === 'numeric') {
      contentHTML = contentHTML.replace(/,/g, ',<wbr>');
    }
    contentValueDiv.innerHTML = contentHTML;
    contentWrapperDiv.appendChild(contentValueDiv);
    td.innerHTML = '';
    td.appendChild(contentWrapperDiv);
  }
}

export function wrapXFormatRenderer(
  rendererFunction: SpreadSheetCellRenderFunctionType
): SpreadSheetCellRenderFunctionType {
  return (instance, td, row, col, prop, value, cellProperties) => {
    rendererFunction(instance, td, row, col, prop, value, cellProperties);
    xFormatRenderer(instance, td, row, col, prop, value, cellProperties);
  };
}

export function xFormatRenderer(
  instance,
  td,
  row,
  col,
  prop,
  value,
  cellProperties
): void {
  let contentHTML = td.getElementsByClassName('cell-content-wrapper')[0]
    .innerHTML;

  if (cellProperties.type === 'numeric' && contentHTML) {
    if (contentHTML.slice(-1) !== 'x') {
      contentHTML = contentHTML + 'x';
    }
  }
  td.getElementsByClassName('cell-content-wrapper')[0].innerHTML = contentHTML;
}

export function wrapDynamicColorRenderer(
  rendererFunction: SpreadSheetCellRenderFunctionType
): SpreadSheetCellRenderFunctionType {
  return (instance, td, row, col, prop, value, cellProperties) => {
    rendererFunction(instance, td, row, col, prop, value, cellProperties);
    dynamicColorRenderer(instance, td, row, col, prop, value, cellProperties);
  };
}

export function dynamicColorRenderer(
  instance,
  td,
  row,
  col,
  prop,
  value,
  cellProperties
): void {
  td.style = `background-color: rgba(0, 255, 0, ${
    value / 10 >= 1 ? 1 : value / 10
  })`;
}
