import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Post } from 'src/app/common/interfaces/post.interface';
import { BlogService } from 'src/app/common/services/blog.service';
import { EditBlogComponent } from '../../blog/edit/edit.component';
import { ModuleNavService } from 'src/app/common/services/module-nav.service';
import { UserService } from 'src/app/common/services/user.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogLeftMenuComponent implements OnInit {
  @Input() width = 300;
  posts: Array<Post> = [];
  filteredPosts: Array<Post> = [];
  edit: boolean;
  tagList: string[];
  activePost = null;
  selectedSearchType: string;
  searchPage = 0;
  searchTypes: string[] = ['Topic', 'Tag'];
  searchInput = '';
  canEdit = false;
  modalMaxHeigt = '95vh';
  iconSearch: boolean;

  constructor(
    private readonly postService: BlogService,
    public navService: ModuleNavService,
    private modalService: MatDialog,
    private userService: UserService
  ) {
    this.edit = false;
    this.selectedSearchType = this.searchTypes[0];
    this.iconSearch = true;
  }

  ngOnInit(): void {
    this.getPosts();
    document.getElementById('posts-table').addEventListener('scroll', event => {
      const elm = event.target as HTMLTextAreaElement;
      const isFullyScrolled =
        elm.scrollTop + elm.clientHeight >= elm.scrollHeight;
      if (isFullyScrolled) this.getPosts();
    });
    this.userService.getAccount().subscribe(
      (user: any) => {
        this.canEdit =
          user.is_riverside_managing_director ||
          user.is_riverside_rmcf_admin ||
          user.is_super_admin;
      },
      err => console.warn
    );
  }

  getPosts(searchType: string = null, search: string = null) {
    if (searchType || search) {
      this.posts = [];
      this.selectedSearchType = searchType;
      this.searchInput = search;
      this.searchPage = 0;
      this.activePost = null;
    }
    this.postService
      .getPosts(this.selectedSearchType, this.searchInput, this.searchPage)
      .subscribe(
        (response: any) => {
          this.posts = this.posts.concat(response.posts);
          if (response.posts.length > 0) this.searchPage++;
          this.tagList = response.tags;
          // Load first post
          if (this.posts.length > 0) {
            this.loadPost(this.posts[0].id);
          } else {
            this.showNoPostsText();
          }
        },
        err => console.warn
      );
  }

  openEditorForm(id?: number, index?: number) {
    const modalRef = this.modalService.open(EditBlogComponent, {
      panelClass: 'step-template-editor-modal',
      maxHeight: this.modalMaxHeigt,
      data: { id, tags: this.tagList }
    });
    modalRef.beforeClosed().subscribe(closeResponse => {
      if (closeResponse) {
        if (closeResponse.action !== 'delete') {
          this.tagList = closeResponse.tags;
          this.loadPost(closeResponse.post.id);
          if (closeResponse.action === 'create') {
            this.posts.push(closeResponse.post);
          } else {
            this.posts[index] = closeResponse.post;
          }
        } else {
          this.posts.splice(index, 1);
          if (this.posts.length === 0) this.showNoPostsText();
        }
      } else {
      }
    });
  }

  loadPost(id: number, clicked = false) {
    if (!this.activePost || clicked) this.activePost = id;
    this.postService.getPost(id).subscribe(
      (post: any) => {
        this.postService.loadPost(post);
      },
      err => console.warn
    );
  }

  showNoPostsText() {
    document.getElementById('blog-title').innerText = '';
    document.getElementById('blog-content').innerHTML =
      '<h3 style="text-align: center">There are no posts available at this time<h3>';
  }

  onInputMethod(e: any) {
    const value = e.target.value;
    if (value) {
      this.iconSearch = false;
    } else {
      this.iconSearch = true;
    }
  }
}
