import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import ModuleContent from '../interfaces/module-content.model';
import { concat, forkJoin, merge, Observable, of } from 'rxjs';
import { last, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { MAX_CACHE_AGE, RestService } from './rest.service';
import { ModuleService } from './module.service';
import { PresignedFileUrl } from '../interfaces/account.interface';

interface StepCacheEntry {
  content: Observable<ModuleContent>;
  entryTime: number;
}

@Injectable()
export class ModuleContentService {
  baseUrl = environment.apiRoot + '/api/modules';
  stepCache = new Map<string, StepCacheEntry>();

  constructor(
    private httpClient: HttpClient,
    private rest: RestService,
    private moduleService: ModuleService
  ) {}

  private static getModuleContent(
    response,
    step,
    moduleId: number,
    stepId: number
  ): ModuleContent {
    let object;
    if (step.content) {
      object = {
        ...step.content,
        can_modify: Boolean(response.headers.get('X-Can-Modify')),
        template_params_json: step.template_params_json,
        template_component: step.template_component,
        is_printable: step.is_printable,
        linked_ids: step.linked_ids,
        is_partial: Boolean(step.is_partial),
        parent_step_id: step.parent_step_id
      };

      object.template_params_json.number_of_inputs = Number(
        object.template_params_json.number_of_inputs
      );
    } else {
      object = {
        module_id: moduleId,
        step_id: Number(stepId)
      };
    }

    return ModuleContent.fromObject(object);
  }

  loadStepData(
    moduleId: number,
    stepId: number,
    org_id: number
  ): Observable<any> {
    if (!stepId) {
      return of({});
    }

    return this.httpClient.get(
      `${this.baseUrl}/${moduleId}/org/${org_id}/step/${stepId}`,
      {
        observe: 'response'
      }
    );
  }

  loadStepsData(
    moduleId: number,
    stepsId: string,
    org_id: number
  ): Observable<any> {
    return this.httpClient.get(
      `${this.baseUrl}/${moduleId}/org/${org_id}/steps/${stepsId}`,
      {
        observe: 'response'
      }
    );
  }

  load(
    moduleId: number,
    stepId: number,
    org_id: number,
    isCacheable = false
  ): Observable<ModuleContent> {
    const response = this.rest
      .get(
        `${this.baseUrl}/${moduleId}/org/${org_id}/step/${stepId}`,
        { observe: 'response' },
        isCacheable
      )
      .pipe(
        map((fullResponse: HttpResponse<any>) =>
          ModuleContentService.getModuleContent(
            fullResponse,
            fullResponse.body,
            moduleId,
            stepId
          )
        )
      );

    return concat(this.moduleService.waitForInputsToSave(), response).pipe(
      last(),
      map(res => res as ModuleContent)
    );
  }

  loadSteps(
    moduleId: number,
    org_id: number,
    stepIds: number[],
    isCacheable = false
  ): Observable<ModuleContent[]> {
    if (!isCacheable) {
      return this.rest
        .get(
          `${this.baseUrl}/${moduleId}/org/${org_id}/steps/${stepIds.join(
            ','
          )}`,
          { observe: 'response' }
        )
        .pipe(
          map((fullResponse: HttpResponse<any>) => {
            const res = fullResponse.body;

            return stepIds.map(stepId =>
              ModuleContentService.getModuleContent(
                fullResponse,
                res[stepId],
                moduleId,
                Number(stepId)
              )
            );
          })
        );
    }
    const cacheStepIds = [];
    const requestStepIds = [];
    for (const stepId of stepIds) {
      (!!this.getCacheStep(moduleId, org_id, stepId)
        ? cacheStepIds
        : requestStepIds
      ).push(stepId);
    }
    const cachedSteps = forkJoin(
      cacheStepIds.map(stepId => this.getCacheStep(moduleId, org_id, stepId))
    );
    if (requestStepIds.length) {
      const request = this.rest
        .get(
          `${
            this.baseUrl
          }/${moduleId}/org/${org_id}/steps/${requestStepIds.join(',')}`,
          { observe: 'response' },
          true
        )
        .pipe(
          map((fullResponse: HttpResponse<any>) => {
            const res = fullResponse.body;

            return Object.keys(res).map(stepId =>
              ModuleContentService.getModuleContent(
                fullResponse,
                res[stepId],
                moduleId,
                Number(stepId)
              )
            );
          })
        );
      requestStepIds.forEach(stepId => {
        this.putCacheStep(
          moduleId,
          org_id,
          stepId,
          request.pipe(
            map((steps: ModuleContent[]) =>
              steps.find(step => step.step_id === stepId)
            )
          )
        );
      });

      return merge(request, cachedSteps);
    }

    return cachedSteps;
  }

  presignedFileUpload(
    fileName: string,
    orgId: number,
    inputId?: number,
    isVideoFile = false
  ): Observable<PresignedFileUrl> {
    const params = inputId
      ? { fileName, inputId: inputId.toString() }
      : { fileName };

    return this.httpClient.get<null>(
      `${environment.apiRoot}/api/resources/org/${orgId}/upload-${
        isVideoFile ? 'video' : 'file'
      }`,
      {
        params
      }
    );
  }

  private getCacheStep(
    moduleId: number,
    org_id: number,
    stepId: number
  ): Observable<ModuleContent> | null {
    const entry = this.stepCache.get(`${moduleId}/${org_id}/${stepId}`);
    if (!entry) {
      return null;
    }
    const isExpired = Date.now() - entry.entryTime > MAX_CACHE_AGE;

    return isExpired ? null : entry.content;
  }

  private putCacheStep(
    moduleId: number,
    org_id: number,
    stepId: number,
    content: Observable<ModuleContent>
  ): void {
    const entry: StepCacheEntry = { content, entryTime: Date.now() };
    this.stepCache.set(`${moduleId}/${org_id}/${stepId}`, entry);
    this.deleteExpiredCache();
  }

  private deleteExpiredCache() {
    this.stepCache.forEach((entry, key) => {
      if (Date.now() - entry.entryTime > MAX_CACHE_AGE) {
        this.stepCache.delete(key);
      }
    });
  }
}
