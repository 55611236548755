import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { AssessmentChartSeries, AssessmentChartActiveEntries } from '.';

@Component({
  selector: 'assessment-chart',
  templateUrl: './assessment-chart.component.html',
  styleUrls: ['./assessment-chart.component.scss']
})
export class AssessmentChartComponent implements OnInit, OnChanges {
  @Input() series: AssessmentChartSeries;
  @Input() colors: string[];
  @Input() isLineChart: boolean;
  @Input() activeEntries: AssessmentChartActiveEntries = [];
  @Output() activatedSeriesChange: EventEmitter<number> = new EventEmitter();

  results: AssessmentChartSeries;
  xAxisTickFormatting: (idx: number) => string;
  xTicks: number[];
  yTicks: number[];
  labels: { [key: number]: string } = {};
  fullLabels: { [key: number]: string } = {};
  colorScheme = { domain: ['#999', '#58ad3f'] };
  barCustomColors: { name: string; value: string }[] = [];
  seriesIndex: string[];

  private readonly labelMaxLen = 20;

  constructor() {}

  ngOnInit(): void {
    this.yTicks = Array.from(Array(11).keys()).map(k => k * 10 - 50);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.series) {
      this.prepareResults();
    }
  }

  activate(event): void {
    this.activatedSeriesChange.emit(this.seriesIndex.indexOf(event.value.name));
  }

  deactivate(): void {
    this.activatedSeriesChange.emit(null);
  }

  private prepareResults(): void {
    const modSeries: AssessmentChartSeries = this.series.map(
      (series, serIdx) => ({
        ...series,
        series: series.series.map((group, groupIdx) => {
          if (serIdx === 0) {
            this.labels[groupIdx + 1] =
              group.label.length <= this.labelMaxLen
                ? group.label
                : group.label.substr(0, this.labelMaxLen - 2) + '...';
            this.fullLabels[groupIdx + 1] = group.label;
            this.barCustomColors.push({
              name: (groupIdx + 1).toString(),
              value: group.value < 0 ? '#ff6666' : '#a9da9a'
            });
          }

          return { ...group, label: undefined };
        })
      })
    );
    const newStartSeries = {
      name: '',
      series: [
        { value: 0, name: -10 },
        { value: 0, name: modSeries[0].series.length + 10 }
      ]
    };
    this.results = [newStartSeries, ...modSeries];
    this.xTicks = Array.from(Array(modSeries[0].series.length + 2).keys()).map(
      k => k - 1
    );
    this.seriesIndex = this.results.map(series => series.name);

    if (this.colors) {
      this.colorScheme.domain = this.colors
        .concat(this.colors)
        .slice(this.colors.length - 1);
    }

    this.xAxisTickFormatting = (idx: number) => this.labels[idx] || '';
    if (!this.activeEntries) {
      this.activeEntries = [];
    }
  }
}
