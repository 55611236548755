import { TemplateContentDataBase } from '../template.interface';
import * as Handsontable from 'handsontable/dist/handsontable.min.js';
import {
  CellDefaultDataFromInput,
  HotNumericFormat,
  SpreadsheetCellData
} from '../../../../common/interfaces/spreadsheet.interface';
import { multipleCheckboxes } from './custom-types/multiple-checkboxes-type';
import { ApiDataDefaultType } from '../../../../common/interfaces/api-data.interface';

export interface SpreadsheetTemplateData extends TemplateContentDataBase {
  template_params_json: {
    description: string;
    apiResource: string;
    visibleRows: string;
    visibleCols: string;
    hideCols: string;
    title: string;
    allow_scrolling_spreadsheet_horizontally: boolean;
    disable_not_active_cell_visual_selection: boolean;
    requireFeedback: boolean;
    calculateFormulasOnServer: boolean;
    leaveTextCellsOnEnterKey: boolean;
    input_sufix: string;
    meta_sufix: string;
    allow_adding_items: boolean;
    number_of_rows_to_add_on_click: number;
    add_item_action_label: string;
    allow_deleting_items: boolean;
    deleting_row_range: string;
    enable_filtering_empty_rows: boolean;
    filtering_empty_row_range: string;
    number_of_empty_rows_by_default: number;
    specified_column_index_to_check_for_empty_rows: string;
    limit_editable_cell_range: string;
    save_default_data_in_input: boolean;
    default_data_row_range: string;
  };
}

export type SpreadsheetTemplateParams = SpreadsheetTemplateData['template_params_json'];

export const SpreadsheetCellTypes = {
  text: 'text',
  numeric: 'numeric',
  percent: 'percent',
  currency: 'currency',
  date: 'date',
  dropdown: 'dropdown',
  checkbox: 'checkbox',
  monthDropdown: 'month-dropdown',
  multipleCheckboxes,
  deleteCell: 'delete-cell',
  xFormat: 'xFormat'
} as const;

// remove after upgrading to TypeScript 3.5.1+
type Omit<T, K extends keyof T> = Pick<
  T,
  ({ [P in keyof T]: P } &
    { [P in K]: never } & { [x: string]: never; [x: number]: never })[keyof T]
>;

export type SpreadsheetCellType = typeof SpreadsheetCellTypes[keyof typeof SpreadsheetCellTypes];

export type SpreadsheetCellRendererType =
  | SpreadsheetCellType
  | 'aboveBelowQuota'
  | 'formulaError'
  | 'deleteCellRenderer'
  | 'isNegativeValue'
  | 'isEqualToPreviousColumnCell'
  | 'isEqualToCellsSum'
  | 'saveCellValueToInput'
  | 'xFormat';

export type SpreadSheetCellRenderFunctionType = (
  instance,
  td,
  row,
  col,
  prop,
  value,
  cellProperties
) => void;

export type SpreadSheetCellRenderersFunctions = {
  readonly [property in SpreadsheetCellRendererType]: SpreadSheetCellRenderFunctionType;
};

export type SpreadsheetCellCustomEditors = {
  readonly [property in SpreadsheetCellType]?: any;
};

export type HotCell = Omit<Handsontable.GridSettings, 'numericFormat'> & {
  validatorName: string;
} & { numericFormat: HotNumericFormat };

// remove in case Handsontable is updated to 7+ (commercial version)
export type HandsontableCellChange = [number, string | number, any, any, any];

export type SpreadsheetInputContent = {
  [rowIndex: number]: { [columnIndex: number]: SpreadsheetCellData };
};

export const monthsArray = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export interface SpreadsheetApiData {
  [endpoint: string]: ApiDataDefaultType;
}

export const instanceOfCellDefaultDataFromInput = (
  object: any
): object is CellDefaultDataFromInput => {
  try {
    return 'items' in object;
  } catch (e) {
    return false;
  }
};
