import { Component, forwardRef, OnDestroy } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import {
  BuyerPersonasSelectionMatrixTable,
  BuyerPersonasSelectionMatrixTemplateData
} from '.';
import txt from '!!raw-loader!./index.ts';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'buyer-personas-selection-matrix',
  templateUrl: 'buyer-personas-selection-matrix.component.html',
  styleUrls: ['./buyer-personas-selection-matrix.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => BuyerPersonasSelectionMatrixComponent)
    }
  ]
})
export class BuyerPersonasSelectionMatrixComponent extends TemplateComponent
  implements OnDestroy {
  params = txt;
  contentData: BuyerPersonasSelectionMatrixTemplateData['template_params_json'];

  tableColumns: { title: string; description: string }[];
  tableData: BuyerPersonasSelectionMatrixTable = {};

  buyerPersonasList: Subscription;

  segmentInput = 'icp_segment_selector_1';

  getDescription() {
    return 'Module';
  }

  getName() {
    return 'Buyer Personas Selection Matrix';
  }

  get input() {
    return this.getInput(
      'buyer_personas_selection_matrix',
      1,
      '',
      this.contentData.input_sufix
    );
  }

  init() {
    this.contentData = this.data.data
      .template_params_json as BuyerPersonasSelectionMatrixTemplateData['template_params_json'];

    this.buyerPersonasList$ = this.buyerPersonasService.getBuyerPersonas();

    this.tableColumns = JSON.parse(this.getInput(this.segmentInput).getValue())
      .filter(seg => seg.isChecked)
      .map(seg => ({
        title: seg.index,
        description: seg.title
      }));

    this.initTableData();

    let savedTableData = {};
    try {
      savedTableData = JSON.parse(this.input.getValue());
    } catch (e) {}

    this.buyerPersonasList = this.buyerPersonasList$
      .pipe(
        map(list =>
          list.map(persona => {
            this.tableColumns.map(col => {
              const defaultData = savedTableData?.[persona.uuid] || {
                [col.title]: false
              };

              if (!this.tableData[persona.uuid]) {
                this.tableData[persona.uuid] = defaultData;
              } else {
                Object.assign(this.tableData[persona.uuid], defaultData);
              }
            });
          })
        )
      )
      .subscribe();
  }

  onChecked() {
    this.input.content = JSON.stringify(this.tableData);
    this.contentChanged(this.input);
  }

  toggleCheckbox($event, buyerPersonaUUID: string, itemTitle: string) {
    this.tableData[buyerPersonaUUID][itemTitle] = !this.tableData[
      buyerPersonaUUID
    ][itemTitle];

    this.onChecked();
  }

  initTableData() {
    if (
      this.input &&
      this.input.content &&
      Object.getOwnPropertyNames(this.input.content).length !== 0
    ) {
      this.tableData = JSON.parse(this.input.content);

      for (const rowId of Object.keys(this.tableData)) {
        if (!isNaN(+rowId)) {
          delete this.tableData[rowId];
        }
      }
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.buyerPersonasList) {
      this.buyerPersonasList.unsubscribe();
    }
  }

  getGroup(): string {
    return '';
  }
}
