import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {
  UserDTO,
  UserEvent
} from '../../common/services/users-management.service';
import { ConfirmationService } from '../../common/services/confirmation.service';

@Component({
  selector: 'users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss']
})
export class UsersTableComponent {
  @Input() dataSource: MatTableDataSource<Partial<UserDTO>>;

  @Output() tableDataChangeEmitter: EventEmitter<UserEvent> = new EventEmitter<
    UserEvent
  >();

  displayedColumns: string[] = ['enabled', 'fname', 'lname', 'email', 'delete'];

  constructor(private confirmationService: ConfirmationService) {}

  toggleUserState($event, user: UserDTO) {
    $event.preventDefault();
    this.onTableDataChange({ type: 'changeState', user });
  }

  deleteUser(user: UserDTO) {
    this.confirmationService
      .removeDialog({
        text: `${user.fname} ${user.lname}`
      })
      .subscribe(() => {
        this.onTableDataChange({ type: 'delete', user });
      });
  }

  onTableDataChange(event: UserEvent) {
    this.tableDataChangeEmitter.emit(event);
  }
}
