import { Component, forwardRef } from '@angular/core';
import { ModuleResultTemplateData } from '.';
import { TemplateComponent } from '../template-base.class';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { TemplateContentData } from '../template-data.class';
import txt from '!!raw-loader!./index.ts';

@Component({
  selector: 'app-module-result',
  templateUrl: './module-result.component.html',
  styleUrls: ['./module-result.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => ModuleResultComponent)
    }
  ]
})
export class ModuleResultComponent extends TemplateComponent {
  params = txt;
  contentData: ModuleResultTemplateData['template_params_json'];

  hasError = false;
  moduleResultContent$: Observable<TemplateContentData>;

  init() {
    this.contentData = this.data.data
      .template_params_json as ModuleResultTemplateData['template_params_json'];

    if (!this.contentData.content_position_select) {
      this.contentData.content_position_select = 'bottom';
    }

    const moduleOptions = this.getModuleOptions();

    let moduleID = Number(this.contentData.module);
    let moduleStepID = this.contentData.step_id
      ? this.contentData.step_id
      : undefined;

    if (this.data.data.options?.module && this.data.data.options?.step_id) {
      moduleID = Number(this.data.data.options?.module);
      moduleStepID = Number(this.data.data.options?.step_id);
    }

    this.moduleResultContent$ = this.navService.organization$.pipe(
      switchMap(orgId =>
        this.moduleService.getOrgModule(moduleID, orgId, true).pipe(
          switchMap(module => {
            const targetedStep = module.steps.find(
              step => Number(moduleStepID) === step.id
            );
            const moduleLastStepID = module.steps[module.steps.length - 1].id;
            const currentStepID = targetedStep
              ? targetedStep.id
              : moduleLastStepID;

            return this.moduleContentService
              .load(moduleID, currentStepID, orgId, true)
              .pipe(
                map(content => {
                  Object.assign(content.options, moduleOptions);
                  content.disabled = true;

                  return new TemplateContentData({
                    data: content,
                    me: this.me,
                    canModify: content.can_modify
                  });
                })
              );
          }),
          catchError((err, caught) => {
            this.hasError = true;

            return of(null);
          })
        )
      )
    );

    if (this.contentData.print_orientation_select) {
      this.setOrientationPrintLayout(this.contentData.print_orientation_select);
    }
  }

  getDescription() {
    return 'Show a summary / result from another module';
  }

  getName() {
    return 'Previous Module Result';
  }

  getGroup(): string {
    return 'Generic';
  }

  hasInputs() {
    return false;
  }

  private getModuleOptions(): { [key: string]: string } {
    const getOptionValue = (value: string) => {
      const OptionSubstitution = value.match(/\${(\w+)}/i);
      if (OptionSubstitution && OptionSubstitution[1]) {
        return this.data.data.options[OptionSubstitution[1]];
      }

      return value;
    };

    return (this.contentData.options || []).reduce(
      (accumulator, option) => ({
        ...accumulator,
        [option.key]: getOptionValue(option.value)
      }),
      {}
    );
  }
}
