import { Component, ElementRef, forwardRef, ViewChild } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import {
  ChartDataTemplateParams,
  ChartDataContent,
  START_CHART_SETTINGS
} from './index';
import { v4 as uuid4 } from 'uuid';
import txt from '!!raw-loader!./index.ts';
import { TemplateInput } from '../../../../common/interfaces/module.interface';
import {
  getNestedValue,
  getNumberArrayFromRange,
  isEmptyValue
} from '../../../../common/utils/helpers';
import {
  ChartItem,
  ChartSettings
} from '../../../../common/interfaces/charts.interface';

@Component({
  selector: 'app-chart-data',
  templateUrl: './chart-data.component.html',
  styleUrls: ['./chart-data.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => ChartDataComponent)
    }
  ]
})
export class ChartDataComponent extends TemplateComponent {
  @ViewChild('chartBlock', { static: true }) chartBlock: ElementRef<
    HTMLDivElement
  >;

  params = txt;
  contentData: ChartDataTemplateParams;
  prefix = 'chart_data_1';

  chartData: ChartDataContent;
  chartSettings: ChartSettings;

  private inputKey: string;

  private get input(): TemplateInput {
    return this.inputs[this.inputKey];
  }

  getDescription(): string {
    return '';
  }

  getName(): string {
    return 'Chart Data';
  }

  getGroup(): string {
    return 'Generic';
  }

  init(): void {
    this.contentData = this.data.data
      .template_params_json as ChartDataTemplateParams;
    this.inputKey = this.contentData.input_sufix
      ? `${this.prefix}_${this.contentData.input_sufix}`
      : this.prefix;

    this.setStylesForChartBlockElement();
    this.prepareChartData();
    this.prepareChartSettings();
  }

  private setStylesForChartBlockElement(): void {
    this.renderer.setStyle(
      this.chartBlock.nativeElement,
      'width',
      this.contentData.graph_width
        ? `${this.contentData.graph_width}px`
        : '100%'
    );
    this.renderer.setStyle(
      this.chartBlock.nativeElement,
      'min-height',
      this.contentData.graph_height
        ? `${this.contentData.graph_height}px`
        : '100%'
    );
  }

  private prepareChartData(): void {
    type ChartItemDictionary = {
      [xValue: number]: { [yValue: number]: ChartItem[] };
    };
    const chartItems: ChartItemDictionary = this.contentData.options?.reduce(
      (accum: ChartItemDictionary, option, optionIndex) => {
        const inputName = option.linked_input_name;
        const linkedInputContent = JSON.parse(
          this.inputs[inputName]?.content || null
        );
        const itemsXValues: number[] = getNestedValue(
          linkedInputContent,
          option.input_x_value_path
        );
        const itemsYValues: number[] = getNestedValue(
          linkedInputContent,
          option.input_y_value_path
        );
        const itemNames: string[] = getNestedValue(
          linkedInputContent,
          option.grid_item_name_path
        );
        const items: ChartItem[] = itemNames
          ?.map((name, index) => {
            if (
              isEmptyValue(itemsXValues?.[index]) &&
              isEmptyValue(itemsYValues?.[index])
            ) {
              return null;
            }
            const id = `grid-item-${uuid4()}`;
            const x = itemsXValues?.[index] || 0;
            const y = itemsYValues?.[index] || 0;
            const r = x + y || 1;

            return {
              id,
              optionIndex,
              inputName,
              name,
              x,
              y,
              r,
              originalName: name
            };
          })
          .filter(Boolean);
        items.forEach(item => {
          accum[item.x] = accum[item.x] || {};
          accum[item.x][item.y] = accum[item.x][item.y] || [];
          accum[item.x][item.y].push(item);
        });

        return accum;
      },
      {}
    );
    this.chartData = Object.values(chartItems)?.reduce(
      (accum: ChartDataContent, values) => {
        Object.values(values).forEach(items => {
          const isCombined = items.length > 1;
          const itemName = isCombined
            ? items.map(item => item.name).join(', ')
            : items[0].name;
          const chartItem: ChartItem = {
            ...items[0],
            isCombined,
            name: ''
          };
          const foundItem = accum.data.find(item => item.name === itemName);
          if (foundItem) {
            foundItem.series.push(chartItem);
          } else {
            accum.data.push({
              id: uuid4(),
              name: itemName,
              series: [chartItem]
            });
          }
        });

        return accum;
      },
      { data: [] }
    );
  }

  private prepareChartSettings(): void {
    const showLegend: boolean = this.contentData.show_legend;
    const legendPosition = this.contentData.legend_position_select;
    const yAxisLabel: string = this.contentData.y_label;
    const showYAxisLabel = !!yAxisLabel;
    const xAxisLabel: string = this.contentData.x_label;
    const showXAxisLabel = !!xAxisLabel;
    const xScaleMin: number = this.contentData.x_scale_min;
    const xScaleMax: number = this.contentData.x_scale_max;
    const yScaleMin: number = this.contentData.y_scale_min;
    const yScaleMax: number = this.contentData.y_scale_max;
    const xAxisTicks = getNumberArrayFromRange(xScaleMin, xScaleMax);
    const yAxisTicks = getNumberArrayFromRange(yScaleMin, yScaleMax);
    const divideByQuadrants = this.contentData.divide_by_quadrants;
    const view: [number, number] = [
      this.contentData.graph_width || 800,
      this.contentData.graph_height || 800
    ];
    this.chartSettings = {
      ...START_CHART_SETTINGS,
      showLegend,
      legendPosition,
      yAxisLabel,
      showYAxisLabel,
      xAxisLabel,
      showXAxisLabel,
      xScaleMin,
      xScaleMax,
      yScaleMin,
      yScaleMax,
      xAxisTicks,
      yAxisTicks,
      view,
      divideByQuadrants
    };
  }
}
