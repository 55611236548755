enum DefaultCompetencyCheckBoxes {
  Vision = 'Vision',
  ChangeLeadership = 'Change Leadership',
  IntelligenceAnalysisSkills = 'Intelligence/Analysis Skills',
  Adaptability = 'Adaptability',
  Judgement = 'Judgement',
  StrategicSkills = 'Strategic Skills',
  Pragmatism = 'Pragmatism',
  Resourcefulness = 'Resourcefulness',
  SelfStructure = 'Self-Structure',
  ProblemSolving = 'Problem Solving',
  Planning = 'Planning',
  Accountability = 'Accountability',
  Empowerment = 'Empowerment',
  APlayerTalent = 'Selecting A-Player Talent',
  Resilience = 'Resilience',
  CustomerFocus = 'Customer Focus',
  Communication = 'Communication',
  Negotiation = 'Negotiation',
  Energy = 'Energy',
  Tenacity = 'Tenacity'
}

interface CompetencyCheckbox {
  name: string;
  checked: boolean;
  deletable: boolean;
  description?: string;
}

export const defaultCheckboxes: CompetencyCheckbox[] = [
  {
    name: DefaultCompetencyCheckBoxes.Vision,
    checked: false,
    deletable: false
  },
  {
    name: DefaultCompetencyCheckBoxes.ChangeLeadership,
    checked: false,
    deletable: false
  },
  {
    name: DefaultCompetencyCheckBoxes.IntelligenceAnalysisSkills,
    checked: false,
    deletable: false
  },
  {
    name: DefaultCompetencyCheckBoxes.Adaptability,
    checked: false,
    deletable: false
  },
  {
    name: DefaultCompetencyCheckBoxes.Judgement,
    checked: false,
    deletable: false
  },
  {
    name: DefaultCompetencyCheckBoxes.StrategicSkills,
    checked: false,
    deletable: false
  },
  {
    name: DefaultCompetencyCheckBoxes.Pragmatism,
    checked: false,
    deletable: false
  },
  {
    name: DefaultCompetencyCheckBoxes.Resourcefulness,
    checked: false,
    deletable: false
  },
  {
    name: DefaultCompetencyCheckBoxes.SelfStructure,
    checked: false,
    deletable: false
  },
  {
    name: DefaultCompetencyCheckBoxes.ProblemSolving,
    checked: false,
    deletable: false
  },
  {
    name: DefaultCompetencyCheckBoxes.Planning,
    checked: false,
    deletable: false
  },
  {
    name: DefaultCompetencyCheckBoxes.Accountability,
    checked: false,
    deletable: false
  },
  {
    name: DefaultCompetencyCheckBoxes.Empowerment,
    checked: false,
    deletable: false
  },
  {
    name: DefaultCompetencyCheckBoxes.APlayerTalent,
    checked: false,
    deletable: false
  },
  {
    name: DefaultCompetencyCheckBoxes.Resilience,
    checked: false,
    deletable: false
  },
  {
    name: DefaultCompetencyCheckBoxes.CustomerFocus,
    checked: false,
    deletable: false
  },
  {
    name: DefaultCompetencyCheckBoxes.Communication,
    checked: false,
    deletable: false
  },
  {
    name: DefaultCompetencyCheckBoxes.Negotiation,
    checked: false,
    deletable: false
  },
  {
    name: DefaultCompetencyCheckBoxes.Energy,
    checked: false,
    deletable: false
  },
  {
    name: DefaultCompetencyCheckBoxes.Tenacity,
    checked: false,
    deletable: false
  }
];

export const defaultCheckboxesTips: Readonly<Record<
  DefaultCompetencyCheckBoxes,
  string
>> = {
  [DefaultCompetencyCheckBoxes.Vision]:
    'Highly effective leader with an innate ability to build trust and relationships throughout the organization; ' +
    'adopt a tailored and attentive approach to partnering with the Company’s management team, Board of Directors,' +
    ' and front-line team members; grounded in humility and maintain a laser focus on the Company’s needs; free of' +
    ' any personal agenda or need for ego satisfaction; wired to take satisfaction in the organization’s healthy' +
    ' growth as opposed to being motivated by personal levels of significance; steady and persistent approach to' +
    ' improving the organization in a pragmatic and transparent manner; highly effective, relationship-based' +
    ' influencer who can insure alignment and buy-in throughout the organization.',
  [DefaultCompetencyCheckBoxes.ChangeLeadership]:
    'Foster cross-functional team performance and solicit input from all direct reports on key decisions while ' +
    'maintaining responsibility and accountability for strategic decisions; highly collaborative and inclusive ' +
    'leader who is not bound by consensus',
  [DefaultCompetencyCheckBoxes.IntelligenceAnalysisSkills]:
    'Be intellectually rigorous and able to engage in and enrich robust discussion, problem solving, and ' +
    'planning; display an innate intellectual curiosity; ability to execute will be complemented by a well-rounded ' +
    'strategic perspective; have a sound judgment and support efficient and sound decision making.',
  [DefaultCompetencyCheckBoxes.Adaptability]:
    'Flexible and have the ability to adapt to changing work conditions; staying calm means not folding under' +
    ' pressure when something changes, or a problem occurs; developing a solution is being able to come up with' +
    ' a plan when there is a problem',
  [DefaultCompetencyCheckBoxes.Judgement]:
    'Rooted in integrity and represent themselves in a truthful and straightforward manner. Intellectually honest' +
    ' and self-aware regarding their own capabilities, limits, and interests. Highly confident but grounded in an' +
    ' appropriate level of humility and must place the organization and its investors ahead of his/her own interests.',
  [DefaultCompetencyCheckBoxes.StrategicSkills]:
    'Using methodologies to spot and exploit opportunities in account positioning; managing individual goals that' +
    ' correspond to a territory view; understanding and adopting corporate strategic goals; conducting' +
    ' executive-level discussion with senior staff at assigned accounts; producing a strategic territory plan;' +
    ' articulating customer value prop and linking solutions to the customer strategy',
  [DefaultCompetencyCheckBoxes.Pragmatism]:
    'Practical, matter-of-fact, sensible, down-to-earth, commonsensical, businesslike, having both/one\'s feet on ' +
    'the ground, hard-headed, no-nonsense; informal hard-nosed',
  [DefaultCompetencyCheckBoxes.Resourcefulness]:
    'Demonstrating resourcefulness when faced with challenges that defy an easy solution; utilizing internal and ' +
    'external resources to advance sales campaigns when faced with objections; leveraging customer – centric ' +
    'solutions when company approaches fall short; finding unique sources of supply for projects, sales campaigns ' +
    'and other efforts',
  [DefaultCompetencyCheckBoxes.SelfStructure]:
    'Self-Structure indicates a preference for determining one’s own priorities and methods for managing tasks. ' +
    'Individuals ranking high in this area tend to be self-disciplined and able to set priorities on their own. ' +
    'Having an internal focus of control, they are able to coordinate multiple activities and typically are good ' +
    'at organizing activities. Those scoring low may require help and direction when defining and setting ' +
    'priorities. They will be more comfortable in an environment where parameters and guidelines are established ' +
    'and communicated.',
  [DefaultCompetencyCheckBoxes.ProblemSolving]:
    'Determining root cause of internal and external problems; developing solutions; overseeing and helping achieve ' +
    'resolution; ensuring problems don’t re-occur; leveraging the management technique of Failure-Based evidence',
  [DefaultCompetencyCheckBoxes.Planning]:
    'Planning time; separating productive vs. nonproductive time; reconciling conflicting high priority commitments; ' +
    'managing time to complete ‘A’ priorities before ‘B’ and ‘C’ ones; balancing professional and personal ' +
    'schedules; meeting deadlines; managing meeting times; adapting to customer and prospect schedules',
  [DefaultCompetencyCheckBoxes.Accountability]:
    'Holds people to their agree upon actions and activities; accepts responsibility for them, and to disclose the ' +
    'results in a transparent manner.',
  [DefaultCompetencyCheckBoxes.Empowerment]:
    'Intense focus on execution, stretch-drive performance, strong bias for action, decisiveness, and a sense of ' +
    'urgency; ambition balanced by ideal levels of empathy for the organization, its customers, and stakeholders; ' +
    'have the highest standards for performance and will professionalize the Company’s commitment to accountability;' +
    ' fierce competitor and embed that ethos throughout the organization ; hands-on, highly resourceful, and be' +
    ' able to achieve winning outcomes with a backdrop of finite people, process, and technology resources.',
  [DefaultCompetencyCheckBoxes.APlayerTalent]:
    'Ability to recruit, attractive and retain ‘A’ level talent; builds a virtual bench of ‘A’ player talent to fill ' +
    'open or expansion roles',
  [DefaultCompetencyCheckBoxes.Resilience]:
    'The ability to handle rejection and accept criticism in a manner which is constructive and growth oriented. ' +
    'Have a healthy, intact ego and a positive self-picture. Those that tend to be more self-critical and less ' +
    'tolerant of critical feedback and rejection score low.',
  [DefaultCompetencyCheckBoxes.CustomerFocus]:
    'Puts the Customer in the focus of everything they do; knows the customers buying process and can identify where ' +
    'each member of the buying decision team is during a sales campaign',
  [DefaultCompetencyCheckBoxes.Communication]:
    'Demonstrating through written or oral skills to present company solutions to executive audiences using formal ' +
    'slideware; maintaining attention and audience engagement when presenting data-rich content; speaking or ' +
    'writing to individuals and groups with minimal presentation time',
  [DefaultCompetencyCheckBoxes.Negotiation]:
    'Using language and other communication skills to influence business partners and customers to do what the ' +
    'individual wants them to do; convincing peers of the merits of their point of view; managing up; utilizing ' +
    'give get frameworks; presenting to general audiences, industry thought leaders and business partners; ' +
    'presenting company value proposition and solution business cases',
  [DefaultCompetencyCheckBoxes.Energy]:
    'Demonstrating work pace; meeting deadlines; maintaining work quality in times of heavy workload and stress; ' +
    'impressing customers and partners with high energy state; impacting the work pace of others; thriving in an ' +
    'environment that mandates a fast pace',
  [DefaultCompetencyCheckBoxes.Tenacity]:
    'Sticking to a plan; not being distracted by noise and friction; establishing reputation for being tenacious; ' +
    'thinking creatively to avoid obstacles; overcoming adversity; managing goals relentlessly'
};
