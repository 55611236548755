export enum STATUS {
  TESTING,
  TEST_ERROR,
  READY,
  RECORDING,
  PROCESSING,
  RECORDED,
  PLAYING,
  UPlOADING,
  DONE,
  ERROR
}
