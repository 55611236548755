import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { SnackBarService } from '../services/snackbar.service';
import { CustomNumberPipe, NumberType } from '../pipes/custom-number.pipe';
import { isEmptyValue } from '../utils/helpers';

@Directive({
  selector: '[numberModel]'
})
export class NumberDirective implements OnInit {
  @Input() numberMin: number;
  @Input() numberMax: number;
  @Input() positiveOnly = false;
  @Input() showNumberError = false;
  @Input() numberType: NumberType = 'decimal';
  @Input('numberModel')
  set numberModel(value: number | string) {
    if (value !== null && Number(value) !== this.value) {
      const valueString = this.numberPipe.transform(value);
      this.initValue = valueString;
      this.value = Number(value);
      this.onValueChange(valueString);
    }
  }
  @Output() numberModelChange: EventEmitter<number> = new EventEmitter<
    number
  >();
  @Output() numberFocusOut: EventEmitter<number> = new EventEmitter<number>();

  private value: number = null;
  private displayValue: string = null;
  private initValue: string;

  constructor(
    private el: ElementRef<HTMLInputElement>,
    private snackBarService: SnackBarService,
    private numberPipe: CustomNumberPipe,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.numberMin = this.el.nativeElement.min
      ? Number(this.el.nativeElement.min)
      : null;
    this.numberMax = this.el.nativeElement.max
      ? Number(this.el.nativeElement.max)
      : null;
  }

  @HostListener('focusout', ['$event.target'])
  onFocusout(): void {
    this.numberFocusOut.emit(this.value);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent): void {
    if (
      [46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      // Allow: home, end, left, right, up, down
      (e.keyCode >= 35 && e.keyCode <= 40)
    ) {
      return;
    }
    // Ensure that it is a number and stop the keypress
    if (
      (e.shiftKey ||
        e.keyCode < 48 ||
        (e.keyCode > 57 && e.keyCode !== 109 && e.keyCode !== 189)) &&
      (e.keyCode < 96 ||
        (e.keyCode > 105 && e.keyCode !== 109 && e.keyCode !== 189))
    ) {
      if (this.showNumberError) {
        this.snackBarService.error('Only numbers available');
      }
      e.preventDefault();
    }
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string): void {
    this.onValueChange(value);
  }

  ngOnInit(): void {
    if (!isEmptyValue(this.numberMin) && !this.positiveOnly) {
      this.positiveOnly = this.numberMin >= 0;
    }
    this.onValueChange(this.initValue, true);
  }

  private onValueChange(newValue: string, forceChange = false): void {
    if (newValue !== this.displayValue || forceChange) {
      const numberValue = this.numberPipe.parse(
        newValue,
        this.numberType,
        this.positiveOnly
      );
      if (
        this.isValidValue(numberValue) ||
        !this.isBiggerThanMin(numberValue)
      ) {
        this.el.nativeElement.value = this.numberPipe.transform(
          newValue,
          this.numberType,
          this.positiveOnly
        );
        this.displayValue = this.el.nativeElement.value;
        if (this.value !== numberValue && this.isBiggerThanMin(numberValue)) {
          this.updateValue(numberValue);
        }
      } else {
        this.el.nativeElement.value = this.displayValue;
      }
    }
  }

  private updateValue(value: number): void {
    this.value = value;
    this.numberModelChange.emit(value);
    this.changeDetectorRef.detectChanges();
  }

  private isValidValue(value: number): boolean {
    return (
      isEmptyValue(value) ||
      (this.isBiggerThanMin(value) && this.isLesserThanMax(value))
    );
  }

  private isBiggerThanMin(value: number): boolean {
    return isEmptyValue(this.numberMin) || value >= this.numberMin;
  }

  private isLesserThanMax(value: number): boolean {
    return isEmptyValue(this.numberMax) || value <= this.numberMax;
  }
}
