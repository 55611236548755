import { MatrixElement } from './index';
import { v4 as uuid4 } from 'uuid';

export const updateMatrixEntryFormat = (
  content: MatrixElement[][]
): MatrixElement[][] => {
  if (!content) {
    return content;
  }

  return content.map(row =>
    row.map((i, columnIndex) => {
      const updItem = { ...i };
      if (columnIndex === 0 && !updItem?.uuid) {
        updItem.uuid = uuid4();
      }

      return updItem;
    })
  );
};
