import {
  Directive,
  ElementRef,
  Input,
  HostListener,
  OnDestroy,
  OnChanges
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { AssessmentChartSeries } from '../assessment-chart';
import { filter, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appLineChartValueLabels]'
})
export class LineChartValueLabelDirective implements OnChanges, OnDestroy {
  @Input() results: AssessmentChartSeries;

  private changeSize: BehaviorSubject<boolean> = new BehaviorSubject(null);
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private resizeTimer: number;

  constructor(private el: ElementRef) {
    this.el.nativeElement.removeAttribute('displayed');
    this.changeSize
      .pipe(takeUntil(this.destroy$), filter(Boolean))
      .subscribe(() => {
        this.el.nativeElement.removeAttribute('displayed');
        setTimeout(() => this.updateLabels(), 200);
      });
  }

  @HostListener('window:resize')
  onResize(): void {
    clearTimeout(this.resizeTimer);
    this.resizeTimer = setTimeout(() => this.changeSize.next(true), 100);
  }

  ngOnChanges(): void {
    clearTimeout(this.resizeTimer);
    this.changeSize.next(true);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    clearTimeout(this.resizeTimer);
  }

  private updateLabels(): void {
    const map = [];
    const chartEl = this.el.nativeElement;
    chartEl.querySelectorAll('.datapoint-value').forEach(el => el.remove());
    const series = chartEl.querySelectorAll('g.line-series path');
    series.forEach((el, idx) => {
      if (!this.results[idx].name) {
        return;
      }

      const points = el
        .getAttribute('d')
        .substr(1)
        .split('L');
      const color = el.getAttribute('stroke');
      points.forEach((point, pIdx) => {
        const g = document.createElementNS('http://www.w3.org/2000/svg', 'g');
        const text = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'text'
        );

        g.appendChild(text);
        g.setAttribute('transform', 'translate(' + point.slice(0, -1) + '0)');
        g.setAttribute('class', 'datapoint-value');

        const isLast = pIdx === points.length - 1;

        // hide nearby points to avoid cluttering the chart with overlapping numbers
        const [x, y] = point.split(',').map(Number);
        if (
          !isLast &&
          map.find(p => Math.abs(x - p[0]) < 30 && Math.abs(y - p[1]) < 30)
        ) {
          g.setAttribute('style', 'display: none');
        }

        const dp = this.results[idx].series[pIdx];
        text.innerHTML = (dp.formattedValue || dp.value).toString();
        text.setAttribute('stroke-width', '1');
        text.setAttribute('text-anchor', 'middle');
        text.setAttribute('x', '0');
        text.setAttribute('y', isLast ? '5' : dp.value > 0 ? '-10' : '15');
        text.setAttribute(
          'style',
          'font-size: 14px; fill: ' + (isLast ? '#fff' : '#444') + ';'
        );

        el.parentNode.appendChild(g);

        if (isLast) {
          const circle = document.createElementNS(
            'http://www.w3.org/2000/svg',
            'circle'
          );
          circle.setAttributeNS(null, 'cx', '0');
          circle.setAttributeNS(null, 'cy', '0');
          circle.setAttributeNS(null, 'r', '20');
          circle.setAttributeNS(null, 'style', 'fill: ' + color + ';');
          g.insertBefore(circle, text);
        }

        map.push([x, y]);
      });

      const avgPoint = points.pop();
      el.setAttribute('d', 'M' + points.join('L'));
    });

    setTimeout(_ => chartEl.setAttribute('displayed', 'true'), 100);
  }
}
