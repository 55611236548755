import { Component, forwardRef } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import txt from '!!raw-loader!./index.ts';
import { ExpandDataResultTemplateData, ExpandedDataResult } from './index';
import { ExpandedData } from '../expand-data';

@Component({
  selector: 'expand-data-result',
  templateUrl: './expand-data-result.component.html',
  styleUrls: ['./expand-data-result.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => ExpandDataResultComponent)
    }
  ]
})
export class ExpandDataResultComponent extends TemplateComponent {
  params = txt;
  contentData: ExpandDataResultTemplateData['template_params_json'];

  expandedResult: ExpandedDataResult;

  getDescription(): string {
    return '';
  }

  getName(): string {
    return 'Expand Data Result';
  }

  getGroup(): string {
    return 'Generic';
  }

  getExpandDataContent(inputKey: string): ExpandedData {
    return JSON.parse(this.inputs[inputKey]?.content || null);
  }

  protected init() {
    this.contentData = this.data.data
      .template_params_json as ExpandDataResultTemplateData['template_params_json'];
    this.expandedResult = this.getExpandDataResult();
  }

  private getExpandDataResult(): ExpandedDataResult {
    return this.contentData.columns.reduce(
      (accum: ExpandedDataResult, column, columnIndex, columns) => {
        const expandInfo: {
          mainColumnTitle: string;
          expandColumnTitle: string;
          content: ExpandedData;
        }[] = column.from_inputs.map(item => ({
          mainColumnTitle: item.main_column_title,
          expandColumnTitle: item.title_from_input,
          content: this.getExpandDataContent(item.key)
        }));
        if (columnIndex === 0) {
          accum[column.title] = expandInfo
            .map(item =>
              item.content
                ? item.content[item.expandColumnTitle]?.map(expandItem => ({
                    value: expandItem.value || ''
                  }))
                : []
            )
            .flat()
            .filter(item => !!item?.value);
        } else {
          accum[column.title] =
            accum[columns[0].title]
              ?.reduce((columnAccum: [number, number][], mainItem) => {
                let rowIndex = -1;
                const inputIndex = expandInfo.findIndex(
                  (inputItem, inputId) => {
                    rowIndex = inputItem.content[
                      inputItem.mainColumnTitle
                    ].findIndex(
                      (value, rowId) =>
                        value.value === mainItem.value &&
                        !columnAccum.some(
                          item => item[0] === inputId && item[1] === rowId
                        )
                    );

                    return !(inputIndex === -1);
                  }
                );
                columnAccum.push([inputIndex, rowIndex]);

                return columnAccum;
              }, [])
              .map(item =>
                item[0] === -1 || item[1] === -1
                  ? { value: '' }
                  : {
                      value:
                        expandInfo[item[0]].content[
                          expandInfo[item[0]].expandColumnTitle
                        ][item[1]].value || ''
                    }
              ) || [];
        }

        return accum;
      },
      {}
    );
  }
}
