import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationModalOptions, dialogType } from './index';
import { ThemePalette } from '@angular/material/core/common-behaviors/color';

@Component({
  selector: 'confirmation-modal',
  templateUrl: './confirmation-modal.html',
  styleUrls: ['./confirmation-modal.scss']
})
export class ConfirmationModalComponent implements OnInit {
  confirmBtnTitle = 'Confirm';
  rejectBtnTitle = 'Cancel';
  type: dialogType = 'default';
  text: string;
  confirmButtonColor: Record<dialogType, ThemePalette> = {
    default: 'primary',
    removing: 'warn'
  };

  constructor(
    public modal: MatDialogRef<ConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationModalOptions
  ) {}

  ngOnInit(): void {
    if (this.data) {
      if (this.data.text) {
        this.text = this.data.text;
      }
      if (this.data.confirmBtnTitle) {
        this.confirmBtnTitle = this.data.confirmBtnTitle;
      }
      if (this.data.rejectBtnTitle) {
        this.rejectBtnTitle = this.data.rejectBtnTitle;
      }
      if (this.data.type) {
        this.type = this.data.type;
      }
    }
  }
}
