import { Injectable } from '@angular/core';
import { Post } from '../interfaces/post.interface';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  baseUrl = environment.apiRoot + '/api/posts';
  observable$ = new BehaviorSubject(null);
  currentPost = this.observable$.asObservable();
  postsPerPage = 50;

  constructor(private httpClient: HttpClient) {}

  // Share data
  loadPost(post: Post) {
    this.observable$.next(post);
  }

  // Crud methods
  getPosts(
    searchType: string,
    searchInput: string,
    searchPage: number
  ): Observable<Post[]> {
    return this.httpClient.get<Post[]>(
      `${this.baseUrl}/${this.postsPerPage}/${searchType}/${searchInput}/${searchPage}`
    );
  }

  getPost(postId: number): Observable<Post[]> {
    return this.httpClient.get<Post[]>(`${this.baseUrl}/${postId}`);
  }

  updatePost(postData: Post): Observable<Post[]> {
    return this.httpClient.post<Post[]>(
      `${this.baseUrl}/update/${postData.id}`,
      postData
    );
  }

  createPost(postData: Post): Observable<Post[]> {
    return this.httpClient.post<Post[]>(`${this.baseUrl}`, postData);
  }

  deletePost(postId: number): Observable<Post[]> {
    return this.httpClient.post<Post[]>(`${this.baseUrl}/delete/${postId}`, {});
  }
}
