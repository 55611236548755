import { Component, forwardRef } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import { ICPSegment, IcpSegmentSelectorTemplateData } from '.';
import txt from '!!raw-loader!./index.ts';
import { IcpProfileService } from '../../../../common/services/icp-profile.service';

@Component({
  selector: 'icp-segment-selector',
  templateUrl: 'icp-segment-selector.component.html',
  styleUrls: ['./icp-segment-selector.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => IcpSegmentSelectorComponent)
    }
  ]
})
export class IcpSegmentSelectorComponent extends TemplateComponent {
  params = txt;
  contentData: IcpSegmentSelectorTemplateData['template_params_json'];

  fieldName = 'icp_segment_selector_1';
  icpService: IcpProfileService;
  icpSegments: ICPSegment[];

  getDescription() {
    return '';
  }

  getName() {
    return 'Icp Segment Selector';
  }

  getGroup() {
    return 'Module';
  }

  init() {
    this.contentData = this.data.data
      .template_params_json as IcpSegmentSelectorTemplateData['template_params_json'];

    this.icpService = this.injectorObj.get(IcpProfileService);

    this.navService.organization$.subscribe(orgID => {
      this.icpService.getIcpSegments(orgID).subscribe(icpSegments => {
        this.icpSegments = icpSegments as ICPSegment[];

        const input = this.getInput(this.fieldName);

        if (input.content) {
          const segments = JSON.parse(input.content) as ICPSegment[];

          segments.forEach(segment => {
            const result = this.icpSegments.find(
              s => segment.index === s.index
            );

            if (result) {
              result.isChecked = segment.isChecked;
            }
          });
        }
      });
    });
  }

  updateSegments(segment?: ICPSegment) {
    if (this.disabled) {
      return;
    }

    if (segment) {
      segment.isChecked = !segment.isChecked;
    }

    const input = this.getInput(this.fieldName);

    input.content = JSON.stringify(
      this.icpSegments.map(s => ({
        index: s.index,
        title: s.name,
        isChecked: s.isChecked
      }))
    );

    this.contentChanged(input);
  }
}
