import { Component, forwardRef, OnInit } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import {
  ProductLaunchAndMarketingData,
  ProductLaunchAndMarketingInitialDetails,
  ProductLaunchAndMarketingMetrics,
  ProductLaunchAndMarketingTemplateData,
  ProductLaunchAndMarketingTiers
} from './index';
import txt from '!!raw-loader!./index.ts';
import { switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'app-product-launch-and-marketing',
  templateUrl: './product-launch-and-marketing.component.html',
  styleUrls: ['./product-launch-and-marketing.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => ProductLaunchAndMarketingComponent)
    }
  ]
})
export class ProductLaunchAndMarketingComponent extends TemplateComponent {
  inputData: ProductLaunchAndMarketingData[];
  contentData: ProductLaunchAndMarketingTemplateData['template_params_json'];
  params = txt;

  hardCodedValues = {
    externalBlockRepeater: 'block_repeater_1_compensation_metrics',
    internalBlockRepeater: 'block_repeater_1_compensation_metrics_br',
    brainstorm: 'brainstorm_1_compensation_metrics',
    matrixEntry: 'matrix_entry_1_compensation_metrics_me'
  };

  frequencyOptions = ['weekly', 'monthly', 'quarterly', 'annually'];

  init() {
    super.init();
    this.contentData = this.data.data
      .template_params_json as ProductLaunchAndMarketingTemplateData['template_params_json'];
    this.getExtraInputData(
      JSON.parse(this.input.getValue() ? this.input.getValue() : '[]')
    );
  }

  getExtraInputData(input) {
    this.navService.organization$
      .pipe(
        take(1),
        switchMap(orgId =>
          this.moduleService.getAPIData(orgId, this.contentData.api_endpoint)
        )
      )
      .subscribe(titles => {
        this.inputData = [];
        (titles as [{ name: string; uuid: string }]).forEach(
          (title, externalBlockIndex) => {
            const tabTitle = title.name;
            let metricTitle;
            let metrics;
            const metricDetails = [];

            JSON.parse(
              JSON.parse(
                this.getInput(this.hardCodedValues.externalBlockRepeater)
                  .content
              ).blocks[externalBlockIndex].data[23159][
                this.hardCodedValues.internalBlockRepeater
              ].content
            ).blocks.forEach((item, internalBlockIndex) => {
              metricTitle =
                item.data[23162][this.hardCodedValues.brainstorm].content;
              metrics = JSON.parse(
                item.data[23163][this.hardCodedValues.matrixEntry].content
              ).map((metricName, metricIndex) => {
                const oldMetric = input?.length
                  ? input[externalBlockIndex]?.metric_details[
                      internalBlockIndex
                    ]?.metrics[metricIndex]
                  : null;

                return {
                  name: metricName[0]?.data,
                  details: oldMetric?.details
                    ? oldMetric.details
                    : JSON.parse(
                        JSON.stringify(ProductLaunchAndMarketingInitialDetails)
                      ),
                  tiers: oldMetric?.tiers ? oldMetric.tiers : []
                };
              });
              metricDetails.push({
                title: metricTitle,
                metrics
              });
            });

            this.inputData.push({
              tabTitle,
              metric_details: metricDetails
            });
          }
        );
        this.updateRow();
      });
  }

  removeTier(
    metric: ProductLaunchAndMarketingMetrics,
    tier: ProductLaunchAndMarketingTiers
  ): void {
    const idx = metric.tiers.indexOf(tier);
    metric.tiers.splice(idx, 1);
    this.updateRow();
  }

  addTier(metric: ProductLaunchAndMarketingMetrics): void {
    metric.tiers.push({ title: '', incrementalIncentive: null });
  }

  updateRow(): void {
    this.input.content = JSON.stringify(this.inputData);
    this.contentChanged(this.input);
  }

  getDescription(): string {
    return 'Module specific template';
  }

  getName(): string {
    return 'Product Launch And Marketing';
  }

  getGroup(): string {
    return 'Module';
  }

  get input() {
    return this.getInput(`product_launch_and_marketing_1`);
  }
}
