import {
  Component,
  ElementRef,
  forwardRef,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import {
  HandsontableCellChange,
  HotCell,
  instanceOfCellDefaultDataFromInput,
  monthsArray,
  SpreadsheetApiData,
  SpreadsheetCellCustomEditors,
  SpreadSheetCellRenderersFunctions,
  SpreadsheetCellType,
  SpreadsheetCellTypes,
  SpreadsheetInputContent,
  SpreadsheetTemplateParams
} from '.';
import * as Handsontable from 'src/assets/scripts/handsontable.js';
import { bindCallback, forkJoin, interval, Observable, of } from 'rxjs';
import { TemplateInput } from 'src/app/common/interfaces/module.interface';
import {
  catchError,
  filter,
  first,
  map,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs/operators';
import { SpreadsheetService } from 'src/app/common/services/spreadsheet.service';
import {
  HotTableComponent,
  HotTableRegisterer
} from '../../../../../lib/angular-handsontable-3.0.0-ce/public_api';
import { FormulaPlugin } from './formulaPlugin';
import txt from '!!raw-loader!./index.ts';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { sanitizeHtml } from '../../../../common/utils/html-helpers';
import {
  currencyToNumber,
  getNestedValue,
  getNumberArrayFromRange,
  isEmptyObject,
  isEmptyValue,
  purifyNumberString
} from '../../../../common/utils/helpers';
import {
  wrapXFormatRenderer,
  wrapRendererFunction,
  wrapDynamicColorRenderer
} from './custom-renderers/wrap-renderer';
import { CustomDropdownEditor } from './custom-editors/custom-dropdown-editor';
import {
  getColChar,
  getColNumericIndex,
  isCellChanged,
  isEditChange,
  isFormulaString,
  isValueInNumberRange,
  parseColumnRange,
  parseNumberRange,
  parseSpreadsheetRange,
  getParsedRange
} from '../../../../common/utils/spreadsheet-helpers';
import {
  CellDefaultData,
  CellDefaultDataFromInput,
  CellSettings,
  SpreadsheetCellApiData,
  SpreadsheetCellData,
  SpreadsheetCellDependency,
  SpreadsheetCellMetaOptions,
  SpreadsheetResource,
  SpreadsheetRowInfo,
  ValidatorType
} from '../../../../common/interfaces/spreadsheet.interface';
import { multipleCheckboxes } from './custom-types/multiple-checkboxes-type';
import { MultipleCheckboxesRenderer } from './custom-renderers/multiple-checkboxes-renderer';
import { BuyerPersona } from '../../../../common/interfaces/buyer-persona.interface';
import { wrapStaticTextRenderer } from './custom-renderers/static-text-renderer';
import {
  ApiDataDefaultType,
  ApiDataType
} from '../../../../common/interfaces/api-data.interface';
import { environment } from '../../../../../environments/environment';

class PercentageEditor extends Handsontable.editors.TextEditor {
  prepare(row, col, prop, td, originalValue, cellProperties) {
    super.prepare(row, col, prop, td, originalValue * 100, cellProperties);
  }

  getValue() {
    // @ts-ignore
    return parseFloat(this.TEXTAREA.value) / 100 || 0;
  }
}

@Component({
  selector: 'app-spreadsheet',
  templateUrl: './spreadsheet.component.html',
  styleUrls: ['./spreadsheet.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => SpreadsheetComponent)
    }
  ]
})
export class SpreadsheetComponent extends TemplateComponent
  implements OnDestroy {
  @ViewChild('hot', { static: true }) hot: HotTableComponent;
  @ViewChild('widthContainer', { static: true }) widthContainer: ElementRef;

  params = txt;
  contentData: SpreadsheetTemplateParams;
  sheet: SpreadsheetResource;
  settings: Handsontable.GridSettings;

  isRendered = false;
  showError = false;
  previewRows: undefined[];
  previewCols: undefined[];

  downloadProgress: boolean;

  faSpinner = faSpinner;
  addItemButtonLabel = 'Add Item';

  // @ts-ignore
  resizeObserver: ResizeObserver;

  private input: TemplateInput;
  private hotId: string;

  private keepFormulas: boolean;
  private meta_sufix: string;
  private isOpenEndedRowRange = false;
  private openEndedStartingRow = 0;
  private visibleRows: number[];
  private visibleCols: number[];
  private visibleRowsStr: string;
  private hideCols: { [realColIndex: number]: true };
  private displayedRows: SpreadsheetRowInfo[];
  private tableRowIndexes: number[] = [];
  private tableData: SpreadsheetCellData[][];
  private backendStartFilteringRowRange: [number, number];
  private realStartFilteringRowRange: [number, number][];
  private filteringIndexes: number[] = [];
  private emptyRows: boolean[] = [];
  private types: SpreadsheetCellType[][];
  private rounding: number[][];
  private cellSettings: CellSettings[][];
  private cellRendererDependencyTable: SpreadsheetCellDependency[][] = [];
  private deleteColumnIndex: number;
  private deleteRowRange: [number, number];
  private apiData: SpreadsheetApiData;
  private buyerPersonas: BuyerPersona[];

  private triggeredValidation = false;
  private invalidCellToFocus: [number, number];
  private rowsWithValidators: { [rowId: number]: boolean };

  private defaultDataBackendRowRange: [number, number];
  private defaultDataLocalRowRange: [number, number];

  private spreadsheetService: SpreadsheetService;
  private hotRegister = new HotTableRegisterer();

  private readonly cellRenderers: SpreadSheetCellRenderersFunctions = {
    text: Handsontable.renderers.TextRenderer,
    numeric: Handsontable.renderers.NumericRenderer,
    percent: Handsontable.renderers.NumericRenderer,
    currency: Handsontable.renderers.NumericRenderer,
    date: Handsontable.renderers.AutocompleteRenderer,
    checkbox: Handsontable.renderers.CheckboxRenderer,
    dropdown: Handsontable.renderers.AutocompleteRenderer,
    'month-dropdown': Handsontable.renderers.AutocompleteRenderer,
    [multipleCheckboxes]: MultipleCheckboxesRenderer,
    'delete-cell': this.deleteCellRenderer.bind(this),
    deleteCellRenderer: this.deleteCellRenderer.bind(this),
    aboveBelowQuota: this.aboveBelowQuota.bind(this),
    formulaError: this.formulaError.bind(this),
    isNegativeValue: this.isNegativeValue.bind(this),
    isEqualToPreviousColumnCell: this.isEqualToPreviousColumnCell.bind(this),
    isEqualToCellsSum: this.isEqualToCellsSum.bind(this),
    saveCellValueToInput: this.saveCellValueToInput.bind(this),
    xFormat: Handsontable.renderers.NumericRenderer
  };

  private readonly cellEditors: SpreadsheetCellCustomEditors = {
    dropdown: CustomDropdownEditor,
    'month-dropdown': CustomDropdownEditor
  };

  private get inputToSaveCellValue(): TemplateInput {
    return this.getInput('spreadsheet', 2, '', this.contentData.input_sufix);
  }

  init() {
    this.spreadsheetService = this.injectorObj.get(SpreadsheetService);
    this.contentData = this.data.data
      .template_params_json as SpreadsheetTemplateParams;
    this.input = this.getInput(
      'spreadsheet',
      this.data.data.instance_index + 1,
      '',
      this.contentData.input_sufix
    );

    this.hotId = `hot-${this.data.data.step_id}-${this.data.data.instance_index}`;

    this.lookForStepInputChange();
    this.setContentDataFromDataOptions();

    this.visibleRows = getParsedRange(
      this.textContent(this.contentData.visibleRows),
      'row'
    );

    this.visibleRowsStr = this.textContent(this.contentData.visibleRows);

    this.visibleCols = getParsedRange(
      this.textContent(this.contentData.visibleCols),
      'column'
    );

    this.meta_sufix = this.contentData.meta_sufix
      ? this.textContent(this.contentData.meta_sufix)
      : '';

    this.addItemButtonLabel = this.contentData.add_item_action_label
      ? this.contentData.add_item_action_label
      : this.addItemButtonLabel;

    this.keepFormulas = !(
      this.isOpenEndedRowRange || this.contentData.calculateFormulasOnServer
    );

    if (this.contentData.default_data_row_range) {
      const rowRange = parseNumberRange(
        this.contentData.default_data_row_range
      );
      this.defaultDataBackendRowRange = [rowRange[0] - 1, rowRange[1] - 1];
    }

    let saveInputObservable: Observable<TemplateInput> = of(null);

    if (this.contentData.allow_adding_items) {
      this.contentData.number_of_rows_to_add_on_click =
        this.contentData.number_of_rows_to_add_on_click || 1;
    }

    if (this.contentData.enable_filtering_empty_rows) {
      this.contentData.number_of_empty_rows_by_default =
        this.contentData.number_of_empty_rows_by_default || 0;
      if (this.contentData.filtering_empty_row_range) {
        const emptyRowRange = parseNumberRange(
          this.contentData.filtering_empty_row_range
        );
        this.backendStartFilteringRowRange = [
          emptyRowRange[0] - 1,
          emptyRowRange[1] - 1
        ];
        const isValidRange =
          !!this.backendStartFilteringRowRange &&
          this.backendStartFilteringRowRange[0] !== -1 &&
          this.backendStartFilteringRowRange[0] !== null &&
          this.backendStartFilteringRowRange[1] !== -1 &&
          this.backendStartFilteringRowRange[1] !== null;
        if (
          this.input.content &&
          this.contentData.allow_adding_items &&
          isValidRange
        ) {
          const newContent = this.getContentWithStrictOrder();
          const newContentString = newContent
            ? JSON.stringify(newContent)
            : null;
          if (newContentString !== this.input.content) {
            this.input.content = newContentString;
            saveInputObservable = this.saveInput(this.input);
          }
        }
      }
    }

    saveInputObservable
      .pipe(switchMap(() => this.getSpreadsheetObservable()))
      .subscribe(
        () => {},
        err => {
          this.showError = true;
          console.error(err);
        }
      );

    this.previewRows = Array(this.visibleRows.length || 50).fill(undefined);
    this.previewCols = Array(14).fill(undefined);

    if (this.keepFormulas) {
      // @ts-ignore
      Handsontable.plugins.registerPlugin('formulaPlugin', FormulaPlugin);
    }

    // @ts-ignore
    this.resizeObserver = new ResizeObserver(mutations => {
      if (this.hotInstance) {
        this.hotInstance.render();
      }
    });

    this.resizeObserver.observe(this.widthContainer.nativeElement);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.resizeObserver.disconnect();
  }

  getDescription() {
    return '';
  }

  getName() {
    return 'Spreadsheet';
  }

  getGroup(): string {
    return 'Generic';
  }

  validate() {
    // temporarily disable validation
    return of(true);

    return interval(250).pipe(
      filter(() => !!this.hotInstance),
      first(),
      switchMap(() => {
        this.invalidCellToFocus = null;
        this.triggeredValidation = true;
        const hotInstance = this.hotInstance;
        if (hotInstance) {
          hotInstance.lastChanges = null;
          hotInstance.dependentChanges = null;
        }
        const validateCallbackObserver$: Observable<boolean> = bindCallback(
          this.hotInstance.validateCells
        ).bind(this.hotInstance)();

        return validateCallbackObserver$.pipe(
          tap(isValid => {
            if (this.invalidCellToFocus) {
              this.hotInstance
                .getCell(this.invalidCellToFocus[0], this.invalidCellToFocus[1])
                ?.scrollIntoView({ block: 'center', inline: 'start' });
            }
          }),
          take(1)
        );
      })
    );
  }

  addRow(): void {
    const content = this.input.content ? JSON.parse(this.input.content) : {};
    if (this.isOpenEndedRowRange) {
      const lastRowInContent = content[this.visibleRows.length - 1];
      const isLastRowFilled =
        lastRowInContent &&
        Object.keys(lastRowInContent).some(key => !!lastRowInContent[key]);
      if (isLastRowFilled) {
        this.visibleRows.push(
          this.visibleRows[this.visibleRows.length - 1] + 1
        );
        const newData = this.filterRowsDataForOpenEndedRange(this.sheet).data;
        this.tableData = this.prepareTableData(newData);
        this.settings = {
          ...this.settings,
          data: this.tableData
        };
        this.hotInstance.updateSettings(this.settings, true);
      }
    } else {
      const fullIndex = this.getFirstEmptyDynamicRowIndex();
      if (fullIndex !== -1) {
        this.updateTableData('add', fullIndex);
      }
    }
  }

  exportXls() {
    this.downloadProgress = true;
    window.location.href = this.spreadsheetService.exportXlsUrl(
      this.input,
      this.contentData.apiResource
    );

    setTimeout(_ => (this.downloadProgress = false), 3000);
  }

  private getContentWithStrictOrder(): SpreadsheetInputContent {
    const content: SpreadsheetInputContent = this.input.content
      ? JSON.parse(this.input.content)
      : null;
    const range = parseColumnRange(
      this.contentData.specified_column_index_to_check_for_empty_rows
    );
    const filteringColumnIndexes = range?.length
      ? getNumberArrayFromRange(range[0], range[1])
      : [];
    const endIndex = this.backendStartFilteringRowRange[1];
    let startIndex = this.backendStartFilteringRowRange[0];
    if (content) {
      const sortedKeys = Object.keys(content).sort(
        (keyA, keyB) => Number(keyA) - Number(keyB)
      );

      return sortedKeys.reduce((accum, key) => {
        if (Number(key) < startIndex || Number(key) > endIndex) {
          accum[key] = content[key];
        } else if (
          isValueInNumberRange(Number(key), this.defaultDataBackendRowRange)
        ) {
          accum[key] = content[key];
          startIndex = startIndex + 1;
        } else {
          const newKey = String(startIndex);
          const isFilledRow = filteringColumnIndexes?.length
            ? content[key] &&
              Object.keys(content[key])?.some(
                columnIndex => !!content[key][columnIndex]
              )
            : filteringColumnIndexes.some(i => !!content[key]?.[i]);
          if (isFilledRow) {
            accum[newKey] = content[key];
            startIndex = startIndex + 1;
          }
        }

        return accum;
      }, {});
    }

    return null;
  }

  private get reloadData(): boolean {
    return !this.keepFormulas;
  }

  private get hotInstance() {
    return this.hotRegister.getInstance(this.hotId);
  }

  private getRealRow(fullIndex: number): number {
    if (fullIndex < 0) {
      return this.cellSettings.length + fullIndex;
    }
    const idx =
      this.visibleRows.length && !this.isOpenEndedRowRange
        ? this.visibleRows.indexOf(fullIndex)
        : fullIndex;

    return idx > -1 ? idx : null;
  }

  private getBackendRowIndex(realRowIndex: number): number {
    return this.displayedRows.length
      ? this.displayedRows[realRowIndex].rowBackendIndex
      : realRowIndex;
  }

  private getRealCol(fullIndex: number): number {
    const idx = this.visibleCols.length
      ? this.visibleCols.indexOf(fullIndex)
      : fullIndex;

    return idx > -1 ? idx : null;
  }

  private parseCellRange(
    range: string,
    useRealPositions = false,
    multipleRanges = false
  ): [number, number][] {
    const parsedRange = parseSpreadsheetRange(range, multipleRanges);
    const realRange: [number, number][] = [];
    if (parsedRange && parsedRange.length) {
      parsedRange.forEach(item => {
        const { rowRange, colRange } = item;
        const [rowFrom, rowTo] = [rowRange[0] - 1, rowRange[1] - 1];
        const [colFrom, colTo] = colRange;
        for (let rowIdx = rowFrom; rowIdx <= rowTo; rowIdx++) {
          for (let colIdx = colFrom; colIdx <= colTo; colIdx++) {
            const [rowIndex, colIndex] = useRealPositions
              ? [this.getRealRow(rowIdx), this.getRealCol(colIdx)]
              : [rowIdx, colIdx];
            if (rowIndex !== null && colIndex !== null) {
              realRange.push([rowIndex, colIndex]);
            }
          }
        }
      });
    }

    return realRange;
  }

  private makeAdditionalRequests(
    data: SpreadsheetResource
  ): Observable<SpreadsheetResource> {
    return of(data).pipe(
      switchMap(() => this.prepareApiData(data)),
      switchMap(() => this.prepareBuyerPersonas(data)),
      switchMap(() => of(data))
    );
  }

  private prepareBuyerPersonas(
    data: SpreadsheetResource
  ): Observable<SpreadsheetResource> {
    if (data.meta.options) {
      const needBuyerPersonas = Object.keys(data.meta.options)
        .map(cellsRange => data.meta.options[cellsRange]?.defaultData)
        .filter(item => instanceOfCellDefaultDataFromInput(item))
        .some((cellDD: CellDefaultDataFromInput) =>
          cellDD.items.some(item => item?.parseBuyerPersonaIndexes)
        );
      if (needBuyerPersonas) {
        return this.buyerPersonasList$.pipe(
          catchError(() => of(null)),
          tap(personas => (this.buyerPersonas = personas)),
          switchMap(() => of(data))
        );
      }
    }

    return of(data);
  }

  private prepareApiData(
    data: SpreadsheetResource
  ): Observable<SpreadsheetResource> {
    if (data.meta.options) {
      const apis = Object.keys(data.meta.options)
        .map(cellsRange => data.meta.options[cellsRange]?.api)
        .filter(Boolean);

      return this.navService.organization$.pipe(
        switchMap(orgId =>
          forkJoin(
            apis.map(api =>
              this.moduleService.getAPIData(orgId, api.endpointName, true).pipe(
                catchError(() => of(null)),
                map(apiData => [api, apiData])
              )
            )
          ).pipe(
            tap(
              (info: [SpreadsheetCellApiData, ApiDataType][]) =>
                (this.apiData = info.reduce(
                  (accum: SpreadsheetApiData, apiInfo) => {
                    const [api, apiData] = apiInfo;
                    if (api.endpointName && !accum[api.endpointName]) {
                      const dataArray = (Array.isArray(apiData)
                        ? apiData
                        : Object.values(apiData || {})) as ApiDataDefaultType;
                      accum[api.endpointName] = [
                        ...dataArray
                      ] as ApiDataDefaultType;
                    }

                    return accum;
                  },
                  {}
                ))
            ),
            switchMap(() => of(data))
          )
        )
      );
    }

    return of(data);
  }

  private getSpreadsheetObservable(): Observable<SpreadsheetResource> {
    return this.spreadsheetService
      .getSpreadsheet(
        this.input,
        this.contentData.apiResource,
        this.isOpenEndedRowRange ? '' : this.visibleRowsStr,
        this.visibleCols,
        this.keepFormulas,
        this.meta_sufix,
        this.isOpenEndedRowRange
      )
      .pipe(
        takeUntil(this.stepTransition$),
        switchMap(data => this.makeAdditionalRequests(data)),
        tap(data => {
          if (!(data.data instanceof Array)) {
            data.data = [];
          }

          const maxRows =
            data.data?.length > data.types?.length ? data.data : data.types;

          this.cellSettings = maxRows.map(row =>
            row.map(() => ({
              editable: false,
              className: '',
              renderer: null,
              placeholder: ''
            }))
          );

          if (this.contentData.hideCols) {
            this.hideCols = this.spreadsheetService
              .getParsedRange(
                this.textContent(this.contentData.hideCols),
                'column'
              )
              .reduce((accum, col) => {
                accum[this.getRealCol(col)] = true;

                return accum;
              }, {});
          }

          this.types = maxRows.map((row, rowIndex) =>
            row.map((item, cellIndex) => {
              let cell = data.data?.[rowIndex]?.[cellIndex];
              if (cell === undefined) {
                data.data[rowIndex] = data.data[rowIndex] || [];
                data.data[rowIndex][cellIndex] =
                  data.data[rowIndex][cellIndex] || null;

                return null;
              }
              if (cell === null) {
                return null;
              }

              let cellType = null;
              if (typeof cell === 'string') {
                if (cell.match(/^[\.0-9]+\%$/)) {
                  cell = (parseFloat(cell) / 100).toString();
                  cellType = 'percent';
                } else if (cell.match(/^\$[ ]{0,}[\.,0-9]+$/)) {
                  cell = parseFloat(cell.split(/[^\.0-9]/).join('')).toString();
                  cellType = 'currency';
                } else if (cell.match(/^[\.,0-9]+$/)) {
                  cell = parseFloat(cell.split(/[^\.0-9]/).join('')).toString();
                  cellType = 'numeric';
                } else if (cell.substr(0, 1) === '=') {
                  cellType = 'numeric';
                }
              }

              data.data[rowIndex][cellIndex] = cell;

              return cellType;
            })
          );

          this.rounding = data.data.map((row, rowIndex) =>
            row.map((cell, cellIndex) => 2)
          );

          const types = { p: 'percent', c: 'currency', n: 'numeric' };
          const REVENUE_MODEL_MODULE = 5;
          data.types.forEach((row, rowIndex) =>
            row.forEach((type, cellIndex) => {
              if (type.length === 2) {
                if (this.types[rowIndex]) {
                  this.types[rowIndex][cellIndex] = types[type[0]];
                  // Avoid rounding percent values in revenue model module (15)
                  if (this.data.data.module_id !== REVENUE_MODEL_MODULE)
                    this.rounding[rowIndex][cellIndex] = Number(type[1]);
                }
              }
            })
          );

          this.cellSettings = (data.meta.editable || []).reduce(
            (editable, range, index) => {
              const parsedRange = this.parseCellRange(range, true);
              parsedRange.forEach(cellPosition => {
                const [realRow, realCol] = cellPosition;
                if (editable?.[realRow]?.[realCol]) {
                  editable[realRow][realCol].editable = !this.disabled;
                }
              });

              return editable;
            },
            this.cellSettings
          );

          if (this.contentData.limit_editable_cell_range) {
            const limitRange = this.parseCellRange(
              this.contentData.limit_editable_cell_range,
              true,
              true
            );
            limitRange.forEach(cellPosition => {
              const [realRow, realCol] = cellPosition;
              if (this.cellSettings?.[realRow]?.[realCol]) {
                this.cellSettings[realRow][realCol].editable = false;
              }
            });
          }

          this.cellSettings = this.cellSettings.map((row, rowId) =>
            row.map((cell, columnId) => ({
              ...cell,
              editable:
                data.data[rowId] &&
                isFormulaString(data.data[rowId][columnId]) &&
                data.data[rowId][columnId] !== '=FALSE()'
                  ? false
                  : cell.editable
            }))
          );

          const metaConfig = (field: string, callback) => {
            this.cellSettings = Object.entries(data.meta[field] || {}).reduce(
              (settings, [range, value]) => {
                if ('*' === range.substr(-1)) {
                  range = range.slice(0, -1) + 'A-' + data.meta.maxColumn;
                }

                const rowRange = range.match(/[\-0-9]+/)[0].split('-');
                const colRange = range
                  .split(/[0-9]/)
                  .join('')
                  .match(/[\-A-Z]+/)[0]
                  .split('-')
                  .filter(a => a);

                const from = getColNumericIndex(colRange[0]);
                const to = getColNumericIndex(colRange[colRange.length - 1]);

                for (let col = from; col <= to; col++) {
                  for (
                    let row = Number(rowRange[0]);
                    row <= Number(rowRange[rowRange.length - 1]);
                    row++
                  ) {
                    const realRow = this.getRealRow(row - 1);
                    const realCol = this.getRealCol(col);
                    if (
                      realRow !== null &&
                      realCol !== null &&
                      settings[realRow]
                    ) {
                      callback(
                        settings[realRow][realCol],
                        value,
                        realRow,
                        realCol
                      );
                    }
                  }
                }

                return settings;
              },
              this.cellSettings
            );
          };

          let triggerInputSave = false;
          metaConfig(
            'types',
            (cell, type, row, col) => (this.types[row][col] = type)
          );
          metaConfig('formatting', (cell, classNames) => {
            if (cell) {
              return (cell.className += ' ' + classNames);
            }
          });
          metaConfig(
            'renderer',
            (cell, renderer) => (cell.renderer = renderer)
          );
          metaConfig('requireValue', (cell, value: ValidatorType) => {
            cell.requireValue = value;
            cell.validator = (cellValue, cb) => {
              if (!Number(cellValue) && !this.triggeredValidation) {
                cb(true);
              } else if (value instanceof Array) {
                cb(cellValue >= value[0] && cellValue <= value[1]);
              } else if (typeof value === 'boolean' && value) {
                cb(
                  isNaN(Number(cellValue)) ? !!cellValue : !!Number(cellValue)
                );
              } else {
                cb(
                  value === cellValue ||
                    Math.abs(Number(value) - Number(cellValue)) < 0.0001
                );
              }
            };
          });
          metaConfig(
            'rendererOptions',
            (cell, rendererOptions) => (cell.rendererOptions = rendererOptions)
          );
          metaConfig(
            'dropdownOptions',
            (cell, dropdownOptions) => (cell.dropdownOptions = dropdownOptions)
          );
          metaConfig(
            'placeholders',
            (cell, placeholder) => (cell.placeholder = placeholder)
          );
          metaConfig(
            'options',
            (cell, options: SpreadsheetCellMetaOptions, row, col) => {
              if (options?.defaultData && !data.data[row][col]) {
                const defaultValue = this.prepareDefaultDataForCell(
                  options.defaultData
                );
                if (defaultValue) {
                  data.data[row][col] = defaultValue;
                  const content = JSON.parse(this.input.content || null) || {};
                  content[row] = content[row] || {};
                  content[row][col] = defaultValue;
                  this.input.content = JSON.stringify(content);
                  triggerInputSave = true;
                }
              }
              if (cell) {
                cell.options = options;
              }
            }
          );

          if (triggerInputSave) {
            this.moduleService.saveInput(this.input).subscribe();
          }
          data.data = data.data.map((row, rowIndex) =>
            row.map((cell, colIndex) => {
              if (this.types[rowIndex][colIndex] === 'checkbox') {
                return !!cell && cell !== '=FALSE()';
              }

              return cell;
            })
          );

          this.rowsWithValidators = this.cellSettings.reduce(
            (accum: { [rowId: number]: boolean }, row, rowIndex) => {
              if (row.some(cell => !!cell.validator)) {
                accum[rowIndex] = true;
              }

              return accum;
            },
            {}
          );

          if (this.contentData.allow_deleting_items) {
            if (this.contentData.deleting_row_range) {
              const deleteRowRange = parseNumberRange(
                this.contentData.deleting_row_range
              );
              this.deleteRowRange = [
                deleteRowRange[0] - 1,
                deleteRowRange[1] - 1
              ];
            }
            data = {
              ...data,
              data: data.data.map(row => [...row, ''])
            };
            this.types = this.types.map(row => [...row, 'delete-cell']);
            this.cellSettings = this.cellSettings.map((row, rowId) => [
              ...row,
              {
                editable: false,
                className: `delete-cell row-${rowId}`,
                renderer: 'deleteCellRenderer'
              }
            ]);
            this.deleteColumnIndex = data.data?.[0]?.length - 1;
          }

          this.sheet = data;
          this.cellRendererDependencyTable = this.prepareDependencyRendererTable(
            this.cellSettings
          );

          data.data = data.data.map(item =>
            item.map(str => (str === 'YOUR COMPANY' ? '' : str))
          );

          if (this.isOpenEndedRowRange) {
            this.sheet = this.filterRowsDataForOpenEndedRange(this.sheet);
          }

          if (this.contentData.enable_filtering_empty_rows) {
            if (
              this.contentData.specified_column_index_to_check_for_empty_rows
            ) {
              const range = parseColumnRange(
                this.contentData.specified_column_index_to_check_for_empty_rows
              );
              this.filteringIndexes = range?.length
                ? getNumberArrayFromRange(range[0], range[1])
                : [];
            }

            this.realStartFilteringRowRange = this.contentData.filtering_empty_row_range
              ?.split(',')
              .map(i => {
                const filteringRange = parseNumberRange(i);
                const range: [number, number] =
                  isEmptyValue(filteringRange[0]) &&
                  isEmptyValue(filteringRange[1])
                    ? null
                    : [
                        this.getRealRow(filteringRange[0] - 1),
                        this.getRealRow(filteringRange[1] - 1)
                      ];

                return range;
              })
              .filter(Boolean);
            this.emptyRows = this.getEmptyRows(this.sheet.data);
          }
          this.visibleRows = this.visibleRows.length
            ? this.visibleRows
            : this.sheet.data.map((row, rowId) => rowId);
          this.prepareTableRowIndexes();
          this.displayedRows = this.visibleRows
            .map((realRowId, rowIndex) =>
              this.emptyRows?.[rowIndex]
                ? null
                : { rowBackendIndex: realRowId, fullTableIndex: rowIndex }
            )
            .filter(Boolean);

          this.tableData = this.prepareTableData([...this.sheet.data]);

          if (
            environment.allowDynamicApi &&
            data?.meta?.colWidths?.length === 4
          ) {
            data.meta.colWidths = [50, 50, 100, 100];
          }

          const totalWidth =
            (data.meta.colWidths || []).reduce(
              (a, b, idx) =>
                (!this.visibleCols.length || this.visibleCols.includes(idx)) &&
                !this.hideCols?.[this.getRealCol(idx)]
                  ? a + b
                  : a,
              0
            ) +
            (this.contentData.allow_deleting_items
              ? data.meta.deleteColWidth || 50
              : 0);

          if (this.defaultDataBackendRowRange) {
            this.defaultDataLocalRowRange = [
              this.getRealRow(this.defaultDataBackendRowRange[0]),
              this.getRealRow(this.defaultDataBackendRowRange[1])
            ];
          }

          if (this.contentData.save_default_data_in_input) {
            this.saveDefaultDataInInput(this.tableData);
          }

          this.settings = {
            autoRowSize: false,
            autoColumnSize: false,
            renderAllRows: true,
            initialData: this.sheet.data,
            tableRowIndexes: this.tableRowIndexes,
            displayedRows: this.displayedRows,
            cellRendererDependencyTable: this.cellRendererDependencyTable,
            data: this.tableData,
            cells: this.formatCell.bind(this),
            rowHeaders: false,
            colHeaders: false,
            disableVisualSelection: Boolean(
              this.contentData.disable_not_active_cell_visual_selection
            ),
            afterRender: () => {
              this.isRendered = true;
              if (
                this.rowsWithValidators &&
                !isEmptyObject(this.rowsWithValidators)
              ) {
                const changes: [number, number][] =
                  this.hotInstance?.dependentChanges ||
                  this.hotInstance?.lastChanges;
                const localRowsObject = Object.keys(
                  this.rowsWithValidators
                ).reduce((accum, tableRowIndex) => {
                  const localRowIndex = this.tableRowIndexes[
                    Number(tableRowIndex)
                  ];
                  if (!isEmptyValue(localRowIndex)) {
                    accum[localRowIndex] = true;
                  }

                  return accum;
                }, {});
                const rowsObject = changes
                  ? changes.reduce(
                      (accum: { [rowId: number]: boolean }, cell) => {
                        if (localRowsObject[cell[0]]) {
                          accum[cell[0]] = true;
                        }

                        return accum;
                      },
                      {}
                    )
                  : localRowsObject;
                const rowsToValidate: number[] = Object.keys(
                  rowsObject
                ).map(key => Number(key));
                if (rowsToValidate?.length) {
                  this.hotInstance?.validateRows(rowsToValidate);
                }
              }
            },
            afterValidate: (table, isValid, value, row, column, source) => {
              if (!isValid) {
                this.invalidCellToFocus =
                  !this.invalidCellToFocus ||
                  (row <= this.invalidCellToFocus[0] &&
                    column <= this.invalidCellToFocus[1])
                    ? [row, column]
                    : this.invalidCellToFocus;
              }
            },
            beforeChange: this.beforeChange.bind(this),
            afterChange: this.afterChange.bind(this),
            afterBeginEditing: (event, row, column) => {
              const realRow = column === undefined ? event : row;
              const realColumn = column === undefined ? row : column;
              const cell = this.hotInstance.getCell(realRow, realColumn);
              const editor = this.hotInstance.getActiveEditor();

              const textarea = editor.TEXTAREA;
              textarea.style.lineHeight = document.defaultView
                .getComputedStyle(cell, null)
                .getPropertyValue('line-height');

              const contentElement =
                cell.getElementsByClassName('cell-content-value')?.[0] ||
                cell.getElementsByClassName('cell-content-wrapper')?.[0];
              if (contentElement) {
                const topOffset =
                  (contentElement.offsetHeight
                    ? contentElement?.offsetTop
                    : textarea.style?.lineHeight
                    ? (cell?.offsetHeight -
                        parseFloat(textarea.style.lineHeight)) /
                      2
                    : 2) || 0;
                textarea.style['padding-top'] = `${topOffset}px`;
              }
              const contentWidth = contentElement?.clientWidth
                ? contentElement.clientWidth + 8
                : cell.clientWidth;
              const paddingLeft =
                (contentElement
                  ? parseFloat(
                      window
                        .getComputedStyle(contentElement, null)
                        .getPropertyValue('padding-left')
                    ) || 0
                  : 0) + 3;
              textarea.style['padding-left'] = `${paddingLeft}px`;

              Object.assign(textarea.parentNode.style, {
                width: String(contentWidth - 2) + 'px',
                height: String(cell.clientHeight - 2) + 'px'
              });
            },
            afterOnCellMouseUp: (par1, par2, par3) => {
              const cellCoords: { row: number; col: number } = [
                par1,
                par2,
                par3
              ].find(par => par.row);
              const editor = this.hotInstance.getActiveEditor();
              if (
                cellCoords &&
                cellCoords.row &&
                this.cellSettings[cellCoords.row] &&
                this.cellSettings[cellCoords.row][cellCoords.col].editable &&
                this.types &&
                this.types[cellCoords.row][cellCoords.col] === 'text' &&
                editor?.cellProperties?.row === cellCoords.row &&
                editor?.cellProperties?.col === cellCoords.col
              ) {
                // @ts-ignore
                editor.enableFullEditMode();
                // @ts-ignore
                editor.beginEditing();
              }
            },
            afterOnCellMouseDown: (par1, par2, par3) => {
              const cellCoords: { row: number; col: number } = [
                par1,
                par2,
                par3
              ].find(par => par.row);
              if (
                cellCoords &&
                cellCoords.row &&
                cellCoords.col &&
                this.types[cellCoords.row][cellCoords.col] === 'delete-cell' &&
                this.isVisibleRowDeleteCell(cellCoords.row)
              ) {
                this.deleteTableRow(cellCoords.row);
              }
            },
            beforeKeyDown: (core: KeyboardEvent, event?) => {
              const keyEvent = core.keyCode ? core : event;
              const hot = this.hotInstance;
              const selectCell = hot.getSelected()[0];
              switch (keyEvent.keyCode) {
                case 13:
                  if (
                    !this.contentData.leaveTextCellsOnEnterKey &&
                    this.types &&
                    this.types[selectCell[0]][selectCell[1]] === 'text'
                  ) {
                    keyEvent.stopImmediatePropagation();
                    (keyEvent.target as HTMLInputElement).value =
                      (keyEvent.target as HTMLInputElement).value + '';
                  }
                  break;

                default:
                  break;
              }
            },
            invalidCellClassName: 'invalidCell',
            rowHeights: rowIndex =>
              this.widthContainer.nativeElement.clientWidth / 46,
            colWidths: col => {
              const realCol = this.visibleCols.length
                ? this.visibleCols[col]
                : col;
              let width = data.meta.colWidths && data.meta.colWidths[realCol];

              if (this.hideCols?.[col]) {
                return 1;
              }

              if (1 === width) {
                return 1;
              }

              if (
                this.contentData.allow_deleting_items &&
                this.deleteColumnIndex &&
                col === this.deleteColumnIndex
              ) {
                width = data.meta.deleteColWidth || 50;
              }

              const elementClientWidth = this.widthContainer.nativeElement
                .clientWidth;
              const clientWidth =
                environment.allowDynamicApi && elementClientWidth
                  ? (data.data[0].length === 4 &&
                      data.data[0].includes('EXAMPLE') &&
                      this.data.data.step_id !== 9044) ||
                    this.data.data.step_id === 9043
                    ? 1620
                    : 750
                  : this.data.data.step_id === 9039
                  ? 750
                  : elementClientWidth;

              return (width || 50) * ((clientWidth + 5) / totalWidth);
            },
            mergeCells: (this.sheet.meta.mergeCells || [])
              .filter(
                cell =>
                  !isEmptyValue(
                    this.tableRowIndexes[this.getRealRow(cell.row)]
                  ) && !isEmptyValue(this.getRealCol(cell.col))
              )
              .map(c => {
                const localRowIndex = this.tableRowIndexes[
                  this.getRealRow(c.row)
                ];
                let colspan = Math.min(
                  c.colspan,
                  data.data[localRowIndex]?.length - c.col
                );

                if (environment.allowDynamicApi && colspan > 1) {
                  colspan -= 1;
                }

                const cell = {
                  ...c,
                  row: localRowIndex,
                  col: this.getRealCol(c.col),
                  colspan
                };
                const isConditionsFulfilled =
                  !c?.mergeConditions ||
                  c.mergeConditions.every(cond => {
                    const cellToCheck = cond.cellToCheck
                      ? {
                          row: this.tableRowIndexes[
                            this.getRealRow(cond.cellToCheck.row)
                          ],
                          col: this.getRealCol(cond.cellToCheck.col)
                        }
                      : { row: cell.row, col: cell.col };
                    if (
                      isEmptyValue(cellToCheck.row) ||
                      isEmptyValue(cellToCheck.col)
                    ) {
                      return false;
                    }
                    const cellData = this.sheet.data[cellToCheck.row]?.[
                      cellToCheck.col
                    ];

                    return (
                      (cond.mergeIfEmpty && !cellData) ||
                      (cond.mergeIfFilled && !!cellData) ||
                      (!cond.mergeIfEmpty && !cond.mergeIfFilled)
                    );
                  });
                if (!isConditionsFulfilled) {
                  return null;
                }

                return cell;
              })
              .filter(Boolean),
            className: 'spreadsheet-table'
          };
          if (
            this.data.data.parent_step_id === 19663 &&
            environment.allowDynamicApi
          ) {
            this.settings.mergeCells = this.settings.mergeCells.map(el => {
              el.colspan = el.colspan > 1 ? el.colspan-- : el.colspan;

              return el;
            });

            const dataWithoutExample = this.settings.data.map(row =>
              row.filter((el, index) => index !== 2)
            );

            this.settings.data = dataWithoutExample;
          }
        })
      );
  }

  private deleteTableRow(rowIdToDelete: number): void {
    this.confirmationService.removeDialog({ text: 'row' }).subscribe(() => {
      const content: SpreadsheetInputContent = this.input.content
        ? JSON.parse(this.input.content)
        : undefined;
      const realIdToDelete = this.displayedRows[rowIdToDelete].rowBackendIndex;
      const fullIndex = this.displayedRows[rowIdToDelete].fullTableIndex;
      if (!!content) {
        const sortedKeys = Object.keys(content).sort(
          (keyA, keyB) => Number(keyA) - Number(keyB)
        );
        const isDefaultDataRow = isValueInNumberRange(
          realIdToDelete,
          this.defaultDataBackendRowRange
        );
        const newContent = sortedKeys.reduce((accum, key) => {
          const keyNumber = Number(key);
          if (
            keyNumber < realIdToDelete ||
            keyNumber > this.deleteRowRange[1]
          ) {
            accum[key] = content[key];
          } else if (keyNumber !== realIdToDelete) {
            const newKey = String(keyNumber - 1);
            accum[newKey] = content[key];
          }

          return accum;
        }, {});
        if (isDefaultDataRow) {
          const lastDefaultDataRow = this.defaultDataLocalRowRange[1];
          const lastBackendRowIndex = this.defaultDataBackendRowRange[1];
          if (
            this.sheet.data[lastDefaultDataRow] &&
            !isEmptyValue(lastBackendRowIndex) &&
            isEmptyValue(newContent[lastBackendRowIndex])
          ) {
            newContent[lastBackendRowIndex] = this.sheet.data[
              lastDefaultDataRow
            ].reduce(
              (
                accum: { [columnIndex: number]: SpreadsheetCellData },
                value,
                columnIndex
              ) => {
                const dataCol = this.visibleCols.length
                  ? this.visibleCols[columnIndex]
                  : columnIndex;
                if (!isFormulaString(value) && !isEmptyValue(dataCol)) {
                  accum[dataCol] = '';
                }

                return accum;
              },
              {}
            );
          }
        }
        this.input.content = JSON.stringify(newContent);
        if (this.openEndedStartingRow) {
          this.visibleRows.pop();
        }
        if (this.reloadData) {
          this.confirmationService.showLoadingSpinner();
          this.moduleService.saveInput(this.input).subscribe(
            () =>
              this.getSpreadsheetObservable().subscribe(
                () => {
                  this.updateTableData('delete', rowIdToDelete);
                  this.confirmationService.stopLoadingSpinner();
                },
                () => this.confirmationService.stopLoadingSpinner()
              ),
            () => this.confirmationService.stopLoadingSpinner()
          );
        } else {
          this.moduleService.saveInput(this.input).subscribe();
          this.updateTableData('delete', rowIdToDelete);
        }
      } else if (!this.emptyRows[fullIndex]) {
        this.updateTableData('delete', rowIdToDelete);
      }
    });
  }

  private getFirstEmptyDynamicRowIndex(): number {
    let filterRange: [number, number] = this.realStartFilteringRowRange[0];
    const firstEmptyDynamicRowIndex = this.emptyRows.findIndex((row, rowId) => {
      filterRange = this.realStartFilteringRowRange?.find(
        r => rowId >= r[0] && rowId <= r[1]
      );

      return filterRange && !!row;
    });
    if (firstEmptyDynamicRowIndex === -1 || !filterRange) {
      return -1;
    }
    const isVeryFirstDynamicRow =
      this.visibleRows[firstEmptyDynamicRowIndex] ===
      this.visibleRows[filterRange[0]];
    if (isVeryFirstDynamicRow) {
      return firstEmptyDynamicRowIndex;
    }
    const lastDisplayedDynamicRowIndex = firstEmptyDynamicRowIndex - 1;
    const realRowIndex = this.visibleRows[lastDisplayedDynamicRowIndex];
    const content = this.input.content ? JSON.parse(this.input.content) : {};

    const isLastDisplayedRowHasContent =
      realRowIndex !== -1 &&
      ((!this.filteringIndexes?.length &&
        content[realRowIndex] &&
        Object.keys(content[realRowIndex]).some(
          key => content[realRowIndex][key]
        )) ||
        (this.filteringIndexes?.length &&
          this.filteringIndexes?.some(
            i => !!this.settings.data?.[lastDisplayedDynamicRowIndex]?.[i]
          )));
    if (isLastDisplayedRowHasContent) {
      return lastDisplayedDynamicRowIndex + 1;
    }

    return -1;
  }

  private prepareTableData(
    data: SpreadsheetCellData[][]
  ): SpreadsheetCellData[][] {
    return this.displayedRows.reduce((accum, displayedRow) => {
      if (data?.[displayedRow.fullTableIndex]) {
        accum.push([...data[displayedRow.fullTableIndex]]);
      }

      return accum;
    }, []);
  }

  private updateTableData(action: 'delete' | 'add', tableRowId: number): void {
    const currentData = this.tableData;
    switch (action) {
      case 'add': {
        const rowsNumberToAdd = this.contentData.number_of_rows_to_add_on_click;
        const rowsToAdd = this.emptyRows
          .slice(tableRowId, tableRowId + rowsNumberToAdd)
          .map(() => false);
        const newIndexes: SpreadsheetRowInfo[] = rowsToAdd.map(
          (item, index) => ({
            rowBackendIndex: this.visibleRows[tableRowId + index],
            fullTableIndex: tableRowId + index
          })
        );
        const newRows = rowsToAdd.map((item, index) => [
          ...this.sheet.data[tableRowId + index].map(cell =>
            isFormulaString(cell) ? cell : ''
          )
        ]);
        this.emptyRows.splice(tableRowId, rowsToAdd.length, ...rowsToAdd);
        this.displayedRows.splice(tableRowId, 0, ...newIndexes);
        this.prepareTableRowIndexes();
        this.tableData.splice(tableRowId, 0, ...newRows);
        break;
      }
      case 'delete': {
        let lastDynamicRowIndex = tableRowId;
        const oldData = currentData.map(row => row.map(cell => cell));
        currentData.forEach((row, rowId) => {
          const isInRange = this.realStartFilteringRowRange.some(
            r =>
              this.displayedRows[rowId].fullTableIndex >= r[0] &&
              this.displayedRows[rowId].fullTableIndex <= r[1]
          );
          if (rowId > tableRowId && isInRange) {
            row.forEach(
              (cell, columnId) =>
                (row[columnId] = isFormulaString(cell)
                  ? oldData[rowId - 1][columnId] || ''
                  : cell)
            );
            lastDynamicRowIndex = rowId;
          }
        });
        currentData.splice(tableRowId, 1);

        const fullIndex = this.displayedRows[lastDynamicRowIndex]
          .fullTableIndex;
        this.emptyRows.splice(fullIndex, 1, true);
        this.displayedRows.splice(lastDynamicRowIndex, 1);
        this.prepareTableRowIndexes();
        this.tableData = currentData;
        break;
      }
    }
    this.hotInstance.lastChanges = null;
    this.hotInstance.dependentChanges = null;
    this.hotInstance.render();
  }

  private prepareDependencyRendererTable(
    settings: CellSettings[][]
  ): SpreadsheetCellDependency[][] {
    const addNewDependency = (
      table: SpreadsheetCellDependency[][],
      depRowIndex: number,
      depColIndex: number,
      refRowIndex: number,
      refColIndex: number
    ): void => {
      if (depRowIndex === refRowIndex && depColIndex === refColIndex) {
        return;
      }
      if (table[refRowIndex][refColIndex]) {
        table[refRowIndex][refColIndex].referenceIn.push([
          depRowIndex,
          depColIndex
        ]);
      } else {
        table[refRowIndex][refColIndex] = {
          dependOn: [],
          referenceIn: [[depRowIndex, depColIndex]]
        };
      }
    };
    const blankTable = settings.map(row => row.map(() => null));

    return settings.reduce(
      (dependencyTable: SpreadsheetCellDependency[][], row, rowIndex) => {
        row.forEach((cell, columnIndex) => {
          switch (cell.renderer) {
            case 'isEqualToCellsSum': {
              if (cell.rendererOptions?.columns) {
                const columnRange = parseColumnRange(
                  cell.rendererOptions.columns
                );
                const [startCol, endCol] = [
                  this.getRealCol(columnRange[0]),
                  this.getRealCol(columnRange[1])
                ];
                for (let i = startCol; i <= endCol; i++) {
                  addNewDependency(
                    dependencyTable,
                    rowIndex,
                    columnIndex,
                    rowIndex,
                    i
                  );
                }
              }
              break;
            }
            case 'isEqualToPreviousColumnCell': {
              const prevColumnIndex = columnIndex - 1;
              addNewDependency(
                dependencyTable,
                rowIndex,
                columnIndex,
                rowIndex,
                prevColumnIndex
              );
              break;
            }
            case 'aboveBelowQuota': {
              if (cell.rendererOptions?.controlCell) {
                const controlCell = this.parseCellRange(
                  cell.rendererOptions.controlCell,
                  true
                )[0];
                addNewDependency(
                  dependencyTable,
                  rowIndex,
                  columnIndex,
                  controlCell[0],
                  controlCell[1]
                );
              }
              break;
            }
          }
        });

        return dependencyTable;
      },
      blankTable
    );
  }

  private formatCell(row: number, column: number): Partial<HotCell> {
    const rowInfo = this.displayedRows[row];
    const cell: Partial<HotCell> = {
      row,
      col: column,
      rowBackendIndex: rowInfo.rowBackendIndex,
      fullTableIndex: rowInfo.fullTableIndex
    };

    const fullTableRowIndex = rowInfo.fullTableIndex;

    const settings = this.cellSettings[fullTableRowIndex][column];

    if (!settings) {
      return cell;
    }

    cell.readOnly = !settings.editable;
    if (!cell.readOnly) {
      cell.className += ' editable';
    }

    if (settings.options) {
      cell.options = settings.options;
    }

    let tp = this.types[fullTableRowIndex][column];
    if (tp && tp === 'checkbox') {
      cell.type = 'checkbox';
      cell.className += ' checkbox';
    } else if (tp && tp === multipleCheckboxes) {
      if (
        cell.options?.api?.endpointName &&
        this.apiData[cell.options.api.endpointName]?.length
      ) {
        cell.options.checkboxes = this.apiData[cell.options.api.endpointName]
          .map(item => ({
            index: item.uuid || String(item.index),
            value: item[cell.options.api.fieldName || 'name']
          }))
          .filter(item => !!item.value);
      }
      if (cell.options?.checkboxes?.length) {
        cell.type = multipleCheckboxes;
        cell.className += ' multiple-checkboxes';
      } else {
        cell.type = SpreadsheetCellTypes.text;
        tp = SpreadsheetCellTypes.text;
        this.types[fullTableRowIndex][column] = SpreadsheetCellTypes.text;
      }
    } else if (tp && tp === 'date') {
      cell.type = 'date';
      cell.dateFormat = 'MM/DD/YYYY';
      cell.className += ' date';
      cell.validator = (value, callback) => callback(true);
    } else if (tp && tp === 'dropdown' && settings?.dropdownOptions) {
      cell.type = 'dropdown';
      cell.source = [...settings.dropdownOptions];
    } else if (tp && tp === 'month-dropdown') {
      cell.type = 'dropdown';
      cell.source = [...monthsArray];
    } else if (tp && tp !== 'text') {
      cell.type = 'numeric';
      cell.extendedType = tp;

      if (!cell.readOnly) {
        cell.validatorName = 'numeric';
      }

      if ('currency' === tp) {
        cell.numericFormat = {
          pattern: {
            thousandSeparated: true,
            optionalMantissa: true,
            output: 'currency',
            mantissa: 0
          },
          culture: 'en-US'
        };

        cell.className += ' currency';
      } else if ('percent' === tp) {
        cell.numericFormat = {
          pattern: {
            trimMantissa: true,
            output: 'percent',
            thousandSeparated: true
          }
        };

        // @ts-ignore - looks like a wrong type definition within angular/handsontable v3.0.0 (5.0.0 works fine)
        cell.editor = PercentageEditor;

        cell.className += ' percent';
      } else if ('numeric' === tp) {
        cell.numericFormat = {
          pattern: {
            thousandSeparated: true,
            optionalMantissa: true
          }
        };
      }

      cell.className += ' numeric';

      if (cell.numericFormat && 'currency' !== tp) {
        cell.numericFormat.pattern.mantissa = this.rounding[fullTableRowIndex][
          column
        ];
      }
    }
    cell.className += ` row-${fullTableRowIndex} col-${column}`;
    if (settings.className) {
      cell.className += ' ' + settings.className;
    }
    if (this.hideCols?.[column]) {
      cell.className += ' hide-column';
    }
    if (
      this.contentData.enable_filtering_empty_rows &&
      this.emptyRows[fullTableRowIndex]
    ) {
      cell.className += ' empty-row-cell';
    }
    if (this.cellRenderers[tp]) {
      cell.renderer = this.cellRenderers[tp];
    }

    if (settings.renderer && this.cellRenderers[settings.renderer]) {
      cell.renderer = this.cellRenderers[settings.renderer];
    }

    if (cell.renderer) {
      cell.renderer = wrapRendererFunction(cell.renderer);
    }

    if (cell.options?.staticText) {
      cell.renderer = wrapStaticTextRenderer(cell.renderer);
    }

    if (tp && tp === 'xFormat' && cell.renderer) {
      cell.renderer = wrapXFormatRenderer(cell.renderer);
    }

    if (settings?.rendererOptions?.colorPalette && cell.renderer) {
      cell.renderer = wrapDynamicColorRenderer(cell.renderer);
    }

    if (this.cellEditors[tp]) {
      cell.editor = this.cellEditors[tp];
    }

    if (settings.validator) {
      cell.validator = settings.validator;
    }

    if (settings.placeholder) {
      cell.placeholder = settings.placeholder;
    }

    return cell;
  }

  private prepareTableRowIndexes(): void {
    let rowLength = 0;
    const getRowIndex = (rowIndex): number => {
      if (this.emptyRows?.[rowIndex]) {
        return null;
      } else {
        rowLength = rowLength + 1;

        return rowLength - 1;
      }
    };
    const rows = this.tableRowIndexes.length
      ? this.tableRowIndexes
      : this.sheet.data;
    rows.forEach((row, rowIndex) => {
      this.tableRowIndexes[rowIndex] = getRowIndex(rowIndex);
    });
  }

  private beforeChange(
    context,
    changes: HandsontableCellChange[],
    source: string
  ) {
    if (!isEditChange(context, changes, source)) {
      return;
    }

    const hotInstance = this.hotInstance;
    if (hotInstance) {
      this.hotInstance.lastChanges = [];
    }

    // sometimes the context argument is not passed (HOT 6 only)
    if (!source) {
      changes = context;
    }

    if (!changes.filter(change => isCellChanged(change)).length) {
      return;
    }

    for (let i = changes.length - 1; i >= 0; i--) {
      const change = changes[i];
      const newVal = change[3];
      const row = this.displayedRows[change[0]].fullTableIndex;

      const tp: SpreadsheetCellType = this.types[row][change[1]];

      if (tp && (tp === 'numeric' || tp === 'currency' || tp === 'percent')) {
        if (typeof newVal === 'string' && newVal) {
          changes[i][3] = parseFloat(purifyNumberString(newVal));
        }
      }

      changes[i][4] = changes[i][3];
    }
    if (hotInstance) {
      hotInstance.lastChanges = changes.map(change => [change[0], change[1]]);
    }
  }

  private afterChange(
    context,
    changes: HandsontableCellChange[],
    source: string
  ) {
    if (!isEditChange(context, changes, source)) {
      return;
    }

    // sometimes the context argument is not passed (HOT 6 only)
    if (!source) {
      changes = context;
    }

    if (!changes.filter(change => isCellChanged(change)).length) {
      return;
    }

    const content: SpreadsheetInputContent = this.input.content
      ? JSON.parse(this.input.content)
      : {};

    changes.forEach(change => {
      const row = this.displayedRows[change[0]].fullTableIndex;
      const col = Number(change[1]);

      const dataRow = this.visibleRows.length ? this.visibleRows[row] : row;
      const dataCol = this.visibleCols.length ? this.visibleCols[col] : col;

      content[dataRow] = content[dataRow] || {};
      content[dataRow][dataCol] =
        typeof change[4] === 'string' ? sanitizeHtml(change[4]) : change[4];

      if (this.reloadData) {
        this.hotInstance.getCell(row, col).className += ' hot-saving';
      }
    });
    const contentString = JSON.stringify(content);
    if (this.input.content !== contentString) {
      this.input.content = contentString;
      this.moduleService.saveInput(this.input).subscribe(_ => {
        if (this.reloadData) {
          this.getSpreadsheetObservable().subscribe(__ => {
            this.hotInstance.updateSettings(this.settings, true);
          });
        }
      });
    }
  }

  private prepareDefaultDataForCell(
    defaultData: CellDefaultData
  ): SpreadsheetCellData {
    let defaultValue;
    if (instanceOfCellDefaultDataFromInput(defaultData)) {
      const values = defaultData.items
        .map(item => {
          const input = this.inputs[item.inputName];
          let value = getNestedValue(
            input?.content,
            item.objectPath,
            this.data.data.options
          );
          if (item.parseBuyerPersonaIndexes && this.buyerPersonas) {
            value = Array.isArray(value)
              ? (value as number[])
                  .map(
                    index =>
                      this.buyerPersonas.find(
                        persona => persona.index === index
                      )?.name
                  )
                  .filter(Boolean)
                  .join(defaultData.itemSeparator || ', ')
              : typeof value === 'string'
              ? value
              : null;
          }

          return value instanceof Object ? undefined : value;
        })
        .filter(Boolean);
      defaultValue = values.join(defaultData.itemSeparator || ',');
    } else {
      defaultValue = defaultData;
    }
    if (defaultValue instanceof Object) {
      defaultValue = null;
    }

    return defaultValue;
  }

  // cell renderer
  private aboveBelowQuota(instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.NumericRenderer.apply(this, arguments);
    td.style.fontWeight = 'bold';
    const controlCell = this.parseCellRange(
      this.cellSettings[row]
        ? this.cellSettings[row][col]?.rendererOptions?.controlCell
        : null,
      true
    )[0];

    if (controlCell) {
      const cellElement = this.hotInstance?.getCell(
        controlCell[0],
        controlCell[1]
      );

      Handsontable.hooks.once('afterRender', () => {
        if (cellElement?.textContent) {
          if (cellElement.textContent?.includes('Above')) {
            td.style.color = 'green';
            td.style.background = '#CEC';
          } else if (cellElement.textContent?.includes('Below')) {
            td.style.color = 'white';
            td.style.background = '#C00';
          }
        }
      });
    }
  }

  // cell renderer
  private formulaError(instance, td, row, col, prop, value, cellProperties) {
    if (value && value.substr && value.substr(0, 1) === '#') {
      arguments[5] = 0;
      td.className += 'dontValidate';
    }

    Handsontable.renderers.NumericRenderer.apply(this, arguments);
  }

  // cell renderer (delete row)
  private deleteCellRenderer(
    instance,
    td,
    row,
    col,
    prop,
    value,
    cellProperties
  ) {
    Handsontable.renderers.TextRenderer.apply(this, arguments);
    if (this.isVisibleRowDeleteCell(row)) {
      td.innerHTML = '<span><i class="fa fa-trash-o"></i></span>';
    }
  }

  private isVisibleRowDeleteCell(rowId: number): boolean {
    const backendRowId = this.getBackendRowIndex(rowId);

    return (
      this.deleteRowRange &&
      backendRowId >= this.deleteRowRange[0] &&
      backendRowId <= this.deleteRowRange[1]
    );
  }

  private filterRowsDataForOpenEndedRange(
    sheet: SpreadsheetResource
  ): SpreadsheetResource {
    const getStartingRows = (rows: SpreadsheetCellData[][]) => {
      const content: SpreadsheetInputContent = this.input.content
        ? JSON.parse(this.input.content)
        : {};
      const startingRows = rows.filter((row, rowIndex) => {
        if (
          rowIndex < this.openEndedStartingRow ||
          (rowIndex >= this.openEndedStartingRow &&
            content[rowIndex] &&
            Object.keys(content[rowIndex]).some(
              key => !!content[rowIndex][key]
            ))
        ) {
          this.visibleRows.push(rowIndex);

          return true;
        }
      });
      if (startingRows.length - 1 < this.openEndedStartingRow) {
        startingRows.push(rows[startingRows.length]);
        this.visibleRows.push(startingRows.length - 1);
      }

      return startingRows;
    };
    const filteredRows = this.visibleRows.length
      ? this.visibleRows.map(rowId => sheet.data[rowId])
      : getStartingRows(sheet.data);

    return {
      ...sheet,
      data: filteredRows
    };
  }

  private getEmptyRows(data: SpreadsheetCellData[][]): boolean[] {
    const content: SpreadsheetInputContent = this.input.content
      ? JSON.parse(this.input.content)
      : {};
    const minDynamicRowsNumber = this.contentData
      .number_of_empty_rows_by_default;

    const emptyRows = data.map((row, rowId) => {
      const contentVisibleRowId = String(this.visibleRows[rowId]);
      if (this.realStartFilteringRowRange?.length) {
        const isInFilterRange = this.realStartFilteringRowRange.some(
          r => rowId >= r[0] && rowId <= r[1]
        );

        return (
          isInFilterRange &&
          (!this.filteringIndexes?.length ||
            !this.filteringIndexes.some(i => row?.[i])) &&
          (!content[contentVisibleRowId] ||
            Object.keys(content[contentVisibleRowId])?.every(
              key => !content[contentVisibleRowId][key]
            ))
        );
      }

      return (
        this.filteringIndexes?.length &&
        !this.filteringIndexes.some(i => row?.[i])
      );
    });
    if (minDynamicRowsNumber) {
      return emptyRows.reduce(
        (accum, isEmptyRow, rowIndex) => {
          const isInFilterRange = this.realStartFilteringRowRange?.some(
            r =>
              rowIndex >= r[0] &&
              rowIndex <= r[1] &&
              rowIndex < r[0] + minDynamicRowsNumber
          );
          accum[rowIndex] = isInFilterRange ? false : isEmptyRow;

          return accum;
        },
        [...emptyRows]
      );
    }

    return emptyRows;
  }

  private saveDefaultDataInInput(data: SpreadsheetCellData[][]): void {
    if (this.defaultDataLocalRowRange) {
      const inputContent: SpreadsheetInputContent = this.input.content
        ? JSON.parse(this.input.content)
        : null;
      const newContent: SpreadsheetInputContent = { ...inputContent };
      const tableRowRange = this.defaultDataLocalRowRange;
      for (let rowId = tableRowRange[0]; rowId <= tableRowRange[1]; rowId++) {
        const backendRowId = this.displayedRows[rowId].rowBackendIndex;
        const isFilledRow = data[rowId].some(
          cell => cell && !isFormulaString(cell)
        );
        if (isFilledRow && !newContent[backendRowId]) {
          newContent[backendRowId] = data[rowId].reduce(
            (
              accum: { [columnIndex: number]: SpreadsheetCellData },
              value,
              columnIndex
            ) => {
              const dataCol = this.visibleCols.length
                ? this.visibleCols[columnIndex]
                : columnIndex;
              if (!isFormulaString(value) && !isEmptyValue(dataCol)) {
                accum[dataCol] = value;
              }

              return accum;
            },
            {}
          );
        }
      }
      const newContentString = JSON.stringify(newContent);
      if (newContentString !== this.input.content) {
        this.input.content = newContentString;
        this.contentChanged(this.input);
      }
    }
  }

  // cell renderer
  private isNegativeValue(instance, td, row, col, prop, value, cellProperties) {
    if (value) {
      if (parseInt(value, 10) < 0) {
        td.classList.remove('positive-cell-value');
        td.classList.add('negative-cell-value');
      } else {
        td.classList.add('positive-cell-value');
      }
    }

    Handsontable.renderers.NumericRenderer.apply(this, arguments);
  }

  // cell renderer
  private isEqualToPreviousColumnCell(
    instance,
    td,
    row,
    col,
    prop,
    value,
    cellProperties
  ) {
    const previousColumnCell: HTMLTableCellElement = instance.getCell(
      row,
      col - 1
    );
    if (td?.innerHTML !== previousColumnCell?.innerHTML) {
      td.className += ' not-equal';
    } else {
      td.className = td.className.replace(/\bnot-equal\b/g, '');
    }
    Handsontable.renderers.NumericRenderer.apply(this, arguments);
  }

  // cell renderer
  private isEqualToCellsSum(
    instance,
    td,
    row,
    col,
    prop,
    value,
    cellProperties
  ) {
    const getValueFromCell = (
      cellElement: HTMLTableDataCellElement
    ): string => {
      const cellValueWrapper =
        cellElement.getElementsByClassName('cell-content-value')?.[0] ||
        cellElement;

      return cellValueWrapper?.innerHTML || '';
    };
    const cellValue = currencyToNumber(value);
    const options = this.cellSettings[row]
      ? this.cellSettings[row][col]?.rendererOptions?.columns
      : null;
    if (options) {
      const colRange = options
        .split(/[0-9]/)
        .join('')
        .match(/[\-A-Z]+/)[0]
        .split('-')
        .filter(a => a);
      const from = getColNumericIndex(colRange[0]);
      const to = getColNumericIndex(colRange[colRange.length - 1]);
      const values = [];
      for (let i = from; i <= to; i++) {
        values.push(
          currencyToNumber(
            getValueFromCell(instance.getCell(row, this.getRealCol(i)))
          )
        );
      }
      const sum = values.reduce((accum, item) => accum + item, 0);
      if (cellValue !== sum) {
        td.className += ' not-equal';
      } else {
        td.className = td.className.replace(/\bnot-equal\b/g, '');
      }
    }
    Handsontable.renderers.NumericRenderer.apply(this, arguments);
  }

  // cell renderer
  private saveCellValueToInput(
    instance,
    td,
    row,
    col,
    prop,
    value,
    cellProperties
  ) {
    const input = this.inputToSaveCellValue;
    if (input) {
      const tableRowIndex = this.displayedRows[row].fullTableIndex;
      const options = this.cellSettings[tableRowIndex]
        ? this.cellSettings[tableRowIndex][col]?.rendererOptions
        : null;
      let valueToSave = 0;
      let newContent: string | { [key: string]: number };
      let currentContent: string | { [key: string]: number };
      let isChanged: boolean;
      try {
        currentContent = JSON.parse(input.content);
      } catch (e) {
        currentContent = input.content;
      }
      let fieldName =
        currentContent && typeof currentContent === 'object'
          ? `${getColChar(col)}${tableRowIndex}`
          : null;
      if (options?.includeCell) {
        const cellRow = this.getRealRow(
          Number(options.includeCell.match(/[\-0-9]+/)[0]) - 1
        );
        const cellCol = getColNumericIndex(
          options.includeCell.match(/[\-A-Z]+/)[0]
        );
        valueToSave = currencyToNumber(
          instance.getCell(cellRow, cellCol)?.innerHTML || ''
        );
      }
      if (options?.fieldName) {
        fieldName = options.fieldName;
      }
      valueToSave = valueToSave + currencyToNumber(value);
      if (fieldName) {
        newContent =
          typeof currentContent === 'object'
            ? { ...currentContent, [fieldName]: valueToSave }
            : { [fieldName]: valueToSave };
        isChanged = newContent[fieldName] !== currentContent?.[fieldName];
      } else {
        newContent = String(valueToSave);
        isChanged = newContent !== currentContent;
      }
      if (isChanged) {
        input.content = JSON.stringify(newContent);
        this.contentChanged(input);
      }
    }
    Handsontable.renderers.NumericRenderer.apply(this, arguments);
  }

  private setContentDataFromDataOptions(): void {
    const options = this.data.data.options;
    if (options.visibleRows) {
      this.contentData.visibleRows = options.visibleRows as string;
    }
    if (options.visibleCols) {
      this.contentData.visibleCols = options.visibleCols as string;
    }
  }

  private lookForStepInputChange(): void {
    this.moduleService.stepInputChanged$
      .pipe(
        this.whileExists(),
        filter(
          (data: TemplateInput) =>
            !!data &&
            !!this.inputs?.[data.element_key] &&
            this.inputs?.[data.element_key]?.content !== data.content
        )
      )
      .subscribe(data => this.updateSpreadsheetInput(data));
  }

  private updateSpreadsheetInput(updatedInput: TemplateInput): void {
    const input = this.inputs[updatedInput.element_key];
    input.content = updatedInput.content;
    if (this.reloadData) {
      this.moduleService.saveInput(input).subscribe(() =>
        this.getSpreadsheetObservable().subscribe(() => {
          this.hotInstance?.updateSettings(this.settings, true);
        })
      );
    }
  }
}
