import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EvaluatingInputData } from '../index';

@Component({
  selector: 'app-evaluate-table',
  templateUrl: './evaluate-table.component.html',
  styleUrls: ['./evaluate-table.component.scss']
})
export class EvaluateTableComponent implements OnInit {
  @Input() objects: Partial<EvaluatingInputData>;
  @Input() criteria: Partial<EvaluatingInputData>;
  @Input() model: EvaluatingInputData;
  @Input() disabled: boolean;
  @Input() defaultValue: number;
  @Input() ratingsValues: number[];

  @Output() modelChange = new EventEmitter<EvaluatingInputData>();
  @Output() componentInitialized = new EventEmitter<EvaluatingInputData>();

  displayedColumns: string[][];

  ngOnInit(): void {
    if (this.objects && this.criteria) {
      this.displayedColumns = this.prepareColumnsHeaders();
      this.prepareModel();
      this.evaluateTableInitialized();
    }
  }

  prepareColumnsHeaders(): string[][] {
    return Object.entries(this.objects).reduce(
      (acc: string[][], [key, { title }]) => [
        ...acc,
        [title, ...this.ratingsValues.map(numb => numb.toString())]
      ],
      [[]]
    );
  }

  prepareModel() {
    this.model = Object.entries(this.objects).reduce(
      (acc: Partial<EvaluatingInputData>, [objectKey, objectValue]) => ({
        ...acc,
        [objectKey]: {
          title: objectValue.title,
          criteria: Object.entries(this.criteria).reduce(
            (accum, [criteriaKey, criteriaValue]) => ({
              ...accum,
              [criteriaKey]: {
                title: criteriaValue.title,
                rating: this.model[objectKey]?.criteria[criteriaKey]
                  ? this.model[objectKey].criteria[criteriaKey].rating
                  : this.defaultValue
              }
            }),
            {}
          )
        }
      }),
      {}
    );
  }

  onRatingChange(objectKey: string, criterionKey: string, value: string) {
    this.model[objectKey].criteria[criterionKey].rating = Number(value);
    this.modelChange.emit(this.model);
  }

  evaluateTableInitialized() {
    this.componentInitialized.emit(this.model);
  }
}
