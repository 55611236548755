import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSnackBarConfig } from '@angular/material/snack-bar/snack-bar-config';
import { CustomScanBarData } from '../components/custom-snackbar/snackbar';
import { CustomSnackbarComponent } from '../components/custom-snackbar/custom-snackbar.component';

@Injectable()
export class SnackBarService {
  constructor(private snackBar: MatSnackBar) {}

  success(message: string, duration = 3000): void {
    this.openSnackBar({ duration, data: { message, type: 'success' } });
  }

  error(message: string, duration = 3000): void {
    this.openSnackBar({ duration, data: { message, type: 'error' } });
  }

  warning(message: string, duration = 3000): void {
    this.openSnackBar({ duration, data: { message, type: 'warning' } });
  }

  info(message: string, duration = 3000): void {
    this.openSnackBar({ duration, data: { message, type: 'info' } });
  }

  private openSnackBar(config?: MatSnackBarConfig<CustomScanBarData>): void {
    this.snackBar.openFromComponent(CustomSnackbarComponent, config);
  }
}
