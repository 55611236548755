import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-persona-picture-list',
  templateUrl: './persona-picture-list.component.html',
  styleUrls: ['./persona-picture-list.component.scss']
})
export class PersonaPictureListComponent implements OnInit {
  list = [
    `${environment.apiRoot}/api/resources?s3_key=Buyer+Personas+images/pic1.jpg`,
    `${environment.apiRoot}/api/resources?s3_key=Buyer+Personas+images/pic2.jpg`,
    `${environment.apiRoot}/api/resources?s3_key=Buyer+Personas+images/pic3.jpg`,
    `${environment.apiRoot}/api/resources?s3_key=Buyer+Personas+images/pic4.jpg`,
    `${environment.apiRoot}/api/resources?s3_key=Buyer+Personas+images/pic5.jpg`,
    `${environment.apiRoot}/api/resources?s3_key=Buyer+Personas+images/pic6.jpg`,
    `${environment.apiRoot}/api/resources?s3_key=Buyer+Personas+images/pic7.jpg`,
    `${environment.apiRoot}/api/resources?s3_key=Buyer+Personas+images/pic8.jpg`,
    `${environment.apiRoot}/api/resources?s3_key=Buyer+Personas+images/pic9.jpg`,
    `${environment.apiRoot}/api/resources?s3_key=Buyer+Personas+images/pic10.jpg`,
    `${environment.apiRoot}/api/resources?s3_key=Buyer+Personas+images/pic11.jpg`,
    `${environment.apiRoot}/api/resources?s3_key=Buyer+Personas+images/pic12.jpg`,
    `${environment.apiRoot}/api/resources?s3_key=Buyer+Personas+images/pic13.jpg`,
    `${environment.apiRoot}/api/resources?s3_key=Buyer+Personas+images/pic14.jpg`,
    `${environment.apiRoot}/api/resources?s3_key=Buyer+Personas+images/pic15.jpg`,
    `${environment.apiRoot}/api/resources?s3_key=Buyer+Personas+images/pic16.jpg`,
    `${environment.apiRoot}/api/resources?s3_key=Buyer+Personas+images/pic17.jpg`,
    `${environment.apiRoot}/api/resources?s3_key=Buyer+Personas+images/pic18.jpg`,
    `${environment.apiRoot}/api/resources?s3_key=Buyer+Personas+images/pic19.jpg`,
    `${environment.apiRoot}/api/resources?s3_key=Buyer+Personas+images/pic20.jpg`,
    `${environment.apiRoot}/api/resources?s3_key=Buyer+Personas+images/pic21.jpg`,
    `${environment.apiRoot}/api/resources?s3_key=Buyer+Personas+images/pic22.jpg`,
    `${environment.apiRoot}/api/resources?s3_key=Buyer+Personas+images/pic23.jpg`,
    `${environment.apiRoot}/api/resources?s3_key=Buyer+Personas+images/pic24.jpg`,
    `${environment.apiRoot}/api/resources?s3_key=Buyer+Personas+images/pic25.jpg`,
    `${environment.apiRoot}/api/resources?s3_key=Buyer+Personas+images/pic26.jpg`,
    `${environment.apiRoot}/api/resources?s3_key=Buyer+Personas+images/pic27.jpg`,
    `${environment.apiRoot}/api/resources?s3_key=Buyer+Personas+images/pic28.jpg`,
    `${environment.apiRoot}/api/resources?s3_key=Buyer+Personas+images/pic29.jpg`,
    `${environment.apiRoot}/api/resources?s3_key=Buyer+Personas+images/pic30.jpg`,
    `${environment.apiRoot}/api/resources?s3_key=Buyer+Personas+images/picAna.jpg`,
    `${environment.apiRoot}/api/resources?s3_key=Buyer+Personas+images/picArchie.jpg`,
    `${environment.apiRoot}/api/resources?s3_key=Buyer+Personas+images/picCharlie.jpg`,
    `${environment.apiRoot}/api/resources?s3_key=Buyer+Personas+images/picLynn.jpg`,
    `${environment.apiRoot}/api/resources?s3_key=Buyer+Personas+images/picTed.jpg`
  ];
  selected: string;

  constructor(public modal: MatDialogRef<PersonaPictureListComponent>) {}

  ngOnInit() {}

  select(item: string) {
    this.selected = item;
  }

  done() {
    this.modal.close(this.selected);
  }
}
