import * as Handsontable from 'src/assets/scripts/handsontable.js';

export const CustomDropdownEditor = Handsontable.editors.DropdownEditor.prototype.extend();

CustomDropdownEditor.prototype.highlightBestMatchingChoice = function(index) {
  if (typeof index === 'number' && this.getValue()) {
    this.htEditor.selectCell(index, 0, void 0, void 0, void 0, false);
  } else {
    this.htEditor.deselectCell();
  }
};
