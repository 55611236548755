import { TemplateContentData } from './template-data.class';
import {
  OnInit,
  ElementRef,
  OnDestroy,
  Injector,
  ComponentFactoryResolver,
  Component,
  ChangeDetectorRef,
  Input,
  Renderer2
} from '@angular/core';
import { TemplateComponentInterface } from './template.interface';
import User from 'src/app/common/interfaces/user.model';
import { ModuleContentService } from 'src/app/common/services/module-content.service';
import { ModuleService } from 'src/app/common/services/module.service';
import { UserService } from 'src/app/common/services/user.service';
import {
  TemplateInput,
  TemplateInputCap
} from 'src/app/common/interfaces/module.interface';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { takeWhile, tap } from 'rxjs/operators';
import { Validation, Validate } from 'src/app/common/validator.class';
import { ModuleNavService } from 'src/app/common/services/module-nav.service';
import { BuyerPersona } from '../../../common/interfaces/buyer-persona.interface';
import { BuyerPersonasService } from '../../../common/services/buyer-personas.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfirmationService } from '../../../common/services/confirmation.service';
import { SnackBarService } from '../../../common/services/snackbar.service';
import { ValidationErrorInterface } from '../../../common/interfaces/validation-error.interface';
import { sanitizeHtml } from '../../../common/utils/html-helpers';
import { TemplateService } from '../../../common/services/template.service';
import { ContentPipe } from '../../../common/pipes/InputsTools/content.pipe';

@Component({ template: '' })
export abstract class TemplateComponent
  implements TemplateComponentInterface, OnInit, OnDestroy {
  data: TemplateContentData;
  hideChanges$: Observable<boolean>;
  inputs: { [key: string]: TemplateInput };
  brainstorm: TemplateInput;
  disabled: boolean;
  hidden = false;
  me: User;
  defaultListContent: '<ul style="padding-left: 20px"><li><p></p></li></ul>';
  buyerPersonasList$: Observable<BuyerPersona[]>;
  contentChanged$ = new BehaviorSubject<TemplateInput>(null);
  validationTriggered: BehaviorSubject<boolean> = new BehaviorSubject(false);
  instanceExists = true;
  isEmbedded = false;
  contentOptions: { [key: string]: string | TemplateInput };
  validationErrors: ValidationErrorInterface[] = [];
  stepTransition$: Subject<void> = new Subject();
  parentStep;
  isPartOfBlockRepeater;
  prefix = '';

  abstract contentData;
  protected abstract params: string;

  constructor(
    protected el: ElementRef,
    protected moduleContentService: ModuleContentService,
    protected moduleService: ModuleService,
    protected moduleNavService: ModuleNavService,
    protected navService: ModuleNavService,
    protected userService: UserService,
    protected injectorObj: Injector,
    protected buyerPersonasService: BuyerPersonasService,
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected matIconRegistry: MatIconRegistry,
    protected confirmationService: ConfirmationService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected snackBarService: SnackBarService,
    protected contentPipe: ContentPipe,
    protected renderer: Renderer2,
    private domSanitizer: DomSanitizer,
    protected templateService: TemplateService
  ) {}

  ngOnInit() {
    this.matIconRegistry.addSvgIcon(
      'custom_driver',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/img/icons/Driver.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'custom_gatekeeper',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/img/icons/Gatekeeper.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'custom_not_involved',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/img/icons/Not_Involved.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'custom_participant',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../../../assets/img/icons/Participant.svg'
      )
    );
    if (this.inputs && this.inputs.brainstorm) {
      this.brainstorm = this.inputs.brainstorm;
    }
    this.hideChanges$ = this.data.onHideChanges;
    this.updateTemplateData();

    this.buyerPersonasList$ = this.buyerPersonasService.getBuyerPersonas();

    Object.keys(this.inputs).map(key => this.decorateInput(this.inputs[key]));
    this.init();
  }

  ngOnDestroy(): void {
    this.instanceExists = false;
    this.onStepTransition();
    document.getElementById('print-style').firstChild?.remove();
  }

  onStepTransition(): void {
    if (!this.stepTransition$.isStopped) {
      this.stepTransition$.next();
      this.stepTransition$.complete();
    }
  }

  updateTemplateData(): void {
    this.inputs = this.data.data.inputs;
    this.disabled = this.data.data.disabled;
    this.hidden = this.data.data.hidden;
    this.me = this.data.me;
    this.contentOptions = this.data.data.options;
  }

  whileExists() {
    return takeWhile(() => this.instanceExists);
  }

  validate(): Observable<boolean> {
    return of(true);
  }

  getValidationErrorMessage(): string {
    return 'This step cannot be marked as done because of missing or invalid inputs';
  }

  setValidationError(error: ValidationErrorInterface) {
    if (!this.validationErrors.find(err => err.type === error.type)) {
      this.validationErrors.push(error);
    }
  }

  removeValidationError(type: string) {
    this.validationErrors = this.validationErrors.filter(
      err => err.type !== type
    );
  }

  hasInputs() {
    return true;
  }

  contentChanged(data: TemplateInput, validators?: Validation[]): void {
    if (data) {
      if (validators?.length && data?.error?.getValue()) {
        this.validateInput(data, validators);
      }
      this.saveInput(data).subscribe();
      if (data.observer) {
        data.observer.next(data.getValue());
      }
    }
  }

  saveInput(data: TemplateInput): Observable<TemplateInput> {
    return this.moduleService.saveInput(data).pipe(
      tap(() => {
        this.contentChanged$.next(data);
        this.moduleService.stepInputChanged$.next(data);
      })
    );
  }

  removeCircularStructure(circularData) {
    Object.values(circularData).forEach(block =>
      Object.keys(block).forEach(inputId =>
        Object.keys(block[inputId]).forEach(inputName => {
          const blockInput = block[inputId][inputName];
          if (blockInput?.error?.observers?.length) {
            blockInput.error.observers = [];
          }
          if (blockInput?.observer?.observers?.length) {
            blockInput.error.observers = [];
          }
        })
      )
    );

    return circularData;
  }

  removeObservablesFromInput(circularData: TemplateInputCap): TemplateInputCap {
    const { observer, error, getValue, ...inputContent } = circularData;
    circularData = inputContent;

    return circularData;
  }

  // this method is used in BlockRepeater Component
  blockRepeaterContentChanged(
    data: TemplateInput,
    stepIndex: number,
    validators?: Validation[]
  ): void {
    if (data) {
      this.moduleService
        .saveInput(data)
        .pipe(tap(() => this.moduleService.stepInputChanged$.next(data)))
        .subscribe(_ => this.contentChanged$.next(data));
      if (data.observer) {
        data.observer.next(data.getValue());
      }
    }
  }

  notEmpty(el: string) {
    return !!this.textContent(el);
  }

  textContent(el: string) {
    const parser = new DOMParser().parseFromString(el || '', 'text/html');

    parser.querySelectorAll('.del').forEach(deleted => deleted.remove());

    return parser.body.textContent.trim().replace(/[^\S\r\n]/g, ' ');
  }

  inputContent(input: TemplateInput): any {
    return this.textContent(input?.content);
  }

  isEnabled() {
    return true;
  }

  decorateInput(inp: TemplateInput): TemplateInput {
    if (inp && !inp.getValue) {
      inp.getValue = () => {
        if (!inp.content) {
          return '';
        }
        const content = sanitizeHtml(inp.content);
        const div = document.createElement('div');
        div.innerHTML = content.split('<br>').join('\n');

        // Remove contents deleted but still visible because of changes tracker
        const deletedEls = div.getElementsByClassName('del');
        for (const el of Array.prototype.slice.call(deletedEls) as Element[]) {
          el.parentNode.removeChild(el);
        }

        const text = (div.innerHTML || '')
          .trim()
          .split('<p></p>')
          .join('')
          .split('class="ins cts-')
          .join('class="');

        div.remove();

        return text;
      };

      inp.observer = new BehaviorSubject(inp.getValue());
      inp.error = new BehaviorSubject(null);
    }

    return inp;
  }

  getInput(
    fieldName: string,
    num?: number,
    prefix?: string,
    suffix?: string
  ): TemplateInput {
    const id =
      (typeof prefix === 'string' ? prefix : this.prefix) +
      fieldName +
      (num ? '_' + String(num) : '') +
      (suffix ? '_' + suffix : '');

    return this.decorateInput(this.inputs[id]);
  }

  validateInput(inp: TemplateInput, validators: Validation[] = []) {
    if (!validators) {
      return true;
    }
    if (!validators.length) {
      validators.push(Validate.required('Please fill out this field'));
    }

    const value = this.inputContent(inp);

    const err = Validate.getErrorMessage(
      validators,
      value instanceof String
        ? value.replace(/<\/?[^>]+(>|$)/g, '').trim()
        : value
    );
    inp.error.next(err);

    return !err;
  }

  resetError(input: TemplateInput) {
    if (input.error) {
      input.error.next(null);
    }
  }

  getBuilderParams() {
    let template_params_json: string | RegExpMatchArray = this.params.match(
      /template_params_json: {[^.]*?};/gm
    );

    template_params_json =
      template_params_json &&
      template_params_json[0] &&
      template_params_json[0].replace(/template_params_json: /, '');

    if (
      template_params_json.length ===
      template_params_json.lastIndexOf(';') + 1
    ) {
      template_params_json = template_params_json.substring(
        0,
        template_params_json.length - 1
      );
    }

    return template_params_json ? template_params_json : false;
  }

  getSortedInputNames(inputMatch: RegExp): string[] {
    return Object.keys(this.inputs)
      .filter(name => name.match(inputMatch))
      .sort((nameA, nameB) => {
        const matchA = nameA.match(/\d+/g);
        const matchB = nameB.match(/\d+/g);
        const numberA = matchA && matchA[0] ? Number(matchA[0]) : null;
        const numberB = matchB && matchB[0] ? Number(matchB[0]) : null;

        return numberA - numberB;
      });
  }

  setOrientationPrintLayout(
    orientation: 'auto' | 'portrait' | 'landscape'
  ): void {
    const printStyleElement = document.getElementById('print-style');
    printStyleElement.innerHTML = '';
    printStyleElement.append(`@media print{@page {size: A4 ${orientation};}}`);
  }

  print() {
    window.print();
  }

  devOnlyClearInputs() {
    Object.values(this.inputs).forEach(input => {
      input.content = null;
      this.contentChanged(input);
    });
  }

  protected init() {}

  abstract getDescription(): string;

  abstract getName(): string;

  abstract getGroup(): string;
}
