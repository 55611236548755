import { Component, forwardRef } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import { SelectBuyerPersonaTemplateData } from '.';
import txt from '!!raw-loader!./index.ts';
import { TemplateInput } from '../../../../common/interfaces/module.interface';
import { of } from 'rxjs';
import { Validate } from '../../../../common/validator.class';

@Component({
  selector: 'select-buyer-persona',
  templateUrl: 'select-buyer-persona.component.html',
  styleUrls: ['./select-buyer-persona.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => SelectBuyerPersonaComponent)
    }
  ]
})
export class SelectBuyerPersonaComponent extends TemplateComponent {
  params = txt;
  contentData: SelectBuyerPersonaTemplateData['template_params_json'];
  prefix = 'select_buyer_persona_1';
  type_select = '';

  input: TemplateInput;
  selectedPersonas: string[] = [];
  selectedPerson: string;

  init() {
    this.contentData = this.data.data
      .template_params_json as SelectBuyerPersonaTemplateData['template_params_json'];
    this.type_select = this.contentData.type_select;
    const key = this.contentData.input_sufix
      ? `${this.prefix}_${this.contentData.input_sufix}`
      : this.prefix;
    this.input = this.inputs[key];
    if (this.type_select === 'dropdown' || this.type_select === 'checkbox') {
      this.selectedPersonas = JSON.parse(this.input.content) || [];
    } else {
      this.selectedPerson = JSON.parse(this.input.content) || 0;
    }
  }

  updateSelectedPersonas() {
    this.input.content = JSON.stringify(this.selectedPersonas);
    this.contentChanged(this.input);
  }

  validate() {
    const Validators = [];
    const min = this.contentData.min_number_of_selections;
    const max = this.contentData.max_number_of_selections;
    if (min && this.selectedPersonas.length < min) {
      Validators.push(Validate.min(min - 1));
    }
    if (max && this.selectedPersonas.length > max) {
      Validators.push(Validate.max(max + 1));
    }

    return of(this.validateInput(this.input, Validators));
  }

  updateSelectedPerson(uuid: string) {
    if (!this.disabled && !this.contentData.readonly) {
      this.selectedPerson = uuid;
      this.input.content = JSON.stringify(this.selectedPerson);
      this.contentChanged(this.input);
    }
  }

  getDescription() {
    return '';
  }

  getName() {
    return 'Select Buyer Persona';
  }

  getGroup() {
    return 'Generic';
  }
}
