import { Pipe, PipeTransform } from '@angular/core';
import { getNestedValue } from '../utils/helpers';
import { TemplateInput } from '../interfaces/module.interface';

@Pipe({
  name: 'interpolate'
})
export class InterpolatePipe implements PipeTransform {
  transform(
    value: string,
    variables: { [key: string]: string | TemplateInput }
  ): string {
    if (!variables) {
      return '';
    }
    const expressionReplacer = (match: string, expression: string) =>
      getNestedValue(variables, expression);

    return (value || '').replace(/\${([\s\S]*)}/gi, expressionReplacer);
  }
}
