import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  Input,
  HostListener,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import User from 'src/app/common/interfaces/user.model';
import { BehaviorSubject, Subscription } from 'rxjs';
import { IceService } from './ice.service';
import { E3ConfirmationDialogService } from 'src/app/common/components/e3-confirmation-dialog/e3-confirmation-dialog.service';
import { TemplateComponent } from '../riverside-step-template/templates/template-base.class';
import {
  InputComment,
  TemplateInputCap
} from 'src/app/common/interfaces/module.interface';
import { format } from 'date-fns';
import {
  formatDoc,
  sanitizeHtml,
  trimEmptyLinesFromEnd
} from '../../common/utils/html-helpers';

export class Comments {
  adding = false;
  content = '';
  list: InputComment[] = [];
  editingIndex: number = null;
  show = false;
  index: number = null;
}

@Component({
  selector: 'ice',
  templateUrl: './ice.component.html',
  styleUrls: ['./ice.component.scss']
})
export class IceComponent implements OnInit, OnChanges, OnDestroy {
  @Input() box: number;
  @Input() disabled: boolean;
  @Input() restrictWidth = true;
  @Input() placeholder = '';
  @Input() maxInputLength;

  // pass input as string identifier or as instance
  @Input() input?: string;
  @Input() prefix?: string;
  @Input() idx?: number;

  @Input() data: TemplateInputCap;
  @Input() single = false;
  @Input() numeric = false;

  @Input() useAsObject = false;
  @Input() object?: any;
  @Input() objectField = 'ice';

  @Output() changed = new EventEmitter(false);
  @Output() dataChanged = new EventEmitter<TemplateInputCap>(false);

  @ViewChild('textBody') textBody: ElementRef;

  comment = new Comments();
  menuComment: InputComment;
  menuIndex: number;

  user: User;
  isDisabled: boolean;

  iceContent: string;

  onApproveSub: Subscription;

  constructor(
    private el: ElementRef,
    private iceService: IceService,
    private dialogService: E3ConfirmationDialogService,
    public template: TemplateComponent
  ) {}

  @HostListener('keyup', ['$event'])
  onKeyEvent(e: KeyboardEvent) {
    if ((e.which < 48 && e.which !== 32 && e.which !== 8) || e.which > 90) {
      return false;
    }

    this.data.error?.next(null);
  }

  ngOnInit() {
    if (this.useAsObject) {
      this.object = this.object || {};
      if (!this.object[this.objectField]) {
        this.object[this.objectField] = { content: null };
      } else if (this.object[this.objectField]?.error) {
        this.object[this.objectField].error = null;
      }
      this.data = this.object[this.objectField];
    } else if (this.input) {
      this.data = this.template.getInput(this.input, this.idx, this.prefix);
    }

    if (!this.data) {
      console.error('No input data found for ', this.input);

      return;
    }

    this.placeholder = this.placeholder?.replaceAll
      ? this.placeholder.replaceAll('\\n', '\n')
      : this.placeholder;

    if (!this.useAsObject) {
      this.data.error = this.data.error || new BehaviorSubject(null);
    }

    this.user = this.template.me;
    this.isDisabled = this.template.disabled || this.disabled;

    if (!this.changed.observers.length) {
      this.changed.subscribe(event => {
        if (!this.object) {
          this.template.contentChanged(event);
        }
      });
    }

    this.iceContent = this.getDataInnerHtml();

    if (this.data.comments_json && this.data.comments_json.length) {
      this.comment.list = this.data.comments_json;
    }

    setTimeout(() => {
      if (this.restrictWidth) {
        const w = String(this.el.nativeElement.clientWidth) + 'px';
        this.el.nativeElement.style.width = w;
        this.el.nativeElement.style.maxWidth = w;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isDisabled = this.template.disabled || this.disabled;
    if (changes.data) {
      this.iceContent = this.getDataInnerHtml();
    }
  }

  ngOnDestroy() {
    this.changed.unsubscribe();

    if (this.onApproveSub) {
      this.onApproveSub.unsubscribe();
    }
  }

  processInput(event: KeyboardEvent) {
    const element = event.target;
    if (
      this.maxInputLength &&
      this.getCombinedText(event.key).length > this.maxInputLength
    ) {
      event.preventDefault();

      return;
    }
    if (this.single && event.keyCode === 13) {
      event.preventDefault();
    }
    if (this.single || !this.isNewLine()) {
      return;
    }
    if (event.key === ' ' && this.getPreviousChar(1) === '*') {
      formatDoc('insertunorderedlist', null, element);
      formatDoc('forwardDelete', null, element);
      event.preventDefault();
    } else if (
      event.key === ' ' &&
      +this.getPreviousChar(2) === 1 &&
      this.getPreviousChar(1) === '.'
    ) {
      formatDoc('insertorderedlist', null, element);
      formatDoc('forwardDelete', null, element);
      formatDoc('forwardDelete', null, element);
      event.preventDefault();
    }
  }

  addComment($event: MouseEvent) {
    $event.stopPropagation();
    $event.preventDefault();
    this.comment.adding = true;
    this.closeComment();
    this.openComment();
  }

  cancelComment() {
    this.comment.adding = false;
    this.comment.content = '';
    this.comment.index = null;
    this.closeComment();
  }

  saveComment(index: false | number = false) {
    const commentContent = sanitizeHtml(this.comment.content);
    if (this.comment.index !== null) {
      this.comment.list[this.comment.index].content = commentContent;
    } else {
      const time = new Date().getTime();
      this.comment.list.push({
        content: commentContent,
        user: this.user,
        time,
        formattedTime: format(time, 'MMM dd yyyy hh:mma')
      });
    }

    this.data.comments_json = this.comment.list;
    this.cancelComment();
    this.dataChanged.emit(this.data);
    this.changed.emit(this.data);
    setTimeout(_ => this.openComment(), 100);
  }

  onMouseEnter() {
    if (this.comment.list && this.comment.list.length) {
      this.openComment();
    }
  }

  closeComment() {
    this.comment.show = false;
  }

  onFocus(e: Event) {
    if (this.iceService.shouldShowWarning && !this.isDisabled) {
      const el = e.target as HTMLDivElement;
      el.blur();
      this.dialogService.open({
        content: this.iceService.warningText,
        onCancel: () => {},
        onClose: () => {
          this.iceService.shouldShowWarning = false;
          this.iceService.onUnapprove.emit();
          el.focus();
        }
      });
    }
  }

  editClicked(event: MouseEvent) {
    this.menuClicked(event);
    this.comment.content = this.menuComment.content;
    this.comment.index = this.menuIndex;
    this.comment.adding = true;
  }

  deleteClicked(event: MouseEvent) {
    this.menuClicked(event);
    const indexToRemove = this.comment.list.length - 1 - this.menuIndex;
    this.comment.list = [
      ...this.comment.list.slice(0, indexToRemove),
      ...this.comment.list.slice(indexToRemove + 1)
    ];
    this.data.comments_json = this.comment.list.length
      ? this.comment.list
      : null;
    this.dataChanged.emit(this.data);
    this.changed.emit(this.data);
  }

  menuClicked(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  saveData() {
    this.data.content = this.textBody.nativeElement.innerHTML;
    this.dataChanged.emit(this.data);
    this.changed.emit(this.data);
  }

  onChange(): void {
    setTimeout(_ => this.saveData(), 100);
  }

  hideError(el: TemplateInputCap) {
    el.error?.next('');
  }

  private openComment() {
    this.comment.show = true;
  }

  private getCombinedText(newText: string): string {
    return this.textBody.nativeElement.innerText + newText;
  }

  private isNewLine() {
    const sel = window.getSelection();

    return (
      // @ts-ignore
      sel.anchorNode.wholeText === '1.' ||
      // @ts-ignore
      sel.anchorNode.wholeText === '*'
    );
  }

  private getPreviousChar(shift) {
    const sel = window.getSelection();
    const postition = sel.anchorOffset - shift;
    const range = sel.getRangeAt(0);

    return (
      // @ts-ignore
      range.startContainer.wholeText &&
      // @ts-ignore
      range.startContainer.wholeText[postition]
    );
  }

  private getDataInnerHtml(): string {
    const el: HTMLDivElement = document.createElement('div');
    el.innerHTML = trimEmptyLinesFromEnd(sanitizeHtml(this.data?.content));
    const hasWrapper = el.innerHTML?.match(/^\s*(<(p|ul|ol))/gi);
    // Add missing root element (can happen because of a bug elsewhere)
    if (el.innerHTML.length && !hasWrapper) {
      el.innerHTML = '<p>' + el.innerHTML + '</p>';
    }

    return el.innerHTML;
  }
}
