import { Component, forwardRef } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import { NegativeAnswersTemplateData, SimpleCheckboxItem } from '.';
import txt from '!!raw-loader!./index.ts';

@Component({
  selector: 'negative-answers',
  templateUrl: 'negative-answers.component.html',
  styleUrls: ['./negative-answers.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => NegativeAnswersComponent)
    }
  ]
})
export class NegativeAnswersComponent extends TemplateComponent {
  params = txt;
  contentData: NegativeAnswersTemplateData['template_params_json'];

  linkedInputs = [];

  getDescription() {
    return '';
  }

  getName() {
    return 'Negative Answers';
  }

  getGroup(): string {
    return 'Outputs';
  }

  protected init() {
    this.contentData = this.data.data
      .template_params_json as NegativeAnswersTemplateData['template_params_json'];

    this.linkedInputs = Object.values(this.inputs)
      .filter(input => input.element_key.match(/^checkbox_/) && input.content)
      .map(cb => JSON.parse(cb.content) as SimpleCheckboxItem[])
      .flat()
      .filter(
        item =>
          (!item.answer && !item.isCustom && item.checked === undefined) ||
          item.answer === false
      );
  }
}
