import { MatSnackBarConfig } from '@angular/material/snack-bar';

export interface CustomScanBarData {
  message: string;
  type?: 'success' | 'error' | 'info' | 'warning';
  icon?: string;
}

export const MAT_SNACK_BAR_CUSTOM_OPTIONS: MatSnackBarConfig<CustomScanBarData> = {
  duration: 2000,
  horizontalPosition: 'right',
  verticalPosition: 'top'
};
