import { Component, forwardRef, HostBinding } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import {
  ExpandDataDropdownInfo,
  ExpandDataTemplateData,
  ExpandedData,
  ExpandedDataDropdownOption,
  ExpandedDataItem,
  TCustomColumnRegexType,
  TExpandDataColumn
} from '.';
import txt from '!!raw-loader!./index.ts';
import { MatrixElement, MatrixElementData } from '../matrix-entry';
import { TemplateInput } from '../../../../common/interfaces/module.interface';
import { CheckboxSelectorItem } from '../checkbox-selector';
import { switchMap, take } from 'rxjs/operators';
import { Validation } from '../../../../common/validator.class';
import { Observable, of } from 'rxjs';
import { ListElement } from '../list-entry';
import { ApiDataType } from '../../../../common/interfaces/api-data.interface';
import { updateBlockRepeaterFormat } from '../block-repeater/helpers';
import { BlockRepeaterContent } from '../block-repeater';
import { MatSelectChange } from '@angular/material/select';
import { updateMatrixEntryFormat } from '../matrix-entry/helpers';

@Component({
  selector: 'expand-data',
  templateUrl: 'expand-data.component.html',
  styleUrls: ['./expand-data.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => ExpandDataComponent)
    }
  ]
})
export class ExpandDataComponent extends TemplateComponent {
  params = txt;
  contentData: ExpandDataTemplateData['template_params_json'];

  inputsToExpand: TemplateInput[] = [];
  oldExpandedData: ExpandedData;
  expandedData: ExpandedData;
  existedExpandedData: ExpandedDataItem[] = [];

  dropdownOptions: ExpandedDataDropdownOption = {};
  rowValueClassNames: string[] = [];
  validRows: boolean[] = [];
  apiData: ApiDataType;
  apiSubscription: Observable<ApiDataType>;
  apiUUIDs: string[];

  isThereDataToExpand = true;
  validators: Validation[];
  validationCellErrors: { [title: string]: boolean[] } = {};
  loading = true;
  columns: TExpandDataColumn[] = [];

  @HostBinding('class.has-error') get class() {
    return !this.isThereDataToExpand || !this.expandedData;
  }

  get input() {
    return this.getInput(`expand_data_1_${this.contentData.input_sufix}`);
  }

  get expandedInputs(): TemplateInput[] {
    return this.contentData.inputs_to_expand
      ? this.contentData.inputs_to_expand
          .map(inputName =>
            inputName.is_part_of_block_repeater
              ? this.getInputFromBlockRepeater(
                  inputName.block_repeater_input_key,
                  inputName.key
                )
              : this.getInput(inputName.key)
          )
          .filter(Boolean)
      : null;
  }

  init() {
    super.init();
    this.contentData = this.data.data
      .template_params_json as ExpandDataTemplateData['template_params_json'];

    this.inputsToExpand =
      (this.expandedInputs?.length ? this.expandedInputs : null) ||
      this.getLinkedInputsToExpand();

    this.columns = this.getDataColumns();

    this.apiSubscription = this.contentData.api_as_data_source
      ? this.navService.organization$.pipe(
          switchMap(orgId =>
            this.moduleService.getAPIData(orgId, this.contentData.api_endpoint)
          ),
          take(1)
        )
      : of(null);
    this.apiSubscription.subscribe(response => {
      this.apiData = response;
      this.apiUUIDs = this.apiData
        ? Array.isArray(this.apiData)
          ? this.apiData.map(i => i?.uuid)
          : Object.keys(this.apiData)
        : null;
      this.oldExpandedData = JSON.parse(this.input.content || null);
      this.expandedData = this.initExpandedData();
      if (this.apiUUIDs?.length) {
        this.filterExpandedData();
      }
      this.fillExpandedData();
      if (
        !this.contentData.expand_nested_inputs &&
        !this.contentData.api_as_data_source
      ) {
        this.filterOutDeprecatedRows();
      }
      this.filterOutBlankRows();
      this.prepareValueClassNames();
      this.updateRow();
      this.isThereDataToExpand =
        !!this.expandedData &&
        Object.values(this.expandedData).some(data => Boolean(data.length));
      this.loading = false;
      this.validators = this.getValidators();
    });
  }

  filterExpandedData() {
    const columns = Object.keys(this.expandedData);
    const allUUIDs = this.expandedData[columns[0]].map(i => i.uuid);
    const uuidsToRemove = allUUIDs.filter(
      uuid => !this.apiUUIDs.includes(uuid)
    );

    for (const uuid of uuidsToRemove) {
      for (const column of columns) {
        this.expandedData[column] = this.expandedData[column].filter(
          data => !!data?.uuid && data.uuid !== uuid
        );
      }
    }
  }

  validateInput(inp: TemplateInput, validators: Validation[] = []): boolean {
    this.prepareValidationCellErrors();

    return super.validateInput(inp, validators);
  }

  validate() {
    return of(this.validateInput(this.input, this.validators));
  }

  getDescription() {
    return '';
  }

  getName() {
    return 'Expand Data';
  }

  getGroup() {
    return 'Generic';
  }

  trackByIndex(index: number, item: any): number {
    return index;
  }

  addNewTextarea(dataItem: ExpandedDataItem): void {
    const stringArray = dataItem.value as string[];
    stringArray.push(null);
  }

  deleteTextarea(dataItem: ExpandedDataItem, textareaIndex: number): void {
    const stringArray = dataItem.value as string[];
    this.confirmationService
      .removeDialog({ text: stringArray[textareaIndex] || 'item' })
      .subscribe(() => {
        stringArray.splice(textareaIndex, 1);
        this.updateRow();
      });
  }

  prepareValueClassNames(): void {
    this.rowValueClassNames = this.expandedData[this.columns[0].title].map(
      (item, index) =>
        this.columns
          .map(column => {
            if (
              column.class_name_prefix &&
              this.expandedData[column.title] &&
              this.expandedData[column.title][index]?.value
            ) {
              return `${column.class_name_prefix}_${
                this.expandedData[column.title][index].value
              }`;
            } else {
              return null;
            }
          })
          .filter(Boolean)
          .join(' ')
    );
  }

  dateChangeHandler(column: string, index: number, dateValue: string): void {
    this.expandedData[column][index].value = dateValue;
    this.updateRow();
  }

  selectDropdownOption(
    event: MatSelectChange,
    columnTitle: string,
    rowIndex: number
  ): void {
    const dropdownOption: ExpandDataDropdownInfo = this.dropdownOptions[
      columnTitle
    ].find(item => item.value === event.value);
    this.expandedData[columnTitle][rowIndex].value = event.value;
    this.expandedData[columnTitle][
      rowIndex
    ].useSubValue = !!dropdownOption?.trigger_additional_input;
    this.updateRow();
  }

  updateRow() {
    const content = JSON.stringify(this.expandedData);
    if (!!content && this.input.content !== content) {
      this.input.content = content;
      this.contentChanged(this.input, this.validators);
    }
  }

  prepareIceRowToUpdate() {
    this.removeCircularStructure(this.expandedData);
    this.updateRow();
  }

  onInputValueChange(item: ExpandedDataItem, value): void {
    item.value = value;
    this.prepareValueClassNames();
  }

  editInputInCell($event) {
    if (!this.disabled) {
      if (
        ($event.target.nodeName === 'DIV' || $event.target.nodeName === 'TD') &&
        $event.target.children.length
      ) {
        $event.target.children[0].focus();
      }
    }
  }

  getInputFromBlockRepeater(
    blockRepeaterName: string,
    inputName: string
  ): TemplateInput {
    const blockRepeater = this.getInput(blockRepeaterName);
    const content = blockRepeater?.content
      ? JSON.parse(blockRepeater.content)
      : null;
    const tabIndex: string = this.contentOptions.tabIndex as string;
    if (content) {
      const data: BlockRepeaterContent = updateBlockRepeaterFormat(content);
      const block = data?.blocks[Number(tabIndex)];
      const inputIndex = Object.keys(block.data).find(
        key => !!block.data[key][inputName]
      );

      return (
        (inputIndex !== undefined ? block.data[inputIndex][inputName] : null) ||
        null
      );
    }

    return null;
  }

  private getUniqueValues(
    data: Array<MatrixElement[]>
  ): Array<MatrixElement[]> {
    const unique = [];
    data.forEach(item => {
      const found = unique.find(find => find[0].data === item[0].data);
      if (!found) {
        unique.push(item);
      }
    });

    return unique;
  }

  private fillExpandedData(): void {
    this.inputsToExpand.reduce((startIndex, inputToExpand) => {
      startIndex = this.fillExpandedDataBy(inputToExpand, startIndex);

      return startIndex;
    }, 0);
  }

  private fillExpandedDataBy(
    inputToExpand: TemplateInput,
    startIndex: number
  ): number {
    if (!inputToExpand.element_key.includes('persona')) {
      const contentToExpand = JSON.parse(inputToExpand.content || null) || null;
      if (contentToExpand) {
        if (inputToExpand.element_key.includes('matrix_entry')) {
          this.fillExpandedDataByMatrixListEntry(inputToExpand, startIndex);
        } else if (inputToExpand.element_key.includes('list_entry')) {
          this.fillExpandedDataByListEntry(contentToExpand, startIndex);
        } else if (inputToExpand.element_key.includes('checkbox')) {
          this.fillExpandedDataByCheckboxSelector(contentToExpand, startIndex);
        } else if (inputToExpand.element_key.includes('expand_data')) {
          this.fillExpandedDataByExpandTemplate(contentToExpand, startIndex);
        }
      } else {
        this.isThereDataToExpand =
          this.inputsToExpand.length > 1 ? this.isThereDataToExpand : false;
        Object.keys(this.expandedData).reduce(
          (accum, column) => {
            this.expandedData[column].forEach((item, index) => {
              this.validRows[startIndex + index] = true;
            });

            return accum;
          },
          { ...this.expandedData }
        );
      }
    } else {
      Object.keys(this.expandedData).reduce(
        (accum, column) => {
          this.expandedData[column].forEach((item, index) => {
            this.validRows[startIndex + index] = true;
          });

          return accum;
        },
        { ...this.expandedData }
      );
    }

    return startIndex + this.rowsLengthFromInput(inputToExpand);
  }

  private fillExpandedDataByListEntry(
    listData: ListElement[],
    startIndex: number
  ) {
    const rowsToAdd: ListElement[] = Object.values(listData);
    const listToAdd = this.columns.filter(
      column => !!column.source_data_key_sub_list_entry_only
    );

    this.expandedData = Object.keys(this.expandedData).reduce(
      (accum, column) => {
        const columnIndex = this.columns.findIndex(i => i.title === column);
        rowsToAdd.forEach((row, rowIndex) => {
          const strictIndex = startIndex + rowIndex;
          const item =
            this.oldExpandedData?.[column]?.find(i => i.uuid === row.uuid) ||
            this.createColumnItem(columnIndex, row.uuid);
          const isExpandedColumn = !!listToAdd.find(
            list => list.title === column
          );
          accum[column][strictIndex] = {
            ...item,
            value: isExpandedColumn ? row.option : item.value
          };
          this.validRows[strictIndex] = true;
        });

        return accum;
      },
      { ...this.expandedData }
    );
    this.isThereDataToExpand = Boolean(
      Object.values(this.expandedData || {})?.[0]?.length
    );
  }

  private fillExpandedDataByMatrixListEntry(
    input: TemplateInput,
    startIndex: number
  ): void {
    const data: MatrixElement[][] = JSON.parse(input.content || null);
    let matrixData = updateMatrixEntryFormat(data);
    const fieldsRegExp = /(?<={)[^}]+(?=})/g;
    const separatorExp = /(?<=})[^}]*.(?={)/g;
    if (JSON.stringify(data) !== JSON.stringify(matrixData)) {
      input.content = JSON.stringify(matrixData);
      this.contentChanged(input);
    }
    if (!this.contentData.turn_off_unique_values_filtering) {
      matrixData = this.getUniqueValues(matrixData).filter(matrixRow =>
        matrixRow.some(matrixElement => !!matrixElement.data)
      );
    }
    this.expandedData = Object.keys(this.expandedData).reduce(
      (accum, column) => {
        const columnIndex = this.columns.findIndex(i => i.title === column);
        const isOldFormatExpandData = this.oldExpandedData?.[column]?.every(
          i => !i.uuid
        );
        matrixData.forEach((row, rowIndex) => {
          const strictIndex = startIndex + rowIndex;
          let matrixEl = row.find(
            i =>
              i.title ===
              (this.columns && this.columns[columnIndex]
                ? this.columns[columnIndex].title_from_input ||
                  this.columns[columnIndex].title
                : '')
          );
          if (!matrixEl && columnIndex === 0) {
            matrixEl = row[0];
          }
          const rowUUID = row[0]?.uuid;
          const oldItem = isOldFormatExpandData
            ? this.createColumnItem(
                columnIndex,
                rowUUID,
                this.oldExpandedData?.[column]?.[strictIndex]?.value
              )
            : this.oldExpandedData?.[column]?.find(i => i.uuid === rowUUID);
          const item =
            oldItem ||
            this.createColumnItem(columnIndex, rowUUID, matrixEl?.data);
          const pattern =
            this.columns && this.columns[columnIndex]
              ? this.columns[columnIndex]
                  .source_data_pattern_sub_matrix_entry_only
              : null;
          const fields = pattern?.match(fieldsRegExp);
          const separator = pattern?.match(separatorExp);
          const isEditable =
            this.columns &&
            this.columns[columnIndex] &&
            !this.columns[columnIndex].is_read_only;
          const value = isEditable
            ? item.value
            : fields?.length > 1
            ? matrixData[rowIndex][+fields[0] - 1]?.data +
              separator[0] +
              matrixData[rowIndex][+fields[1] - 1]?.data
            : matrixEl?.data;

          accum[column][strictIndex] = { ...item, value };

          if (
            this.columns &&
            this.columns[columnIndex] &&
            this.columns[columnIndex].default_data?.length
          ) {
            if (this.columns[columnIndex].type_select === 'ice') {
              accum[column][strictIndex].ice = accum[column][strictIndex]
                .ice || {
                content: ''
              };
              accum[column][strictIndex].ice.content =
                accum[column][strictIndex].ice.content ||
                this.columns[columnIndex].default_data;
            } else {
              accum[column][strictIndex].value =
                accum[column][strictIndex].value ||
                this.columns[columnIndex].default_data;
            }
          }
          this.validRows[strictIndex] = true;
        });

        return accum;
      },
      { ...this.expandedData }
    );
    this.isThereDataToExpand = Boolean(matrixData.length);
  }

  private fillExpandedDataByExpandTemplate(
    expandData: ExpandedData,
    startIndex: number
  ): void {
    const expandDataRowNumber =
      Object.values(expandData || {})?.[0]?.length || 0;
    const emptyRows = [...new Array(expandDataRowNumber)].map(() => null);
    if (this.contentData.expand_nested_inputs) {
      this.moduleContentService
        .load(
          this.data.data.module_id,
          Number(this.data.data.linked_ids[0]),
          this.data.data.org_id
        )
        .pipe(
          take(1),
          switchMap(linkedExpandedStep =>
            this.moduleContentService.load(
              linkedExpandedStep.module_id,
              Number(linkedExpandedStep.linked_ids[0]),
              linkedExpandedStep.org_id
            )
          )
        )
        .subscribe(sourceStep => {
          startIndex = this.fillExpandedDataBy(
            sourceStep.inputs[Object.keys(sourceStep.inputs)[0]],
            startIndex
          );

          this.expandedData = Object.keys(this.expandedData).reduce(
            (accum, column) => {
              this.expandedData[column].forEach((item, index) => {
                const parentItem = expandData[column]?.find(
                  i => i.id === item.id
                );
                if (parentItem) {
                  this.validRows[startIndex + index] = true;
                }

                accum[column][index] = {
                  ...accum[column][index],
                  value: parentItem
                    ? parentItem.value
                    : accum[column][index].value,
                  id: item.id || null
                };
              });

              return accum;
            },
            { ...this.expandedData }
          );
        });
    } else {
      this.expandedData = Object.keys(this.expandedData).reduce(
        (accum, column) => {
          const columnToAdd = expandData[column]
            ? expandData[column].map(
                (item, index) => expandData[column][index] || item
              )
            : [...emptyRows];
          columnToAdd.forEach((item, index) => {
            this.validRows[startIndex + index] = true;
            accum[column][startIndex + index] = {
              ...accum[column][startIndex + index],
              value: item?.value || accum[column][startIndex + index]?.value
            };
          });

          return accum;
        },
        { ...this.expandedData }
      );
    }
  }

  private fillExpandedDataByCheckboxSelector(
    expandData: CheckboxSelectorItem[],
    startIndex: number
  ): void {
    const inputContent = JSON.parse(this.input.content) || {};
    const rowsToAdd = expandData.reduce((accum, checkbox) => {
      if (this.contentData.save_only_negative_checkboxes) {
        return !checkbox.checked ? [...accum, checkbox] : [...accum];
      } else {
        return checkbox.checked ? [...accum, checkbox] : [...accum];
      }
    }, []);

    this.expandedData = Object.keys(this.expandedData).reduce(
      (accum, column, columnIndex) => {
        rowsToAdd.forEach((item, rowIndex) => {
          const existedItem = inputContent[column]?.find(
            checkbox => checkbox && checkbox.id === item.id
          );
          const itemToAdd = columnIndex && existedItem ? existedItem : item;
          if (
            !accum[column].find(
              existed => existed && existed.id === itemToAdd.id
            )
          ) {
            accum[column][startIndex + rowIndex] = {
              ...accum[column][startIndex + rowIndex],
              id: itemToAdd.id,
              value: columnIndex ? itemToAdd?.value : itemToAdd.title
            };
          }
        });

        accum[column] = accum[column].filter(item =>
          [...rowsToAdd, ...this.existedExpandedData].some(
            checkbox => checkbox.id === item.id
          )
        );

        this.existedExpandedData = this.existedExpandedData
          .concat(accum[column])
          .filter(function(checkbox) {
            return this.has(checkbox.id) ? false : this.add(checkbox.id);
          }, new Set());

        if (!columnIndex) {
          this.isThereDataToExpand = !this.isThereDataToExpand
            ? Boolean(accum[column].length)
            : this.isThereDataToExpand;
        }

        return accum;
      },
      { ...this.expandedData }
    );
  }

  private setAccDataByUUID(
    accum: ExpandedData,
    column: TExpandDataColumn,
    uuid: string,
    item: ExpandedDataItem,
    forceUpdate = false
  ) {
    const dataIndex = accum[column.title].findIndex(data => data.uuid === uuid);

    if (dataIndex === -1) {
      accum[column.title].push(item);
    } else if (forceUpdate) {
      accum[column.title][dataIndex] = item;
    }

    return accum;
  }

  private initExpandedData(): ExpandedData {
    const parsedData: ExpandedData = JSON.parse(this.input.content) || {};
    const accum: ExpandedData = {};
    const predefinedNumber = this.getPredefinedRowsLength();

    this.columns.forEach((column, columnIndex) => {
      accum[column.title] = parsedData?.[column.title] || [];
      switch (column.type_select) {
        case 'dropdown':
          if (column.take_input_values_as_dropdown_options) {
            const dropdownInputContent =
              JSON.parse(this.getInput(column.from_input)?.content || null) ||
              [];
            const isMatrixTitle = dropdownInputContent.every(matrixRow =>
              matrixRow.every(matrixElement => !!matrixElement.title)
            );
            this.dropdownOptions[column.title] = dropdownInputContent
              .map(matrixRow =>
                matrixRow.find(matrixElement =>
                  isMatrixTitle
                    ? matrixElement.title === column.title_from_input
                    : Number(matrixElement.id) === 1
                )
              )
              .filter(matrixElement => !!matrixElement)
              .map(matrixElement => matrixElement.data)
              .filter(value => !!value)
              .map(value => ({ value }));
          } else {
            this.dropdownOptions[column.title] = column.dropdown_options.map(
              option => ({
                ...option,
                value: this.textContent(option.value)
              })
            );
          }
          break;
        case 'multiple_textareas': {
          accum[column.title] = accum[column.title].map(item => ({
            ...item,
            value: Array.isArray(item.value)
              ? item.value
              : [item.value ? String(item.value) : null]
          }));
          break;
        }
      }

      if (this.apiData && this.apiUUIDs?.length) {
        switch (column.type_select) {
          case 'persona':
            for (const [uuid, persona] of Object.entries(this.apiData)) {
              this.setAccDataByUUID(
                accum,
                column,
                uuid,
                { uuid, persona },
                true
              );
            }
            break;
          case 'ice':
            for (const [index, uuid] of this.apiUUIDs.entries()) {
              let data = null;
              const col = parsedData[column.title];
              if (col && col[index]) {
                data = col[index].ice;
              }

              this.setAccDataByUUID(accum, column, uuid, {
                uuid,
                ice: data || null
              });
            }
            break;
          case 'checkboxes':
            this.apiUUIDs.forEach(uuid => {
              const data = accum[column.title].find(i => i.uuid === uuid);
              const item: ExpandedDataItem = {
                uuid,
                value: null,
                checkboxes: column.checkboxes?.map(i => {
                  const oldCheckbox = data?.checkboxes?.find(
                    c => c.id === i.id
                  );

                  return {
                    ...i,
                    userText: oldCheckbox?.userText,
                    isChecked: oldCheckbox?.isChecked
                  };
                })
              };
              this.setAccDataByUUID(accum, column, uuid, item, true);
            });
            break;
          default:
            this.apiUUIDs.forEach((uuid, index) => {
              const apiItem = Array.isArray(this.apiData)
                ? this.apiData[index]
                : this.apiData[uuid];
              const data = accum[column.title].find(i => i.uuid === uuid);
              const value =
                column.is_read_only && column.api_key
                  ? apiItem[column.api_key]
                  : data?.value;
              this.setAccDataByUUID(accum, column, uuid, { uuid, value }, true);
            });
            break;
        }
      }

      const currentLength = accum[column.title]?.length || 0;
      if (currentLength < predefinedNumber) {
        const blankElements: ExpandedDataItem[] = [
          ...new Array(predefinedNumber - currentLength)
        ].map(() => this.createColumnItem(columnIndex));
        accum[column.title].push(...blankElements);
      }
    });

    return accum;
  }

  private getLinkedInputsToExpand(): TemplateInput[] {
    return Object.keys(this.data.data.inputs)
      .filter(inputName => !inputName.includes(this.contentData.input_sufix))
      .map(inputName => this.getInput(inputName));
  }

  private filterOutDeprecatedRows(): void {
    this.expandedData = Object.keys(this.expandedData).reduce(
      (accum, columnName) => {
        accum[columnName] = this.expandedData[columnName].filter(
          (item, rowIndex) => this.validRows[rowIndex]
        );

        return accum;
      },
      {}
    );
  }

  private filterOutBlankRows(): void {
    const blankRows = this.expandedData[this.columns[0].title]
      .map((item, index) =>
        !item?.value && !item?.checkboxes && !item?.persona ? index : null
      )
      .filter(Boolean);
    const filledRowsLength =
      this.expandedData[this.columns[0].title].length - blankRows.length;
    this.expandedData = Object.keys(this.expandedData).reduce(
      (accum, columnName) => {
        accum[columnName] = this.expandedData[columnName]
          .filter((item, itemIndex) => !blankRows.includes(itemIndex))
          .filter((item, itemIndex) => itemIndex < filledRowsLength);

        return accum;
      },
      {}
    );
  }

  private rowsLengthFromInput(input: TemplateInput): number {
    if (!input.element_key.includes('persona')) {
      const content = JSON.parse(input.content || null) || null;
      if (input.element_key.includes('matrix_entry')) {
        return content?.filter(row =>
          row.some(element => Number(element.id) === 1 && !!element?.data)
        ).length;
      } else if (input.element_key.includes('list_entry')) {
        return Object.values(content)?.filter((row: any) => !!row.option)
          .length;
      } else if (input.element_key.includes('checkbox')) {
        const count =
          this.expandedData &&
          Object.values(this.expandedData[Object.keys(this.expandedData)[0]])
            ?.length;

        return (this.expandedData && count) || 0;
      } else if (input.element_key.includes('expand_data')) {
        return (content && content[this.columns[0].title]?.length) || 0;
      } else if (input.element_key.includes('block_repeater')) {
        const info = this.contentData.inputs_to_expand?.find(
          item => item.block_repeater_input_key === input.element_key
        );
        const childInput = info
          ? this.getInputFromBlockRepeater(
              info.block_repeater_input_key,
              info.key
            )
          : null;

        return childInput ? this.rowsLengthFromInput(childInput) : 0;
      }
    }

    return Array.isArray(this.apiData)
      ? this.apiData.length
      : Object.keys(this.apiData)?.length;
  }

  private getPredefinedRowsLength(): number {
    return !!this.apiData
      ? Array.isArray(this.apiData)
        ? this.apiData.length
        : Object.keys(this.apiData)?.length
      : this.inputsToExpand
          .map(input => this.rowsLengthFromInput(input))
          .filter(Boolean)
          .reduce((sum, inputLength) => sum + inputLength, 0) || 0;
  }

  private getDataColumns(): TExpandDataColumn[] {
    const getStartPosition = (
      columns: TExpandDataColumn[],
      startPosition: number
    ): number => {
      const index = columns.reduce(
        (indexAccum, column, columnIndex) => {
          if (!!column && !column.isCustomColumn) {
            indexAccum.standardColumnAccum = indexAccum.standardColumnAccum + 1;
            if (indexAccum.standardColumnAccum - 1 === startPosition) {
              indexAccum.index = columnIndex;
            }
          }

          return indexAccum;
        },
        { standardColumnAccum: 0, index: -1 }
      ).index;

      return index === -1 ? columns.length : index;
    };
    const standardColumns = this.contentData.columns;
    if (
      this.contentData.enable_custom_columns &&
      this.contentData.custom_columns?.length
    ) {
      return this.contentData.custom_columns.reduce(
        (accum: TExpandDataColumn[], customColumn) => {
          const positionIndex = customColumn.column_position_index;
          if (
            (!!positionIndex || positionIndex === 0) &&
            customColumn?.from_input
          ) {
            const customColumns = this.getCustomColumns(customColumn).filter(
              customCol => !accum.some(col => col.title === customCol.title)
            );
            const startPosition = getStartPosition(accum, positionIndex);
            accum.splice(startPosition, 0, ...customColumns);
          }

          return accum;
        },
        [...standardColumns]
      );
    }

    return standardColumns;
  }

  private getCustomColumns(
    customColumn: TExpandDataColumn
  ): TExpandDataColumn[] {
    const input = this.inputs[customColumn.from_input];
    let values: TExpandDataColumn[] = [];
    if (input.element_key.includes('matrix_entry')) {
      const matrixContent: MatrixElement[][] =
        JSON.parse(input.content || null) || null;
      values = matrixContent
        .map(row =>
          row.find(cell => cell.title === customColumn.title_from_input)
        )
        .filter(cell => cell?.data)
        .map(cell => ({
          ...customColumn,
          title: String(cell.data),
          title_from_input: String(cell.data),
          isCustomColumn: true
        }))
        .map(column => {
          const type: TCustomColumnRegexType = this.contentData.types_for_custom_columns.find(
            regexType => {
              try {
                return (
                  column.title.search(
                    new RegExp(regexType.title_regex, 'gi')
                  ) !== -1
                );
              } catch (e) {
                return false;
              }
            }
          );

          return type
            ? {
                title: column.title,
                title_from_input: column.title_from_input,
                isCustomColumn: true,
                ...type
              }
            : column;
        });
    }

    return values;
  }

  private createColumnItem(
    columnIndex: number,
    uuid?: string,
    value: MatrixElementData = null
  ): ExpandedDataItem {
    const column: TExpandDataColumn = this.columns[columnIndex];
    let newItem: ExpandedDataItem = { value, uuid, id: columnIndex };
    if (column && column.type_select === 'checkboxes') {
      const checkboxes = column.checkboxes.map(checkbox => ({
        ...checkbox,
        userText: '',
        isChecked: false
      }));
      newItem = { ...newItem, checkboxes };
    } else if (column && column.type_select === 'multiple_textareas') {
      const startNumber = column.number_of_pregenerated_items || 0;
      const predefinedArray: string[] = [...new Array(startNumber)].map(
        () => null
      );

      newItem = { ...newItem, value: predefinedArray };
    }

    return newItem;
  }

  private getValidators(): Validation[] {
    return this.columns
      .map(column => {
        if (column.required) {
          const requiredMessage = 'Please fill in the fields marked above';
          const params = { title: column.title };
          let requiredFunc = (value: string) => {
            const content: ExpandedData = JSON.parse(value || null);

            return (
              content &&
              content[column.title] &&
              content[column.title]?.every(cell => !!cell?.value)
            );
          };
          switch (column.type_select) {
            case 'checkboxes': {
              requiredFunc = (value: string) => {
                const content: ExpandedData = JSON.parse(value || null);

                return (
                  content &&
                  content[column.title] &&
                  content[column.title].every(
                    cell =>
                      !!cell?.checkboxes?.some(checkbox => checkbox.isChecked)
                  )
                );
              };
              break;
            }
          }

          return new Validation(requiredFunc, requiredMessage, params);
        }

        return null;
      })
      .filter(Boolean);
  }

  private prepareValidationCellErrors(): void {
    this.validationCellErrors = this.columns.reduce(
      (accum: { [title: string]: boolean[] }, column) => {
        accum[column.title] = null;
        if (column.required) {
          switch (column.type_select) {
            case 'checkboxes': {
              accum[column.title] = this.expandedData[column.title]?.map(
                item => !item.checkboxes?.some(checkbox => checkbox.isChecked)
              );
              break;
            }
            default: {
              accum[column.title] = this.expandedData[column.title]?.map(
                item => !item.value
              );
              break;
            }
          }
        }

        return accum;
      },
      {}
    );
  }
}
