import { Component, forwardRef } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import txt from '!!raw-loader!./index.ts';
import { CheckboxSelectorItem, CheckboxSelectorTemplateData } from '.';
import { Observable, of } from 'rxjs';
import { Validate } from 'src/app/common/validator.class';
import { MatRadioChange } from '@angular/material/radio';
import { TemplateInput } from '../../../../common/interfaces/module.interface';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { v4 as uuidv4 } from 'uuid';
import { switchMap } from 'rxjs/operators';
import { BuyerPersona } from '../../../../common/interfaces/buyer-persona.interface';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-checkbox-selector',
  templateUrl: './checkbox-selector.component.html',
  styleUrls: ['./checkbox-selector.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => CheckboxSelectorComponent)
    }
  ]
})
export class CheckboxSelectorComponent extends TemplateComponent {
  params = txt;
  contentData: CheckboxSelectorTemplateData['template_params_json'];

  faTrashedIcon = faTrash;

  items: CheckboxSelectorItem[];
  isUserCustomSectionFormVisible = false;
  userCustomQuestion = '';
  apiResponse: BuyerPersona[];
  showOnlyChecked = false;
  input: TemplateInput;

  getDescription(): string {
    return 'Checkbox selector';
  }

  getName(): string {
    return 'Checkbox selector';
  }

  getGroup(): string {
    return 'Generic';
  }

  hasInputs(): boolean {
    return true;
  }

  validate(): Observable<boolean> {
    if (this.contentData.useRadioButtons) {
      return of(
        this.items
          .filter(i => !i.isCustom)
          .every(i => i.checked !== null && i.checked !== undefined)
      );
    }

    // @todo: refactor
    const minValidator = Validate.min(
      this.contentData.minimum_of_required_selections
    );
    const maxValidator = Validate.max(
      this.contentData.maximum_of_required_selections
    );
    const checkedItemsAmount = this.items.filter(
      (item: CheckboxSelectorItem) => item.checked
    ).length;

    if (
      this.contentData.minimum_of_required_selections &&
      this.contentData.maximum_of_required_selections
    ) {
      return of(
        minValidator.isValid(checkedItemsAmount) &&
          maxValidator.isValid(checkedItemsAmount)
      );
    }

    if (
      this.contentData.minimum_of_required_selections &&
      !this.contentData.maximum_of_required_selections
    ) {
      return of(minValidator.isValid(checkedItemsAmount));
    }

    if (
      !this.contentData.minimum_of_required_selections &&
      this.contentData.maximum_of_required_selections
    ) {
      return of(maxValidator.isValid(checkedItemsAmount));
    }

    return of(true);
  }

  init() {
    this.contentData = this.data.data
      .template_params_json as CheckboxSelectorTemplateData['template_params_json'];
    this.contentData.customItemLabel = this.contentData.customItemLabel
      ? this.contentData.customItemLabel
      : 'Add new item';
    this.disabled = this.disabled || this.contentData.is_read_only;

    const request = this.contentData.api_as_data_source
      ? this.navService.organization$.pipe(
          switchMap(orgId =>
            this.moduleService.getAPIData(orgId, this.contentData.api_endpoint)
          )
        )
      : of(null);

    request.subscribe(response => {
      this.apiResponse = response;
      this.input =
        this.getInput('checkbox_1_' + this.contentData.input_sufix) ||
        this.getInput('checkbox_1');

      if (this.contentData.source_data_input_key) {
        const input = this.getInput(
          this.textContent(this.contentData.source_data_input_key)
        );
        const sourceData = input ? JSON.parse(input.content) : null;
        if (sourceData) {
          this.items = Object.keys(sourceData)
            .map(key => {
              const object: string = sourceData[key].option;

              return {
                id: object,
                title: object,
                description: '',
                checked: false
              };
            })
            .filter(item => item.id && item.title.trim().length > 0);
        }
      } else {
        let options = this.contentData.options as CheckboxSelectorItem[];

        if (this.apiResponse?.length) {
          options = [];
          this.apiResponse.forEach(item => {
            options.push({
              id: String(item.index),
              title: null,
              checked: null,
              description: this.contentData.is_persona
                ? null
                : item[this.contentData.api_endpoint_data_field],
              persona: this.contentData.is_persona ? item : null
            });
          });
        }

        this.items = this.contentData.allow_multiple_checkboxes_in_row
          ? options.map(option => ({
              ...option,
              multipleCheckboxes: this.contentData.multiple_checkboxes.map(
                checkbox => ({
                  ...checkbox,
                  checked: null
                })
              )
            }))
          : options.map(option => ({ ...option }));
      }

      if (this.items && this.items.some(i => !i.id)) {
        console.warn('Please define ID for all checkbox template items');
      }

      if (this.input) {
        if (this.input.content) {
          const serializedItems = JSON.parse(
            this.input.content
          ) as CheckboxSelectorItem[];
          serializedItems.forEach(item => {
            if (item.isCustom) {
              if (
                !this.items.find(
                  existedItem =>
                    existedItem.id &&
                    item.id &&
                    item.id.toString() === existedItem.id.toString()
                )
              ) {
                const customItem: CheckboxSelectorItem = {
                  ...item,
                  id: item.id,
                  title: item.title,
                  checked: item.checked,
                  multipleCheckboxes: item.multipleCheckboxes,
                  isCustom: true
                };
                this.items.push(customItem);
              }
            } else {
              const existedIndex = this.items.findIndex(
                existedItem =>
                  existedItem.id &&
                  item.id &&
                  item.id.toString() === existedItem.id.toString()
              );
              if (existedIndex !== -1) {
                let existed: CheckboxSelectorItem = this.items[existedIndex];
                if (existed.multipleCheckboxes) {
                  existed.multipleCheckboxes = existed.multipleCheckboxes.map(
                    (checkbox, id) => ({
                      ...checkbox,
                      checked:
                        item.multipleCheckboxes && item.multipleCheckboxes[id]
                          ? item.multipleCheckboxes[id].checked
                          : checkbox.checked
                    })
                  );
                }
                existed = {
                  ...item,
                  title: existed.title,
                  description: existed.description,
                  image: existed.image,
                  isCustom: existed.isCustom,
                  multipleCheckboxes: existed.multipleCheckboxes
                };
                this.items.splice(existedIndex, 1, existed);
              }
            }
          });
        }
      } else {
        console.warn('Count of inputs probably is not set');
      }
    });

    if (this.data.data.options && this.data.data.options['checked only']) {
      this.showOnlyChecked = this.data.data.options['checked only'] === 'true';
    }
  }

  onChecked(item: CheckboxSelectorItem) {
    item.checked = !item.checked;
    this.input.content = this.prepareItemToSerialize();
    this.contentChanged(this.input);
  }

  onMultipleCheckboxClick(): void {
    this.input.content = this.prepareItemToSerialize();
    this.contentChanged(this.input);
  }

  makeAnswer(item: CheckboxSelectorItem, $event?: MatRadioChange) {
    item.checked = $event
      ? $event.value
      : item.checked === undefined
      ? true
      : !item.checked;
    this.input.content = this.prepareItemToSerialize();
    this.contentChanged(this.input);
  }

  addNewQuestion(): void {
    this.userCustomQuestion = this.userCustomQuestion.trim();

    if (!this.userCustomQuestion.length) {
      this.snackBarService.error('Please fill in this field');

      return;
    }
    const newItem: CheckboxSelectorItem = {
      id: uuidv4(),
      title: this.userCustomQuestion,
      checked: true,
      isCustom: true
    };
    if (this.contentData.allow_multiple_checkboxes_in_row) {
      newItem.multipleCheckboxes = this.contentData.multiple_checkboxes.map(
        checkbox => ({
          ...checkbox,
          checked: null
        })
      );
    }
    this.userCustomQuestion = '';

    this.items.push(newItem);
    this.input.content = this.prepareItemToSerialize();
    this.contentChanged(this.input);
    this.isUserCustomSectionFormVisible = false;
  }

  removeCustomItem(index: number) {
    this.confirmationService
      .removeDialog({
        text: this.items[index].title
      })
      .subscribe(() => {
        this.items.splice(index, 1);
        this.input.content = this.prepareItemToSerialize();
        this.contentChanged(this.input);
      });
  }

  prepareItemToSerialize() {
    return JSON.stringify(
      this.items.map((item: CheckboxSelectorItem) => ({
        ...item,
        title: item.title || null
      }))
    );
  }
}
