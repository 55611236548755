import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
  ElementRef,
  AfterViewInit,
  ViewChildren,
  QueryList,
  ChangeDetectorRef
} from '@angular/core';
import { ViewDimensions } from '@swimlane/ngx-charts/lib/common/view-dimensions.helper';

@Component({
  selector: 'g[bubble-label]',
  template: `
    <svg:rect
      [attr.x]="rectX"
      [attr.y]="rectY"
      [attr.width]="rectWidth"
      [attr.height]="rectHeight"
      [attr.fill]="'white'"
    />
    <svg:text
      class="bubble-label-text"
      alignment-baseline="middle"
      [attr.text-anchor]="textAnchor"
      [attr.x]="0"
      [attr.y]="0"
    >
      <tspan
        *ngFor="let item of items"
        [attr.x]="item.x"
        [attr.y]="item.y"
        class="bubble-label-item"
        #textSpan
      >
        {{ item.value }}
      </tspan>
    </svg:text>
  `,
  styles: [
    '.bubble-label-text { font-size: 14px; font-weight: bold; font-style: italic;}'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BubbleLabelComponent implements OnChanges, AfterViewInit {
  @Input() value: string;
  @Input() posX: number;
  @Input() posY: number;
  @Input() dims: ViewDimensions;
  @Input() circleAbsX: number;
  @Input() circleAbsY: number;

  @ViewChildren('textSpan') textSpan: QueryList<ElementRef<SVGTSpanElement>>;

  element: any;
  horizontalPadding = 0;
  verticalPadding = 15;
  textAnchor: 'start' | 'middle' | 'end' = 'start';
  items: { value: string; x: number; y: number }[];
  charWidth = 8;
  charHeight = 16;
  rectX;
  rectY;
  rectWidth = 0;
  rectHeight = 0;

  constructor(element: ElementRef, private cd: ChangeDetectorRef) {
    this.element = element.nativeElement;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.update();
  }

  ngAfterViewInit(): void {
    this.textSpan.forEach(item => {
      const { x, y, width, height } = item.nativeElement.getBBox();
      this.rectX = this.rectX ? (x < this.rectX ? x : this.rectX) : x;
      this.rectY = this.rectY ? (y < this.rectY ? y : this.rectY) : y;
      this.rectWidth = width > this.rectWidth ? width : this.rectWidth;
      this.rectHeight = this.rectHeight + height;
      this.cd.detectChanges();
    });
  }

  adjustOverBorderPosition(
    value: string,
    x: number,
    y: number,
    absX: number,
    absY: number
  ): [number, number] {
    const width = value.length * this.charWidth;
    const height = this.charHeight;
    let newX = x;
    let newY = y;
    if (absX + width / 2 >= this.dims.width) {
      newX = this.dims.width - (absX + width / 2) - this.horizontalPadding;
    }
    if (absY < 0) {
      newY = this.verticalPadding + height;
    }

    return [newX, newY];
  }

  update(): void {
    const calcX = this.posX + this.horizontalPadding;
    const calcY = this.posY - this.verticalPadding;
    if (calcX === 0) {
      this.textAnchor = 'middle';
    }
    this.items = this.value.split(', ').map((item, index, self) => {
      let x = calcX;
      let y =
        calcY < 0
          ? calcY - this.verticalPadding * (self.length - index - 1)
          : calcY + this.verticalPadding * index;
      const absX = this.circleAbsX + x;
      const absY = this.circleAbsY + y;
      [x, y] = this.adjustOverBorderPosition(item, x, y, absX, absY);

      return {
        value: item,
        x,
        y
      };
    });
  }
}
