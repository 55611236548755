import * as Handsontable from 'src/assets/scripts/handsontable.js';
import * as HotFormula from 'hot-formula-parser/dist/formula-parser.min.js';
import * as Numbro from 'numbro';
import { HandsontableCellChange } from './index';
import {
  getColChar,
  getColumnIndexFromAddress,
  getRowIndexFromAddress,
  isCellChanged,
  isEditChange,
  isFormulaString,
  parseSpreadsheetCellAddress
} from '../../../../common/utils/spreadsheet-helpers';
import {
  HotNumericFormat,
  SpreadsheetCellData,
  SpreadsheetCellDependency,
  SpreadsheetCellPosition
} from '../../../../common/interfaces/spreadsheet.interface';

const parser = new HotFormula.Parser();

// @todo - IFERROR is not needed because errors are handled at renderer level
// hacky way to get rid of them is to use MAX function
// (as the expected second parameter of IFERROR is 0)
// it should be fixed more properly
const replaceIfErrorForCell = (
  cell: SpreadsheetCellData
): SpreadsheetCellData =>
  typeof cell === 'string' ? cell.split('IFERROR').join('MAX') : cell;

const replaceSUMRangeForCell = (
  cell: SpreadsheetCellData
): SpreadsheetCellData => {
  if (typeof cell === 'string') {
    const matches = cell.match(/SUM\([A-Z][0-9]+\:[A-Z][0-9]+\)/g) || [];
    matches.forEach(sum => {
      const [from, to] = sum.slice(4, -1).split(':');

      const idxFrom = Number(from.substr(1));
      const idxTo = Number(to.substr(1));
      const rowRange = Array(idxTo - idxFrom + 1)
        .fill(0)
        .map((_, i) => String(idxFrom + i));

      const colFrom = from.charCodeAt(0);
      const colRange = Array(to.charCodeAt(0) - colFrom + 1)
        .fill(0)
        .map((_, i) => String.fromCharCode(colFrom + i));

      const range = colRange.reduce(
        (refs, col) => refs.concat(rowRange.map(r => col + r)),
        []
      );

      cell = String(cell)
        .split(sum)
        .join('(' + range.join('+') + ')');
    });
  }

  return cell;
};

const replaceFormulaCommandsInCell = (
  cell: SpreadsheetCellData
): SpreadsheetCellData => {
  const replacedSUMCommand = replaceSUMRangeForCell(cell);

  return replaceIfErrorForCell(replacedSUMCommand);
};

const replaceFormulaCommands = (
  valuesWithFormulas: SpreadsheetCellData[][]
): SpreadsheetCellData[][] =>
  valuesWithFormulas?.map(row =>
    row.map((cell: string) => replaceFormulaCommandsInCell(cell))
  );

const prepareDependencyCellTable = (
  valuesWithFormulas: SpreadsheetCellData[][],
  startDependencyTable?: SpreadsheetCellDependency[][]
): SpreadsheetCellDependency[][] => {
  const startTable = startDependencyTable
    ? startDependencyTable.map(row => row.map(cell => cell))
    : valuesWithFormulas.map(row => row.map(() => null));

  const checkIsSameCell = (
    cellA: [number, number],
    cellB: [number, number]
  ): boolean => cellA[0] === cellB[0] && cellA[1] === cellB[1];

  return valuesWithFormulas.reduce(
    (dependencyTable: SpreadsheetCellDependency[][], row, rowIndex) => {
      row.forEach((cell, columnIndex) => {
        if (typeof cell === 'string' && cell[0] === '=') {
          const colChar = getColChar(columnIndex);
          const cellAddress = `${colChar}${rowIndex + 1}`;
          const cellReferences = cell.match(/[A-Z][0-9]+/g) || [];
          cellReferences.forEach(cellRefAddress => {
            if (cellRefAddress !== cellAddress) {
              const [refRow, refColumn] = parseSpreadsheetCellAddress(
                cellRefAddress
              );
              if (!(rowIndex === refRow && columnIndex === refColumn)) {
                if (dependencyTable[rowIndex][columnIndex]) {
                  if (
                    dependencyTable[rowIndex][
                      columnIndex
                    ].dependOn.findIndex(dependCell =>
                      checkIsSameCell(dependCell, [refRow, refColumn])
                    ) === -1
                  ) {
                    dependencyTable[rowIndex][columnIndex].dependOn.push([
                      refRow,
                      refColumn
                    ]);
                  }
                } else {
                  dependencyTable[rowIndex][columnIndex] = {
                    dependOn: [[refRow, refColumn]],
                    referenceIn: []
                  };
                }
                if (dependencyTable[refRow][refColumn]) {
                  if (
                    dependencyTable[refRow][
                      refColumn
                    ].referenceIn.findIndex(referenceCell =>
                      checkIsSameCell(referenceCell, [rowIndex, columnIndex])
                    ) === -1
                  ) {
                    dependencyTable[refRow][refColumn].referenceIn.push([
                      rowIndex,
                      columnIndex
                    ]);
                  }
                } else {
                  dependencyTable[refRow][refColumn] = {
                    dependOn: [],
                    referenceIn: [[rowIndex, columnIndex]]
                  };
                }
              }
            }
          });
        }
      });

      return dependencyTable;
    },
    startTable
  );
};

const getCellsToUpdate = (
  dependencyTable: SpreadsheetCellDependency[][],
  changedCells: SpreadsheetCellPosition[]
): SpreadsheetCellPosition[] => {
  const getDependentCells = (
    row: number,
    column: number
  ): SpreadsheetCellPosition[] => {
    const actualCell = { row, column };
    let dependentCells: { row: number; column: number }[] = [];
    if (dependencyTable[row][column]?.dependOn) {
      dependentCells = dependencyTable[row][column]?.dependOn
        .map(cell => getDependentCells(cell[0], cell[1]))
        .flat();
    }

    return dependentCells.length
      ? [...dependentCells, actualCell]
      : [actualCell];
  };
  const getReferenceInCells = (
    row: number,
    column: number
  ): SpreadsheetCellPosition[] => {
    const actualCell = { row, column };
    let referenceInCells: { row: number; column: number }[] = [];
    if (dependencyTable[row][column]?.referenceIn) {
      referenceInCells = dependencyTable[row][column]?.referenceIn
        .map(cell => getReferenceInCells(cell[0], cell[1]))
        .flat();
    }

    return referenceInCells.length
      ? [actualCell, ...referenceInCells]
      : [actualCell];
  };

  return changedCells
    .reduce((accum: SpreadsheetCellPosition[], changedCell) => {
      const { row, column } = changedCell;
      const dependentCells = getDependentCells(row, column);
      const referenceInCells = getReferenceInCells(row, column);
      const cells = [...dependentCells, ...referenceInCells];
      accum.push(...cells);

      return accum;
    }, [])
    .reduce((filteredCells: SpreadsheetCellPosition[], cell) => {
      const index = filteredCells.findIndex(
        uniqueCell =>
          cell.row === uniqueCell.row && cell.column === uniqueCell.column
      );
      if (index !== -1) {
        filteredCells.splice(index, 1);
      }
      filteredCells.push(cell);

      return filteredCells;
    }, []);
};

const getValue = (
  cellAddress: string,
  valuesWithFormulas: SpreadsheetCellData[][],
  allValuesWithFormulas: SpreadsheetCellData[][],
  settings
): SpreadsheetCellData => {
  const rowIndex = getRowIndexFromAddress(cellAddress);
  const tableRowIndex = settings.tableRowIndexes?.[rowIndex];
  const row =
    tableRowIndex === null
      ? allValuesWithFormulas[rowIndex]
      : valuesWithFormulas[tableRowIndex];
  if (row) {
    const columnIndex = cellAddress.charCodeAt(0) - 'A'.charCodeAt(0);

    return row[columnIndex];
  }

  return null;
};

const applyCellCalculation = (
  cell: SpreadsheetCellData,
  rowIndex: number,
  columnIndex: number,
  valuesWithFormulas: SpreadsheetCellData[][],
  allValuesWithFormulas: SpreadsheetCellData[][],
  settings,
  tableWithCalculatedValues?: SpreadsheetCellData[][]
): SpreadsheetCellData => {
  if (typeof cell === 'string') {
    const fullTableIndex =
      settings.displayedRows?.[rowIndex]?.fullTableIndex ?? rowIndex;
    const selfRef = getColChar(columnIndex) + (fullTableIndex + 1).toString();
    // replace a formula self-reference with 0
    cell = (cell as string)
      .split(new RegExp(`${selfRef}(?![0-9])`, 'g'))
      .join('0');

    if (cell[0] === '=') {
      (cell.match(/[A-Z][0-9]+/g) || []).forEach(ref => {
        const referenceColumnIndex = getColumnIndexFromAddress(ref);
        const referenceRowIndex = getRowIndexFromAddress(ref);
        const tableRowIndex = settings.tableRowIndexes?.[referenceRowIndex];
        const isDisplayedRow = tableRowIndex !== null;

        const refVal = tableWithCalculatedValues
          ? tableWithCalculatedValues?.[tableRowIndex]?.[
              referenceColumnIndex
            ] || ''
          : getValue(ref, valuesWithFormulas, allValuesWithFormulas, settings);
        // cell reference can be replaced with a calculated value
        if (!isFormulaString(refVal as string) || !isDisplayedRow) {
          let replacedValue = '';
          if (refVal === '' || !isDisplayedRow) {
            replacedValue = '0';
          } else if (!isNaN(Number(refVal))) {
            replacedValue = Number(refVal).toString();
          } else {
            replacedValue = '"' + refVal + '"';
          }

          cell = (cell as string)
            .split(new RegExp(ref + '(?![0-9])', 'g'))
            .join(replacedValue);
        }
      });

      // calculate the result when all cell references have been replaced by numbers
      if (!cell.match(/[A-Z][0-9]/)) {
        const valueString = cell.replace(/^=/gi, '').replace(/\+\s*\)/gi, ')');
        cell = parser.parse(valueString).result;
      }
    }
  }

  return cell;
};

const applyBasicCalculations = (
  valuesWithFormulas: SpreadsheetCellData[][],
  allValuesWithFormulas: SpreadsheetCellData[][],
  settings
) =>
  valuesWithFormulas.map((row, rowIndex) =>
    row.map((cell, columnIndex) =>
      applyCellCalculation(
        cell,
        rowIndex,
        columnIndex,
        valuesWithFormulas,
        allValuesWithFormulas,
        settings
      )
    )
  );

const calculateValues = (valuesWithFormulas, allValues, settings) => {
  // replace SUM ranges with simple additions, for example, SUM(A1:A3) becomes A1+A2+A3
  let values = replaceFormulaCommands(valuesWithFormulas);

  // repeatedly apply cell calculations until all cell references have been replaced by calculated values
  let previousResult = '';
  while (JSON.stringify(values) !== previousResult) {
    previousResult = JSON.stringify(values);
    values = applyBasicCalculations(values, allValues, settings);
  }

  return values;
};

const updateTableValues = (
  currentTableValues: SpreadsheetCellData[][],
  updateCells: SpreadsheetCellPosition[],
  valuesWithFormulas: SpreadsheetCellData[][],
  allValuesWithFormulas: SpreadsheetCellData[][],
  settings
): SpreadsheetCellData[][] =>
  updateCells.reduce(
    (updatedTable: SpreadsheetCellData[][], cell) => {
      const { row, column } = cell;
      const localRowIndex = settings.tableRowIndexes[row];
      const cellWithFormula = replaceFormulaCommandsInCell(
        valuesWithFormulas[localRowIndex][column]
      );
      updatedTable[localRowIndex][column] = applyCellCalculation(
        cellWithFormula,
        row,
        column,
        valuesWithFormulas,
        allValuesWithFormulas,
        settings,
        updatedTable
      );

      return updatedTable;
    },
    currentTableValues.map(row => row.map(cell => cell))
  );

export function FormulaPlugin(hotInstance) {
  // Call the BasePlugin constructor.
  // @ts-ignore
  Handsontable.plugins.BasePlugin.call(this, hotInstance);

  this._superClass = Handsontable.plugins.BasePlugin;
}

// Inherit the BasePlugin prototype.
FormulaPlugin.prototype = Object.create(
  // @ts-ignore
  Handsontable.plugins.BasePlugin.prototype,
  {
    constructor: {
      writable: true,
      configurable: true,
      value: FormulaPlugin
    }
  }
);

// Enable plugin for all instances
FormulaPlugin.prototype.isEnabled = () => true;

FormulaPlugin.prototype.enablePlugin = function() {
  let allValues: SpreadsheetCellData[][];
  let tableValues: SpreadsheetCellData[][];
  let settings: Handsontable.GridSettings;
  let cellsToUpdate: SpreadsheetCellPosition[] = [];
  let dependencyTable: SpreadsheetCellDependency[][];

  this.addHook('init', () => {
    settings = this.hot?.getSettings()?.__proto__;
    allValues = replaceFormulaCommands(settings.initialData);
    tableValues = calculateValues(this.hot.getData(), allValues, settings);
    if (allValues) {
      dependencyTable = prepareDependencyCellTable(
        allValues,
        settings.cellRendererDependencyTable
      );
    }
  });

  this.addHook(
    'beforeChange',
    (context, changes: HandsontableCellChange[], source: string) => {
      settings = this.hot?.getSettings()?.__proto__;
      if (!isEditChange(context, changes, source)) {
        cellsToUpdate = [];
        this.hot.lastChanges = null;
        this.hot.dependentChanges = null;

        return;
      }
      // sometimes the context argument is not passed (HOT 6 only)
      if (!source) {
        changes = context;
      }

      this.hot.lastChanges = [];
      this.hot.dependentChanges = [];

      const changedCells = changes
        .filter(change => isCellChanged(change))
        .map(change =>
          settings?.displayedRows?.[change[0]]
            ? {
                row: settings.displayedRows[change[0]].fullTableIndex,
                column: Number(change[1])
              }
            : null
        )
        .filter(Boolean);

      if (!changedCells.length) {
        return;
      }

      cellsToUpdate = getCellsToUpdate(dependencyTable, changedCells);
      this.hot.dependentChanges = cellsToUpdate.map(cell => [
        settings.tableRowIndexes[cell.row],
        cell.column
      ]);
    }
  );

  this.addHook('beforeRender', (isForced: boolean, skipRender: any) => {
    if (!isForced) {
      this.hot.lastChanges = null;
      this.hot.dependentChanges = null;
    }
    settings = this.hot?.getSettings()?.__proto__;
    const data = this.hot.getData();
    if (cellsToUpdate.length && dependencyTable) {
      tableValues = updateTableValues(
        tableValues,
        cellsToUpdate,
        data,
        allValues,
        settings
      );
      cellsToUpdate = [];
    } else if (data.length !== tableValues.length) {
      tableValues = calculateValues(data, allValues, settings);
    }
  });

  // returns a calculated value for validation (instead of formula)
  this.addHook(
    'beforeValidate',
    (value: any, row: number, prop: string | number, source?: string) =>
      tableValues[row][prop]
  );

  this.addHook(
    'beforeValueRender',
    (
      value: any,
      cellProperties: {
        row: number;
        col: number;
        numericFormat: HotNumericFormat;
      }
    ) => {
      if (!value || value[0] !== '=') {
        if (typeof value === 'string' && Number(value)) {
          return Math.round(Number(value) * 1000) / 1000;
        } else {
          return value;
        }
      }

      let calculated = tableValues[cellProperties.row][cellProperties.col];

      if (typeof calculated === 'string' || typeof calculated === 'boolean') {
        return calculated;
      }

      calculated = Math.round(calculated * 1000) / 1000;

      if (cellProperties.numericFormat) {
        return Numbro.default(Number(calculated)).format(
          cellProperties.numericFormat.pattern as any
        );
      } else {
        return calculated;
      }
    }
  );

  // The super class' method assigns the this.enabled property to true, which can be later used to check if plugin is already enabled.
  this._superClass.prototype.enablePlugin.call(this);
};

// @ts-ignore
Handsontable.plugins.registerPlugin('formulaPlugin', FormulaPlugin);
