import { Component, OnInit, forwardRef } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import { VideoTemplateData } from '.';
import txt from '!!raw-loader!./index.ts';
import { TemplateInput } from '../../../../common/interfaces/module.interface';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => VideoComponent)
    }
  ]
})
export class VideoComponent extends TemplateComponent implements OnInit {
  contentData: VideoTemplateData['template_params_json'];
  params = txt;
  prefix = 'video_1_';
  input: TemplateInput;
  videoProvider: 'Vimeo' | 'Youtube';

  embedUrl: string;

  getDescription() {
    return 'Text and video';
  }

  getName() {
    return 'Video';
  }

  getGroup(): string {
    return 'Generic';
  }

  hasInputs() {
    return true;
  }

  protected init() {
    this.contentData = this.data.data
      .template_params_json as VideoTemplateData['template_params_json'];
    const url = this.contentData.videoUrl;
    if (url?.indexOf('vimeo.com') > -1) {
      this.videoProvider = 'Vimeo';

      if (url.indexOf('player.vimeo.com') > -1) {
        this.embedUrl = url;
      } else {
        const [id] = url
          .split('.com/')
          .pop()
          .split('/');
        this.embedUrl = 'https://player.vimeo.com/video/' + id + '?portrait=0';
      }
    }
    if (!this.contentData.question_label) {
      this.contentData.question_label = 'Type response to video questions';
    }
    this.input = this.getInput(null, null, null, this.contentData.input_sufix);
  }
}
