import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { ModuleService } from '../../common/services/module.service';
import { Module } from '../../common/interfaces/module.interface';
import { map } from 'rxjs/operators';
import { MatSelectChange } from '@angular/material/select';
import { TemplateService } from '../../common/services/template.service';
import { Templates } from '../../module-viewer/riverside-step-template/templates';

@Component({
  selector: 'app-module-selector',
  templateUrl: './module-selector.component.html',
  styleUrls: ['./module-selector.component.scss']
})
export class ModuleSelectorComponent implements OnInit {
  filteredModules = [];
  modules = [];
  selectedModule: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public moduleService: ModuleService,
    private templateService: TemplateService
  ) {
    this.templateService.templates = Templates;
  }

  ngOnInit() {
    this.moduleService
      .getModules()
      .pipe(
        map(modules => ModuleService.sortModulesAlphabetically([...modules]))
      )
      .subscribe(modules => (this.modules = this.filteredModules = modules));

    this.selectedModule = parseInt(this.router.url.split('/').pop(), 10);
    this.navigateToModuleIfNeeded(this.selectedModule);
  }

  navigateToModuleIfNeeded(moduleId: number = null) {
    if (!Number(moduleId)) {
      this.moduleService.getDefaultModule().subscribe(module => {
        this.router.navigateByUrl(`/builder/${module.id}`);
        this.selectedModule = module.id;
      });
    }
  }

  onChange(event: MatSelectChange) {
    this.router.navigateByUrl(`/builder/${event.value}`);
  }

  filterMyOptions(query) {
    this.filteredModules = this.modules.filter(module =>
      module.name.toLowerCase().includes(query.toLowerCase())
    );
  }
}
