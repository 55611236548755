import { Component, Inject } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef
} from '@angular/material/snack-bar';
import { CustomScanBarData } from './snackbar';

@Component({
  selector: 'app-custom-snackbar',
  templateUrl: './custom-snackbar.component.html',
  styleUrls: ['./custom-snackbar.component.scss']
})
export class CustomSnackbarComponent {
  readonly iconList: Readonly<Record<CustomScanBarData['type'], string>> = {
    success: 'done',
    error: 'cancel',
    info: 'info',
    warning: 'warning'
  };

  constructor(
    private snackBarRef: MatSnackBarRef<CustomSnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: CustomScanBarData
  ) {}

  dismiss(): void {
    this.snackBarRef.dismiss();
  }
}
