import { Component, forwardRef } from '@angular/core';

import { TemplateComponent } from '../template-base.class';
import { PersonaBehaviorTemplateData } from '.';
import txt from '!!raw-loader!./index.ts';
import { take } from 'rxjs/operators';
import { TemplateInput } from '../../../../common/interfaces/module.interface';

@Component({
  selector: 'app-persona_behavior',
  templateUrl: './persona_behavior.component.html',
  styleUrls: ['./persona_behavior.component.scss'],
  preserveWhitespaces: true,
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => PersonaBehaviorTemplateComponent)
    }
  ]
})
export class PersonaBehaviorTemplateComponent extends TemplateComponent {
  params = txt;
  personaInputs: TemplateInput[] = [];
  selectionMatrixInputs: TemplateInput[] = [];

  contentData: PersonaBehaviorTemplateData['template_params_json'];

  getDescription() {
    return '';
  }

  getName() {
    return 'Persona Behavior';
  }

  getGroup(): string {
    return 'Generic';
  }

  protected init() {
    this.contentData = this.data.data
      .template_params_json as PersonaBehaviorTemplateData['template_params_json'];

    const suffix = this.contentData.input_sufix
      ? '_' + this.contentData.input_sufix
      : '';

    const personaInputsStartIndex = this.contentData.selection_matrix
      ? this.contentData.selection_matrix.length
      : 0;
    if (this.contentData.selection_matrix) {
      this.selectionMatrixInputs = this.contentData.selection_matrix.map(
        (_matrix, matrixInputIndex) =>
          this.getInput(`persona_behavior_${matrixInputIndex + 1}${suffix}`)
      );
    }

    this.buyerPersonasList$.pipe(take(1)).subscribe(buyerPersonas => {
      this.personaInputs = buyerPersonas.map(persona =>
        this.getInput(
          `persona_behavior_${personaInputsStartIndex + persona.index}${suffix}`
        )
      );
    });
  }
}
