import {
  Component,
  OnChanges,
  OnDestroy,
  ViewChild,
  Input,
  ComponentFactoryResolver
} from '@angular/core';
import { RTemplateDirective } from './riverside-step-template-host.directive';
import { TemplateContentData } from './templates/template-data.class';
import { TemplateComponent } from './templates/template-base.class';
import { UserService } from '../../common/services/user.service';
import { ModuleNavService } from '../../common/services/module-nav.service';
import { of, Subscription } from 'rxjs';
import { filter, mergeMap, pluck, switchMap, take, tap } from 'rxjs/operators';
import { OtherStep, Step } from '../../common/interfaces/module.interface';
import { Topic } from './module-progress-bar/module-progress-bar.component';
import { ExportService } from '../../common/services/export.service';
import { HttpResponse } from '@angular/common/http';
import { NavigationActions } from '../../common/types/module-viewer';
import { saveAs } from 'file-saver';
import { TemplateService } from '../../common/services/template.service';

@Component({
  selector: 'riverside-step-template',
  templateUrl: './riverside-step-template.component.html',
  styleUrls: ['./riverside-step-template.component.scss']
})
export class RiversideStepTemplateComponent implements OnChanges, OnDestroy {
  // templates = {
  //   ...Templates,
  //   block_repeater: BlockRepeaterComponent,
  //   block_repeater_wrapper: BlockRepeaterWrapperComponent,
  //   composable_template: ComposableTemplateComponent
  // };
  @Input() canModify: boolean;
  @Input() template: string;
  @Input() data: TemplateContentData;
  @Input() progressBarTopics: Array<Topic>;
  @Input() embedded = false;
  @ViewChild(RTemplateDirective, { static: true })
  templateHost: RTemplateDirective;

  templateInstance: TemplateComponent;
  disabled: boolean;
  action: NavigationActions;
  isStepLastInSection: boolean;
  isStepLastInModule: boolean;
  showFeedback = false;
  isHiddenProgressBar = false;
  hiddenSteps: number[] = [];
  isUnderConstruction: boolean;
  currentStep: Step;
  exportErrorMessage =
    'Export could not be completed due to a technical issue. Please try again later.';
  isExportError = false;

  isLoading = false;
  spinnerButtonId: number;

  moduleSubscription: Subscription;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private userService: UserService,
    private navService: ModuleNavService,
    public exportService: ExportService,
    private templateService: TemplateService
  ) {
    // this.templateService.templates = this.templates;
  }

  ngOnChanges() {
    let componentFactory;
    try {
      componentFactory = this.componentFactoryResolver.resolveComponentFactory(
        this.templateService.templates[this.template]
      );
    } catch (e) {
      console.warn('Failed to load template ', this.template);
    }

    this.isUnderConstruction = !componentFactory;

    const viewContainerRef = this.templateHost.viewContainerRef;
    viewContainerRef.clear();
    if (!this.isUnderConstruction) {
      this.disabled = this.data.data.disabled;
      this.initAction();

      const componentRef = viewContainerRef.createComponent(componentFactory);
      this.templateInstance = componentRef.instance as TemplateComponent;
      this.templateInstance.data = this.data;
      (this.progressBarTopics || []).forEach(topic => {
        topic.activeTopic = this.data.data.step_id;
        topic.isActive = topic.stepId.includes(topic.activeTopic);
        if (topic.hasOwnProperty('hiddenStep')) {
          this.hiddenSteps.push(topic.hiddenStep);
        }
      });
      this.isHiddenProgressBar = this.hiddenSteps.includes(
        this.data.data.step_id
      );
      this.templateInstance.isEmbedded = this.embedded;

      let steps;
      let streamFollowingStepIndex = 0;
      if (this.moduleSubscription) {
        this.moduleSubscription.unsubscribe();
      }
      this.moduleSubscription = this.navService.moduleDataReplay$
        .pipe(
          pluck('steps'),
          tap(streamSteps => {
            steps = streamSteps;
          }),
          mergeMap((streamSteps: Step[]) => {
            const currentStepIndex = streamSteps.findIndex(
              streamStep =>
                streamStep.id === this.templateInstance.data.data.step_id
            );
            streamFollowingStepIndex = currentStepIndex + 1;

            return of(streamSteps[currentStepIndex]);
          }),
          filter(Boolean)
        )
        .subscribe((step: Step) => {
          if (steps[streamFollowingStepIndex]) {
            this.isStepLastInModule = Boolean(
              step.is_partial || steps[streamFollowingStepIndex].is_partial
            );
            this.isStepLastInSection = Boolean(
              step.is_section_break ||
                steps[streamFollowingStepIndex].is_section_break ||
                this.isStepLastInModule
            );
          } else {
            this.isStepLastInSection = true;
          }
          this.showFeedback = Boolean(
            this.isStepLastInSection
              ? !step.is_request_feedback
              : step.is_request_feedback
          );
          this.currentStep = step;
          try {
            if (!Array.isArray(this.currentStep.other_steps)) {
              this.currentStep.other_steps = JSON.parse(
                this.currentStep.other_steps as string
              ) as OtherStep[];
            }
          } catch (e) {
            this.currentStep.other_steps = [];
          }
        });

      if (this.embedded) {
        this.templateInstance.disabled = true;
      }
    }
    this.isExportError = false;
  }

  ngOnDestroy(): void {
    if (this.moduleSubscription) {
      this.moduleSubscription.unsubscribe();
    }
  }

  exportData() {
    this.isLoading = true;
    this.exportService
      .exportData(
        this.currentStep.export_api,
        this.data.data.inputs,
        this.data.data.module_id,
        this.data.data.org_id,
        this.currentStep.pptx_steps
      )
      .subscribe(
        res => this.saveFile(res),
        () => this.throwErrorMessage()
      );
  }

  exportPdfData() {
    this.isLoading = true;
    this.spinnerButtonId = 0;
    this.navService.moduleDataReplay$
      .pipe(
        take(1),
        switchMap(module => {
          const steps = module.steps
            .filter(step => step.parent_step_id === this.data.data.step_id)
            .map(s => s.id.toString());

          return this.exportService.pdfExport(
            this.data.data.module_id,
            this.data.data.step_id,
            this.data.data.org_id,
            this.currentStep.pdf_export_filename,
            [...this.data.data.linked_ids, ...steps],
            [this.templateInstance.contentData?.api_url]
          );
        })
      )
      .subscribe(
        res => this.saveFile(res),
        () => this.throwErrorMessage()
      );
  }

  exportOtherStep(step: OtherStep, index: number) {
    step.module_id = step.module_id || this.navService.module.current;
    this.isLoading = true;
    this.spinnerButtonId = index + 1;
    this.exportService
      .multiPdfExport(
        step.module_id,
        step.step_id as string,
        this.data.data.org_id,
        step.button_title,
        '',
        [
          'campaign-strategy/campaigns',
          'value-propositions/solutions',
          'value-propositions/statements',
          'value-propositions/reasons',
          'value-propositions/competitors',
          'value-propositions/our-differences',
          'value-propositions/competitor-differences',
          'value-propositions/proof-points',
          'customer-success/customers'
        ]
      )
      .subscribe(
        res => this.saveFile(res),
        e => this.throwErrorMessage(e)
      );
  }

  triggerStepTransition(): void {
    if (this.templateInstance) {
      this.templateInstance.onStepTransition();
    }
  }

  protected initAction() {
    this.action = this.userService.me.permissions.riversideProvideFeedback
      ? 'approve'
      : 'mark_as_done';
  }

  private saveFile(response: HttpResponse<Blob>) {
    const contentDisposition = response.headers.get('content-disposition');
    saveAs(
      response.body,
      contentDisposition ? contentDisposition.split('=')[1] : undefined
    );
    this.isLoading = false;
    this.isExportError = false;
  }

  private throwErrorMessage(e?) {
    if (e) {
      console.error(e);
    }
    this.isExportError = true;
    this.isLoading = false;

    setTimeout(() => {
      this.isExportError = false;
    }, 5000);
  }
}
