import { Component, forwardRef } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import { ContentMapTemplateData } from '.';
import txt from '!!raw-loader!./index.ts';
import { BuyerPersona } from '../../../../common/interfaces/buyer-persona.interface';
import { Observable } from 'rxjs';

declare interface ContentMapInputData {
  hasData: boolean;
}

interface Content {
  title: string;
  description: string;
}

@Component({
  selector: 'content-map',
  templateUrl: 'content-map.component.html',
  styleUrls: ['./content-map.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => ContentMapComponent)
    }
  ]
})
export class ContentMapComponent extends TemplateComponent {
  params = txt;
  contentData: ContentMapTemplateData['template_params_json'];
  buyerPersonasList$: Observable<BuyerPersona[]>;

  type: string;
  raws: number[];
  contentMap = {
    '1_simple_map': [
      {
        title: 'where',
        description: 'Where will you get your content?'
      },
      {
        title: 'who',
        description: 'Who in your company can write the content?'
      },
      {
        title: 'what',
        description:
          'What will you ask of your customers in exchange for the content?'
      }
    ],
    '2_buyer_personas': [
      {
        title: 'not_in_market',
        description: 'Not in Market'
      },
      {
        title: 'recognizes_need',
        description: 'Recognizes Need'
      },
      {
        title: 'defines_need',
        description: 'Defines Need'
      },
      {
        title: 'evaluates_option',
        description: 'Evaluates Options'
      },
      {
        title: 'mitigates_risk',
        description: 'Mitigates Risk'
      },
      {
        title: 'selects_solution',
        description: 'Selects Solution'
      }
    ]
  };

  getDescription() {
    return 'Content map description';
  }

  getName() {
    return 'Content Map';
  }

  getGroup(): string {
    return 'Module';
  }

  get input() {
    return this.getInput('content_map');
  }

  typeIsSimple(): boolean {
    return this.type === '1_simple_map';
  }

  protected init() {
    // @ts-ignore - template_params_json.inputs property causes error with TypeScript 3.1
    this.contentData = this.data.data
      .template_params_json as ContentMapTemplateData['template_params_json'];
    this.type = this.contentData.typeOfMap_select;
    this.raws = Array(this.contentData.number_of_inputs)
      .fill(1)
      .map((x, i) => i + 1);
    if (!this.typeIsSimple()) {
      this.buyerPersonasList$ = this.buyerPersonasService.getBuyerPersonas();
    }
  }
}
