import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { ModuleService } from '../../../../common/services/module.service';
import { Task } from '../../../../common/interfaces/task.interface';
import { Organization } from '../../../../common/interfaces/module.interface';
import { ExportService } from '../../../../common/services/export.service';
import { SpreadsheetService } from '../../../../common/services/spreadsheet.service';
import { ModuleContentService } from '../../../../common/services/module-content.service';
import { ModuleNavService } from '../../../../common/services/module-nav.service';
import { saveAs } from 'file-saver';
import { HttpResponse } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-list-view-executive-dashboard',
  templateUrl: './list-view-executive-dashboard.component.html',
  styleUrls: ['./list-view-executive-dashboard.component.scss']
})
export class ListViewExecutiveDashboardComponent implements OnInit {
  @Input() organization: Organization;
  tasks: Array<Task> = [];
  orgId: number;
  loadingDashboard = false;

  constructor(
    private moduleService: ModuleService,
    private exportService: ExportService,
    private moduleContentService: ModuleContentService,
    private moduleNavService: ModuleNavService,
    private spreadsheetService: SpreadsheetService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.moduleNavService.currentOrganizationData$.subscribe(
      (org: Organization) => {
        this.orgId = org.id;
        this.getExecutiveDashboard(org);
      },
      err => console.warn
    );
  }

  getExecutiveDashboard(org: Organization) {
    this.loadingDashboard = true;
    this.moduleService.getExecutiveDashboard(org.id).subscribe(
      (response: any) => {
        this.tasks = response.data;
        this.loadingDashboard = false;
        this.cd.detectChanges();
      },
      err => console.warn,
      () => {
        this.loadingDashboard = false;
        this.cd.detectChanges();
      }
    );
  }

  exportFile(deliverable) {
    if (deliverable.export_type === 'KPI') {
      // Kpi report
      const date = new Date();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const kpisUrl =
        environment.kpisUrl +
        deliverable.export_data
          .replace('org_id', this.orgId)
          .replace('year', year)
          .replace('/month', `/${month}`);
      document.cookie = `rras_export_org=${this.orgId}; expires=Sun, 1 Jan 2023 00:00:00 UTC; domain=.seagage.com ;path=/`;
      window.open(kpisUrl, '_blank').focus();
    } else if (deliverable.export_type === 'redirect') {
      // Redirect to module
      let url = !isNaN(deliverable.export_data)
        ? `/#/org/${this.orgId}/module/${deliverable.module_id}/step/${deliverable.export_data}`
        : deliverable.export_data;
      url += deliverable.export_data.includes('sales-training')
        ? this.orgId
        : '';
      window.open(url, '_blank').focus();
    } else {
      let deliverableData = null;
      this.moduleContentService
        .loadStepData(
          deliverable.module_id,
          deliverable.export_data,
          this.orgId
        )
        .subscribe(
          (response: any) => {
            deliverableData = response.body;
            if (deliverable.export_type === 'pptx') {
              this.exportPPTX(deliverableData);
            } else if (deliverable.export_type === 'xls') {
              return this.exportXls(
                deliverableData.content.inputs,
                deliverableData.template_params_json.apiResource
              );
            } else if (deliverable.export_type === 's3') {
              return this.exportFromS3(deliverableData.content.inputs);
            }
          },
          err => console.warn
        );
    }
  }

  exportPPTX(deliverableData) {
    this.exportService
      .exportData(
        deliverableData.export_api,
        deliverableData.content.inputs,
        deliverableData.content.module_id,
        deliverableData.content.org_id,
        deliverableData.content.pptx_steps
      )
      .subscribe(
        res => this.saveFile(res),
        error => this.throwErrorMessage(error)
      );
  }

  exportXls(input, apiResource) {
    let inputData;
    if (Object.keys(input)) {
      inputData = Object.keys(input)[0];
      window.location.href = this.spreadsheetService.exportXlsUrl(
        input[inputData],
        apiResource
      );
    }
  }

  exportFromS3(input) {
    let inputData;
    if (Object.keys(input)) {
      inputData = input[Object.keys(input)[0]];
      let inputContentData;
      if ((inputContentData = JSON.parse(inputData.content))) {
        window.location.href = inputContentData.url;
      }
    }
  }

  private saveFile(response: HttpResponse<Blob>) {
    const contentDisposition = response.headers.get('content-disposition');
    saveAs(
      response.body,
      contentDisposition ? contentDisposition.split('=')[1] : undefined
    );
  }

  private throwErrorMessage(e?) {
    console.log(e);
    if (e) {
      console.error(e);
    }
  }

  trackByFn(index, entity) {
    return entity.id;
  }
}
