// import './index.ng1';

/*
 * Entry point of the application.
 * Only platform bootstrapping code should be here.
 * For app-specific initialization, use `app/app.component.ts`.
 */

import { enableProdMode, NgModuleRef } from '@angular/core';
import { ModuleViewerModule } from './app/module-viewer/module-viewer.module';
import { environment } from './environments/environment';
import './globals';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

window.CONSTANTS = environment;

if (environment.production) {
  enableProdMode();
}

const bootstrap = () =>
  platformBrowserDynamic().bootstrapModule(ModuleViewerModule);

const browserFingerprint = navigator.userAgent.match(
  /Chrom(e|ium)\/([0-9]+)\./
);

if (browserFingerprint && parseInt(browserFingerprint[2], 10) >= 91) {
  document.getElementsByTagName('body')[0].classList.add('chrome91-only');
}

// if (environment.hmr) {
//   hmrBootstrap(module, bootstrap);
// } else {
bootstrap().catch(err => console.error(err));
// }
