import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output
} from '@angular/core';
import { CustomCurrencyPipe } from '../pipes/custom-currency.pipe';

@Directive({
  selector: '[appCurrencyMask], [currencyValue]'
})
export class CurrencyMaskDirective {
  @Input('currencyValue')
  set currencyValue(value: number | string) {
    if (value !== null && Number(value) !== this.value) {
      const valueString = this.currencyPipe.transform(value);
      this.value = Number(value);
      this.onValueChange(valueString);
    } else if (value === null) {
      this.value = null;
      this.onValueChange('');
    }
  }
  @Output() currencyValueChange: EventEmitter<number> = new EventEmitter<
    number
  >();
  @Output() currencyFocusout: EventEmitter<number> = new EventEmitter<number>();

  private value: number = null;
  private displayValue: string = null;
  private selectionPosition: [number, number] = [0, 0];

  constructor(
    private el: ElementRef<HTMLInputElement>,
    private currencyPipe: CustomCurrencyPipe,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  private static getPeriodsNumber(value: string, endPosition: number): number {
    return (value.slice(0, endPosition).match(/,/g) || []).length;
  }

  private static isCurrencySignAppear(
    valueBefore: string,
    valueAfter: string
  ): boolean {
    return valueBefore[0] !== '$' && valueAfter[0] === '$';
  }

  @HostListener('focusout', ['$event.target'])
  onFocusout(): void {
    this.currencyFocusout.emit(this.value);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event): void {
    const e: KeyboardEvent = event;
    if (
      [46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)
    ) {
      return;
    }
    // Ensure that it is a number and stop the keypress
    if (
      (e.shiftKey ||
        e.keyCode < 48 ||
        (e.keyCode > 57 && e.keyCode !== 109 && e.keyCode !== 189)) &&
      (e.keyCode < 96 ||
        (e.keyCode > 105 && e.keyCode !== 109 && e.keyCode !== 189))
    ) {
      e.preventDefault();
    }
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string): void {
    this.selectionPosition = [
      this.el.nativeElement.selectionStart,
      this.el.nativeElement.selectionEnd
    ];
    const periodBefore = CurrencyMaskDirective.getPeriodsNumber(
      value,
      this.selectionPosition[0]
    );
    const valueBefore = this.el.nativeElement.value;
    this.onValueChange(value);
    const periodAfter = CurrencyMaskDirective.getPeriodsNumber(
      this.displayValue,
      this.selectionPosition[0]
    );
    const periodDifference = periodAfter - periodBefore;
    this.el.nativeElement.setSelectionRange(
      this.selectionPosition[0] + periodDifference,
      this.selectionPosition[1] + periodDifference
    );
    if (
      CurrencyMaskDirective.isCurrencySignAppear(
        valueBefore,
        this.el.nativeElement.value
      )
    ) {
      this.el.nativeElement.setSelectionRange(
        this.el.nativeElement.selectionStart + 1,
        this.selectionPosition[1] + 1
      );
    }
  }

  private onValueChange(newValue: string): void {
    if (newValue !== this.displayValue) {
      const currencyValue = this.currencyPipe.parse(newValue);
      this.el.nativeElement.value = this.currencyPipe.inputTransform(newValue);
      this.displayValue = this.el.nativeElement.value;
      if (this.value !== currencyValue) {
        this.updateValue(currencyValue);
      }
    }
  }

  private updateValue(value: number): void {
    this.value = value;
    this.currencyValueChange.emit(value);
    this.changeDetectorRef.detectChanges();
  }
}
