import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SprintStatus } from '../../../common/interfaces/module.interface';
import { isEmptyValue } from '../../../common/utils/helpers';

export const SPRINT_LEVELS: { label: string; value: number }[] = [
  { value: -100, label: 'Poor' },
  { value: -90, label: 'Poor' },
  { value: 0, label: 'Poor' },
  { value: 40, label: 'Fair' },
  { value: 65, label: 'Good' },
  { value: 80, label: 'Very Good' },
  { value: 90, label: 'Exceptional' },
  { value: 100, label: 'Exceptional' }
];

export const PROGRESS_BAR_INFO = [
  { left: 0, value: -100 },
  { left: 2, value: -90 },
  { left: 30, value: 0 },
  { left: 45, value: 40 },
  { left: 60, value: 65 },
  { left: 80, value: 80 },
  { left: 98, value: 90 },
  { left: 100, value: 100 }
];

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss']
})
export class DashboardHeaderComponent implements OnChanges {
  @Input() sprintStatus: SprintStatus;

  rrasTotalScore = '-';
  progressBarWidth: number;
  nextStatusLevel = '-';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.sprintStatus) {
      this.prepareSprintInfo();
      if (!isEmptyValue(this.sprintStatus?.rras_score)) {
        this.progressBarWidth = this.getProgressBarWidth();
      }
    }
  }

  private prepareSprintInfo(): void {
    this.nextStatusLevel = (
      isEmptyValue(this.sprintStatus?.rras_score)
        ? null
        : SPRINT_LEVELS.find(
            level =>
              level.value > this.sprintStatus.rras_score && level.value >= 40
          ) || SPRINT_LEVELS[SPRINT_LEVELS.length - 1]
    )?.label || '-';
    this.rrasTotalScore = this.sprintStatus?.rras_score?.toString() ?? '-';
  }

  private getProgressBarWidth(): number {
    const rightBoardIndex = PROGRESS_BAR_INFO.findIndex(
      step => step.value >= this.sprintStatus.rras_score
    );

    const rightBoard = PROGRESS_BAR_INFO[rightBoardIndex] || null;
    const leftBoard = PROGRESS_BAR_INFO[rightBoardIndex - 1] || null;

    return rightBoard && leftBoard
      ? leftBoard.left +
          ((this.sprintStatus.rras_score - leftBoard.value) /
            (rightBoard.value - leftBoard.value)) *
            (rightBoard.left - leftBoard.left)
      : rightBoard?.left || leftBoard?.left;
  }
}
