import { Component, forwardRef } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import txt from '!!raw-loader!./index.ts';
import { Observable, of } from 'rxjs';
import { TemplateContentData } from '../template-data.class';
import { map, switchMap, tap } from 'rxjs/operators';
import ModuleContent from '../../../../common/interfaces/module-content.model';
import { BlockData, BlockRepeaterContentInput } from '../block-repeater';
import { BlockRepeaterResultTemplateData } from './index';
import { Module } from '../../../../common/interfaces/module.interface';
import { parse } from 'flatted';
import { updateBlockRepeaterFormat } from '../block-repeater/helpers';

@Component({
  selector: 'app-block-repeater-result',
  templateUrl: './block-repeater-result.component.html',
  styleUrls: ['./block-repeater-result.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => BlockRepeaterResultComponent)
    }
  ]
})
export class BlockRepeaterResultComponent extends TemplateComponent {
  params = txt;
  contentData: BlockRepeaterResultTemplateData['template_params_json'];

  hasError = false;
  blockRepeaterData: BlockData;
  blockRepeaterResultContent$: Observable<TemplateContentData[]>;

  private moduleID: number;
  private moduleStepID: number;
  private moduleOptions: { [key: string]: string };
  private blockRepeaterModule: Module;

  init() {
    this.contentData = this.data.data
      .template_params_json as BlockRepeaterResultTemplateData['template_params_json'];
    this.prepareResults();
  }

  getDescription() {
    return 'Show a summary / result of block repeater from another module';
  }

  getName() {
    return 'Block Repeater Result';
  }

  getGroup(): string {
    return 'Generic';
  }

  hasInputs() {
    return false;
  }

  private prepareResults(): void {
    this.moduleID = Number(this.contentData.module);
    this.moduleStepID = this.contentData.step_id
      ? this.contentData.step_id
      : undefined;
    this.moduleOptions = (this.contentData.options || []).reduce(
      (accumulator, option) => ({ ...accumulator, [option.key]: option.value }),
      {}
    );
    const preparedSteps: ModuleContent[] = this.data.data.options?.resultSteps
      ? parse(this.data.data.options?.resultSteps as string)
      : null;

    this.blockRepeaterResultContent$ = (preparedSteps
      ? of(preparedSteps)
      : this.navService.organization$.pipe(
          switchMap(orgId =>
            this.moduleService.getOrgModule(this.moduleID, orgId, true).pipe(
              switchMap(module => {
                this.blockRepeaterModule = module;
                const blockSteps = module.steps
                  .filter(
                    step => step.parent_step_id === Number(this.moduleStepID)
                  )
                  .map(step => step.id);

                return this.moduleContentService
                  .loadSteps(
                    this.moduleID,
                    orgId,
                    [this.moduleStepID, ...blockSteps],
                    true
                  )
                  .pipe(this.whileExists());
              })
            )
          )
        )
    )
      .pipe(
        map((moduleSteps: ModuleContent[]) =>
          this.contentData.filter_out_steps?.length
            ? moduleSteps.filter(moduleStep =>
                this.contentData.filter_out_steps.every(
                  item => moduleStep.step_id !== Number(item.step_id)
                )
              )
            : moduleSteps
        )
      )
      .pipe(
        map((moduleSteps: ModuleContent[]) => {
          const mainStep = moduleSteps.find(
            step => step.step_id === this.moduleStepID
          );
          const blockSteps = moduleSteps.filter(
            step => step.step_id !== this.moduleStepID
          );
          this.prepareBlockRepeaterData(mainStep, blockSteps);
          const moduleContent: ModuleContent[] = this.prepareTemplateToRender(
            this.blockRepeaterData,
            blockSteps
          );

          return this.getTemplateContent(moduleContent);
        }),
        tap(content => (this.hasError = !content))
      );
  }

  private getTabBlockRepeaterData(
    repeaterContent: BlockRepeaterContentInput,
    tabIndex: number
  ): BlockData {
    const updatedData = updateBlockRepeaterFormat(repeaterContent);
    const data = updatedData ? updatedData : null;

    return (data ? data?.blocks[tabIndex]?.data : null) || null;
  }

  private prepareTemplateToRender(
    steps: BlockData,
    moduleSteps: ModuleContent[]
  ): ModuleContent[] {
    const templatesToRender = [];
    if (steps) {
      Object.keys(steps).forEach(stepId => {
        const moduleToRender = moduleSteps.find(
          moduleData => moduleData.step_id === Number(stepId)
        );

        if (!moduleToRender) {
          return;
        }

        templatesToRender.push({
          ...moduleToRender,
          instance_index: Number(this.contentOptions.tabIndex) || 0,
          inputs:
            moduleToRender.template_component === 'file_uploader'
              ? moduleToRender.inputs
              : steps[stepId],
          disabled: true,
          can_modify: false
        });
      });

      return templatesToRender;
    }

    return null;
  }

  private getTemplateContent(
    moduleContent: ModuleContent[]
  ): TemplateContentData[] {
    return moduleContent?.map(
      content =>
        new TemplateContentData({
          data: content,
          me: this.me,
          canModify: content.can_modify
        })
    );
  }

  private prepareBlockRepeaterData(
    content: ModuleContent,
    blockSteps: ModuleContent[] = []
  ): void {
    Object.assign(content.options, this.moduleOptions);
    content.disabled = true;
    const paramsJson = content.template_params_json;
    // @ts-ignore
    const inputSufix = paramsJson.input_sufix;
    // @ts-ignore
    const inputKey = Object.keys(content.inputs).find(input =>
      input.includes(inputSufix)
    );
    const repeaterContent = content?.inputs[inputKey]
      ? JSON.parse(content.inputs[inputKey].content)
      : null;
    this.blockRepeaterData = this.getTabBlockRepeaterData(
      repeaterContent,
      Number(this.contentOptions.tabIndex) || 0
    );
    blockSteps.forEach(blockStep => {
      if (
        blockStep.template_component === 'spreadsheet' &&
        blockStep.step_id &&
        blockStep.parent_step_id === content.step_id &&
        !this.blockRepeaterData?.[blockStep.step_id]
      ) {
        this.blockRepeaterData = this.blockRepeaterData || {};
        this.blockRepeaterData[blockStep.step_id] = blockStep.inputs;
        Object.assign(blockStep.options, this.moduleOptions);
      }
    });
  }
}
