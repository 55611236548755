import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TemplateComponent } from '../riverside-step-template/templates/template-base.class';
import { BuyerPersona } from '../../common/interfaces/buyer-persona.interface';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { TemplateInput } from '../../common/interfaces/module.interface';

@Component({
  selector: 'app-selection-matrix',
  templateUrl: './selection-matrix.component.html',
  styleUrls: ['./selection-matrix.component.scss']
})
export class SelectionMatrixComponent implements OnInit {
  @Input() personas: BuyerPersona[];
  @Input() prefix: string;
  @Input() question: string;
  @Input() index: number;
  @Input() disabled: boolean;
  @Input() horizontal = false;
  @Input() options: Array<{ option: string }>;

  @Output() changed: EventEmitter<TemplateInput> = new EventEmitter();

  checkboxInputs: { [key: string]: string[] } = {};
  optionSortIndex: { [key: string]: number } = {};

  constructor(public template: TemplateComponent) {}

  ngOnInit(): void {
    const personaInput = this.template.getInput(
      `persona_behavior_${this.index + 1}_${this.prefix}`
    );

    try {
      this.checkboxInputs = JSON.parse(personaInput.content || '{}');
    } catch (e) {
      this.checkboxInputs = {};
    }
    this.optionSortIndex = this.getOptionOrder();
  }

  toggleSelection(
    personaIdx: number,
    option: string,
    checkBox: MatCheckbox,
    $event
  ) {
    if ($event.target.className.includes('mat-checkbox')) {
      return;
    }
    const input = this.template.getInput(
      `persona_behavior_${this.index + 1}_${this.prefix}`
    );
    checkBox.checked = !checkBox.checked;
    this.updateSelection(input, option, checkBox.checked, personaIdx);
  }

  updateTextInput(
    personaIdx: number,
    option: string,
    $event: MatCheckboxChange
  ) {
    const checked = $event.checked;
    const input = this.template.getInput(
      `persona_behavior_${this.index + 1}_${this.prefix}`
    );
    this.updateSelection(input, option, checked, personaIdx);
  }

  updateSelection(
    input: TemplateInput,
    option: string,
    checked: boolean,
    personaIdx: number
  ) {
    if (!this.checkboxInputs[personaIdx]) {
      this.checkboxInputs[personaIdx] = [];
    }
    if (checked) {
      this.checkboxInputs[personaIdx].push(option);
      this.checkboxInputs[personaIdx].sort(
        (selectionA, selectionB) =>
          this.optionSortIndex[selectionA] - this.optionSortIndex[selectionB]
      );
    } else {
      this.checkboxInputs[personaIdx] = this.checkboxInputs[personaIdx].filter(
        sel => sel !== option
      );
    }

    input.content = JSON.stringify(this.checkboxInputs);

    this.changed.emit(input);
  }

  private getOptionOrder(): { [key: string]: number } {
    return this.options.reduce(
      (accumulator: { [key: string]: number }, currentOption) => {
        if (!accumulator[currentOption.option]) {
          accumulator[currentOption.option] = Object.keys(accumulator).length;
        }

        return accumulator;
      },
      {}
    );
  }
}
