import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { VideoItem } from '../../../common/interfaces/module.interface';

@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.scss']
})
export class VideoModalComponent {
  video: VideoItem;

  constructor(
    private modal: MatDialogRef<VideoModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: VideoItem
  ) {
    this.video = data;
  }
}
