import { TemplateInput } from '../interfaces/module.interface';

export const isEmptyValue = (value: any): boolean =>
  value === null || value === undefined;

export const isEmptyObject = (value: any): boolean =>
  !Object.keys(value).length;

export const purifyNumberString = (value: string): string =>
  value
    .replace(/[^\d.-]/g, '')
    .replace('.', 'x')
    .replace(/\./g, '')
    .replace('x', '.')
    .replace(/(?!^)-/g, '');

export const currencyToNumber = (value: string | number): number =>
  Number(purifyNumberString(String(value)));

export const getNestedValue = (
  obj: string | any,
  path: string | string[],
  options?: { [key: string]: string | TemplateInput },
  separator = '.'
) => {
  if (isEmptyValue(path)) {
    return obj;
  }
  let object = obj;
  try {
    object = typeof object === 'string' ? JSON.parse(object || null) : object;
  } catch (e) {
    object = undefined;
  }

  const keyReplacer = (match, key) =>
    String(isEmptyValue(options?.[key]) ? key : options[key]);
  const indexerReplacer = (match, index) => `.${index}`;
  const parseString = (value: string): string =>
    value
      .replace(/{(\w+)}/g, keyReplacer)
      .replace(/\[([\w()]+)\]/g, indexerReplacer)
      .replace(/^\./, '');
  const properties = Array.isArray(path)
    ? path.map(item => parseString(item))
    : parseString(path).split(separator);
  let isMultipleEntryTrigger = false;

  let nestedValue = properties.reduce((prev, curr, paramIndex) => {
    if (!prev || isMultipleEntryTrigger) {
      return prev;
    }
    const multipleEntry = curr.match(/(\(\w+\))/);
    if (multipleEntry?.length) {
      isMultipleEntryTrigger = true;
      const listString = multipleEntry.input;
      const remainingKeys =
        properties.length === paramIndex + 1
          ? null
          : properties.slice(paramIndex + 1);
      if (listString === '(index)') {
        return prev?.map(item =>
          getNestedValue(item, remainingKeys, options, separator)
        );
      }

      return null;
    }
    let nextValue = prev[curr];

    if (paramIndex !== properties.length - 1 && typeof nextValue === 'string') {
      try {
        nextValue = JSON.parse(nextValue || null) ?? nextValue;
      } catch (e) {}
    }

    return nextValue;
  }, object);
  try {
    nestedValue =
      typeof nestedValue === 'string'
        ? JSON.parse(nestedValue || null)
        : nestedValue;
  } catch (e) {}

  return nestedValue;
};

export const getNumberArrayFromRange = (
  sv: number | string,
  ev: number | string
): number[] => {
  const startValue = Number(sv);
  const endValue = Number(ev);
  const numberArray: number[] = [];
  for (let i = startValue; i <= endValue; i++) {
    numberArray.push(i);
  }

  return numberArray;
};
