import { Component, forwardRef } from '@angular/core';
import txt from '!!raw-loader!./index.ts';
import { TemplateComponent } from '../template-base.class';
import {
  ExpandDataInputInfo,
  FinalFeedbackTemplateParams,
  PersonaFeedbackInfo,
  PersonaIceInfo,
  personaInputNames
} from './index';
import { ExpandedData } from '../expand-data';
import { TemplateInputCap } from '../../../../common/interfaces/module.interface';

@Component({
  selector: 'app-final-feedback',
  templateUrl: './final-feedback.component.html',
  styleUrls: ['./final-feedback.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => FinalFeedbackComponent)
    }
  ]
})
export class FinalFeedbackComponent extends TemplateComponent {
  contentData: FinalFeedbackTemplateParams;
  params = txt;

  inputsContent: { [inputName: string]: ExpandedData };
  previousData: PersonaFeedbackInfo[];

  readonly personaInputInfo = personaInputNames;

  getDescription() {
    return '';
  }

  getName() {
    return 'Final Feedback';
  }

  getGroup(): string {
    return 'Outputs';
  }

  init() {
    super.init();
    this.contentData = this.data.data
      .template_params_json as FinalFeedbackTemplateParams;
    this.preparePreviousData();
  }

  updateIce(
    iceData: TemplateInputCap,
    inputInfo: ExpandDataInputInfo,
    personaIndex: number
  ): void {
    const input = this.inputs[inputInfo.inputName];
    const inputContent = this.inputsContent[inputInfo.inputName];
    if (input && inputContent?.[inputInfo.titleName]?.[personaIndex]) {
      const newIceData = this.removeObservablesFromInput({ ...iceData });
      inputContent[inputInfo.titleName][personaIndex].ice = newIceData;
      const newContentString = JSON.stringify(
        this.removeCircularStructure({ ...inputContent })
      );
      if (input.content !== newContentString) {
        input.content = newContentString;
        this.contentChanged(input);
      }
    }
  }

  private preparePreviousData(): void {
    this.buyerPersonasList$.subscribe(personas => {
      this.inputsContent = Object.keys(this.inputs).reduce((accum, key) => {
        accum[key] = JSON.parse(this.inputs[key].content || null);

        return accum;
      }, {});
      this.previousData = personas.map((persona, index) => ({
        persona,
        inputIceInfo: Object.keys(personaInputNames).reduce(
          (accum: PersonaIceInfo, key: keyof PersonaIceInfo) => {
            const iceInfo = personaInputNames[key];
            const content = this.inputsContent[iceInfo.inputName];
            accum[key] = content?.[iceInfo.titleName]?.[index] || null;

            return accum;
          },
          {}
        )
      }));
    });
  }
}
