import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {
  BehaviorSubject,
  combineLatest,
  forkJoin,
  Observable,
  of,
  throwError
} from 'rxjs';
import { Module, Step, TemplateInput } from '../interfaces/module.interface';
import { environment } from '../../../environments/environment';
import {
  catchError,
  map,
  shareReplay,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import { ModuleService } from './module.service';
import { ModuleNavService } from './module-nav.service';
import { UserService } from './user.service';
import { ModuleContentService } from './module-content.service';
import { SpreadsheetService } from './spreadsheet.service';
import { BuyerPersonasService } from './buyer-personas.service';
import { IcpProfileService } from './icp-profile.service';
import { getStepSpreadsheetApiData } from '../utils/spreadsheet-helpers';
import { BuyerPersona } from '../interfaces/buyer-persona.interface';

export interface ExportApiOptionsResponse {
  [endpoint: string]: string;
}

declare interface Inputs {
  [key: string]: TemplateInput;
}

@Injectable({
  providedIn: 'root'
})
export class ExportService {
  currentBlockRepeaterIndex = new BehaviorSubject<number>(0);
  private exportOptionsRequest$: Observable<ExportApiOptionsResponse>;
  private budgetStrategySpreedSheets = [
    {
      inputKey: 'spreadsheet_1_budget_strategy',
      xls: 'sales-budget/annual-sales-labor.xlsx'
    },
    {
      inputKey: 'spreadsheet_1_other_expenses',
      xls: 'sales-budget/other-expenses.xlsx'
    }
  ];

  constructor(
    private httpClient: HttpClient,
    private moduleService: ModuleService,
    private moduleNavService: ModuleNavService,
    private userService: UserService,
    private moduleContentService: ModuleContentService,
    private spreadsheetService: SpreadsheetService,
    private buyerPersonasService: BuyerPersonasService,
    private icpProfileService: IcpProfileService
  ) {
    this.exportOptionsRequest$ = this.httpClient
      .get<ExportApiOptionsResponse>(`${environment.exportApiRoot}/node/list`)
      .pipe(shareReplay(1));
  }

  getExportOptions(): Observable<ExportApiOptionsResponse> {
    return this.exportOptionsRequest$;
  }

  exportData(
    exportApiOptionsResponseValue: string,
    inputs: Inputs,
    moduleId: number,
    orgId: number,
    pptxSteps?: string
  ): Observable<HttpResponse<Blob>> {
    const data = {} as any;

    return this.moduleNavService.currentOrganizationData$.pipe(
      take(1),
      tap(org => {
        Object.values(inputs).forEach(input => {
          try {
            data[input.element_key] = JSON.parse(input.content);
          } catch (e) {
            const inputData = input.getValue ? input.getValue() : input.content;
            if (inputData && inputData.length) {
              data[input.element_key] = inputData
                .replace(/<p><\/p>/g, '\n')
                .split(environment.apiRoot + '/api/resources?s3_key=')
                .join('https://riverside-seagage.s3-us-west-2.amazonaws.com/');
            }
          }
        });
        data.organization = { ...org };
      }),
      switchMap(res => {
        if (exportApiOptionsResponseValue === 'node/lead_management/pptx') {
          data.stepsString = pptxSteps;

          return this.moduleContentService.loadStepsData(
            moduleId,
            pptxSteps,
            orgId
          );
        } else {
          return of(res);
        }
      }),
      switchMap(res => {
        if (
          exportApiOptionsResponseValue === 'php/export/excel/budgetStrategy'
        ) {
          return this.getBudgetStrategyData(inputs).pipe(
            tap(spreadsheet => (data.spreadsheet = spreadsheet))
          );
        } else {
          return of(res);
        }
      }),
      switchMap(res => {
        data.stepsData = res.body;

        return this.httpClient.post(
          `${environment.exportApiRoot}/${exportApiOptionsResponseValue}`,
          data,
          { observe: 'response', responseType: 'blob' }
        );
      })
    );
  }

  pdfExport(
    moduleId: number,
    stepId: number,
    orgId: number,
    filename?: string,
    linkedSteps?: string[],
    apiUrl?: string[]
  ) {
    return this.getExportData(
      moduleId,
      `${stepId}`,
      orgId,
      [stepId, ...linkedSteps.map(el => +el)],
      filename,
      null,
      null,
      apiUrl
    ).pipe(
      switchMap(data =>
        this.httpClient.post(`${environment.exportApiRoot}/node/pdf`, data, {
          observe: 'response',
          responseType: 'blob'
        })
      )
    );
  }

  multiPdfExport(
    moduleId: number,
    stepId: string,
    orgId: number,
    buttonTitle = '',
    filename?: string,
    apiUrls?: string[]
  ) {
    let steps = stepId.split(',').map(step => +step);
    const isDirectPart = steps.some(el => isNaN(el));

    let directPart = null;

    if (isDirectPart) {
      const step = JSON.parse(stepId);
      directPart = step.directPart;
      steps = [step.stepId];
    }

    return forkJoin(steps.map(step => this.findChildrenSteps(step))).pipe(
      map(step => step.reduce((acc, curr) => [...acc, ...curr])),
      switchMap(childrenSteps => {
        const stepsString = [...steps, ...childrenSteps].join(',');

        return this.getExportData(
          moduleId,
          stepsString,
          orgId,
          steps,
          filename,
          directPart,
          buttonTitle,
          apiUrls
        ).pipe(
          switchMap(data =>
            this.httpClient.post(
              `${environment.exportApiRoot}/node/pdf/exportSteps`,
              data,
              {
                observe: 'response',
                responseType: 'blob'
              }
            )
          )
        );
      })
    );
  }

  private getBudgetStrategyData(inputs) {
    const sheetParams = this.budgetStrategySpreedSheets.map(e => {
      const { id, module_id, org_id } = inputs[e.inputKey];

      return { xls: e.xls, input: { id, module_id, org_id } };
    }) as [any];

    return combineLatest(
      sheetParams.map(sheetParam =>
        this.spreadsheetService
          .getSpreadsheet(
            (sheetParam.input as any) as TemplateInput,
            sheetParam.xls
          )
          .pipe(
            map(content => ({ content: content.data, xls: sheetParam.xls }))
          )
      )
    );
  }

  private findChildrenSteps(stepId: number): Observable<any> {
    return this.moduleNavService.moduleDataReplay$.pipe(
      take(1),
      map((module: Module) => {
        let children = module.steps
          .filter(step => step.parent_step_id === stepId)
          .map(step => step.id);

        let isSearching = true;
        const container = children;

        while (isSearching) {
          const newChildren = module.steps
            .filter(step => children.includes(step.parent_step_id))
            .map(step => step.id);

          if (!newChildren.length) {
            isSearching = false;
          } else {
            container.push(...newChildren);
            children = newChildren;
          }
        }

        return [...container];
      })
    );
  }

  private getExportData(
    moduleId: number,
    stepId: string,
    orgId: number,
    steps: number[],
    filename?: string,
    directPart?: string,
    buttonTitle?: string,
    apiUrls?: string[]
  ): Observable<any> {
    return combineLatest([
      this.moduleContentService.loadStepsData(
        moduleId,
        `${steps.join(',')},${stepId}`,
        orgId
      ),
      this.userService.getAccount(),
      this.getModuleResult(`${steps.join(',')},${stepId}`, orgId),
      this.moduleService.getOrgModule(moduleId, orgId),
      this.moduleNavService.currentOrganizationData$,
      this.buyerPersonasService.getBuyerPersonas(),
      this.icpProfileService.getIcpSegments(orgId),
      this.currentBlockRepeaterIndex
    ]).pipe(
      take(1),
      switchMap(res => {
        const spreadsheetFilter = (step: any) =>
          step?.template_component === 'spreadsheet';

        const stepsWithSpreadsheets: any = Object.values(res[0].body).filter(
          spreadsheetFilter
        );

        const otherModuleSteps: any = Object.values(res[2]).reduce(
          (acc: any, cur: any) => [...acc, ...cur.filter(spreadsheetFilter)],
          []
        );

        stepsWithSpreadsheets.push(...otherModuleSteps);
        const notDuplicate = {};

        if (stepsWithSpreadsheets.length) {
          return combineLatest(
            stepsWithSpreadsheets.map(step => {
              if (step.id === 9039 && !notDuplicate.hasOwnProperty(step.id)) {
                notDuplicate[step.id] = true;
                step.template_params_json.visibleRows = '8-9';
                step.template_params_json.visibleCols = '4';
              }

              return getStepSpreadsheetApiData(step, this.spreadsheetService);
            })
          ).pipe(
            map(xlsData => [
              ...res,
              xlsData.reduce((result: any, el: any) => [...result, ...el], [])
            ])
          );
        } else {
          return of(res);
        }
      }),
      switchMap(res => {
        return combineLatest(
          apiUrls.map(url => this.moduleService.getAPIData(orgId, url))
        ).pipe(map(apiDataList => [...res, ...apiDataList]));
      }),
      map(
        ([
          { body: stepData },
          userData,
          otherModuleSteps,
          moduleData,
          org,
          buyerPersonas,
          icpSegments,
          blockRepeaterIdx,
          spreadsheet,
          ...apiData
        ]) => ({
          stepData,
          userData,
          filename: `${org.name.toLowerCase()} ${this.getFileName(
            filename,
            stepData,
            moduleData
          )}`
            .split(' ')
            .join('-'),
          otherModuleSteps,
          moduleData,
          org,
          buyerPersonas: this.changePersonasPictureUrl(buyerPersonas),
          icpSegments,
          directPart,
          steps,
          buttonTitle,
          blockRepeaterIdx,
          spreadsheet,
          apiData: apiUrls.map((url, index) => ({
            apiUrl: url,
            apiData: apiData[index]
          }))
        })
      )
    );
  }

  private changePersonasPictureUrl(personas: BuyerPersona[]): BuyerPersona[] {
    if (!personas.map) {
      return personas;
    }

    return personas.map(persona => {
      if (persona.picture && persona.picture.includes('s3_key')) {
        const url = 'https://riverside-seagage.s3-us-west-2.amazonaws.com';
        const regExp = /(?<=s3_key=).*/g;
        const match = persona.picture.match(regExp)[0].split('/');
        persona.picture = `${url}/${match[0]}/${match[1]}`;
      }

      return persona;
    });
  }

  private getFileName(filename, stepData, moduleData): string {
    if (filename) {
      return filename;
    } else if (Object.keys(stepData).length > 1) {
      return moduleData.name;
    } else {
      return Object.keys(stepData)
        .map(key => stepData[key].description)
        .join('');
    }
  }

  private getModuleResult(stepsId: string, orgId: number): Observable<any> {
    const steps = stepsId.split(',');

    return this.moduleNavService.moduleDataReplay$.pipe(
      map((module: Module) =>
        module.steps.filter(
          step =>
            (step.template_component === 'module_result' &&
              steps.includes(`${step.id}` || `${step.parent_step_id}`)) ||
            step.template_component === 'block_repeater_result'
        )
      ),
      switchMap(res => (res.length ? of(res) : throwError(new Error()))),
      switchMap((data: Step[]) =>
        forkJoin(
          data.map(step =>
            this.moduleContentService
              .loadStepData(
                step.template_params_json.module,
                step.template_params_json.step_id,
                orgId
              )
              .pipe(catchError(() => of([])))
          )
        ).pipe(
          map(stepRes => stepRes.map(step => step.body).filter(step => step))
        )
      ),
      switchMap((data: Step[]) =>
        forkJoin(
          data.map(step =>
            this.moduleService.getOrgModule(step.module_id, orgId, true)
          )
        ).pipe(
          switchMap((module: Module[]) => {
            const children = this.findChildrenOfChildren(module, data) || [];
            if (children) {
              return forkJoin(
                children.map(step =>
                  this.moduleContentService.loadStepData(
                    step.module_id,
                    step.id,
                    orgId
                  )
                )
              ).pipe(
                map(childrenSteps => {
                  childrenSteps.forEach((step: any) => data.push(step.body));
                  const result = {};
                  data.forEach(step => {
                    const currentModule = module.find(
                      m => m.id === step.module_id
                    );
                    if (!result[currentModule.id]) {
                      result[currentModule.id] = [currentModule];
                    }
                    result[currentModule.id].push(step);
                  });

                  return result;
                })
              );
            } else {
              return of([]);
            }
          })
        )
      ),
      catchError(() => of([]))
    );
  }

  private findChildrenOfChildren(module, steps) {
    return steps
      .reduce(
        (acc, step) => {
          const foundModule = module.find(m => m.id === step.module_id);
          const children = foundModule.steps.filter(
            el => el.parent_step_id === step.id
          );
          if (children.length) {
            acc.push(children);
          }

          return acc;
        },
        [...steps]
      )
      .flat();
  }
}
