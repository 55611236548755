import { Component, forwardRef } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import txt from '!!raw-loader!./index.ts';
import {
  MatrixOption,
  MatrixElement,
  MatrixEntryTemplateParams,
  MatrixCustomCellProperties,
  MatrixLeftColumnCell
} from '.';
import { TemplateComponent } from '../template-base.class';
import { TemplateInput } from '../../../../common/interfaces/module.interface';
import { Validation } from '../../../../common/validator.class';
import { getNestedValue, isEmptyValue } from '../../../../common/utils/helpers';
import { updateMatrixEntryFormat } from './helpers';

@Component({
  selector: 'matrix-entry',
  templateUrl: 'matrix-entry.component.html',
  styleUrls: ['./matrix-entry.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => MatrixEntryComponent)
    }
  ]
})
export class MatrixEntryComponent extends TemplateComponent {
  params = txt;
  contentData: MatrixEntryTemplateParams;
  prefix = 'matrix_entry_1';

  items: MatrixElement[][] = [];
  footerRow: MatrixElement[] = [];
  options: MatrixOption[];
  radioGroups: {
    [key: string]: { selectedInRows: string[]; values: string[] };
  };
  buttonText: string;
  requiredRowCount: number;
  rowValueClassNames: string[] = [];
  allowReorderingItems = false;
  isMaxNumberOfRows = false;
  customCellProperties: MatrixCustomCellProperties[] = [];
  predefinedLeftColumn: MatrixLeftColumnCell[] = [];

  validators: Validation[];
  validationCellErrors: boolean[][] = [];

  private inputKey: string;

  private static getMatrixElementFromOption(
    option: MatrixOption,
    data: string | boolean | number = '',
    isFooter = false
  ): MatrixElement {
    return {
      data,
      isFooter,
      id: option.id,
      title: option.title,
      type: option.type_select,
      radio_group: option.radio_group,
      radio_value: option.radio_value,
      checkboxes: option.checkboxes?.map(o => ({ ...o, isChecked: false })),
      currency_input: option.type_select === '3_number' && option.currency_input
    };
  }

  get input(): TemplateInput {
    return this.inputs[this.inputKey];
  }

  getDescription(): string {
    return '';
  }

  getName(): string {
    return 'Matrix Entry';
  }

  getGroup(): string {
    return 'Generic';
  }

  init(): void {
    this.contentData = this.data.data
      .template_params_json as MatrixEntryTemplateParams;

    this.checkOptions();

    this.requiredRowCount = this.contentData.min_number_of_filled_items || 1;

    this.inputKey = this.contentData.input_sufix
      ? `${this.prefix}_${this.contentData.input_sufix}`
      : this.prefix;
    this.allowReorderingItems = this.contentData.allow_reordering_items;

    this.buttonText = this.contentData.add_item_action_label;
    this.prepareOptions();
    this.prepareDefaultData();
    this.prepareRadioGroups();
    this.prepareFooterRow();
    this.prepareCustomCellProperties();
    this.prepareLeftColumn();
    if (this.isEmbedded) {
      this.items = this.items.filter(item =>
        item.some(matrixElement => !!matrixElement.data)
      );
    }

    const ApiMatrixDropdownData = {};
    const ApiDataDropdowns = this.contentData.options.filter(
      el => !!el.use_api_values
    );

    ApiDataDropdowns.forEach(el => {
      if (!ApiMatrixDropdownData[el.matrix_api_url]) {
        this.navService.organization$
          .pipe(
            take(1),
            switchMap(orgId =>
              this.moduleService.getAPIData(orgId, el.matrix_api_url)
            )
          )
          .subscribe(
            (data: []) => {
              el.matrix_dropdown = data.map(values => ({
                value: values[this.textContent(el.api_param)]
              }));
              ApiMatrixDropdownData[el.matrix_api_url] = el.matrix_dropdown;
            },
            () => {
              ApiMatrixDropdownData[el.matrix_api_url] = el.matrix_dropdown.map(
                dropdown_value => dropdown_value.value
              );
            }
          );
      } else {
        el.matrix_dropdown = ApiMatrixDropdownData[el.matrix_api_url].map(
          values => values[el.api_param]
        );
      }
    });
    this.checkForMaxNumberOfRows();
    this.prepareValueClassNames();
    this.checkForOneSelectedCheckbox();
    this.validators = this.getValidators();
    this.updateInputs();
  }

  validateInput(inp: TemplateInput, validators: Validation[] = []): boolean {
    this.prepareValidationCellErrors();

    return super.validateInput(inp, validators);
  }

  validate() {
    return of(this.validateInput(this.input, this.validators));
  }

  addStep(): void {
    const item: MatrixElement[] = this.options.map(option =>
      MatrixEntryComponent.getMatrixElementFromOption(option)
    );
    this.items.push(item);
    this.checkForMaxNumberOfRows();
    this.prepareRadioGroups();
    this.updateInputs();
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);
    this.updateInputs();
  }

  replaceItem(previousIndex, direction): void {
    if (
      previousIndex + direction >= 0 &&
      previousIndex + direction < this.items.length
    ) {
      moveItemInArray(this.items, previousIndex, previousIndex + direction);
      this.updateInputs();
    }
  }

  removeStep(index: number): void {
    this.confirmationService.removeDialog({ text: 'item' }).subscribe(() => {
      this.items.splice(index, 1);
      if (this.items.length && this.items[0].length) {
        this.items[0][0].hasUpdates = true;
      }
      this.checkForMaxNumberOfRows();
      this.prepareRadioGroups();
      this.updateInputs();
    });
  }

  updateInputs(): void {
    this.onInputChange();
    const inputContent: MatrixElement[][] = updateMatrixEntryFormat(this.items);

    if (this.footerRow?.length) {
      inputContent.push(this.footerRow);
    }
    if (this.input.content !== JSON.stringify(inputContent)) {
      this.input.content = JSON.stringify(inputContent);
      this.contentChanged(this.input, this.validators);
    }
  }

  handleCheckboxChange(field: MatrixElement, rowId: number): void {
    if (this.contentData.one_selected_checkbox_per_row) {
      this.items[rowId]
        .filter(item => item.type === '4_checkbox' && item.id !== field.id)
        .forEach(item => (item.data = false));
    }
    this.updateInputs();
  }

  handleSelectionChange(field: MatrixElement): void {
    const triggerSortMatrix = !!this.options[Number(field.id) - 1]
      .sort_rows_by_dropdown_value;
    if (triggerSortMatrix) {
      this.sortMatrixByDropdownValues(Number(field.id));
    }
    this.updateInputs();
  }

  dateChangeHandler(field: MatrixElement, dateValue: string): void {
    field.data = dateValue;
    this.updateInputs();
  }

  setRadioGroupValue(
    radioGroup: string,
    rowId: number,
    field: MatrixElement
  ): void {
    this.items[rowId]
      .filter(matrixElement => matrixElement.radio_group === radioGroup)
      .forEach(
        matrixElement =>
          (matrixElement.data = matrixElement.radio_value === field.radio_value)
      );
    this.radioGroups[radioGroup].selectedInRows[rowId] = field.radio_value;
  }

  onInputChange(): void {
    this.prepareFooterRow();
    this.prepareValueClassNames();
  }

  onInputValueChange(field, value): void {
    field.data = value;
    this.updateInputs();
  }

  editInputInCell($event): void {
    if (!this.disabled) {
      if ($event.target.nodeName === 'DIV' || $event.target.nodeName === 'TD') {
        $event.target.children[0]?.focus();
      }
    }
  }

  updateItemsToLatestConfiguration(
    items: MatrixElement[][]
  ): MatrixElement[][] {
    const getUpdatedCellConfig = (
      cell: MatrixElement,
      item: MatrixOption
    ): MatrixElement => ({
      ...cell,
      id: item.id,
      title: item.title,
      type: item.type_select,
      radio_group: item.radio_group,
      radio_value: item.radio_value,
      checkboxes:
        item.type_select === '9_checkboxes' && cell.type === '9_checkboxes'
          ? item.checkboxes?.map(i => {
              const oldCheckbox = cell.checkboxes?.find(c => c.id === i.id);

              return {
                ...i,
                userText: oldCheckbox?.userText,
                isChecked: oldCheckbox?.isChecked
              };
            })
          : item.checkboxes?.map(o => ({ ...o, isChecked: false })),
      currency_input: item.type_select === '3_number' && item.currency_input
    });

    return items.reduce((accum: MatrixElement[][], row) => {
      row.sort((cellA, cellB) => Number(cellA.id) - Number(cellB.id));
      const updatedRow: MatrixElement[] = this.options.map((column, index) =>
        row[index]
          ? getUpdatedCellConfig(row[index], column)
          : MatrixEntryComponent.getMatrixElementFromOption(column)
      );
      accum.push(updatedRow);

      return accum;
    }, []);
  }

  trackByFn(index, item) {
    return index;
  }

  private prepareDefaultData(): void {
    const content: MatrixElement[][] = this.input.content
      ? JSON.parse(this.input.content)
      : null;
    const isEmpty =
      isEmptyValue(content) ||
      !content?.some(row =>
        row.some(
          i =>
            !isEmptyValue(i.data) ||
            (i.data === 'string' && i.data.length) ||
            !!i.checkboxes?.length
        )
      );
    if (isEmpty) {
      if (
        this.contentData.api_as_default_data_source &&
        this.contentData.api_endpoint &&
        this.contentData.api_fields_to_use?.length
      ) {
        this.prepareAPIDataAsDefault();
      } else if (
        this.contentData.default_data &&
        this.contentData.default_data.length
      ) {
        this.items = this.contentData.default_data
          .map(itemDefaultData =>
            this.options.map((option, index) => {
              const cellDefaultData = itemDefaultData?.matrix_element?.[index];
              let cellValue: string;
              if (cellDefaultData?.use_value_from_input) {
                const linkedInput = this.inputs[
                  cellDefaultData.linked_input_name
                ];
                cellValue = getNestedValue(
                  linkedInput.content,
                  cellDefaultData.value_path
                );
              }
              cellValue =
                cellValue ||
                this.contentPipe.transform(cellDefaultData?.value) ||
                '';

              return MatrixEntryComponent.getMatrixElementFromOption(
                option,
                this.textContent(cellValue)
              );
            })
          )
          .filter(item => item.some(element => !!element.data));
      } else if (!!this.contentData.copy_matrix_entry_data_as_source) {
        this.prepareSourceDataAsDefault();
      }
    } else {
      this.items = JSON.parse(this.input.content)
        .filter(matrixRow =>
          matrixRow.some(matrixElement => !matrixElement.isFooter)
        )
        .map((matrixRow: MatrixElement[]) =>
          matrixRow.map((matrixItem: MatrixElement) => {
            if (
              matrixItem.type === '1_text' ||
              matrixItem.type === '2_textarea'
            ) {
              matrixItem.data = this.textContent(matrixItem.data?.toString());
            }

            return matrixItem;
          })
        );
      const updatedItems = this.updateItemsToLatestConfiguration(this.items);
      if (JSON.stringify(updatedItems) !== JSON.stringify(this.items)) {
        this.items = updatedItems;
        this.updateInputs();
      }
    }

    const hasUpdates =
      this.items.length && this.items[0].length && this.items[0][0].hasUpdates;

    if (
      !this.contentData.api_as_default_data_source &&
      !hasUpdates &&
      this.items.length < this.contentData.number_of_pregenerated_items
    ) {
      for (
        let i = this.items.length;
        i < this.contentData.number_of_pregenerated_items;
        i++
      ) {
        this.items.push(
          this.options.map(option =>
            MatrixEntryComponent.getMatrixElementFromOption(option)
          )
        );
      }
    }
    if (
      !this.contentData.api_as_default_data_source &&
      (content == null ||
        content
          .flat()
          .every(value => typeof value.data === 'string' && !value.data.length))
    ) {
      this.updateInputs();
    }
  }

  private prepareAPIDataAsDefault(): void {
    this.navService.organization$
      .pipe(
        take(1),
        switchMap(orgId =>
          this.moduleService.getAPIData(orgId, this.contentData.api_endpoint)
        )
      )
      .subscribe(response => {
        if (response && Array.isArray(response)) {
          this.items = response.map(item =>
            this.options.map((option, columnId) =>
              MatrixEntryComponent.getMatrixElementFromOption(
                option,
                this.textContent(
                  item[
                    this.contentData.api_fields_to_use[columnId]?.field_name
                  ] || ''
                )
              )
            )
          );
          this.updateInputs();
        }
      });
  }

  private prepareSourceDataAsDefault(): void {
    let inputContent = JSON.parse(
      this.inputs[this.contentData.source_input_name]?.content || null
    );
    if (!!inputContent && this.contentData?.source_content_path?.length) {
      inputContent = getNestedValue(
        inputContent,
        this.contentData.source_content_path,
        this.data.data.options
      );
    }
    const sourceItems: MatrixElement[][] = inputContent;
    if (sourceItems?.length) {
      this.items = sourceItems
        .filter(matrixRow =>
          matrixRow.some(matrixElement => !matrixElement.isFooter)
        )
        .map((matrixRow: MatrixElement[]) =>
          matrixRow.map((matrixItem: MatrixElement) => {
            if (
              matrixItem.type === '1_text' ||
              matrixItem.type === '2_textarea'
            ) {
              matrixItem.data = this.textContent(matrixItem.data?.toString());
            }

            return matrixItem;
          })
        );
      const updatedItems = this.updateItemsToLatestConfiguration(this.items);
      if (JSON.stringify(updatedItems) !== JSON.stringify(this.items)) {
        this.items = updatedItems;
        this.updateInputs();
      }
    }
  }

  private prepareFooterRow(): void {
    const getFooterElementValue = (
      optionIndex: number
    ): string | boolean | number => {
      const option: MatrixOption = this.options[optionIndex];
      if (option.is_total && option.type_select === '3_number') {
        const values = this.items.map((row, rowIndex) => {
          const isSubtract = this.customCellProperties[rowIndex]
            ?.matrix_element?.[optionIndex]?.subtract_for_total_row;
          let value = Number(
            row.find(
              matrixElement => Number(matrixElement.id) === Number(option.id)
            )?.data || 0
          );
          value = isSubtract ? -value : value;

          return value;
        });

        return values.reduce((sum, value) => sum + value, 0);
      }

      if (option.is_total && option.type_select === '4_checkbox') {
        const values = this.items.map(matrixRow =>
          Number(
            matrixRow.find(
              matrixElement => Number(matrixElement.id) === Number(option.id)
            )?.data || 0
          )
        );

        return values.reduce((sum, value) => sum + +value, 0);
      }

      return this.textContent(option.footer_row_label || '');
    };
    if (this.contentData.show_footer_row) {
      this.footerRow = this.options.map((option, index) => {
        const footerValue = getFooterElementValue(index);

        return MatrixEntryComponent.getMatrixElementFromOption(
          option,
          footerValue,
          true
        );
      });
    }
  }

  private prepareRadioGroups(): void {
    const getSelectedValuesForRadioGroup = (groupName: string): string[] =>
      this.items.map(
        matrixRow =>
          matrixRow.find(
            matrixElement =>
              matrixElement.radio_group === groupName && !!matrixElement.data
          )?.radio_value || null
      );
    this.radioGroups = this.options.reduce((accum, option) => {
      if (
        option.type_select === '8_radio' &&
        option.radio_group &&
        option.radio_value
      ) {
        if (accum && accum[option.radio_group]) {
          accum[option.radio_group].values.push(option.radio_value);
        } else {
          accum[option.radio_group] = {
            selectedInRows: getSelectedValuesForRadioGroup(option.radio_group),
            values: [option.radio_value]
          };
        }
      }

      return accum;
    }, {});
  }
  private sortMatrixByDropdownValues(columnId: number): void {
    this.items.sort((rowA, rowB) => {
      const dataA = rowA.find(column => Number(column.id) === columnId)?.data;
      const dataB = rowB.find(column => Number(column.id) === columnId)?.data;
      const valueA = this.options[columnId - 1].matrix_dropdown.findIndex(
        item => item.value === String(dataA)
      );
      const valueB = this.options[columnId - 1].matrix_dropdown.findIndex(
        item => item.value === String(dataB)
      );

      return valueA - valueB;
    });
  }

  private checkOptions(): void {
    switch (this.data.data.options?.view) {
      case 'table':
        this.contentData.form_type_select = '1_table_view';
        break;
      case 'horizontal':
        this.contentData.form_type_select = '2_horizontal_form_view';
        break;
      case 'vertical':
        this.contentData.form_type_select = '3_vertical_form_view';
        break;
    }
  }

  private checkForOneSelectedCheckbox(): void {
    if (
      this.options.some(column => column.type_select === '4_checkbox') &&
      this.contentData.one_selected_checkbox_per_row
    ) {
      const updatedItems = this.items.map(row => {
        const firstSelectedIndex = row.findIndex(
          item => item.type === '4_checkbox' && !!item.data
        );

        return firstSelectedIndex === -1
          ? row
          : row.map((item, index) =>
              item.type === '4_checkbox' &&
              !!item.data &&
              index !== firstSelectedIndex
                ? { ...item, data: false }
                : item
            );
      });
      if (JSON.stringify(updatedItems) !== JSON.stringify(this.items)) {
        this.items = updatedItems;
        this.updateInputs();
      }
    }
  }

  private getValidators(): Validation[] {
    let validations: Validation[] = [];
    if (this.contentData.min_number_of_filled_items) {
      const requiredFunc = (value: string, params: { minItems: number }) => {
        const content: MatrixElement[][] = JSON.parse(value || null);

        return (
          content?.filter(row => row.some(element => element.data)).length >=
          params.minItems
        );
      };
      const requiredMessage =
        this.textContent(
          this.contentData.error_message_when_not_enough_items_filled
        ) ||
        `Please fill in at least ${this.contentData.min_number_of_filled_items} rows above`;
      const funcParams = {
        minItems: Number(this.contentData.min_number_of_filled_items)
      };
      const minFilledValidation = new Validation(
        requiredFunc,
        requiredMessage,
        funcParams
      );
      validations.push(minFilledValidation);
    }
    const requiredValidations = this.contentData.options
      .map(column => {
        if (column.required) {
          const requiredMessage = 'Please fill in the fields marked above';
          const funcParams = { columnId: column.id };
          let requiredFunc = (value: string) => {
            const content: MatrixElement[][] = JSON.parse(value || null);
            const columnValues = content
              ?.map(row => row.find(item => item.id === Number(column.id)))
              ?.filter(row => !row.isFooter);

            return columnValues && columnValues?.every(cell => !!cell?.data);
          };
          switch (column.type_select) {
            case '6_label': {
              requiredFunc = (_: string) => true;
              break;
            }
            case '8_radio': {
              requiredFunc = (value: string) => {
                const content: MatrixElement[][] = JSON.parse(value || null);
                const radioGroupRows = content?.map(row =>
                  row.filter(item => item?.radio_group === column.radio_group)
                );

                return radioGroupRows.every(row =>
                  row.some(item => !!item.data)
                );
              };
              break;
            }
            case '9_checkboxes': {
              requiredFunc = (value: string) => {
                const content: MatrixElement[][] = JSON.parse(value || null);
                const columnValues = content?.map(row =>
                  row.find(item => item.id === Number(column.id))
                );

                return (
                  columnValues &&
                  columnValues?.every(
                    cell =>
                      !!cell?.checkboxes?.some(checkbox => checkbox.isChecked)
                  )
                );
              };
              break;
            }
            case '10_persona_select': {
              requiredFunc = (value: string) => {
                const content: MatrixElement[][] = JSON.parse(value || null);
                const columnValues = content?.map(row =>
                  row.find(item => item.id === Number(column.id))
                );

                return (
                  columnValues &&
                  columnValues?.every(
                    cell =>
                      !!cell?.data &&
                      Array.isArray(cell.data) &&
                      cell?.data?.length
                  )
                );
              };
              break;
            }
          }

          return new Validation(requiredFunc, requiredMessage, funcParams);
        }

        return null;
      })
      .filter(Boolean);

    validations = [...validations, ...requiredValidations].filter(Boolean);

    return validations;
  }

  private prepareValidationCellErrors(): void {
    this.validationCellErrors = this.items.map(row =>
      row.map(item => {
        const option = this.contentData.options.find(
          columnOption => Number(columnOption.id) === item.id
        );

        if (!option) {
          return false;
        }

        switch (option.type_select) {
          case '6_label': {
            return false;
          }
          case '8_radio': {
            const radioGroup = row.filter(
              cell => cell?.radio_group === option.radio_group
            );

            return !radioGroup.some(cell => !!cell.data);
          }
          case '9_checkboxes': {
            return option?.required
              ? !item.checkboxes?.some(checkbox => checkbox.isChecked)
              : false;
          }
          case '10_persona_select': {
            return option?.required
              ? Array.isArray(item.data) && !item.data?.length
              : false;
          }
          default: {
            return option?.required ? !item.data : false;
          }
        }
      })
    );
  }

  private prepareOptions(): void {
    this.options = this.contentData.options?.map(option => ({
      ...option,
      footer_cell_colspan: option.footer_cell_colspan ?? 1
    }));
  }

  private prepareValueClassNames(): void {
    this.rowValueClassNames = this.items.map(row =>
      this.contentData.options
        .map(column => {
          if (column.class_name_prefix) {
            let value = row.find(item => Number(item.id) === Number(column.id))
              ?.data;
            switch (column.type_select) {
              case '4_checkbox':
              case '8_radio': {
                value = Boolean(value) ? 'checked' : '';
              }
            }

            return value ? `${column.class_name_prefix}-${value}` : null;
          }

          return null;
        })
        .filter(Boolean)
        .join(' ')
    );
  }

  private prepareCustomCellProperties(): void {
    if (!!this.contentData.custom_cell_properties?.length) {
      this.customCellProperties = this.contentData.custom_cell_properties.map(
        item => (item.matrix_element?.length ? item : { matrix_element: [] })
      );
    }
  }

  private prepareLeftColumn(): void {
    if (
      this.contentData.show_predefined_left_column &&
      this.contentData.predefined_left_column?.length
    ) {
      this.predefinedLeftColumn = this.contentData.predefined_left_column.map(
        item => ({
          ...item,
          rowspan: item.rowspan || 0
        })
      );
    }
  }

  private checkForMaxNumberOfRows(): void {
    if (!!this.contentData.max_number_of_items && !!this.items?.length) {
      this.isMaxNumberOfRows =
        this.items.length >= Number(this.contentData.max_number_of_items);
    }
  }
}
