import { BrainstormTemplateComponent } from './brainstorm/brainstorm.component';
import { GenericTemplateComponent } from './generic/generic.component';
import { PersonaBehaviorTemplateComponent } from './persona_behavior/persona_behavior.component';
import { PersonaPictureTemplateComponent } from './persona-picture/persona-picture.component';
import { QuestionImageComponent } from './question-image/question-image.component';
import { SegmentCriteriaDefineComponent } from './segment-criteria-define/segment-criteria-define.component';
import { VideoComponent } from './video/video.component';
import { SpreadsheetComponent } from './spreadsheet/spreadsheet.component';
import { CheckboxSelectorComponent } from './checkbox-selector/checkbox-selector.component';
import { PreRequisiteModulesComponent } from './pre-requisite-modules/pre-requisite-modules.component';
import { ModuleResultComponent } from './module-result/module-result.component';
import { PersonaStrategyComponent } from './persona-strategy/persona-strategy.component';
import { CampaignCalendarTemplateComponent } from './campaign-calendar-template/campaign-calendar-template.component';
import { CampaignNeedsTemplateComponent } from './campaign-needs-template/campaign-needs-template.component';
import { RadioButtonComponent } from './radio-button/radio-button.component';
import { IcpBreakoutsComponent } from './icp-breakouts/icp-breakouts.component';
import { FileUploaderTemplateComponent } from './file-uploader-template/file-uploader-template.component';
import { ContentMapComponent } from './content-map/content-map.component';
import { PersonaRolesComponent } from './persona-roles/persona-roles.component';
import { ListEntryComponent } from './list-entry/list-entry.component';
import { TalentStrategyComponent } from './talent-strategy/talent-strategy.component';
import { RACIComponent } from './raci/raci.component';
import { NegativeAnswersComponent } from './negative-answers/negative-answers.component';
import { BuyerJourneyMapComponent } from './buyer-journey-map/buyer-journey-map.component';
import { BuyerProcessMapComponent } from './buyer-process-map/buyer-process-map.component';
import { ShowInputValueComponent } from './show-input-value/show-input-value.component';
import { BuyerPersonasSelectionMatrixComponent } from './buyer-personas-selection-matrix/buyer-personas-selection-matrix.component';
import { MarketingOrganizationDesignComponent } from './marketing-organization-design/marketing-organization-design.component';
import { SelectBuyerPersonaComponent } from './select-buyer-persona/select-buyer-persona.component';
import { ExpandDataComponent } from './expand-data/expand-data.component';
import { ExpandDataResultComponent } from './expand-data-result/expand-data-result.component';
import { MatrixEntryComponent } from './matrix-entry/matrix-entry.component';
import { CompetitionComparisonComponent } from './competition-comparison/competition-comparison.component';
import { ValuePropositionComponent } from './value-proposition/value-proposition.component';
import { IcpAreasComponent } from './icp-areas/icp-areas.component';
import { IcpSegmentSelectorComponent } from './icp-segment-selector/icp-segment-selector.component';
import { QuizComponent } from './quiz/quiz.component';
import { CountdownTimerComponent } from './countdown-timer/countdown-timer.component';
import { BuyerInputListComponent } from './buyer-input-list/buyer-input-list.component';
import { OpportunitySummaryComponent } from './opportunity-summary/opportunity-summary.component';
import { EvaluateComponent } from './evaluate/evaluate.component';
import { CompensationAndQuotaComponent } from './compensation-and-quota/compensation-and-quota.component';
import { SalesMarketingOpsReviewComponent } from './sales-marketing-ops-review/sales-marketing-ops-review.component';
import { SalesBudgetStatusTemplateComponent } from './sales-budget-status-template/sales-budget-status-template.component';
import { ParticipationLevelActivityComponent } from './participation-level-activity/participation-level-activity.component';
import { SalesLeadershipSellingTimeComponent } from './sales-leadership-selling-time/sales-leadership-selling-time.component';
import { SituationalCoachingComponent } from './situational-coaching/situational-coaching.component';
import { CustomerSuccessReviewComponent } from './customer-success-review/customer-success-review.component';
import { ReviewWithSelectionComponent } from './review-with-selection/review-with-selection.component';
import { PricingStrategyPlotComponent } from './pricing-strategy-plot/pricing-strategy-plot.component';
import { ChannelStrategySummaryComponent } from './channel-strategy-summary/channel-strategy-summary.component';
import { CalculatedCriteriaComponent } from './calculated-criteria/calculated-criteria.component';
import { ProspectingContactStrategyComponent } from './prospecting-contact-strategy/prospecting-contact-strategy.component';
import { KpiSummaryComponent } from './kpi-summary/kpi-summary.component';
import { MatrixEntrySummaryComponent } from './matrix-entry-summary/matrix-entry-summary.component';
import { BlockRepeaterResultComponent } from './block-repeater-result/block-repeater-result.component';
import { TerritoryDesignSummaryComponent } from './territory-design-summary/territory-design-summary.component';
import { PlaysReviewComponent } from './plays-review/plays-review.component';
import { FinalFeedbackComponent } from './final-feedback/final-feedback.component';
import { AudienceExtensionTemplateComponent } from './audience-extension-template/audience-extension-template.component';
import { ChartDataComponent } from './chart-data/chart-data.component';
import { ComposableTemplateComponent } from './composable-template/composable-template.component';
import { BlockRepeaterWrapperComponent } from './block-repeater/block-repeater-wrapper/block-repeater-wrapper.component';
import { BlockRepeaterComponent } from './block-repeater/block-repeater.component';
import { ProductLaunchAndMarketingComponent } from './product-launch-and-marketing/product-launch-and-marketing.component';

export const Templates = {
  brainstorm: BrainstormTemplateComponent,
  campaign_calendar: CampaignCalendarTemplateComponent,
  campaign_needs: CampaignNeedsTemplateComponent,
  generic: GenericTemplateComponent,
  persona_behavior: PersonaBehaviorTemplateComponent,
  segment_criteria_define: SegmentCriteriaDefineComponent,
  persona_picture: PersonaPictureTemplateComponent,
  final_feedback: FinalFeedbackComponent,
  module_result: ModuleResultComponent,
  pre_requisite_modules: PreRequisiteModulesComponent,
  question_image: QuestionImageComponent,
  spreadsheet: SpreadsheetComponent,
  video: VideoComponent,
  content_map: ContentMapComponent,
  checkbox: CheckboxSelectorComponent,
  persona_strategy: PersonaStrategyComponent,
  radio_button: RadioButtonComponent,
  file_uploader: FileUploaderTemplateComponent,
  persona_roles: PersonaRolesComponent,
  icp_breakouts: IcpBreakoutsComponent,
  talent_strategy: TalentStrategyComponent,
  list_entry: ListEntryComponent,
  show_input_value: ShowInputValueComponent,
  raci: RACIComponent,
  negative_answers: NegativeAnswersComponent,
  buyer_journey_map: BuyerJourneyMapComponent,
  buyer_process_map: BuyerProcessMapComponent,
  buyer_personas_selection_matrix: BuyerPersonasSelectionMatrixComponent,
  competition_comparison: CompetitionComparisonComponent,
  select_buyer_persona: SelectBuyerPersonaComponent,
  expand_data: ExpandDataComponent,
  expand_data_result: ExpandDataResultComponent,
  icp_areas: IcpAreasComponent,
  icp_segment_selector: IcpSegmentSelectorComponent,
  marketing_organization_design: MarketingOrganizationDesignComponent,
  matrix_entry: MatrixEntryComponent,
  matrix_entry_summary: MatrixEntrySummaryComponent,
  countdown_timer: CountdownTimerComponent,
  opportunity_summary: OpportunitySummaryComponent,
  buyer_input_list: BuyerInputListComponent,
  compensation_and_quota: CompensationAndQuotaComponent,
  value_proposition: ValuePropositionComponent,
  quiz: QuizComponent,
  sales_marketing_ops_review: SalesMarketingOpsReviewComponent,
  customer_success_review: CustomerSuccessReviewComponent,
  evaluate: EvaluateComponent,
  sales_budget_status_template: SalesBudgetStatusTemplateComponent,
  participation_level_activity: ParticipationLevelActivityComponent,
  sales_leadership_selling_time: SalesLeadershipSellingTimeComponent,
  situational_coaching: SituationalCoachingComponent,
  pricing_strategy_plot: PricingStrategyPlotComponent,
  review_with_selection: ReviewWithSelectionComponent,
  prospecting_contact_strategy: ProspectingContactStrategyComponent,
  kpi_summary: KpiSummaryComponent,
  block_repeater_result: BlockRepeaterResultComponent,
  calculated_criteria: CalculatedCriteriaComponent,
  channel_strategy_summary: ChannelStrategySummaryComponent,
  territory_design_summary: TerritoryDesignSummaryComponent,
  plays_review: PlaysReviewComponent,
  audience_extension: AudienceExtensionTemplateComponent,
  chart_data: ChartDataComponent,
  block_repeater: BlockRepeaterComponent,
  block_repeater_wrapper: BlockRepeaterWrapperComponent,
  composable_template: ComposableTemplateComponent,
  product_launch_and_marketing: ProductLaunchAndMarketingComponent
};
