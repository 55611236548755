import { Component, OnChanges, Input } from '@angular/core';
import User from 'src/app/common/interfaces/user.model';

@Component({
  selector: 'ice-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnChanges {
  @Input() user: User;
  @Input() time: string;

  className: string;
  firstLetter: string;

  constructor() {}

  ngOnChanges() {
    // @ts-ignore
    this.className = `bcts-${(this.user.id % 3) + 1}`;
    this.firstLetter = this.user.name[0].toUpperCase();
  }
}
