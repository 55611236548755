import { Component } from '@angular/core';
import { ModuleService } from '../../common/services/module.service';
import { VideoItem, VideoList } from '../../common/interfaces/module.interface';
import { shareReplay } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ModuleNavService } from '../../common/services/module-nav.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { VideoModalComponent } from './video-modal/video-modal.component';

@Component({
  selector: 'app-video-library',
  templateUrl: './video-library.component.html',
  styleUrls: ['./video-library.component.scss']
})
export class VideoLibraryComponent {
  moduleVideos$: Observable<VideoList[]>;
  filteredModuleVideos: VideoList[];
  selectedVideoList: VideoList;
  moduleId = 1;

  constructor(
    private moduleService: ModuleService,
    private navService: ModuleNavService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: MatDialog
  ) {
    this.moduleVideos$ = this.moduleService.getVideos().pipe(shareReplay(1));
    this.checkForModuleIdChange();
  }

  setVideoList(videoList: VideoList): void {
    this.selectedVideoList = videoList;
    this.setModuleId(videoList.id);
  }

  filterModules(videoLibrary: VideoList[], query: string): void {
    this.filteredModuleVideos = query
      ? videoLibrary.filter(videoList =>
          videoList.name.toLowerCase().includes(query.toLowerCase())
        )
      : videoLibrary;
  }

  openVideoModal(video: VideoItem): void {
    const dialogConfig = new MatDialogConfig<VideoItem>();
    dialogConfig.data = { ...video };
    this.modalService.open(VideoModalComponent, dialogConfig);
  }

  private setModuleId(moduleId: number): void {
    this.moduleId = moduleId;
    if (this.navService.videoLibraryId.current !== moduleId) {
      this.navService.videoLibraryId.current = moduleId;
    }
    if (Number(this.route.snapshot.params.moduleId) !== moduleId) {
      this.router.navigate(['video-library', moduleId]);
    }
  }

  private checkForModuleIdChange(): void {
    combineLatest([this.route.params, this.moduleVideos$]).subscribe(
      ([params, data]) => {
        this.setModuleId(
          Number(params.moduleId) || this.navService.videoLibraryId.current
        );
        this.filteredModuleVideos = data;
        this.selectedVideoList =
          data.find(videoList => videoList.id === this.moduleId) || data[0];
      }
    );
  }
}
