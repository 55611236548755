/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { SpreadSheetCellRenderFunctionType } from '../index';
import { SpreadsheetCellStaticTextData } from '../../../../../common/interfaces/spreadsheet.interface';

export function wrapStaticTextRenderer(
  rendererFunction: SpreadSheetCellRenderFunctionType
): SpreadSheetCellRenderFunctionType {
  return (instance, td, row, col, prop, value, cellProperties) => {
    rendererFunction(instance, td, row, col, prop, value, cellProperties);
    staticTextRenderer(instance, td, row, col, prop, value, cellProperties);
  };
}

export function staticTextRenderer(
  instance,
  td,
  row,
  col,
  prop,
  value,
  cellProperties
): void {
  const cellWrapper =
    td.getElementsByClassName('cell-content-wrapper')?.[0] || td;
  const staticText: SpreadsheetCellStaticTextData =
    cellProperties.options?.staticText;
  const startElement = staticText?.start
    ? `<div class="static static-text-start">${staticText.start}</div>`
    : '';
  const endElement = staticText?.end
    ? `<div class="static static-text-end">${staticText.end}</div>`
    : '';
  if (!!startElement || !!endElement) {
    cellWrapper.className = `${cellWrapper.className} with-static-text`;
    cellWrapper.innerHTML = `${startElement}${cellWrapper.innerHTML}${endElement}`;
  }
}
