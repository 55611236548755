import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { TemplateInput } from '../../interfaces/module.interface';
import {
  FileData,
  FileTypeFromBuilder
} from '../../../module-viewer/riverside-step-template/templates/file-uploader-template';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { SnackBarService } from '../../services/snackbar.service';
import { first, switchMap } from 'rxjs/operators';
import { PresignedFileUrl } from '../../interfaces/account.interface';
import { dataURItoBlob } from '../../utils/file-helpers';
import { ModuleNavService } from '../../services/module-nav.service';
import { ModuleContentService } from '../../services/module-content.service';
import {
  faFile,
  faFileExcel,
  faFileImage,
  faFileVideo,
  faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import { ConfirmationService } from '../../services/confirmation.service';
import { Observable } from 'rxjs';

export const imageExtensions = ['jpeg', 'png', 'svg'] as const;
export const spreadsheetExtensions = ['xlsx', 'csv'] as const;
export const videoExtensions = [
  'mp4',
  'avi',
  'webm',
  'ogg',
  'ogm',
  'ogv'
] as const;

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit {
  @Input() fileData: FileData;
  @Input() fileInputControl: TemplateInput;
  @Input() selectedFileType: FileTypeFromBuilder = 'Custom';
  @Input() acceptedExtensions: string[];
  @Input() maximumFileSizeMB = 25;
  @Input() isFileRequired = false;
  @Input() errorMessage = 'An error was found. Please contact with admin!';
  @Input() uploadFileMessage = 'Click to choose a file or drag it here';
  @Input() waitLoadMessage = 'Your file is uploading. Please wait...';
  @Input() readonly = false;
  @Input() downloadOnly = false;
  @Output() fileDataChange = new EventEmitter<FileData>();
  @Output() showFormatValidationError = new EventEmitter<void>();

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;

  bufferFile: FileData;
  acceptedExtensionsString: string;
  srcURL: string;
  isPDFFileType = false;
  isImageFileType = false;
  isVideoFileType = false;
  isFileChosen = false;
  isFileLoading = false;
  isError = false;

  readonly faTrash = faTrashAlt;
  readonly faFileExcelIcon = faFileExcel;
  readonly faFileImageIcon = faFileImage;
  readonly faFileCommonIcon = faFile;
  readonly faFileVideoIcon = faFileVideo;

  private readonly imageExtensions = imageExtensions;
  private readonly spreadsheetExtensions = spreadsheetExtensions;
  private readonly videoExtensions = videoExtensions;

  constructor(
    private httpClient: HttpClient,
    private snackBarService: SnackBarService,
    private moduleNavService: ModuleNavService,
    private moduleContentService: ModuleContentService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.prepareAcceptedExtensions();
    this.loadFile();
  }

  onFileSectionClick($event, isChangeAction = false): void {
    if (this.readonly || this.downloadOnly) {
      return;
    }
    if ($event) {
      $event.stopPropagation();
    }
    if (this.isFileChosen) {
      if (isChangeAction) {
        this.bufferFile = { ...this.fileData };
        this.fileInput.nativeElement.click();
      }
    } else {
      this.fileInput.nativeElement.click();
    }
  }

  onDrop($event: DragEvent): void {
    $event.preventDefault();
    $event.stopPropagation();
    const { dataTransfer } = $event;
    const files = [].slice.apply(dataTransfer.files);

    if (this.readonly || this.downloadOnly) {
      return this.snackBarService.error(`You couldn't upload the file!`);
    }

    if (this.isFileChosen) {
      if (files.length === 1 && this.isFileExtensionValid(files[0])) {
        this.prepareFileToUpload(files[0]);
      } else {
        this.showFormatValidationError.emit();
      }
    } else {
      if (this.isFileExtensionValid(files[0])) {
        this.prepareFileToUpload(files[0]);
      } else {
        this.showFormatValidationError.emit();
      }
    }
  }

  onFileChange(file?: File): void {
    const files = this.fileInput.nativeElement.files;

    if (files.length === 0 && file && this.isFileExtensionValid(file)) {
      this.prepareFileToUpload(file);

      return;
    }

    if (files && files.length === 1 && this.isFileExtensionValid(files[0])) {
      this.prepareFileToUpload(files[0]);
    } else if (!this.isFileChosen) {
      this.showFormatValidationError.emit();
    }
  }

  removeFileToUpload($event: MouseEvent): void {
    $event.stopPropagation();
    this.confirmationService
      .removeDialog({
        text: `"${this.fileData.filename}"`
      })
      .subscribe(() => {
        this.isFileChosen = false;
        this.bufferFile = null;
        this.fileInput.nativeElement.value = '';
        this.fileDataChange.emit(null);
      });
  }

  private loadFile(): void {
    if (this.fileData?.fileTypeFromBuilder === this.selectedFileType) {
      this.isFileLoading = true;
      this.loadFileFromBase64();
      this.isFileChosen = true;
      this.isFileLoading = false;
      this.bufferFile = { ...this.fileData };
      this.isPDFFileType = this.fileData.filetype.includes('pdf');
      this.isImageFileType = this.fileData.filetype.includes('image');
      this.isVideoFileType = this.videoExtensions.some(ext =>
        this.fileData.filetype.includes(ext)
      );
    } else {
      this.isFileChosen = false;
    }
  }

  private prepareFileToUpload(file: File): void {
    const sizeinMB = file.size / 1024 / 1024;
    this.bufferFile = { ...this.fileData };
    this.formatFileData(file);

    if (!this.isVideoFileType && sizeinMB > this.maximumFileSizeMB) {
      this.fileInput.nativeElement.value = '';
      if (this.isFileChosen) {
        this.fileData = { ...this.bufferFile };
      }

      return this.showMaximumSizeFileError();
    }
    const reader = new FileReader();
    this.isFileLoading = true;
    this.isFileChosen = false;

    reader.readAsDataURL(file);

    reader.onload = () => {
      if (
        typeof reader.result === 'string' &&
        reader.result.length > 0 &&
        this.fileInputControl
      ) {
        if (!this.isFileExtensionValid(file)) {
          this.snackBarService.error('Invalid file extension');

          return;
        }
        this.uploadFile(file, reader.result + '');
      } else {
        this.showMaximumSizeFileError();
        this.isFileLoading = false;
        this.isFileChosen = false;
      }
    };
  }

  private uploadFile(file: File, dataURI: string): void {
    this.presignedFileUpload(file).subscribe(
      (res: PresignedFileUrl) => {
        const { url, key } = res;
        this.httpClient
          .put(url, dataURItoBlob(dataURI), {
            headers: {
              'Content-Type': file.type,
              'Content-Encoding': 'base64'
            }
          })
          .pipe(first())
          .subscribe(
            () => {
              this.fileData = {
                filename: file.name,
                filetype: file.type,
                filesize: this.fileData.filesize,
                sizesuffix: this.fileData.sizesuffix,
                url: key,
                fileTypeFromBuilder: this.selectedFileType
              };
              this.loadFileFromBase64();
              this.isFileChosen = true;
              this.isFileLoading = false;
              this.fileDataChange.emit(this.fileData);
            },
            () => {
              this.isFileLoading = false;
              this.snackBarService.error(
                'Error uploading file. Please contact administrator!'
              );
            }
          );
      },
      e => {
        if (
          e.error &&
          e.error.failure &&
          e.error.failure === 'INVALID_EXTENSION'
        ) {
          this.snackBarService.error('Invalid file extension');
        } else {
          this.snackBarService.error('Could not upload file');
        }
      }
    );
  }

  private prepareAcceptedExtensions(): void {
    switch (this.selectedFileType) {
      case 'Image':
        this.acceptedExtensions = [...this.imageExtensions];
        this.acceptedExtensionsString = 'image/*';
        break;
      case 'Spreadshset':
        this.acceptedExtensions = [...this.spreadsheetExtensions];
        break;
      case 'Video':
        this.acceptedExtensions = [...this.videoExtensions];
        break;
      default:
        if (!this.acceptedExtensions?.length) {
          console.warn('You must fill custom extensions!');
          this.isError = true;
        }
        break;
    }
    this.acceptedExtensions = this.acceptedExtensions?.map(e =>
      e.trim().toLowerCase()
    );
    if (!this.acceptedExtensionsString) {
      this.acceptedExtensionsString = this.acceptedExtensions
        .map(type => '.' + type)
        .join(', ');
    }
  }

  private loadFileFromBase64(): void {
    if (this.fileData.url) {
      this.srcURL = this.fileData.url
        .split('https://s3-us-west-2.amazonaws.com/riverside-seagage-secure/')
        .join(environment.apiRoot + '/api/resources?s3_key=');
    }
  }

  private formatFileData(file: File): void {
    let filesize = file.size / 1024;
    let sizesuffix = 'KB';
    if (filesize >= 1024) {
      sizesuffix = 'MB';
      filesize /= 1024;
    }
    this.fileData = {
      filename: file.name,
      filetype: file.type,
      filesize,
      sizesuffix
    };
    this.isPDFFileType = file.type.includes('pdf');
    this.isImageFileType = file.type.includes('image');
    this.isVideoFileType = this.videoExtensions.some(ext =>
      file.type.includes(ext)
    );
  }

  private isFileExtensionValid(file: File): boolean {
    const ext = file.name
      .substring(file.name.lastIndexOf('.') + 1)
      .toLowerCase();

    if (this.selectedFileType === 'Image') {
      return file.type.match(/^image\/*/) !== null;
    }

    return ext && this.acceptedExtensions.indexOf(ext) !== -1;
  }

  private showMaximumSizeFileError(): void {
    this.snackBarService.error(
      `Error uploading file. Please make sure the file size is less than ${this.maximumFileSizeMB}MB`
    );
  }

  private presignedFileUpload(file: File): Observable<PresignedFileUrl> {
    return this.moduleNavService.organization$.pipe(
      first(),
      switchMap(orgId =>
        this.moduleContentService.presignedFileUpload(
          file.name,
          orgId,
          this.fileInputControl.id,
          this.isVideoFileType
        )
      )
    );
  }
}
