import { Injectable } from '@angular/core';
import {
  BuyerPersonaDictionary,
  BuyerPersona
} from '../interfaces/buyer-persona.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ModuleNavService } from './module-nav.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BuyerPersonasService {
  baseUrl = environment.apiRoot + '/api/modules';
  private buyerPersonas$: Observable<BuyerPersona[]>;
  private dataChanged$ = new BehaviorSubject(true);

  constructor(
    private httpClient: HttpClient,
    private moduleNavService: ModuleNavService
  ) {
    this.buyerPersonas$ = this.dataChanged$.pipe(
      switchMap(_ => this.getBuyerPersonasData()),
      shareReplay(1)
    );
  }

  reloadBuyerPersonas() {
    this.dataChanged$.next(true);
  }

  // TODO: make this return dictionary object (refactor effected components)
  getBuyerPersonas() {
    return this.buyerPersonas$;
  }

  // TODO: make this return dictionary object (refactor effected components)
  getBuyerPersonasData() {
    return this.moduleNavService.organization$.pipe(
      switchMap(orgId =>
        this.httpClient.get<BuyerPersonaDictionary>(
          `${this.baseUrl}/org/${orgId}/buyer-personas`
        )
      ),
      map(personas => Object.values(personas))
    );
  }

  changePersonaURLImageToLocal(persona) {
    const s3 = 's3_key=';
    const s3Us = 'riverside-seagage.s3-us-west-2.amazonaws.com';
    if (
      persona.picture &&
      (persona.picture.includes(s3) || persona.picture.includes(s3Us))
    ) {
      const key = persona.picture.includes(s3)
        ? persona.picture.split(s3)[1]
        : persona.picture.split(s3Us)[1];
      const img = key?.split('/')[1];
      persona.picture = '/assets/img/personas/' + img;

      return persona;
    }
  }
}
