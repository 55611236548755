export enum ModulePriorityLevel {
  Undefined = 0,
  Level100 = 100,
  Level200 = 200,
  Level300 = 300,
  Level400 = 400
}

export enum ModuleIDs {
  Coaching = 36,
  VirtualSelling = 55,
  SuccessfulSalesRequirements = 56,
  SalesProcessExitCriteria = 57,
  EffectiveCommunicationListeningSkills = 58,
  CompetitiveIntelligence = 59,
  ProposalWriting = 61,
  FieldMarketing = 62,
  ListeningSkills = 63,
  TimeTerritoryManagement = 64,
  HoldingYourselfAccountable = 65,
  TimeManagementPlanning = 66,
  ManagingFunnelUsingMetrics = 67,
  RecognizingBuyingBehaviors = 68,
  RecognizingBuyingRoles = 69,
  UnderstandingBuyingProcess = 70,
  UsingPersonas = 71,
  ICPSelling = 72,
  ProspectResearch = 73,
  SellingStories = 74,
  UsingTopProspectingSources = 75,
  QualifyingLeads = 76,
  EstablishingRapport = 77,
  MaximizingAppointmentSetting = 78,
  UsingNetworkingReferrals = 79,
  MasteringSocialMedia = 80,
  ManagingFunnel = 81,
  BypassingGatekeepers = 82,
  PlanningSalesCall = 83,
  RefiningYourApproach = 84,
  DiscoveringNeeds = 85,
  DevelopingSolution = 86,
  PresentingSolution = 87,
  OvercomingObjections = 88,
  OvercomingRisk = 89,
  Negotiating = 90,
  ClosingSale = 91,
  BuildingBusinessCase = 92,
  PsychologySelling = 93,
  BuildingLongTermRelationships = 94,
  BalancingServiceProfit = 95,
  ManagingKeyAccounts = 96,
  ConductingAccountReviews = 97,
  CustomerSuccessCustomerService = 98,
  RenewalsCrossSellUpSell = 99,
  AssessingDeal = 100,
  CompetitiveDealStrategies = 101,
  IdentifyingNextSteps = 102,
  BuyingDecisionTeams = 103,
  DevelopingMobilizer = 104,
  WinningRFPs = 105
}

export const modulePriorities: Record<ModuleIDs, ModulePriorityLevel> = {
  [ModuleIDs.Coaching]: ModulePriorityLevel.Level100,
  [ModuleIDs.VirtualSelling]: ModulePriorityLevel.Level200,
  [ModuleIDs.SuccessfulSalesRequirements]: ModulePriorityLevel.Level100,
  [ModuleIDs.SalesProcessExitCriteria]: ModulePriorityLevel.Level100,
  [ModuleIDs.EffectiveCommunicationListeningSkills]:
    ModulePriorityLevel.Level200,
  [ModuleIDs.CompetitiveIntelligence]: ModulePriorityLevel.Level300,
  [ModuleIDs.ProposalWriting]: ModulePriorityLevel.Level200,
  [ModuleIDs.FieldMarketing]: ModulePriorityLevel.Level300,
  [ModuleIDs.ListeningSkills]: ModulePriorityLevel.Level200,
  [ModuleIDs.TimeTerritoryManagement]: ModulePriorityLevel.Level100,
  [ModuleIDs.HoldingYourselfAccountable]: ModulePriorityLevel.Level200,
  [ModuleIDs.TimeManagementPlanning]: ModulePriorityLevel.Level200,
  [ModuleIDs.ManagingFunnelUsingMetrics]: ModulePriorityLevel.Level300,
  [ModuleIDs.RecognizingBuyingBehaviors]: ModulePriorityLevel.Level100,
  [ModuleIDs.RecognizingBuyingRoles]: ModulePriorityLevel.Level300,
  [ModuleIDs.UnderstandingBuyingProcess]: ModulePriorityLevel.Level100,
  [ModuleIDs.UsingPersonas]: ModulePriorityLevel.Level200,
  [ModuleIDs.ICPSelling]: ModulePriorityLevel.Level100,
  [ModuleIDs.ProspectResearch]: ModulePriorityLevel.Level200,
  [ModuleIDs.SellingStories]: ModulePriorityLevel.Level200,
  [ModuleIDs.UsingTopProspectingSources]: ModulePriorityLevel.Level400,
  [ModuleIDs.QualifyingLeads]: ModulePriorityLevel.Level100,
  [ModuleIDs.EstablishingRapport]: ModulePriorityLevel.Level200,
  [ModuleIDs.MaximizingAppointmentSetting]: ModulePriorityLevel.Level200,
  [ModuleIDs.UsingNetworkingReferrals]: ModulePriorityLevel.Level300,
  [ModuleIDs.MasteringSocialMedia]: ModulePriorityLevel.Level400,
  [ModuleIDs.ManagingFunnel]: ModulePriorityLevel.Level200,
  [ModuleIDs.BypassingGatekeepers]: ModulePriorityLevel.Level300,
  [ModuleIDs.PlanningSalesCall]: ModulePriorityLevel.Level100,
  [ModuleIDs.RefiningYourApproach]: ModulePriorityLevel.Level400,
  [ModuleIDs.DiscoveringNeeds]: ModulePriorityLevel.Level100,
  [ModuleIDs.DevelopingSolution]: ModulePriorityLevel.Level200,
  [ModuleIDs.PresentingSolution]: ModulePriorityLevel.Level200,
  [ModuleIDs.OvercomingObjections]: ModulePriorityLevel.Level200,
  [ModuleIDs.OvercomingRisk]: ModulePriorityLevel.Level400,
  [ModuleIDs.Negotiating]: ModulePriorityLevel.Level300,
  [ModuleIDs.ClosingSale]: ModulePriorityLevel.Level300,
  [ModuleIDs.BuildingBusinessCase]: ModulePriorityLevel.Level400,
  [ModuleIDs.PsychologySelling]: ModulePriorityLevel.Level400,
  [ModuleIDs.BuildingLongTermRelationships]: ModulePriorityLevel.Level300,
  [ModuleIDs.BalancingServiceProfit]: ModulePriorityLevel.Level100,
  [ModuleIDs.ManagingKeyAccounts]: ModulePriorityLevel.Level400,
  [ModuleIDs.ConductingAccountReviews]: ModulePriorityLevel.Level200,
  [ModuleIDs.CustomerSuccessCustomerService]: ModulePriorityLevel.Level100,
  [ModuleIDs.RenewalsCrossSellUpSell]: ModulePriorityLevel.Level200,
  [ModuleIDs.AssessingDeal]: ModulePriorityLevel.Level300,
  [ModuleIDs.CompetitiveDealStrategies]: ModulePriorityLevel.Level200,
  [ModuleIDs.IdentifyingNextSteps]: ModulePriorityLevel.Level100,
  [ModuleIDs.BuyingDecisionTeams]: ModulePriorityLevel.Level100,
  [ModuleIDs.DevelopingMobilizer]: ModulePriorityLevel.Level400,
  [ModuleIDs.WinningRFPs]: ModulePriorityLevel.Level300
};
