import { Component, forwardRef, OnInit } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import txt from '!!raw-loader!./index.ts';
import {
  dataSetName,
  IcpBreakoutsTemplateData,
  IcpData,
  TableRow,
  TitleChangeEvent
} from '.';
import { take } from 'rxjs/operators';

@Component({
  selector: 'icp-breakouts',
  templateUrl: 'icp-breakouts.component.html',
  styleUrls: ['./icp-breakouts.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => IcpBreakoutsComponent)
    }
  ]
})
export class IcpBreakoutsComponent extends TemplateComponent implements OnInit {
  params = txt;
  contentData: IcpBreakoutsTemplateData['template_params_json'];

  icpData: IcpData;
  readonly dataSetTypes: dataSetName[] = [
    'region',
    'industry',
    'product',
    'persona',
    'stratification'
  ];

  ngOnInit() {
    super.ngOnInit();
    this.activeSetName = this.dataSetTypes[0];
    this.icpData = this.activeSetData;
    if (this.activeSet) {
      this.activeSet.content = this.dataSetTypes[0];
      this.contentChanged(this.activeSet);
    }
  }

  setActiveSet(set: dataSetName) {
    this.activeSetName = set;
    this.icpData = this.activeSetData;
  }

  combinedTables() {
    this.icpData[2].rows.forEach(row => {
      for (let i = 0; i < this.icpData[2].rows.length; i++) {
        Object.keys(row.values).forEach(key => {
          this.icpData[2].rows[i].values[key] =
            this.icpData[0].rows[i].values[key] +
            this.icpData[1].rows[i].values[key];
        });
      }
    });
    this.icpData[2] = { ...this.icpData[2] };
    this.activeSetData = this.icpData;
  }

  deleteRow(row: TableRow) {
    this.confirmationService
      .removeDialog({
        text: row.title
      })
      .subscribe(() => {
        const i = this.icpData[0].rows.findIndex(tableRow => tableRow === row);
        this.icpData.forEach(table => {
          table.rows.splice(i, 1);
        });
        this.activeSetData = this.icpData;
      });
  }

  addNewRow($event: dataSetName) {
    this.icpData.forEach(table => {
      const value = $event.charAt(0).toUpperCase() + $event.substring(1);
      table.rows.push(this.getNewRowData(`New ${value}`));
    });
    this.activeSetData = this.icpData;
  }

  changeTitle(event: TitleChangeEvent) {
    const i = this.icpData[0].rows.findIndex(
      tableRow => tableRow === event.row
    );
    this.icpData.forEach(table => {
      table.rows[i].title = event.value.trim();
    });
    this.activeSetData = this.icpData;
  }

  getDescription() {
    return 'This template uses 6 inputs';
  }

  getName() {
    return 'Icp Breakouts';
  }

  getGroup(): string {
    return 'Module';
  }

  get activeSet() {
    return this.getInput('icp_breakouts_1');
  }

  get region() {
    return this.getInput('icp_breakouts_2');
  }

  get industry() {
    return this.getInput('icp_breakouts_3');
  }

  get product() {
    return this.getInput('icp_breakouts_4');
  }

  get persona() {
    return this.getInput('icp_breakouts_5');
  }

  get stratification() {
    return this.getInput('icp_breakouts_6');
  }

  set activeSetName(activeSet: dataSetName) {
    if (this.activeSet) {
      this.activeSet.content = activeSet;
      this.contentChanged(this.activeSet);
    }
  }

  get activeSetName() {
    return (this.activeSet?.getValue() as dataSetName) || this.dataSetTypes[0];
  }

  set activeSetData(data: IcpData) {
    const input = this[this.activeSetName];
    if (input) {
      input.content = JSON.stringify(data);
      this.contentChanged(input);
    }
  }

  get activeSetData() {
    const input = this[this.activeSetName];

    return JSON.parse(
      input?.getValue() || JSON.stringify(this.buildNewTable())
    ) as IcpData;
  }

  private buildNewTable(): IcpData {
    const data: IcpData = [
      { title: 'Prospects', rows: [] },
      { title: 'Customers', rows: [] },
      { title: 'Combined', rows: [] }
    ];

    if (this.activeSetName !== 'persona') {
      data.forEach(item => {
        item.rows.push(this.getNewRowData(`New ${this.activeSetName}`));
      });
    } else {
      this.buyerPersonasService
        .getBuyerPersonas()
        .pipe(take(1))
        .subscribe(personas => {
          this.icpData.forEach(table => {
            personas.forEach(persona => {
              table.rows.push(this.getNewRowData(`${persona.index}`));
            });
          });
          this.activeSetData = this.icpData;
        });
    }

    return data;
  }

  private getNewRowData(text: string) {
    return {
      title: text,
      values: { a: 0, b: 0, c: 0, d: 0 }
    };
  }
}
