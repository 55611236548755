import { Component, Input, OnInit } from '@angular/core';
import { Module } from '../../../../common/interfaces/module.interface';
import { Observable } from 'rxjs';
import { ListViewBaseDashboardComponent } from '../list-view-base-dashboard';
import { map, tap } from 'rxjs/operators';
import { isEmptyValue } from '../../../../common/utils/helpers';
import { SPRINT_LEVELS } from '../../dashboard-header/dashboard-header.component';
import { Router } from '@angular/router';
import { ModuleService } from '../../../../common/services/module.service';

@Component({
  selector: 'app-list-view-fac-dashboard',
  templateUrl: './list-view-fac-dashboard.component.html',
  styleUrls: ['./list-view-fac-dashboard.component.scss']
})
export class ListViewFacDashboardComponent
  extends ListViewBaseDashboardComponent
  implements OnInit {
  @Input() listModules$: Observable<Module[]>;
  @Input() isExecutiveDashboard: boolean;

  nextLevel = '-';
  hardcodedModulesShortNames = [
    { id: 1, shortName: 'BP & BPM' },
    { id: 16, shortName: 'CQD' },
    { id: 23, shortName: 'OM' },
    { id: 24, shortName: 'PL & M' },
    { id: 47, shortName: 'S & MO' }
  ];
  chartInfo: {
    labels: { [key: string]: string };
    data: { name: string; value: number; id: number }[];
  } = { data: [], labels: {} };
  color = { domain: [] };

  constructor(private router: Router, protected moduleService: ModuleService) {
    super(moduleService);
  }

  ngOnInit() {
    this.listModules$ = this.listModules$.pipe(
      tap(item => {
        this.setChartData(item);
      }),
      map(item => item.filter(module => module.status?.sprint_id === 1))
    );
    this.nextLevel =
      (isEmptyValue(this.sprintStatus?.rras_score)
        ? null
        : SPRINT_LEVELS.find(
            level =>
              level.value > this.sprintStatus.rras_score && level.value >= 40
          ) || SPRINT_LEVELS[SPRINT_LEVELS.length - 1]
      )?.label || '-';
  }

  setChartData(modules: Module[]): void {
    modules.forEach(module => {
      const shortName = this.getShortName(module);
      this.chartInfo.data.push({
        name: shortName,
        value: module.status?.progress ? module.status.progress : 0,
        id: module.id
      });

      this.chartInfo.labels[shortName] = module.name;
      this.color.domain.push(
        module.status?.sprint_id === 1 ? '#4ead49' : '#cccccc'
      );
    });
  }

  getShortName(module: Module): string {
    return (
      this.hardcodedModulesShortNames.find(item => item.id === module.id)
        ?.shortName ||
      module.name
        .replace(/( and )+/g, '&')
        .replace(/[^A-Z&]+/g, '')
        .replace(/&/g, ' & ')
    );
  }

  onSelectChart(event) {
    this.router.navigate([
      'org',
      this.organization.id,
      'module',
      this.chartInfo.data.find(data => data.name === event.name)?.id
    ]);
  }
}
