import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  HostListener
} from '@angular/core';
import { BuyerPersonasService } from '../../../../common/services/buyer-personas.service';
import { BuyerPersona } from '../../../../common/interfaces/buyer-persona.interface';
import { Observable, combineLatest } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';

type BuyerPersonaSelection = BuyerPersona & { isSelected: boolean };

@Component({
  selector: 'buyer-personas-selector',
  templateUrl: './buyer-personas-selector.component.html',
  styleUrls: ['./buyer-personas-selector.component.scss']
})
export class BuyerPersonasSelectorComponent implements OnInit {
  @Input() readonly = false;
  @Input() alwaysOpen: false;
  @Input() showStringInPrintMode = false;
  @Input() selected: string[] = [];
  @Input() showFullModeInReadonly = false;
  @Input() type: 'dropdown' | 'checkbox' = 'dropdown';

  @Output() selectedChange = new EventEmitter<string[]>();

  selectedBuyerPersonas$: Observable<BuyerPersona[]>;
  readOnlyTitles$: Observable<string>;
  buyerPersonasList$: Observable<BuyerPersonaSelection[]>;
  dropdownOpen = false;
  isNotSelected = false;
  isPrintable = environment.allowDynamicApi;

  constructor(
    private buyerPersonasService: BuyerPersonasService,
    private eRef: ElementRef
  ) {}

  @HostListener('document:click', ['$event'])
  hideDropdown(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.dropdownOpen = false;
    }
  }

  ngOnInit() {
    this.buyerPersonasList$ = combineLatest([
      this.buyerPersonasService.getBuyerPersonas(),
      this.selectedChange.pipe(startWith(null as Event))
    ]).pipe(
      map(([buyerPersonas]) =>
        buyerPersonas.map(
          (persona: BuyerPersona & { isSelected: boolean }) => ({
            ...persona,
            isSelected: this.selected.some(item => item === persona.uuid)
          })
        )
      ),
      tap(
        personaList =>
          (this.isNotSelected = !personaList.some(
            persona => persona.isSelected
          ))
      )
    );
    // Show personas selected titles only in case it's read only
    this.selectedBuyerPersonas$ = this.buyerPersonasList$.pipe(
      map(personas =>
        personas.filter(persona =>
          this.selected.some(item => item === persona.uuid)
        )
      )
    );
    this.readOnlyTitles$ = this.selectedBuyerPersonas$.pipe(
      map(
        personas =>
          personas.map(persona => persona.name).join(', ') ||
          'No personas selected'
      )
    );
  }

  selectBuyerPersona(event: MouseEvent, uuid: string): void {
    event?.stopPropagation();
    if (this.readonly) {
      return;
    }
    if (this.selected.includes(uuid)) {
      this.selected.splice(this.selected.indexOf(uuid), 1); // Remove selected persona
    } else {
      this.selected.push(uuid); // Add selected persona
    }
    this.selectedChange.emit([...this.selected]);
  }

  switchDropdown(): void {
    if (!this.readonly) {
      this.dropdownOpen = !this.dropdownOpen;
    }
  }
}
