import { TemplateContentDataBase } from '../template.interface';

export interface SituationalCoachingTemplateData
  extends TemplateContentDataBase {
  template_params_json: {
    description: string;
    title: string;
    layout_type_select: 'table' | 'list';
  };
}

export interface TablePath {
  title: string;
  subtitle: string;
  text: string;
  reps: { value: string }[];
}

export const tableData = [
  [
    {
      title: 'Support',
      subtitle: '#3 Reluctant Contributor',
      text: '- Enthusiasm <br> + Skills',
      reps: []
    },
    {
      title: 'Direct',
      subtitle: '#2 Disillusioned Learner',
      text: '- Enthusiasm <br> - Skills',
      reps: []
    }
  ],
  [
    {
      title: 'Delegate',
      subtitle: '#4 Peak Performer',
      text: '+ Enthusiasm <br> + Skills',
      reps: []
    },
    {
      title: 'Teach',
      subtitle: '#1 Enthusiastic Beginner',
      text: '+ Enthusiasm <br> - Skills',
      reps: []
    }
  ]
];
