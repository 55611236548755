import { Injectable } from '@angular/core';
import { TemplateInput } from '../interfaces/module.interface';
import { Observable, of } from 'rxjs';
import { ModuleService } from './module.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  SpreadsheetMeta,
  SpreadsheetResource
} from '../interfaces/spreadsheet.interface';
import { RestService } from './rest.service';

@Injectable()
export class SpreadsheetService {
  constructor(
    private moduleService: ModuleService,
    private rest: RestService
  ) {}

  getSpreadsheet(
    input: TemplateInput,
    xlsFile: string,
    visibleRows?: string,
    visibleCols?: number[],
    keepFormulas?: boolean,
    meta_sufix = '',
    isOpenEndedRowRange = false
  ): Observable<SpreadsheetResource> {
    const modelMeta = xlsFile?.split('.')[0];

    return this.rest
      .get<SpreadsheetResource>(
        `${this.moduleService.baseUrl}/${input.module_id}/org/${input.org_id}/input/${input.id}/xls?xls=` +
          xlsFile +
          (visibleRows?.length || isOpenEndedRowRange
            ? '&rows=' + visibleRows
            : '') +
          (visibleCols?.length ? '&cols=' + JSON.stringify(visibleCols) : '') +
          (keepFormulas ? '&keepFormulas=true' : '')
      )
      .pipe(
        switchMap((responseData: SpreadsheetResource) =>
          this.rest
            .get<SpreadsheetMeta>(
              `/assets/xls/${modelMeta}${meta_sufix}.meta.json?` +
                this.getBuildId(),
              undefined,
              true
            )
            .pipe(
              map(meta => ({ ...responseData, meta })),
              catchError(() => of(responseData))
            )
        )
      );
  }

  exportXlsUrl(input: TemplateInput, xlsFile: string) {
    return (
      `${this.moduleService.baseUrl}/${input.module_id}/org/${input.org_id}/input/${input.id}/xls-export?xls=` +
      xlsFile
    );
  }

  getParsedRange(range, direction: 'row' | 'column') {
    return range
      ? range.split(',').reduce((rows, intv) => {
          const highLow = intv.split('-');
          if (direction === 'row' && highLow.length === 2 && !highLow[1]) {
            return [];
          }
          for (
            let idx = Number(highLow[0]) - 1;
            idx <= Number(highLow[highLow.length - 1]) - 1;
            idx++
          ) {
            rows.push(idx);
          }

          return rows;
        }, [])
      : [];
  }

  private getBuildId() {
    const scripts = document.getElementsByTagName('script') as any;

    return Array.prototype.slice
      .apply(scripts)
      .map(script =>
        script.src
          .split('/')
          .pop()
          .split('.')
      )
      .filter(name => name[0] === 'main')
      .pop()[1];
  }
}
