import { Component, OnInit, Input } from '@angular/core';
import { TemplateComponent } from '../templates/template-base.class';
import { BuyerPersona } from '../../../common/interfaces/buyer-persona.interface';
import { BuyerPersonasService } from '../../../common/services/buyer-personas.service';

@Component({
  selector: 'riverside-persona',
  templateUrl: './persona.component.html',
  styleUrls: ['./persona.component.scss']
})
export class PersonaComponent implements OnInit {
  @Input() persona: BuyerPersona;
  @Input() size = 80;
  @Input() vertical = false;

  style;

  constructor(
    private template: TemplateComponent,
    private buyerPersonaService: BuyerPersonasService
  ) {}

  ngOnInit() {
    if (this.size) {
      this.style = {
        width: this.size + 'px',
        height: this.size + 'px'
      };
    }
    this.buyerPersonaService.changePersonaURLImageToLocal(this.persona);
  }
}
