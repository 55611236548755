import { Component, forwardRef } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import { TalentStrategyTemplateData } from '.';
import txt from '!!raw-loader!./index.ts';
import { TemplateInput } from '../../../../common/interfaces/module.interface';
import { MatSelectChange } from '@angular/material/select';
import { defaultCheckboxes, defaultCheckboxesTips } from './chexboxFields';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';

declare interface TalentStrategyInputData {
  hasData: boolean;
}

@Component({
  selector: 'talent-strategy',
  templateUrl: 'talent-strategy.component.html',
  styleUrls: ['./talent-strategy.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => TalentStrategyComponent)
    }
  ]
})
export class TalentStrategyComponent extends TemplateComponent {
  params = txt;
  contentData: TalentStrategyTemplateData['template_params_json'];
  prefix = 'talent_strategy_';

  readonly frequencyList: string[] = [
    'Weekly',
    'Monthly',
    'Quarterly',
    'Semiannually',
    'Annually'
  ];
  readonly defaultCheckboxesTips = defaultCheckboxesTips;

  faCheck = faCheck;
  faTimes = faTimes;

  competenciesState = [];
  jobs: TemplateInput[] = [];
  jobsDescription: TemplateInput[] = [];
  assessmentFields: TemplateInput[] = [];
  hiringAssessmentFields: TemplateInput[] = [];
  frequencyFields: TemplateInput[] = [];
  competencies;
  checkboxInput: string;
  showCheckBoxAdditionInput = false;
  particularAssessmentList: string[] = [];
  fieldsContent: string[] = [];
  shouldShowTab: boolean;

  private assessmentList: string[] = [];

  getDescription() {
    return 'Talent strategy template';
  }

  getName() {
    return 'Talent Strategy';
  }

  getGroup(): string {
    return 'Module';
  }

  get input() {
    return this.getInput('talent_strategy');
  }

  init() {
    super.init();
    // @ts-ignore
    this.contentData = this.data.data
      .template_params_json as TalentStrategyTemplateData['template_params_json'];

    this.initJobs();
    this.jobsDescription = this.getJobsDescription();

    if (
      this.contentData.step_type_select === '2_identify_competencies' ||
      this.contentData.step_type_select === '6_job_overview'
    ) {
      this.initCompetencies();
    }

    if (
      this.contentData.step_type_select === '7_assessments' ||
      '8_hiring_assessments'
    ) {
      if (this.contentData.step_type_select === '8_hiring_assessments') {
        this.assessmentList = [
          'Caliper Profile',
          'OMG',
          'Watson Glaser',
          'DiSC',
          'Other (Specify)'
        ];
      } else {
        this.assessmentList = [
          'Caliper Profile',
          'OMG',
          'Watson Glaser',
          'Other (Specify)'
        ];
      }

      this.initFrequencies();
      this.initAssessments();
    }

    if (this.contentData.step_type_select === '8_hiring_assessments') {
      this.initHiringAssessments();
    }

    this.shouldShowTab = [2, 3, 4, 5, 6].includes(
      Number(this.contentData.step_type_select[0])
    );

    this.particularAssessmentList = this.assessmentList.slice(
      0,
      this.contentData.step_type_select === '8_hiring_assessments' ? 4 : 3
    );

    if (Number(this.contentData.step_type_select[0]) === 6) {
      this.moduleNavService.currentOrganizationData$.subscribe(currentOrg => {
        if (currentOrg.name) {
          Object.keys(this.inputs)
            .filter(inputName =>
              inputName.includes(`${this.prefix}companyName`)
            )
            .forEach(inputName => {
              const decoratedInput = this.getInput(inputName, null, '');
              decoratedInput.content =
                decoratedInput.getValue() || currentOrg.name;
            });
        }
      });
    }

    if (Number(this.contentData.step_type_select[0]) === 7) {
      this.fieldsContent = this.jobs
        .map((job, index) =>
          this.getFieldContentSelection(this.assessmentFields[index])
        )
        .map((content, index) =>
          this.assessmentFields[index].content === '' ? null : content
        );
    } else if (Number(this.contentData.step_type_select[0]) === 8) {
      this.fieldsContent = this.jobs
        .map((job, index) =>
          this.getFieldContentSelection(this.hiringAssessmentFields[index])
        )
        .map((content, index) =>
          this.hiringAssessmentFields[index].content === '' ? null : content
        );
    }
  }

  getFieldContentSelection(assessmentInput: TemplateInput): string {
    const isNotOtherOptionSelected = this.particularAssessmentList.some(
      item => item === assessmentInput.content
    );

    return (
      (isNotOtherOptionSelected
        ? assessmentInput.content
        : this.assessmentList[4]) || ''
    );
  }

  addJob() {
    const index = this.jobs.length + 1;
    this.jobs.push(this.inputs[this.prefix + 'title_' + index]);
  }

  removeJob(index: number, jobTitle: string): void {
    this.confirmationService
      .removeDialog({ text: jobTitle ? `"${jobTitle}"` : '' })
      .subscribe(() => {
        const getRelatedInputNames = (relatedIndex: number) =>
          Object.keys(this.inputs).filter(
            inputName =>
              inputName.startsWith(this.prefix) &&
              inputName.endsWith(relatedIndex.toString())
          );
        while (index < this.contentData.number_of_inputs) {
          const indexInputs = getRelatedInputNames(index + 1);
          if (
            !(
              this.inputs[this.prefix + 'title_' + (index + 2)] &&
              this.inputs[this.prefix + 'title_' + (index + 2)].content
            )
          ) {
            indexInputs.forEach(
              inputName => (this.inputs[inputName].content = null)
            );
            break;
          }
          indexInputs.forEach(inputName => {
            const inputNameWithoutIndex = inputName.slice(
              0,
              -(index + 1).toString().length
            );
            this.inputs[
              inputNameWithoutIndex + (index + 1)
            ].content = this.inputs[
              inputNameWithoutIndex + (index + 2)
            ].content;
          });
          index++;
        }
        this.moduleService
          .saveMultipleInputs(
            Object.keys(this.inputs).map(inputName => this.inputs[inputName])
          )
          .subscribe();
        this.initJobs();
        this.jobsDescription = this.getJobsDescription();
      });
  }

  initFrequencies(): void {
    this.frequencyFields = this.getSortedInputNames(
      /^talent_strategy_frequency_[0-9]+$/
    ).map(frequency => this.inputs[frequency]);
  }

  initAssessments(): void {
    this.assessmentFields = this.getSortedInputNames(
      /^talent_strategy_assessments_[0-9]+$/
    ).map(assessment => this.inputs[assessment]);
  }

  initHiringAssessments(): void {
    this.hiringAssessmentFields = this.getSortedInputNames(
      /^talent_strategy_hiringAssessments_[0-9]+$/
    ).map(assessment => this.inputs[assessment]);
  }

  changeAssessmentSelect(
    event: MatSelectChange,
    assessmentInput: TemplateInput,
    assessmentIndex: number
  ): void {
    assessmentInput.content =
      event.value === this.assessmentList[4] ? '' : event.value;
    this.fieldsContent[assessmentIndex] = this.getFieldContentSelection(
      assessmentInput
    );
    this.contentChanged(assessmentInput);
  }

  updateCompetency(index: number): void {
    const input = this.inputs[this.prefix + 'competencies_' + (index + 1)];
    input.content = JSON.stringify(this.competencies[index]);
    this.contentChanged(input);
  }

  openCheckboxInput(): void {
    this.showCheckBoxAdditionInput = true;
  }

  closeCheckboxInput(): void {
    this.checkboxInput = '';
    this.showCheckBoxAdditionInput = false;
  }

  addCheckboxValue(index: number) {
    this.competencies[index].push({
      name: this.checkboxInput,
      checked: this.canAddCompetency(index),
      description: '',
      deletable: true
    });
    this.checkboxInput = '';
    this.showCheckBoxAdditionInput = false;
    this.updateCompetency(index);
  }

  canAddCompetency(index: number): boolean {
    return (
      this.competencies[index].filter(competency => competency.checked).length <
      15
    );
  }

  isLimitedCompetenciesCheckedNumber(competencies): boolean {
    return competencies.filter(competency => !!competency.checked).length >= 15;
  }

  removeCheckbox(jobIndex: number, competencyIndex: number): void {
    this.confirmationService
      .removeDialog({
        text: `"${this.competencies[jobIndex][competencyIndex].name}"`
      })
      .subscribe(() => {
        this.competencies[jobIndex].splice(competencyIndex, 1);
        this.updateCompetency(jobIndex);
      });
  }

  set talentStrategyInputData(
    talentStrategyInputData: TalentStrategyInputData
  ) {
    this.input.content = JSON.stringify(talentStrategyInputData);
    this.contentChanged(this.input);
  }

  get talentStrategyInputData() {
    return JSON.parse(this.input.getValue() || '{}') as TalentStrategyInputData;
  }

  private initJobs(): void {
    this.jobs = [
      ...this.getSortedInputNames(/^talent_strategy_title_[0-9]+$/)
        .filter(jobName => this.inputs[jobName].content)
        .map(job => this.inputs[job])
    ];
  }

  private initCompetencies(): void {
    this.competencies = this.getSortedInputNames(
      /^talent_strategy_competencies_[0-9]+$/
    ).map(competencies => {
      if (!this.inputs[competencies].content) {
        this.inputs[competencies].content = JSON.stringify(defaultCheckboxes);
      }

      return JSON.parse(this.inputs[competencies].content);
    });

    this.jobs.map((job, idx) => {
      this.competencies.map(competency => {
        this.competenciesState.push(
          !competency.checked &&
            this.competencies[idx].filter(item => !!item.checked).length >= 15
        );
      });
    });
  }

  private getJobsDescription(): TemplateInput[] {
    return this.getSortedInputNames(
      /^talent_strategy_description_[0-9]+$/
    ).map(job => ({ ...this.inputs[job] }));
  }
}
