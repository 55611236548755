import * as Handsontable from 'src/assets/scripts/handsontable.js';
import { MultipleCheckboxesRenderer } from '../custom-renderers/multiple-checkboxes-renderer';
import { MultipleCheckboxesEditor } from '../custom-editors/multiple-checkboxes-editor';

export const MultipleCheckboxesType = {
  editor: MultipleCheckboxesEditor,
  renderer: MultipleCheckboxesRenderer
};
export const multipleCheckboxes = 'multiple-checkboxes';

Handsontable.renderers.registerRenderer(
  multipleCheckboxes,
  MultipleCheckboxesRenderer
);
Handsontable.editors.registerEditor(
  multipleCheckboxes,
  MultipleCheckboxesEditor
);
Handsontable.cellTypes.registerCellType(
  multipleCheckboxes,
  MultipleCheckboxesType
);
