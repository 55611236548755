import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { switchMap, take } from 'rxjs/operators';

@Injectable()
export class ExportKeyInterceptor implements HttpInterceptor {
  constructor(private activatedRoute: ActivatedRoute) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (environment.allowDynamicApi) {
      return this.activatedRoute.queryParams.pipe(
        take(1),
        switchMap(({ key }) =>
          next.handle(
            request.clone({
              params: key ? request.params.set('key', key) : request.params
            })
          )
        )
      );
    } else {
      return next.handle(request);
    }
  }
}
