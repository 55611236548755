import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BubbleChartComponent } from '@swimlane/ngx-charts';
import { animate, style, transition, trigger } from '@angular/animations';
import { ChartData } from '../../../../common/interfaces/charts.interface';

interface Quadrant {
  position: { x: number; y: number; width: number; height: number };
  data: ChartData[];
}
type QuadrantSeries = [Quadrant, Quadrant, Quadrant, Quadrant];

@Component({
  selector: 'app-custom-bubble-chart',
  template: `
    <ngx-charts-chart
      [view]="[width, height]"
      [showLegend]="legend"
      [activeEntries]="activeEntries"
      [legendOptions]="legendOptions"
      [animations]="animations"
      (legendLabelClick)="onClick($event)"
      (legendLabelActivate)="onActivate($event)"
      (legendLabelDeactivate)="onDeactivate($event)"
    >
      <svg:defs>
        <svg:clipPath [attr.id]="clipPathId">
          <svg:rect
            [attr.width]="dims.width + 10"
            [attr.height]="dims.height + 10"
            [attr.transform]="'translate(-5, -5)'"
          />
        </svg:clipPath>
      </svg:defs>
      <svg:g [attr.transform]="transform" class="bubble-chart chart">
        <svg:g
          ngx-charts-x-axis
          *ngIf="xAxis"
          [showGridLines]="showGridLines"
          [dims]="dims"
          [xScale]="xScale"
          [showLabel]="showXAxisLabel"
          [labelText]="xAxisLabel"
          [trimTicks]="trimXAxisTicks"
          [rotateTicks]="rotateXAxisTicks"
          [maxTickLength]="maxXAxisTickLength"
          [tickFormatting]="xAxisTickFormatting"
          [ticks]="xAxisTicks"
          (dimensionsChanged)="updateXAxisHeight($event)"
        />
        <svg:g
          ngx-charts-y-axis
          *ngIf="yAxis"
          [showGridLines]="showGridLines"
          [yScale]="yScale"
          [dims]="dims"
          [showLabel]="showYAxisLabel"
          [labelText]="yAxisLabel"
          [trimTicks]="trimYAxisTicks"
          [maxTickLength]="maxYAxisTickLength"
          [tickFormatting]="yAxisTickFormatting"
          [ticks]="yAxisTicks"
          (dimensionsChanged)="updateYAxisWidth($event)"
        />
        <svg:rect
          class="bubble-chart-area"
          x="0"
          y="0"
          [attr.width]="dims.width"
          [attr.height]="dims.height"
          style="fill: rgb(255, 0, 0); opacity: 0; cursor: 'auto';"
          (mouseenter)="deactivateAll()"
        />
        <ng-container *ngIf="divideByQuadrants">
          <svg:rect
            *ngFor="let quadrant of quadrants; let i = index"
            class="bubble-quadrant"
            [attr.x]="quadrant.position.x"
            [attr.y]="quadrant.position.y"
            [attr.width]="quadrant.position.width"
            [attr.height]="quadrant.position.height"
            (mouseenter)="hideCirclesInQuadrant(i)"
            (mouseleave)="showCirclesInQuadrant(i)"
          />
        </ng-container>
        <svg:g [attr.clip-path]="clipPath">
          <ng-container *ngIf="divideByQuadrants; else classicMode">
            <svg:g
              *ngFor="let quadrant of quadrants; let i = index"
              class="quadrant-{{ i }}"
              [class.hide-circle-labels]="hideCirclesInQuadrants[i]"
            >
              <ng-container
                *ngTemplateOutlet="
                  bubbleSeries;
                  context: {
                    seriesData: quadrant.data,
                    hideCircles: hideCirclesInQuadrants[i]
                  }
                "
              ></ng-container>
            </svg:g>
          </ng-container>
          <ng-template #classicMode>
            <ng-container
              *ngTemplateOutlet="bubbleSeries; context: { seriesData: data }"
            ></ng-container>
          </ng-template>
          <ng-template
            #bubbleSeries
            let-seriesData="seriesData"
            let-hideCircles="hideCircles"
          >
            <svg:g
              *ngFor="let series of seriesData; trackBy: trackBy"
              [@animationState]="'active'"
            >
              <svg:g
                custom-charts-bubble-series
                [xScale]="xScale"
                [yScale]="yScale"
                [rScale]="rScale"
                [xScaleType]="xScaleType"
                [yScaleType]="yScaleType"
                [xAxisLabel]="xAxisLabel"
                [yAxisLabel]="yAxisLabel"
                [colors]="colors"
                [data]="series"
                [activeEntries]="activeEntries"
                [tooltipDisabled]="tooltipDisabled"
                [tooltipTemplate]="tooltipTemplate"
                [showCircleLabels]="showCircleLabels"
                [dims]="dims"
                [hideCircles]="hideCircles"
                (select)="onClick($event, series)"
                (activate)="onActivate($event)"
                (deactivate)="onDeactivate($event)"
              />
            </svg:g>
          </ng-template>
        </svg:g>
      </svg:g>
    </ngx-charts-chart>
  `,
  styles: [
    `
      .bubble-quadrant {
        fill: #eaeaea;
        opacity: 0.5;
        cursor: auto;
        transition: 0.5s;
      }
      .bubble-quadrant:hover {
        opacity: 0;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('animationState', [
      transition(':leave', [
        style({
          opacity: 1
        }),
        animate(
          500,
          style({
            opacity: 0
          })
        )
      ])
    ])
  ]
})
export class CustomBubbleChartComponent extends BubbleChartComponent {
  @Input() showGridLines = true;
  @Input() legend = false;
  @Input() legendTitle = 'Legend';
  @Input() legendPosition: 'right' | 'below' = 'right';
  @Input() xAxis = true;
  @Input() yAxis = true;
  @Input() showXAxisLabel: boolean;
  @Input() showYAxisLabel: boolean;
  @Input() xAxisLabel: string;
  @Input() yAxisLabel: string;
  @Input() trimXAxisTicks = true;
  @Input() trimYAxisTicks = true;
  @Input() rotateXAxisTicks = true;
  @Input() maxXAxisTickLength = 16;
  @Input() maxYAxisTickLength = 16;
  @Input() xAxisTickFormatting: any;
  @Input() yAxisTickFormatting: any;
  @Input() xAxisTicks: any[];
  @Input() yAxisTicks: any[];
  @Input() roundDomains = false;
  @Input() maxRadius = 10;
  @Input() minRadius = 3;
  @Input() autoScale: boolean;
  @Input() schemeType = 'ordinal';
  @Input() tooltipDisabled = false;
  @Input() xScaleMin: any;
  @Input() xScaleMax: any;
  @Input() yScaleMin: any;
  @Input() yScaleMax: any;
  @Input() results: any;
  @Input() view: [number, number];
  @Input() scheme: any;
  @Input() showCircleLabels = false;
  @Input() divideByQuadrants = false;

  data: ChartData[];
  quadrants: QuadrantSeries;
  hideCirclesInQuadrants = [false, false, false, false];

  update(): void {
    super.update();
    if (this.showCircleLabels) {
      this.prepareLabelPositions();
    }
    if (this.divideByQuadrants) {
      this.attachCirclesToQuadrants();
    }
  }

  prepareLabelPositions(): void {
    type BubbleLabelPosition = {
      circleX: number;
      circleY: number;
      offsetX: number;
      offsetY: number;
    };
    const offsetYArray = [40, -10, 60, -20];
    const closedPositions: BubbleLabelPosition[] = [];
    const xCircleRange = 4;
    const isOverlap = (position: BubbleLabelPosition) =>
      !!closedPositions.find(
        pos =>
          pos.circleY === position.circleY &&
          ((position.circleX >= pos.circleX &&
            pos.circleX + xCircleRange >= position.circleX) ||
            (position.circleX <= pos.circleX &&
              pos.circleX - xCircleRange <= position.circleX)) &&
          position.offsetY === pos.offsetY
      );
    this.data.forEach(item => {
      item.series.forEach(series => {
        let i = 0;
        const curPos: BubbleLabelPosition = {
          circleX: series.x,
          circleY: series.y,
          offsetX: 0,
          offsetY: 0
        };
        while (isOverlap(curPos) && i < 4) {
          curPos.offsetY = offsetYArray[i];
          i++;
        }
        closedPositions.push(curPos);
        series.labelX = curPos.offsetX;
        series.labelY = curPos.offsetY;
      });
    });
  }

  attachCirclesToQuadrants(): void {
    const mediumX = this.xScaleMax / 2;
    const mediumY = this.yScaleMax / 2;
    this.quadrants = this.data.reduce(
      (accum: QuadrantSeries, item) => {
        item.series.forEach(series => {
          const quadIndex: 0 | 1 | 2 | 3 =
            series.x >= mediumX
              ? series.y >= mediumY
                ? 1
                : 3
              : series.y >= mediumY
              ? 0
              : 2;
          let quadData = accum[quadIndex].data?.find(
            ser => ser.name === item.name
          );
          if (!quadData) {
            quadData = { name: item.name, id: item.id, series: [] };
            accum[quadIndex].data.push(quadData);
          }
          quadData.series.push(series);
        });

        return accum;
      },
      [
        {
          position: {
            width: this.dims.width / 2,
            height: this.dims.height / 2,
            x: 0,
            y: 0
          },
          data: []
        },
        {
          position: {
            width: this.dims.width / 2,
            height: this.dims.height / 2,
            x: this.dims.width / 2,
            y: 0
          },
          data: []
        },
        {
          position: {
            width: this.dims.width / 2,
            height: this.dims.height / 2,
            x: 0,
            y: this.dims.height / 2
          },
          data: []
        },
        {
          position: {
            width: this.dims.width / 2,
            height: this.dims.height / 2,
            x: this.dims.width / 2,
            y: this.dims.height / 2
          },
          data: []
        }
      ]
    );
  }

  hideCirclesInQuadrant(index: number): void {
    this.hideCirclesInQuadrants[index] = true;
  }

  showCirclesInQuadrant(index: number): void {
    this.hideCirclesInQuadrants[index] = false;
  }
}
