import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { Module } from '../../../../common/interfaces/module.interface';
import { SPRINT_LEVELS } from '../../dashboard-header/dashboard-header.component';
import { isEmptyValue } from '../../../../common/utils/helpers';
import { ListViewBaseDashboardComponent } from '../list-view-base-dashboard';

@Component({
  selector: 'app-list-view-se-dashboard',
  templateUrl: './list-view-se-dashboard.component.html',
  styleUrls: [
    './../list-view-dashboard.component.scss',
    './list-view-se-dashboard.component.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListViewSeDashboardComponent extends ListViewBaseDashboardComponent
  implements OnChanges {
  @Input() listModules: Module[];

  sprintInfo: { label: string; value: string; tip?: string }[] = [
    {
      label: 'RRAS<br />Total score',
      value: '-',
      tip:
        'This is a standardized percentage derived by taking the amount of points earned ' +
        'from the current assessment and dividing it by the total amount of points possible. ' +
        'It represents the percentage of progress made towards a perfect score on the most current assessment.'
    },
    {
      label: 'Points to next level',
      value: '-',
      tip:
        'This is the number of potential improvement points, based on your current assessment, ' +
        'needed in order to move to the next level of performance (e.g., Poor, Fair, Good, Very Good and Exceptional). '
    },
    {
      label: 'PI score from current sprint',
      value: '-',
      tip:
        'This is the total number of potential improvement points for each module assigned to the current sprint.'
    },
    {
      label: 'Next level',
      value: '-',
      tip:
        'This represents the next level of performance. Performance levels span from Poor (-100 to 39), ' +
        'Fair (40-64), Good (65-79), Very Good ( 80-89) and Exceptional (90-100). ' +
        'The achievement of these levels is based on the most current assessment.'
    }
  ];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.listModules || changes?.sprintStatus) {
      this.prepareSprintInfo();
    }
  }

  private prepareSprintInfo(): void {
    const newInfo = [...this.sprintInfo];
    const nextLevel = isEmptyValue(this.sprintStatus?.rras_score)
      ? null
      : SPRINT_LEVELS.find(
          level =>
            level.value > this.sprintStatus.rras_score && level.value >= 40
        ) || SPRINT_LEVELS[SPRINT_LEVELS.length - 1];
    newInfo[0].value = this.sprintStatus?.rras_score?.toString() ?? '-';
    newInfo[1].value =
      (!isEmptyValue(nextLevel?.value) &&
        !isEmptyValue(this.sprintStatus?.rras_score) &&
        String(nextLevel.value - this.sprintStatus.rras_score)) ||
      '-';
    newInfo[2].value =
      this.summarizeImprovement(this.listModules).toString() ?? '-';
    newInfo[3].value = nextLevel?.label || '-';
    this.sprintInfo = newInfo;
  }
}
