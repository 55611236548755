import { Component, forwardRef } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import {
  ChannelStrategySummaryTemplateData,
  COMPANIES_MODELS,
  Company,
  FormBuilderData
} from '.';
import txt from '!!raw-loader!./index.ts';
import { MatrixElement, MatrixElementData } from '../matrix-entry';
import { Observable, of } from 'rxjs';
import { BrainstormTemplateParams } from '../brainstorm';
import { scan, switchMap, take } from 'rxjs/operators';
import { ExpandedData } from '../expand-data';
import { BlockRepeaterContent } from '../block-repeater';
import { updateBlockRepeaterFormat } from '../block-repeater/helpers';

@Component({
  selector: 'channel-strategy-summary',
  templateUrl: 'channel-strategy-summary.component.html',
  styleUrls: ['./channel-strategy-summary.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => ChannelStrategySummaryComponent)
    }
  ]
})
export class ChannelStrategySummaryComponent extends TemplateComponent {
  params = txt;
  contentData: ChannelStrategySummaryTemplateData['template_params_json'];

  currentSlide = 0;
  companies: Company[];
  demandGeneration$: Observable<FormBuilderData[]>;
  dealRegistration$: Observable<FormBuilderData[]>;
  enablingTechnology$: Observable<FormBuilderData[]>;
  partnerMarketingPlan$: Observable<Map<string, FormBuilderData[]>>;
  leadManagement$: Observable<Map<string, FormBuilderData[]>>;
  customDealRegistrationRules: FormBuilderData[];
  keyStackholdersMain: MatrixElementData[][][] = [];
  keyStackholdersFooter: MatrixElementData[] = [];
  keyStackholdersMainHeaders: string[] = [];
  keyStackholdersFooterHeaders: string;
  escalationMatrix: string[][];
  kpiMatrix: MatrixElementData[][][];
  kpiMatrixHeaders: string[] = [];
  mdfData: number[][];

  companiesRoles: string[][][];
  partnershipVisionsInput: ExpandedData;

  getDescription() {
    return '';
  }

  getName() {
    return 'Channel Strategy Summary';
  }

  getGroup() {
    return 'Outputs';
  }

  init() {
    super.init();
    this.setOrientationPrintLayout('landscape');

    this.contentData = this.data.data
      .template_params_json as ChannelStrategySummaryTemplateData['template_params_json'];

    this.companies = this.initCompanies();
    this.demandGeneration$ =
      this.contentData.demand_generation_id &&
      this.initFormBuilderBlock(this.contentData.demand_generation_id);

    this.dealRegistration$ =
      this.contentData.deal_registration_rules_id &&
      this.initFormBuilderBlock(
        this.contentData.deal_registration_rules_id,
        true
      );

    this.enablingTechnology$ =
      this.contentData.channel_enablement_id &&
      this.contentData.channel_enablement_br_sufix &&
      this.initFormBuilderBlock(
        this.contentData.channel_enablement_id,
        false,
        this.contentData.channel_enablement_br_sufix
      );

    this.contentData.key_stackholders_sufix &&
      this.initKeyStackholders(this.contentData.key_stackholders_sufix);

    this.customDealRegistrationRules =
      this.contentData.deal_registration_custom_rules_sufix &&
      this.initCustomDealRegistrationRules(
        this.contentData.deal_registration_custom_rules_sufix
      );

    this.kpiMatrix =
      this.contentData.kpi_matrix_sufix &&
      this.initKPIMatrix(this.contentData.kpi_matrix_sufix);

    this.mdfData =
      this.contentData.mdf_sufix &&
      this.initMDFData(this.contentData.mdf_sufix);

    this.partnerMarketingPlan$ =
      this.contentData.partner_marketing_plan_id &&
      this.contentData.partner_marketing_plan_suffix &&
      this.contentData.partner_marketing_plan_fb_id &&
      this.initPartnerMarketingPlan(
        this.contentData.partner_marketing_plan_id,
        this.contentData.partner_marketing_plan_suffix,
        this.contentData.partner_marketing_plan_fb_id,
        this.contentData.partner_marketing_plan_fb_suffix
      );

    this.leadManagement$ =
      this.contentData.lead_management_id &&
      this.contentData.lead_management_suffix &&
      this.contentData.lead_management_fb_id &&
      this.initPartnerMarketingPlan(
        this.contentData.lead_management_id,
        this.contentData.lead_management_suffix,
        this.contentData.lead_management_fb_id,
        this.contentData.lead_management_fb_suffix
      );
  }

  initCompaniesRoles(): string[][][] {
    const reg =
      this.contentData.company_roles_sufix &&
      new RegExp(`${this.contentData.company_roles_sufix}_[\\d]+$`);

    return (
      reg &&
      Object.keys(this.inputs)
        .filter(key => key.match(reg))
        .map((key, i) => {
          const input = this.inputs[key];
          const content =
            input && (JSON.parse(input.content) as MatrixElement[][]);
          const usData =
            content &&
            content[0].filter(role => role.data).map(role => role.title);
          const theirData =
            content &&
            content[1].filter(role => role.data).map(role => role.title);

          return [usData, theirData];
        })
    );
  }

  initCompanies(): Company[] {
    const companiesInput =
      this.contentData.companies_sufix &&
      this.inputs[
        Object.keys(this.inputs).find(key =>
          key.endsWith(this.contentData.companies_sufix)
        )
      ];

    const companiesData =
      companiesInput.content &&
      (JSON.parse(companiesInput.content) as MatrixElement[]);
    this.companiesRoles = this.initCompaniesRoles();
    this.partnershipVisionsInput = this.initPartnershipVisionsData();

    return companiesData
      .map(company => {
        let visionIndex;
        if (this.partnershipVisionsInput) {
          this.partnershipVisionsInput[
            Object.keys(this.partnershipVisionsInput)[0]
          ].forEach((expandedCompany, index) => {
            if (expandedCompany.value === company[0]?.data) {
              visionIndex = index;
            }
          });
        }
        const vision =
          visionIndex !== undefined &&
          this.partnershipVisionsInput[
            Object.keys(this.partnershipVisionsInput)[1]
          ][visionIndex].value;
        const correctName = company[1]?.data.replace(/(<([^>]+)>)/gi, '');

        return {
          name: company[0]?.data,
          model: correctName,
          roles: this.getCompanyRoles(correctName),
          vision
        };
      })
      .filter(company => company.name?.length);
  }

  initPartnershipVisionsData() {
    const visionsInput =
      this.contentData.partnership_visions_sufix &&
      this.inputs[
        Object.keys(this.inputs).find(key =>
          key.endsWith(this.contentData.partnership_visions_sufix)
        )
      ];

    return (
      visionsInput.content && (JSON.parse(visionsInput.content) as ExpandedData)
    );
  }

  getCompanyRoles(model: string): string[] {
    const modelIndex = model && COMPANIES_MODELS.indexOf(model.trim());

    return (
      this.companiesRoles[modelIndex] && [
        this.companiesRoles[modelIndex][0].join(', '),
        this.companiesRoles[modelIndex][1].join(', ')
      ]
    );
  }

  initFormBuilderBlock(
    step_id: number,
    cleanEmpty = false,
    blockRepeaterSuffix: string = null
  ): Observable<FormBuilderData[]> {
    return this.moduleContentService
      .load(this.data.data.module_id, step_id, this.data.data.org_id, true)
      .pipe(
        take(1),
        switchMap(resp => {
          const suffix =
            blockRepeaterSuffix || resp.template_params_json.input_sufix;
          const params = resp.template_params_json as BrainstormTemplateParams;
          const questions = params.questions;
          const inputsKeys =
            suffix &&
            Object.keys(this.inputs).filter(key => key.endsWith(suffix));
          let inputsContent;

          if (!blockRepeaterSuffix) {
            inputsContent = inputsKeys.map(key => this.inputs[key].content);
          } else {
            const brContent = this.initBlockRepeaterContent(
              blockRepeaterSuffix
            );
            inputsContent = [];
            brContent?.blocks.forEach(brInput => {
              Object.values(brInput.data).forEach(inputs => {
                const companyData = Object.values(inputs).reduce(
                  (acc, input) => {
                    acc.push(input.content);

                    return acc;
                  },
                  []
                );
                inputsContent.push(companyData);
              });
            });
          }

          return questions.map((q, i) => {
            const content = blockRepeaterSuffix
              ? inputsContent.map(companyData => companyData[i])
              : inputsContent[i];

            return {
              question: q.question,
              content
            };
          });
        }),
        scan(
          (acc, q: FormBuilderData) =>
            cleanEmpty ? (q.content ? [...acc, q] : acc) : [...acc, q],
          []
        )
      );
  }

  initKeyStackholders(sufix: string) {
    const brContent = this.initBlockRepeaterContent(sufix);

    return (
      brContent &&
      brContent.blocks.map(brInput => {
        const inputObjectMain = Object.values(brInput.data)[0];
        const inputObjectFooter = Object.values(brInput.data)[1];
        const inputMain = inputObjectMain && Object.values(inputObjectMain)[0];
        const inputFooter =
          inputObjectFooter && Object.values(inputObjectFooter)[0];
        const matrixRowsMain: MatrixElement[][] = JSON.parse(
          inputMain?.content || null
        );
        const matrixRowsFooter: MatrixElement[][] = JSON.parse(
          inputFooter?.content || null
        );

        const main =
          matrixRowsMain?.reduce((accRow: MatrixElementData[][], matrixRow) => {
            const row = matrixRow.reduce(
              (accItem: MatrixElementData[], matrixItem) => {
                if (
                  this.keyStackholdersMainHeaders.indexOf(matrixItem.title) ===
                  -1
                ) {
                  this.keyStackholdersMainHeaders.push(matrixItem.title);
                }
                accItem.push(matrixItem.data);

                return accItem;
              },
              []
            );
            accRow.push(row);

            return accRow;
          }, []) || [];
        this.keyStackholdersMain.push(main);

        matrixRowsFooter?.forEach(matrixRow => {
          matrixRow.forEach(matrixItem => {
            if (!this.keyStackholdersFooterHeaders) {
              this.keyStackholdersFooterHeaders = matrixItem.title;
            }
            this.keyStackholdersFooter.push(matrixItem.data);
          });
        });
      })
    );
  }

  initCustomDealRegistrationRules(sufix: string): FormBuilderData[] {
    const rulesInput = this.inputs[
      Object.keys(this.inputs).find(key => key.endsWith(sufix))
    ];

    const rulesData =
      rulesInput.content && (JSON.parse(rulesInput.content) as MatrixElement[]);

    return (
      rulesData
        ?.map(rule => ({
          question: rule[0].data,
          content: rule[1].data
        }))
        .filter(rule => rule.content.length) || []
    );
  }

  initKPIMatrix(sufix: string): MatrixElementData[][][] {
    const brContent = this.initBlockRepeaterContent(sufix);

    return (
      brContent?.blocks &&
      brContent.blocks.map(brInput => {
        const inputObject = Object.values(brInput.data)[0];
        const input = inputObject && Object.values(inputObject)[0];
        const matrixRows: MatrixElement[][] = JSON.parse(
          input?.content || null
        );

        return matrixRows.reduce((accRow: MatrixElementData[][], matrixRow) => {
          const row = matrixRow.reduce(
            (accItem: MatrixElementData[], matrixItem) => {
              if (this.kpiMatrixHeaders.indexOf(matrixItem.title) === -1) {
                this.kpiMatrixHeaders.push(matrixItem.title);
              }
              accItem.push(matrixItem.data);

              return accItem;
            },
            []
          );
          accRow.push(row);

          return accRow;
        }, []);
      })
    );
  }

  initMDFData(sufix: string): number[][] {
    const brContent = this.initBlockRepeaterContent(sufix);
    const MDFData = [];
    brContent &&
      brContent.blocks.forEach(brInput => {
        let result;
        Object.values(brInput.data).forEach(
          stepInput =>
            (result = Object.values(stepInput).map(input =>
              input.content !== 'NaN' ? input.content : 0
            ))
        );
        MDFData.push(result);
      });

    return MDFData;
  }

  initBlockRepeaterContent(sufix: string): BlockRepeaterContent {
    const brInput = this.inputs[
      Object.keys(this.inputs).find(key => key.endsWith(sufix))
    ];

    if (!brInput) {
      return;
    }

    return brInput
      ? (updateBlockRepeaterFormat(
          JSON.parse(brInput.content)
        ) as BlockRepeaterContent)
      : null;
  }

  initPartnerMarketingPlan(
    stepId: number,
    brSuffix: string,
    formBuilderId: string,
    formBuilderSuffix: string
  ): Observable<Map<string, FormBuilderData[]>> {
    return this.moduleContentService
      .load(this.data.data.module_id, stepId, this.data.data.org_id, true)
      .pipe(
        take(1),
        switchMap(resp => {
          const marketingPlanData = new Map<string, FormBuilderData[]>();
          const INPUT_KEY = Object.keys(resp?.inputs).find(key =>
            key.endsWith(brSuffix)
          );
          const input: BlockRepeaterContent = JSON.parse(
            resp?.inputs?.[INPUT_KEY]?.content || 'null'
          );

          for (const [index, block] of Object.entries(input?.blocks || [])) {
            const inputKeys = Object.keys(
              block.data[formBuilderId]
            ).filter(key => key.endsWith(formBuilderSuffix));

            for (const inputKey of inputKeys) {
              const dataBefore = marketingPlanData.get(index) || [];
              const data: FormBuilderData = {
                content: block.data[formBuilderId][inputKey].content,
                question: block.data[formBuilderId][inputKey].content
              };
              marketingPlanData.set(index, [...dataBefore, data]);
            }
          }

          return of(marketingPlanData);
        })
      );
  }

  toggleCurrentSlide(index: number) {
    this.currentSlide = index;
  }
}
