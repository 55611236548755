import { TemplateContentDataBase } from '../template.interface';
import {
  ChartData,
  ChartSettings
} from '../../../../common/interfaces/charts.interface';
import { websafeColors } from '../campaign-calendar-template/campaign-calendar/websafe-colors';

export interface ChartDataTemplateData extends TemplateContentDataBase {
  template_params_json: {
    title: string;
    description: string;
    input_sufix: string;
    graph_width: number;
    graph_height: number;
    x_label: string;
    y_label: string;
    x_scale_min: number;
    x_scale_max: number;
    y_scale_min: number;
    y_scale_max: number;
    show_legend: boolean;
    legend_position_select: 'right' | 'below';
    divide_by_quadrants: boolean;
    background_content: string;
    options: Array<{
      linked_input_name: string;
      input_x_value_path: string;
      input_y_value_path: string;
      grid_item_name_path: string;
    }>;
  };
}

export type ChartDataTemplateParams = ChartDataTemplateData['template_params_json'];

export interface ChartDataContent {
  data: ChartData[];
}

export const START_CHART_SETTINGS: Readonly<ChartSettings> = {
  showXAxis: true,
  showYAxis: true,
  showLegend: false,
  legendPosition: 'below',
  colorScheme: { domain: [...websafeColors] },
  showXAxisLabel: true,
  xAxisLabel: 'X',
  showYAxisLabel: true,
  yAxisLabel: 'Y',
  minRadius: 10,
  maxRadius: 10,
  showGridLines: false
};
