import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  Module,
  Organization
} from 'src/app/common/interfaces/module.interface';
import { ModuleService } from 'src/app/common/services/module.service';

@Component({
  selector: 'app-toggle-module',
  templateUrl: './toggle-module.component.html',
  styleUrls: ['./toggle-module.component.scss']
})
export class ToggleModuleComponent implements OnInit {
  @Input() module: Module;
  @Input() organization: Organization;
  @Input() showCompletedCheck = true;

  @Output() moduleChange = new EventEmitter<Module>(false);

  moduleID: string;

  constructor(private moduleService: ModuleService) {}

  ngOnInit() {
    this.moduleID = this.module.id.toString();
  }

  toggleModuleStatus(module: Module) {
    const status = module.status ? !module.status.is_activated : true;

    this.moduleService
      .setStatus(module, status, this.organization.id)
      .subscribe(newStatus => {
        module.status = { ...newStatus, sprint_id: newStatus?.sprint_id ?? 0 };
        this.moduleChange.emit(module);
      });
  }
}
