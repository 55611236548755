import { Injectable } from '@angular/core';
import User from '../interfaces/user.model';
import {
  AccountProfile,
  UpdatePassword,
  PresignedFileUrl
} from '../interfaces/account.interface';
import { Observable, BehaviorSubject, of, interval, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { tap, switchMap, catchError, finalize } from 'rxjs/operators';
import { SessionExpirationModalComponent } from '../components/session-expiration-modal/session-expiration-modal.component';
import { MatDialog } from '@angular/material/dialog';

type AccountProfileStatus = AccountProfile & { status: string };

@Injectable()
export class UserService {
  get me(): User {
    return this.meChanged.getValue();
  }
  set me(_value: User) {
    this.meChanged.next(_value);
  }

  meChanged: BehaviorSubject<User> = new BehaviorSubject(null);

  accountBaseUrl = environment.apiRoot + '/api/account';

  accountSessionRemainingTimeUrl = environment.apiRoot + '/timeout';

  legacyBaseUrl = environment.apiRoot;

  sessionSecondsTimeLeft = 120;

  checkSessionTimeLeftInterval = 60000;

  intervalSubscriptionId: Subscription;

  isSessionPopupOpen = false;

  constructor(
    private httpClient: HttpClient,
    private modalService: MatDialog
  ) {}

  setMeFromData(data: any) {
    this.me = User.fromObject<User>(data);
  }

  redirectToLoginPage() {
    window.location.href = `${
      environment.apiRoot
    }/api/account/external-login?redirect=${btoa(window.location.origin)}`;
  }

  getAccount(): Observable<AccountProfileStatus> {
    return this.httpClient
      .get<AccountProfileStatus>(`${environment.apiRoot}/user/me`)
      .pipe(
        catchError(() => {
          if (!environment.apiRoot.includes('local.seagage.com')) {
            this.redirectToLoginPage();
          }

          return of(null);
        })
      );
  }

  getFundId() {
    return this.httpClient.get<User>(`${this.accountBaseUrl}/me`);
  }

  startCheckingSessionTime() {
    if (this.intervalSubscriptionId) {
      this.intervalSubscriptionId.unsubscribe();
    }

    this.intervalSubscriptionId = this.getAccount()
      .pipe(
        catchError(err => of(err)),
        switchMap(account =>
          !account ? of(null) : interval(this.checkSessionTimeLeftInterval)
        )
      )
      .subscribe(id => {
        if (id) {
          this.checkTimeLeft();
        }

        return id;
      });
  }

  signin(credentials: FormData): Observable<boolean> {
    return this.httpClient
      .post<boolean>(`${this.legacyBaseUrl}/signin/`, credentials)
      .pipe(
        tap(res => {
          if (res) {
            this.isSessionPopupOpen = false;
            this.startCheckingSessionTime();
          }
        })
      );
  }

  signout(): Observable<AccountProfileStatus> {
    if (this.intervalSubscriptionId) {
      this.intervalSubscriptionId.unsubscribe();
    }

    return this.httpClient
      .get(`${this.legacyBaseUrl}/signout?legacy_no_redirect=true`)
      .pipe(
        tap(() => this.setMeFromData(null)),
        switchMap(() => this.getAccount())
      );
  }

  saveAccount(account: AccountProfile): Observable<null> {
    return this.httpClient.post<null>(`${this.accountBaseUrl}/me`, account);
  }

  updatePassword(data: UpdatePassword): Observable<null> {
    return this.httpClient.post<null>(
      `${this.accountBaseUrl}/me/password`,
      data
    );
  }

  presignedProfilePictureUpload(ext: string): Observable<PresignedFileUrl> {
    return this.httpClient.get<null>(
      `${this.accountBaseUrl}/me/upload-picture`,
      {
        params: { ext }
      }
    );
  }

  showTimeLeftModal(timer: Date) {
    if (!this.isSessionPopupOpen) {
      const modalRef = this.modalService.open(SessionExpirationModalComponent);
      this.isSessionPopupOpen = true;
      modalRef.afterClosed().subscribe(result => {
        if (!result) {
          this.intervalSubscriptionId.unsubscribe();
          this.signout()
            .pipe(finalize(() => this.redirectToLoginPage()))
            .subscribe();
        } else {
          this.isSessionPopupOpen = false;
          this.intervalSubscriptionId = this.getAccount()
            .pipe(switchMap(() => interval(this.checkSessionTimeLeftInterval)))
            .subscribe(id => {
              this.checkTimeLeft();
            });
        }
      });

      modalRef.componentInstance.timer = timer;
    } else {
      this.intervalSubscriptionId.unsubscribe();
    }
  }

  checkTimeLeft() {
    return this.httpClient.get(this.accountSessionRemainingTimeUrl).subscribe(
      (response: { timeleft: number }) => {
        if (response) {
          const timeLeft = +response.timeleft;
          if (timeLeft && timeLeft <= this.sessionSecondsTimeLeft) {
            const minutes = timeLeft / 60;
            const seconds = timeLeft % 60;
            this.showTimeLeftModal(new Date(1, 1, 1, 1, minutes, seconds));
          }
        }
      },
      () => this.redirectToLoginPage()
    );
  }
}
