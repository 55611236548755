import { Component, forwardRef } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Validate, Validation } from 'src/app/common/validator.class';

import {
  BrainstormFieldChange,
  BrainstormQuestion,
  BrainstormTemplateParams
} from '.';
import txt from '!!raw-loader!./index.ts';
import { TemplateInput } from '../../../../common/interfaces/module.interface';
import { MatSelect } from '@angular/material/select';
import { filter, switchMap, tap } from 'rxjs/operators';
import { getNestedValue, isEmptyValue } from '../../../../common/utils/helpers';
import { InputApiDataDictionary } from '../../../../common/interfaces/api-data.interface';
import { BuyerPersona } from '../../../../common/interfaces/buyer-persona.interface';

@Component({
  selector: 'app-brainstorm',
  templateUrl: './brainstorm.component.html',
  styleUrls: ['./brainstorm.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => BrainstormTemplateComponent)
    }
  ]
})
export class BrainstormTemplateComponent extends TemplateComponent {
  params = txt;
  contentData: BrainstormTemplateParams;

  inputInstances: TemplateInput[] = [];
  suffix: string;
  formulaParams: number[][] = [];
  inputValidators: { [key: string]: Validation[] };
  questions: BrainstormQuestion[];
  inputApiData: InputApiDataDictionary = {};
  buyerPersonas: BuyerPersona[];

  private onFieldValueChange: BehaviorSubject<
    BrainstormFieldChange
  > = new BehaviorSubject<BrainstormFieldChange>(null);

  getDescription() {
    return '';
  }

  getName() {
    return 'Form Builder';
  }

  getGroup(): string {
    return 'Generic';
  }

  init() {
    this.contentData = this.data.data
      .template_params_json as BrainstormTemplateParams;

    this.suffix = this.contentData.input_sufix
      ? `_${this.contentData.input_sufix}`
      : '';
    this.prepareAdditionalRequests().subscribe(() => this.prepareQuestions());
    this.inputValidators = this.getValidators();
  }

  validate() {
    return of(
      Object.keys(this.inputValidators).reduce(
        (isValid, key) =>
          this.validateInput(this.getInput(key), this.inputValidators[key]) &&
          isValid,
        true
      )
    );
  }

  questionTrack(idx: number, q: BrainstormQuestion) {
    return idx;
  }

  updateData($event): void {
    const { index, event } = $event;
    const input = this.getInput(this.inputInstances[index].element_key);
    const currentContent = input.content;

    if (event.target) {
      input.content = this.isDate(event.target.value)
        ? event.target.value.toISOString()
        : event.target.value
        ? event.target.value
        : '';
    }

    if (event.source instanceof MatSelect) {
      input.content = event.value;
    }

    if (typeof event === 'string') {
      input.content = event;
    }
    if (currentContent !== input.content || event.source instanceof MatSelect) {
      this.contentChanged(
        input,
        this.inputValidators[this.inputInstances[index].element_key]
      );
      this.onFieldValueChange.next({
        inputId: this.inputInstances[index].id,
        inputName: this.inputInstances[index].element_key,
        value: input.content
      });
    }
  }

  isDate(input): boolean {
    return Object.prototype.toString.call(input) === '[object Date]';
  }

  private prepareFormulaValuesFromInputs(i: number): void {
    const inputsForFormula = this.contentData.questions[
      i - 1
    ]?.inputs_for_formula.map(input => this.getInput(input.key));
    this.formulaParams[i - 1] = inputsForFormula.map(input =>
      Number(input.getValue())
    );

    const updateFormulaParams = (
      inputId: number,
      fieldId: number,
      value: string
    ) => {
      const index = inputsForFormula.findIndex(input => input.id === inputId);
      if (
        index !== -1 &&
        this.formulaParams &&
        this.formulaParams[fieldId - 1] &&
        this.formulaParams[fieldId - 1][index] !== Number(value)
      ) {
        inputsForFormula[index].content = value;
        this.formulaParams[fieldId - 1] = inputsForFormula.map(input =>
          Number(input.getValue())
        );
      }
    };

    this.onFieldValueChange
      .pipe(this.whileExists(), filter(Boolean))
      .subscribe((item: BrainstormFieldChange) => {
        updateFormulaParams(Number(item.inputId), i, item.value);
        this.changeDetectorRef.detectChanges();
      });
    this.moduleService.stepInputChanged$
      .pipe(this.whileExists(), filter(Boolean))
      .subscribe((data: TemplateInput) =>
        updateFormulaParams(Number(data.id), i, data.content)
      );
  }

  private getValidators(): { [key: string]: Validation[] } {
    const requiredValidator = Validate.required('Please fill out this field');

    return this.inputInstances.reduce(
      (accum: { [key: string]: Validation[] }, input, index) => {
        const question = this.contentData.questions[index];
        accum[input.element_key] = question.required
          ? [requiredValidator]
          : null;

        return accum;
      },
      {}
    );
  }

  private prepareQuestions(): void {
    const questions = this.contentData.questions ?? [];
    const inputsKeys = Object.keys(this.data.data.inputs);
    this.questions = questions.reduce(
      (accum: BrainstormQuestion[], question, i) => {
        const strQuery = `brainstorm_${i + 1}${this.suffix}`;
        const typeSelect = question?.type_select
          ? question?.type_select
          : 'Textarea';
        const modQuestion = { ...question, type_select: typeSelect, index: i };
        if (typeSelect !== 'Textarea' && this.inputs[strQuery].content) {
          this.inputs[strQuery].content = this.inputs[strQuery].content.replace(
            /<[^>]+>/gm,
            ''
          );
        }
        if (typeSelect === 'Formula' && modQuestion?.inputs_for_formula) {
          this.prepareFormulaValuesFromInputs(i + 1);
        }
        this.prepareDefaultDataForQuestion(modQuestion, strQuery);
        if (inputsKeys.indexOf(strQuery) !== -1) {
          this.inputInstances.push(this.getInput(strQuery));
        }
        accum.push(modQuestion);

        return accum;
      },
      []
    );
  }

  private prepareDefaultDataForQuestion(
    question: BrainstormQuestion,
    inputName: string
  ): void {
    if (question.type_select === 'Date') {
      if (this.inputs[inputName].content) {
        const date = new Date(this.inputs[inputName].content);
        this.inputs[inputName].content =
          date.toDateString() !== 'Invalid Date' ? date.toDateString() : '';
      }
    } else {
      const getDefaultValue = (quest: BrainstormQuestion) => {
        const optionReplacer = (match, optionKey) => {
          let optionValue = this.data.data.options[optionKey] ?? '';
          if (optionKey === 'tabIndex+1') {
            optionValue = String(Number(optionValue) + 1);
          }

          return String(optionValue);
        };
        let defaultValue: string = quest.default_value;
        if (quest?.use_value_from_input_as_default && quest?.inputs?.length) {
          defaultValue = quest.inputs
            .reduce((values: string[], i) => {
              if (i?.input_key) {
                const inputContent = i?.load_input_endpoint
                  ? this.inputApiData[i.input_key]
                  : this.inputs[i.input_key]?.content;
                let nestedValue =
                  getNestedValue(
                    inputContent,
                    i?.value_path?.replace(/\${(\w+)}/g, optionReplacer)
                  ) || '';
                if (nestedValue && Array.isArray(nestedValue)) {
                  nestedValue = i?.convert_value_to_buyer_personas
                    ? this.getPersonaNamesFromArray(nestedValue)
                    : nestedValue.filter(Boolean).join(', ');
                }
                values.push(String(nestedValue));
              }

              return values;
            }, [])
            .filter(Boolean)
            .join(`${quest?.inputs_separator ?? ', '}`);
        }
        defaultValue = this.contentPipe
          .transform(defaultValue, false)
          ?.replace(/\${(\w+)}/g, optionReplacer);
        if (quest.type_select === 'Inline') {
          defaultValue = defaultValue?.replace(/(<([^>]+)>)/gi, '');
        } else if (quest.type_select === 'Numbers') {
          defaultValue = parseInt(
            this.textContent(defaultValue),
            10
          ).toString();
        }

        return defaultValue;
      };
      const updateToDefaultValue =
        question.update_to_default_value_everytime &&
        this.contentData.read_only;
      if (
        isEmptyValue(this.inputs[inputName].content) ||
        updateToDefaultValue
      ) {
        const defaultValue = getDefaultValue(question);
        this.inputs[inputName].content = defaultValue || '';

        this.contentChanged(this.getInput(inputName));
      }
    }
  }

  private getPersonaNamesFromArray(
    uuidArray: string[],
    separator = ', '
  ): string {
    const uuids = uuidArray?.length ? uuidArray : [];

    return (
      this.buyerPersonas
        ?.filter(persona => uuids.includes(persona.uuid) && !!persona.name)
        .map(persona => persona.name)
        .join(separator) || ''
    );
  }

  private prepareAdditionalRequests(): Observable<void> {
    return of(null).pipe(
      switchMap(() => this.prepareInputData()),
      switchMap(() => this.prepareBuyerPersonas()),
      switchMap(() => of(null))
    );
  }

  private prepareInputData(): Observable<void> {
    const inputInfo = Object.values(
      this.contentData.questions?.reduce(
        (
          accum: {
            [inputKey: string]: {
              inputKey: string;
              moduleId: number;
              clean: boolean;
            };
          },
          field
        ) => {
          if (
            field.use_default_value_as_buyer_personas &&
            field.inputs?.length
          ) {
            field?.inputs?.forEach(i => {
              if (
                i?.load_input_endpoint &&
                i?.input_key &&
                i?.module_id &&
                !accum[i?.input_key]
              ) {
                accum[i.input_key] = {
                  inputKey: i.input_key,
                  moduleId: i.module_id,
                  clean: !!field.type_select && field.type_select !== 'Textarea'
                };
              }
            });
          }

          return accum;
        },
        {}
      )
    );

    return this.navService.organization$.pipe(
      switchMap(orgId =>
        this.moduleService.getMultipleInputValues(orgId, inputInfo)
      ),
      tap(data => (this.inputApiData = data)),
      switchMap(() => of(null))
    );
  }

  private prepareBuyerPersonas(): Observable<void> {
    const shouldLoadPersonas = this.contentData.questions.some(q =>
      q?.inputs?.some(i => i?.convert_value_to_buyer_personas)
    );

    return shouldLoadPersonas
      ? this.buyerPersonasList$.pipe(
          tap(personas => (this.buyerPersonas = personas)),
          switchMap(() => of(null))
        )
      : of(null);
  }
}
