import { Component, forwardRef } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import {
  DESCRIPTION_FIELDS,
  DescriptionField,
  IconsEnum,
  PersonaField,
  PersonaRolesTemplateData,
  PersonasDescriptionsFields
} from '.';
import txt from '!!raw-loader!./index.ts';
import { from, of, Subscription } from 'rxjs';
import { filter, isEmpty, mergeMap, take, tap } from 'rxjs/operators';
import { BuyerPersona } from '../../../../common/interfaces/buyer-persona.interface';
import { TemplateInput } from '../../../../common/interfaces/module.interface';
import { v4 as uuid4 } from 'uuid';
import { Validation } from '../../../../common/validator.class';
import {
  DeprecatedMatrixData,
  MatrixData
} from '../participation-level-activity';

@Component({
  selector: 'persona-roles',
  templateUrl: 'persona-roles.component.html',
  styleUrls: ['./persona-roles.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => PersonaRolesComponent)
    }
  ]
})
export class PersonaRolesComponent extends TemplateComponent {
  params = txt;

  contentData: PersonaRolesTemplateData['template_params_json'];

  personasDescriptionsFields: PersonasDescriptionsFields = {
    personas: {},
    descriptions: {}
  };
  personasFields: PersonaField = {};
  descriptionFields = DESCRIPTION_FIELDS;
  hasPersonas = true;
  isStepLoaded = false;

  inputInstance: TemplateInput;

  icons = IconsEnum;

  personasToProcess: DeprecatedMatrixData | MatrixData[];

  isLoading: Subscription;

  private inputValidator: Validation[];

  getDescription() {
    return `
      <p>This template provides the capability to describe roles for buyer personas.</p>
      <p>It needs to be linked with a roles selector step to use its data.</p>
      <p>Also, it requires a suffix of an input of roles selector step (the "Linked Step Suffix" field) and its line
      number (the "Line Number To Process" field) to process.</p>
      <p>The template uses one input to store data.</p>
    `;
  }

  getName() {
    return 'Persona Roles';
  }

  getGroup(): string {
    return 'Module';
  }

  init() {
    this.contentData = this.data.data
      .template_params_json as PersonaRolesTemplateData['template_params_json'];

    this.personasToProcess = this.getPersonasToProcess();

    if (this.personasToProcess) {
      this.inputInstance = this.getInput(
        this.data.data.template_component,
        1,
        '',
        this.contentData.input_sufix
      );

      this.initPersonasDescriptionsFields();
      let i = 0;
      this.isLoading = this.buyerPersonasList$
        .pipe(
          take(1),
          mergeMap((list: BuyerPersona[]) => from(list)),
          tap((persona: BuyerPersona) => {
            let personaToProcess;

            // eslint-disable-next-line
            if (
              Object.values(this.personasToProcess).some(
                role => typeof role === 'string'
              )
            ) {
              if (this.personasToProcess[i]) {
                personaToProcess = this.personasToProcess[i];
              }
            } else {
              personaToProcess = (this.personasToProcess as MatrixData[]).find(
                p => p.index === persona.index
              )?.role;
            }

            if (
              personaToProcess !== undefined &&
              personaToProcess !== 'Not_involved' &&
              personaToProcess !== null
            ) {
              this.personasFields[`${persona.index - 1}`] = {
                personaRole: personaToProcess,
                personaRef: persona
              };
            }
            i++;
          })
        )
        .subscribe(() => {
          this.hasPersonas =
            !!Object.keys(this.personasFields).length &&
            !!Object.keys(this.personasToProcess).length;
          this.personasDescriptionsFields.personas = this.personasFields;
          this.isStepLoaded = true;
        });
    } else {
      this.isStepLoaded = true;
      this.hasPersonas = false;
    }
  }

  clearEmptyEntries() {
    Object.entries(this.personasDescriptionsFields.descriptions).forEach(
      ([key, value]) => {
        this.personasDescriptionsFields.descriptions[key] = Object.values(
          value
        ).reduce((acc, cur) => (cur.value.trim() ? [...acc, cur] : acc), []);
      }
    );
    this.changeInputContent();
  }

  validateDescriptionsFunc(): boolean {
    const emptyDescriptions = Object.values(
      this.personasDescriptionsFields.descriptions
    ).filter(d => d.length);

    const isValid =
      Object.values(this.personasDescriptionsFields.descriptions).length ===
      emptyDescriptions.length;
    if (!isValid) {
      this.setValidationError({
        type: 'emptyDescriptions',
        message: `Please add at least one entry in each section`
      });
    } else {
      this.removeValidationError('emptyDescriptions');
    }

    return isValid;
  }

  validate() {
    this.clearEmptyEntries();

    return of(this.validateDescriptionsFunc()).pipe(
      filter(isValid => !isValid),
      isEmpty()
    );
  }

  getPersonasToProcess() {
    const linkedInput = this.getInput(
      'participation_level_activity',
      this.contentData.line_number_to_process,
      '',
      this.contentData.linked_step_suffix || ''
    );

    return (
      linkedInput && linkedInput.content && JSON.parse(linkedInput.content)
    );
  }

  initPersonasDescriptionsFields(): void {
    if (this.inputInstance.content) {
      this.personasDescriptionsFields = JSON.parse(this.inputInstance.content);
    } else {
      this.personasDescriptionsFields.descriptions = this.descriptionFields.reduce(
        (accum, description: DescriptionField) => ({
          ...accum,
          [description.jsonName]: []
        }),
        {}
      );
    }
  }

  updateInputs(input): void {
    if (input.value.length > 100) {
      input.value = input.value.substring(0, 99);
      this.snackBarService.error('Characters limit is 100');
    }

    this.changeInputContent();
  }

  removeInput(id: string, descriptionName: string): void {
    this.confirmationService
      .removeDialog({
        text: 'this input field'
      })
      .subscribe(() => {
        this.personasDescriptionsFields.descriptions[
          descriptionName
        ] = this.personasDescriptionsFields.descriptions[
          descriptionName
        ].filter(input => input.id !== id);

        this.changeInputContent();
      });
  }

  addInput(descriptionName: string): void {
    this.personasDescriptionsFields.descriptions[descriptionName].push({
      id: uuid4(),
      value: ''
    });
  }

  protected changeInputContent() {
    if (
      this.inputInstance.content !==
      JSON.stringify(this.personasDescriptionsFields)
    ) {
      this.inputInstance.content = JSON.stringify(
        this.personasDescriptionsFields
      );
      this.contentChanged(this.inputInstance);
    }
  }
}
