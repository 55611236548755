import { Component, forwardRef } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import { VennDiagramItem, CompetitionComparisonTemplateData } from '.';
import txt from '!!raw-loader!./index.ts';
import { TemplateInput } from '../../../../common/interfaces/module.interface';
import { ModuleService } from '../../../../common/services/module.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Observable, of } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { BlockRepeaterContent } from '../block-repeater';
import { updateBlockRepeaterFormat } from '../block-repeater/helpers';
import { v4 as uuid4 } from 'uuid';

@Component({
  selector: 'competition-comparison',
  templateUrl: 'competition-comparison.component.html',
  styleUrls: ['./competition-comparison.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => CompetitionComparisonComponent)
    }
  ]
})
export class CompetitionComparisonComponent extends TemplateComponent {
  params = txt;
  prefix = 'competition_comparison_1';
  contentData: CompetitionComparisonTemplateData['template_params_json'];

  faTrashedIcon = faTrash;

  inputKey: string;
  input: TemplateInput;
  competitors: VennDiagramItem[] = [];
  moduleService: ModuleService;
  modalService: MatDialog;
  route: ActivatedRoute;
  sanitizer: DomSanitizer;

  organizationName = '';
  isAddCompetitorFormVisible = false;
  rows = 15;

  getDescription() {
    return 'Module';
  }

  getName() {
    return 'Competition Comparison';
  }

  init() {
    this.contentData = this.data.data
      .template_params_json as CompetitionComparisonTemplateData['template_params_json'];
    this.inputKey = this.contentData.input_sufix
      ? `${this.prefix}_${this.contentData.input_sufix}`
      : this.prefix;
    this.input = this.inputs[this.inputKey];

    this.moduleService = this.injectorObj.get(ModuleService);
    this.route = this.injectorObj.get(ActivatedRoute);
    this.modalService = this.injectorObj.get(MatDialog);
    this.sanitizer = this.injectorObj.get(DomSanitizer);

    this.prepareOrganizationName();
    this.prepareCompetitors();
  }

  validate(): Observable<boolean> {
    return of(
      this.competitors.every(
        c =>
          c.textAboutCompetitor.length > 0 &&
          c.textAboutPortCo.length > 0 &&
          c.intersection.length > 0
      )
    );
  }

  addCompetitor(competitorName: string) {
    competitorName = competitorName.trim();

    if (!competitorName.length) {
      return this.snackBarService.error(`Competitor's name is required`);
    }

    if (competitorName.length > 100) {
      competitorName = competitorName.substring(0, 100);
    }

    if (this.competitors.find(c => c.name === competitorName)) {
      return this.snackBarService.error(
        `Competitor "${competitorName}" has already been added`
      );
    }

    this.competitors.push({
      id: `competition-comparison-${uuid4()}`,
      name: competitorName,
      textAboutCompetitor: '',
      textAboutPortCo: '',
      intersection: '',
      competitorPurchase: ['', '', '']
    });

    this.saveCompetitors();

    this.isAddCompetitorFormVisible = false;
  }

  removeCompetitor(index: number) {
    this.confirmationService
      .removeDialog({ text: `"${this.competitors[index].name}"` })
      .subscribe(
        result => {
          this.competitors.splice(index, 1);
          this.saveCompetitors();
        },
        () => {}
      );
  }

  saveCompetitors() {
    this.input.content = JSON.stringify(this.competitors);
    this.contentChanged(this.input);
  }

  getGroup(): string {
    return 'Generic';
  }

  changeTextareas() {
    this.saveCompetitors();
  }

  switchInput($event: KeyboardEvent, position: number) {
    const parentOfInput = ($event.target as HTMLInputElement).parentElement;
    const neededInput =
      position === 1
        ? parentOfInput.previousElementSibling?.firstElementChild
        : parentOfInput.nextElementSibling?.firstElementChild;

    if (neededInput instanceof HTMLInputElement) {
      neededInput.selectionStart = neededInput.value.length;
      neededInput.focus();
    }
  }

  private prepareOrganizationName(): void {
    this.moduleService.getOrganizations().subscribe(organizations => {
      const organization = organizations.find(
        o => +o.id === +this.route.snapshot.parent.params.orgId
      );
      this.organizationName = organization ? organization.name : 'ERROR!';
    });
  }

  private prepareCompetitors(): void {
    const content: VennDiagramItem[] = JSON.parse(this.input.content || null);
    if (this.contentData.default_data_items_from_block_repeater_input) {
      const defaultDataCompetitors: VennDiagramItem[] = this.getCompetitorsFromDefaultDataInput();
      this.competitors = defaultDataCompetitors.map(competitor => {
        const foundItem = content?.find(item => item.id === competitor.id);

        return foundItem ? { ...foundItem, name: competitor.name } : competitor;
      });
    } else if (content) {
      this.competitors = content;
    }
    this.competitors?.forEach(
      cItem =>
        (cItem.competitorPurchase =
          cItem.competitorPurchase && cItem.competitorPurchase.length > 0
            ? cItem.competitorPurchase
            : ['', '', ''])
    );
  }

  private getCompetitorsFromDefaultDataInput(): VennDiagramItem[] {
    const defaultDataInput: TemplateInput = this.contentData
      .default_data_items_from_block_repeater_input
      ? this.inputs[this.contentData.block_repeater_input_name]
      : null;
    const stepId = this.contentData.item_name_step_id;
    const inputName = this.contentData.item_name_step_input_name;
    const content: BlockRepeaterContent = updateBlockRepeaterFormat(
      JSON.parse(defaultDataInput?.content || null)
    );

    return (
      content?.blocks?.reduce((accum: VennDiagramItem[], block) => {
        const competitorName: string =
          this.textContent(block.data?.[stepId]?.[inputName]?.content) || '';
        const item: VennDiagramItem = {
          id: block.id,
          name: competitorName,
          textAboutCompetitor: '',
          textAboutPortCo: '',
          intersection: '',
          competitorPurchase: ['', '', '']
        };
        accum.push(item);

        return accum;
      }, []) || []
    );
  }
}
