import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatrixCheckbox } from '../index';

@Component({
  selector: 'app-matrix-checkboxes',
  templateUrl: './matrix-checkboxes.component.html',
  styleUrls: ['./matrix-checkboxes.component.scss']
})
export class MatrixCheckboxesComponent {
  @Input() checkboxes: MatrixCheckbox[] = [];
  @Input() disabled: boolean;
  @Output() checkboxChange: EventEmitter<MatrixCheckbox> = new EventEmitter<
    MatrixCheckbox
  >();

  constructor() {}

  onCheckboxClick(checkbox: MatrixCheckbox): void {
    checkbox.isChecked = !checkbox.isChecked;
    this.checkboxChange.emit(checkbox);
  }

  onOtherCheckChange(checkbox: MatrixCheckbox): void {
    this.checkboxChange.emit(checkbox);
  }
}
