import { Component, forwardRef, HostBinding } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import txt from '!!raw-loader!./index.ts';
import { RadiobuttonTemplateData, Item } from '.';
import { TemplateInput } from '../../../../common/interfaces/module.interface';
import { of } from 'rxjs';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => RadioButtonComponent)
    }
  ]
})
export class RadioButtonComponent extends TemplateComponent {
  items: Item[];
  prefix = 'radio_button_1_';
  params = txt;
  contentData: RadiobuttonTemplateData['template_params_json'];
  isMultipleRadioGroups = false;
  userChoice = 0;
  selectedChoiceClass = '';
  input: TemplateInput;

  @HostBinding('class') get stepSelect() {
    return this.contentData.step_select;
  }

  @HostBinding('class.horizontal') get isHorizontalView() {
    return this.contentData.direction_select === 'horizontal';
  }

  getDescription() {
    return 'Radio button';
  }

  getName() {
    return 'Radio button';
  }

  getGroup(): string {
    return 'Generic';
  }

  hasInputs(): boolean {
    return true;
  }

  validate() {
    return of(
      (this.contentData.require_selection &&
        ((this.isMultipleRadioGroups && this.items.every(i => !!i.choice)) ||
          (!this.isMultipleRadioGroups && !!this.userChoice))) ||
        !this.contentData.require_selection
    );
  }

  getValidationErrorMessage() {
    return this.contentData.require_selection
      ? this.isMultipleRadioGroups
        ? 'Please make selections for all questions in this step'
        : 'This step cannot be marked as done because of missing selection'
      : super.getValidationErrorMessage();
  }

  init() {
    this.contentData = this.data.data
      .template_params_json as RadiobuttonTemplateData['template_params_json'];
    this.input = this.getInput(this.contentData.input_sufix, null, this.prefix);
    this.isMultipleRadioGroups =
      this.contentData.step_select === 'TitleButtonsAndInput';

    if (this.isMultipleRadioGroups) {
      const content: Item[] = JSON.parse(this.input.content || '[]');
      this.items = this.contentData?.options?.map((o, index) => ({
        ...o,
        choice: content[index]?.choice,
        inputValue: content[index]?.inputValue
      }));
    } else {
      this.items = this.contentData.options;
      this.userChoice = Number(this.input.getValue());
      this.selectedChoiceClass = this.textContent(
        this.items[this.userChoice - 1]?.choice
      );
    }
  }

  onRadioChange() {
    this.input.content = this.isMultipleRadioGroups
      ? JSON.stringify(this.items)
      : String(this.userChoice);
    this.selectedChoiceClass = this.textContent(
      this.items[this.userChoice - 1]?.choice
    );
    if (this.contentData.update_module_steps_on_change) {
      this.saveInput(this.input).subscribe(() =>
        this.moduleService.reloadModule()
      );
    } else {
      this.contentChanged(this.input);
    }
  }
}
