import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { AssessmentService } from 'src/app/common/services/assessment.service';
import {
  Observable,
  BehaviorSubject,
  combineLatest,
  Subscription,
  of
} from 'rxjs';
import {
  AssessmentType,
  AssessmentGroup,
  AssessmentOrgGroup,
  AssessmentSession,
  PendingSessions
} from 'src/app/common/interfaces/assessment.interface';
import { ModuleNavService } from 'src/app/common/services/module-nav.service';
import {
  filter,
  take,
  distinctUntilChanged,
  switchMap,
  map,
  shareReplay,
  takeWhile,
  tap,
  skip,
  debounceTime,
  catchError
} from 'rxjs/operators';
import { Organization } from 'src/app/common/interfaces/module.interface';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { CanModifyPipe } from '../../../common/pipes/canModify.pipe';

@Component({
  selector: 'app-assessment-menu',
  templateUrl: './assessment-menu.component.html',
  styleUrls: ['./assessment-menu.component.scss']
})
export class AssessmentMenuComponent implements OnInit, OnDestroy {
  types$: Observable<AssessmentType[]>;
  groups$: Observable<AssessmentGroup[]>;
  allGroups$: Observable<AssessmentGroup[]>;
  orgGroups$: Observable<AssessmentOrgGroup[]>;
  activeType$: Observable<AssessmentType>;
  session$: Observable<AssessmentSession>;
  sessionRequest$: Observable<HttpResponse<AssessmentSession>>;
  orgObserver$: Observable<number>;
  pendingSessions$: Observable<PendingSessions>;
  selectedAssessments: AssessmentGroup[] = [];
  isInitializedSession = false;

  activeGroup$: BehaviorSubject<AssessmentGroup>;
  groupCompleted$ = new BehaviorSubject<boolean>(false);
  finishError$ = new BehaviorSubject<boolean>(false);

  nextGroupWatch: Subscription;
  activateTypeWatch: Subscription;
  activeSessionWatch: Subscription;

  isDestroyed = false;
  finishFlag = 'isDone';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public asmService: AssessmentService,
    public navService: ModuleNavService,
    private canModifyPipe: CanModifyPipe,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.types$ = this.asmService.getTypes();

    this.activeType$ = this.navService.assessmentType$;

    this.orgObserver$ = this.navService.organization$.pipe(
      distinctUntilChanged()
    );

    this.allGroups$ = this.activeType$.pipe(
      switchMap(type => this.asmService.getGroups(type))
    );

    this.activateTypeWatch = this.activeType$.subscribe(_ =>
      this.setFirstUncompletedGroup()
    );

    this.orgGroups$ = combineLatest([
      this.activeType$,
      this.orgObserver$,
      this.asmService.groupsUpdated$
    ]).pipe(
      takeWhile(_ => !this.isDestroyed),
      switchMap(([type, orgId]) => this.asmService.getOrgGroups(type, orgId)),
      shareReplay(1)
    );

    this.sessionRequest$ = combineLatest([
      this.activeType$,
      this.orgObserver$,
      this.asmService.isSelectedAssessment$
    ]).pipe(
      takeWhile(_ => !this.isDestroyed),
      switchMap(([type, orgId]) => this.asmService.getSession(type, orgId)),
      tap(
        response =>
          (this.finishFlag = this.canModifyPipe.transform(response)
            ? 'isApproved'
            : 'isDone')
      ),
      shareReplay(1)
    );

    this.session$ = this.sessionRequest$.pipe(
      map(response => response.body),
      tap(session => {
        if (!!this.isInitializedSession !== !!session.isInitialized) {
          this.isInitializedSession = !!session.isInitialized;
          this.asmService.isSelectedAssessment$.next(!!session.isInitialized);
        }
        this.cd.detectChanges();
      })
    );

    this.groups$ = combineLatest([
      this.allGroups$,
      this.orgGroups$,
      this.session$
    ]).pipe(
      switchMap(([allGroups, orgGroups, session]) => {
        if (session.isPartial) {
          const partialGroups = allGroups.filter(g => orgGroups[g.id]);

          return of(partialGroups);
        } else {
          return of(allGroups);
        }
      })
    );

    // check if a session ID has changed while within the context of the same org and type
    this.activeSessionWatch = combineLatest([
      this.activeType$,
      this.orgObserver$
    ])
      .pipe(
        map(([type, org]) => type.id.toString() + '-' + org),
        distinctUntilChanged(),
        switchMap(g =>
          this.navService.activeAssessmentSessionId$.pipe(
            filter(s => !!s),
            distinctUntilChanged(),
            debounceTime(500),
            skip(1)
          )
        )
      )
      .subscribe(id => this.asmService.groupsUpdated$.next(Date.now()));

    this.setFirstUncompletedGroup();

    this.activeGroup$ = this.navService.assessmentGroup$;

    this.pendingSessions$ = this.asmService.getSessionsPendingApproval();

    // move to next group if current is done
    this.nextGroupWatch = this.asmService.moveToNextGroup$
      .pipe(
        takeWhile(_ => !this.isDestroyed),
        filter(r => r),
        switchMap(_ =>
          combineLatest([
            this.activeGroup$,
            this.activeType$,
            this.groups$
          ]).pipe(take(1))
        )
      )
      .subscribe(([active, type, orgGroups]) => {
        const next = orgGroups.find(
          g =>
            Number(g.position) > Number(active.position) &&
            (!orgGroups[g.id] || !orgGroups[g.id][this.finishFlag])
        );

        if (next) {
          this.setGroup(next);
        } else if (Object.values(orgGroups).length) {
          this.finish(true);
        }

        window.scrollTo({ top: 0, behavior: 'smooth' });
      });
  }

  ngOnDestroy() {
    this.isDestroyed = true;
    this.activateTypeWatch.unsubscribe();
    this.nextGroupWatch.unsubscribe();
    this.activeSessionWatch.unsubscribe();
  }

  setType(type: AssessmentType) {
    this.navService.assessmentType.current = type.id;
  }

  setGroup(group: AssessmentGroup) {
    const current = this.navService.assessmentGroup$.value;
    this.router.navigate([
      'org',
      this.navService.lastOrganization.current,
      'assessment'
    ]);
    if (group && (!current || group.id !== current.id)) {
      this.navService.assessmentGroup$.next(group);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  finish(waitUntilReady = false) {
    const takeFunc = waitUntilReady ? takeWhile(_ => waitUntilReady) : take(1);
    combineLatest([this.groups$, this.orgGroups$])
      .pipe(takeFunc)
      .subscribe(([groups, orgGroups]) => {
        if (
          Object.values(orgGroups).filter(g => (g as AssessmentOrgGroup).isDone)
            .length === groups.length
        ) {
          this.navService.assessmentGroup$.next(null);

          this.router.navigate(['finish'], { relativeTo: this.route });
          this.finishError$.next(false);
          waitUntilReady = false;
        } else if (!waitUntilReady) {
          this.setFirstUncompletedGroup();
          this.finishError$.next(true);
          setTimeout(_ => this.finishError$.next(false), 5000);
        }
      });
  }

  addToSelectedList(group: AssessmentGroup) {
    if (this.selectedAssessments.includes(group)) {
      const idx = this.selectedAssessments.indexOf(group);
      this.selectedAssessments.splice(idx, 1);
    } else {
      this.selectedAssessments.push(group);
    }
  }

  selectAllGroups(groups) {
    this.selectedAssessments =
      groups.length === this.selectedAssessments.length ? [] : [...groups];
  }

  startAssessment(session: AssessmentSession) {
    this.asmService
      .startSession(
        this.selectedAssessments.map(el => el.id),
        session
      )
      .pipe(
        switchMap(result => {
          if (result) {
            return this.activeType$.pipe(
              switchMap(types =>
                this.asmService.getSelectedAssessmentGroups(
                  session.type_id,
                  session.org_id
                )
              )
            );
          }
        }),
        catchError(error => {
          return of([]);
        })
      )
      .subscribe(groups => {
        window.location.reload();

        // const groupValues = Object.values(groups) as AssessmentGroup[];
        // if (groupValues.length) {
        //   window.setTimeout(() => {
        //     this.isInitializedSession = true;
        //     this.asmService.isSelectedAssessment$.next(true);
        //     this.asmService.groupsUpdated$.next(Date.now())
        //   }, 100);
      });
  }

  setOrganization(organization: Organization) {
    this.navService.lastOrganization.current = organization.id;

    this.router.navigate(['org', organization.id, 'assessment']);
  }

  viewPastAssessments() {
    this.router.navigate(
      ['dashboard', this.navService.lastOrganization.current],
      { state: { section: 'assessments' } }
    );
  }

  private setFirstUncompletedGroup() {
    if (this.groups$ && this.orgGroups$) {
      combineLatest([this.groups$, this.orgGroups$])
        .pipe(take(1))
        .subscribe(([groups, orgGroups]) => {
          const unfinished = groups.find(
            g => !orgGroups[g.id] || !orgGroups[g.id][this.finishFlag]
          );
          if (
            !unfinished &&
            groups.length === Object.values(orgGroups).length
          ) {
            this.finish();
          } else {
            this.router.navigate([
              'org',
              this.navService.lastOrganization.current,
              'assessment'
            ]);
            this.setGroup(unfinished || groups[0]);
          }
        });
    }
  }
}
