import * as Handsontable from 'src/assets/scripts/handsontable.js';

export const MultipleCheckboxesEditor = Handsontable.editors.BaseEditor.prototype.extend();

MultipleCheckboxesEditor.prototype.getValue = () => {};
MultipleCheckboxesEditor.prototype.open = () => {};
MultipleCheckboxesEditor.prototype.close = () => {};
MultipleCheckboxesEditor.prototype.beginEditing = () => {};
MultipleCheckboxesEditor.prototype.finishEditing = () => {};
MultipleCheckboxesEditor.prototype.cancelChanges = () => {};
MultipleCheckboxesEditor.prototype.discardEditor = () => {};
MultipleCheckboxesEditor.prototype.onBeforeKeyDown = () => {};
