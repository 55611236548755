import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  ViewChild
} from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import { PricingStrategyPlotTemplateData } from '.';
import txt from '!!raw-loader!./index.ts';

@Component({
  selector: 'pricing-strategy-plot',
  templateUrl: 'pricing-strategy-plot.component.html',
  styleUrls: ['./pricing-strategy-plot.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => PricingStrategyPlotComponent)
    }
  ]
})
export class PricingStrategyPlotComponent extends TemplateComponent
  implements AfterViewInit {
  @ViewChild('PerceivedPrice', { static: false })
  PerceivedPriceCanvas: ElementRef;
  @ViewChild('PerceivedValue', { static: false })
  PerceivedValueCanvas: ElementRef;

  params = txt;
  contentData: PricingStrategyPlotTemplateData['template_params_json'];

  isAllValuesExist = false;
  grid: string[][][] = [
    [[], [], []],
    [[], [], []],
    [[], [], []]
  ];
  ranges = ['1-3', '4-6', '7-10'];

  getDescription() {
    return 'Plot competitor perceived price/value table in Pricing Strategy module';
  }

  getName() {
    return 'Pricing Strategy Plot';
  }

  getGroup() {
    return 'Module';
  }

  init() {
    this.contentData = this.data.data
      .template_params_json as PricingStrategyPlotTemplateData['template_params_json'];

    const matrix = JSON.parse(
      this.getInput('expand_data_1_competitors').getValue()
    );

    const facilitatorCompany = JSON.parse(
      this.getInput('matrix_entry_1_your_company').getValue()
    );

    const clearValuesFromCompanyMatrixEntry = facilitatorCompany[0].map(
      matrixEntryItem => this.textContent(matrixEntryItem.data)
    );

    const competitorsPriceAndValues = [
      Object.values(matrix['Perceived Price']),
      Object.values(matrix['Perceived Value'])
    ]
      .flat()
      .filter(
        (competitorPriceOrValue: { value: string }) =>
          !competitorPriceOrValue.value
      );

    Object.values(matrix.Competitor).forEach((name, index) => {
      const perceivedPrice = Number(matrix['Perceived Price'][index].value);
      const perceivedValue = Number(matrix['Perceived Value'][index].value);

      if (
        !perceivedPrice ||
        !perceivedValue ||
        !(name as { value: string }).value
      ) {
        return;
      }

      const row = this.getRangeIdx(perceivedPrice);
      const col = this.getRangeIdx(perceivedValue);
      const array = this.grid[row][col];

      array.push((name as { value: string }).value);
    });

    if (
      competitorsPriceAndValues.length !== 0 ||
      matrix.Competitor.length === 0 ||
      matrix.Competitor.some(c => !c.value) ||
      !clearValuesFromCompanyMatrixEntry.every(columnValue => columnValue)
    ) {
      this.isAllValuesExist = true;
    } else {
      const row = this.getRangeIdx(clearValuesFromCompanyMatrixEntry[1]);
      const col = this.getRangeIdx(clearValuesFromCompanyMatrixEntry[2]);
      const array = this.grid[row][col];
      array.push(clearValuesFromCompanyMatrixEntry[0]);
    }
  }

  ngAfterViewInit(): void {
    if (!this.PerceivedPriceCanvas || !this.PerceivedValueCanvas) {
      return;
    }

    const perceivedPriceCanvasElement = this.PerceivedPriceCanvas
      .nativeElement as HTMLCanvasElement;
    const perceivedValueCanvasElement = this.PerceivedValueCanvas
      .nativeElement as HTMLCanvasElement;

    const ctxPerceivedPriceCanvasElement = perceivedPriceCanvasElement.getContext(
      '2d'
    );
    const ctxPerceivedValueCanvasElement = perceivedValueCanvasElement.getContext(
      '2d'
    );

    ctxPerceivedPriceCanvasElement.fillStyle = 'white';
    ctxPerceivedPriceCanvasElement.fillRect(25, 8, 2, 600);

    ctxPerceivedValueCanvasElement.fillStyle = 'white';
    ctxPerceivedValueCanvasElement.fillRect(0, 25, 598, 2);

    ctxPerceivedPriceCanvasElement.beginPath();
    ctxPerceivedPriceCanvasElement.moveTo(26, 0);
    ctxPerceivedPriceCanvasElement.lineTo(12, 26);
    ctxPerceivedPriceCanvasElement.lineTo(40, 26);
    ctxPerceivedPriceCanvasElement.fill();

    ctxPerceivedValueCanvasElement.beginPath();
    ctxPerceivedValueCanvasElement.moveTo(600, 26);
    ctxPerceivedValueCanvasElement.lineTo(575, 10);
    ctxPerceivedValueCanvasElement.lineTo(575, 40);
    ctxPerceivedValueCanvasElement.fill();
  }

  private getRangeIdx(value: number) {
    return value > 6 ? 2 : Number(value >= 4);
  }
}
