import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, RoutesRecognized } from '@angular/router';
import { BehaviorSubject, Observable, combineLatest, from, of } from 'rxjs';
import { ModuleService } from './module.service';
import {
  AssessmentType,
  AssessmentGroup
} from '../interfaces/assessment.interface';
import {
  filter,
  startWith,
  distinctUntilChanged,
  switchMap,
  shareReplay,
  share,
  take,
  map,
  withLatestFrom,
  skip,
  tap,
  pluck,
  mergeMap
} from 'rxjs/operators';
import { AssessmentService } from './assessment.service';
import { Module, Organization } from '../interfaces/module.interface';
import { ModuleContentService } from './module-content.service';

export class ResourceFromStorage<T extends { toString: () => string }> {
  onChange = new BehaviorSubject<T>(null);

  private _current: T;
  private readonly storageKey: string;
  private readonly defaultObservable: Observable<T>;
  private readonly type: 'json' | 'string' | 'number';

  constructor(
    storageKey: string,
    defaultObservable: Observable<T> = null,
    type: 'json' | 'string' | 'number' = 'json'
  ) {
    this.storageKey = storageKey;
    this.type = type;
    this.onChange.next(this.current);
    this.defaultObservable = defaultObservable;
    this.loadDefaultValue();
  }

  set current(value: T) {
    if (value === this._current) {
      return;
    }
    this._current = value;
    window.localStorage.setItem(this.storageKey, this.processToStorage());
    this.onChange.next(value);
  }

  get current(): T {
    if (this._current === undefined) {
      const fromStorage = window.localStorage.getItem(this.storageKey);
      if (fromStorage) {
        this._current = this.processFromStorage(fromStorage);
      }
    }

    if (this._current === undefined) {
      this.loadDefaultValue(true);
    }

    return this._current;
  }

  loadDefaultValue(force?: boolean) {
    if ((force || !this.current) && this.defaultObservable) {
      this.defaultObservable.pipe(take(1)).subscribe(value => {
        this.current = value;
      });
    }
  }

  processFromStorage(fromStorage: string) {
    if (this.type === 'json') {
      try {
        return JSON.parse(fromStorage);
      } catch (e) {
        return null;
      }
    }
    if (this.type === 'number') {
      return Number(fromStorage);
    }

    return fromStorage;
  }

  processToStorage() {
    if (this.type !== 'json') {
      return this._current.toString();
    } else {
      return JSON.stringify(this._current);
    }
  }
}

@Injectable()
export class ModuleNavService {
  assessmentType = new ResourceFromStorage<number>('last_type');
  activeAssessmentType$: Observable<AssessmentType>;
  assessmentGroup$ = new BehaviorSubject<AssessmentGroup>(null);
  activeAssessmentSessionId$ = new BehaviorSubject<number>(null);

  lastOrganization = new ResourceFromStorage<number>(
    'last_organization_id',
    this.moduleService.getDefaultOrganization().pipe(map(org => org.id)),
    'number'
  );

  organization$ = this.lastOrganization.onChange.pipe(
    filter(org => !!org),
    distinctUntilChanged()
  );

  currentOrganizationData$ = this.organization$.pipe(
    switchMap(orgId =>
      this.moduleService
        .getOrganizations()
        .pipe(
          map(organizations =>
            organizations.find((org: Organization) => org.id === orgId)
          )
        )
    )
  );

  module = new ResourceFromStorage<number>(
    'last_module_id',
    this.moduleService.getDefaultModule().pipe(map(mod => mod.id)),
    'number'
  );

  module$ = this.module.onChange.pipe(
    filter(m => !!m),
    distinctUntilChanged()
  );

  videoLibraryId = new ResourceFromStorage<number>(
    'last_module_id',
    of(1),
    'number'
  );

  moduleData$ = combineLatest([
    this.organization$,
    this.module$,
    this.moduleService.moduleChanged$
  ]).pipe(
    switchMap(([orgId, module]) =>
      this.moduleService.getOrgModule(module, orgId, false)
    ),
    map(moduleData => {
      const sortedSteps = moduleData.steps.reduce((steps, step) => {
        steps[step.id] = step;

        return steps;
      }, {});

      const isLocked = moduleData.steps.reduce((locked, step) => {
        const pendingSteps = step.linked_ids
          .filter(
            id =>
              sortedSteps[id] &&
              !sortedSteps[id].is_checked &&
              !sortedSteps[id].is_approved &&
              !sortedSteps[id].is_partial
          )
          .map(id => sortedSteps[id].description);

        locked[step.id] = pendingSteps.length ? pendingSteps : false;

        return locked;
      }, {});

      moduleData.steps.forEach(step => (step.isLocked = isLocked[step.id]));

      return moduleData;
    }),
    share()
  );

  moduleDataReplay$ = this.moduleData$.pipe(shareReplay(1));

  step = new ResourceFromStorage<number>(
    'last_step_id',
    this.moduleData$.pipe(
      map(mod => mod.steps.find(s => !s.is_section_break).id)
    ),
    'number'
  );

  step$ = this.step.onChange.pipe(
    filter(m => !!m),
    distinctUntilChanged()
  );

  private moveStep$ = new BehaviorSubject<number>(null);

  get assessmentType$() {
    if (!this.activeAssessmentType$) {
      this.activeAssessmentType$ = this.assessmentType.onChange.pipe(
        startWith(this.assessmentType.current || 1),
        distinctUntilChanged(),
        filter(t => !!t),
        switchMap(type_id => this.asmService.getType(type_id))
      );
    }

    return this.activeAssessmentType$;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private moduleService: ModuleService,
    private asmService: AssessmentService,
    private moduleContentService: ModuleContentService
  ) {
    this.moduleService.getOrganizations().subscribe(organizations => {
      const currentOrg =
        parseInt(this.route.snapshot.params.orgId, 10) ||
        this.lastOrganization.current;
      this.lastOrganization.current = Number(currentOrg)
        ? Number(currentOrg)
        : Number(organizations[0].id);

      this.router.events.subscribe(val => {
        if (val instanceof RoutesRecognized) {
          const params = val.state.root.firstChild.params;

          if (params.orgId) {
            this.lastOrganization.current = Number(params.orgId);
          }
        }
      });
    });

    // move to next (or previous) step
    this.moveStep$
      .pipe(
        filter(offset => !!offset),
        switchMap(_ =>
          this.moduleDataReplay$.pipe(
            skip(1),
            take(1),
            withLatestFrom(this.moveStep$)
          )
        )
      )
      .subscribe(([module, offset]) => {
        let index = module.steps.findIndex(s => s.id === this.step.current);
        let step = null;

        do {
          index = Math.min(
            Math.max(0, index + offset),
            module.steps.length - 1
          );
          step = module.steps[index];
        } while (
          index !== module.steps.length - 1 &&
          (step.is_section_break ||
            step.parent_step_id ||
            step.isLocked ||
            !index)
        );

        if (step.is_section_break) {
          step = module.steps.find(
            findStep => !findStep.is_section_break && !findStep.isLocked
          );
        }

        if (!step.isLocked && !step.parent_step_id) {
          this.goToStep(step.id);
        }
      });
  }

  getActivatedRoute(): ActivatedRoute {
    return this.route;
  }

  getRouter(): Router {
    return this.router;
  }

  nextStep() {
    this.moveStep$.next(1);
  }

  previousStep() {
    this.moveStep$.next(-1);
  }

  setAssessmentType(type: AssessmentType) {
    this.assessmentType.current = type.id;
  }

  goToStep(id: number) {
    this.router.navigate([
      'org',
      this.lastOrganization.current,
      'module',
      this.module.current,
      'step',
      id
    ]);
  }

  getModuleService() {
    return this.moduleService;
  }

  getLinkedStepsInputs() {
    const linkedInputs = {};
    let linkedSteps;

    const linkedModules = this.moduleDataReplay$.pipe(
      map(moduleData =>
        moduleData.steps.find(step => step.id === this.step.current)
      ),
      pluck('linked_ids'),
      filter(linked_ids => linked_ids !== undefined),
      tap((linked_ids: number[]) => {
        linkedSteps = linked_ids;
      }),
      mergeMap((linked_ids: number[]) => from(linked_ids)),
      switchMap((linked_id: number) =>
        this.moduleContentService.load(
          this.module.current,
          linked_id,
          this.lastOrganization.current
        )
      )
    );

    return linkedModules.pipe(
      map((module, index) =>
        // console.log('---------------------')
        // console.log('module linked', module)

        this.moduleDataReplay$.pipe(
          mergeMap((linkedModule: Module) => {
            // console.log('insideModule', linkedModule)

            const parentSteps = linkedModule.steps.filter(
              step => step.parent_step_id === +linkedSteps[index]
            );

            // console.log('filtered steps', parentSteps)

            return parentSteps.map(parentStep =>
              // console.log('****')
              // console.log(parentStep.module_id, parentStep.id, module.org_id)
              this.moduleContentService.load(
                parentStep.module_id,
                parentStep.id,
                module.org_id
              )
            );
          })
        )
      )
    );
  }
}
