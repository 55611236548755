import { Pipe, PipeTransform } from '@angular/core';
import { format, isValid, parse } from 'date-fns';
import {
  FULL_DATE_INPUT_FORMAT,
  MONTH_YEAR_INPUT_FORMAT,
  SECONDARY_FULL_DATE_INPUT_FORMAT
} from '../utils/date-fns-date-picker';

@Pipe({
  name: 'formatdate'
})
export class FormatDatePipe implements PipeTransform {
  transform(date: string | Date, formatDate = FULL_DATE_INPUT_FORMAT): string {
    const dateObj = this.parse(date, formatDate);

    return isValid(dateObj) ? format(dateObj, formatDate) : '';
  }

  parse(date: string | Date, formatDate = FULL_DATE_INPUT_FORMAT): Date {
    if (typeof date === 'string') {
      let fullDate = parse(date, formatDate, new Date());
      if (formatDate !== FULL_DATE_INPUT_FORMAT && !isValid(fullDate)) {
        fullDate = parse(date, FULL_DATE_INPUT_FORMAT, new Date());
      }
      if (!isValid(fullDate)) {
        fullDate = parse(date, SECONDARY_FULL_DATE_INPUT_FORMAT, new Date());
      }
      if (!isValid(fullDate)) {
        fullDate = parse(date, MONTH_YEAR_INPUT_FORMAT, new Date());
      }

      return fullDate;
    }

    return date;
  }
}
