import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import {
  Campaign,
  CampaignCustomField,
  CampaignGraph,
  buyingStages,
  campaignTypes,
  CampaignTemplateErrors,
  defaultKpiValue
} from './index';
import { websafeColors } from './websafe-colors';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import {
  add,
  differenceInDays,
  eachMonthOfInterval,
  endOfYear,
  format,
  getDayOfYear,
  getDaysInMonth,
  getDaysInYear,
  getMonth,
  getYear,
  lastDayOfYear,
  parse,
  startOfYear
} from 'date-fns';
import { ConfirmationService } from '../../../../../common/services/confirmation.service';
import { MatDialog } from '@angular/material/dialog';
import { v4 as uuidv4 } from 'uuid';
import { SnackBarService } from '../../../../../common/services/snackbar.service';
import { TemplateInput } from '../../../../../common/interfaces/module.interface';
import { FULL_DATE_INPUT_FORMAT } from '../../../../../common/utils/date-fns-date-picker';
import { environment } from '../../../../../../environments/environment';

declare interface Month {
  name: string;
  width: number;
}

@Component({
  selector: 'campaign-calendar',
  templateUrl: './campaign-calendar.component.html',
  styleUrls: ['./campaign-calendar.component.scss']
})
export class CampaignCalendarComponent implements OnInit, AfterViewInit {
  @Input() readonly = false;
  @Output() campaignsChange = new EventEmitter<Campaign[]>();
  @Input() campaigns: Campaign[] = [];
  @Input() itemLabel = 'Campaign';
  @Input() customFieldsArray: CampaignCustomField[] = [];
  @Input() isCampaignModule = true;
  @Input() errors: CampaignTemplateErrors;
  @Input() campaignInput: TemplateInput;
  months: Month[] = eachMonthOfInterval({
    start: startOfYear(new Date()),
    end: endOfYear(new Date())
  }).map(month => ({
    name: format(month, 'MMM'),
    width: 0
  }));
  campaignGraphs: CampaignGraph;
  years: Array<{ name: string; months: Month[] }> = [];
  colors = [...websafeColors];
  isPrintableMode = environment.allowDynamicApi;

  @ViewChildren('tableHeadRows') tableHeadRows: QueryList<any>
  @ViewChildren('tableRows') tableRows: QueryList<any>

  readonly buyingStages = buyingStages;
  readonly campaignTypes = campaignTypes;

  constructor(
    private modalService: MatDialog,
    private confirmationService: ConfirmationService,
    private sanitizer: DomSanitizer,
    private snackBarService: SnackBarService
  ) {}

  ngOnInit(): void {
    this.colors = this.colors.filter(
      color => !this.campaigns.find(campaign => campaign.color === color)
    );
    if (this.customFieldsArray?.length) {
      this.predefineCustomFields();
    }
    this.updateCampaigns();
  }

  ngAfterViewInit() {
    if (environment.allowDynamicApi) {
      const childLength = this.tableRows.get(0).nativeElement.children.length

      for (let i = 0; i < childLength; i++) {
        let maxValue = 0;

        this.tableRows.forEach((row: ElementRef) => {
          if (row.nativeElement.children[i].offsetHeight > maxValue) {
            maxValue = row.nativeElement.children[i].offsetHeight;
          }
        })

        this.tableRows.forEach((row: ElementRef) => {
          this.tableHeadRows.get(0).nativeElement.children[i].height = maxValue;
          row.nativeElement.children[i].height = maxValue;
        })
      }
    }

  }

  trackByFn(index: number, item: Campaign): string {
    return item.id;
  }

  deleteCampaign(campaign: Campaign): void {
    this.confirmationService
      .removeDialog({
        text: campaign.theme
      })
      .subscribe(() => {
        const idx = this.campaigns.findIndex(c => c.id === campaign.id);
        this.campaigns.splice(idx, 1);
        this.triggerCampaignsChange();
      });
  }

  getMonthCellWidthPercent(month: string, year: string): number {
    const daysInMonth = getDaysInMonth(
      parse(`${month}-${year}`, 'MMM-yyyy', new Date())
    );
    const daysInYear = getDaysInYear(+year);
    const fullWidthOfMonthsPercent = 72;

    return (daysInMonth * fullWidthOfMonthsPercent) / daysInYear;
  }

  triggerCampaignsChange(): void {
    this.updateCampaigns();
    this.campaignsChange.emit(this.campaigns);
  }

  startDataChange(): void {
    this.sortCampaigns();
    this.triggerCampaignsChange();
  }

  private getCampaignWidth(campaign: Campaign): SafeStyle {
    const start = new Date(campaign.startDate);
    const end = new Date(campaign.endDate);
    const diff = differenceInDays(end, start);
    const borderWidth = getMonth(start) < 6 ? 3 : getMonth(start) > 8 ? 1 : 2;

    return this.sanitizer.bypassSecurityTrustStyle(
      `calc(${(diff * 100) / getDaysInYear(start)}% + ${borderWidth}px)`
    );
  }

  private getCampaignOffset(campaign: Campaign): SafeStyle {
    const start = new Date(campaign.startDate);
    const offsetDays = getDayOfYear(start) - 1;
    const borderWidth = getMonth(start) < 1 ? 0 : getMonth(start) > 5 ? 2 : 1;

    return this.sanitizer.bypassSecurityTrustStyle(
      `calc(${(offsetDays * 100) / getDaysInYear(start)}% + ${borderWidth}px)`
    );
  }

  private splitCampaignsByYear(campaigns: Campaign[]): CampaignGraph {
    return [].concat
      .apply(
        [],
        campaigns
          .filter(campaign => campaign.startDate && campaign.endDate)
          .map(campaign => {
            let start = new Date(campaign.startDate);
            const end = new Date(campaign.endDate);
            const ranges = [];
            while (getYear(start) !== getYear(end)) {
              ranges.push([
                format(start, FULL_DATE_INPUT_FORMAT),
                format(lastDayOfYear(start), FULL_DATE_INPUT_FORMAT)
              ]);
              start = add(lastDayOfYear(start), { days: 1 });
            }
            ranges.push([
              format(start, FULL_DATE_INPUT_FORMAT),
              format(end, FULL_DATE_INPUT_FORMAT)
            ]);

            return ranges.map(range => ({
              ...campaign,
              startDate: range[0],
              endDate: range[1]
            }));
          })
      )
      .map(campaign => {
        campaign.offset = this.getCampaignOffset(campaign);
        campaign.width = this.getCampaignWidth(campaign);

        return campaign;
      })
      .reduce((acc, campaign) => {
        const year = getYear(new Date(campaign.startDate));
        if (!acc.hasOwnProperty(year)) {
          acc[year] = [];
        }
        acc[year].push(campaign);

        return acc;
      }, {});
  }

  private updateCampaigns(): void {
    this.campaigns = this.campaigns.map(campaign => {
      if (!campaign.color) {
        return {
          ...campaign,
          color: this.getColor()
        };
      }

      return campaign;
    });
    this.campaignGraphs = this.splitCampaignsByYear(this.campaigns);
    this.years = Object.keys(this.campaignGraphs).map(year => ({
      name: year,
      months: this.months.map(month => ({
        name: month.name,
        width: this.getMonthCellWidthPercent(month.name, year)
      }))
    }));
  }

  private addCampaign(): void {
    const lastCampaign =
      this.campaigns.length && this.campaigns[this.campaigns.length - 1];
    if (!this.campaigns.length || lastCampaign?.theme) {
      const newCampaign: Campaign = {
        id: uuidv4(),
        theme: '',
        kpi: this.isCampaignModule ? defaultKpiValue : null,
        persona: [],
        customFields: this.customFieldsArray?.reduce((accum, customField) => {
          accum[customField.name] = '';

          return accum;
        }, {})
      };
      this.campaigns.push(newCampaign);
      this.triggerCampaignsChange();
    } else {
      this.snackBarService.info(
        'Please fill in the title of the previous campaign',
        2000
      );
    }
  }

  private sortCampaigns(): void {
    this.campaigns.sort(
      (a, b) =>
        new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
    );
  }

  private getColor(): string {
    if (!this.colors.length) {
      return websafeColors[0];
    }

    return this.colors.shift();
  }

  private predefineCustomFields(): void {
    let triggerUpdate = false;
    this.campaigns = this.campaigns.map(campaign => ({
      ...campaign,
      customFields: this.customFieldsArray.reduce((accum, customField) => {
        triggerUpdate = !(
          campaign.customFields &&
          campaign.customFields[customField.name] !== undefined
        );
        accum[customField.name] =
          (campaign.customFields
            ? campaign.customFields[customField.name]
            : '') || '';

        return accum;
      }, {})
    }));

    if (triggerUpdate) {
      this.triggerCampaignsChange();
    }
  }
}
