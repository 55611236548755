import { NgModule } from '@angular/core';
import { E3CheckboxComponent } from './components/e3-checkbox/e3-checkbox.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { E3AsyncButtonDirective } from './components/e3-async-button/e3-async-button.directive';

import { E3ScrollSpyDirective } from './components/e3-scrollspy/e3-scrollspy.directive';
import { E3ConfirmationDialogComponent } from './components/e3-confirmation-dialog/e3-confirmation-dialog.component';
import { E3ConfirmationDialogService } from './components/e3-confirmation-dialog/e3-confirmation-dialog.service';
import { SafehtmlPipe } from './pipes/safehtml.pipe';
import { MatButtonModule } from '@angular/material/button';
import { SafeurlPipe } from './pipes/safeurl.pipe';
import { E3RequiredComponent } from './components/e3-required/e3-required.component';
import { LoadingComponent } from './components/loading/loading.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal';
import { CustomSnackbarComponent } from './components/custom-snackbar/custom-snackbar.component';
import { MatIconModule } from '@angular/material/icon';
import { SnackBarService } from './services/snackbar.service';
import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSnackBarModule
} from '@angular/material/snack-bar';
import { MAT_SNACK_BAR_CUSTOM_OPTIONS } from './components/custom-snackbar/snackbar';
import { DatePickerInputComponent } from './components/date-picker-input/date-picker-input.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MAT_DATE_FORMATS,
  MatNativeDateModule,
  MatRippleModule
} from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MAT_DATE_FNS_DATE_FORMATS } from './utils/date-fns-date-picker';
import { MatDialogModule } from '@angular/material/dialog';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TabsLabelComponent } from './components/tabs/tabs-label-component/tabs-label.component';
import { TabsBodyComponent } from './components/tabs/tabs-body-component/tabs-body.component';
import { TabsItemComponent } from './components/tabs/tabs-item-component/tabs-item.component';
import { TabsComponent } from './components/tabs/tabs-component/tabs.component';
import { CurrencyMaskDirective } from './directives/currency-mask.directive';
import { NumberDirective } from './directives/number.directive';
import { SurveyModalComponent } from './components/survey-modal/survey-modal.component';
import { ReadableDatePipe } from './pipes/readabledate.pipe';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { MatChipsModule } from '@angular/material/chips';
import { FormatDatePipe } from './pipes/fomartdate.pipe';
import { AutosizeTextboxDirective } from './directives/autosize-textbox.directive';
import { WindowRefService } from './services/window-ref.service';
import { ContentEditableValueAccessorDirective } from './directives/contenteditable-value-accessor.directive';
import { InterpolatePipe } from './pipes/interpolate.pipe';

const exports = [
  E3CheckboxComponent,
  E3AsyncButtonDirective,
  E3ScrollSpyDirective,
  E3ConfirmationDialogComponent,
  E3RequiredComponent,
  SafehtmlPipe,
  SafeurlPipe,
  ReadableDatePipe,
  FormatDatePipe,
  InterpolatePipe,
  LoadingComponent,
  ConfirmationModalComponent,
  SurveyModalComponent,
  CustomSnackbarComponent,
  DatePickerInputComponent,
  TabsLabelComponent,
  TabsBodyComponent,
  TabsItemComponent,
  TabsComponent,
  FileUploaderComponent,
  ReadableDatePipe,
  CurrencyMaskDirective,
  NumberDirective,
  AutosizeTextboxDirective,
  ContentEditableValueAccessorDirective
];
@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatSnackBarModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    FontAwesomeModule,
    MatRippleModule,
    MatChipsModule
  ],
  declarations: exports,
  providers: [
    E3ConfirmationDialogService,
    SnackBarService,
    WindowRefService,
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: MAT_SNACK_BAR_CUSTOM_OPTIONS
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_DATE_FORMATS },
    FormatDatePipe
  ],
  bootstrap: [],
  exports
})
export class E3CommonModule {}
