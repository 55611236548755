import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { VideoData } from '../../../video-manager';

@Component({
  selector: 'feedback-source',
  templateUrl: './feedback-source.component.html',
  styleUrls: ['./feedback-source.component.scss']
})
export class FeedbackSourceComponent implements OnInit {
  @Input() ckConfig = { allowedContent: true };

  @Output() messageChanged = new EventEmitter();
  @Output() currentTabChanged = new EventEmitter();
  @Output() submitFeedback = new EventEmitter<string>(false);

  tabs = ['text', 'video', 'audio'];
  activeTabIndex = 0;

  _message = '';
  get message() {
    return this._message;
  }

  set message(msg: string) {
    this._message = msg;
    this.messageChanged.emit(msg);
  }

  _currentTab = 'text';
  get currentTab() {
    return this._currentTab;
  }

  set currentTab(msg: string) {
    this._currentTab = msg;
    this.currentTabChanged.emit(msg);
  }

  get videoTabIndex() {
    return this.tabs.indexOf('video');
  }

  get activeTab() {
    return this.tabs[this.activeTabIndex];
  }

  constructor() {}

  ngOnInit() {
    this.currentTabChanged.emit(this.activeTab);
  }

  submitVideo(videoData: VideoData): void {
    const recordedVideoHtml = videoData?.recordedVideoUrl
      ? `<video class="record-video" src="${videoData.recordedVideoUrl}" controls>`
      : '';
    const uploadedVideoHtml = videoData?.uploadedVideoData?.url
      ? `<video class="upload-video" src="${videoData.uploadedVideoData.url}" controls>`
      : '';
    const videoCommentHtml = videoData?.comment
      ? `<p class="video-comment">${videoData?.comment}</p>`
      : '';
    const messageBlockHtml = [
      recordedVideoHtml,
      uploadedVideoHtml,
      videoCommentHtml
    ].join('');
    this.message =
      '<div style="display: flex; flex-direction: column; justify-content: center text-align: center">' +
      messageBlockHtml +
      '</div>';
    this.submitFeedback.emit(this.message);
  }

  submitAudio(url: string) {
    this.message = `<div style="text-align: center">
      <audio src="${url}" controls>
    </div>`;
    this.submitFeedback.emit(this.message);
  }

  handleTabChange(event: MatTabChangeEvent) {
    this.activeTabIndex = event.index;
    this.currentTab = this.activeTab;
    this.currentTabChanged.emit(this.currentTab);
  }
}
