import { Component, forwardRef } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { Observable, of } from 'rxjs';
import txt from '!!raw-loader!./index.ts';
import { ListElement, ListEntry, ListEntryTemplateParams } from '.';
import { TemplateComponent } from '../template-base.class';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { TemplateInput } from '../../../../common/interfaces/module.interface';
import { Validation } from '../../../../common/validator.class';

@Component({
  selector: 'list-entry',
  templateUrl: 'list-entry.component.html',
  styleUrls: ['./list-entry.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => ListEntryComponent)
    }
  ]
})
export class ListEntryComponent extends TemplateComponent {
  params = txt;
  contentData: ListEntryTemplateParams;

  inputIds: ListElement[] = [];
  buttonText: string;
  maxLine: boolean;
  inputInstance: TemplateInput;
  requiredRowCount: number;

  validators: Validation[];

  getDescription() {
    return '';
  }

  getName() {
    return 'List Entry';
  }

  getGroup(): string {
    return 'Generic';
  }

  trackByFn(index, item) {
    return index;
  }

  init() {
    this.contentData = this.data.data
      .template_params_json as ListEntryTemplateParams;
    this.requiredRowCount = this.contentData.min_number_of_filled_items || 1;
    this.inputInstance = this.getInput(
      this.data.data.template_component,
      1,
      '',
      this.contentData.input_sufix || ''
    );
    const content = this.inputInstance.content
      ? JSON.parse(this.inputInstance.content)
      : null;
    if (content === null) {
      this.prepareDefaultData();
    } else {
      Object.keys(content).forEach(input => {
        this.inputIds.push({
          option: this.textContent(content[input].option) || '',
          uuid: content[input].uuid || uuidv4()
        });
      });
      this.updateInputs();
    }
    if (this.inputIds.length < this.contentData.number_of_pregenerated_items) {
      for (
        let i = this.inputIds.length;
        i < this.contentData.number_of_pregenerated_items;
        i++
      ) {
        this.inputIds.push({ option: '', uuid: uuidv4() });
      }
    }

    if (
      !!this.contentData.max_number_of_item &&
      this.inputIds.length + 1 > this.contentData.max_number_of_item
    ) {
      this.maxLine = true;
    }
    this.buttonText = this.contentData.add_item_action_label;
    this.validators = this.getValidators();
  }

  addStep(): void {
    this.inputIds.push({ option: '', uuid: uuidv4() });
    if (this.inputIds.length === Number(this.contentData.max_number_of_item)) {
      this.maxLine = true;
    }
    this.updateInputs();
  }

  removeStep(index: number): void {
    this.confirmationService.removeDialog({ text: 'item' }).subscribe(() => {
      this.inputIds.splice(index, 1);
      this.maxLine = false;
      this.updateInputs();
    });
  }

  updateInputs(): void {
    if (this.inputInstance.content !== JSON.stringify(this.inputIds)) {
      this.inputInstance.content = JSON.stringify(this.inputIds);
      this.contentChanged(this.inputInstance, this.validators);
    }
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.inputIds, event.previousIndex, event.currentIndex);
    this.updateInputs();
  }

  validate(): Observable<boolean> {
    return of(this.validateInput(this.inputInstance, this.validators));
  }

  private getValidators(): Validation[] {
    if (this.contentData.min_number_of_filled_items) {
      const rowString =
        Number(this.contentData.min_number_of_filled_items) > 1
          ? 'rows'
          : 'row';
      const minFilledMessage =
        this.contentData.error_message_when_not_enough_items_filled ||
        `Please fill in at least ${this.contentData.min_number_of_filled_items} ${rowString} above`;
      const funcParams = {
        minItems: Number(this.contentData.min_number_of_filled_items)
      };
      const minFilledFunc = (value: string, params: { minItems: number }) => {
        const content: ListEntry = value ? JSON.parse(value) : null;
        const filledValues = content
          ? Object.keys(content).filter(key => content[key].option)
          : null;

        return filledValues?.length >= params.minItems;
      };
      const minFilledValidation = new Validation(
        minFilledFunc,
        minFilledMessage,
        funcParams
      );

      return [minFilledValidation];
    }

    return null;
  }

  private prepareDefaultData(): void {
    if (this.contentData.default_data && this.contentData.default_data.length) {
      this.inputIds = this.contentData.default_data.map(itemDefaultData => ({
        option: this.textContent(itemDefaultData.option) || '',
        uuid: uuidv4()
      }));
      this.updateInputs();
    }
  }
}
