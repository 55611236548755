import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  NgForm,
  Validators
} from '@angular/forms';

@Component({
  selector: 'user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit {
  @Output() formSubmitEmitter: EventEmitter<any> = new EventEmitter<any>();

  userForm: FormGroup;
  firstName: FormControl;
  lastName: FormControl;
  email: FormControl;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.firstName = new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(20)
    ]);

    this.lastName = new FormControl('', [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(20)
    ]);

    this.email = new FormControl('', [Validators.required, Validators.email]);

    this.userForm = this.formBuilder.group({
      fname: this.firstName,
      lname: this.lastName,
      email: this.email
    });
  }

  onSubmit(form: NgForm): void {
    if (this.userForm.valid) {
      this.formSubmitEmitter.emit(form.value);
      form.resetForm();
    }
  }
}
