import { Component, ContentChild, Input } from '@angular/core';
import { TabsBodyComponent } from '../tabs-body-component/tabs-body.component';
import { TabsLabelComponent } from '../tabs-label-component/tabs-label.component';

@Component({
  selector: 'app-tabs-item-component',
  templateUrl: './tabs-item.component.html',
  styleUrls: ['./tabs-item.component.scss']
})
export class TabsItemComponent {
  @Input() label: string;

  @Input() isActive: boolean;

  @ContentChild(TabsBodyComponent) bodyComponent: TabsBodyComponent;

  @ContentChild(TabsLabelComponent) labelComponent: TabsLabelComponent;

  constructor() {}
}
