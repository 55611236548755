import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  NgZone,
  Input
} from '@angular/core';
import { RecorderMessageEvent } from '../index';

@Component({
  selector: 'standalone-recorder',
  templateUrl: './standalone-recorder.component.html',
  styleUrls: ['./standalone-recorder.component.scss']
})
export class StandaloneRecorderComponent implements OnInit, OnDestroy {
  @Input() videoUrl: string;
  @Input() width = '325';
  @Input() height = '295';
  @Output() videoUrlChange = new EventEmitter<string>(false);

  url: string;
  videoExist = false;

  private standalone_recorder_url = 'https://standalone-recorder1.seagage.com';
  private alreadyBound = false;
  private sourceId: string;

  constructor(private zone: NgZone) {}

  ngOnInit() {
    if (!this.alreadyBound) {
      this.zone.runOutsideAngular(() => {
        window.addEventListener(
          'message',
          this.handleMessage.bind(this),
          false
        );
      });
    }
    if (this.videoUrl) {
      this.videoExist = true;
      this.url = this.videoUrl;
      this.height = '245';
    } else {
      this.videoExist = false;
      this.url = this.createStandaloneRecorderIframe();
    }
  }

  newRecord() {
    this.videoExist = false;
    this.height = '295';
    this.url = this.createStandaloneRecorderIframe();
  }

  ngOnDestroy() {
    this.zone.runOutsideAngular(() => {
      window.removeEventListener(
        'message',
        this.handleMessage.bind(this),
        false
      );
      this.alreadyBound = false;
    });
  }

  handleMessage(event: RecorderMessageEvent) {
    if (
      event.data.type === 'iframe_event' &&
      event.data.data.type === 'DONE' &&
      event.data.data.data.sourceId === this.sourceId
    ) {
      this.videoUrlChange.emit(event.data.data.data.url);
      this.height = '245';
      this.videoExist = true;
      this.url = event.data.data.data.url;
    }
  }

  private createStandaloneRecorderIframe() {
    this.sourceId = this.getRandomName();
    const recorder_url =
      this.standalone_recorder_url +
      '/index.html' +
      (this.sourceId ? '?sourceId=' + this.sourceId : '');

    return recorder_url;
  }

  private getRandomName() {
    return (
      Math.random()
        .toString(36)
        .substring(2, 15) +
      Math.random()
        .toString(36)
        .substring(2, 15)
    );
  }
}
