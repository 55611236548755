import { TemplateContentDataBase } from '../template.interface';
import { BuyerPersona } from '../../../../common/interfaces/buyer-persona.interface';

export class PersonasDescriptionsFields {
  personas: PersonaField;
  descriptions: Descriptions;
}

export class Descriptions {
  [key: string]: Description[];
}

export class Description {
  id: string;
  value: string;
}

export class PersonaField {
  [index: string]: {
    personaRole: string;
    personaRef: BuyerPersona;
  };
}

export class DescriptionField {
  title: string;
  btnTitle: string;
  jsonName: string;
}

export enum IconsEnum {
  Driver = 'custom_driver',
  Participant = 'custom_participant',
  Gatekeeper = 'custom_gatekeeper',
  Not_involved = 'custom_not_involved'
}

export const DESCRIPTION_FIELDS: DescriptionField[] = [
  { title: 'Needs', btnTitle: 'Need', jsonName: 'needs' },
  { title: 'Activities', btnTitle: 'Activity', jsonName: 'activities' },
  {
    title: 'Content Preferences',
    btnTitle: 'Content Preferences',
    jsonName: 'content_preferences'
  }
];

export interface PersonaRolesTemplateData extends TemplateContentDataBase {
  template_params_json: {
    description: string;
    title: string;
    input_sufix: string;
    line_number_to_process: number;
    linked_step_suffix: string;
  };
}
