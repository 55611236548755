import { Component, forwardRef } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import {
  BuyerInputListTemplateData,
  BuyerInputsData,
  BuyerListContent,
  BuyerListItem,
  BuyerListTemplateInput,
  BuyerPreviousInfo
} from '.';
import txt from '!!raw-loader!./index.ts';
import { Validation } from '../../../../common/validator.class';
import { TemplateInput } from '../../../../common/interfaces/module.interface';
import { switchMap, take, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import {
  ApiDataDefaultType,
  ApiDataDictionary
} from '../../../../common/interfaces/api-data.interface';
import { MatrixCheckbox } from '../matrix-entry';

@Component({
  selector: 'buyer-input-list',
  templateUrl: 'buyer-input-list.component.html',
  styleUrls: ['./buyer-input-list.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => BuyerInputListComponent)
    }
  ]
})
export class BuyerInputListComponent extends TemplateComponent {
  params = txt;
  contentData: BuyerInputListTemplateData['template_params_json'];
  prefix = 'buyer_input_list';

  inputsData: BuyerInputsData[] = [];
  previousInfo: BuyerPreviousInfo[][] = [];
  buttonText: string;
  inputValidators: { [key: string]: Validation[] };
  apiData: ApiDataDictionary = {};

  init() {
    super.init();
    this.contentData = this.data.data
      .template_params_json as BuyerInputListTemplateData['template_params_json'];
    this.contentData.list_title = this.textContent(this.contentData.list_title);

    if (this.contentData.previous_lists) {
      this.previousInfo = this.getPreviousInfo();
    }
    this.prepareRequests().subscribe(() => {
      this.inputsData.push({
        title: this.contentData.list_title,
        suffix: this.contentData.input_sufix,
        data: [],
        count: []
      });
      this.inputsData = this.setInputsData();
    });
    this.buttonText = this.contentData.add_item_action_label || 'Add Item';
    this.inputValidators = this.getValidators();
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  validate() {
    if (
      !this.contentData.fields ||
      this.contentData.fields.length === 0 ||
      // TODO: RAS-274 task. Should be deleted
      this.data.data.step_id === 13154
    ) {
      return of(true);
    }

    this.inputValidators = this.getValidators();

    return this.buyerPersonasList$.pipe(
      take(1),
      switchMap(personaList =>
        of(
          personaList.reduce((isValid, persona, index) => {
            const inputName = `${this.prefix}_${index + 1}_${
              this.contentData.input_sufix
            }`;
            const input = this.getInput(inputName, null, '');

            return (
              (!input ||
                this.validateInput(input, this.inputValidators[inputName])) &&
              isValid
            );
          }, true)
        )
      )
    );
  }

  getDescription() {
    return '';
  }

  getName() {
    return 'Buyer Input List';
  }

  getGroup() {
    return 'Module';
  }

  addInput(row: BuyerInputsData, idx: number): void {
    this.contentData.fields.forEach(field => {
      const fieldContent = row.data[idx].content[field.title];
      const newItem: BuyerListItem = {
        value: '',
        placeholder: field.placeholder,
        disabled: field.disabled,
        type: field.type_select
      };
      if (fieldContent) {
        fieldContent.push(newItem);
      } else {
        row.data[idx].content[field.title] = [newItem];
      }
    });
    row.count[idx] = row.count[idx] + 1;
    this.saveBuyerInput(row.data[idx]);
  }

  removeInput(row: BuyerInputsData, idx: number, inputFieldId: number): void {
    this.confirmationService.removeDialog().subscribe(dialogResult => {
      Object.keys(row.data[idx].content).forEach(key =>
        row.data[idx].content[key].splice(inputFieldId, 1)
      );
      row.count[idx] = row.count[idx] - 1;
      this.saveBuyerInput(row.data[idx]);
    });
  }

  saveBuyerInput(buyerListInput: BuyerListTemplateInput): void {
    this.inputValidators = this.getValidators();
    const input: TemplateInput = this.inputs[buyerListInput.element_key];
    input.content = JSON.stringify(buyerListInput.content);
    this.contentChanged(input, this.inputValidators[input.element_key]);
  }

  private prepareRequests(): Observable<void> {
    return of(null).pipe(
      switchMap(() => this.prepareApiData()),
      switchMap(() => of(null))
    );
  }

  private prepareApiData(): Observable<void> {
    const requests = Object.keys(
      this.contentData.fields?.reduce(
        (accum: { [apiUrl: string]: true }, field) => {
          if (
            field?.use_api_values &&
            field?.api_url &&
            !accum[field.api_url]
          ) {
            accum[field.api_url] = true;
          }

          return accum;
        },
        {}
      )
    ).filter(Boolean);

    return this.navService.organization$.pipe(
      switchMap(orgId =>
        this.moduleService.getMultipleApiData(orgId, requests)
      ),
      tap(data => (this.apiData = data)),
      switchMap(() => of(null))
    );
  }

  private setInputsData(): BuyerInputsData[] {
    return this.inputsData.map(el => {
      el.data = Object.entries(this.inputs)
        .filter(input => input[0].includes(el.suffix))
        .map(input => {
          const inputData = { ...input[1] };
          let buyerData: BuyerListTemplateInput;
          const inputContent: BuyerListContent = JSON.parse(inputData.content);
          const isNewestConfiguration = this.contentData.fields.every(
            field =>
              !!inputContent?.[field.title] &&
              (!inputContent[field.title]?.length ||
                inputContent[field.title][0]?.type === field.type_select)
          );
          if (inputContent && isNewestConfiguration) {
            buyerData = {
              ...inputData,
              content: inputContent
            };
          } else {
            buyerData = {
              ...inputData,
              content: this.contentData.fields
                ? this.contentData.fields.reduce(
                    (accum: BuyerListContent, field) => {
                      let newItem: BuyerListItem = {
                        value: '',
                        placeholder: field.placeholder,
                        disabled: field.disabled,
                        type: field.type_select
                      };
                      if (
                        field.use_api_values &&
                        this.apiData?.[field.api_url]
                      ) {
                        const checkboxes: MatrixCheckbox[] = (this.apiData[
                          field.api_url
                        ] as ApiDataDefaultType).map((checkbox, index) => ({
                          id: index,
                          title: checkbox[field.api_key],
                          isOther: false
                        }));
                        newItem = {
                          ...newItem,
                          checkboxes
                        };
                      }

                      accum[field.title] = [newItem];

                      return accum;
                    },
                    {}
                  )
                : null
            };
          }

          return buyerData;
        });
      el.count = el.data.map(buyerInput =>
        buyerInput.content
          ? Object.keys(buyerInput.content).reduce((accum, key) => {
              if (buyerInput.content[key].length > accum) {
                return buyerInput.content[key].length;
              }
            }, 0)
          : 0
      );

      return el;
    });
  }

  private getPreviousInfo(): BuyerPreviousInfo[][] {
    return this.contentData.previous_lists.reduce((accum, item) => {
      const sortedNames = this.getSortedInputNames(
        new RegExp(this.textContent(item.list_suffix))
      );
      for (let i = 0; i < this.contentData.number_of_inputs; i++) {
        const input = this.inputs[sortedNames[i]];
        if (input) {
          let newItem: BuyerPreviousInfo;
          if (input.element_key.includes('persona_behavior')) {
            newItem = {
              values: [[input.content]],
              className: this.textContent(item.list_suffix)
            };
          } else if (input.element_key.includes('buyer_input_list')) {
            const content = JSON.parse(input.content);
            newItem = {
              values: content
                ? Object.keys(content).map(key =>
                    content[key].map(
                      (listItem: BuyerListItem) => listItem.value
                    )
                  )
                : [['']],
              className: this.textContent(item.list_suffix)
            };
          }
          if (newItem) {
            if (accum[i]) {
              accum[i].push(newItem);
            } else {
              accum[i] = [newItem];
            }
          }
        }
      }

      return accum;
    }, []);
  }

  private getValidators(): { [key: string]: Validation[] } {
    return this.inputsData.reduce((accum, data) => {
      data.data.forEach(input => {
        const requiredMessage = 'Please fill out fields above';
        const requiredFunc = (value: string) => {
          const content: BuyerListContent = JSON.parse(value || null);

          return (
            content &&
            Object.keys(content).every(
              key =>
                !content[key]?.length ||
                content[key].some(item => !!item.value && !item.disabled)
            )
          );
        };
        const requiredValidator = new Validation(requiredFunc, requiredMessage);

        const atLeastMessage = 'Please add at least one item for persona';
        const alLeastFunc = (value: string) => {
          const content: BuyerListContent = JSON.parse(value || null);

          return (
            content && Object.keys(content).every(key => content[key]?.length)
          );
        };
        const alLeastValidator = new Validation(alLeastFunc, atLeastMessage);

        if (accum[input.element_key]) {
          accum[input.element_key].push(
            ...[requiredValidator, alLeastValidator]
          );
        } else {
          accum[input.element_key] = [requiredValidator, alLeastValidator];
        }
      });

      return accum;
    }, {});
  }
}
