import { Component, forwardRef } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import txt from '!!raw-loader!./index.ts';
import {
  ContactStrategySlide,
  ContactStrategySlideInputFields,
  ContactStrategyInputNames,
  ProspectingContactStrategyTemplateData,
  CadenceSlide,
  DayInfo
} from './index';
import {
  Organization,
  TemplateInput
} from '../../../../common/interfaces/module.interface';
import { BuyerPersona } from '../../../../common/interfaces/buyer-persona.interface';
import { combineLatest, Observable } from 'rxjs';
import { shareReplay, take } from 'rxjs/operators';
import { AccountProfile } from '../../../../common/interfaces/account.interface';
import { getNestedValue } from '../../../../common/utils/helpers';
import { BlockRepeaterContent } from '../block-repeater';
import { MatrixElement } from '../matrix-entry';

@Component({
  selector: 'app-prospecting-contact-strategy',
  templateUrl: './prospecting-contact-strategy.component.html',
  styleUrls: ['./prospecting-contact-strategy.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => ProspectingContactStrategyComponent)
    }
  ]
})
export class ProspectingContactStrategyComponent extends TemplateComponent {
  params = txt;
  contentData: ProspectingContactStrategyTemplateData['template_params_json'];
  contactStrategySlides: ContactStrategySlide[] = [];
  cadenceSlides: CadenceSlide[];
  selectedSlideIndex = 0;
  selectedCadencePage: 0 | 1 = 0;
  currentDate: string;
  currentOrg: Organization;
  currentUser: Observable<AccountProfile>;

  private readonly contactStrategyInputSuffixes: Readonly<
    ContactStrategySlideInputFields
  > = {
    triggerEvents: 'trigger_events',
    contactMethods: 'methods_to_contact',
    conceptHypothesis: 'persona_behavior_buyers_concepts',
    businessReason: 'valid_business_reasons',
    solutions: 'solutions_outcomes',
    outcomes: 'solutions_outcomes',
    proofPoints: 'proof_points_assets',
    introductionFramework: 'introduction_framework',
    proposals: 'business_reason_proposal'
  };

  private readonly buyerInputListKey: Readonly<
    ContactStrategySlideInputFields
  > = {
    triggerEvents: 'Trigger Events',
    contactMethods: 'Contact Methods',
    businessReason: 'Valid Business Reason',
    solutions: 'solutions',
    outcomes: 'outcomes',
    proofPoints: 'Proof Points',
    proposals: 'Business Reason Proposal'
  };

  private contactStrategyInputNames: ContactStrategyInputNames;

  private readonly cadenceInputs = {
    mcl_cadence: {
      inputName: 'block_repeater_1_mcl_cadence',
      cadencesPath: 'data[17729].matrix_entry_1_cadence_technology.content'
    },
    mql_cadence: {
      inputName: 'block_repeater_1_mql_cadence',
      cadencesPath: 'data[20052].matrix_entry_1_MQL.content'
    }
  };

  getDescription(): string {
    return '';
  }

  getName(): string {
    return 'Prospecting Contact Strategy';
  }

  getGroup(): string {
    return 'Module';
  }

  get input(): TemplateInput {
    return this.getInput('prospecting_contact_strategy');
  }

  selectSlide(personaIndex: number): void {
    this.selectedSlideIndex = personaIndex;
  }

  init(): void {
    this.contentData = this.data.data
      .template_params_json as ProspectingContactStrategyTemplateData['template_params_json'];
    this.currentUser = this.userService.getAccount().pipe(shareReplay(1));
    this.prepareCurrentDate();
    this.prepareCurrentOrganization();
    this.prepareSlideData();
    if (this.contentData.print_orientation_select) {
      this.setOrientationPrintLayout(this.contentData.print_orientation_select);
    }
  }

  changeCadencePage(pageIndex: 0 | 1): void {
    this.selectedCadencePage = pageIndex;
  }

  private prepareCurrentDate(): void {
    const today = new Date();
    this.currentDate = [
      today.getMonth(),
      today.getDay(),
      today.getFullYear()
    ].join('/');
  }

  private prepareCurrentOrganization(): void {
    combineLatest([
      this.moduleNavService.organization$,
      this.moduleService.getOrganizations()
    ])
      .pipe(take(1))
      .subscribe(([orgId, organizations]) => {
        this.currentOrg = organizations.find(
          org => Number(org.id) === Number(orgId)
        );
      });
  }

  private getItemsFromBuyerInputList(
    inputName: string,
    listKey: string
  ): string[] {
    const content = JSON.parse(this.getInputContent(inputName));
    if (content && content[listKey] && content[listKey]?.length) {
      return content[listKey].map(item => item.value).filter(Boolean);
    }

    return null;
  }

  private getInputContent(inputName: string): string {
    return inputName ? this.getInput(inputName)?.content || null : undefined;
  }

  private prepareStepInputNames(): void {
    this.contactStrategyInputNames = {
      triggerEvents: this.getSortedInputNames(
        new RegExp(this.contactStrategyInputSuffixes.triggerEvents)
      ),
      contactMethods: this.getSortedInputNames(
        new RegExp(this.contactStrategyInputSuffixes.contactMethods)
      ),
      conceptHypothesis: this.getSortedInputNames(
        new RegExp(this.contactStrategyInputSuffixes.conceptHypothesis)
      ),
      businessReason: this.getSortedInputNames(
        new RegExp(this.contactStrategyInputSuffixes.businessReason)
      ),
      solutions: this.getSortedInputNames(
        new RegExp(this.contactStrategyInputSuffixes.solutions)
      ),
      outcomes: this.getSortedInputNames(
        new RegExp(this.contactStrategyInputSuffixes.outcomes)
      ),
      proofPoints: this.getSortedInputNames(
        new RegExp(this.contactStrategyInputSuffixes.proofPoints)
      ),
      introductionFramework: this.getSortedInputNames(
        new RegExp(this.contactStrategyInputSuffixes.introductionFramework)
      ),
      proposals: this.getSortedInputNames(
        new RegExp(this.contactStrategyInputSuffixes.proposals)
      )
    };
  }

  private prepareSlideData(): void {
    switch (this.contentData.step_select) {
      case '22_review_contact_strategy':
        this.prepareStepInputNames();
        this.parseContactStrategyInputData();
        break;
      case '27_review_cadence':
        this.parseCadenceInputData();
        break;
    }
  }

  private parseContactStrategyInputData(): void {
    this.buyerPersonasList$
      .pipe(this.whileExists())
      .subscribe((personas: BuyerPersona[]) => {
        this.contactStrategySlides = personas.map((persona, index) => ({
          persona,
          triggerEvents: this.getItemsFromBuyerInputList(
            this.contactStrategyInputNames.triggerEvents[index],
            this.buyerInputListKey.triggerEvents
          ),
          contactMethods: this.getItemsFromBuyerInputList(
            this.contactStrategyInputNames.contactMethods[index],
            this.buyerInputListKey.contactMethods
          ),
          conceptHypothesis: this.getInputContent(
            this.contactStrategyInputNames.conceptHypothesis[index]
          ),
          businessReason: this.getItemsFromBuyerInputList(
            this.contactStrategyInputNames.businessReason[index],
            this.buyerInputListKey.businessReason
          ),
          solutions: this.getItemsFromBuyerInputList(
            this.contactStrategyInputNames.solutions[index],
            this.buyerInputListKey.solutions
          ),
          outcomes: this.getItemsFromBuyerInputList(
            this.contactStrategyInputNames.outcomes[index],
            this.buyerInputListKey.outcomes
          ),
          proofPoints: this.getItemsFromBuyerInputList(
            this.contactStrategyInputNames.proofPoints[index],
            this.buyerInputListKey.proofPoints
          ),
          introductionFramework: this.getInputContent(
            this.contactStrategyInputNames.introductionFramework[index]
          ),
          proposals: this.getItemsFromBuyerInputList(
            this.contactStrategyInputNames.proposals[index],
            this.buyerInputListKey.proposals
          )
        }));
      });
  }

  private parseCadenceInputData(): void {
    this.buyerPersonasList$
      .pipe(this.whileExists())
      .subscribe((personas: BuyerPersona[]) => {
        this.cadenceSlides = personas.map(persona => ({
          persona,
          mclDays: this.getDaysInfoForPersona(
            persona,
            this.cadenceInputs.mcl_cadence
          ),
          mqlDays: this.getDaysInfoForPersona(
            persona,
            this.cadenceInputs.mql_cadence
          )
        }));
      });
  }

  private getDaysInfoForPersona(
    persona: BuyerPersona,
    inputInfo: { inputName: string; cadencesPath: string }
  ): DayInfo[] {
    const dayStringReplacer = (match, dayNumber) => dayNumber;
    const brContent: BlockRepeaterContent = JSON.parse(
      this.inputs[inputInfo.inputName]?.content || null
    );
    const personaBlock = brContent?.blocks?.find(
      block => block.linkedUuid === persona?.uuid
    );
    const meContent: MatrixElement[][] = getNestedValue(
      personaBlock,
      inputInfo.cadencesPath
    );

    if (meContent?.length) {
      const dayIndex = 0;
      const contactMediumIndex = 1;
      const contentIdeaIndex = 2;
      const combinedDays = meContent.reduce(
        (accum: { [day: string]: DayInfo }, row) => {
          const dayString = String(row[dayIndex]?.data) || '1';
          const dayNumber = dayString?.replace(/Day (\d+)/g, dayStringReplacer);
          const contactMedium = String(row[contactMediumIndex]?.data || '');
          const contentIdea = String(row[contentIdeaIndex]?.data || '');
          if (!dayNumber || (!contactMedium && !contentIdea)) {
            return accum;
          } else if (accum[dayNumber]) {
            accum[dayNumber].contactMedium.push(contactMedium);
            accum[dayNumber].contentIdea.push(contentIdea);
          } else {
            accum[dayNumber] = {
              dayNumber,
              contactMedium: [contactMedium],
              contentIdea: [contentIdea]
            };
          }

          return accum;
        },
        {}
      );

      return Object.keys(combinedDays).map(key => combinedDays[key]);
    }

    return null;
  }
}
