import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import {
  Module,
  Organization,
  ModuleCategory,
  SprintStatus
} from 'src/app/common/interfaces/module.interface';
import { ModuleService } from 'src/app/common/services/module.service';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { map, shareReplay, skipWhile, switchMap, tap } from 'rxjs/operators';
import {
  ActivatedRoute,
  PRIMARY_OUTLET,
  Router,
  UrlSegmentGroup,
  UrlTree
} from '@angular/router';
import { AssessmentService } from 'src/app/common/services/assessment.service';
import { CanModifyPipe } from 'src/app/common/pipes/canModify.pipe';
import {
  modulePriorities,
  ModulePriorityLevel
} from '../../common/constants/modules';
import { isEmptyValue } from '../../common/utils/helpers';
import { UserService } from '../../common/services/user.service';

export const SalesTrainingSegment = 'sales-training';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  @Input() isExecutiveDashboard: boolean;
  modules$: Observable<ModuleCategory[]>;
  categoryColumns: Module[][][];
  organizations$: Observable<Organization[]>;
  filteredOrganizations: Organization[];
  organization: Organization;
  view = 'list';
  isSalesExcellenceDashboard = true;
  moduleListUpdate$: BehaviorSubject<void> = new BehaviorSubject<void>(null);
  sprintStatus: SprintStatus;
  organizationSubscription: Subscription;
  canActivate = false;
  IS_RACF_SE = false;
  canToggleScreens = false;
  isSalesTraining = false;
  canSeeExecutiveDashboard = false;
  dashboard = 'sales_excellence';

  readonly priorityLevelInfo: Record<ModulePriorityLevel, string> = {
    [ModulePriorityLevel.Undefined]: 'Undefined',
    [ModulePriorityLevel.Level100]: '100 LEVEL',
    [ModulePriorityLevel.Level200]: '200 LEVEL',
    [ModulePriorityLevel.Level300]: '300 LEVEL',
    [ModulePriorityLevel.Level400]: '400 LEVEL'
  };

  readonly priorityLevelArray: number[] = [
    ModulePriorityLevel.Level100,
    ModulePriorityLevel.Level200,
    ModulePriorityLevel.Level300,
    ModulePriorityLevel.Level400
  ];

  constructor(
    private moduleService: ModuleService,
    private asmService: AssessmentService,
    private route: ActivatedRoute,
    private router: Router,
    private canModifyPipe: CanModifyPipe,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.processUrlSegment();
    this.prepareOrganizations();
    this.prepareModules();
    if (window.history.state && window.history.state.section) {
      this.setView(window.history.state.section);
    }
  }

  ngOnDestroy() {
    this.organizationSubscription.unsubscribe();
  }

  setView(selectedView: string): void {
    this.view = selectedView;
  }

  switchGrid(selectedDashboard: string): void {
    this.dashboard = selectedDashboard;
  }

  setOrganization(organization: Organization) {
    this.organization = organization;
    if (Number(this.route.snapshot.params.orgId) !== Number(organization.id)) {
      this.router.navigate(
        this.isSalesTraining
          ? ['dashboard', SalesTrainingSegment, organization.id]
          : ['dashboard', organization.id]
      );
    }
    this.updateSprintStatus();
    this.triggerModuleListUpdate();
  }

  filterOrganizations(organizations: Organization[], query: string): void {
    this.filteredOrganizations = query
      ? organizations.filter(organization =>
          organization.name.toLowerCase().includes(query.toLowerCase())
        )
      : organizations;
  }

  private prepareOrganizations(): void {
    this.organizations$ = this.moduleService.getOrganizations();
    const id = this.route.snapshot.params.orgId;
    this.organizationSubscription = this.organizations$.subscribe(
      organizations => {
        this.filteredOrganizations = organizations;
        this.setOrganization(
          id
            ? organizations.find(org => org.id.toString() === id)
            : organizations[0]
        );
      }
    );
  }

  private prepareModules(): void {
    const moduleRequest$ = this.moduleListUpdate$.pipe(
      skipWhile(() => !this.organization),
      switchMap(() =>
        this.moduleService
          .getCategories(this.organization.id, this.isSalesTraining)
          .pipe(shareReplay(1))
      ),
      shareReplay(1),
      tap(response => {
        this.canActivate = this.canModifyPipe.transform(response);
        this.canToggleScreens =
          this.canActivate ||
          this.userService.me?.permissions?.riversideRMCFAdmin;
        if (!this.canToggleScreens) this.dashboard = 'portfolio_company';
        this.canSeeExecutiveDashboard =
          this.userService.me.is_super_admin ||
          this.userService.me.is_riverside_managing_director ||
          this.userService.me.is_riverside_rmcf_admin;
        this.userService.getFundId().subscribe(userMe => {
          this.IS_RACF_SE =
            userMe.fund === 'RACF' &&
            this.userService.me.is_riverside_managing_director;
        });
      })
    );

    this.modules$ = moduleRequest$.pipe(
      map(response => this.prepareCategories(response.body)),
      tap((categories: ModuleCategory[]) => {
        this.categoryColumns = categories.map(category => {
          if (category.modules.length >= 12) {
            const chunkSize = Math.ceil(category.modules.length / 2);

            return [
              category.modules.slice(0, chunkSize),
              category.modules.slice(chunkSize)
            ];
          } else {
            return [category.modules];
          }
        });
      }),
      shareReplay(1)
    );
  }

  private processUrlSegment(): void {
    const tree: UrlTree = this.router.parseUrl(
      this.router.routerState.snapshot.url
    );
    const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
    this.isSalesTraining = g?.segments?.some(
      segment => segment.path === SalesTrainingSegment
    );
    if (this.isSalesTraining) {
      this.setView('grid');
    }
  }

  private prepareCategories(categories: ModuleCategory[]): ModuleCategory[] {
    return categories.map(category => ({
      ...category,
      modules: category.modules.map(module => {
        if (module.status) {
          const sprint_id: number = isEmptyValue(module.status.sprint_id)
            ? 0
            : Number(module.status.sprint_id) || 0;
          module.status = {
            ...module.status,
            sprint_id
          };
        }

        return {
          ...module,
          priority: modulePriorities[module.id] || ModulePriorityLevel.Undefined
        };
      })
    }));
  }

  private updateSprintStatus(): void {
    this.moduleService
      .getSprintStatus(this.organization.id)
      .subscribe(status => (this.sprintStatus = status));
  }

  private triggerModuleListUpdate(): void {
    this.moduleListUpdate$.next();
  }
}
