import { Injectable } from '@angular/core';
import * as Handsontable from 'src/assets/scripts/handsontable.js';

const instances = new Map<string, Handsontable>();

@Injectable()
export class HotTableRegisterer {
  getInstance(id: string): Handsontable {
    return instances.get(id);
  }

  registerInstance(
    id: string,
    instance: Handsontable
  ): Map<string, Handsontable> {
    return instances.set(id, instance);
  }

  removeInstance(id: string): boolean {
    return instances.delete(id);
  }
}
