import { Injectable } from '@angular/core';
import {
  Module,
  ModuleCategory,
  ModuleStatus,
  ModuleStepChange,
  Organization,
  SprintStatus,
  Step,
  TemplateInput,
  VideoList
} from '../interfaces/module.interface';
import { Task } from '../interfaces/task.interface';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {
  BehaviorSubject,
  forkJoin,
  interval,
  Observable,
  of,
  throwError
} from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap,
  takeWhile,
  tap
} from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { RestService } from './rest.service';
import { sanitizeHtml } from '../utils/html-helpers';
import {
  ApiDataDictionary,
  ApiDataType
} from '../interfaces/api-data.interface';

export type GetOrganizationListHTTPParams = {
  includeParent?: '1';
};

@Injectable()
export class ModuleService {
  baseUrl = environment.apiRoot + '/api/modules';

  organizations$: Observable<Organization[]>;
  moduleChanged$ = new BehaviorSubject(true);
  stepInputChanged$: BehaviorSubject<TemplateInput> = new BehaviorSubject<
    TemplateInput
  >(null);
  inputDebounce$: { [key: number]: BehaviorSubject<TemplateInput> } = {};
  inputObservable$: { [key: number]: Observable<TemplateInput> } = {};

  private modules: Observable<Module[]>;

  constructor(private httpClient: HttpClient, private rest: RestService) {}

  static isEqualSteps(stepA: Step, stepB: Step, lookPosition = true): boolean {
    if (lookPosition) {
      return JSON.stringify(stepA) === JSON.stringify(stepB);
    }
    const { step_index: step_indexA, position: positionA, ...contentA } = stepA;
    const { step_index: step_indexB, position: positionB, ...contentB } = stepB;

    return JSON.stringify(contentA) === JSON.stringify(contentB);
  }

  static isEqualModules(
    itemA: Module | string,
    itemB: Module | string
  ): boolean {
    const moduleA: Module =
      typeof itemA === 'string' ? JSON.parse(itemA) : itemA;
    const moduleB: Module =
      typeof itemB === 'string' ? JSON.parse(itemB) : itemB;
    const {
      last_updated: last_updatedA,
      priority: priorityA,
      ...modA
    } = moduleA;
    const {
      last_updated: last_updatedB,
      priority: priorityB,
      ...modB
    } = moduleB;

    return JSON.stringify(modA) === JSON.stringify(modB);
  }

  static CompareStepsBetweenModules(
    itemA: Module | string,
    itemB: Module | string
  ): ModuleStepChange[] {
    const moduleA: Module =
      typeof itemA === 'string' ? JSON.parse(itemA) : itemA;
    const moduleB: Module =
      typeof itemB === 'string' ? JSON.parse(itemB) : itemB;
    const stepsA = [...moduleA.steps];
    const stepsB = [...moduleB.steps];
    const changesA: ModuleStepChange[] = stepsA
      .map(stepA => {
        const stepBIndex = stepsB.findIndex(
          step => Number(step.id) === Number(stepA.id)
        );
        let change: ModuleStepChange;
        if (stepBIndex === -1) {
          change = { step: stepA, action: 'deleted' };
        } else {
          change = ModuleService.isEqualSteps(stepA, stepsB[stepBIndex])
            ? null
            : {
                step: stepsB[stepBIndex],
                action: ModuleService.isEqualSteps(
                  stepA,
                  stepsB[stepBIndex],
                  false
                )
                  ? 'positionChange'
                  : 'contentChange'
              };
          stepsB.splice(stepBIndex, 1);
        }

        return change;
      })
      .filter(Boolean);

    return stepsB.reduce((accum, stepB) => {
      accum.push({ step: stepB, action: 'new' });

      return accum;
    }, changesA);
  }

  static sortModulesAlphabetically(modules: Module[]): Module[] {
    const sortModulesAlphabetically = (a, b): number => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();

      return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
    };

    return modules.sort(sortModulesAlphabetically);
  }

  waitForInputsToSave() {
    return interval(20).pipe(
      takeWhile(
        () => Object.values(this.inputDebounce$).filter(v => v).length > 0
      )
    );
  }

  getModuleConfig(id: number, isCacheable = true): Observable<Module> {
    if (!Number(id)) {
      throw throwError('Invalid ID');
    }

    return this.rest.get<Module>(
      `${this.baseUrl}/${id}`,
      undefined,
      isCacheable,
      null
    );
  }

  getModuleLastUpdateDate(
    moduleId: number
  ): Observable<Pick<Module, 'last_updated'>> {
    return this.rest.get(`${this.baseUrl}/${moduleId}/check`);
  }

  getModuleUpdate(currentModule: Module): Observable<Module> {
    return this.getModuleLastUpdateDate(currentModule.id).pipe(
      switchMap(updateDate => {
        if (
          currentModule?.last_updated &&
          updateDate.last_updated !== currentModule.last_updated
        ) {
          return this.getModuleConfig(currentModule.id, false);
        } else {
          return of(null);
        }
      })
    );
  }

  getOrgModule(
    id: number,
    org_id: number,
    isCacheable = false
  ): Observable<Module> {
    const endpoint = String(id) + `/org/${org_id}`;

    return this.rest
      .get<Module>(`${this.baseUrl}/${endpoint}`, undefined, isCacheable)
      .pipe(
        map(res => {
          let i = 1;
          for (const step of res.steps as Step[]) {
            step.position = i;
            if (!step.is_section_break) {
              i++;
            }
          }

          return res;
        })
      );
  }

  reloadModule() {
    this.moduleChanged$.next(true);
  }

  getModules(): Observable<Module[]> {
    if (!this.modules) {
      this.modules = this.httpClient
        .get<Module[]>(this.baseUrl)
        .pipe(shareReplay(1));
    }

    return this.modules;
  }

  getDefaultModule(): Observable<Module> {
    return this.getModules().pipe(
      switchMap(modules => this.getModuleConfig(modules[0].id))
    );
  }

  getTemplateResources(
    moduleId: number,
    template: string
  ): Observable<string[]> {
    return this.httpClient.get<string[]>(
      `${this.baseUrl}/${moduleId}/templates/resources?template=` + template
    );
  }

  saveModule(module: Module): Observable<null> {
    return this.httpClient.post<null>(`${this.baseUrl}/${module.id}`, module);
  }

  feedbackStarted(
    module: Partial<Module> & { orgId?: number }
  ): Observable<null> {
    return this.httpClient.post<null>(
      `${this.baseUrl}/${module.id}/feedback/start`,
      module
    );
  }

  getCategories(
    orgId: number,
    salesTraining = false
  ): Observable<HttpResponse<ModuleCategory[]>> {
    return this.httpClient.get<ModuleCategory[]>(
      `${this.baseUrl}/categories/org/${orgId}${
        salesTraining ? '?salestraining=true' : ''
      }`,
      { observe: 'response' }
    );
  }

  getSprintStatus(orgId: number): Observable<SprintStatus> {
    return this.rest.get(
      `${this.baseUrl}/org/${orgId}/sprint/status`,
      undefined
    );
  }

  getInputValue(
    orgId: number,
    moduleId: number,
    inputKey: string,
    makeClean = false,
    isCacheable = true
  ): Observable<string> {
    return this.rest.get(
      `${this.baseUrl}/${moduleId}/org/${orgId}/input-value/${inputKey}${
        makeClean ? '?clean=true' : ''
      }`,
      undefined,
      isCacheable
    );
  }

  getMultipleInputValues(
    orgId: number,
    inputInfo: { inputKey: string; moduleId: number; clean: boolean }[]
  ): Observable<{ [key: string]: string }> {
    return inputInfo?.length
      ? forkJoin(
          inputInfo.map(i =>
            this.getInputValue(orgId, i.moduleId, i.inputKey, i.clean).pipe(
              catchError(() => of(null)),
              map((apiData: string) => [i.inputKey, apiData])
            )
          )
        ).pipe(
          map((data: [string, string][]) =>
            data?.reduce((accum, item) => {
              accum[item[0]] = item[1];

              return accum;
            }, {})
          )
        )
      : of(null);
  }

  getAPIData<T = ApiDataType>(
    orgId: number,
    endpoint: string,
    isCacheable = false
  ): Observable<T> {
    if (!endpoint) {
      return of(null);
    }

    return this.rest.get(
      `${this.baseUrl}/org/${orgId}/${endpoint}`,
      undefined,
      isCacheable
    );
  }

  getMultipleApiData(
    orgId: number,
    requests: string[]
  ): Observable<ApiDataDictionary> {
    return requests?.length
      ? forkJoin(
          requests.map(api =>
            this.getAPIData(orgId, api, true).pipe(
              catchError(() => of(null)),
              map(apiData => [api, apiData])
            )
          )
        ).pipe(
          map((data: [string, ApiDataType][]) =>
            data?.reduce((accum, item) => {
              accum[item[0]] = item[1];

              return accum;
            }, {})
          )
        )
      : of(null);
  }

  getVideos(): Observable<VideoList[]> {
    return this.rest.get(`${this.baseUrl}/videos`, undefined, true);
  }

  setStatus(
    module: Partial<Module>,
    isActivated: boolean,
    orgId: number
  ): Observable<ModuleStatus> {
    return this.httpClient.post<ModuleStatus>(
      `${this.baseUrl}/${module.id}/org/${orgId}/` +
        (isActivated ? 'activate' : 'deactivate'),
      {}
    );
  }

  setDueDate(
    module: Partial<Module>,
    date: string,
    orgId: number
  ): Observable<ModuleStatus> {
    return this.httpClient.post<ModuleStatus>(
      `${this.baseUrl}/${module.id}/org/${orgId}/due-date`,
      { date }
    );
  }

  setModuleSprint(
    module: Partial<Module>,
    orgId: number,
    sprintId: number
  ): Observable<any> {
    return this.httpClient.post(`${this.baseUrl}/org/${orgId}/sprint/module`, {
      module_id: module.id,
      sprint_id: sprintId
    });
  }

  setSprintTargetDate(orgId: number, date: string): Observable<any> {
    return this.httpClient.post(`${this.baseUrl}/org/${orgId}/sprint/date`, {
      date
    });
  }

  saveNotes(
    module: Partial<Module>,
    orgId: number,
    notes: string
  ): Observable<ModuleStatus> {
    return this.httpClient.post<ModuleStatus>(
      `${this.baseUrl}/${module.id}/org/${orgId}/notes`,
      { notes }
    );
  }

  saveAssignedTo(
    module: Partial<Module>,
    orgId: number,
    assigned_to: string
  ): Observable<ModuleStatus> {
    return this.httpClient.post<ModuleStatus>(
      `${this.baseUrl}/${module.id}/org/${orgId}/assign`,
      { assigned_to }
    );
  }

  saveInput(input: TemplateInput): Observable<TemplateInput> {
    if (!input) {
      console.error('No input data provided');

      return;
    }

    const inputToSave: TemplateInput = { ...input };

    if (!this.inputDebounce$[inputToSave.id]) {
      this.inputDebounce$[inputToSave.id] = new BehaviorSubject(inputToSave);
      this.inputObservable$[inputToSave.id] = this.inputDebounce$[
        inputToSave.id
      ].pipe(
        debounceTime(350),
        distinctUntilChanged(
          (p, i) => p.id === i.id && p.content === i.content
        ),
        switchMap(inp => {
          const dataToSend = (({ comments_json, content, id }) => ({
            comments_json,
            id,
            content: content === null ? content : sanitizeHtml(content)
          }))(inp);

          return this.httpClient
            .post<TemplateInput>(
              `${this.baseUrl}/${inp.module_id}/org/${inp.org_id}/input/${inp.id}`,
              dataToSend
            )
            .pipe(
              tap(() => {
                const isInputChangedDuringPost =
                  this.inputDebounce$[inputToSave.id].getValue()?.content !==
                  inp.content;
                if (!isInputChangedDuringPost) {
                  if (this.inputDebounce$[inputToSave.id]) {
                    this.inputDebounce$[inputToSave.id].complete();
                  }
                  this.inputDebounce$[inputToSave.id] = null;
                  this.inputObservable$[inputToSave.id] = null;
                }
              })
            );
        }),
        shareReplay(1)
      );
    } else {
      this.inputDebounce$[inputToSave.id].next(inputToSave);
    }
    this.stepInputChanged$.next(inputToSave);

    return this.inputObservable$[inputToSave.id];
  }

  saveMultipleInputs(inputs: TemplateInput[]): Observable<null> {
    return this.httpClient.post<null>(
      `${this.baseUrl}/${inputs[0].module_id}/org/${inputs[0].org_id}/inputs`,
      inputs.map(input =>
        (({ comments_json, content, id }) => ({ comments_json, content, id }))(
          input
        )
      )
    );
  }

  getOrganizations(
    forceNew = false,
    params?: GetOrganizationListHTTPParams
  ): Observable<Organization[]> {
    if (!this.organizations$ || forceNew) {
      this.organizations$ = this.httpClient
        .get<Organization[]>(`${this.baseUrl}/organizations/list`, { params })
        .pipe(shareReplay(1));
    }

    return this.organizations$.pipe(filter(orgs => !!orgs));
  }

  getOrganizationsByModule(id: number): Observable<Organization[]> {
    return this.httpClient
      .get<Organization[]>(`${this.baseUrl}/${id}/organizations/list`)
      .pipe(shareReplay(1));
  }

  getDefaultOrganization(): Observable<Organization> {
    return this.getOrganizations().pipe(map(orgs => orgs[0]));
  }

  exportUrl(moduleId: number) {
    return `${this.baseUrl}/export/${moduleId}`;
  }

  sync(moduleId: number): Observable<null> {
    return this.httpClient.get<null>(`${this.baseUrl}/sync/${moduleId}`);
  }

  markAsDone(
    moduleId: number,
    orgId: number,
    stepId: number,
    is_checked = true
  ): Observable<null> {
    return this.httpClient.post<null>(
      this.baseUrl +
        '/' +
        moduleId +
        '/org/' +
        orgId +
        '/step/' +
        stepId +
        '/done',
      { is_checked }
    );
  }

  markAsApproved(
    moduleId: number,
    orgId: number,
    stepId: number,
    is_approved: boolean,
    is_section: boolean
  ): Observable<number[]> {
    return this.httpClient.post<number[]>(
      this.baseUrl +
        '/' +
        moduleId +
        '/org/' +
        orgId +
        '/step/' +
        stepId +
        '/done',
      { is_approved, is_section, org_id: orgId }
    );
  }

  getExecutiveDashboard(org_id: number): Observable<Task[]> {
    return this.httpClient.get<Task[]>(
      `${this.baseUrl}/executive_dashboard/org/${org_id}`
    );
  }
}
