import {
  Component,
  ElementRef,
  forwardRef,
  OnDestroy,
  QueryList,
  ViewChildren
} from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import {
  BuyerPersonaMatrix,
  BuyerPersonaMatrixFromStep,
  ICP,
  ICPPersonaData,
  ICPSegmentSelector,
  SolutionFromListEntry,
  ValuePropositionTableColumn,
  ValuePropositionTemplateData
} from '.';
import txt from '!!raw-loader!./index.ts';
import { BuyerPersona } from '../../../../common/interfaces/buyer-persona.interface';
import { IcpProfileService } from '../../../../common/services/icp-profile.service';
import { TemplateInput } from '../../../../common/interfaces/module.interface';
import { map, take } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'value-proposition',
  templateUrl: './value-proposition.component.html',
  styleUrls: ['./value-proposition.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => ValuePropositionComponent)
    }
  ]
})
export class ValuePropositionComponent extends TemplateComponent
  implements OnDestroy {
  @ViewChildren('tabs') tabs: QueryList<ElementRef>;

  params = txt;
  contentData: ValuePropositionTemplateData['template_params_json'];

  verticalsInput = 'list_entry_1_value_propositions_verticals';
  verticalsIcpsInput = 'block_repeater_1_valuePropositions_verticalsIcpReview';
  verticalsBuyerPersonasInput =
    'icp_buyer_personas_selector_1_valuePropositions_buyerPersonasVerticals';

  generalData: any;
  loading = false;
  ICPSelectorStepId = 10737;
  ICPSelectorInput = 'icp_segment_selector_1';

  mainColumns: ValuePropositionTableColumn[] = [
    {
      title: 'Value Proposition Statement & Paragraph',
      classFieldsNames: [
        'ValuePropositionStatement',
        'ValuePropositionParagraph'
      ],
      placeholders: [
        'Value Proposition Statement',
        'Value Proposition Paragraph'
      ],
      steps: [
        { index: 4, isEditableField: true },
        { index: 5, isEditableField: true },
        { index: 7, isEditableField: false }
      ]
    },
    {
      title: 'Needs',
      classFieldsNames: ['Needs'],
      placeholders: [],
      steps: [
        { index: 1, isEditableField: true },
        { index: 2, isEditableField: false },
        { index: 3, isEditableField: false },
        { index: 4, isEditableField: false },
        { index: 5, isEditableField: false },
        { index: 7, isEditableField: false }
      ]
    },
    {
      title: 'Wants',
      classFieldsNames: ['Wants'],
      placeholders: [],
      steps: [
        { index: 1, isEditableField: true },
        { index: 2, isEditableField: false },
        { index: 3, isEditableField: false },
        { index: 4, isEditableField: false },
        { index: 5, isEditableField: false },
        { index: 7, isEditableField: false }
      ]
    },
    {
      title: 'Fears',
      classFieldsNames: ['Fears'],
      placeholders: [],
      steps: [
        { index: 1, isEditableField: true },
        { index: 2, isEditableField: false },
        { index: 3, isEditableField: false },
        { index: 4, isEditableField: false },
        { index: 5, isEditableField: false },
        { index: 7, isEditableField: false }
      ]
    },
    {
      title: 'Products / Services',
      classFieldsNames: ['ProductsServices'],
      placeholders: [],
      steps: [
        { index: 2, isEditableField: true },
        { index: 3, isEditableField: false },
        { index: 4, isEditableField: false },
        { index: 5, isEditableField: false },
        { index: 7, isEditableField: false }
      ]
    },
    {
      title: 'Unique, Common, Commodity',
      classFieldsNames: ['UniqueCommonCommodity'],
      placeholders: [],
      steps: [
        { index: 2, isEditableField: true },
        { index: 3, isEditableField: false },
        { index: 4, isEditableField: false },
        { index: 5, isEditableField: false },
        { index: 7, isEditableField: false }
      ]
    },
    {
      title: 'Prioritize',
      classFieldsNames: ['Prioritize'],
      placeholders: [],
      steps: [
        { index: 3, isEditableField: true },
        { index: 4, isEditableField: false },
        { index: 5, isEditableField: false },
        { index: 7, isEditableField: false }
      ]
    }
  ];
  currentStepColumns: ValuePropositionTableColumn[];
  sanitizer: DomSanitizer;
  buyerPersonaMatrix = new BuyerPersonaMatrix();
  templateSteps = {
    First: 1,
    Second: 2,
    Third: 3,
    Fourth: 4,
    Fifth: 5,
    Sixth: 6,
    Summary: 7
  };
  currentStep:
    | 'First'
    | 'Second'
    | 'Third'
    | 'Fourth'
    | 'Fifth'
    | 'Sixth'
    | 'Summary'
    | 'Areas';

  generalInput: TemplateInput;
  buyerPersonasSelectionMatrixInput: TemplateInput;
  icpSegmentsInput: TemplateInput;
  listEntryInput: TemplateInput;
  currentStepNumber: number;

  icpSegments: ICPSegmentSelector[] = [];
  icpData: ICP[] = [];

  listEntryOptions: string[];

  personas: BuyerPersona[];

  errorMessage = '';

  showSpecificIcp = false;
  specificIcpIndex: number;
  faTrashIcon = faTrash;

  private icpService: IcpProfileService;

  getDescription() {
    return '';
  }

  getName() {
    return 'Value Proposition';
  }

  init() {
    this.setOrientationPrintLayout('landscape');

    this.contentData = this.data.data
      .template_params_json as ValuePropositionTemplateData['template_params_json'];

    this.icpService = this.injectorObj.get(IcpProfileService);
    this.sanitizer = this.injectorObj.get(DomSanitizer);

    this.currentStep = this.contentData.step_select;

    this.generalInput = this.getInput('value_proposition', 1, null, null);

    this.fillDefaultdata();

    this.currentStepNumber = this.templateSteps[this.contentData.step_select];

    this.currentStepColumns = this.mainColumns.filter(
      column =>
        column.steps.map(s => s.index).indexOf(this.currentStepNumber) !== -1
    );

    this.mainColumns.forEach(
      column =>
        (column.currentStep = column.steps.find(
          s => s.index === this.currentStepNumber
        ))
    );

    if (this.contentData.step_select === 'Second') {
      const entryPair = Object.entries(this.inputs).find(
        inputPair => inputPair[0].match(/^list_entry*/) && inputPair[1].content
      );

      this.listEntryInput = entryPair[1];

      const solutions = JSON.parse(
        this.listEntryInput.content
      ) as SolutionFromListEntry[];

      this.listEntryOptions = Object.values(solutions)
        .filter(item => item.option)
        .map(item => item.option);
    }
  }

  fillDefaultdata() {
    // Get data from required steps
    const VERTICALS = this.getInput(this.verticalsInput);
    const ICPS = this.getInput(this.verticalsIcpsInput);
    const BUYER_PERSONAS = this.getInput(this.verticalsBuyerPersonasInput);
    const data = {
      verticals: VERTICALS ? JSON.parse(VERTICALS.content) : null,
      icps: ICPS ? JSON.parse(ICPS.content) : null,
      buyerPersonas: BUYER_PERSONAS ? JSON.parse(BUYER_PERSONAS.content) : null
    };

    const icpsPerVertical = [];

    // Verify data
    if (
      data.verticals &&
      data.icps.blocks &&
      data.verticals.length === data.icps.blocks.length
    ) {
      this.loading = true;
      this.buyerPersonasList$.pipe(take(1)).subscribe(personas => {
        data.verticals.map((vertical, index) => {
          icpsPerVertical.push({
            title: vertical.option,
            uuid: vertical.uuid,
            data: {}
          });

          const icps = JSON.parse(
            data.icps.blocks[index].data[this.ICPSelectorStepId][
              this.ICPSelectorInput
            ].content
          ).filter(icp => icp.isChecked);

          icps.map(icp => {
            icpsPerVertical[index].data[icp.title] = {
              icpPersonaData: [],
              companyNarrative: '',
              icpValueProposition: ''
            };
            if (!data.buyerPersonas) return;
            Object.keys(data.buyerPersonas[vertical.uuid]).map(personaKey => {
              const PERSONA = data.buyerPersonas[vertical.uuid];
              if (PERSONA[personaKey] && PERSONA[personaKey][icp.title]) {
                const PERSONA_DATA = {
                  Fears: '',
                  NeedWantsFears: '',
                  Needs: '',
                  Prioritize: '',
                  ProductsServices: '',
                  UniqueCommonCommodity: '',
                  ValuePropositionParagraph: '',
                  ValuePropositionStatement: '',
                  Wants: '',
                  persona: personaKey,
                  personaRef: personas.find(p => p.uuid === personaKey),
                  areas: [this.getNewArea()]
                };
                icpsPerVertical[index].data[icp.title].icpPersonaData.push(
                  PERSONA_DATA
                );
              }
            });
          });
        });
        this.generalData = icpsPerVertical;
        this.getExistingData();
        this.loading = false;
      });
    }
  }

  getExistingData() {
    if (this.generalInput.content) {
      const input = JSON.parse(this.generalInput.content);
      this.generalData.map((vertical, verticalIndex) => {
        const existingVertical = input.find(v => v.uuid === vertical.uuid);
        if (existingVertical) {
          Object.keys(vertical.data).map(icpKey => {
            if (Object.keys(existingVertical.data).includes(icpKey)) {
              // Assign company Narrative and Value Proposition
              this.generalData[verticalIndex].data[icpKey].companyNarrative =
                existingVertical.data[icpKey].companyNarrative;
              this.generalData[verticalIndex].data[icpKey].icpValueProposition =
                existingVertical.data[icpKey].icpValueProposition;
              const icp = vertical.data[icpKey];
              icp.icpPersonaData.map((persona, personaIndex) => {
                const existingPersona = existingVertical.data[
                  icpKey
                ].icpPersonaData.find(p => p.persona === persona.persona);
                if (existingPersona) {
                  this.generalData[verticalIndex].data[icpKey].icpPersonaData[
                    personaIndex
                  ].Fears = existingPersona.Fears;
                  this.generalData[verticalIndex].data[icpKey].icpPersonaData[
                    personaIndex
                  ].NeedWantsFears = existingPersona.NeedWantsFears;
                  this.generalData[verticalIndex].data[icpKey].icpPersonaData[
                    personaIndex
                  ].Needs = existingPersona.Needs;
                  this.generalData[verticalIndex].data[icpKey].icpPersonaData[
                    personaIndex
                  ].Prioritize = existingPersona.Prioritize;
                  this.generalData[verticalIndex].data[icpKey].icpPersonaData[
                    personaIndex
                  ].ProductsServices = existingPersona.ProductsServices;
                  this.generalData[verticalIndex].data[icpKey].icpPersonaData[
                    personaIndex
                  ].UniqueCommonCommodity =
                    existingPersona.UniqueCommonCommodity;
                  this.generalData[verticalIndex].data[icpKey].icpPersonaData[
                    personaIndex
                  ].ValuePropositionParagraph =
                    existingPersona.ValuePropositionParagraph;
                  this.generalData[verticalIndex].data[icpKey].icpPersonaData[
                    personaIndex
                  ].ValuePropositionStatement =
                    existingPersona.ValuePropositionStatement;
                  this.generalData[verticalIndex].data[icpKey].icpPersonaData[
                    personaIndex
                  ].Wants = existingPersona.Wants;
                  this.generalData[verticalIndex].data[icpKey].icpPersonaData[
                    personaIndex
                  ].areas = existingPersona.areas;
                  if (!existingPersona.areas)
                    this.generalData[verticalIndex].data[icpKey].icpPersonaData[
                      personaIndex
                    ].areas = [this.getNewArea()];
                }
              });
            }
          });
        }
      });
    }
  }

  getBuyerPersonaMatrixInstance(personaMatrix: BuyerPersonaMatrixFromStep) {
    const resultPersonaMatrix = new BuyerPersonaMatrix();
    Object.entries(personaMatrix).map(pair => {
      const persona = pair[0];
      const icps = pair[1];

      const row = {
        persona,
        icps: []
      };

      row.icps = Object.entries(icps)
        .filter(icpPair => icpPair[1])
        .map(icpPair => ({
          index: Number(icpPair[0]),
          isSelected: icpPair[1]
        }));

      if (row.icps.length > 0) {
        resultPersonaMatrix.rows.push(row);
      }
    });

    return resultPersonaMatrix;
  }

  getNewArea() {
    return {
      title: '',
      proof_points: [{ value: '' }, { value: '' }, { value: '' }]
    };
  }

  switchInput($event: KeyboardEvent, position: number) {
    const parentOfInput = ($event.target as HTMLInputElement).parentElement;
    const neededInput =
      position === 1
        ? parentOfInput.previousElementSibling?.firstElementChild
        : parentOfInput.nextElementSibling?.firstElementChild;

    if (neededInput instanceof HTMLInputElement) {
      neededInput.selectionStart = neededInput.value.length;
      neededInput.focus();
    }
  }

  makeSafeText(proofPoint) {
    proofPoint.value = this.textContent(proofPoint.value);
    this.updateICP();
  }

  addICPArea(persona) {
    persona.areas.push(this.getNewArea());
    this.updateICP();
  }

  removeArea(persona, areaIndex: number) {
    persona.areas.splice(areaIndex, 1);
    this.updateICP();
  }

  updateICP() {
    this.generalInput.content = JSON.stringify(this.generalData);
    this.contentChanged(this.generalInput);
  }

  getGroup(): string {
    return 'Module';
  }
}
