import { Component, forwardRef } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import txt from '!!raw-loader!./index.ts';
import {
  OperationsCalendar,
  OperationsCharter,
  OperationsOrgDesign,
  OperationsStructure,
  OrgDesignResponsibility,
  QuarterActivities,
  ReportingStructure,
  SalesMarketingOpsReviewTemplateData
} from './index';
import { TemplateInput } from '../../../../common/interfaces/module.interface';
import { ListElement } from '../list-entry';
import { MatrixElement } from '../matrix-entry';

@Component({
  selector: 'app-sales-marketing-ops-review',
  templateUrl: './sales-marketing-ops-review.component.html',
  styleUrls: ['./sales-marketing-ops-review.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => SalesMarketingOpsReviewComponent)
    }
  ]
})
export class SalesMarketingOpsReviewComponent extends TemplateComponent {
  get input(): TemplateInput {
    return this.getInput('sales_marketing_ops_review');
  }
  params = txt;
  contentData: SalesMarketingOpsReviewTemplateData['template_params_json'];

  salesOperationsCharter: OperationsCharter;
  marketingOperationsCharter: OperationsCharter;

  salesOperationsStructure: OperationsStructure;
  marketingOperationsStructure: OperationsStructure;

  salesOperationsDesign: OperationsOrgDesign;
  marketingOperationsDesign: OperationsOrgDesign;

  salesOperationsCalendar: OperationsCalendar;
  marketingOperationsCalendar: OperationsCalendar;
  readonly quarterList: string[] = [
    'Quarter 1',
    'Quarter 2',
    'Quarter 3',
    'Quarter 4'
  ];
  private readonly columnsInVerticalOperations = 4;
  private readonly columnsInOrgDesign = 4;

  getName(): string {
    return 'Sales & Marketing Operations Review';
  }

  getDescription(): string {
    return '';
  }

  getGroup(): string {
    return 'Outputs';
  }

  protected init(): void {
    this.contentData = this.data.data
      .template_params_json as SalesMarketingOpsReviewTemplateData['template_params_json'];
    switch (this.contentData.step_type_select) {
      case '11_review_charters':
        this.prepareSalesMarketingOpsInfo();
        break;
      case '19_review_structure':
        this.prepareSalesMarketingStructureInfo();
        break;
      case '24_review_org_design':
        this.prepareSalesMarketingDesignInfo();
        break;
      case '27_review_calendars':
        this.prepareSalesMarketingCalendarInfo();
        break;
    }
    if (this.contentData.print_orientation_select) {
      this.setOrientationPrintLayout(this.contentData.print_orientation_select);
    }
  }

  private prepareSalesMarketingCalendarInfo(): void {
    this.salesOperationsCalendar = {
      title: 'Sales Operations',
      quarters: this.getQuarterActivities(
        'matrix_entry_1_sales_operations_activities'
      )
    };
    this.marketingOperationsCalendar = {
      title: 'Marketing Operations',
      quarters: this.getQuarterActivities(
        'matrix_entry_1_marketing_operations_activities'
      )
    };
  }

  private prepareSalesMarketingStructureInfo(): void {
    this.salesOperationsStructure = {
      title: 'Sales Data Planning and Reporting Structure',
      structures: this.getReportingStructures(
        'matrix_entry_1_sales_metric',
        'expand_data_1_data_collection_sales_reports',
        'Sales Reports'
      )
    };
    this.marketingOperationsStructure = {
      title: 'Marketing Data Planning and Reporting Structure',
      structures: this.getReportingStructures(
        'matrix_entry_1_marketing_metric',
        'expand_data_1_data_collection_marketing_reports',
        'Marketing Reports'
      )
    };
  }

  private prepareSalesMarketingDesignInfo(): void {
    this.salesOperationsDesign = {
      title: 'Sales Operations',
      responsibilities: this.chunkEntry(
        this.getDesignResponsibilities(
          'matrix_entry_1_sales_responsibility',
          'expand_data_1_operations_structure_sales_responsibilities'
        ),
        this.columnsInOrgDesign
      )
    };
    this.marketingOperationsDesign = {
      title: 'Marketing Operations',
      responsibilities: this.chunkEntry(
        this.getDesignResponsibilities(
          'matrix_entry_1_marketing_responsibilities',
          'expand_data_1_operations_structure_marketing_responsibilities'
        ),
        this.columnsInOrgDesign
      )
    };
  }

  private prepareSalesMarketingOpsInfo(): void {
    this.salesOperationsCharter = {
      title: 'Sales Operations',
      mission: this.getInput(
        'brainstorm_1_questions_mission_statement'
      )?.getValue(),
      values: this.chunkMatrixEntry(
        this.getParsedInputContent('matrix_entry_1_sales_operations_values')
      ),
      objectives: this.chunkListEntry(
        this.listEntryInputContentToArray(
          'list_entry_1_sales_operations_objectives'
        )
      ),
      responsibilities: this.getParsedInputContent(
        'matrix_entry_1_sales_responsibility'
      ),
      notResponsible: this.listEntryInputContentToArray(
        'list_entry_1_sales_not_responsible'
      ),
      faq: this.getParsedInputContent('matrix_entry_1_sales_operations_faq')
    };
    this.marketingOperationsCharter = {
      title: 'Marketing Operations',
      mission: this.getInput(
        'brainstorm_2_questions_mission_statement'
      )?.getValue(),
      values: this.chunkMatrixEntry(
        this.getParsedInputContent('matrix_entry_1_marketing_operations_values')
      ),
      objectives: this.chunkListEntry(
        this.listEntryInputContentToArray(
          'list_entry_1_marketing_operations_objectives'
        )
      ),
      responsibilities: this.getParsedInputContent(
        'matrix_entry_1_marketing_responsibilities'
      ),
      notResponsible: this.listEntryInputContentToArray(
        'list_entry_1_marketing_not_responsible'
      ),
      faq: this.getParsedInputContent('matrix_entry_1_marketing_operations_faq')
    };
  }

  private getReportingStructures(
    metricsInputName: string,
    reportsInputName: string,
    reportsInputTitle: string
  ): ReportingStructure[] {
    const metricsContent = this.getParsedInputContent(metricsInputName);
    const reportsContent = this.getParsedInputContent(reportsInputName);
    if (reportsContent && reportsContent[reportsInputTitle]) {
      const metrics: {
        [name: string]: { type: string; definition: string };
      } = metricsContent.reduce((accum, matrixRow) => {
        const metricName = matrixRow.find(element => Number(element.id) === 1)
          ?.data;
        const metricType =
          matrixRow.find(element => Number(element.id) === 2)?.data || '';
        const metricDefinition =
          matrixRow.find(element => Number(element.id) === 3)?.data || '';
        if (metricName) {
          accum[metricName] = {
            type: metricType,
            definition: metricDefinition
          };
        }

        return accum;
      }, {});

      return reportsContent[reportsInputTitle].reduce(
        (accum, reportName, index) => {
          const metricName = reportsContent?.Metric[index]?.value
            ? reportsContent.Metric[index].value
            : '';
          const reportingCadence = reportsContent?.Frequency[index]?.value
            ? reportsContent.Frequency[index].value
            : '';
          const system = reportsContent?.System[index]?.value
            ? reportsContent.System[index].value
            : '';
          const jobTitle =
            reportsContent['Who (Job Title)'] &&
            reportsContent['Who (Job Title)'][index]?.value
              ? reportsContent['Who (Job Title)'][index].value
              : '';
          const structure: ReportingStructure = {
            metricsName: metricName,
            metricsDefinition: metrics[metricName]?.definition || '',
            type: metrics[metricName]?.type || '',
            reportName: reportName?.value || '',
            system,
            jobTitle,
            reportingCadence
          };
          accum.push(structure);

          return accum;
        },
        []
      );
    }

    return null;
  }

  private getDesignResponsibilities(
    responsibleInputName: string,
    structInputName: string
  ): OrgDesignResponsibility[] {
    const responsibleContent = this.getParsedInputContent(responsibleInputName);
    const structureContent = this.getParsedInputContent(structInputName);
    if (responsibleContent && responsibleContent.length) {
      return responsibleContent.reduce((accum, currentMatrixRow, index) => {
        const responsibleValueElement = currentMatrixRow.find(
          matrixElement => Number(matrixElement?.id) === 1
        );
        const responsibleDefElement = currentMatrixRow.find(
          matrixElement => Number(matrixElement?.id) === 2
        );
        const responsibleTasks: string =
          structureContent?.Tasks && structureContent.Tasks[index]
            ? structureContent.Tasks[index].value
            : '';
        if (responsibleValueElement?.data) {
          const orgDesignItem: OrgDesignResponsibility = {
            name: responsibleValueElement?.data || '',
            definition: responsibleDefElement?.data || '',
            tasksDescription: responsibleTasks || ''
          };
          accum.push(orgDesignItem);
        }

        return accum;
      }, []);
    }

    return null;
  }

  private getQuarterActivities(inputName: string): QuarterActivities[] {
    const parsedInputContent = this.getParsedInputContent(inputName);
    if (parsedInputContent && parsedInputContent.length) {
      return this.quarterList.map(quarterName =>
        parsedInputContent.reduce(
          (accum, currentMatrixRow) => {
            const textElement = currentMatrixRow.find(
              matrixElement => Number(matrixElement?.id) === 1
            );
            const dropdownElement = currentMatrixRow.find(
              matrixElement => Number(matrixElement?.id) === 2
            );
            if (textElement?.data && dropdownElement?.data === quarterName) {
              accum.activities.push(textElement.data);
            }

            return accum;
          },
          { quarterName, activities: [] }
        )
      );
    }

    return null;
  }

  private chunkMatrixEntry(
    matrixEntry: MatrixElement[][]
  ): MatrixElement[][][] {
    return matrixEntry && matrixEntry.length
      ? this.chunkEntry(matrixEntry, this.columnsInVerticalOperations)
      : null;
  }

  private chunkListEntry(listEntry: ListElement[]): ListElement[][] {
    return listEntry && listEntry.length
      ? this.chunkEntry(listEntry, this.columnsInVerticalOperations)
      : null;
  }

  private listEntryInputContentToArray(inputName: string): ListElement[] {
    const parsedInputContent = this.getParsedInputContent(inputName);

    return parsedInputContent
      ? Object.keys(parsedInputContent).map(
          elementName => parsedInputContent[elementName]
        )
      : null;
  }

  private getParsedInputContent(inputName: string) {
    return JSON.parse(this.getInput(inputName)?.content || null);
  }

  private chunkEntry(inputEntry, size: number) {
    return inputEntry.reduce(
      (arr, item, idx) =>
        idx % size === 0
          ? [...arr, [item]]
          : [...arr.slice(0, -1), [...arr.slice(-1)[0], item]],
      []
    );
  }
}
