import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  Module,
  ModuleCategory,
  Organization,
  SprintStatus
} from '../../../common/interfaces/module.interface';
import { ModuleScores } from '../../../common/interfaces/assessment.interface';
import { ModuleService } from '../../../common/services/module.service';
import { Sort } from '@angular/material/sort';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap
} from 'rxjs/operators';
import { AssessmentService } from '../../../common/services/assessment.service';

@Component({
  selector: 'app-list-view-dashboard',
  templateUrl: './list-view-dashboard.component.html',
  styleUrls: ['./list-view-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListViewDashboardComponent implements OnInit, OnChanges {
  @Input() organization: Organization;
  @Input() canActivate: boolean;
  @Input() IS_RACF_SE: boolean;
  @Input() isSalesExcellenceDashboard: boolean;
  @Input() selectedDashboard: string;
  @Input() sprintStatus: SprintStatus;
  @Input() modules$: Observable<ModuleCategory[]>;

  @Output() sprintStatusUpdateTrigger = new EventEmitter<void>();
  @Output() moduleListUpdateTrigger = new EventEmitter<void>();

  orgId$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  listModules$: Observable<Module[]>;
  assessmentScores$: Observable<ModuleScores>;

  listSortOrder$ = new BehaviorSubject<Sort>({
    active: 'idx',
    direction: 'asc'
  });

  constructor(
    protected moduleService: ModuleService,
    private asmService: AssessmentService
  ) {}

  ngOnInit() {
    this.assessmentScores$ = this.orgId$.pipe(
      filter(Boolean),
      distinctUntilChanged(),
      switchMap((orgId: number) => this.asmService.getModuleScores(orgId)),
      shareReplay(1)
    );

    this.listModules$ = combineLatest([
      this.modules$,
      this.listSortOrder$,
      this.assessmentScores$
    ]).pipe(
      map(([response, listSortOrder, assessmentScores]) => {
        const items = response
          .map(category => category.modules)
          .reduce((a, b) => a.concat(b), [])
          .map((module, idx) => {
            module.idx = idx + 1;

            return module;
          });

        return items.sort((a, b) => {
          const direction = listSortOrder.direction === 'asc' ? 1 : -1;
          let field = listSortOrder.active;
          if ('progress_status' === field) {
            field = 'progress';
          }

          let aValue;
          let bValue;
          if (field.substring(0, 10) === 'assessment') {
            const type = field.substring(11);
            aValue = assessmentScores[a.id]
              ? assessmentScores[a.id][type]
              : null;
            bValue = assessmentScores[b.id]
              ? assessmentScores[b.id][type]
              : null;
          } else {
            aValue =
              'idx' === field || 'name' === field
                ? a[field]
                : a.status
                ? a.status[field]
                : null;
            bValue =
              'idx' === field || 'name' === field
                ? b[field]
                : b.status
                ? b.status[field]
                : null;
          }

          if (field === 'sprint_id') {
            aValue = a.status?.progress === 100 ? -1 : aValue;
            bValue = b.status?.progress === 100 ? -1 : bValue;
          }

          // @todo - duplication with master-dashboard code
          let defLowValue =
            (isNaN(parseInt(aValue, 10)) && isNaN(parseInt(bValue, 10))) ||
            'due_date' === field
              ? 'ZZZ'
              : 9999;
          let defHiValue = '' as string | number;

          if ('assessment' === field.substring(0, 10)) {
            defLowValue = 9999 * direction;
            defHiValue = 9999 * direction;
          }

          const defValue = -1 === direction ? defHiValue : defLowValue;

          aValue = aValue || aValue === 0 ? aValue : defValue;
          bValue = bValue || bValue === 0 ? bValue : defValue;

          return aValue < bValue ? -1 * direction : direction;
        });
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.organization) {
      this.orgId$.next(this.organization.id);
    }
  }

  triggerSprintStatusUpdate(): void {
    this.sprintStatusUpdateTrigger.emit();
  }

  triggerModuleListUpdate(): void {
    this.moduleListUpdateTrigger.emit();
  }

  setListSort(sortLabel: Sort) {
    this.listSortOrder$.next(sortLabel);
  }
}
