import { Component, forwardRef } from '@angular/core';
import { TemplateComponent } from '../template-base.class';
import {
  CalculatedCriteriaInputData,
  CalculatedCriteriaTemplateData,
  Grade
} from '.';
import txt from '!!raw-loader!./index.ts';
import { TemplateInput } from '../../../../common/interfaces/module.interface';
import { MatrixElement, MatrixElementData } from '../matrix-entry';
import { ExpandedData } from '../expand-data';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'calculated-criteria',
  templateUrl: 'calculated-criteria.component.html',
  styleUrls: ['./calculated-criteria.component.scss'],
  providers: [
    {
      provide: TemplateComponent,
      useExisting: forwardRef(() => CalculatedCriteriaComponent)
    }
  ]
})
export class CalculatedCriteriaComponent extends TemplateComponent {
  params = txt;
  contentData: CalculatedCriteriaTemplateData['template_params_json'];

  inputInstance: TemplateInput;
  companies: MatrixElementData[];
  companiesExist = false;

  totalScoresA = [];
  totalScoresB = [];
  overallScores = [];
  grades: Grade[] = [];

  scoreAInputContent: ExpandedData;
  scoreBInputContent: ExpandedData;
  weightAInputContent: ExpandedData;
  weightBInputContent: ExpandedData;

  model: CalculatedCriteriaInputData;

  getDescription() {
    return `<p>This template provides the ability to calculate scores of companies by criteria and select them to contact.</p>
            <p>This template requires linked steps and their suffixes. Also it uses 1 input and its input suffix.</p>
            <p>You should link and define suffixes for:</p>
            <ul>
              <li>Companies(Matrix Entry)</li>
              <li>Scores(two Expand Data)</li>
              <li>Weights(two Expand Data)</li>
            </ul>`;
  }

  getName() {
    return 'Calculated Criteria';
  }

  getGroup() {
    return 'Generic';
  }

  setCalculatedCriteriaInputData(company: string, $event: MatRadioChange) {
    this.model[company] = $event.value;
    this.inputInstance.content = JSON.stringify(this.model);
    this.contentChanged(this.inputInstance);
  }

  getCalculatedCriteriaInputData() {
    return JSON.parse(
      this.inputInstance.getValue() || '{}'
    ) as CalculatedCriteriaInputData;
  }

  init() {
    super.init();

    this.contentData = this.data.data
      .template_params_json as CalculatedCriteriaTemplateData['template_params_json'];

    this.initInputs();

    if (this.scoreAInputContent && this.scoreBInputContent) {
      const scoreA = this.getScoreValues(this.scoreAInputContent);
      const scoreB = this.getScoreValues(this.scoreBInputContent);

      if (
        scoreA &&
        scoreB &&
        this.weightAInputContent &&
        this.weightBInputContent
      ) {
        const weightA = this.getWeightValues(this.weightAInputContent);
        const weightB = this.getWeightValues(this.weightBInputContent);

        if (weightA && weightB) {
          this.totalScoresA = this.processTotalScores(scoreA, weightA);
          this.totalScoresB = this.processTotalScores(scoreB, weightB);
          this.overallScores = this.totalScoresA.map(
            (score, index) =>
              Math.floor(score * this.totalScoresB[index] * 10) / 10
          );
          this.grades = this.getGrades();
        }
      }
    }
  }

  initInputs() {
    this.inputInstance = this.getInput(
      this.data.data.template_component,
      1,
      '',
      this.contentData.input_sufix || ''
    );

    if (this.contentData.companies_input_sufix) {
      const companiesInputKey = Object.keys(this.inputs).find(key =>
        key.endsWith(this.contentData.companies_input_sufix)
      );
      const companiesInput =
        companiesInputKey && this.inputs[companiesInputKey];
      const companiesInputContent =
        companiesInput &&
        (JSON.parse(companiesInput.content) as MatrixElement[][]);

      this.companies = companiesInputContent
        .filter(matrixElementGroup => matrixElementGroup[0].data)
        .map(matrixElementGroup => matrixElementGroup[0].data);
      this.companiesExist = Boolean(this.companies.length);
    }

    if (this.companiesExist) {
      let isModelsDifferent = false;
      this.model = Object.entries(this.getCalculatedCriteriaInputData()).reduce(
        (acc, [key, value]) => {
          const existedCompany = this.companies.find(
            company => company === key
          );
          isModelsDifferent = isModelsDifferent
            ? isModelsDifferent
            : !Boolean(existedCompany);

          return existedCompany
            ? {
                ...acc,
                [key]: value
              }
            : acc;
        },
        {}
      );

      if (isModelsDifferent) {
        this.inputInstance.content = JSON.stringify(this.model);
        this.contentChanged(this.inputInstance);
      }

      if (this.contentData.score_a_sufix && this.contentData.score_b_sufix) {
        this.scoreAInputContent = this.getContent(
          this.contentData.score_a_sufix
        );
        this.scoreBInputContent = this.getContent(
          this.contentData.score_b_sufix
        );
      }

      if (this.contentData.weight_a_sufix && this.contentData.weight_b_sufix) {
        this.weightAInputContent = this.getContent(
          this.contentData.weight_a_sufix
        );
        this.weightBInputContent = this.getContent(
          this.contentData.weight_b_sufix
        );
      }
    }
  }

  getContent(suffix: string): ExpandedData {
    const inputKey = Object.keys(this.inputs).find(key => key.endsWith(suffix));
    const input = inputKey && this.inputs[inputKey];

    return input && (JSON.parse(input.content) as ExpandedData);
  }

  getScoreValues(scoreContent: ExpandedData): number[][] {
    return Object.entries(scoreContent).reduce((acc, [key, val]) => {
      if (val[0] && typeof val[0].value !== 'string') {
        val.forEach((current, index) => {
          acc[index] = [
            ...(acc[index] ? acc[index] : []),
            current.value ? Math.floor(Number(current.value) * 10) / 10 : 0
          ];
        });
      }

      return acc;
    }, []);
  }

  getWeightValues(weightContent: ExpandedData): number[] {
    const weightKey = Object.keys(weightContent).find(key =>
      key.match(/^weight$/i)
    );

    return weightContent[weightKey]?.map(weight =>
      weight.value ? Number(weight.value) / 100 : 0
    );
  }

  processTotalScores(scores: number[][], weights: number[]): number[] {
    return scores
      .reduce(
        (companyAcc, companyScore) => [
          ...companyAcc,
          companyScore.reduce(
            (acc, criteriaScore, index) =>
              acc + criteriaScore * (weights[index] || 0),
            0
          )
        ],
        []
      )
      .map(score => Math.floor(score * 10) / 10);
  }

  getGrades(): Grade[] {
    return this.overallScores.map(score => {
      switch (true) {
        case score > 20:
          return 'A';
        case score > 15:
          return 'B';
        case score > 10:
          return 'C';
        case score > 5:
          return 'D';
        default:
          return 'F';
      }
    });
  }
}
