import { CheckboxSelectorItem } from '../checkbox-selector';

export interface StrategyBlock extends Resource {
  inputName: string;
  hours?: number;
  title: string;
  stepId: number;
  numberOfBlock: number;
  marketingHead: string;
  strategies: Strategy[];
}

export interface StrategyBlockError {
  isInvalid?: boolean;
  strategies: {
    canMemberExecuteStrategy?: boolean;
    jobType?: boolean;
    isInvalid?: boolean;
    strategyExecutionDate?: boolean;
    hours?: boolean;
    contribution?: boolean;
    cost?: boolean;
    resources?: {
      isInvalid: boolean;
      contribution?: boolean;
      cost?: boolean;
    }[];
    kpi?: {
      isInvalid: boolean;
      trackingMethod?: { isInvalid: boolean }[];
    }[];
  }[];
}

export interface EmployeeError {
  title: boolean;
  hoursPerWeek?: boolean;
  weeksPerYear?: boolean;
  totalyHours?: boolean;
}

export interface Employee {
  title: string;
  hoursPerWeek: number;
  weeksPerYear: number;
  totalyHours: number;
}

export interface Strategy extends CheckboxSelectorItem, Resource {
  canMemberExecuteStrategy?: boolean;
  jobType?: JobTypes;
  strategyExecutionDate?: string;
  hours?: number;
  resources?: Resource[];
  kpi?: KPI[];
}

export interface KPI {
  name: string;
  trackingMethod?: string[];
}

export interface Resource {
  name: string;
  contribution?: ResourceCircles;
  cost?: ResourceCircles;
  budget?: number;
  budgetPercent?: number;
  scoreValue?: number;
}

export enum ResourceCircles {
  Low = 'Low',
  Medium = 'Med',
  High = 'High'
}

export enum ResourceColors {
  Red = 'red',
  Yellow = 'yellow',
  Green = 'green'
}

export enum JobTypes {
  Hire = 'Hire',
  Train = 'Train',
  Buy = 'Buy'
}

export enum StrategyBlocks {
  PM = 'pm',
  CC = 'cc',
  LDO = 'ldo',
  PDG = 'pdg',
  DM = 'dm'
}

export const STRATEGY_BLOCK_TITLES: Record<StrategyBlocks, string> = {
  [StrategyBlocks.PM]: 'Product Marketing',
  [StrategyBlocks.CC]: 'Content & Creative',
  [StrategyBlocks.LDO]: 'Lead Development & Operations',
  [StrategyBlocks.PDG]: 'Programs and Demand Generation',
  [StrategyBlocks.DM]: 'Digital Marketing'
};

export const STRATEGY_BLOCK_ORDER: Record<StrategyBlocks, number> = {
  [StrategyBlocks.PM]: 1,
  [StrategyBlocks.CC]: 2,
  [StrategyBlocks.LDO]: 3,
  [StrategyBlocks.PDG]: 4,
  [StrategyBlocks.DM]: 5
};
