export const sanitizeHtml = (htmlString: string): string =>
  (htmlString || '')
    .replace(/<script[^>]*>[\S\s]*?<\/script[^>]*>/gi, '')
    .replace(/<style[^>]*>[\S\s]*?<\/style[^>]*>/gi, '')
    .replace(/(style=['"].+?['"])/gm, '')
    .replace(/(class=['"].+?['"])/gm, '')
    .replace(/(id=['"].+?['"])/gm, '')
    .replace(/<img[\S\s]*?\/*[^>]*>/gi, '');

export const trimEmptyLinesFromEnd = (htmlString: string): string =>
  (htmlString || '').replace(
    /((<\s*(p|div)[^>]*>([\s]|<br>|<br \/>)*<\s*\/\s*(p|div)>)|<br>|<br \/>)*$/,
    ''
  );

export const removeStylesFromTags = (htmlString: string): string =>
  (htmlString || '').replace(/(style="[a-zA-Z0-9:;.\s()\-,#!&]*")/gi, '');

export const keepOnlyTextTags = (htmlString): string =>
  (htmlString || '').replace(
    /<(?!\/?(p|div|span|br|br\/|ul|ol|li)(?=>|\s.*>))\/?.*?>/gi,
    ''
  );

export const formatDoc = (sCmd, sValue, element) => {
  document.execCommand(sCmd, false, sValue);
  element.focus();
};
