import { Component, OnInit } from '@angular/core';
import {
  UserDTO,
  UserEvent,
  UsersManagementService,
  UsersTypes,
  UsersTypesInterface
} from '../../common/services/users-management.service';
import { MatTableDataSource } from '@angular/material/table';
import { SnackBarService } from '../../common/services/snackbar.service';

@Component({
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  providers: [UsersManagementService]
})
export class MainComponent implements OnInit {
  active;
  disabled = true;
  usersTypes: UsersTypesInterface[];
  isFormShowed = false;
  currentFormUserType: UsersTypes = 'md';
  tableData: UserDTO[];

  dataSource: MatTableDataSource<Partial<UserDTO>>;
  noUsersFound = false;

  constructor(
    private usersManagementService: UsersManagementService,
    private snackBar: SnackBarService
  ) {}

  ngOnInit(): void {
    this.updateTableData();

    this.usersTypes = [
      {
        type: 'md',
        label: 'Sales Excellence Director',
        buttonLabel: 'Add Sales Excellence Director',
        list: this.tableData
      },
      {
        type: 'facilitator',
        label: 'Facilitators',
        buttonLabel: 'Add Facilitator',
        list: this.tableData
      },
      {
        type: 'rmcf',
        label: 'RMCF Admins',
        buttonLabel: 'Add RMCF Admin',
        list: this.tableData
      }
    ];
  }

  updateTableData() {
    this.usersManagementService
      .loadByType(this.currentFormUserType)
      .subscribe((users: UserDTO[]) => {
        this.tableData = users;
        this.noUsersFound = !(users && users.length);
        this.dataSource = new MatTableDataSource<Partial<UserDTO>>(
          this.tableData
        );
      });
  }

  submitForm(userData: Partial<UserDTO>) {
    this.usersManagementService
      .createUser(this.currentFormUserType, userData)
      .subscribe(user => {
        this.tableData.push(user as UserDTO);
        this.dataSource.data = this.tableData;
        this.noUsersFound = !(this.tableData && this.tableData.length);
        this.snackBar.success('User was successfully created');
      });
  }

  onTableUserChange($event: UserEvent) {
    if ($event.type === 'changeState') {
      this.usersManagementService
        .toggleUserState(this.currentFormUserType, $event.user)
        .subscribe(res => ($event.user.isEnabled = !$event.user.isEnabled));
    } else if ($event.type === 'delete') {
      this.usersManagementService
        .deleteUser(this.currentFormUserType, $event.user)
        .subscribe(res => {
          this.dataSource.data = this.tableData = this.tableData.filter(
            user => $event.user.id !== user.id
          );
          this.noUsersFound = !(this.tableData && this.tableData.length);
          this.snackBar.success('User was successfully deleted');
        });
    }
  }

  toggleShowingUserForm(state: boolean) {
    this.isFormShowed = state;
  }

  changeTab($event) {
    this.toggleShowingUserForm(false);

    switch ($event.index) {
      case 0:
        this.currentFormUserType = 'md';
        break;
      case 1:
        this.currentFormUserType = 'facilitator';
        break;
      case 2:
        this.currentFormUserType = 'rmcf';
        break;
    }

    this.updateTableData();
  }
}
