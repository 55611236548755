import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'users-management-root',
  templateUrl: './users-management-root.component.html',
  styleUrls: ['users-management-root.component.scss']
})
export class UsersManagementRootComponent implements OnInit {
  ngOnInit(): void {}
}
