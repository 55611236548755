import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  NgZone,
  OnDestroy
} from '@angular/core';
import { WindowRefService } from '../services/window-ref.service';

@Directive({
  selector: 'textarea[autosize]'
})
export class AutosizeTextboxDirective implements AfterViewInit, OnDestroy {
  @Input() autosizeMinRows = 1;
  @Input() autosizeMaxRows = 10;

  @Input('ngModel')
  get ngModel(): string {
    return this.value;
  }
  set ngModel(val) {
    this.value = val;
  }

  private lineHeight: number;
  private maxHeight: number;
  private oldContent: string;
  private oldWidth: number;
  private value: string;
  private resizeTimer: number;

  constructor(
    private el: ElementRef<HTMLTextAreaElement>,
    private window: WindowRefService,
    private zone: NgZone
  ) {
    this.el.nativeElement.style.resize = 'none';
    this.el.nativeElement.style.overflow = 'hidden';
  }

  @HostListener('input')
  onInput(): void {
    this.adjustHeight();
  }

  @HostListener('window:resize')
  onResize(): void {
    clearTimeout(this.resizeTimer);
    this.resizeTimer = setTimeout(() => this.adjustHeight(), 100);
  }

  ngOnDestroy(): void {
    clearTimeout(this.resizeTimer);
  }

  ngAfterViewInit(): void {
    this.el.nativeElement.rows = this.autosizeMinRows;
    this.lineHeight = parseFloat(
      this.window.nativeWindow
        .getComputedStyle(this.el.nativeElement, null)
        .getPropertyValue('line-height')
    );
    const maxRow = this.autosizeMaxRows > 0 ? this.autosizeMaxRows : 0;
    this.maxHeight = (this.lineHeight || 0) * maxRow;
    if (this.el.nativeElement.value !== this.value) {
      this.el.nativeElement.value = this.value;
    }
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        this.adjustHeight();
      }, 0);
    });
  }

  adjustHeight(): void {
    const currentText = this.el.nativeElement.value;
    const offsetWidth = this.el.nativeElement.offsetWidth;
    if (
      this.oldContent === currentText &&
      (this.oldWidth === offsetWidth || !offsetWidth)
    ) {
      return;
    }
    this.oldContent = currentText;
    this.oldWidth = offsetWidth;
    this.el.nativeElement.style.overflow = 'hidden';
    this.el.nativeElement.style.height = 'auto';
    let scrollHeight = this.el.nativeElement.scrollHeight;
    if (!!this.maxHeight) {
      scrollHeight =
        scrollHeight > this.maxHeight ? this.maxHeight : scrollHeight;
    }
    this.el.nativeElement.style.height = `${scrollHeight}px`;
    this.el.nativeElement.style.overflow = 'auto';
  }
}
